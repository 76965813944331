<template>
  <span v-if="pedido">
    <v-snackbar color="error" v-model="deshacer.state" multi-line>
      {{ deshacer.message }}
      <v-btn color="blue" text @click="method_deshacer">{{
        deshacer.button
      }}</v-btn>
    </v-snackbar>
    <LayoutRegistrar>
      <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
          <v-breadcrumbs-item
            slot="item"
            slot-scope="{ item }"
            exact
            :to="item.to"
            >{{ item.text }}</v-breadcrumbs-item
          >
        </v-breadcrumbs>
      </template>
      <template v-slot:title>
        <v-list class="ma-0 pa-0">
          <v-list-item class="pl-1">
            <v-list-item-avatar class="ma-1">
              <v-btn @click="$router.push({ name: 'pedidoCompra' })" icon>
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-title>
              <h1>Pedido nº {{ pedido.numeroVentaPorArqueo }}</h1>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-alert
                dense
                :color="estado == 'PENDIENTE' ? 'primary' : 'success'"
                type="info"
              >
                {{ pedido.getEstadosActual }}
              </v-alert>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:navigation-controller>
        <v-container>
          <!-- Agrega detalle de pedido -->
          <agregar-detalle-pedido
            v-if="!disabledDetalle"
            :esVenta="false"
            :isUpdate="true"
            :detalles="detalles_pedido"
          ></agregar-detalle-pedido>
          <!-- Tipo de entrega y fecha estimada -->
          <tipo-entrega-fecha-estimada
            :tipoEntrega="tipoEntrega"
            @update-tipo-entrega="actualizarTipoEntrega"
            @update-fecha-estimada="actualizarFechaEstimada"

            :fechaEntregaEstimada="fechaEntregaEstimada"
            :disabledDetalle="disabledDetalle"
          ></tipo-entrega-fecha-estimada>
          <v-list dense two-line class="pa-0 ma-0">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-calendar-range'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="'Fecha de creación'"
                ></v-list-item-title>
                <v-list-item-subtitle>{{ fechaCreacion }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-folder'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="'Última modificación'"
                ></v-list-item-title>
                <v-list-item-subtitle>{{
                  ultimaActualizacion
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <compra-detalle-subtotal
          :detalles="detalles_pedido"
        ></compra-detalle-subtotal>
        <v-divider></v-divider>

        <v-row
          class="mt-2"
          align="end"
          v-if="hay_cambios && hay_detalles_para_ver"
        >
          <v-col cols="12">
            <v-btn
              :loading="loadingButtonG"
              :disabled="loadingButtonG"
              block
              color="green"
              @click="guardarPedido()"
            >
              Guardar cambios
              <template v-slot:loader>
                <span>Guardando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="estado == 'PENDIENTE'"
              :loading="loadingButtonGA"
              :disabled="loadingButtonGA"
              block
              color="blue"
              @click="guardarAprobarPedido()"
            >
              Guardar y Aprobar
              <template v-slot:loader>
                <span>Aprobando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:vista-previa>
        <v-alert type="info">Al actualizar el pedido, se mantiene el precio original a la fecha de creación.</v-alert>
        <v-card color="transparent" flat>
          <v-container fill-height fluid>
            <v-row align="center">
              <v-col cols="12">
                <pedido-detalle-actualizar
                  :disabledDetalle="disabledDetalle"
                  v-if="detalles_pedido.length > 0"
                  :detalles="detalles_pedido"
                  :esVenta="false"
                ></pedido-detalle-actualizar>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <info-proveedor :proveedor="pedido.proveedor"></info-proveedor>

          <v-list dense two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  v-text="
                    tipoEntrega == 1
                      ? 'mdi-truck-delivery'
                      : 'mdi-home-map-marker'
                  "
                ></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ tipoEntrega }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="fechaEntregaEstimada"
                  >Fecha estimada:
                  {{ moment(fechaEntregaEstimada).format("ll") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </template>
    </LayoutRegistrar>

    <dialog-confirmation-close
      v-if="dialogDiscardChanges"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar actualización?</template>
      <template v-slot:message
        >¿Seguro que quieres salir? Se perderán todos los cambios.</template
      >
      <template v-slot:buttonOK>Salir de la página</template>
    </dialog-confirmation-close>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
import AgregarDetallePedido from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";
import LayoutRegistrar from "@/layouts/layout.registrar.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import { deshacer_eliminado } from "@/services/detallesComprobante/serviceDetallesUpdate";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import PedidoDetalleActualizar from "../../../components/pedido/actualizar/pedido.detalle.actualizar.vue";
import TipoEntregaFechaEstimada from "@/components/compra/pedido/pedido.compra.tipoentrega.fechaestimada.vue";

import CompraDetalleSubtotal from "@/components/compra/compra.detalle.subtotal.vue";

("@/layouts/layout.two.columns.indep.scroll.vue");
export default {
  name: "pedido.compra.actualizar",
  components: {
    CompraDetalleSubtotal,
    TipoEntregaFechaEstimada,
    AgregarDetallePedido,
    InfoProveedor,
    LayoutRegistrar,
    DialogConfirmationClose,
    PedidoDetalleActualizar,
  },
  data() {
    return {
      tipoEntrega: null,
      dialogPedidoCancelar: false,
   
      menuFechaEntrega: false,
      fechaEntregaEstimada: "",
      get_detalles: [],
      pedido: null,
      detalles_pedido: [],
      estado: null,
      estadosSiguientes: [],

      flagCambioTipoEntrega: false,
      flagCambioFechaEntrega: false,

      dialog: false,
      producto_buscado: null,
      dialogDiscardChanges: false,

      toNextRoute: null,

      initCantidadDetalles: null,
      loadingButtonG: false,
      loadingButtonGA: false,
      loadingButtonGF: false,
      loadingButton: false,
    };
  },
  computed: {


    disabledDetalle() {
      if (this.estado == "PENDIENTE") {
        return false;
      }
      return true;
    },
    hay_cambios() {
      if (this.hay_detalles_para_ver) {
        if (
          this.is_update_detalles ||
          this.detalles_pedido.length > this.initCantidadDetalles ||
          this.flagCambioTipoEntrega ||
          this.flagCambioFechaEntrega
        ) {
          return true;
        }
      }
      return false;
    },

    fechaCreacion() {
      return this.moment(this.pedido.fechaAlta).format("LL H:mma");
    },
    ultimaActualizacion() {
      return this.moment(this.pedido.fechaUltModificacion).format("LL H:mma");
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoCompra" },
        },
        {
          text: `Nº ${this.pedido.numeroVentaPorArqueo}`,
          disabled: false,
          to: { name: "ActualizarPedido" },
        },
      ];
    },

    hay_detalles_para_ver() {
      if (this.detalles_pedido.length > 0) {
        return true;
      }
      return false;
    },
    ...mapState("pedidos", ["deshacer"]),
    ...mapGetters("pedidos", ["is_update_detalles", "get_detalles_update"]),
  },
  methods: {
    ...mapMutations("pedidos", [
      "deshacer_eliminado",
      "clean_detalles_update",
      "set_pedido_con_detalle",
    ]),
    ...mapActions("pedidos", ["get_pedido_con_detalle"]),
    actualizarTipoEntrega(tipo){
      alert(tipo)
      console.log(tipo);
      this.flagCambioTipoEntrega = true,
      this.tipoEntrega = tipo
    },
      actualizarFechaEstimada(fecha){
      this.flagCambioFechaEntrega = true,
      this.fechaEntregaEstimada = fecha
    },
    fetchPedido(id) {
      var url = `/pedidos/compra/${id}`;

      this.axios.get(url).then((response) => {
        this.initCantidadDetalles = response.data.detalles_pedido.length;
        this.pedido = response.data;
        this.detalles_pedido = response.data.detalles_pedido;
        this.estado = response.data.getEstadosActual;
        this.tipoEntrega = response.data.tipoEntrega;
        this.fechaEntregaEstimada = response.data.fechaEntregaEstimada;

        this.estadosSiguientes = response.data.getEstadosSiguientesDescripcion;
      });
    },

    setProductoBuscado(data) {
      if (data == null) {
        this.producto_buscado = "";
      } else {
        this.producto_buscado = data;
      }
    },
    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.pedido.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.toNextRoute = true;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });

          var route = "pedidoCompra";

          this.$router.push({ name: route });
        })
        .catch((e) => {});
    },
    cambiarEstado(estado) {
      if (estado == "CANCELADO") {
        this.dialogPedidoCancelar = true;
        return;
      }
      if (estado == "FINALIZADO") {
        this.toNextRoute = true;
        this.$router.push({ name: "nuevoRemitoCompra" });
      }
      this.loadingButton = true;

      let url = `pedidos/actestado/${this.pedido.id}/${estado}`;
      this.axios
        .get(url)
        .then((response) => {
          this.toNextRoute = true;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido ${estado}`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButton = false;
          var route = "pedidoCompra";

          this.$router.push({ name: route });
        })
        .catch((e) => {
          this.loadingButton = false;
        });
    },
    volver() {
      this.toNextRoute = true;

      this.clean_detalles_update();
      var route = "pedidoCompra";

      this.$router.push({ name: route });
    },
    guardarPedido() {
      this.loadingButtonG = true;
      if (
        !this.flagCambioTipoEntrega &&
        !this.flagCambioFechaEntrega &&
        this.get_detalles_update == false
      ) {
        this.loadingButtonG = false;
        return alert("No encuentra detalles a actualizar");
      }

      let url = `/pedidos/compra/${this.pedido.id}`;
      console.log(this.tipoEntrega);
      this.axios({
        method: "PUT",
        url: url,
        data: {
          detalle_pedido: this.get_detalles_update,
          tipoEntrega: this.tipoEntrega.id,
          fechaEntregaEstimada: this.fechaEntregaEstimada,
        },
      })
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido actualizado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButtonG = false;
          this.goToNextRoute();
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "danger",
            text: `No se pudo guardar el pedido`,
            icon: "mdi-close-octagon",
            timeout: 3000,
          });
          this.loadingButtonG = false;
        });
    },
    async guardarEnviarPedido() {
      this.loadingButtonGF = true;

      if (this.get_detalles_update == false)
        return alert("No encuentra detalles a actualizar");

      let urlGuardar = `/pedidos/compra/${this.pedido.id}`;
      const responseGuardar = await this.axios({
        method: "PUT",
        url: urlGuardar,
        data: { detalle_pedido: this.get_detalles_update },
      });

      let urlAprobar = `pedidos/actestado/${this.pedido.id}/enviado`;
      const resonseAprobar = await this.axios.get(urlAprobar);
      this.loadingButtonGF = false;
      this.goToNextRoute();
    },
    async guardarAprobarPedido() {
      this.loadingButtonGA = true;

      if (this.get_detalles_update == false)
        return alert("No encuentra detalles a actualizar");

      let urlGuardar = `/pedidos/compra/${this.pedido.id}`;
      const responseGuardar = await this.axios({
        method: "PUT",
        url: urlGuardar,
        data: { detalle_pedido: this.get_detalles_update },
      });

      let urlAprobar = `pedidos/actestado/${this.pedido.id}/aprobado`;
      try {
        const resonseAprobar = await this.axios.get(urlAprobar);
        this.loadingButtonGA = false;
        this.goToNextRoute();
      } catch (error) {
        this.loadingButtonGA = false;
      }

      this.goToNextRoute();
    },
    goToNextRoute() {
      this.toNextRoute = true;
      var route = "pedidoCompra";

      this.$router.push({ name: route });
    },

    method_deshacer() {
      deshacer_eliminado(this.detalles_pedido);
    },

    closeDialog() {
      this.dialogDiscardChanges = false;
      this.toNextRoute = null;
    },
    discardChanges() {
      this.dialogDiscardChanges = false;
      this.$router.push(this.toNextRoute);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.is_update_detalles) {
      next();
    } else {
      if (this.toNextRoute) {
        this.clean_detalles_update();

        next();
      } else {
        this.dialogDiscardChanges = true;
        this.toNextRoute = to;
      }
    }
  },
  created() {
    let id = this.$route.params.id;
    this.fetchPedido(id);
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
