<template>
  <div>
    <ClienteBuscadorSeleccionar
      :cliente="pedidoClienteVenta"
      @set-cliente="setCliente"
      @remove-cliente="removeCliente"
    ></ClienteBuscadorSeleccionar>
    <ClienteDireccionEnvioSelect
      v-if="pedidoClienteVenta"
      :direccion="pedidoClienteDireccion"
      :esParaEnviar="pedidoEsParaEnviar"
      :cliente="pedidoClienteVenta"
      @set-direccion="setDireccion"
      @set-envio="setEnvio"
    ></ClienteDireccionEnvioSelect>
  </div>
</template>

<script>
import ClienteBuscadorSeleccionar from "@/components/cliente/ClienteBuscadorSeleccionar.vue";
import ClienteDireccionEnvioSelect from "@/components/cliente/ClienteDireccionEnvioSelect.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "PedidoVentaClienteDireccionEnvio",
  components: {
    ClienteBuscadorSeleccionar,
    ClienteDireccionEnvioSelect,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("pedidos", [
      "pedidoClienteVenta",
      "pedidoClienteDireccion",
      "pedidoEsParaEnviar",
    ]),
  },

  methods: {
    ...mapMutations("pedidos", [
      "SET_PEDIDO_CLIENTE_VENTA",
      "SET_PEDIDO_CLIENTE_DIRECCION",
      "SET_PEDIDO_ES_PARA_ENVIAR",
    ]),
    removeCliente() {
      this.SET_PEDIDO_CLIENTE_VENTA(null);
      this.SET_PEDIDO_CLIENTE_DIRECCION(null);
      this.SET_PEDIDO_ES_PARA_ENVIAR(false);
    },
    setCliente(cliente) {
      this.SET_PEDIDO_CLIENTE_VENTA(cliente);
    },
    setEnvio(bool) {
      this.SET_PEDIDO_ES_PARA_ENVIAR(bool);
    },

    setDireccion(direccion) {
      this.SET_PEDIDO_CLIENTE_DIRECCION(direccion);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>