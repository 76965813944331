<template>
  <v-dialog max-width="700" v-model="openDialogCategoria">
    <v-card>
      <v-card-title>
        Seleccione una categoria
        <v-spacer></v-spacer>
        <v-icon large @click="openDialogCategoria = false">mdi-close</v-icon>
      </v-card-title>
      <v-container>
        <keep-alive>
          <tree-categoria
            @processCategoria="seleccionarCategoria"
          ></tree-categoria>
        </keep-alive>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria.vue";
export default {
  name: "categoria-seleccionar-list",
  components: {
    TreeCategoria
  },
  data() {
    return {
      openDialogCategoria: true,
    };
  },
  methods: {
    seleccionarCategoria(data) {
      this.$emit('process-categoria',data);
      this.openDialogCategoria = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>