<template>
  <ProductoLayoutList>
     
    <template v-slot:list>
      <v-container fluid>
        <template v-if="products.length > 0">
          <v-row>
            <v-col cols="12" md="3" v-for="producto in productos" :key="producto.id">
             <productos-list-card-item @process-producto="actualizarProductos" :producto="producto"></productos-list-card-item>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-img contain height="200" src="@/assets/empty_icons/null_pedidos.svg"></v-img>
          <div class="text-center title" style="line-height: 1.6555">
            No se han encontraron resultados.
          </div>
          <div class="text-center">
            <li>Revisá los filtros aplicados.</li>
          </div>
        </template>
      </v-container>
    </template>

  </ProductoLayoutList>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import ProductoLayoutList from "@/components/producto/listar/ProductoLayoutList.vue";
import productosListCardItem from "./productos.list.card.item.vue";

export default {
  name: "productos.lista.card",
  components: { ProductoLayoutList, productosListCardItem },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
   
  },
  data() {
    return {
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
     
    };
  },
  computed: {
    productos() {
      return this.products;
    },
    ...mapState("sucursales", ["configuracion"]),
    inventario() {
      return this.configuracion.productoInventario;
    },

  },
  methods: {
    actualizarProductos(producto) {
      this.$emit("actualizar-producto", [producto]);
    },
    calcularPrecioPY(producto) {
      return Math.round(
        producto.precioVenta + (producto.precioVenta * producto.porcentajePY) / 100
      );
    },

  },
};
</script>
