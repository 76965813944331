import { render, staticRenderFns } from "./producto.filtro.marca.vue?vue&type=template&id=4cfa2212&scoped=true&"
import script from "./producto.filtro.marca.vue?vue&type=script&lang=js&"
export * from "./producto.filtro.marca.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cfa2212",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VList,VListItem,VListItemContent})
