<template>
  <div>
    <div class="title">
      <b>{{ tipoPlataforma.nombre }}</b>
    </div>
    <VentasVTable
      :ventas="ventas"
      @process-open="abrirVistaPrevia"
    ></VentasVTable>
    <VentaVistaPreviaDrawer
      v-if="dialogVistaPrevia"
      :model="dialogVistaPrevia"
      @close-dialog="drawerVistaPrevia = false"
      @set-drawer="setDrawer"
      :venta="ventaSelected"
      :isPlataforma="true"
    ></VentaVistaPreviaDrawer>

    <v-pagination
      v-if="ventas"
      @input="paginationChangeHandler"
      :length="pages"
      :value="paginacion.page"
      circle
    ></v-pagination>
  </div>
</template>

<script>
import VentasApi from "@/api/venta/venta.js";
import VentasVTable from "@/components/venta/listar/VentasVTable.vue";
import VentaVistaPreviaDrawer from "@/components/venta/listar/VentaVistaPreviaDrawer.vue";
export default {
  name: "ventasPlataformaList",
  components: { VentasVTable, VentaVistaPreviaDrawer },
  props: {
    sucursal_id: {
      type: Number,
      required: false,
      default: null,
    },
    tipoPlataforma: {
      type: Object,
      required: true,
      default: () => ({ nombre: "Pedidos Ya", key: "py" }),
    },
  },
  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  data() {
    return {
      loading: false,
      dialogVistaPrevia: false,
      ventas: [],
      ventaSelected: null,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 4 },
    };
  },
  methods: {
    setDrawer(value) {
      this.dialogVistaPrevia = value;
    },

    abrirVistaPrevia(venta) {
      this.dialogVistaPrevia = true;
      this.ventaSelected = venta;
    },
    closeVistaPrevia() {
      (this.dialogVistaPrevia = false), (this.ventaSelected = null);
    },
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.setPage(pageNumber);
      } else {
        this.setPage(pageNumber);
      }
    },
    setPage(pageNumber) {
      this.paginacion.page = pageNumber;

      this.fetchVentasPedidosYa();
    },
    async fetchVentasPedidosYa() {
      try {
        this.loading = true;
        if (this.tipoPlataforma.key == "py") {
          var response = await VentasApi.indexpy(this.paginacion);
        } else {
          this.loading = false;

          return;
        }
        this.ventas = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    sucursal_id(value) {
      this.fetchVentasPedidosYa();
    },
  },
  created() {
    this.fetchVentasPedidosYa();
  },
};
</script>

<style lang="scss" scoped>
</style>