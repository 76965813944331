<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-toolbar color="transparent" flat>
          <v-btn icon @click="$router.go(-1)">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>
            <h2>{{ fechaInicioActividad(arqueo) }}</h2>
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-row id="info-arqueo">
          <v-col>
            <v-card>
              <v-card-title>
                Información de la caja
                <v-spacer></v-spacer>
                <v-icon > {{arqueo.esVigente ? 'mdi-lock-open-variant' : 'mdi-lock'}}</v-icon>
             <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      text
                      icon
                      :color="diferenciaDeCaja(arqueo) == 0 ? 'green' : 'red'"
                    >
                      <v-icon>{{
                        diferenciaDeCaja(arqueo) == 0
                          ? "mdi-check-circle"
                          : "mdi-close-circle"
                      }}</v-icon>
                    </v-btn>
                  </template>

                  <span>{{
                    diferenciaDeCaja(arqueo) == 0
                      ? "La caja dió bien"
                      : "La caja no dió bien"
                  }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                 <v-subheader>Inicio de caja</v-subheader>
                <v-row>
                  <v-col>
                   
                    <v-list-item class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> mdi-account </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle> Usuario</v-list-item-subtitle>
                        <v-list-item-title>{{
                          arqueo.usuario.toUpperCase()
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> mdi-calendar-check </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle> Hora de inicio </v-list-item-subtitle>
                        <v-list-item-title>{{
                          horaInicioActividad(arqueo)
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <div class="">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar class="mr-1">
                      <v-icon dark> mdi-cash </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>Saldo inicial</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ arqueo.montoInicial | convertPesoArg }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>

                <div v-if="!arqueo.esVigente">
                  <v-subheader>Cierre de caja</v-subheader>
                    <v-row>
                
                  <v-col>
                    <v-list-item  class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> mdi-cash </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>Saldo final</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ arqueo.montoCierre | convertPesoArg }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                    <v-col>
                    <v-list-item  class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> mdi-calendar-lock </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>Hora de cierre </v-list-item-subtitle>
                        <v-list-item-title>
                          {{ horaCierreActividad(arqueo) }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row class="mt-0" v-if="diferenciaDeCaja(arqueo) !== 0">
                  <!-- <v-col cols="12" md="6">
                    <v-list-item  class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> mdi-cash-register </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-subtitle>Efectivo en caja</v-list-item-subtitle>

                        <v-list-item-title>
                          {{
                            arqueo.montoCierreConteo | convertPesoArg
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item></v-col
                  > -->
                  <v-col cols="12" md="6" >
                    <v-list-item dense class="pa-0">
                      <v-list-item-avatar class="mr-1">
                        <v-icon dark> {{ iconDiferenciaCaja(arqueo) }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{
                          textDiferenciaCaja(arqueo)
                        }}</v-list-item-subtitle>

                        <v-list-item-title>
                          {{
                            diferenciaDeCaja(arqueo) | convertPesoArg
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-list-item dense class="pa-0" v-if="diferenciaDeCaja(arqueo) !== 0">
                  <v-list-item-avatar class="mr-1">
                    <v-icon dark> mdi-comment</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>Comentarios</v-list-item-subtitle>

                    <v-list-item-title>
                      {{
                        arqueo.observaciones ? arqueo.observaciones : "Sin comentarios"
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                </div>
              </v-card-text>
            </v-card>
         
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="12">
            <ventas-por-forma-de-pago :ventas="arqueo.ventas"></ventas-por-forma-de-pago>
         
            <div class="title mt-2">Detalle de venta</div>
            <v-card>
              <v-card-text>
                <v-row align="start">
                  <v-col cols="4" md="2">
                    <v-list-item  class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Cantidad de ventas</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{ arqueo.ventas.cantidad ? arqueo.ventas.cantidad : 0 }}
                        </v-list-item-title>
                         <v-list-item-subtitle
                          class="text-center"
                        >
                          <span class="text-wrap text-caption">
                            <a @click="$router.push({name: 'detalleVentas',params: { arqueo: arqueo }})"> Ver más</a>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="4" md="3">
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Ventas anuladas</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{
                            arqueo.ventasAnuladas.length > 0
                              ? arqueo.ventasAnuladas.length
                              : 0
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="arqueo.ventasAnuladas.length > 0"
                          class="text-center"
                        >
                          <span class="text-wrap text-caption">
                            <a @click="verVentasAnuladas"> Ver ventas anuladas</a>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="4" md="3">
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Ventas facturadas</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{
                            arqueo.ventasFacturadas.length > 0
                              ? arqueo.ventasFacturadas.length
                              : 0
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="
                            arqueo.ventasFacturadas && arqueo.ventasFacturadas.length > 0
                          "
                          class="text-center"
                        >
                          <a @click="verventasFacturadas"> Ver ventas</a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="4" md="3">
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption">
                            Productos vendidos</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          <span> {{ arqueo.detallesComprobantes.length }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="arqueo.detallesComprobantes.length > 0"
                          class="text-center"
                        >
                          <a @click="verProductos"> Ver productos</a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div class="title mt-2">Movimientos de caja</div>

            <v-card>
              <v-card-text>
                <v-row align="start">
                  <v-col cols="6" md="3" class="pt-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Cantidad egresos</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{ arqueo.extracciones ? arqueo.extracciones.length : 0 }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6" md="3" class="pt-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Egresos</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{ arqueo.egresos | convertPesoArg }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="arqueo.egresos > 0"
                          class="text-center"
                        >
                          <span class="text-wrap text-caption">
                            <a @click="verEgresos"> Ver egresos</a>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Ingresos</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{ arqueo.ingresos | convertPesoArg }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog width="650px" persistent scrollable v-model="dialogEgresos">
      <v-card>
        <v-card-title>
          Egresos
          <v-spacer></v-spacer>
          <v-btn @click="dialogEgresos = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(retiro, index) in arqueo.extracciones" :key="index">
              <v-list-item-action> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Retiro</v-list-item-title>
                <v-list-item-subtitle
                  ><span class="text-primary">{{ retiro.descripcion }} </span>
                  -
                  {{ moment(retiro.fechaAlta).format("HH:mm") }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-icon>
                {{ retiro.monto | convertPesoArg }}
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-for="(retiro, index) in arqueo.otrosEgresos" :key="index">
              <v-list-item-action> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ retiro.tipoIngresoEgreso }}</v-list-item-title>
                <v-list-item-subtitle
                  ><span class="text-primary">{{ retiro.descripcion }}</span
                  >{{ moment(retiro.fechaAlta).format("HH:mm") }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-icon>
                {{ retiro.monto | convertPesoArg }}
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="650px"
      v-if="dialogVentasAnuladas"
      persistent
      scrollable
      v-model="dialogVentasAnuladas"
    >
      <v-card>
        <v-card-title>
          Ventas Anuladas
          <v-spacer></v-spacer>
          <v-btn @click="dialogVentasAnuladas = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            calculate-widths
            v-if="arqueo.ventasAnuladas.length > 0"
            :headers="headers"
            :items="arqueo.ventasAnuladas"
            :expanded.sync="expanded"
            hide-default-footer
            item-key="id"
            show-expand
            :single-expand="true"
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="2">
                <v-list dense>
                  <v-list-item
                    dense
                    v-for="detalle in item.detalles_comprobante"
                    :key="detalle.id"
                  >
                    <ul>
                      <li>
                        <v-list-item-icon>
                          <span style="color: grey">{{ detalle.cantidad }}x</span>
                        </v-list-item-icon>
                      </li>
                    </ul>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="color: grey">{{
                          detalle.producto.descripcion
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action> </v-list-item-action>
                  </v-list-item>
                </v-list>
              </td>
            </template>
            <template v-slot:item.fechaAlta="{ item }">
              {{ moment(item.fechaAlta).format("HH:mm") }}
            </template>
            <template v-slot:item.fechaUltModificacion="{ item }">
              {{ moment(item.fechaUltModificacion).format("HH:mm") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="650px" persistent scrollable v-model="dialogProductosVendidos">
      <v-card>
        <v-card-title>
          Productos vendidos
          <v-spacer></v-spacer>
          <v-btn @click="dialogProductosVendidos = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-list dense>
            <v-list-item v-for="detalle in arqueo.detallesComprobantes" :key="detalle.id">
              <v-list-item-action> </v-list-item-action>
              <ul>
                <li>
                  <v-list-item-icon>
                    <span>{{ detalle.cantidad }}x</span>
                  </v-list-item-icon>
                </li>
              </ul>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ detalle.nombre }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action> </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="650px"
      v-if="dialogVentasFacturar"
      persistent
      scrollable
      v-model="dialogVentasFacturar"
    >
      <v-card>
        <v-card-title>
          Ventas para facturar
          <v-spacer></v-spacer>
          <v-btn @click="dialogVentasFacturar = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            calculate-widths
            v-if="arqueo.ventasFacturadas.length > 0"
            :headers="headersParaFacturar"
            :items="arqueo.ventasFacturadas"
            :expanded.sync="expanded"
            hide-default-footer
            item-key="id"
            show-expand
            :single-expand="true"
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="2">
                <v-list dense>
                  <v-list-item
                    dense
                    v-for="detalle in item.detalles_comprobante"
                    :key="detalle.id"
                  >
                    <ul>
                      <li>
                        <v-list-item-icon>
                          <span style="color: grey">{{ detalle.cantidad }}x</span>
                        </v-list-item-icon>
                      </li>
                    </ul>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="color: grey">{{
                          detalle.producto.descripcion
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action> </v-list-item-action>
                  </v-list-item>
                </v-list>
              </td>
            </template>
            <template v-slot:item.fechaAlta="{ item }">
              {{ moment(item.fechaAlta).format("HH:mm") }}
            </template>
            <template v-slot:item.fechaUltModificacion="{ item }">
              {{ moment(item.fechaUltModificacion).format("HH:mm") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VentasPorFormaDePago from '@/components/reportes/VentasPorFormaDePago.vue';
export default {
  components: { VentasPorFormaDePago },
  name: "reporte.resumen.arqueo",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },

  data() {
    return {
      arqueo: null,
      dialogVentasAnuladas: false,
      dialogVentasFacturar: false,
      dialogProductosVendidos: false,
      dialogEgresos: false,
      expanded: [],

      headersParaFacturar: [
        {
          text: "Hora",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Nro", value: "numeroVentaPorArqueo" },

        { text: "Tipo comprobante", value: "letra", align: "center" },
        { text: "Forma de pago", value: "formaDePago.nombre" },

        { text: "Total", value: "total" },
      ],
      headers: [
        {
          text: "Hora creación",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Hora anulación", value: "fechaUltModificacion" },
        { text: "Total", value: "total" },
        { text: "Motivo", value: "motivoAnulacion.descripcion" },
        { text: "Comentario", value: "comentarioMotivoAnulacion" },
      ],
    };
  },
  methods: {
    verventasFacturadas() {
      this.dialogVentasFacturar = true;
    },
    verEgresos() {
      this.dialogEgresos = true;
    },
    verVentasAnuladas() {
      this.dialogVentasAnuladas = true;
    },
    verProductos() {
      this.dialogProductosVendidos = true;
    },

    fechaInicioActividad(arqueo) {
      return this.moment(arqueo.fechaInicio).format("LLL");
    },
    fechaCierreActividad(arqueo) {
      return this.moment(arqueo.fechaCierre).format("LLL");
    },
    horaCierreActividad(arqueo) {
      return this.moment(arqueo.fechaCierre).format("HH:mm");
    },

    horaInicioActividad(arqueo) {
      return this.moment(arqueo.fechaInicio).format("HH:mm");
    },
    textDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "Está faltando";
      } else {
        return "Está sobrando";
      }
    },
    iconDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "mdi-cash-minus";
      } else {
        return "mdi-cash-plus";
      }
    },
    diferenciaDeCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return arqueo.montoCierre - arqueo.montoCierreConteo;
      } else {
        return arqueo.montoCierreConteo - arqueo.montoCierre;
      }
    },
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          this.$router.go(-1);
        } else {
          if (params.arqueo) {
            this.arqueo = params.arqueo;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
