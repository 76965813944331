<template>
  <v-list dense v-if="cliente">
    <v-checkbox
      v-show="!esParaEnviar"
      label="Enviar pedido"
      v-model="localEsParaEnviar"
    >
    </v-checkbox>
    <template v-if="esParaEnviar">
      <v-list-item>
        <v-list-item-title>Detalles de entrega</v-list-item-title>
        <v-list-item-action v-show="esParaEnviar">
          <v-btn small text @click="removeDireccion()">
            <v-icon>mdi-swap-horizontal</v-icon>Para retirar</v-btn
          >
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-if="!direccion">
        <v-list-item-content>
          <cliente-direccion-create
            @refresh-direcciones="getDireccionFavorita"
            :cliente_id="cliente.id"
          ></cliente-direccion-create>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-menu
      v-if="esParaEnviar && direccion"
      v-model="isDialogDirecciones"
      :disabled="false"
      :close-on-click="false"
      :nudge-width="150"
      offset-y
      close
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-on="on">
          <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ direccion.nombre }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ direccion.calle }}{{ direccion.altura }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-icon :color="direccion.is_favorita ? 'yellow darken-3' : ''">
            mdi-star
          </v-icon>
        </v-list-item>
      </template>
      <cliente-direccion-seleccion
        :cliente="cliente"
        @process-direccion="setDireccion"
        @close-menu="setDialogDirecciones(false)"
        @open-menu="setDialogDirecciones(true)"
      ></cliente-direccion-seleccion>
    </v-menu>
  </v-list>
</template>

<script>
import ClienteDireccionSeleccion from "@/components/cliente/direcciones/cliente.direccion.seleccion.vue";
import ClienteDireccionCreate from "@/views/cliente/direcciones/cliente.direccion.create.vue";

import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";

export default {
  name: "",
  components: {
    ClienteDireccionSeleccion,
    ClienteDireccionCreate,
  },
  props: {
    cliente: {
      type: Object,
      required: true,
    },
    direccion: {
      type: [Object, null],
    },
    esParaEnviar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDialogDirecciones: false,
      localEsParaEnviar: this.esParaEnviar,
    };
  },

  methods: {
    setDialogDirecciones(bool) {
      this.isDialogDirecciones = bool;
    },
    setEsParaEnviar(bool) {
      this.localEsParaEnviar = bool;
      this.$emit("set-envio", bool);
    },
    removeDireccion() {
      this.$emit("set-direccion", null);
      this.setEsParaEnviar(false);
      this.setDialogDirecciones(false);
    },
    setDireccion(direccion) {
      this.$emit("set-direccion", direccion);
      this.setDialogDirecciones(false);
    },
    getDireccionFavorita() {
      ClienteDireccionesAPI.get_direccion_favorita(this.cliente.id).then(
        (response) => {
          this.setDireccion(response.data);
        }
      );
    },
  },
  watch: {
    localEsParaEnviar(val) {
      if (val) {
        this.$emit("set-envio", val);
        this.getDireccionFavorita();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>