<template>
  <v-form>
    <v-text-field
          outlined
          autofocus
          v-model="empleado.nombre"
          label="Nombre"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('nombre')"
          data-vv-name="nombre"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="empleado.apellido"
          label="Apellido"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('apellido')"
          data-vv-name="apellido"
          required
        ></v-text-field>
         <v-text-field
          outlined
          v-model="empleado.documento"
          label="DNI"
          v-validate="'required|numeric|min:7|max:8'"
          :error-messages="errors.collect('dni')"
          data-vv-name="dni"
          required
        ></v-text-field>


    <v-btn block :disabled="errors.any()" @click="submit" color="success">
      Registrar</v-btn
    >
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";

export default {
  name: "empleado.register.form",
  inject: {
    $validator: "$validator",
  },
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
  },
  props: {
    empleado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {},
  methods: {
   
    async submit() {
      this.$emit("process-register", this.empleado);
    },
  },
};
</script>

<style lang="scss" scoped></style>
