<template>
  <LayoutBasic>
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:title>
      <v-list class="ma-0 ml-2 pa-0">
        <v-list-item class="pl-1">
          <v-list-item-title>
            <h1>Promociones</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <div class="title">Disponibles</div>

        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="3"
            v-for="promocion in promociones"
            :key="promocion.id"
          >
            <promocion-item-card
              @process-promocion="verDetallePromocion"
              :promocion="promocion"
            >
              <template v-slot:chip-estado> </template>
            </promocion-item-card>
          </v-col>
        </v-row>
        <template v-if="promocionesVencidas.length > 0">
          <div>Vencidas</div>

          <v-row>
            <v-col
              cols="12"
              md="3"
              lg="3"
              v-for="promocion in promocionesVencidas"
              :key="promocion.id"
            >
              <promocion-item-card
                @process-promocion="verDetallePromocion"
                :promocion="promocion"
              >
                <template v-slot:chip-estado>
                  <v-chip small color="warning">
                    <v-avatar left>
                      <v-icon>mdi-clock</v-icon>
                    </v-avatar>

                    Vencida</v-chip
                  >
                </template>
              </promocion-item-card>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/productos/layout.registrar.producto";
import PromocionItemCard from "@/views/promocion/promocion.item.card.vue";

export default {
  name: "promocion.home",
  components: {
    LayoutBasic,
    PromocionItemCard,
  },
  data() {
    return {
      promociones: [],
      promocionesVencidas: [],

      item: null,
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
    };
  },
  computed: {
    items() {
      return [
        {
          text: "Nueva promocion",
          icon: "mdi-clock",
          route: "promocionNueva",
        },
      ];
    },
  },
  methods: {
    async eliminarPromocion(promo) {
      const response = await this.axios.delete(`promociones/${promo.id}`);
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    verDetallePromocion(promocion) {
      var id = promocion.id;
      this.$router.push({ name: "detallePromocion", params: { id: id } }).catch(() => {});
      // this.$router.push( { name: 'home', params: { id: id}})
    },
    async fetchPromociones() {
      const response = await this.axios.get("/promociones/");
      const response2 = await this.axios.get("/promociones?vencidas=true");

      this.promociones = response.data;
      this.promocionesVencidas = response2.data;
    },
  },
  created() {
    this.fetchPromociones();
  },
};
</script>

<style lang="scss" scoped></style>
