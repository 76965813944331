<template>
  <layout-registrar-producto v-if="producto">
    <template v-slot:up-title>
      <span></span>
    </template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <h1 style="font-size: 30px" class="text-wrap">
                {{ producto.descripcion }} {{ producto.id }}
              </h1>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span style="font-size: 15px" class="text-wrap">
                {{ producto.nombre_sucursal }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-container>
        <v-list>
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-title>
                <h1 style="font-size: 30px" class="text-wrap">
                  {{ producto.cantidad_actual }}
                </h1>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span style="font-size: 15px" class="text-wrap">
                  Stock actual
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-wrap"> Modificacíon manual </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <ProductoStockManager
          :stock_parameters="stock_parameters"
          :producto="producto"
          @nuevoMovimientoStock="handleNuevoMovimientoStock"
        ></ProductoStockManager>
      </v-container>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <!-- <v-btn v-show="step == 1" small class="mb-2" color="error" block
                    @click="$router.push({ name: 'actualizarProducto', params: { id: producto.id, producto: producto } })">
                    <b >Eliminar
                    </b>
                </v-btn> -->
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-row
        align="start"
        justify="center"
        class="mt-2 mx-auto"
        v-if="movimientos_stock.length > 0"
        
      >
        <v-col cols="10">
          <v-data-table
            :headers="headers"
            :items="movimientos_stock"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Historial de cambios de Stock</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:item.cantidad="{ item }">
              <v-chip
                label
                class="ma-2 pa-2 text-capitalize"
                :color="item.cantidad > 0 ? 'green' : 'red'"
              >
                <v-avatar left>
                  <v-icon
                    v-text="
                      item.cantidad > 0 ? 'mdi-arrow-up' : 'mdi-arrow-down'
                    "
                  ></v-icon>
                </v-avatar>
                {{ item.cantidad }}
              </v-chip>
            </template>
            <template v-slot:item.fecha="{ item }">
              {{ moment(item.fecha).format("DD MMM HH:mm") }}
            </template>
          </v-data-table>
          <v-btn v-if="next_page" @click="fetchNextPage" class="mt-2">
            Más
          </v-btn>

          <v-timeline v-if="false" align-top dense>
            <v-timeline-item
              v-for="movimiento in movimientos_stock"
              :key="movimiento.id"
              :color="movimiento.cantidad > 0 ? 'green' : 'red'"
              fill-dot
            >
              <template v-slot:icon>
                <span>{{ movimiento.cantidad }}</span>
              </template>
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>Actual: {{ movimiento.cantidad_actual }} </strong>
                </v-col>
                <v-col>
                  <v-hover v-slot="{ hover }" close-delay="100">
                    <v-card
                      :color="hover ? '#E91E62' : ''"
                      :elevation="hover ? 16 : 2"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-container>
                        <v-list-item class="grow">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              <span style="font-size: 20px"
                                ><v-icon>mdi-arrow-left</v-icon
                                ><strong>{{
                                  movimiento.cantidad
                                }}</strong></span
                              >
                            </v-list-item-subtitle>
                            <v-list-item-title>
                              <div class="text-caption mb-2">Movimiento</div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-row>
                            <v-col>
                              <strong>{{
                                moment(movimiento.fecha).format("lll")
                              }}</strong>
                              <div class="text-caption mb-2">Descripcion</div>
                            </v-col>
                            <v-col>
                              <strong>{{ movimiento.descripcion }}</strong>
                              <div class="text-caption mb-2">Descripcion</div>
                            </v-col>
                            <v-col>
                              <strong>{{
                                movimiento.motivo_cambio.descripcion
                              }}</strong>
                              <div class="text-caption mb-2">Motivo</div>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-list-item class="grow">
                          <v-list-item-avatar color="grey darken-3">
                            <v-img
                              class="elevation-6"
                              alt=""
                              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              movimiento.usuario
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item v-if="next_page" class="mb-4" hide-dot>
              <v-btn @click="fetchNextPage()" class="mx-0"> Más </v-btn>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row align="start" justify="center" class="mt-2 mx-auto" v-else>
        <h2>Sin historial</h2>
      </v-row>
    </template>
  </layout-registrar-producto>
</template>
  
<script>
import { administrarMixin } from "@/mixins/producto/administrarMixin.js";
import LayoutRegistrarProducto from "@/layouts/productos/layout.registrar.producto";
import ProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";

import ProductoItem from "@/components/producto/producto.item.vue";

import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";
import ProductoHistorialItem from "@/components/producto/ProductoHistorialItem.vue";
import ProductoStockManager from "@/components/producto/inventario/ProductoStockManager.vue";

export default {
  name: "ProductoItemInventario",
  components: {
    ProductoItem,
    ProductoStockManager,
    LayoutRegistrarProducto,

    ProductoHistorialItem,
  },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [administrarMixin],
  data() {
    return {
      loadingFetch: false,
      producto: null,
      movimientos_stock: [],
      stock_parameters: null,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },
      next_page: false,
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Movimiento", value: "cantidad" },
        { text: "Descripción", value: "descripcion" },
        { text: "Motivo del Cambio", value: "motivo_cambio" },
        { text: "Comentario", value: "comentario" },
        { text: "En stock", value: "cantidad_actual" },
        { text: "Usuario", value: "usuario" },
      ],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },

  methods: {
    /**
     * Añade un nuevo movimiento de stock al principio de la lista de movimientos.
     *
     * @param {Object} movimientoStock - El objeto que contiene los detalles del nuevo movimiento de stock.
     */
    handleNuevoMovimientoStock(movimientoStock) {
      this.movimientos_stock.unshift(movimientoStock);
    },

    fetchNextPage() {
      this.paginacion.page = this.paginacion.page + 1;
      this.fetchInventarioProducto(this.producto.id);
    },
    fetchInventarioProducto(id) {
      console.log(id);
      this.loadingFetch = true;
      ProductosAPI.indexInventario(id, this.paginacion)
        .then((response) => {
          // Verificar si hay próxima página
          this.next_page = response.data.next ? true : false;

          
          console.log("response.data.results",response.data.results);
          console.log("this.movimientos_stock.length > 0", this.movimientos_stock.length > 0);
          console.log("this.movimientos_stock", this.movimientos_stock);
          // Verificar si hay resultados para concatenar
          if (response.data.results && response.data.results.length > 0) {
            // Concatenar los resultados a movimientos_stock
            if (this.movimientos_stock.length > 0) {
              this.movimientos_stock = this.movimientos_stock.concat(
                response.data.results
              );
            } else {
              this.movimientos_stock = response.data.results;
            }
          }

          // Asignar otros datos de respuesta
          this.producto = response.data.producto;
          this.stock_parameters = response.data.stock_parameters;

          this.loadingFetch = false;
        })
        .catch((error) => {
          console.error("Error al obtener inventario:", error);
          this.loadingFetch = false;
        });
    },

    changeParam(id) {
      this.$router.replace({
        name: "productoHome",
        params: { id_producto: id },
      });
      this.fetchInventarioProducto(id);
    },
  },
  created() {
    if (this.$route.params.producto === undefined) {
      this.fetchInventarioProducto(this.$route.params.id_producto);
    } else {
      this.loadingFetch = true;
      var producto = this.$route.params.producto;

      this.fetchInventarioProducto(producto.id);
      this.loadingFetch = false;
    }
  },
};
</script>
  
<style scoped>
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}

.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}

.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}

.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
  