var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"row-pointer transparent",attrs:{"headers":_vm.headers,"items":_vm.pedidos,"no-data-text":"No hay pedidos","hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.fechaAlta",fn:function(ref){
var item = ref.item;
return [(_vm.esVenta)?_c('span',[_vm._v(_vm._s(_vm.moment(item.fechaAlta).startOf("minutes").fromNow()))]):_c('span',[_vm._v(_vm._s(_vm.moment(item.fechaAlta).format("lll")))])]}},{key:"item.estaPagado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.estaPagado ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.estaPagado ? "Pagado" : "No pagado")+" ")])]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [(item.cliente)?_c('v-chip',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('span',[_vm._v(_vm._s(item.cliente.full_name))])],1):_vm._e(),(item.nombreEntidad)?_c('span',[_vm._v(_vm._s(item.nombreEntidad.cliente.nombre))]):_vm._e()]}},{key:"item.paraEnviar",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":!item.paraEnviar}},[_vm._v(" "+_vm._s(item.paraEnviar ? "mdi-truck-delivery" : "mdi-home")+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogEstado),callback:function ($$v) {_vm.dialogEstado=$$v},expression:"dialogEstado"}},[_c('estado-select',{attrs:{"estados":_vm.estados}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }