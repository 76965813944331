<template>
  <v-container>
    <v-card>
      <v-btn @click="generarArchivoExcel" large> Generar archivo excel</v-btn>
      <v-tabs v-model="tab" background-color="" dark>
        <v-tab>Productos</v-tab>
        <v-tab>Productos con fracciones</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-container>
              <small
                >* Solo se mostrarán los proveedores que tienen el módulo
                cargado.</small
              >
              <small>
                <a>Más info</a>
              </small>
              <v-row>
                <v-col cols="4">
                  <proveedor
                    :solo="true"
                    :isForArchivo="false"
                    placeholder="Proveedor"
                    @set_proveedor="setProveedor"
                  ></proveedor>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5" v-if="id_proveedor">
                  <v-card color="light-green accent-1">
                    <v-container>
                      <div>
                        Última lista de precios subida el
                        <i class="subtitle">28/8/20</i>
                      </div>
                      <div>
                        Nombre:
                        <b>Lista de Precios 30-08-2019.xls.</b>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-card>
                    <v-container>
                      <v-row>
                        <div>
                          <small
                            >* La ganancia y el % de flete se aplicarán a todos
                            los productos de la lista</small
                          >
                        </div>
                        <v-col cols="4">
                          Ganancia
                          <v-text-field
                            outlined
                            v-model="ganancia"
                            id="ganancia"
                            v-validate="'required|decimal:2|max:30'"
                            :counter="30"
                            :error-messages="errors.collect('ganancia')"
                            data-vv-name="ganancia"
                            required
                            type="number"
                            prefix="%"
                            min="0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          Flete
                          <v-text-field
                            outlined
                            v-model="flete"
                            id="fletes"
                            v-validate="'required|integer|max:5'"
                            :counter="30"
                            :error-messages="errors.collect('flete')"
                            data-vv-name="flete"
                            required
                            type="number"
                            min="0"
                            prefix="%"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="mt-4">
                  <v-file-input
                    v-model="files"
                    :loading="loading"
                    :rules="rules"
                    accept=".xls, .xlsx, .ods"
                    placeholder="Selecciona un archivo"
                    label="Archivo"
                    clearable
                    loader-height="4"
                  ></v-file-input>
                </v-col>
                <v-col class="mt-3" cols="1">
                  <v-btn
                    v-if="!isArchivoSubido && !loading && files"
                    @click="generarArchivoExcel"
                    color="primary"
                    fab
                    dark
                  >
                    <v-icon>mdi-arrow-up-bold-box</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!isArchivoSubido && !loading && files"
                    @click="registrarExcelProductosFraccionados"
                    color="primary"
                    dark
                  >
                    Registrar fracciones
                  </v-btn>
                  
                  <v-progress-circular
                    v-if="loading || isArchivoSubido"
                    :rotate="-90"
                    :size="80"
                    :width="15"
                    :value="tiempoProcesamiento"
                    :color="isArchivoSubido ? 'success' : 'primary'"
                    >{{ tiempoProcesamiento }} %</v-progress-circular
                  >

                  <v-btn
                    v-if="isErrorSubirArchivo"
                    :color="isErrorSubirArchivo ? 'error' : 'success'"
                    fab
                    dark
                  >
                    <v-icon
                      v-text="
                        isErrorSubirArchivo
                          ? 'mdi-emoticon-sad-outline'
                          : 'mdi-emoticon-outline'
                      "
                    ></v-icon>
                  </v-btn>

                  <!-- <v-btn v-else @click="procesarArchivo()" class="mx-2" fab dark large color="cyan"> -->
                  <!-- <v-icon dark>mdi-pencil</v-icon> -->
                </v-col>
                <v-col
                  v-if="isArchivoSubido && !isErrorSubirArchivo"
                  class="mt-6"
                >
                  <span class="title green--text">
                    <b>¡ {{ messageExito }} !</b>
                  </span>
                </v-col>
                <v-col v-if="isErrorSubirArchivo" class="mt-6">
                  <span class="title green--text">
                    <b>¡ {{ messageError }} !</b>
                  </span>
                </v-col>
              </v-row>

              <v-checkbox v-model="reset" label="Resetear productos"></v-checkbox>
               <v-checkbox v-model="deleteProducts" label="Eliminar productos"></v-checkbox>
            </v-container>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-container>
              <v-row>
                <v-col cols="12">Próximamente</v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import proveedor from "@/components/proveedor/FormSelectProveedor";
export default {
  name: "productos.listadeprecios",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    proveedor,
  },

  data() {
    return {
      tab: null,
      loading: false,
      files: null,
      ganancia: 0,
      flete: 0,
      id_proveedor: null,
      id_hilo: null,
      isArchivoSubido: false,
      isErrorSubirArchivo: false,
      messageError: null,
      messageExito: null,
      estado: 0,
      reset: false, // Bandera que elimina todos los productos de la lista de precios de proveedor
      deleteProducts: false, // Bandera que elimina todos los productos del proveedor

      rules: [
        (value) =>
          !value ||
          value.size > 5000 ||
          "El archivo debe pesar como mínimo 5kB!",
      ],
    };
  },
  computed: {
    tiempoProcesamiento() {
      if (this.estado) {
        return parseInt((this.estado.procesado * 100) / this.estado.total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    setProveedor(data) {
      this.id_proveedor = data.id;
    },

    subirArchivo() {
      if (this.id_proveedor) {
        this.loading = true;
        var form_data = new FormData();

        ///////////////////////PARA DESCARGAR PDF ///////////////////////
        /*const parametros = {grupo : ["categoriaArbol"],filtro : {}}
        const headers = {responseType: 'blob',observe: 'response'}
        this.axios
          .post("/reporte/0",parametros,headers)
          .then(response => {
             const a = document.createElement('a');
             a.href = URL.createObjectURL(response.data);
             a.download = response.headers['content-disposition'].replace("inline;filename=", '');
             a.click();
           })
           .catch(e => {
             this.isErrorSubirArchivo = true;
             this.messageError = "Error al cargar el archivo";
           });*/
        ////////////////////////// TERMINA ///////////////////////////////////

        form_data.append("file", this.files);
        form_data.append("proveedor", this.id_proveedor);

        this.axios
          .post("/proveedores/listasdeprecios/upload", form_data)
          .then((response) => {
            this.procesarArchivo();
          })
          .catch((e) => {
            this.isErrorSubirArchivo = true;
            this.messageError = "Error al cargar el archivo";
          });
      } else {
        alert("Seleccione un proveedor");
      }
    },

    generarArchivoExcel() {
      if (this.id_proveedor) {
        this.loading = true;
        var form_data = new FormData();
        form_data.append("proveedor", this.id_proveedor);
        form_data.append("file", this.files);

        this.axios
          .post("/productos/generarExcelProductosFraccionados", form_data, { responseType: 'blob' })

          .then((res) => {
            console.log("generarArchivoExcel", res);
            const url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            const [, filename] = res.headers['content-disposition'].split('filename=');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((e) => {
            console.log(e);
            this.isErrorSubirArchivo = true;
            this.messageError = "Error al cargar el archivo";
          });
      } else {
        alert("Selecciona un proveedor");
      }

    },
    registrarExcelProductosFraccionados() {
      if (this.id_proveedor) {
        this.loading = true;
        var form_data = new FormData();
        form_data.append("proveedor", this.id_proveedor);
        form_data.append("file", this.files);
        form_data.append("reset", this.reset);
        form_data.append("delete", this.deleteProducts);





        this.axios
          .post("/productos/registrarExcelProductosFraccionados", form_data, { responseType: 'blob' })

          .then((res) => {
            console.log("generarArchivoExcel", res);
            const url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            const [, filename] = res.headers['content-disposition'].split('filename=');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((e) => {
            console.log(e);
            this.isErrorSubirArchivo = true;
            this.messageError = "Error al cargar el archivo";
          });
      } else {
        alert("Selecciona un proveedor");
      }
    },
    procesarArchivo() {
      this.axios
        .post("/productos/procesarlista", {
          nombre: this.files.name,
          id_proveedor: this.id_proveedor,

          ganancia: this.ganancia,
          flete: this.flete,
        })
        .then((response) => {
          this.id_hilo = response.data.TareaID;

          this.verificarEstado();
        })
        .catch((e) => {
          this.isErrorSubirArchivo = true;
          this.messageError = "Error al procesar el archivo";
        });
    },
    verificarEstado() {
      this.axios
        .get(`/productos/estadoprocesamiento/${this.id_hilo}`)
        .then((response) => {
          this.estado = response.data;

          if (response.data.estado == 0) {
            setTimeout(() => {
              this.verificarEstado();
            }, 1325);
          } else {
            this.estado.procesado = this.estado.total - 1;
            setTimeout(() => {
              this.estado.procesado = this.estado.total;
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                color: "success",
                text: "Lista de precio subida con éxito",
                icon: "mdi-bell-ring",
                timeout: 4000,
              });
              this.messageExito = "Archivo subido con éxito";
              this.isArchivoSubido = true;

              this.loading = false;
            }, 2000);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>