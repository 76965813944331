<template>
  <v-card flat>
    <v-container>
      <v-card-text>
        <v-text-field
          outlined
          autofocus
          v-model="empleado.nombre"
          label="Nombre"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('nombre')"
          data-vv-name="nombre"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="empleado.apellido"
          label="Apellido"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('apellido')"
          data-vv-name="apellido"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="empleado.email"
          label="Email"
          v-validate="'required|email'"
          :error-messages="errors.collect('email')"
          data-vv-name="email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="empleado.telefono"
          label="Telefono"
          v-validate="'required|numeric|min:7|max:30'"
          :error-messages="errors.collect('telefono')"
          data-vv-name="telefono"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="empleado.documento"
          label="DNI"
          v-validate="'required|numeric|min:7|max:8'"
          :error-messages="errors.collect('dni')"
          data-vv-name="dni"
          required
        ></v-text-field>
        <!-- <date-picker-date-of-birth
          @process-date-birthday="setDateOfBirth"
        ></date-picker-date-of-birth> -->


      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn text color="grey" @click="$emit('close-dialog')">Cancelar</v-btn>

          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{ textButton }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";
import DatePickerDateOfBirth from "@/util/DatePickerDateOfBirth.vue";

export default {
  name: "empleado.form",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
    DatePickerDateOfBirth,
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      loading: false,
      searchInput: "",
    

    };
  },

  props: {
    empleado: {
      type: Object,
      required: true,
    },
   
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    setDateOfBirth(date) {
      this.empleado.fechaNacimiento = date;
    },
    
   

    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-empleado", this.empleado);
        } else {
          this.$validator.validate();
        }
      });
    },
    
  },
 

 
  
};
</script>

<style lang="scss" scoped></style>
