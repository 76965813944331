<template>
  <div v-if="marcas.length">
    <v-list v-if="type == 'list'" dense class="ml-2">
      <v-list-item>
        <v-list-item-content>
          <marca-list-select
            v-show="marcas.length > 0"
            :marcas="marcas"
            @set_marca="setMarca"
          ></marca-list-select>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="type == 'checkbox'">
      <span class="text-body-2"><b>Marca</b></span>
      <v-checkbox
        class="pb-0 pt-0"
        dense
        v-for="(item, i) in marcas"
        :key="i"
        :label="`${item.nombre}`"
        multiple
        v-model="selected"
        :value="item"
        @change="setMarca(item, $event)"
      >
      </v-checkbox>
    </div>
  </div>
</template>

<script>
import MarcaListSelect from "@/components/marcas/MarcaListSelect";
import { mapGetters } from "vuex";
export default {
  name: "producto.filtro.marca",
  components: {
    MarcaListSelect,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
  },
  methods: {
   
    setMarca(marca) {
      this.$emit("select-marca", marca);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>