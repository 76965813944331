<template>
   <ProductoLayoutList>
     
     <template v-slot:list>
    <v-data-table
      :loading="loading"
      v-model="selectedProduct"
      :headers="headers"
      :items="productos"
      :search="search"
      item-key="id"
      :show-select="productos_admin_seleccion_manual"
      :hide-default-footer="true"
      disable-pagination
      no-data-text="No se encontraron productos"
      no-results-text="Sin coincidencia para la búsqueda"
      :calculate-widths="true"
    >
      <template v-slot:headers="props">
        <tr>
          <th>
            <v-checkbox
              :input-value="props.all"
              :indeterminate="props.indeterminate"
              primary
              hide-details
              @click.stop="toggleAll"
            ></v-checkbox>
          </th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
            ]"
            @click="changeSort(header.value)"
          >
            <v-icon small>arrow_upward</v-icon>
            <h2>{{ header.text }}</h2>
          </th>
        </tr>
      </template>
      <template v-slot:item.cantidadstock="{ item }">
        <v-chip :color="getColor(item)" dark>
          {{ item.cantidadstock }}
        </v-chip>
      </template>
      <template v-slot:item.fechaActualizacion="{ item }">
        {{ moment(item.fechaActualizacion).format("lll") }}
      </template>
    </v-data-table>
   
     </template>
   </ProductoLayoutList>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ProductoLayoutList from "@/components/producto/listar/ProductoLayoutList.vue"
export default {
  name: "producots.list.table.update",
  components: {ProductoLayoutList},
  props: {
    productos: {
      type: Array,
      required: true,
      default: () => [],
    },
    
   
  },
  data: () => ({
    selected: [],

    search: "",
    loading: false,

  }),
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    ...mapState("productos", ["paginacion_productos_admin", "productos_admin_seleccion_manual", "productos_admin_selected"]),


      selectedProduct: {
      get() {
        return this.productos_admin_selected;
      },
      set(val) {
        this.SET_PRODUCTOS_ADMIN_SELECTED(val)
        return 
      }
      },
   
    headers() {
      return [
        {
          text: "Código",
          value: "codigo",
          align: "center",
        },
        {
          text: "Descripcion",
          value: "descripcion",
          align: "center",
        },
        {
          text: "Categoria",
          value: "categoriaArbol.name",
          align: "center",
        },
        {
          text: "Marca",
          value: "marca.nombre",
          align: "center",
        },
        {
          text: "Proveedor",
          value: "proveedor.nombre",
          align: "center",
        },
        {
          text: "P. Costo",
          value: "precioCosto",
          align: "center",
        },
        {
          text: "P. Venta",
          value: "precioVenta",
          align: "center",
        },
        {
          text: "Ganancia",
          value: "ganancia",
          align: "center",
        },
        {
          text: "Ult. Actualización",
          value: "fechaActualizacion",
          align: "center",
        },
      ];
    },
  },

  methods: {
    ...mapMutations("productos", [
      "set_page_productos_admin",
      "SET_SELECCION_MANUAL_PRODUCTOS_ADMIN",
      "SET_PRODUCTOS_ADMIN_SELECTED"
    ]),

    ...mapActions("productos", [
      "get_productos_for_filters",
    ]),
    cleanSelected() {
      this.selected = [];
    },
    
    getColor(producto) {
      var cantidadstock = parseInt(producto.cantidadstock);

      var stockmin = parseInt(producto.stockmin);

      var stockmax = parseInt(producto.stockmax);

      var stockPromedio = (stockmin + stockmax) / 2;

      var stockLimiteAprobado = stockmin + stockmin * 0.2;
      console.log("cantidadstock", cantidadstock);
      console.log("stockLimiteAprobado", stockLimiteAprobado);

      if (cantidadstock > stockLimiteAprobado) return "green";
      else if (stockLimiteAprobado < cantidadstock && cantidadstock > stockmin)
        return "orange";
      else return "red";
    },
   
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.productos.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

  

    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style >
.v-datatable thead {
  background-color: rgb(190, 65, 65);
}

.table-scroll-wrapper {
  overflow: auto;
  border: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll {
  margin: 0px;
  border: none;
}

.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-scroll tr td:first-child,
.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 0;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:first-child {
  z-index: 3;
}

.table-scroll tr td:nth-child(2),
.table-scroll th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 64px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
  background: #1e1e1e;
}

.table-scroll th:nth-child(2) {
  z-index: 4;
}

.table-scroll tr td:nth-child(3),
.table-scroll th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  width: 200px;

  left: 134px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
  background: #1e1e1e;
}

.table-scroll th:nth-child(3) {
  z-index: 4;
}
</style>
