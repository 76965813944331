<template>
  <span>
    <LayoutRegistrarFactura>
      <template v-slot:title><div>Nueva factura</div> </template>
      <template v-slot:navigation-controller>
        <v-container>
          <div class="mt-1">
            <v-list dense rounded>
              <v-list-item
                style="background-color: grey"
                v-if="!remitoSeleccionado"
                @click="showRemitosDialog = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-page-next</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Seleccionar remito </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-dialog v-model="showRemitosDialog" max-width="600">
            <RemitosCompraPendientes
              @select-remito="fetchRemito"
            ></RemitosCompraPendientes>
          </v-dialog>

          <!-- Mostrar remito seleccionado -->
          <div v-if="remitoSeleccionado">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ remitoSeleccionado.nroComprobante }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ moment(remitoSeleccionado.fechaAlta).format("lll") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="removeRemito">Quitar</v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
          <v-divider></v-divider>

          <div class="mt-4">
            <ProveedorCardSelect
              :title="proveedor ? proveedor.nombre : 'Seleccionar proveedor'"
              @set-proveedor-register="setProveedor"
            ></ProveedorCardSelect>
          </div>

          <pedido-compra-agregar-detalle
            class="mt-4"
            :detalles="detallesFactura"
            :isCompra="true"
          ></pedido-compra-agregar-detalle>
          <v-divider></v-divider>

          <div class="title mt-4 mb-4" style="line-height: 1.3333">
            Datos de factura
          </div>
          <v-alert type="info" dense
            >Asigna número y sucursal para registrar la factura.</v-alert
          >
          <v-text-field
            outlined
            label="Nº de factura"
            dense
            type="number"
            v-model="nroFactura"
            v-validate="'numeric|max:13|min:1'"
            :error-messages="errors.collect('nroFactura')"
            data-vv-name="nroFactura"
            :counter="13"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="13"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Sucursal"
            v-validate="'numeric|max:13|min:1'"
            :error-messages="errors.collect('sucursal')"
            data-vv-name="sucursal"
            v-model="sucursal"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="5"
            :counter="5"
          ></v-text-field>
          <!-- Campos de IVA dinámicos -->
          <span v-for="item in totalesIva" :key="item.key">
            <v-text-field
              outlined
              dense
              v-validate="'decimal:2|max:12|min:0'"
              :label="`IVA ${item.key}%`"
              :error-messages="errors.collect(`iva-${item.key}`)"
              :data-vv-name="`IVA ${item.key}`"
              v-model.number="item.value"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="12"
              suffix="$"
            ></v-text-field>
          </span>
          <v-text-field
            outlined
            dense
            label="Perc. IVA"
            v-validate="'decimal:2|max:12|min:1'"
            :error-messages="errors.collect('percepcion')"
            data-vv-name="Percepción IVA"
            v-model.number="percepcionIva"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="12"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Perc. Ingresos brutos"
            v-validate="'decimal:2|max:12|min:1'"
            :error-messages="errors.collect('percepcionIB')"
            data-vv-name="Percepción Ingresos brutos"
            v-model.number="percepcionIB"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="12"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Otros impuestos"
            v-validate="'decimal:2|max:12|min:1'"
            :error-messages="errors.collect('netoNoGravado')"
            data-vv-name="Otros impuestos"
            v-model.number="netoNoGravado"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="12"
          ></v-text-field>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <FacturaCompraSubtotal
          :totalesIva="totalesIva"
          :withIva="false"
          :detalles="detallesFactura"
          :percepcionIva="percepcionIVANumber"
          :percepcionIB="percepcionIBNumber"
          :noGravado="netoNoGravadoNumber"
        ></FacturaCompraSubtotal>

        <v-row>
          <v-col cols="12">
            <v-btn
              block
              color="green"
              :disabled="esValidoRegistrar || errors.any()"
              @click="validateForm"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
          <v-alert type="info"
            >Podés editar la cantidad y actualizar el precio de costo.</v-alert
          >
          <v-container fill-height fluid>
            <v-row align="center">
              <v-col cols="12">
                <div class="mb-2">Detalle</div>

                <FacturaCompraDetalleList
                  :edit="true"
                  :withIva="true"
                  :detalles="detallesFactura"
                ></FacturaCompraDetalleList>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:cliente>
        <v-row>
          <v-col cols="12">
            <v-alert
              icon="mdi-file-cloud-outline"
              v-if="nroFactura"
              type="success"
              dense
              >Factura de compra.</v-alert
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item v-if="nroFactura">
              <v-list-item-content>
                <v-list-item-title>
                  {{ documento }} - {{ letra }}: {{ nroFactura }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nroFactura ? nroFactura : "-" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title> Nota de pedido </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nroFactura ? nroFactura : "-" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Sucursal </v-list-item-title>
                <v-list-item-subtitle>
                  {{ sucursal ? sucursal : "-" }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <info-proveedor :proveedor="proveedor"></info-proveedor>
        </v-container>
      </template>
    </LayoutRegistrarFactura>
    <v-dialog width="600" v-model="dialogPagar">
      <v-card>
        <v-container>
          <v-card-title> ¿Vas a pagar la factura? </v-card-title>
          <v-card-text>
            <v-alert type="success" v-if="montoActual > totalRemito">
              <v-row align="center">
                <v-col class="grow">
                  Saldo suficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="registrarFactura(true)"
                    :loading="loadingButton"
                    color="yellow"
                    class="black--text"
                    ><b>Pagar</b></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
            <v-alert v-else type="error">
              <v-row align="center">
                <v-col class="grow">
                  Saldo insuficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    :loading="loadingButton"
                    @click="registrarFactura(false)"
                    >Pagar después</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <v-card-actions
            v-if="montoActual > totalRemito"
            class="justify-space-around"
          >
            <v-btn
              :loading="loadingButton"
              color="info"
              text
              @click="registrarFactura(false)"
              >Pagar después</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogCancelarRemito"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar la factura?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar la factura? Se perderán todos los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar factura</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
//GLOBAL

import LayoutRegistrarFactura from "@/layouts/layout.registrar.factura.vue";
import FacturaCompraSubtotal from "@/components/compra/factura/FacturaCompraSubtotal.vue";
import FacturaCompraDetalleList from "@/components/compra/factura/FacturaCompraDetallesList.vue";
import RemitosCompraPendientes from "@/components/compra/remito/RemitosCompraPendientes.vue";

import BuscarProducto from "@/components/producto/buscador/producto.buscador.menu.vue";
import PedidoCompraAgregarDetalle from "@/views/compra/pedidos/pedido.compra.agregar.detalle";
import PedidoCompraAprobadosList from "@/components/compra/pedido/pedido.compra.aprobados.list.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import ProveedorCardSelect from "@/components/proveedor/ProveedorCardSelect";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";

import { mapActions } from "vuex";

export default {
  name: "FacturaCompraRegistrar",
  components: {
    FacturaCompraDetalleList,
    RemitosCompraPendientes,
    PedidoCompraAgregarDetalle,
    FacturaCompraSubtotal,
    PedidoCompraAprobadosList,
    ProveedorCardSelect,
    InfoProveedor,
    BuscarProducto,
    LayoutRegistrarFactura,
    DialogConfirmationClose,
  },
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },

  data() {
    return {
      totalesIva: [
        { key: "27", value: 0 },
        { key: "21", value: 0 },
        { key: "10.5", value: 0 },
        { key: "5", value: 0 },
        { key: "2.5", value: 0 },
        { key: "0", value: 0 },
        { key: "Exento", value: 0 },
      ],
      dialogPagar: false,
      montoActual: null,
      totalRemito: 0,
      proveedor: null,
      documento: "FC",
      letra: "A",

      showRemitosDialog: false, // Para mostrar el diálogo de remitos pendientes
      remitoSeleccionado: null, // Remito seleccionado

      sucursal: null,
      nroFactura: null,
      detallesFactura: [],
      percepcionIva: 0,
      percepcionIB: 0,
      netoNoGravado: 0,

      pedidos_compra_aprobados: [],

      finalizar: false,
      to: null,

      //Bandera que se activa cuando hubo un cambio
      isBorrador: false,
      loadingValidBorrador: false,
      dialogCancelarRemito: false,
      loadingButton: false,
    };
  },

  computed: {
    sortedTotalesIva() {
      return this.totalesIva.sort((a, b) => {
        if (a.key === "Exento") return 1;
        if (b.key === "Exento") return -1;
        return parseFloat(a.key) - parseFloat(b.key);
      });
    },
    sortedTotalesIvas() {
      const keysOrder = ["27", "21", "10.5", "5", "2.5", "0", "Exento"]; // Orden deseado

      // Convertimos el objeto a un array, lo ordenamos y lo convertimos de nuevo a un objeto
      return Object.fromEntries(
        Object.entries(this.totalesIva).sort((a, b) => {
          return keysOrder.indexOf(a[0]) - keysOrder.indexOf(b[0]);
        })
      );
    },
    percepcionIBNumber() {
      return Number(this.percepcionIB) || 0;
    },
    percepcionIVANumber() {
      return Number(this.percepcionIva) || 0;
    },
    netoNoGravadoNumber() {
      return Number(this.netoNoGravado) || 0;
    },

    isValidBorrador() {
      var detalles = this.detallesFactura;

      if (detalles.length > 0) return true;

      if (this.proveedor !== null) return true;

      return false;
    },
    esValidoRegistrar() {
      let detalles = this.detallesFactura.length > 0 ? true : false;
      let proveedor = this.proveedor !== null ? true : false;
      return proveedor && detalles ? false : true;
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Remito",
          disabled: false,
          to: { name: "remitoCompra" },
        },
        {
          text: "Nuevo remito",
          disabled: false,
          to: { name: "nuevoRemitoCompra" },
        },
      ];
    },
  },
  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

    activeDialogPagar() {
      this.dialogPagar = true;
    },
    ...mapActions("remitos", ["registrar_factura_compra", "get_remito_compra"]),
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.registrarFactura();
        } else {
          this.$validator.validate();
        }
      });
    },
    setProveedor(data) {
      this.proveedor = data;
    },
    setRemito(remito) {
      this.remitoSeleccionado = remito;
    },
    setRemitoDetalles(detalles) {
      this.detallesFactura = detalles;
    },
    setProveedorRemito(proveedor) {
      this.proveedor = proveedor;
    },
    removeRemito() {
      this.remitoSeleccionado = null;
    },
    async fetchRemito(remito) {
      try {
        console.log("remito", remito);
        const response = await this.get_remito_compra(remito.id);
        console.log(response);
        this.setRemito(response.data);
        this.setRemitoDetalles(response.data.detalles_comprobante);
        this.setProveedorRemito(response.data.proveedor);

        this.showRemitosDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    registrarFactura() {
      this.loadingButton = true;
      this.finalizar = true;
      const totalesIvaMayoresCero = this.totalesIva
        .filter((item) => item.value > 0)
        .map((item) => ({
          key: item.key,
          value: item.value,
        }));

      var data = {
        proveedor: this.proveedor,
        detalles: this.detallesFactura,
        remito: this.remitoSeleccionado,
        numero: this.nroFactura,
        sucursal: this.sucursal,
        estaPago: false,
        percepcionIB: this.percepcionIBNumber,
        percepcionIva: this.percepcionIVANumber,
        netoNoGravado: this.netoNoGravadoNumber,
        totalesIva: totalesIvaMayoresCero,
      };
      if (this.nroFactura !== null && !isNaN(parseInt(this.nroFactura))) {
        data.documento = this.documento;
        data.letra = this.letra;
      }

      this.registrar_factura_compra(data)
        .then((response) => {
          this.to = true; // Si es verdadero me deja salir de la interfaz

          this.$router.push({ name: "facturaCompra" });
          this.fetch_productos_mas_vendidos();
          this.loadingButton = false;
        })
        .catch((e) => {
          var message = e.response.data;
          if (e.response.data.detail) {
            message = e.response.data.detail;
          }
          this.loadingButton = false;
          this.finalizar = false;

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${message}`,
            icon: "mdi-information",
            timeout: 4000,
          });
          console.log(e);
        });
    },

    closeDialog() {
      this.dialogCancelarRemito = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarRemito = false;
      this.$router.push(this.to);
    },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
    calcularTotalesIva() {
      // Creamos un objeto para almacenar los totales acumulados por IVA
      const acumulado = this.totalesIva.reduce((acc, item) => {
        acc[item.key] = 0;
        return acc;
      }, {});

      // Iteramos sobre los detalles de la venta para calcular el total de cada IVA
      this.detallesFactura.forEach((detalle) => {
        const iva = parseFloat(detalle.producto.iva_compra.description || 0);
        const subtotal =
          parseFloat(detalle.precioUnitario) * parseFloat(detalle.cantidad);

        // Acumulamos el total del IVA en el objeto de acumulado
        if (acumulado.hasOwnProperty(iva)) {
          acumulado[iva] += subtotal * (iva / 100);
        }
      });

      // Actualizamos totalesIva con los valores acumulados y redondeamos
      this.totalesIva.forEach((item) => {
        item.value = Math.round((acumulado[item.key] || 0) * 100) / 100;
      });
      console.log(this.totalesIva);
    },
  },
  created() {
    this.getMontoActual();
  },
  watch: {
    detallesFactura: {
      handler() {
        this.calcularTotalesIva();
      },
      deep: true,
    },
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return;
        } else {
          if (params.remito) {
            console.log(params);
            // this.fetchPedido(params.pedido);
            this.remitoSeleccionado = params.remito;

            // this.detallesFactura = params.pedido.detalles_pedido;
            this.proveedor = params.remito.proveedor;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta.
    if (this.to) {
      if (this.finalizar) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Factura registrada",
          icon: "mdi-check",
          timeout: 2000,
        });
      }
      next();
    } else {
      // seteo to con la siguiente ruta

      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada

      !this.isValidBorrador ? next() : (this.dialogCancelarRemito = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
