<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cuentasCorrientes"
          class="row-pointer transparent"
          @click:row="handleClick"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Lista de cuentas corrientes</v-toolbar-title>
            </v-toolbar>
          </template>

          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay cuentas corrientes.</v-alert
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

export default {
  name: "ClienteListCtaCte",
  components: {
    LayoutDialogDelete,
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      cuentasCorrientes: [
        { id: 1, cliente: "Lucas", saldo: 0, vigente: true },
        { id: 2, cliente: "Franco", saldo: 100, vigente: true },
      ],

      headers: [
        { text: "Cliente", value: "cliente" },
        { text: "Saldo", value: "saldo", sortable: false },
        { text: "Vigente", value: "vigente", sortable: false },

        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {},
  methods: {
    processEliminar(cliente) {
      this.dialogDelete = true;
      this.clienteParaEliminar = cliente;
    },
    eliminarCliente() {
      this.eliminar_cliente(this.clienteParaEliminar.id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: `Se eliminó al cliente '${this.clienteParaEliminar.nombre}'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.dialogDelete = false;
      });
    },
    handleClick(a) {
      console.log("handleClick", a);
      this.$router.push({
        name: "clientesCuentasCorrientesDetalle",
        params: { id_cliente: a.id },
      });
    },
  },
};
</script>

<style  scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
