<template>
  <div>
    <v-dialog v-model="dialogAnularComprobante" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          @click="dialogAnularComprobante = true"
          :disabled="!configuracion.puedeAnularComprobante"
        >
          Anular
        </v-btn>
      </template>
      <v-card color="#242526">
        <v-card-title>
          <b>
            <div style="font-size: 22px">¿Desea anular el comprobante?</div>
          </b>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="grey" @click="dialogAnularComprobante = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">
            <div style="font-size: 16px">Seleccione el motivo</div>
            <div class="text-center mt-2">
              <v-select
                outlined
                data-vv-name="motivo"
                v-validate="'required'"
                :error-messages="errors.collect('motivo')"
                v-model="motivoAnulacion.descripcion"
                :items="motivosAnulacion"
                item-value="descripcion"
                item-text="descripcion"
                label="Motivo"
              >
              </v-select>
              <v-textarea
                v-if="motivoAnulacion.descripcion == 'Otro'"
                label="Comentarios"
                outlined
                data-vv-name="comentario"
                v-validate="'max:100'"
                :error-messages="errors.collect('comentario')"
                rows="3"
                counter="100"
                style="font-size: 25px"
                row-height="20"
                v-model="motivoAnulacion.comentario"
              ></v-textarea>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="info" @click="dialogAnularComprobante = false" text>
            <b> Permanecer en la página </b>
          </v-btn>
          <v-btn
            :disabled="
              errors.any() || motivoAnulacion.descripcion == 'Otro'
                ? motivoAnulacion.comentario
                  ? false
                  : true
                : false
            "
            @click="validateForm"
            :loading="loadingAnular"
            color="info"
          >
            <b> Anular comprobante </b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VentasApi from "@/api/venta/venta";

export default {
  name: "VentaAnularComprobante",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    comprobante: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dialogAnularComprobante: false,
      loadingAnular: false,
      motivoAnulacion: {
        descripcion: "",
        comentario: "",
      },
      motivosAnulacion: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.anularComprobante();
        } else {
          this.$validator.validate();
        }
      });
    },
    async getMotivosAnulacion() {
      try {
        const response = await this.axios.get("comprobantes/motivosanulacion");
        this.motivosAnulacion = response.data;
      } catch (error) {}
    },
    async anularComprobante() {
      this.loadingAnular = true;

      var id = this.comprobante.id
      try {
        const response = await VentasApi.anular(id, this.motivoAnulacion);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Venta anulada`,
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialogAnularComprobante = false;
        this.loadingAnular = false;

        this.$router.push("/ventas");
      } catch (error) {
        this.loadingAnular = false;
        var message = error.response.data.detail
          ? error.response.data.detail
          : `No se pudo anular la venta.`;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: message,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
  },
  created() {
    this.getMotivosAnulacion();
  },
};
</script>

<style lang="scss" scoped>
</style>