<template>
  <LayoutBasic  :showLinksMenu="false">
    <template v-slot:breadcrumbs>
      <!-- <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
        <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
          item.text
        }}</v-breadcrumbs-item>
      </v-breadcrumbs> -->
    </template>

    <template v-slot:navigation-title>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <h1 style="font-size: 35px">Configuración</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-content>
      <v-list>
          <v-list-item v-for="(item, i) in tipo_parametros" :key="i" exact :to="{name: item.route}" >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.nombre"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import { mapActions, mapState } from "vuex";

export default {
  name: "Ventas",
  components: {
    LayoutBasic,
  },
  data() {
    return {
   

      item: 0,
      items: [],
    };
  },
  computed: {
    ...mapState("sucursales",["configuracion"]),
     ...mapState("configuraciones", ["tipo_parametros"]),

  },

  methods: {
    ...mapActions("configuraciones", ["fetch_tipo_parametros"]),
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  mounted() {
    if (this.$route.path == "/configuracion") {

      this.$router.replace({name: this.items[this.item].route});
    }
  },

  created() {
    this.fetch_tipo_parametros()
  
    this.$store.commit("SET_LINKS", this.items);
  },
};
</script>

<style lang="scss" scoped></style>
