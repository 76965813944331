<template>
  <v-card>
    <v-card-title>
      Nuevo producto fraccionado
      <v-spacer></v-spacer>
      <v-icon large @click="closeDialog">mdi-close</v-icon>
    </v-card-title>
    <v-container>
      <v-card-text>

        <v-row align="center" justify="center">
          <v-col cols="12">

            <div>
              <form-producto-descripcion :producto="producto">
              </form-producto-descripcion>
            </div>
            <form-producto-precio-venta :producto="producto" :outlined="true"></form-producto-precio-venta>
            <div>
              <v-divider></v-divider>
              <div class="mt-1">
                <form-producto-barcode :producto="producto"></form-producto-barcode>
              </div>
            </div>
            <div name="Precio de costo">

              <div class="mt-1">
                <form-producto-precio-costo :producto="producto" :outlined="true"></form-producto-precio-costo>
              </div>
            </div>
            <div name="Ganancia">
              <v-divider></v-divider>

              <div class="mt-1">
                <form-producto-ganancia :producto="producto" :outlined="true">
                </form-producto-ganancia>
              </div>
            </div>
            <form-producto-iva :producto="producto" :outlined="true"></form-producto-iva>
            <div name="Flete">

              <div class="mt-1">
                <form-producto-flete :producto="producto" :outlined="true"></form-producto-flete>
              </div>
            </div>


          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-btn color="grey" text @click="closeDialog">Cancelar</v-btn>

          <v-btn color="success" :disabled="errors.any()" @click="validateForm">Guardar</v-btn>
        </v-row>
      </v-card-actions>
      <!-- <v-row align="end" justify="end">

      <v-col cols="4">
      </v-col>
      </v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";
import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";


export default {
  name: "FormProducto",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    FormProductoDescripcion,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoBarcode,
    FormProductoPrecioVenta,
  },
  data() {
    return {
      producto: {
        descripcion: "",
        precioCosto: 0,
        ganancia: 0,
        codigoDeBarra: null,
        flete: 0,
        iva: 0,
      },
      iva: [
        { id: 88, valor: '0.00' },
        { id: 89, valor: '10.50' },
        { id: 90, valor: '21.00' },
      ],

    };
  },
  computed: {
    ...mapGetters("productos", ["productos_agregados_register"]),
    precioVenta() {
      var precioVenta = null;
       var iva = this.producto.iva? this.producto.iva.description : 0

      if (
        this.producto.descripcion != "" &&
        this.producto.precioCosto > 0 &&
        this.producto.ganancia > 0 &&
        this.producto.flete >= 0 &&
        iva>= 0
      ) {
        precioVenta = Math.round(
          this.producto.precioCosto *
          (1 + parseFloat(this.producto.ganancia) / 100) *
          (1 + parseFloat(this.producto.iva) / 100) *
          (1 + parseFloat(this.producto.flete) / 100)
        );
        this.producto.precioVenta = precioVenta;
      }

      return precioVenta;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    },
    validateForm() {

      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit('process-register', this.producto)
        } else {
          this.$validator.validate();
        }
      });


    },
    async enviarProducto() {
      const result = await this.$refs.formProductos.validate();
      if (result) {
        // eslint-disable-next-line
        this.$emit("proccesProducto", this.producto);
        this.$refs.formProductos.reset();
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        this.enviarProducto();
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
