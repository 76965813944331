<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card :color="empleado.is_active ? '' : 'grey'">
        <!-- <v-img contain :src="'http://127.0.0.1:8080'+empleado.foto" :aspect-ratio="16 / 9" dark v-if="empleado.foto">
        </v-img>
       

        <v-img
          v-if="!empleado.foto"
          height="150px"
          dark
          contain
          src="@/assets/man-woman.jpg"
          @click="dialogSubirFoto=true"
        ></v-img> -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-h5 white--text"
                >{{ empleado.persona.nombre }} {{ empleado.persona.apellido }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :color="empleado.is_active ? 'green' : 'red'"
                  icon
                  @click="setActiveEmpleado(empleado)"
                  ><v-icon color=""> mdi-power </v-icon></v-btn
                >
              </template>
              <span>{{
                empleado.is_active ? "Deshabilitar" : "Habilitar"
              }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
        <!-- {{empleado.is_active ? 'Desactivar' : 'Activar'}} -->
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-card-account-details </v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ empleado.persona.documento }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ empleado.persona.telefono }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ empleado.persona.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <div class="mt-4">
        <v-card :disabled="!empleado.is_active" flat color="transparent">
          <v-card-title>
            <span class="text-h5 white--text">Usuario</span>

            <v-spacer></v-spacer>
          </v-card-title>
          <v-list subheader v-if="empleado.user">
            <v-subheader>Usuario</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-img contain src="@/assets/man-woman.jpg"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="empleado.user.username"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  v-text="
                    empleado.user.is_active ? 'Habilitado' : 'Deshabilitado'
                  "
                ></v-list-item-action-text>
                <v-switch
                  :loading="loadingActive"
                  color="success"
                  @change="setActiveUser(empleado.user)"
                  v-model="empleado.user.is_active"
                >
                </v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item> </v-list-item>
            <v-btn
              block
              color="red"
              @click="dialogReestablecerContraseña = true"
              >Reestablecer contraseña</v-btn
            >
            <v-dialog v-model="dialogSubirFoto" width="400">
          <usuario-subir-foto
            :user="empleado.user"
            @process-success="dialogSubirFoto = false"
            @process-cancel-foto="dialogSubirFoto = false"
          ></usuario-subir-foto>
        </v-dialog>

            <v-dialog
              persistent
              v-model="dialogReestablecerContraseña"
              width="400"
            >
              <reestablecer-contraseña
                :user="empleado.user"
                @close-dialog="dialogReestablecerContraseña = false"
              ></reestablecer-contraseña>
            </v-dialog>
          </v-list>

          <div v-else>
            <v-btn
              v-if="!empleado.user"
              @click="$router.push({ name: 'empleadoUsuario' })"
              color="success"
              >Administrar Usuario</v-btn
            >
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ReestablecerContraseña from "@/components/usuarios/usuario.reestablecer.contraseña.vue";

import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import UsuarioSubirFoto from "@/components/usuarios/usuario.subir.foto.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ClientesCuentaCorriente",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
    ReestablecerContraseña,
    UsuarioSubirFoto
  },

  data() {
    return {
      dialogReestablecerContraseña: false,
      loadingActive: false,
dialogSubirFoto: false,

    };
  },
  computed: {
    ...mapState("empleado", ["empleado", "loadingEmpleado"]),
  },
  methods: {
    ...mapActions("empleado", ["set_active_empleado"]),
    setActiveEmpleado(empleado) {
      this.set_active_empleado(empleado)
        .then((response) => {
          const message = empleado.is_active ? "Inhabilitado" : "Habilitado";

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${message}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setActiveUser(user) {
      this.loadingActive = true;
      setTimeout(() => {
        console.log(user.is_active);
        var accion = user.is_active ? "activar" : "desactivar";
        const message = user.is_active ? "Habilitado" : "Inhabilitado";
        var data = {
          accion: accion,
          usuario: user.id,
        };
        this.axios
          .post("/usuarios/setactive", data)
          .then((response) => {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: `${message}`,
              icon: "mdi-information",
              timeout: 3000,
            });
            user.is_active = user.is_active;
            this.loadingActive = false;
          })
          .catch((e) => {
            this.loadingActive = false;

            console.log(e);
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
