<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Editar perfil</div>
      </v-col>
    </v-row>
    <PersonaUpdate :persona="empleado.persona"></PersonaUpdate>

    <!-- <v-row justify="center">
      <v-col cols="12">
        <empleado-form
          v-if="empleado"
          textButton="Guardar"
          @close-dialog="$emit('close-dialog')"
          :empleado="empleado"
          :provincias="provincias"
          :isUpdate="true"
          @process-empleado="actualizarEmpleado"
        ></empleado-form>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import EmpleadoForm from "@/components/empleado/empleado.form.vue";
import ClienteApi from "@/api/clientes/clientes.js";

import { mapActions, mapMutations, mapState } from "vuex";
import PersonaUpdate from "@/views/usuarios/PersonaUpdate.vue";
export default {
  name: "empleado.update",
  components: {
    EmpleadoForm,
    PersonaUpdate,
  },

  data() {
    return {
      provincias: [],
    };
  },
  computed: {
    ...mapState("empleado", ["empleado", "loadingEmpleado"]),
  },

  methods: {
    ...mapActions("empleado", ["get_empleado", "actualizar_empleado"]),
    ...mapMutations("empleado", ["SET_EMPLEADO"]),

    actualizarEmpleado(data) {
      this.actualizar_empleado(data).then(() => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Actualizado",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.$emit("close-dialog");
      });
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        console.log(params);
        if (params.cliente) {
          this.SET_EMPLEADO(params.cliente);
        } else {
          this.get_empleado(params.id_empleado);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
