<template>
  <v-card color="#242526" v-if="preImpreso">
    <v-card-title>
     
      <div style="font-size: 20px"><b>Datos de la factura pre-impresa</b></div>

      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon color="grey" @click="$emit('close-dialog')">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="5">
          <v-text-field label="Tipo de comprobante" disabled :value="`Factura ${preImpreso.letra}`" >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Punto de venta" disabled :value="preImpreso.punto_de_venta"> </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Nro de factura" disabled :value="preImpreso.numero"> </v-text-field>
        </v-col>
      </v-row>

      <v-row justify="end" class="text-end">
        <v-col>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> CAI: {{preImpreso.cai}} </v-list-item-title>
              <v-list-item-subtitle> Vto: {{moment(preImpreso.vto_cai).format('DD-MM-YYYY')}} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>

   
  </v-card>
</template>

<script>
            import VentasApi from "@/api/venta/venta";

export default {
    name: 'pre-impreso.consultar',
    props: {
        comprobante: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loadingFacturar : false,
            preImpreso: null

        }
    },
    methods: {
        async registrarPreImpreso(){

         const response = await VentasApi.facturarPreImpreso(this.comprobante.id, this.preImpreso);

            this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Comprobante facturado`,
            icon: "mdi-check",
            timeout: 3000,
            });

        },
        fetchDatosPreImpreso(){
             this.axios.get(`afipfiscal/preimpreso/${this.comprobante.id}`).then((response) => {
             this.preImpreso = response.data
             });
        }
    },
    created(){
        this.fetchDatosPreImpreso()
    }
}
</script>

<style lang="scss" scoped></style>
