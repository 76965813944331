<template>
  <v-card>
    <v-container>
      <v-card-title> Actualizar foto de perfil </v-card-title>
      <v-card-text>
        <v-file-input
          label="Subir foto"
          accept="image/png, image/jpeg, image/jpg"
          v-model="file"
          @change="mostrarImagen($event)"
          filled
          prepend-icon="mdi-camera"
          id="archivo"
        ></v-file-input>
        <div v-show="file" class="text-center justify-center">
          <img width="300" height="300" id="img1" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="$emit('process-cancel-foto')" text> Cancelar </v-btn>
        <v-btn color="info" @click="guardarFoto"> Guardar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "usuario.subir.foto",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      file2: null,
    };
  },

  methods: {
    mostrarImagen(event) {
      console.log(event);
      var file = event;
      this.file2 = event;
      var reader = new FileReader();
      reader.onload = function (event) {
        var img = document.getElementById("img1");
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    guardarFoto() {
      let fd = new FormData();
      var img = document.getElementById("archivo");
      var imagen = img.files[0];
      console.log(imagen);

      fd.append("file", imagen);
      var id = this.user.id;
      this.axios
        .post(`/usuarios/${id}/uploadphoto`, fd)
        .then((response) => {
          console.log(response);
          this.$emit("process-success");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
