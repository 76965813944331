<template>
  <div v-if="comprobante">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Nro de comprobante {{comprobante.nroComprobante}}- Creada el {{moment(comprobante.fechaAlta).format('LLLL')}}</v-list-item-title
                >
              </v-list-item-content>
             
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Pago</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="text-h6"> {{comprobante.total | convertPesoArg}}</v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
     
              <v-card>
                <v-card-title ><v-icon class="mr-2">mdi-credit-card-outline</v-icon><span class="text-subtitle-1">Medio de pago</span></v-card-title>
                <v-card-text>
                  <v-list-item
                    v-for="fp in comprobante.formasDePago"
                    :key="fp.id"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-star-four-points</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title><span class="text-subtitle-2"> 
                        
                        {{
                        fp.nombre
                      }}
                        </span>
                      
                      
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title
                >
                <v-icon class="mr-2">mdi-purse-outline</v-icon>
                <span class="text-subtitle-1">

                     Compraste
                {{
                  comprobante.detalles_comprobante.length
                }}
                Producto
                </span>
             </v-card-title
              >
              <v-card-text>
                <v-list-item
                  v-for="c in comprobante.detalles_comprobante"
                  :key="c.id"
                >
                <v-list-item-icon>
                      <v-icon>mdi-bag-checked</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      c.producto.descripcion
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                    class="mt-1"
                      >
                      {{ c.precioUnitario | convertPesoArg }} x{{
                        c.cantidad
                      }} Unidad</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Retiro en el local</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ClienteUpdate from "@/views/cliente/ClienteUpdate";
import VentasAPI from "@/api/venta/venta";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

export default {
  name: "ClienteCuentaCorrienteMovimientosDetail",
  components: {
    ClienteUpdate,
    LayoutDialogDelete,
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      comprobante: null,
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Nº de comprobante", value: "comprobabte" },
        { text: "Tipo de movimiento", value: "tipoMovimiento" },
        { text: "Detalle", value: "verDetalle" },

        { text: "Importe", value: "haber" },

        { text: "Saldo", value: "saldo", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {},
  methods: {
    getComprobante(id) {
      VentasAPI.show(id).then((response) => {
        this.comprobante = response.data;
      });
    },
  },

  watch: {
    "$route.params": {
      handler: function (params) {
        this.getComprobante(params.id_comprobante);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style  scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
