<template>
  <div class="text-center">
    <span v-for="chip in get_querys" :key="chip.key">
      <v-chip v-if="closeTag" class="ma-2" close @click:close="close(chip)">
        <v-icon v-if="chip.key == 'barcode'" left> mdi-barcode </v-icon>
        {{ chip.key == "py" ? "PedidosYa" : chip.nombre }}</v-chip
      >
      <v-chip v-else class="ma-2">{{
        chip.key == "py" ? "PedidosYa" : chip.nombre
      }}</v-chip>
    </span>
  </div>
</template>

<script>
export default {
  name: "productos.filtros.tag",
  props: {
    removeTagCategoria: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeTag: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    get_querys() {
      const chipsArray = [];

      // Iterar a través de las propiedades en los parámetros de consulta
      for (const property in this.$route.query) {
        // Dividir los valores de la propiedad por '%7C' para obtener valores individuales
        const valuesArray = this.$route.query[property].split("%7C");

        // Iterar a través de los valores individuales
        for (const value of valuesArray) {
          // Dividir los valores individuales por '|' para obtener valores más individuales
          const individualValues = value.split("|");

          // Iterar a través de los valores más individuales y crear chips
          for (const individualValue of individualValues) {
            // Transformar el valor en el nombre deseado
            console.log("individualValue",individualValue);
            const nombre = this.get_text_transform(individualValue);

            // Crear un objeto chip y agregarlo al array
            const chip = {
              key: property,
              value: individualValue,
              nombre: nombre,
            };
            chipsArray.push(chip);
          }
        }
      }

      // Devolver el array de chips
      return chipsArray;
    },
  },
  methods: {
    get_text_transform(str) {
      if (typeof str === "string") {
        return str.match(/[^ ]+/g).join("-");
      } else {
        return str;
      }
    },
    remove_query_url(key, valueToRemove) {
      let queryParams = Object.assign({}, this.$route.query);

      if (queryParams.hasOwnProperty(key)) {
        // Dividimos los valores por '|'
        const valuesArray = queryParams[key].split("|");

        // Filtramos los valores para mantener solo los que no coinciden con el valor a eliminar
        const filteredValues = valuesArray.filter(
          (value) => value !== valueToRemove
        );

        // Si quedan valores después de la eliminación, los unimos con '|'
        if (filteredValues.length > 0) {
          queryParams[key] = filteredValues.join("|");
        } else {
          // Si no quedan valores, eliminamos la clave
          delete queryParams[key];
        }

        this.$router.replace({ query: queryParams }).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },

    close(chip) {
      this.$emit("close-chip", chip);
      this.remove_query_url(chip.key, chip.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>