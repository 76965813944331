<template>
  <div class="items3">
    
    <v-data-table
      style="background-color: transparent"
      :headers="computedHeaders"
      :items="detalles"
      sort-by="calories"
      no-data-text="No hay productos agregados"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.producto.cantidadstock="{ item }">
        {{ item.producto.controlaStock ? item.producto.cantidadstock : "N/A" }}
      </template>

   
      <template v-slot:item.subtotal="{ item }">
        {{ parseFloat(item.precioUnitario) * parseFloat(item.cantidad) }}
      </template>

     
    </v-data-table>
  </div>
</template>

<script>
import {
  quitDetalle,
  updateDetalle,
  addDetalle,
} from "@/services/detallesComprobante/serviceDetalles";

import { mapMutations, mapState } from "vuex";
import ComprobanteDetalleForm from "@/components/comprobante/detalle/comprobante.detalle.form.vue";
import ComprobanteDetalleButtons from "@/components/comprobante/detalle/comprobante.detalle.buttons.vue";

export default {
  name: "remito.compra.detalles.consultar",

  components: {
    ComprobanteDetalleForm,
    ComprobanteDetalleButtons,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
 
      /////////////////////
      dialog_detalle: false,
      detalle: null,
      detalle_update: {},
      height: "100%",
      headers: [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "producto.descripcion",
        },
        { text: "Cantidad", value: "cantidad" },

        { text: "Precio Costo", value: "precioUnitario" },
        { text: "Subtotal", value: "subtotal" },

        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales", ["configuracion"]),
    computedHeaders() {
      if (!this.edit) {
        return this.headers.filter((header) => header.text !== "Acciones");
      }

      return this.headers;
    },
  },
  methods: {
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Guardado";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open(text) {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = text;
    },

    /////////////////////////
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),
    

    emitSubTotal() {
      //Lo invoca el padre
      this.$emit("process-sub-total", this.subtotal());
    },
  
   

    subtotal() {
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          var precio =
            this.detalles[index].cantidad *
            this.detalles[index].precioUnitario;
          subtotal += precio;
        } else {
          var precio =
            this.detalles[index].cantidad *
            this.detalles[index].precioUnitario;
          subtotal += precio;
        }
      }
      return subtotal;
    },

   
  },
 
 
};
</script>

<style scoped>
.v-list.detalle {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
