<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" md="3">
        <v-card style="border-radius: 10px" color="#121212" flat outlined elevation="0">
          <v-container>
            <div class="text-tab">Hoy</div>
            <div class="value-tab title">
              {{ computedDateFormattedMomentjs }}
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="4" md="2">
        <v-sheet outlined color="green" rounded>
          <v-card style="border-radius: 10px" color="#121212" flat outlined elevation="0">
            <v-container>
              <div class="text-tab">En buzón</div>
              <div class="value-tab title">
                {{ egresos.monto_actual | convertPesoArg }}
              </div>
            </v-container>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <div class="title mt-2">Últimos movimientos</div>
        <template v-if="egresos.buzon.length > 0">
          <v-list class="mt-2" subheader two-line>
            <buzon-list :egresos="egresos"></buzon-list>
          </v-list>
        </template>
        <template v-else>
          <div>
            <v-alert>No hay registros</v-alert>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import EgresosApi from "@/api/egresos/index";
import BuzonList from "@/components/egresos/buzon/buzon.list.vue";

export default {
  name: "buzon.home",
  components: {
    BuzonList,
  },
  data() {
    return {
      date: this.moment().format("YYYY-MM-DD"),
      menu2: false,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.moment(this.date).format("ll") : "";
    },
    egresos() {
      return this.$store.state.egresos.egresos_buzon;
    },
  },
  methods: {
    ...mapActions("egresos", ["fetch_egresos_buzon"]),

    async fetchEgresosBuzon() {
      this.fetch_egresos_buzon();
    },
  },

  created() {
    this.fetchEgresosBuzon();
  },
};
</script>

<style lang="scss" scoped></style>
