<template>
  <div>
    <categoria-update
      :categoria="categoria"
      @refresh-categorias="$emit('refresh-categorias')"
      @cerrar-dialog="$emit('cerrar-dialog')"
      @update-category="actualizarCategoria"
      :withColor="withColor"
    >
    </categoria-update>
  </div>
</template>
<script>
import CategoriaUpdate from "@/components/categorias/categoria.update";
import EgresosApi from "@/api/egresos/index";
export default {
  name: "EgresoCategoriaUpdate",
  components: {
    CategoriaUpdate,
  },
  props: {
    categoria: {
      type: Object,
    },
    withColor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    actualizarCategoria(categoria) {
      var data = {
        padre: categoria.parent,
        name: categoria.name,
        color: categoria.color,
      };
      EgresosApi.updateCategoria(categoria.id, data)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Categoría actualizada",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("refresh-categorias");
          this.$emit("cerrar-dialog");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Problema al actualizar",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("cerrar-dialog");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>