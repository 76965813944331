<template>
  <v-navigation-drawer app right fixed class="items2" :width=width>
    <template v-slot:prepend>
      <slot name="cliente"></slot>
    </template>
    <slot name="detalle-pedido"></slot>
    <template v-slot:append>
      <div class="pa-2">
        <v-row>
          <v-col>

        <slot name="button-navigation-controller"></slot>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '450px'
    }
  }
};
</script>

<style scoped>
.items2:hover ::-webkit-scrollbar {
  width: 8px;
}
.items2 ::-webkit-scrollbar {
  width: 4px;
}
/* width */

/* Track */
.items2 ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.items2 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
