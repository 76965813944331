<template>
  <div>
    <v-card flat v-if="pedido">
      <v-card-title class="ma-0 pb-0">
        Pedido nº {{ pedido.numeroVentaPorArqueo }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              class="mr-1"
              v-on="on"
              icon
              @click="$emit('reestablecer-detalles')"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>Reestablecer pedido</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small class="mr-1" v-on="on" icon @click="$emit('set-menu')">
              <v-icon>mdi-swap-vertical-bold</v-icon>
            </v-btn>
          </template>
          <span>Cambiar pedido</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="$emit('reset-pedido')">mdi-delete</v-icon>
          </template>
          <span>Quitar pedido</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div></div>
        <v-list class="pa-0" dense two-line>
          <v-list-item dense>
            <v-list-item-icon>
              <v-icon v-text="'mdi-calendar-range'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="tipoComprobante == 'venta'">
                {{ pedido.cliente.cliente.nombre }}</v-list-item-title
              >
              <v-list-item-title v-else> {{ pedido.proveedor.nombre }}</v-list-item-title>

              <v-list-item-subtitle>{{ pedido.fechaAlta }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-btn @click="verMasInfoPedido()" text x-small block>
            {{ verMasInfo ? "Ocultar" : "Ver más" }}</v-btn
          >
          <v-divider v-show="verMasInfo"></v-divider>
          <v-list-item dense v-show="verMasInfo">
            <v-list-item-avatar>
              <v-icon v-text="'mdi-folder'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="'Última actualización'"></v-list-item-title>
              <v-list-item-subtitle>{{
                moment(pedido.fechaUltModificacion).format('lll')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "pedido.seleccionado.consultar",
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    tipoComprobante: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verMasInfo: false,
    };
  },
  methods: {
    verMasInfoPedido() {
      this.verMasInfo = !this.verMasInfo;
    },
  },
};
</script>

<style lang="scss" scoped></style>
