<template>
  <v-card>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-form @submit.prevent="submitForm">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field
                      dense
                      outlined
                      v-model="rubroVenta.codigo"
                      label="Código"
                      required
                    ></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field
                      dense
                      outlined
                      v-model="rubroVenta.descripcion"
                      label="Descripción"
                      required
                    ></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <AlicuotaIvaSelect
                      :iva="rubroVenta.iva"
                      @update:iva="setIva"
                    ></AlicuotaIvaSelect>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Neto Gravado:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_neto_gravado"
                      @update:initialCuenta="setCuentaNetoGravado"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Ingresos Brutos:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_ing_bru"
                      @update:initialCuenta="setCuentaIngBru"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Neto No Gravado/Exento:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_neto_no_gravado_exento"
                      @update:initialCuenta="setCuentaNetoNoGravadoExento"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >I.V.A:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_iva"
                      @update:initialCuenta="setCuentaIva"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >I.V.A No Inscripto:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_iva_no_inscripto"
                      @update:initialCuenta="setCuentaIvaNoInscripto"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >I.V.A Per/Ret:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_iva_per_ret"
                      @update:initialCuenta="setCuentaIvaPerRet"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Ganancia:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_ganancia"
                      @update:initialCuenta="setCuentaGanancia"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >O. Conc. 3:
                    <CuentasTreeviewDialog
                      :planDeCuentas="planDeCuentas"
                      :initialCuenta="rubroVenta.cuenta_o_conc_3"
                      @update:initialCuenta="setCuentaOConc3"
                    ></CuentasTreeviewDialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!-- Repetir el mismo patrón para las otras cuentas -->
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-row justify="end">
                <v-btn color="primary" type="submit">{{ textButton }}</v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import AlicuotaIvaSelect from "@/components/util/AlicuotaIvaSelect.vue";

export default {
  name: "RubroVentaForm",
  components: {
    CuentasTreeviewDialog,
    AlicuotaIvaSelect,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
    rubroVenta: {
      type: Object,
      required: true,
    },
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },
  },
  data() {
    return {};
  },

  methods: {
    setIva(vatType) {
      this.rubroVenta.iva = vatType;
    },
    setCuentaNetoGravado(value) {
      this.rubroVenta.cuenta_neto_gravado = value[0];
    },

    setCuentaIvaNoInscripto(value) {
      this.rubroVenta.cuenta_iva_no_inscripto = value[0];
    },

    setCuentaNetoNoGravadoExento(value) {
      this.rubroVenta.cuenta_neto_no_gravado_exento = value[0];
    },

    setCuentaIva(value) {
      this.rubroVenta.cuenta_iva = value[0];
    },

    setCuentaIvaPerRet(value) {
      this.rubroVenta.cuenta_iva_per_ret = value[0];
    },

    setCuentaIngBru(value) {
      this.rubroVenta.cuenta_ing_bru = value[0];
    },

    setCuentaGanancia(value) {
      this.rubroVenta.cuenta_ganancia = value[0];
    },
    setCuentaOConc3(value) {
      this.rubroVenta.cuenta_o_conc_3 = value[0];
    },

    submitForm() {
      this.$emit("register:rubro", this.rubroVenta);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>