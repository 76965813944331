<template>
  <v-row justify="center">
    <v-col cols="10">
      <div>
        <h1>Informe menusal</h1>
      </div>

      <v-row class="mt-4">
        <v-col cols="12" md="3" sm="6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedMomentjs"
                label="Seleccioná el mes"
                prepend-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              locale="es"
              type="month"
              :max="moment().format('YYYY-MM')"
              v-model="date"
              @change="changeDate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <loading :active.sync="loading"></loading>
      </v-row>
      <template v-if="reporte && !loading">
      <v-row>
        <v-col cols="6" md="6">
          <!-- <template v-if="reporte && reporte.cantidad == 0">
          <div>
            <v-alert> No hay registros de actividad. </v-alert>
          </div>
        </template> -->
          <v-card>
            <v-container>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Ganancia </v-list-item-title>
                  <v-list-item-title>
                    <h1>{{ reporte.total_ganancia | convertPesoArg }}</h1>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Ingreso por ventas </v-list-item-title>
                  
                </v-list-item-content>
                <v-list-item-action>
                    <span>{{ reporte.total_ventas | convertPesoArg }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Egresos (Gastos y Compras) </v-list-item-title>
               
                </v-list-item-content>
                  <v-list-item-action>
                    <span>{{ reporte.total_gastos | convertPesoArg }}</span>
                </v-list-item-action>
              </v-list-item>

              <v-card-text> </v-card-text>
            </v-container>
          </v-card>
       
        </v-col>

        <v-col cols="6" md="6">
          <v-row>
             <v-col cols="12">
              <ChartGastosPorMes
                :chartGastos="chartGastos"
                :verListadoGastos="false"
                :verAction="true"
                :key="reloadChartGastos"
              ></ChartGastosPorMes>
            </v-col>
            <v-col cols="12">
              <ChartComprasPorMes
                :compras="chartGastosCompras"
                :verListadoCompra="false"
              ></ChartComprasPorMes>
            </v-col>
           
          </v-row>
        </v-col>
      </v-row>
       </template>
       </v-col
  ></v-row>
</template>

<script>
import Loading from "vue-loading-overlay";
import VentasPorFormaDePago from "@/components/reportes/VentasPorFormaDePago.vue";
import CardVistaPreviaArqueo from "../../../components/reportes/CardVistaPreviaArqueo.vue";
import ChartGastosPorMes from "@/components/reportes/ChartGastosPorMes.vue";
import ChartComprasPorMes from "@/components/reportes/ChartComprasPorMes.vue";

export default {
  name: "ReporteGanancias",
  components: {
    Loading,
    VentasPorFormaDePago,
    CardVistaPreviaArqueo,
    ChartComprasPorMes,
    ChartGastosPorMes,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Hora creación",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Hora anulación", value: "fechaUltModificacion" },
        { text: "Total", value: "total" },
        { text: "Motivo", value: "motivoAnulacion.descripcion" },
        { text: "Comentario", value: "comentarioMotivoAnulacion" },
      ],

      date: new Date().toISOString().substr(0, 7),

      loading: false,
      menu2: false,
      reporte: null,
      chartGastosCompras: null,
      chartGastos: null,
      reloadChartGastos: 1
    };
  },
  computed: {
    queryParams() {
      return this.$route.query || "";
    },
    computedDateFormattedMomentjs() {
      return this.date ? this.moment(this.date).format("MMMM [del] YYYY") : "";
    },
  },
  methods: {
    changeDate() {
      this.reporte = null;
      this.$router.replace({ path: "/reportes/", query: { date: this.date } });
      this.fetchReporte();
      this.fetchCompras()
      this.fetchGastos()
    },

    fetchReporte() {
      console.log("Hola", this.date);
      this.loading = true;
      var url = `reportes/reporteganancias?current=${this.date}`;
      this.axios
        .get(url)
        .then((response) => {
          this.reporte = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status && e.response.status === 404) {
            this.loading = false;
          }
          this.loading = false;
        });
    },
    fetchCompras() {
      var url = `/reportes/reportecompracategoria?current=${this.date}`;
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.chartGastosCompras = response.data;
      });
    },
    fetchGastos() {
      var url = `/reportes/reportegastoscat?current=${this.date}`;
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.chartGastos = response.data;
        // this.reloadChartGastos = !this.reloadChartGastos
      });
    },
  },

  created() {
    if (Object.keys(this.queryParams).length !== 0) {
      //Si la fecha que llega como parametro es menor a hoy
      console.log(this.moment(this.queryParams.date).isValid());
      if (this.moment(this.queryParams.date).isValid()) {
        if (
          new Date(this.queryParams.date).toISOString().substr(0, 10) <
          this.date
        ) {
          this.date = this.queryParams.date;
        }
      }
    }

    this.fetchReporte();
    this.fetchCompras();
    this.fetchGastos();
  },
};
</script>

<style lang="scss" scoped></style>
