<template>
  <div>
    <v-select
      v-model="model"
      :items="proveedoresOrdenados"
      required
      label="Proveedor"
      @change="sendProveedor"
      item-text="nombre"
      item-value="id"
      return-object
      clearable
      outlined
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "proveedor.select",
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
    ...mapGetters("productos", ["proveedor_select"]),

    proveedoresOrdenados() {
      function compare(a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
        return 0;
      }
      return this.proveedores.sort(compare);
    },
  },
  methods: {
    sendProveedor(val) {
      this.$emit("set-proveedor", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
