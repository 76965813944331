<template>
  <v-row align="center">
    <template v-if="categorias.length > 0">
      <v-col v-for="(p, i) in categorias" :key="i"  cols="12" md="6">
        <v-card color="#303030" height="100%"   @click="processCategoria(p)">
          <v-container>
            <div class="headline align-center text-center">
              {{ p.name }} 
            </div>
          </v-container>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-container class="mt-2">
        <v-row>
          <v-col>
            <v-alert text type="info"> <h2>No se han encontrado categorías</h2></v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-row>
</template>

<script>
export default {
  inject: ["theme"],
  name: "CardListCategoria",
  props: {
    categorias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    processCategoria(categoria) {
      this.$emit("process-categoria", categoria);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
