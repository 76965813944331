<template>
  <v-container>
    <div>
      <h2>
        {{ estadoPlural | capitalize }} <span class="ml-1">{{ pedidos.length }}</span>
      </h2>

    </div>
    <lista-pedidos
      v-if="pedidos.length > 0"
      :esVenta="true"
      @abrir-vista-previa="abrirVistaPrevia"
      :pedidos="pedidos">
    </lista-pedidos>
    <!-- <lista-pedidos :pedidos="pedidos"></lista-pedidos> -->
    <div v-else>
      <v-divider></v-divider>
      <div class="mt-3">No hay pedidos {{ estadoPluralLower }}</div>
    </div>
    <v-dialog v-model="dialogVistaPrevia" width="800px">
       <pedido-venta-vista-previa
       v-if="dialogVistaPrevia"
        @close-dialog="dialogVistaPrevia = false"
        @process-quitar-pedido="quitarPedido"
        @process-ver-pedido="abrirPedido"
        @process-refresh="fetchPedidos"
        :pedido="pedido"
      ></pedido-venta-vista-previa> 

    </v-dialog>
    <PedidoVentaCobrar
      v-if="drawerVistaPrevia"
      :model="drawerVistaPrevia"
      @close-dialog="drawerVistaPrevia = false"
      @set-drawer="setDrawer"
      @process-quitar-pedido="quitarPedido"
      @process-ver-pedido="abrirPedido"
      @process-refresh="fetchPedidos"
      :id_pedido="pedido.id"
    ></PedidoVentaCobrar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ListaPedidos from "@/views/pedido/lista/ListaPedidos";
import PedidoVentaVistaPrevia from "@/views/venta/pedidos/pedido.venta.vista-previa2";
import PedidoVentaCobrar from "@/views/venta/pedidos/pedido.venta.cobrar";

export default {
  name: "pedido.venta.consultar",
  components: {
    ListaPedidos,
    PedidoVentaVistaPrevia,
    PedidoVentaCobrar,
  },
  props: {
    estado: {
      type: String,
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      model: null,
      pedido: null,
      

      drawerVistaPrevia: false,
      dialogVistaPrevia: false,

    };
  },
  computed: {
     ...mapState("sucursales",["configuracion"]),
    estadoPlural() {
      if (this.configuracion.menuVentaCruachan) {
        if (this.estado) {
          if (this.estado == "PENDIENTE") {
            return "En producción";
          } else if (this.estado == "APROBADO") {
            return "Listos";
          }
        } else {
          return "Pedidos";
        }
      } else {
        if (this.estado) {
          return this.estado + "S";
        } else {
          return "Pedidos";
        }
      }
    },
    estadoPluralLower() {
      return this.estadoPlural.toLowerCase().trim();
    },
    pedidos() {
      if (this.estado) {
        if (this.estado.toUpperCase() === "PENDIENTE") {
          return this.pedidos_venta_pendientes;
        }
        if (this.estado.toUpperCase() === "APROBADO") {
          return this.pedidos_venta_aprobados;
        }
      } else {
        return this.pedidos_venta;
      }
      return [];
    },

    ...mapState("pedidos", [
      "pedidos_venta_aprobados",
      "pedidos_venta_pendientes",
      "pedidos_venta",
    ]),
  },
  methods: {
    setDrawer(val){
      this.drawerVistaPrevia = val
    },
    ...mapActions("pedidos", ["fetch_pedidos_venta"]),
    abrirVistaPrevia(pedido) {
      console.log(pedido.cliente);
      this.pedido = pedido;
      if (this.estado == 'PENDIENTE'){
        this.dialogVistaPrevia  = true
      }else{

        this.drawerVistaPrevia = true;
      }
    },
    abrirPedido(pedido) {
      this.$router.push({
        name: "ActualizarPedidoVenta",
        params: {
          id: pedido.id,
          estado: this.estado,
        },
      });
    },
    quitarPedido(pedido) {
      console.log("Quitar pedido",pedido);
      const index = this.pedidos.findIndex(p => p.id == pedido.id)
      if (index > -1) {
        this.pedidos.splice(index, 1);
      }
    },

    fetchPedidos(estado) {
      if (estado) {
        this.fetch_pedidos_venta(estado);
      } else {
        this.fetch_pedidos_venta(null);
      }
    },
  },
  created() {
    if (this.estado) {
      this.fetchPedidos(this.estado);
    } else {
      this.fetchPedidos(null);
    }
  },
};
</script>

<style scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
