<template>
  <div class="d-flex flex-column">
    <div class="two-colors">
      <v-row justify="center" class="mt-2">
        <v-col cols="12" lg="8" md="10">
          <div class="h-100 d-flex flex-column">
            <div class="justify-content-center">
              <v-row>
                <v-col cols="6" md="6" lg="6">
                  <div class="info-cont flex-grow-1 text-center text-white">
                    <h4 class="">Sucursal</h4>
                    <h3>
                      <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                      {{ sucursal.direccion }}
                    </h3>
                  </div>
                </v-col>
                <v-col cols="6" md="6" lg="6">
                  <div class="info-cont flex-grow-1 text-center text-white">
                    <h4 class="">Caja</h4>
                    <h3>
                      <v-icon>mdi-desktop-classic</v-icon>
                      {{ caja.nombre }}
                    </h3>
                  </div>
                </v-col>
              </v-row>
              <ingreso-egreso-dinero
                :key="reload"
                :esIngreso="false"
                @process-dinero="processDinero"
                @close-dialog="$router.go(-1)"
              >
              </ingreso-egreso-dinero>
            </div>
          </div>
        </v-col>
      </v-row>

      <dialog-confirmation-close
        v-if="dialogConfirmarCierre"
        @close-dialog="dialogConfirmarCierre = false"
        @process-accept="cerrarArqueoCaja"
      >
        <template v-slot:titulo>¿Quiere cerrar la caja?</template>
        <template v-slot:message
          >¿Está seguro que quieres finalizarla? No podrá revertir los
          cambios.</template
        >
        <template v-slot:buttonOK>Finalizar</template>
      </dialog-confirmation-close>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import IngresoEgresoDinero from "@/views/actividad/ingreso.egreso.dinero.vue";
import ContarBilletes from "@/views/actividad/contar.billetes.vue";
import { getStorage } from "@/services/serviceLocalStorage";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "retiro.efectivo.caja.vue",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DialogConfirmationClose,
    IngresoEgresoDinero,
    ContarBilletes,
    Loading,
  },

  data() {
    return {
      dialogConfirmarCierre: false,
      reload: 0,
    };
  },

  computed: {
    sucursal() {
      return this.$store.state.sucursales.sucursal;
    },
    caja() {
      return this.$store.state.sucursales.caja;
    },
    ...mapState("notificaciones", ["alerta_retiro"]),
    ...mapGetters("notificaciones", [
      "bloqueoRetiroDinero",
      "bloqueoStockMinimo",
    ]),
  },
  methods: {
    ...mapActions("notificaciones", ["async_fetch_notificaciones"]),

    processDinero() {
      this.async_fetch_notificaciones().then(() => {
        this.reload += 1;
        this.$router.push("/ventas/nuevo");
      });
    },
    extraerDinero() {
      this.$router.push("/movimientos");
    },
  },

  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log(this.bloqueoRetiroDinero, this.alerta_retiro);
  //   if (this.alerta_retiro && this.bloqueoRetiroDinero) {
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
};
</script>

<style scoped>
.two-colors {
  background: #afcb08;
  background: linear-gradient(
    180deg,
    #afcb08 0,
    #afcb08 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );
}
</style>
