<template>
  <div>
    <v-select
      v-if="!categoriaSelect"
      v-model="categoria"
      :items="itemsCategoria"
      item-text="name"
      item-value="id"
      readonly
      label="Categoría"
      @mousedown="dialogCategoria = true"
      :outlined="outlined"
    ></v-select>

    <v-select
      :outlined="outlined"
      v-else
      label="Categoría"
      :items="[categoriaSelect]"
      v-model="categoriaSelect"
      item-text="name"
      item-value="id"
      clearable
      @click="changeCategoria()"
      @click:clear="clearData()"
    >
    </v-select>
    <v-dialog v-model="dialogCategoria" width="500">
      <v-card>
        <v-card-title>
          Seleccione una categoría
          <v-spacer></v-spacer>
          <v-icon large @click="dialogCategoria = false">mdi-close</v-icon>
        </v-card-title>
        <v-container>
          <keep-alive>
            <tree-categoria :categorias="categorias" @process-categoria="setCategoriaRegister"></tree-categoria>
          </keep-alive>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria.vue";
import CategoriasAPI from "@/api/categorias";
export default {
  name: "categoria.select",
  components: {
    TreeCategoria,
  },
  props: {
    categoria: {
      type: Object,
      required: false,
      // default: () => ({ categoria: { id: null } }),
      default: null
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialogCategoria: false,
      categoriaSelect: null,
      categorias: null
    };
  },
  computed: {
    itemsCategoria() {
      if (!this.categoria || this.categoria.id == null || this.categoria.name == "SIN CATEGORIA") {
        return [];
      } else {
        return [this.categoria];
      }
    },
  },

  methods: {
    changeCategoria() {
      this.categoriaSelect = null;
      this.dialogCategoria = true;
    },
    clearData() {
      this.categoriaSelect = null;
      this.$emit("clearable", this.categoria)
      this.$emit("process-categoria", null);

    },
    setCategoriaRegister(tp) {
      this.categoriaSelect = tp;
      this.dialogCategoria = false;
      this.$emit("process-categoria", tp);
    },
     fetchCategoria() {
      CategoriasAPI.index()
        .then((response) => {
          if (response.data.parent.children.length > 0) {
            this.categorias = response.data.parent
          } else {
            this.categorias = false;
          }
        });
    },
  },
  
 created() {
    this.fetchCategoria();
  },
  mounted() {
    if (this.categoria && this.categoria.id !== null && this.categoria.name !== "SIN CATEGORIA") {
      this.categoriaSelect = this.categoria;
    }
  },
};
</script>

<style lang="scss" scoped></style>
