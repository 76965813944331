<template>
  <layout-pedidos>
    <template v-slot:breadcrumbs>
      <!-- <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
        <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
          item.text
        }}</v-breadcrumbs-item>
      </v-breadcrumbs> -->
    </template>

    <template v-slot:title>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Hola {{ username }}</v-list-item-title>
            <!-- <v-list-item-subtitle>Dueño</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>
    <template v-slot:navigation-controller>
      <v-list  flat>
          <v-list-item exact  color="blue lighten-3" v-for="(item, i) in items" :key="i" :to="{name: item.route}">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <router-view></router-view>
    </template>
  </layout-pedidos>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import { mapState } from "vuex";

export default {
  name: "profile",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      navigation: [
        {
          text: "Alertas",
          disabled: false,
          to: { name: "ventas" },
        },
      ],

      item: 0,
      items: [],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sucursales",["configuracion"]),
    username() {
      return this.user.toUpperCase();
    },
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  mounted() {
    if (this.$route.path == "/profile") {
      this.$router.push("/profile/misdatos");
    }
  },
  created() {
    this.items.push(
      { text: "Mis datos", icon: "mdi-account-circle", route: "misdatos" },
      {
        text: "Mis consumos",
        icon: "mdi-cart-plus",
        route: "misConsumos",
      },
      {
        text: "Mis retiros",
        icon: "mdi-cart-plus",
        route: "misRetiros",
      }
    );
    if (this.configuracion.ventaConPedido) {
      this.items.push({ text: "Pedidos", icon: "mdi-account", route: "pedidoVenta" });
    }
    this.$store.commit("SET_LINKS", this.items);
  },
};
</script>

<style lang="scss" scoped></style>
