<template>
  <div>
    <v-list>
      <FormaDePagoItem v-for="formaDePago in formasDePago" :key="formaDePago.id" :formaDePago="formaDePago" @monto-cambiado="actualizarTotal"></FormaDePagoItem>
    </v-list>
  </div>
</template>


<script>
import FormaDePagoItem from "@/components/comprobante/formadepago/FormaDePagoItem.vue";


export default {
  name: "FormasDePagoList",
  components: {
    FormaDePagoItem,
  },
  props: {
    formasDePago: {
      type: Array,
      required: true,
    }
  },
  methods: {
    actualizarTotal(payload) {
      console.log("actualizarTotal", payload);
      this.$emit('total-cambiado', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>