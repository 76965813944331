import axios from 'axios'
const url = '/comprobantes/formaDePago'

export default {
    create(data){
        return axios.post(url, data)
    },
    update(id, data) {
        console.log(data);
        
        return axios.put(`${url}/${id}`, data)
    },
    index(){
        return axios.get(url)
    },
    delete(id) {
        return axios.get(`${url}/${id}`)
    },
   
    show(id) {
        return axios.get(`${url}/${id}`)
    },
   

}