<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="display-1" tag="h3">
              Movimientos de cuenta
            </v-list-item-title>
            <v-list-item-subtitle
              style="color: rgba(255, 255, 255, 0.45); font-size: 18px"
            >
              Conoce el detalle de las operaciones que impactan en tu cuenta
              corriente.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-data-table
          :headers="headers"
          :items="movimientos"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.fecha="{ item }">
            {{ moment(item.fecha).format("ll") }}
          </template>
          <template v-slot:item.verDetalle="{ item }">
            <div
              v-if="!item.recibo"
              style="color: #009ee3"
              @click="
                $router.push({
                  name: 'ClienteCuentaCorrienteMovimientosDetail',
                  params: { id_comprobante: item.comprobante },
                })
              "
            >
              Ver detalle
            </div>
          </template>
          <template v-slot:item.monto="{ item }">
            <span v-if="item.debe > 0">- {{ item.debe | convertPesoArg }}</span>
            <span v-if="item.haber > 0">
              {{ item.haber | convertPesoArg }}</span
            >
          </template>
          <template v-slot:item.saldo="{ item }">
            <span style="font-size: 1.2rem">{{
              item.saldo | convertPesoArg
            }}</span>
          </template>

          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay movimientos.</v-alert
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="movimientos"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import ClienteCuentaCorrienteAPI from "@/api/clientes/cuentacorriente.js";

export default {
  name: "ClienteListCtaCte",
  components: {},
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Nº de comp.", value: "descripcion" },
        { text: "Detalle", value: "verDetalle" },

        { text: "Importe", value: "monto" },

        { text: "Saldo", value: "saldo", sortable: false },
      ],
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },
      loading: false,
      movimientos: [],
    };
  },

  computed: {
    ...mapState("clienteCuentaCorriente", ["cuentaCorriente"]),
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.paginacion.page = pageNumber;
        this.fetchMovimientos();
      } else {
        this.paginacion.page = pageNumber;
        this.fetchMovimientos();
      }
    },

    handleClick(a) {
      this.$router.push({
        name: "clientesCuentasCorrientesDetalle",
        params: { id: a.id },
      });
    },
    fetchMovimientos() {
      ClienteCuentaCorrienteAPI.movimientos_cta_cte(
        this.$route.params.id_cliente,
        this.paginacion
      ).then((response) => {
        this.movimientos = response.data.results;
        this.paginacion.cantidad = response.data.count;
      });
    },
  },
  created() {
    this.fetchMovimientos();
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
