<template>
  <div>
      <v-card flat width="250px" >
    <v-list class="pb-0 pt-0 mb-0"  dense>
      <span class="text-body-2"><b>Marcas</b></span>
      <v-list-item
      class="mt-0 mb-0 pt-0"

        @click="set_select_data(marca)"
        v-for="marca in marcaFilter"
        :key="marca.id"
        link
      >
          <v-list-item-title>{{ marca.nombre }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="marcas.length > 5" link>
        <v-list-item-content>
          <v-list-item-title @click="dialog = true">
            <div class="blue--text">Ver todos</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
      </v-card>
    <v-dialog v-model="dialog" width="750">
      <v-card>
        <v-container>
          <v-card-title>
            Elegir Marca
            <v-spacer></v-spacer>
            <v-icon color="blue" @click="dialog=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col @click="set_select_data(m)" v-for="m in marcasOrdenados" :key="m.id" cols="3">
                <div>
                  <a class="white--text">{{m.nombre}}</a>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "MarcaListSelect",
  props: {
    marcas: {
      type: Array,
      required: true
    }
  },
  data() {
    return { dialog: false,item: null, };
  },
  computed: {
    marcaFilter() {
      if (this.marcas.length > 5) {
        return this.marcas.slice(0, 5);
      } else {
        return this.marcas;
      }
    },
    marcasOrdenados() {
      function compare(a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
        return 0;
      }
      return this.marcas.sort(compare);
    }
  },

  methods: {
    set_select_data(data) {
      this.dialog = false;
      this.$emit("set_marca", data);
    }
  },

  created() {}
};
</script>

<style lang="scss" scoped>
</style>