var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cliente)?_c('LayoutBasic',{attrs:{"showLinksMenu":false,"width":'400px'},scopedSlots:_vm._u([{key:"navigation-subtitle",fn:function(){return [_c('div',[_vm._v(" Clientes ")])]},proxy:true},{key:"navigation-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.cliente.persona.nombre)+" "+_vm._s(_vm.cliente.persona.apellido)+" ")]},proxy:true},{key:"navigation-subtitle-after",fn:function(){return [(_vm.cliente.es_empleado)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Empleado")]):_vm._e()]},proxy:true},{key:"navigation-content",fn:function(){return [_c('v-img',{attrs:{"src":_vm.noImage,"lazy-src":("https://picsum.photos/10/6?image=" + (15)),"aspect-ratio":"1","height":"150px","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}),_c('v-divider',{attrs:{"light":""}}),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'clienteHome' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Perfil")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'actualizarCliente',
            params: { cliente: _vm.cliente },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Editar perfil")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'direcciones', params: { cliente: _vm.cliente } })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-map-marker-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Direcciones")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'clienteContabilidad',
            params: { cliente: _vm.cliente },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-wallet-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Contabilidad")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'clientesCuentasCorrientesDetalle',
            params: { cliente: _vm.cliente },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-wallet-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Cuenta corriente")])],1)],1)],1)]},proxy:true},{key:"navigation-append",fn:function(){return [_c('v-container',[_c('v-btn',{attrs:{"color":"red","block":"","small":""},on:{"click":function($event){_vm.dialogDelete = true}}},[_vm._v("Eliminar cliente")]),(_vm.dialogDelete)?_c('layout-dialog-delete',{on:{"close-dialog":function($event){_vm.dialogDelete = false},"processDelete":_vm.eliminarCliente},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Eliminar clientppe")]},proxy:true},{key:"message",fn:function(){return [_vm._v(" ¿Desea eliminar el cliente ' "),_c('i',[_vm._v(_vm._s(_vm.cliente.persona.nombre)+" "+_vm._s(_vm.cliente.persona.apellido))]),_vm._v(" ' ? ")]},proxy:true}],null,false,3267078668)}):_vm._e()],1)]},proxy:true},{key:"vista-previa",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('router-view')],1)],1)],1)]},proxy:true}],null,false,3786665016)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }