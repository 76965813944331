<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/egresos'" v-if="egreso">
    <template v-slot:navigation-title> Actualizar egreso </template>
    <template v-slot:navigation-content>
      <v-container>
        <egreso-form :egreso="egreso"></egreso-form>
        <div v-if="false">
          <v-hover v-slot:default="{ hover }">
            <v-card
              color="primary"
              @click="dialogDetalleRegistrar = true"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-container>
                <span class="justify-center">
                  <v-icon>mdi-plus</v-icon>
                  <span class="text-center"> Agregar detalle </span>
                </span>
              </v-container>
            </v-card>
          </v-hover>
          <v-list dense v-if="detalles_server_delete.length > 0">
            <v-subheader>Papelera</v-subheader>
            <v-list-item
              v-for="(detalle, index) in detalles_server_delete"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>{{ detalle.descripcion }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title class="align-end text-end">
                  {{
                    Number(detalle.monto) | convertPesoArg
                  }}</v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-action class="align-center">
                <v-btn @click="restaurarDetalle(detalle)" icon>
                  <v-icon>mdi-delete-restore</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-dialog v-if="false" width="600" v-model="dialogDetalleRegistrar">
          <ValidationObserver ref="formDetalleRegistrar">
            <div slot-scope="{ invalid, untouched }">
              <v-card>
                <v-container>
                  <v-card-title>
                    Nuevo detalle
                    <v-spacer></v-spacer>
                    <v-icon color="blue" @click="dialogDetalleRegistrar = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>

                  <VTextAreaWithValidation
                    outlined
                    label="Descripción"
                    autofocus
                    v-model="detalle.descripcion"
                    placeholder="descripcion"
                    rules="required|max:100"
                    :counter="30"
                    name="descripción"
                    rows="3"
                    row-height="30"
                  />
                  <VTextFieldWithValidation
                    outlined
                    label="monto"
                    v-model="detalle.monto"
                    rules="required|decimal|max:30"
                    :counter="30"
                    name="monto"
                    @keyup.enter="añadirDetalle()"
                  />
                  <v-card-actions class="justify-end">
                    <v-btn @click="dialogDetalleRegistrar = false"
                      >Cancelar</v-btn
                    >

                    <v-btn
                      color="success"
                      @click="añadirDetalle()"
                      :disabled="invalid"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </div>
          </ValidationObserver>
        </v-dialog>
        <v-dialog width="600" v-model="dialogDetalleActualizar">
          <ValidationObserver ref="formDetalleActualizar">
            <div slot-scope="{ invalid, untouched }">
              <v-card>
                <v-container>
                  <v-card-title>
                    Actualizar detalle
                    <v-spacer></v-spacer>
                    <v-icon
                      color="blue"
                      @click="dialogDetalleActualizar = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>

                  <VTextAreaWithValidation
                    outlined
                    label="Descripción"
                    autofocus
                    v-model="detalle.descripcion"
                    placeholder="descripcion"
                    rules="required|max:100"
                    :counter="30"
                    name="descripción"
                    rows="3"
                    row-height="30"
                  />
                  <VTextFieldWithValidation
                    outlined
                    label="monto"
                    v-model="detalle.monto"
                    rules="required|decimal|max:30"
                    :counter="30"
                    name="monto"
                    @keyup.enter="modificarDetalle(detalle)"
                  />
                  <v-card-actions class="justify-end">
                    <v-btn @click="dialogDetalleActualizar = false"
                      >Cancelar</v-btn
                    >

                    <v-btn
                      color="success"
                      @click="modificarDetalle(detalle)"
                      :disabled="invalid"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </div>
          </ValidationObserver>
        </v-dialog>
      </v-container>
    </template>
    <template v-slot:navigation-append>
      <v-divider></v-divider>

      <v-btn class="mb-2" large block color="green" @click="actualizarEgreso()"
        >Actualizar</v-btn
      >
    </template>

    <template v-slot:vista-previa>
      <v-container v-if="false" fill-height fluid>
        <v-row align="center" class="mt-2 mb-2 ml-2 mr-2">
          <v-col cols="12">
            <v-subheader>Detalles del egreso</v-subheader>
            <v-list>
              <v-list-item v-for="detalle in egreso.detalles" :key="detalle.id">
                <v-list-item-content>
                  <v-list-item-title>{{
                    detalle.descripcion
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center"
                  ><div style="font-size: 20px">
                    {{ Number(detalle.monto) | convertPesoArg }}
                  </div></v-list-item-icon
                >
                <v-list-item-icon class="align-center">
                  <v-btn icon @click="openDialogEditar(detalle)">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                  <v-btn icon @click="quitarDetalle(detalle)">
                    <v-icon>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <div v-if="egreso && egreso.tipoIngresoEgreso">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    color="#121212"
                    @click="dialogDetalleRegistrar = true"
                    :elevation="hover ? 12 : 4"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-container>
                      <span class="justify-center">
                        <v-icon>mdi-plus</v-icon>
                        <span class="text-center"> Agregar detalle </span>
                      </span>
                    </v-container>
                  </v-card>
                </v-hover>
              </div>
            </v-list>
            <v-divider></v-divider>
            <v-list class="align-end">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Cantidad items</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon
                  >{{ egreso.detalles.length }}
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Subtotal</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon
                  ><h3>{{ Number(subtotal()) | convertPesoArg }}</h3>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import { ValidationObserver } from "vee-validate";
import InformationDialogIcon from "@/components/dialogs/information-dialog.icon.vue";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";

import EgresoForm from "@/components/egresos/EgresoForm";

export default {
  name: "egreso.actualizar",
  inject: {
    $validator: "$validator",
  },
  components: {
    LayoutBasic,
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
    InformationDialogIcon,
    VSelectWithValidation,
    EgresoForm,
  },
  data() {
    return {
      dialogDetalleRegistrar: false,
      dialogDetalleActualizar: false,
      egreso: null,
      tiposIngresoEgreso: [],
      detalle: { descripcion: "", monto: null },

      isLoading: false,
      detalles_server_update: [],
      detalles_server_delete: [],
      detalles_server_new: [],
    };
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },

  methods: {
    async añadirDetalle() {
      const result = await this.$refs.formDetalleRegistrar.validate();

      if (result) {
        this.egreso.detalles.push(this.detalle);
        this.detalles_server_new.push(this.detalle);

        this.detalle = { descripcion: "", monto: null };
        this.$refs.formDetalleRegistrar.reset();
        this.dialogDetalleRegistrar = false;
      } else {
        this.$validator.validate();
      }
    },
    restaurarDetalle(detalle) {
      const index = this.detalles_server_delete.indexOf(detalle);

      if (index > -1) {
        delete detalle.accion;
        // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor
        this.egreso.detalles.push(detalle);
        this.detalles_server_delete.splice(index, 1);
      }
    },
    quitarDetalle(detalle) {
      const index = this.egreso.detalles.indexOf(detalle);

      if (index > -1) {
        if ("id" in detalle) {
          detalle.accion = 0;
          // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor
          this.detalles_server_delete.push(detalle);
          this.egreso.detalles.splice(index, 1);
        } else {
          const index2 = this.detalles_server_new.indexOf(detalle);

          this.detalles_server_new.splice(index2, 1);

          this.egreso.detalles.splice(index, 1);
        }
      }
    },
    openDialogEditar(detalle) {
      this.dialogDetalleActualizar = true;

      this.detalle = { ...detalle };
    },
    modificarDetalle(detalle) {
      var detalles = this.egreso.detalles;

      let detalle_para_mostrar = detalles.find((d) => d.id == detalle.id);

      let detalle_del_server = this.detalles_server_update.find(
        (d) => d.id == detalle.id
      );
      if (detalle_del_server) {
        detalle_del_server.descripcion = detalle.descripcion;
        detalle_del_server.monto = detalle.monto;
        detalle_para_mostrar.monto = detalle.monto;
        detalle_para_mostrar.descripcion = detalle.descripcion;
      }

      for (var i in detalles) {
        if (detalles[i].descripcion == detalle.descripcion) {
          if (detalles[i].monto == detalle.monto) {
            this.dialogDetalleActualizar = false;

            break; //Stop this loop, we found it!
          } else {
            this.detalles_server_update.push(detalle);

            detalle_para_mostrar.monto = detalle.monto;
            this.detalle = { descripcion: "", monto: null };

            this.dialogDetalleActualizar = false;
          }
        } else {
          detalle_para_mostrar.descripcion = detalle.descripcion;

          this.detalles_server_update.push(detalle);
          this.detalle = { descripcion: "", monto: null };

          this.dialogDetalleActualizar = false;
        }
      }
    },
    subtotal() {
      var subtotal = 0;
      if (this.egreso) {
        for (let index = 0; index < this.egreso.detalles.length; index++) {
          var precio = parseFloat(this.egreso.detalles[index].monto);
          subtotal = subtotal + precio;
        }
      }

      return subtotal;
    },
    async actualizarEgreso() {
      try {
        var detalles = this.detalles_server_update;

        if (this.detalles_server_delete.length > 0) {
          detalles = detalles.concat(this.detalles_server_delete);
        }

        if (this.detalles_server_new.length > 0) {
          detalles = detalles.concat(this.detalles_server_new);
        }
        this.egreso.detalles = detalles;
        // var egreso = {
        //   monto: this.egreso.monto,
        //   detalles: detalles,
        //   tipoIngresoEgreso: this.egreso.tipoIngresoEgreso,
        //   descripcion: this.egreso.descripcion,
        // };

        const response = await this.axios.put(
          `/egresos/ingresoegreso/${this.egreso.id}`,
          this.egreso
        );

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Egreso actualizado",
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.goToRoute("egresos");
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: error.response.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    async eliminarEgreso(egreso) {
      this.axios
        .delete(`/egresos/ingresoegreso/${egreso.id}`)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Egreso eliminado",
            icon: "mdi-check",
            timeout: 2500,
          });

          this.goToRoute("egresos");
        })
        .catch((error) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inseperado",
            icon: "mdi-information",
            timeout: 2500,
          });
        });
    },

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async fetchEgreso(id) {
      const response = await this.axios.get(`/egresos/ingresoegreso/${id}`);

      this.egreso = response.data;
    },
    async fetchTipos() {
      const response = await this.axios.get(
        "/egresos/tipoingresoegreso?esIngreso=False"
      );
      this.tiposIngresoEgreso = response.data;
    },
  },

  created() {
    this.$store.commit("SET_LAYOUT", {
      layout: "layout-basic",
      withNav: false,
    });
    let id = this.$route.params.id;
    this.fetchTipos();

    this.fetchEgreso(id);
  },
};
</script>

<style lang="scss" scoped></style>
