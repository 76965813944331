<template>
  <div>
    <!-- <v-data-table hide-default-footer :headers="headers" :items="detalles">
      <template v-slot:item.cantidad="props">
        <v-edit-dialog
          @cancel="cancel"
          @open="open(props.item.cantidad)"
          @save.native="save"
        >
          {{ props.item.cantidad }}
          <template v-slot:input>
            <v-text-field
              v-model="props.item.cantidad"
              v-validate="'numeric|max:5'"
              :error-messages="errors.collect('cantidad')"
              data-vv-name="cantidad"
              label="Cantidad"
              type="number"
              min="0"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:footer>
        <v-row justify="end">
          <v-col cols="3">Subtotal ${{subtotal}}</v-col>
        </v-row>
      </template>
    </v-data-table> -->
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Cantidad</th>
            <th class="text-left">Producto</th>
            <th class="text-left">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="modificarDetalle(item)" v-for="(item, i) in detalles" :key="i">
            <td>{{ item.cantidad }}</td>
            <td>{{ item.producto.descripcion }}</td>
            <td>{{ item.producto.precioCosto }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row justify="end">
      <v-col cols="3">Subtotal ${{ subtotal }}</v-col>
    </v-row>
    <v-dialog v-if="detalle_update" v-model="dialog_detalle" width="500">
      <detalle-pedido-form :detalle="detalle_update"></detalle-pedido-form>
    </v-dialog>
  </div>
</template>

<script>
import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";

export default {
  name: "TablaDetallePedido",
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  components: {
    DetallePedidoForm,
  },
  computed: {
    subtotal() {
      var subtotal = 0;
      if (this.detalles.length > 0) {
        for (let index = 0; index < this.detalles.length; index++) {
          var precio =
            this.detalles[index].cantidad * this.detalles[index].producto.precioCosto;
          subtotal += precio;
        }
      }

      return subtotal;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "cantidad",
        },
        { text: "Producto", value: "producto.descripcion" },
        { text: "Precio", value: "producto.precioCosto" },
      ],
      cantidad: null,
      detalle_update: {},
      dialog_detalle: false,
    };
  },
  methods: {
    modificarDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
    save(cantidad) {
      event.preventDefault();
      var numberCantidad = parseInt(cantidad);
      if (numberCantidad > 0 && numberCantidad !== null) {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Guardado";
        cantidad = 0;
        // call form submission logic
      } else {
        this.$validator.validate();
        cantidad = this.cantidad;
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceledo";
    },
    open(cantidad) {
      this.cantidad = cantidad;
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialogo abierto";
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
      event.preventDefault();

      if (event.keyCode === 13) {
        // Cuando el modal para agregar la cantidad esta abierto
        if (
          this.detalle_update.cantidad !== "" &&
          this.detalle_update.cantidad !== "0" &&
          parseInt(this.detalle_update.cantidad) < 100000 &&
          this.dialog_detalle == true
        ) {
          this.$emit("modificarDetalle", this.detalle_update);

          this.dialog_detalle = false;
          return;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
