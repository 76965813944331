<template>
  <ValidationObserver ref="observer">
    <v-form @submit.prevent="submitForm" slot-scope="{ invalid, validated }">
      <VTextFieldWithValidation
        outlined
        :rules="'numeric'"
        name="Número"
        v-model="asiento.numero"
        label="Número"
      />
    
    </v-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";

export default {
  name: "AsientoContableForm",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },
  inject: {
    $validator: "$validator",
  },
  props: {
    asiento: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async validateForm() {
      return this.$refs.observer.validate();
    },
  },
};
</script>
