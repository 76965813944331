<template>
  <v-card>
    <v-container>
      <v-card-title class="justify-center"
        >Egreso {{ egreso.id }} <v-spacer></v-spacer>
        <v-btn large color="grey" icon @click="$emit('close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-alpha-p-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="egreso.categoria"
                  ><b>{{ egreso.categoria.name }} asf</b>
                </v-list-item-title>
                <v-list-item-title
                  ><b>{{ egreso.tipoIngresoEgreso.descripcion }}</b>
                </v-list-item-title>
                <!-- <v-list-item-subtitle class="text--primary">{{
                  pedido.tipoEntrega == 0 ? "Entrega en el local" : "Para retirar"
                }}</v-list-item-subtitle> -->
                <v-list-item-subtitle
                  >{{ egreso.descripcion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-row justify="center" align="start">
            <v-col cols="4" class="text-center align-start">
              <v-btn @click="verEgreso" :loading="loadingButton" x-large icon>
                <v-icon dark> mdi-circle-edit-outline </v-icon>
              </v-btn>
              <div class="text-center" id="buttonAccion">
                <span> Editar</span>
              </div>
            </v-col>
            <v-col cols="4" class="text-center align-start">
              <v-btn
                @click="dialogDelete = true"
                :loading="loadingButton"
                x-large
                icon
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <div class="text-center" id="buttonAccion">
                <span> Eliminar</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-subheader>Detalles del egreso</v-subheader>
          <v-list dense>
            <v-list-item
              dense
              v-for="detalle in egreso.detalles"
              :key="detalle.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ detalle.descripcion }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-center"
                ><div>
                  {{ Number(detalle.monto) | convertPesoArg }}
                </div></v-list-item-icon
              >
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="align-end">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>Cantidad items</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>{{ egreso.detalles.length }} </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Total</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon
                ><h3 style="font-size: 20px">
                  {{ egreso.monto | convertPesoArg }}
                </h3>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <layout-dialog-delete
      @close-dialog="dialogDelete = false"
      @processDelete="eliminar(egreso)"
      v-if="dialogDelete"
    >
      <template v-slot:title> Eliminar egreso </template>
      <template v-slot:message> ¿Desea eliminar el egreso? </template>
    </layout-dialog-delete>
  </v-card>
</template>

<script>
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

export default {
  name: "egreso.vista.previa",
  components: {
    LayoutDialogDelete,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    egreso: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogDelete: false,
      loadingButton: false,
      tooltip: false,
    };
  },
  computed: {
    subtotal() {
      var subtotal = 0;
      if (this.egreso) {
        for (let index = 0; index < this.egreso.detalles.length; index++) {
          var precio = parseFloat(this.egreso.detalles[index].monto);
          subtotal = subtotal + precio;
        }
      }

      return subtotal;
    },
    fechaAlta() {
      return this.moment(this.egreso.fechaAlta).format("LLL");
    },
  },
  methods: {
    verEgreso() {
      this.$router.push({
        name: "egresoActualizar",
        params: { id: this.egreso.id },
      });
    },

    eliminar(egreso) {
      this.axios
        .delete(`/egresos/ingresoegreso/${egreso.id}`)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Egreso eliminado",
            icon: "mdi-check",
            timeout: 2500,
          });
          this.$emit("process-eliminar-success", this.egreso);
          this.dialogDelete = false;
          this.$emit("close-dialog");
        })
        .catch((error) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inseperado",
            icon: "mdi-information",
            timeout: 2500,
          });
          this.$emit("close-dialog");
        });
    },
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
