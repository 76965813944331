<template>
  <div>
    <categoria-form
      @cerrar-dialog="$emit('cerrar-dialog')"
      @processCategoria="registrarCategoria"
      :categoria="categoria"
      :key="reload_input_categoria"
      :withColor="withColor"
    ></categoria-form>
    <cateogoria-alerta-productos-asignados
      v-if="show"
      @close-dialog="show = false"
      @refresh-categorias="$emit('refresh-categorias')"
      :categoria="categoria"
      :categoriaPadre="categoriaPadre"
      :productos="productos"
    ></cateogoria-alerta-productos-asignados>
  </div>
</template>

<script>
import CategoriaForm from "@/components/categorias/categoria.form";
import CategoriaAlertaProductosAsignados from "@/components/categorias/cateogoria.alerta.productos.asignados.vue";
export default {
  name: "categoria.create",
  components: {
    CategoriaForm,
    CategoriaAlertaProductosAsignados,
  },
  props: {
    categoriaPadre: {
      type: Object,
    },
    withColor: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      categoria: { name: "" },
      productos: null,
      show: false,
      reload_input_categoria: 0,
    };
  },
  computed: {},
  methods: {
    registrarCategoria(data) {
     this.$emit('save-category', data)
    },
  },
};
</script>

<style lang="scss" scoped></style>
