<template>
  <ValidationObserver ref="observer">
    <v-form @submit.prevent="submitForm" slot-scope="{ invalid, validated }">
      <VTextFieldWithValidation
        outlined
        autofocus

        :rules="'required'"
        name="código"
        v-model="asientoTipo.codigo"
        label="Código"
      />
      <VTextFieldWithValidation
        outlined
        :rules="'required'"
        name="descripción"
        v-model="asientoTipo.descripcion"
        label="Descripción"
      />
      <VTextAreaWithValidation
        outlined
        label="Comentario"
        v-model="asientoTipo.comentario"
        placeholder="comentario"
        rules="max:100"
        :counter="100"
        name="comentario"
        rows="3"
        row-height="30"
      />
    </v-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";

export default {
  name: "AsientoTipoForm",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },
  inject: {
    $validator: "$validator",
  },
  props: {
    asientoTipo: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async validateForm() {
      return this.$refs.observer.validate();
    },
  },
};
</script>
