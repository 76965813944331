<template>
  <v-card>
    <v-container>
      <formasdepago-list :formasDePago="formasDePagoCtaCte"></formasdepago-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><h2>Total</h2></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon
          ><h2>{{ Number(get_total) | convertPesoArg }}</h2>
        </v-list-item-icon>
      </v-list-item>
      <template v-if="totalPagoCliente > get_total">
        <v-switch
          v-model="dejar_vuelto_en_cuenta"
          label="El vuelto queda en cuenta."
        ></v-switch>
        <v-list-item dense v-if="dejar_vuelto_en_cuenta">
          <v-alert text color="info">
            <h3 class="text-h5">Saldo a favor</h3>
            <div>El vuelto quedará como saldo a favor en la cuenta.</div>
          </v-alert>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <div>
                <h2 class="orange--text">Vuelto:</h2>
              </div></v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-icon>
            <h2 class="orange--text">{{ vuelto() | convertPesoArg }}</h2>
          </v-list-item-icon>
        </v-list-item>
      </template>

      <v-list-item dense v-else>
        <v-list-item-content>
          <v-list-item-title>
            <div>
              <h2 class="orange--text">Faltan:</h2>
            </div></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon>
          <h2 class="orange--text">{{ faltante() | convertPesoArg }}</h2>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn
              @click="pagarCuentaCorriente"
              color="success"
              :disabled="totalPagoCliente < 1"
              block
              >Pagar</v-btn
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-container>
  </v-card>
</template>

<script>
import FormasdepagoList from "@/components/comprobante/formasdepago.list.vue";
import ClienteCuentaCorrienteAPI from "@/api/clientes/cuentacorriente.js";

import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "cliente.cuentacorriente.pagar",
  components: {
    FormasdepagoList,
  },
  props: {
    cuentaCorriente: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dejar_vuelto_en_cuenta: false,
    };
  },
  computed: {
    ...mapGetters("comprobantes", ["formasDePago"]),
    ...mapGetters("ventas", ["totalPagoCliente", "get_total"]),
    ...mapState("ventas", ["formasDePagoSeleccionadas"]),

    formasDePagoCtaCte() {
      return this.formasDePago.filter((fp) => fp.nombre !== "CTA. CTE.");
    },
  },
  methods: {
    ...mapMutations("ventas", ["SET_SUBTOTAL"]),
    vuelto() {
      return Math.round(this.totalPagoCliente - this.get_total);
    },
    faltante() {
      return Math.round(this.get_total - this.totalPagoCliente);
    },
    pagarCuentaCorriente() {
      var monto_de_pago = this.totalPagoCliente;

      // if (this.vuelto() > 0 && !this.dejar_vuelto_en_cuenta) {
      //   monto_de_pago = this.get_total;
      // }

      var data = {
        cliente: this.cuentaCorriente.cliente,
        comprobante: 0,
        total: monto_de_pago,
        formaDePago: this.formasDePagoSeleccionadas,
      };
      ClienteCuentaCorrienteAPI.pagar(data)
        .then((response) => {
          this.$emit("refresh-cuenta-corriente");
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pago registrado",
            icon: "mdi-check",
            timeout: 3000,
          });
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.$emit("close-dialog-cobrar");
        });
    },
  },
  created() {
    this.SET_SUBTOTAL(this.cuentaCorriente.saldo);
  },
};
</script>

<style lang="scss" scoped>
</style>