<template>
  <div>
    <v-app-bar :prominent="false" flat app clipped-left>
      <v-toolbar-title>
          <v-btn class="mr-2" style="background-color: #4e4f50" large icon @click="$router.go(-1)">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
        <a @click="goToRoute('/home')" class="siteLogo mt-2"></a>
      </v-toolbar-title>
      <div style="width: 80px"></div>
      <slot name="title">Compras</slot>

      <v-spacer></v-spacer>
      <slot name="bar-before-spacer"></slot>

      <template v-slot:extension>
        <slot name="bar-extension"></slot>
      </template>
      <div style="width: 100px"></div>
      <v-progress-linear
        v-if="false"
        slot="extension"
        :indeterminate="true"
      ></v-progress-linear>
    </v-app-bar>
      <slot name="drawer"></slot>
   
    <v-container style="position: absolute  height:auto;">
      <slot name="arriba-vista-previa"></slot>
    </v-container>

    <v-footer app height="72" style="opacity: 0.7" inset>
      <slot name="search-barcode"></slot>
    </v-footer>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex";

export default {
  name: "layout.menu.derecha",
  data: () => ({
    drawer: null,
    snackbar: {
      show: false,
      color: "",
      mode: "",
      timeout: null,
      text: "",
      top: true,
      right: true,
      icon: null,
      vertical: false,
    },
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {

    goToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).catch((e) => {});
      }
    },
   
  },
  mounted() {
    this.$updateBus.$on("showSnackBar", (snackbar) => {
      this.snackbar = Object.assign(this.snackbar, snackbar);
    });
  },
 
};
</script>
<style scoped>

.siteLogo {
  position: absolute;
  top: 0;
  background-image: url("../assets/bear-face-icon-2.png"), none;
  background-size: 53px;
  /* filter: brightness(1.1);
  mix-blend-mode: multiply; */
  width: 113px;
  height: 80px;
}

.items2:hover ::-webkit-scrollbar {
  width: 8px;
}
.items2 ::-webkit-scrollbar {
  width: 4px;
}
/* width */

/* Track */
.items2 ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.items2 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
