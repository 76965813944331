<template>
  <v-row>
    <v-col cols="6">
      <!-- <v-subheader>Prefix for dollar currency</v-subheader> -->
      <v-list-item-title>
        <v-checkbox
          class="mt-0 pt-0"
          dense
          v-model="selected"
          :label="formaDePago.nombre"
          :value="formaDePago.nombre"
          hide-details=""
        ></v-checkbox>
      </v-list-item-title>
    </v-col>

    <v-col cols="6" style="padding: 0">
      <!-- :disabled="formaDePago != formaDePago.nombre || formaDePago != 'EFECTIVO'" -->
      <template v-if="formaDePago.nombre === 'CTA. CTE.'">
        <formadepago-ctacte
          @quitar-forma-pago="quitarFormaDePagoCtaCte"
          @close-dialog="dialogCuentaCorriente = false"
          @set-monto-forma-pago="setFormaPagoCtaCte"
          :formaDePago="formaDePago"
          v-if="dialogCuentaCorriente"
        ></formadepago-ctacte>
        <div v-if="montoSelected && montoSelected > 0">
          <v-btn class="text-start" block @click="dialogCuentaCorriente = true">
            <span class="text-start" style="text-align: right">
              {{ montoSelected }}
            </span>
          </v-btn>
        </div>
        <v-btn
          v-else
          block
          text
          @click="dialogCuentaCorriente = true"
          color="green"
          :disabled="selected !== 'CTA. CTE.'"
          >Seleccionar cuenta</v-btn
        >
      </template>
      <template v-if="formaDePago.nombre !== 'CTA. CTE.'">
        <v-text-field
          :name="formaDePago.nombre"
          :disabled="selected == null || bloquear"
          :autofocus="selected == formaDePago.nombre"
          dense
          :outlined="selected != null"
          v-model="montoSelected"
          type="number"
          min="0"
        >
        </v-text-field>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FormadepagoCtacte from "@/components/comprobante/formadepago.ctacte.vue";

export default {
  components: { FormadepagoCtacte },
  name: "FormaDePago",

  props: {
    formaDePago: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
     
      dialogCuentaCorriente: false,
    };
  },
  computed: {
    ...mapGetters("ventas", ["bloquear_forma_pago"]),
    bloquear() {
      return this.$store.getters["ventas/bloquear_forma_pago"](
        this.formaDePago.nombre
      );
    },
    selected: {
      get() {
        return this.$store.getters[
          "ventas/get_nombre_forma_de_pago_seleccionada"
        ](this.formaDePago.nombre);
      },
      set(value) {
        console.log(value);
        if (value == "CTA. CTE.") {
          this.habilitarBotonCtaCte = true;
        }
        this.agregar_quitar_forma_de_pago(this.formaDePago.nombre);
      },
    },

    montoSelected: {
      get() {
        return this.$store.getters[
          "ventas/get_monto_forma_de_pago_seleccionada"
        ](this.formaDePago.nombre);
      },
      set(monto) {
        console.log(monto);
        var nombre = this.formaDePago.nombre;
        this.agregar_monto_forma_de_pago({
          descripcion: nombre,
          monto: monto,
        });
      },
    },
  },
  methods: {
    ...mapActions("ventas", [
      "agregar_monto_forma_de_pago",
      "agregar_quitar_forma_de_pago",
    ]),

    disabledFormaDePago(fp) {
      return !this.formaDePago.includes(fp);
    },
    setFormaPagoCtaCte(monto) {
      var nombre = 'CTA. CTE.'
      this.agregar_monto_forma_de_pago({
        descripcion: nombre,
        monto: monto,
      });
    },
    quitarFormaDePagoCtaCte(fp) {
      this.agregar_quitar_forma_de_pago(fp.nombre);
    },
  },
};
</script>

<style lang="scss" scoped></style>
