<template>
  <div>
    <div class="display-1">Promociones</div>
    <promocion-buscar
      class="mt-4"
      @resultado-buscar-promocion="setPromociones"
      @focus.native="$event.target.select()"
      @loading-buscar-promocion="setLoadingBuscador"
    ></promocion-buscar>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div>
      <promocion-list-card
        v-if="!loading"
        :isVenta="isVenta"
        @process-promocion="eventprocessPromocion"
        :promociones="promociones"
      ></promocion-list-card>
    </div>
  </div>
</template>

<script>
import PromocionListCard from "@/views/promocion/promocion.list.card.vue";
import PromocionBuscar from "./promocion.buscar.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "promocion.consultar",
  components: {
    PromocionBuscar,
    PromocionListCard,
    Loading,
  },
  props: {
    isVenta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      promociones: [],
      loading: false,
    };
  },
  methods: {
    setLoadingBuscador(bool) {
      this.loading = bool;
    },
    setPromociones(promociones) {
      this.promociones = promociones;
    },
    eventprocessPromocion(promocion) {
      this.$emit("process-promocion", promocion);
    },
    async fetchPromociones() {
      try {
        const response = await this.axios.get("/promociones/");

        this.promociones = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchPromociones();
  },
};
</script>

<style lang="scss" scoped></style>
