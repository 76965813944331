var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('loading',{attrs:{"active":_vm.loadingProductosFrecuentes,"is-full-page":false,"background-color":"transparent","color":"#FA0050"},on:{"update:active":function($event){_vm.loadingProductosFrecuentes=$event}}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingProductosFrecuentes),expression:"!loadingProductosFrecuentes"}]},_vm._l((_vm.productos_mas_vendidos),function(producto){return _c('v-col',{key:producto.id,attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',_vm._g({staticClass:"mx-auto",attrs:{"hover":"","color":"'grey darken-1'","height":"100","disabled":_vm.inventario &&
              producto.controlaStock &&
              producto.cantidadstock <= 0},on:{"click":function($event){return _vm.validate(producto)}}},on),[(_vm.esPedidoYa)?_c('v-card-title',{staticClass:"ma-0 mt-1 pa-0"},[_c('v-spacer'),_c('v-img',{attrs:{"src":require("@/assets/pedidosya/pedidoYa.svg"),"max-height":"20","max-width":"20","contain":""}})],1):_vm._e(),_c('v-card-text',{staticClass:"my-4 mt-1 text-center"},[_c('div',{staticClass:"text-truncate text-h5"},[_vm._v(" "+_vm._s(producto.descripcion)+" ")]),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(producto.precioVenta))+" ")]),_c('div',[(
                    _vm.inventario &&
                    producto.controlaStock &&
                    producto.cantidadstock <= 0
                  )?_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v("Sin stock")]):_vm._e()],1),_c('div',[(
                    _vm.inventario &&
                    producto.controlaStock &&
                    producto.cantidadstock > 0
                  )?_c('v-chip',{attrs:{"small":"","color":"info"}},[_vm._v(_vm._s(producto.cantidadstock))]):_vm._e()],1)])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(producto.descripcion))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }