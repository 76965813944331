<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/egresos'">
    <template v-slot:navigation-title>Tipos de egreso </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item @click="dialogCreate = true">
          <v-list-item-icon>
            <v-icon v-text="'mdi-plus'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="'Nuevo tipo de egreso'"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-dialog width="500" v-model="dialogCreate">
        <v-card>
          <v-container>
            <ValidationObserver ref="formDetalle">
              <div slot-scope="{ invalid }">
                <v-card flat>
                  <v-container>
                    <v-card-title class="justify-center"
                      >Nuevo tipo de egreso <v-spacer></v-spacer>
                      <v-btn
                        large
                        color="grey"
                        icon
                        @click="dialogCreate = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <VTextFieldWithValidation
                                label="Tipo de egreso"
                                autofocus
                                v-model="tipo.descripcion"
                                @keyup.enter="registrarTipo()"
                                rules="required|max:30"
                                :counter="30"
                                name="Tipo de egreso"
                              />
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >Cuenta Contable:
                              <CuentasTreeviewDialog
                                :planDeCuentas="planDeCuentas"
                                :initialCuenta="tipo.cuenta_contable"
                                @update:initialCuenta="setCuentaCorriente"
                              ></CuentasTreeviewDialog>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialogCreate = false">Cancelar</v-btn>

                      <v-btn
                        color="green"
                        :disabled="invalid || !tipo.cuenta_contable"
                        @click="registrarTipo()"
                        >Registrar</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-card>
              </div>
            </ValidationObserver>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog width="500" v-model="dialogEdit">
        <ValidationObserver ref="edit">
          <v-card slot-scope="{ invalid }">
            <v-container>
              <v-card-title class="justify-center">
                Editar tipo de egreso<v-spacer></v-spacer>
                <v-btn large color="grey" icon @click="dialogEdit = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <VTextFieldWithValidation
                        label="Tipo de egreso"
                        autofocus
                        v-model="tipoEdit.descripcion"
                        @keyup.enter="modificar()"
                        rules="required|max:30"
                        :counter="30"
                        name="Tipo de egreso"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Cuenta Contable:
                      <CuentasTreeviewDialog
                        :planDeCuentas="planDeCuentas"
                        :initialCuenta="tipoEdit.cuenta_contable"
                        @update:initialCuenta="setCuentaCorrienteEdit"
                      ></CuentasTreeviewDialog>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdit = false">Cancelar</v-btn>

                <v-btn
                  large
                  color="green"
                  :disabled="invalid || !tipoEdit.cuenta_contable"
                  @click="modificar()"
                  >Actualizar</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card>
        </ValidationObserver>
      </v-dialog>
    </template>

    <template v-slot:vista-previa>
      <v-row justify="center" class="fill-height" align="start">
        <v-col>
          <v-subheader>Lista</v-subheader>
          <v-list v-if="tiposIngresoEgreso.length > 0">
            <v-list-item
              v-for="(tipo, index) in tiposIngresoEgreso"
              :key="index"
            >
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ tipo.descripcion }}</v-list-item-title>
                <v-list-item-subtitle>
                  <a @click="openDialogEliminar(tipo)"> Eliminar</a>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div v-if="tipocuenta_contable">
                  {{ tipo.cuenta_contable.codigo }} -
                  {{ tipo.cuenta_contable.nombre }}
                </div>
                <div v-else>
                  <v-icon>mdi-folder-question</v-icon>
                </div>
              </v-list-item-action>
              <v-list-item-icon class="align-center">
                <v-btn icon @click="editarTipo(tipo)">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list-item v-else>
            <v-list-item-title>No hay registros</v-list-item-title>
          </v-list-item>
        </v-col>
      </v-row>
      <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="closeDialogEliminar"
      @process-accept="eliminar()"
    >
      <template v-slot:titulo>¿Quieres eliminar el tipo de egreso?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar "{{ tipoDelete.descripcion }}"
        ?</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import { ValidationObserver } from "vee-validate";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";


import { mapActions, mapState } from "vuex";

export default {
  name: "tipo.ingreso.egreso",
  inject: {
    $validator: "$validator",
  },
  components: {
    LayoutBasic,
    VTextFieldWithValidation,
    ValidationObserver,
    CuentasTreeviewDialog,
    DialogConfirmationClose
  },
  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },

  data() {
    return {
      tiposIngresoEgreso: [],
      tipoEdit: null,
      tipo: { descripcion: null, esIngreso: false, cuenta_contable: null },
      isLoading: false,
      dialogEdit: false,
      dialogCreate: false,
      dialogEliminar: false,
      tipoDelete: null

    };
  },

  methods: {
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),
    setCuentaCorriente(cuenta) {
      this.tipo.cuenta_contable = cuenta[0];
    },
    setCuentaCorrienteEdit(cuenta) {
      this.tipoEdit.cuenta_contable = cuenta[0];
    },
    editarTipo(tipo) {
      console.log("editarTipo", tipo);
      this.tipoEdit = { ...tipo };
      this.dialogEdit = true;
    },
    async modificar() {
      try {
        const result = await this.$refs.edit.validate();
        this.tipoEdit.cuenta_contable = this.tipoEdit.cuenta_contable.codigo;
        if (result) {
          const response = await this.axios.put(
            `/egresos/tipoingresoegreso/${this.tipoEdit.id}`,
            this.tipoEdit
          );

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Tipo de egreso actualizado",
            icon: "mdi-bug",
            timeout: 2500,
          });
          this.dialogEdit = false;
          this.tipoEdit = null;
          this.fetchTipos();
        } else {
          this.$validator.validate();
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: error.response.data.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    closeDialogEliminar() {
      this.tipoDelete = null;
      this.dialogEliminar = false

    },
    openDialogEliminar(tipo){
      this.tipoDelete = tipo;
      this.dialogEliminar = true
    },
    async eliminar() {
      try {
        const response = await this.axios.delete(
          `/egresos/tipoingresoegreso/${this.tipoDelete.id}`
        );

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Se eliminó correctamente",
          icon: "mdi-information",
          timeout: 2500,
        });
        this.fetchTipos();
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "primary",
          text: error.response.data.data,
          icon: "mdi-information",
          timeout: 4000,
          right: false,
          top: false,
        });
      } finally {
        this.dialogEliminar = false
        this.tipoDelete = null
      }
    },
    async registrarTipo() {
      try {
        var data;
        const result = await this.$refs.formDetalle.validate();
        this.tipo.cuenta_contable = this.tipo.cuenta_contable.codigo;
        if (result) {
          const response = await this.axios.post(
            "/egresos/tipoingresoegreso",
            this.tipo
          );

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Tipo de egreso registrada",
            icon: "mdi-check",
            timeout: 2500,
          });
          this.dialogCreate = false;
          this.fetchTipos();
        } else {
          this.$validator.validate();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: error.response.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    async fetchTipos() {
      const response = await this.axios.get(
        "/egresos/tipoingresoegreso?esIngreso=False"
      );
      this.tiposIngresoEgreso = response.data;
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
    this.fetchTipos();
    this.fetchPlanDeCuentas();
  },
};
</script>

<style lang="scss" scoped></style>
