<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5">Configuración de notificaciones</div>

        <div class="subtitle">Elegí que notificación queres recibir</div>

        <v-list rounded class="mt-4">
          <configuracion-notificaciones-item
            v-for="tipo_notificacion in tipo_notificaciones"
            :key="tipo_notificacion.id"
            :tipo_notificacion="tipo_notificacion"
          >
          </configuracion-notificaciones-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfiguracionNotificacionesItem from "@/views/configuracion/configuracion.notificaciones.item";
import NotificacionesAPI from "@/api/configuracion/notificaciones/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "configuracion.notificaciones",
  components: {
    ConfiguracionNotificacionesItem,
  },
  data() {
    return {
      dialog: false,
      monto: null,
      montoEdit: null,
      tipoNotificacionesEdit: null,
      loading: false,
      alertas: [],
      switch1: false,
    };
  },
  computed: {
    ...mapState("notificaciones", ["tipo_notificaciones"]),
  },
  methods: {
    ...mapActions("notificaciones", ["fetch_tipo_notificaciones"]),
    openDialog(monto) {
      this.montoEdit = monto;

      this.dialog = true;
    },
  },
  created() {
    this.fetch_tipo_notificaciones();
  },
};
</script>

<style lang="scss" scoped></style>
