var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Lista de productos")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('productos-filtros-tag',{attrs:{"removeTagCategoria":true},on:{"close-chip":_vm.close}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-end"},[_c('v-btn-toggle',{staticClass:"mr-3",attrs:{"mandatory":""},model:{value:(_vm.toggle_one),callback:function ($$v) {_vm.toggle_one=$$v},expression:"toggle_one"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("mdi-view-module")])],1)]}}])},[_c('span',[_vm._v("Vista con imágenes ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1)]}}])},[_c('span',[_vm._v("Vista de lista")])])],1)],1)])],1),(_vm.toggle_one == 0)?_c('div',[_c('producto-listado-home',{attrs:{"products":_vm.productos_admin},on:{"actualizar-producto":_vm.actualizarProductos}})],1):_vm._e(),(_vm.toggle_one == 1)?_c('productos-tabla',{ref:"productosTabla",attrs:{"productos":_vm.productos_admin,"verBuscador":false},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.input_search))])]},proxy:true},{key:"default",fn:function(ref){
var selected = ref.selected;
return [_c('v-scale-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"headline"},on),[(selected.length > 0 && selected.length < 11)?_c('v-btn',{key:"delete",attrs:{"icon":""},on:{"click":function($event){return _vm.processEliminar(selected)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar producto")])])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-scale-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"headline"},on),[(selected.length == 1)?_c('v-btn',{key:"export",attrs:{"icon":""},on:{"click":function($event){return _vm.actualizarProductos(selected)}}},[_c('v-icon',[_vm._v("mdi-pen")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Editar producto")])])],1)]}}],null,false,2608934692)}):_vm._e(),(_vm.dialogDelete)?_c('layout-dialog-delete',{on:{"close-dialog":function($event){_vm.dialogDelete = false},"processDelete":_vm.eliminarProducto},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Eliminar producto")]},proxy:true},{key:"message",fn:function(){return [(_vm.productoParaEliminar.length > 1)?_c('div',[_vm._v(" ¿Desea eliminar los productos "),_vm._l((_vm.productoParaEliminar),function(p){return _c('li',{key:p.id},[_c('i',[_vm._v(_vm._s(p.descripcion))]),_vm._v(" ' ? ")])})],2):_c('div',[_vm._v(" ¿Desea eliminar el producto ' "),_c('i',[_vm._v(_vm._s(_vm.productoParaEliminar[0].descripcion))]),_vm._v(" ' ? ")])]},proxy:true}],null,false,226925922)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }