import axios from 'axios'
export default {
    index( paginacion ){
        var url = `/sucursales/configuracion/alerta/`
        return axios.get(url)
    },

    show( id, alerta ){
        var alerta= "RETIRO"
        var url = `/sucursales/configuracion/alerta?a=${alerta}`
        return axios.get(url)
    },

    update( monto, alerta ){
        var url = `/sucursales/configuracion/alerta/`
        return axios.put(url, {
            alerta:alerta,
            monto: monto,
          }) 
    },

    create( data ){
    },
    

  
}