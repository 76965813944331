export var administrarMixin = {
    data() {
        return {
            step: 1,
            loading: false,
            producto: {
              descripcion: "",
              precioCosto: null,
              ganancia: null,
              marca: { id: null },
              proveedor: null,
              categoriaArbol: { id: null },
              codigoDeBarra: null,
              stockmin: 0,
              stockmax: 0,
              controlaStock: false,
              cantidadstock: 0,
              flete: 0,
              iva:  { "description": "21" },
              iva_compra:  { "description": "0" },

             
              esPedidoYa: false,
              porcentajePY: 0,
              es_elaboracion_propia: false,
            },
            marca: null,
            proveedor: null,
            categoria: null,
        }
    },
    methods: {
        calcularPrecioPY() {
            return Math.round(
              this.producto.precioVenta +
                (this.producto.precioVenta * this.producto.porcentajePY) / 100
            );
          },
          setMarca(marca) {
            this.marca = marca;
          },
          setProveedor(proveedor) {
            this.proveedor = proveedor;
          },
          setCategoria(categoria) {
            this.categoria = categoria;
          },
          nextStep(step) {
            this.step = step;
          },
          resetProducto(){
            this.producto =  {
              descripcion: "",
              precioCosto: null,
              ganancia: null,
              marca: { id: null },
              proveedor: null,
              categoriaArbol: { id: null },
              codigoDeBarra: null,
              stockmin: 0,
              stockmax: 0,
              controlaStock: false,
              cantidadstock: 0,
              flete: 0,
              iva: 0,
              esPedidoYa: false,
              porcentajePY: 0,
            }
          },
          validateForm(nextStep) {
            this.$validator.validateAll().then(() => {
              if (!this.errors.any()) {
                if (nextStep) {
                  this.nextStep(nextStep);
                } else {
                  this.registrarProducto();
                }
              } else {
                this.$validator.validate();
              }
            });
          },
    }
}