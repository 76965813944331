<template>
  <v-card>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" v-if="compras && compras.data.length > 0">
          <h1 class="text-center">Compras</h1>
          <v-divider></v-divider>
          <doughnut-chart  :chartdata="compras" />
          <h2 class="text-center">
            {{ totalGastosCompra | convertPesoArg }}
          </h2>
          <v-list v-if="verListadoCompra">
            <v-list-item v-for="gasto in compras.data" :key="gasto.id">
              <v-list-item-avatar>
                <v-chip :color="gasto.color"></v-chip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ gasto.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ gasto.total | convertPesoArg }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col v-else cols="12" md="6">
          <h1 class="text-center">Compras</h1>
          <v-divider></v-divider>
          <h3 class="text-center">No hay compras</h3>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DoughnutChart from "@/chartjs/doughnut.chart.vue";
export default {
  name: "ChartComprasPorMes",
  components: { DoughnutChart },

  props: {
    compras: {
      required: true,
    },
    verListadoCompra: {
      required: false,
      default: true,
    }
  },
  computed: {
    totalGastosCompra() {
      if (this.compras) {
        const sum = this.compras.data.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>