<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <AsientoTipoListItem
          v-for="asiento in asientosTipo"
          :key="asiento.id"
          :asiento="asiento"
        >
        </AsientoTipoListItem>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AsientoTipoListItem from "@/components/contabilidad/asiento/asientotipo/AsientoTipoListItem.vue";

export default {
  name: "AsientoTipoList",
  components: {
    AsientoTipoListItem,
  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  props: {
    asientos: {
      type: Array,
      required: false,
    },
  },

  computed: {
    ...mapState("contabilidad", ["asientosTipo"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetch_asientos_tipo"]),
    goToEditar(adiento) {
      this.$router.push({
        name: "asientoTipoUpdate",
        params: { asiento: adiento },
      });
    },

    fetchAsientosTipo() {
      this.fetch_asientos_tipo();
    },
  },
  created() {
    this.fetchAsientosTipo();
  },
};
</script>