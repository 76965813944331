<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Nuevo asiento tipo</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-container>
            <AsientoTipoForm :asientoTipo="nuevoAsientoTipo" ref="asientoTipoForm"></AsientoTipoForm>
            <AsientoTipoDetalleForm
            :detalles="nuevoAsientoTipo.detalles"
              ref="detalleAsientoForm"
            ></AsientoTipoDetalleForm>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row justify="end" class="mt-4">
                <v-btn color="success" @click="submitForm">Registrar</v-btn>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AsientoTipoForm from "@/components/contabilidad/asiento/asientotipo/AsientoTipoForm.vue";
import AsientoTipoDetalleForm from "@/components/contabilidad/asiento/asientotipo/AsientoTipoDetalleForm.vue";
import { mapActions } from "vuex";

export default {
  name: "AsientoTipoRegistrarView",
  inject: {
    $validator: "$validator",
  },
  components: {
    AsientoTipoForm,
    AsientoTipoDetalleForm,
  },
  data() {
    return {
       nuevoAsientoTipo: {
        codigo: '',
        descripcion: '',
        comentario: '',
        detalles: [] // Puedes inicializar los detalles según sea necesario
      },
    };
  },
  methods: {
    ...mapActions("contabilidad", ["registrar_asiento_tipo"]),

    async submitForm() {
      const isDetalleAvailable =
        await this.$refs.detalleAsientoForm.validateDetalles();
      const isAsientoTipoValid =
        await this.$refs.asientoTipoForm.validateForm();

      if (!isAsientoTipoValid || !isDetalleAvailable) {
        console.log(isAsientoTipoValid);
        console.log(isDetalleAvailable);

        return;
      }
      try {
        const datosParaEnviar = {
          codigo: this.nuevoAsientoTipo.codigo,
          descripcion: this.nuevoAsientoTipo.descripcion,
          comentario: this.nuevoAsientoTipo.comentario,
          detalles: this.nuevoAsientoTipo.detalles,
        };
        const response = await this.registrar_asiento_tipo(datosParaEnviar);
        this.$router.push("/contabilidad/asientotipo");
      } catch (e) {
        this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        console.error("Error al registrar el asiento tipo:", e);
      }
    },
  },
};
</script>
