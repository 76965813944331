import axios from 'axios'
const url = '/proveedores'
export default {
    index() {
        return axios.get(`${url}`)
    },

    show(id) {
        return axios.get(`${url}/${id}`)
    },
    create(data) {

        return axios.post(`${url}`, data)
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },


    delete(id, data) {
        return axios.delete(`${url}/${id}`, data)


    },
    conListaDePrecios() {
        let url = "/proveedores/proveedorconlista";
        return axios.get(url);
      },

}