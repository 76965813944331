<template>
  <v-card @click="cambiarProveedor" max-height="500" flat>
    <v-img contain src="@/assets/man-woman.jpg" height="125px" dark>
      <v-row align="end" class="fill-height">
        <v-card-title>
          <div v-if="isProveedor" class="display-1 text-center">
            {{ proveedor.nombre }}
          </div>
          <div v-else class="title text-center">Aquí aparece el proveedor</div>
        </v-card-title>
      </v-row>
    </v-img>

    <v-card-text v-if="isProveedor">
      <v-list class="pa-0 ma-0" dense two-line>
        <v-list-item
          v-if="proveedor.categorias && proveedor.categorias.length > 0">
          <v-list-item-content>
            <v-list-item-title> Categoría del proveedor </v-list-item-title>
            <v-list-item-subtitle>
              <v-alert>
                <h3 v-for="c in proveedor.categorias" :key="c">{{ c.name }}</h3>
              </v-alert>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-text="'mdi-map-marker-radius'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> {{ proveedor.direccion }} </v-list-item-title>
            <v-list-item-subtitle
              >{{ proveedor.localidad.nombre }} -
              {{ proveedor.localidad.provincia.nombre }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-text="'mdi-cellphone-android'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ proveedor.telefono }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-text="'mdi-email'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ proveedor.email }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "proveedor.info",
  props: {
    proveedor: {
      required: true,
    },
  },
  computed: {
    isProveedor() {
      var isNull = this.proveedor === null ? false : true;
      return isNull;
    },
  },
  methods: {
    cambiarProveedor() {
      this.$emit("change-proveedor");
    },
  },
};
</script>

<style lang="scss" scoped></style>
