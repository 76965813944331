<template>
  <div>
    <v-list>
      <v-list-item class="d-flex pa-2">
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              filled
              v-model="producto.descripcion"
              label="Nombre del producto"
              id="descripcion"
              v-validate="'required|max:100'"
              :error-messages="errors.collect('descripcion')"
              data-vv-name="descripcion"
              :counter="100"
              autofocus
              required
            ></v-text-field>
          </v-list-item-title>
          <v-list-item-title>
            <v-text-field
              filled
              prepend-inner-icon="mdi-barcode-scan"
              v-model="producto.codigoDeBarra"
              label="Código de barra (opcional)"
              id="codigodebarra"
              v-validate="'numeric|max:13|min:8'"
              :error-messages="errors.collect('codigodebarra')"
              data-vv-name="codigodebarra"
              type="number"
              hint="Escanea el código de barra del producto o escríbelo manualmente."
            ></v-text-field>
          </v-list-item-title>
          <v-list-item-title>
            <v-container>
              <v-row>
                <v-col cols="12" lg="2" md="4" sm="6">
                  <v-text-field
                    v-model="producto.precioCosto"
                    id="precioCosto"
                    v-validate="'required|numeric|max:30'"
                    :error-messages="errors.collect('precioCosto')"
                    data-vv-name="precioCosto"
                    label="Precio Costo"
                    prefix="$"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="2" md="2" sm="6">
                  <v-text-field
                    prefix="%"
                    v-model="producto.ganancia"
                    v-validate="'required|decimal:2|max:30'"
                    :error-messages="errors.collect('ganancia')"
                    data-vv-name="ganancia"
                    type="number"
                    label="Ganancia"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="2" md="2" sm="6">
                  <v-text-field
                    v-model="producto.flete"
                    id="flete"
                    label-for="flete"
                    label="% Flete"
                    type="number"
                    prefix="%"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" md="4" sm="6">
                  <v-select
                    v-model="producto.iva"
                    :items="iva"
                    v-validate="'required'"
                    :error-messages="errors.collect('iva')"
                    data-vv-name="iva"
                    id="iva"
                    item-text="valor"
                    item-value="valor"
                    label="IVA"
                    type="number"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="3" md="4" sm="6">
                  <v-text-field
                    color="green"
                    class="title"
                    prefix="$"
                    id="precioVenta"
                    v-model="precioVenta"
                    label="Precio de venta"
                    v-validate="'required|integer|max:30'"
                    :error-messages="errors.collect('precioVenta')"
                    data-vv-name="precioVenta"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-title>
          <v-list-item-title>
            <v-container>
              <v-row>
                <v-col cols="12" lg="4" md="5" sm="6">
                  <v-select
                    v-model="producto.proveedor.id"
                    v-validate="'required'"
                    :error-messages="errors.collect('proveedor')"
                    data-vv-name="proveedor"
                    :items="proveedores"
                    item-text="nombre"
                    item-value="id"
                    label="Proveedor"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="5" md="7" sm="6">
                  <categoria-select
                    @process-categoria="setCategoriaRegister"
                    :categoria="producto.categoriaArbol"
                  ></categoria-select>
                </v-col>
                <v-col cols="12" lg="3" md="5" sm="6">
                  <v-select
                    :items="marcas"
                    v-model="producto.marca.id"
                    item-text="nombre"
                    item-value="id"
                    label-for="marca"
                    label="Marca (opcional)"
                    clearable
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="configuracion.productoInventario">
      <v-list-item-content>
        <v-list-item-title>
          <v-container>
            <v-checkbox
              v-model="producto.controlaStock"
              label="Con stock"
              color="red"
              hide-details
            ></v-checkbox>
          </v-container>
        </v-list-item-title>
      </v-list-item-content>
    </v-list>

    <v-list v-if="producto.controlaStock">
      <v-subheader>Stock</v-subheader>
      <v-list-item-content>
        <v-list-item-title>
          <v-container>
            <v-row>
              <v-col cols="12" lg="3" md="4" sm="6">
                <v-text-field
                  v-model="producto.stockmin"
                  label="Stock mínimo"
                  type="number"
                  required
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="2" sm="6">
                <v-text-field
                  v-model="producto.stockmax"
                  type="number"
                  label="Stock máximo"
                  required
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="2" sm="6">
                <v-text-field
                  filled
                  v-model="producto.cantidadstock"
                  type="number"
                  label="Stock actual"
                  required
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item-title>
      </v-list-item-content>
    </v-list>
  </div>
</template>

<script>
import CategoriaSelect from "@/components/categorias/consultar/categoria.select.vue";

import { mapGetters, mapState } from "vuex";
export default {
  name: "producto.form",
  components: {
    CategoriaSelect,
  },
  inject: ["$validator"],


  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      venta: null,
      iva: [
        { id: 88, valor: '0.00' },
        { id: 89, valor: '10.50' },
        { id: 90, valor: '21.00' },
      ],
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
    ...mapGetters("proveedor", ["proveedores"]),
    ...mapState("sucursales",["configuracion"]),

    

    precioVenta: {
      get() {
        return this.calcularPrecioVenta();
      },
      set(val) {
        return (this.venta = val);
      },
    },
  },
  methods: {
    //Actualmente esta devolviendo NaN
    calcularPrecioVenta() {
      if (this.producto.precioCosto && this.producto.ganancia) {
        return Math.round(
          this.producto.precioCosto *
            (1 + parseFloat(this.producto.ganancia) / 100) *
            (1 + parseFloat(this.producto.flete) / 100) *
            (1 + parseFloat(this.producto.iva) / 100)
        );
      } else {
        return null;
      }
    },

    calcularGanancia(venta) {
      let ganancia =
        (venta /
          (this.producto.precioCosto *
            (1 + parseFloat(this.producto.flete) / 100) *
            (1 + parseFloat(this.producto.iva) / 100)) -
          1) *
        100;

      return ganancia;
    },
    setCategoriaRegister(cat) {
      this.producto.categoriaArbol = cat;
    },
  },
  watch: {
    venta: {
      handler(val) {
        if (val !== null) {
          let ganancia = this.calcularGanancia(parseInt(val));
          this.producto.ganancia = Math.round(ganancia * 100) / 100;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
