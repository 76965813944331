<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/compras'">

    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
          item.text
        }}</v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>
    <template v-slot:navigation-title>Pedidos de compra </template>

        <template v-slot:navigation-content>

      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="borradoresCompra && borradoresCompra.length > 0">
            <v-subheader>Borradores</v-subheader>
            <v-list-item
              v-for="(item, i) in borradoresCompra"
              :key="i"
              @click="setBorradorPedidoCompra(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-delete'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="item.proveedor">{{
                  item.proveedor.nombre
                }}</v-list-item-title>
                <v-list-item-title v-else
                  >Borrador sin proveedor seleccionado</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  moment(item.time).fromNow()
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <v-row>
          <v-col cols="6">
            <pedido-compra-list
              estado="pendiente"
              :tipoComprobante="tipoComprobante"
            ></pedido-compra-list>
          </v-col>
          <v-col cols="6">
            <pedido-compra-list
              estado="aprobado"
              :tipoComprobante="tipoComprobante"
            ></pedido-compra-list>
          </v-col>
        </v-row>
      </v-container>

       <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import PedidoCompraList from "@/views/compra/pedidos/pedido.compra.list.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import { getStorage, saveStorage } from "@/services/serviceLocalStorage";

import { mapState, mapMutations } from "vuex";

export default {
  name: "pedidos.compra",
  components: {
    PedidoCompraList,
    LayoutBasic,
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },
  data() {
    return {
      item: null,
    };
  },
  computed: {
    borradoresCompra() {
      return getStorage("borradoresPedidosCompra");
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoCompra" },
        },
      ];
    },
    items() {
      return [
        {
          text: "Nuevo pedido",
          icon: "mdi-clock",
          route: "nuevoPedidoCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "pedidosCompraConsultar",
        },
      ];
    },
  },
  methods: {
    setBorradorPedidoCompra(item) {
      var proveedor = item.proveedor;
      var detalles = item.detalles;
      var i = this.borradoresCompra.indexOf(item);

      if (i !== -1) {
        this.borradoresCompra.splice(i, 1);
      }
      localStorage.setItem(
        "borradoresPedidosCompra",
        JSON.stringify(this.borradoresCompra)
      );

      this.$router
        .push({
          name: "nuevoPedidoCompra",
          params: { proveedor: proveedor, detalles: detalles },
        })
        .catch(() => {});
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
