<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    
  >
   <template v-slot:activator="{ on }">
          <v-btn color="brown" block dark v-on="on">Ver venta</v-btn>
        </template>
    <v-card>
      <v-app-bar color="brown" fixed>
        <v-app-bar-nav-icon 
        >
          <v-icon @click="dialog = false">mdi-arrow-left</v-icon>
        </v-app-bar-nav-icon>
    
        <v-toolbar-title>
          <slot name="toolbar-title">
           Buscar más productos</slot
          ></v-toolbar-title>

      </v-app-bar>

      <v-card-text>
        <template v-slot:prepend>
          <slot name="cliente"></slot>
        </template>
        <slot name="detalle-pedido"></slot>
        <slot name="card-actions"></slot>
      </v-card-text>
      <v-card-actions>

      </v-card-actions>

    </v-card>
      <v-footer dark fixed>
        <slot name="footer">
         
        </slot>
      </v-footer>
  </v-dialog>
</template>

<script>
export default {

  data() {
    return {
      dialog: false
    }
  },
 
  methods: {
   
  },
 
  
};
</script>

<style scoped>
/* Estilo adicional si es necesario */
</style>
