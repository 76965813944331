<template>
  <layout-registrar-producto v-if="producto">
    <template v-slot:up-title>
      <span></span>
    </template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <div style="font-size: 30px" class="text-wrap">
              Historial de precios
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-container>
        <v-list>
          <v-list-item class="pa-0">
            <v-list-item-title>
              <h1 style="font-size: 30px" class="text-wrap">
                {{ producto.descripcion }}
              </h1>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-subtitle>
                Última actualización el
                {{ moment(producto.fechaActualizacion).format("ll") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <!-- <v-btn v-show="step == 1" small class="mb-2" color="error" block
                    @click="$router.push({ name: 'actualizarProducto', params: { id: producto.id, producto: producto } })">
                    <b >Eliminar
                    </b>
                </v-btn> -->
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-row
        align="start"
        justify="center"
        class="mt-2 mx-auto"
        v-if="historialProducto.length > 0"
      >
        <v-col cols="6">
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="historial in historialProducto"
              :key="historial.id"
              color="pink"
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ moment(historial.fecha).format("lll") }}</strong>
                </v-col>
                <v-col>
                  <v-hover v-slot="{ hover }" close-delay="100">
                    <v-card
                      :color="hover ? '#E91E62' : ''"
                      :elevation="hover ? 16 : 2"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-container>
                        <v-list-item class="grow">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              <span style="font-size: 20px"
                                ><strong>{{
                                  historial.precioVenta | convertPesoArg()
                                }}</strong></span
                              >
                            </v-list-item-subtitle>
                            <v-list-item-title>
                              <div class="text-caption mb-2">Precio venta</div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="grow">
                          <v-list-item-avatar color="grey darken-3">
                            <v-img
                              class="elevation-6"
                              alt=""
                              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              historial.username
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-row>
                            <v-col>
                              <strong>{{
                                historial.precioCosto | convertPesoArg()
                              }}</strong>
                              <div class="text-caption mb-2">Precio Costo</div>
                            </v-col>
                            <v-col>
                              <strong>{{ historial.ganancia }}</strong>
                              <div class="text-caption mb-2">Ganancia</div>
                            </v-col>
                            <v-col>
                              <strong>{{ historial.iva }}</strong>
                              <div class="text-caption mb-2">IVA</div>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item v-if="next_page" class="mb-4" hide-dot>
              <v-btn @click="fetchNextPage()" class="mx-0"> Más </v-btn>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row align="start" justify="center" class="mt-2 mx-auto" v-else>
        <h2>Sin historial</h2>
      </v-row>
    </template>
  </layout-registrar-producto>
</template>
  
<script>
import { administrarMixin } from "@/mixins/producto/administrarMixin.js";
import LayoutRegistrarProducto from "@/layouts/productos/layout.registrar.producto";
import ProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";

import FormProductoProveedor from "@/components/producto/form/form.producto.proveedor.vue";
import FormProductoCategoria from "@/components/producto/form/form.producto.categoria.vue";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";

import ProductoForm from "@/components/producto/form/producto.form";
import FormProductoMarca from "@/components/producto/form/form.producto.marca.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";
import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";
import FormProductoInventario from "@/components/producto/form/form.producto.inventario.vue";

import CalculadoraPrecioVenta from "@/components/producto/actualizar/producto.calculadora.precioVenta.vue";
import ProductoItem from "@/components/producto/producto.item.vue";

import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";
import ProductoHistorialItem from "../../components/producto/ProductoHistorialItem.vue";

export default {
  name: "ProductoItemHome",
  components: {
    ProductoItem,
    FormProductoCategoria,
    FormProductoDescripcion,
    FormProductoBarcode,
    ProductoForm,
    LayoutRegistrarProducto,
    FormProductoProveedor,
    FormProductoMarca,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoPrecioVenta,
    ProductoVistaPrevia,
    FormProductoInventario,
    CalculadoraPrecioVenta,
    ProductoHistorialItem,
  },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [administrarMixin],
  data() {
    return {
      loadingFetch: false,
      producto: null,
      historialProducto: [],
      dialogCalculator: false,
      loadingProductosFraccionados: false,
      productosFraccionados: null,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },
      next_page: false,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },

  methods: {
    ...mapActions("productos", [
      "actualizar_producto",
      "fetch_productos_mas_vendidos",
    ]),

    validateForm(nextStep) {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          if (nextStep) {
            this.nextStep(nextStep);
          } else {
            this.actualizarProducto(this.producto);
          }
        } else {
          this.$validator.validate();
        }
      });
    },
    actualizarProducto(p) {
      this.loading = true;

      this.actualizar_producto(p)
        .then((response) => {
          // this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto Actualizado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.loading = false;
          this.fetch_productos_mas_vendidos();
          this.$router.go(-1);
        })
        .catch((e) => {
          this.loading = false;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;
        });
    },
    setPrecioCosto(precio) {
      this.producto.precioCosto = precio;
    },
    setGanancia(precio) {
      this.producto.ganancia = precio;
    },
    fetchNextPage() {
      this.paginacion.page = this.paginacion.page + 1;
      this.fetchHistorialProducto(this.producto.id);
    },
    fetchHistorialProducto(id) {
      this.loadingFetch = true;
      ProductosAPI.indexHistorial(id, this.paginacion).then((response) => {
        console.log(response.data.next);
        if (response.data.next) {
          console.log("si");
          this.next_page = true;
        } else {
          this.next_page = false;
        }
        if (this.historialProducto.length > 0) {
          this.historialProducto = this.historialProducto.concat(
            response.data.results
          );
        } else {
          this.historialProducto = response.data.results;
        }

        this.loadingFetch = false;
      });
    },
    fetchProducto(id) {
      this.loadingFetch = true;

      ProductosAPI.show(id).then((response) => {
        this.producto = response.data;
        this.producto.categoriaArbol
          ? null
          : (this.producto.categoriaArbol = { id: null });
        this.setCategoria(response.data.categoriaArbol);
        this.setMarca(response.data.marca);
        this.setProveedor(response.data.proveedor);
        this.loadingFetch = false;
      });
    },
    changeParam(id) {
      this.$router.replace({ name: "productoHome", params: { id: id } });
      this.fetchHistorialProducto(id);
    },
  },
  created() {
    if (this.$route.params.producto === undefined) {
      this.fetchProducto(this.$route.params.id);

      this.fetchHistorialProducto(this.$route.params.id);
    } else {
      this.loadingFetch = true;
      var producto = this.$route.params.producto;
      if (!producto.categoriaArbol) {
        producto.categoriaArbol = { id: null };
      }
      this.fetchHistorialProducto(producto.id);

      this.setCategoria(producto.categoriaArbol);
      this.setMarca(producto.marca);
      this.setProveedor(producto.proveedor);

      producto.categoriaArbol = producto.categoriaArbol
        ? producto.categoriaArbol
        : { id: null };
      this.producto = producto;

      this.loadingFetch = false;
    }
  },
};
</script>
  
<style scoped>
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}

.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}

.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}

.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
  