<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Formas de pago</div>
      </v-col>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar forma de pago"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-col>
      <v-col cols="12">
        <v-list>
          <v-list-item @click="$router.push({ name: 'rubroVentaRegistrar' })">
            <v-list-item-icon>
              <v-icon>mdi-book-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Nuevo forma de pago</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <ContabilidadFormasDePagoList :planDeCuentas="planDeCuentas" :formasDePago="formas_de_pago"></ContabilidadFormasDePagoList>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ContabilidadFormasDePagoList from "@/components/contabilidad/formasDePago/ContabilidadFormasDePagoList.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ContabilidadRubroVenta",
  components: {
    ContabilidadFormasDePagoList,
    LayoutBasic,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapState("comprobantes", ["formas_de_pago"])
  },
  methods: {
    ...mapActions("comprobantes", ["fetch_formas_de_pago"]),
  },
  created() {
    this.fetch_formas_de_pago();
  },
};
</script>

<style lang="scss" scoped></style>
