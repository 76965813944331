<template>
  <v-card>
    <v-container>
      <v-card-title class="justify-center">
        <span v-if="remito.codigo"> {{ remito.codigo }} </span>
        <span v-else> ID: {{ remito.id }} </span>
        <v-spacer></v-spacer>
        <v-btn large color="grey" icon @click="$emit('close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-alpha-r-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ remito.proveedor.nombre }}</v-list-item-title
                >

                <v-list-item-subtitle>{{
                  moment(remito.fechaAlta).format("lll")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn block @click="verRemito(remito)" color="indigo accent-4"
            >Ver remito
            <v-icon class="ml-3">mdi-book-open-outline</v-icon></v-btn
          >
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6"></v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogCobrar" width="600">
      <cliente-cuentacorriente-pagar
        v-if="dialogCobrar"
        @refresh-cuenta-corriente="refreshCuentaCorrientePago"
        @close-dialog-cobrar="dialogCobrar = false"
        :comprobante="remito"
      ></cliente-cuentacorriente-pagar>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el remito?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el remito? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar remito</template>
    </dialog-confirmation-close>
  </v-card>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import ComprobanteCompraPagar from "@/components/compra/ComprobanteCompraPagar.vue";

import { mapActions } from "vuex";

export default {
  name: "remito.compra.vista-previa",
  components: {
    DialogConfirmationClose,
    ComprobanteCompraPagar,
  },
  props: {
    remito: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogPedidoCancelar: false,
      loadingButton: false,
      montoActual: 0,
      dialogCobrar: false,
    };
  },
  computed: {
    pedidosSiguientes() {
      var pedidos = [];
      this.remito.getEstadosSiguientesDescripcion.forEach((element) => {
        if (element.accion) {
          pedidos.push(element);
        }
      });
      return pedidos;
    },
  },
  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

    ...mapActions("remitos", ["pagar_remito"]),
    // verRemito() {
    //   this.$emit("process-ver-remito", this.remito);
    // },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
    pagarRemito() {
      this.loadingButton = true;
      this.pagar_remito(this.remito.id)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Remito pagado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("refresh");
          this.$emit("close-dialog");
          this.fetch_productos_mas_vendidos();
          this.loadingButton = false;
        })
        .catch((e) => {
          var message = e.response.data;
          if (e.response.data.detail) {
            message = e.response.data.detail;
          }

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: message,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loadingButton = false;
        });
    },

    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.remito.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Remito cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("process-quitar-remito", this.remito);
        })
        .catch((e) => {});
    },

    fetchPedido(id) {
      var url = `/comprobantes/comprobantes/${id_remito}`;

      this.axios.get(url).then((response) => {
        this.remito = response.data;
        this.detalles_remito = response.data.detalles_comprobante;
      });
    },
    async verRemito(remito) {
      this.$router.push({
        name: "remitosConsultarDetalle",
        params: { id: remito.id },
      });
    },
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
