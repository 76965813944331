<template>
  <span v-if="pedido">
    <v-snackbar color="error" v-model="deshacer.state" multi-line>
      {{ deshacer.message }}
      <v-btn color="blue" text @click="method_deshacer">{{ deshacer.button }}</v-btn>
    </v-snackbar>
    <LayoutThreeColumn>
      <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
            item.text
          }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template>

      <template v-slot:title
        ><h2>Pedido nº {{ pedido.numeroVentaPorArqueo }} </h2>
      </template>
      <!-- <template v-slot:subtitle>
        <v-list-item>
          <v-list-item-title>
            <v-alert dense :color="estado == 'PENDIENTE' ? 'orange' : 'blue'" type="info">
              {{ estado }}
            </v-alert>
          </v-list-item-title>
        </v-list-item>
      </template> -->
      <template v-slot:bar-extension>
        <template v-if="configuracion.menuVentaCruachan">
          <venta-menu-buttons :isUpdate="true" :detalles="detallesPedido"></venta-menu-buttons>
        </template>
      </template>
      <template v-slot:search-barcode>
        <agregar-producto-barcode :detalles="detallesPedido"></agregar-producto-barcode
      ></template>
      <template v-slot:arriba-vista-previa>
        <productos-frecuentes
        :isUpdate="true"
          :detalles="detallesPedido"
          :fromBuscador="false"
        ></productos-frecuentes>
      </template>

      <template v-slot:cliente>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="display-1">Pedido nº {{ pedido.numeroVentaPorArqueo }}</div>
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              <v-alert
                class="mb-0 mt-0 pt-0 pb-0"
                dense
                :color="estado == 'PENDIENTE' ? 'orange' : 'blue'"
                type="info"
              >
                 {{
                  estado == "PENDIENTE"
                    ? "En producción"
                    : estado
                }}
              </v-alert>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list dense two-line class="pa-0 ma-0">
           <VentaClienteInfoCard
                  :formCliente="formCliente"
                  :formEnvio="formEnvio"
                  :cliente="pedido.cliente"
                  :paraEnviar="paraEnviar"
                  :showImage="false"
                ></VentaClienteInfoCard>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-text="'mdi-calendar-range'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="'Fecha de creación'"></v-list-item-title>
              <v-list-item-subtitle>{{ fechaCreacion }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-avatar>
              <v-icon v-text="'mdi-folder'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="'Última modificación'"></v-list-item-title>
              <v-list-item-subtitle>{{ ultimaActualizacion }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </template>
      <template v-slot:detalle-pedido>
        <pedido-detalle-actualizar
          :disabledDetalle="disabledDetalle"
          v-if="detallesPedido.length > 0"
          :detalles="detallesPedido"
        ></pedido-detalle-actualizar>
      </template>
      <template v-slot:button-navigation-controller>
         <pedido-detalle-subtotal :detalles="detallesPedido"></pedido-detalle-subtotal>
        <v-row class="mt-2" align="end" v-if="hay_cambios && hay_detalles_para_ver">
          <v-col cols="12">
            <v-btn
              :loading="loadingButtonG"
              :disabled="loadingButtonG"
              block
              color="green"
              @click="guardarPedido()"
            >
              Guardar cambios
              <template v-slot:loader>
                <span>Guardando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <!-- <v-btn
              v-if="estado == 'PENDIENTE'"
              :loading="loadingButtonGA"
              :disabled="loadingButtonGA"
              block
              color="blue"
              @click="guardarAprobarPedido()"
            >
              Guardar y Aprobar
              <template v-slot:loader>
                <span>Aprobando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn> -->
            <v-btn
              v-if="estado == 'APROBADO'"
              :loading="loadingButtonGA"
              :disabled="loadingButtonGA"
              block
              color="blue"
              @click="guardarEnviarPedido()"
            >
              Guardar y Enviar
              <template v-slot:loader>
                <span>Finalizando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </LayoutThreeColumn>

    <dialog-confirmation-close
      v-if="dialogDiscardChanges"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar actualización?</template>
      <template v-slot:message
        >¿Seguro que quieres salir? Se perderán todos los cambios.</template
      >
      <template v-slot:buttonOK>Salir de la página</template>
    </dialog-confirmation-close>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
import LayoutThreeColumn from "@/layouts/layout.three.column";
import AgregarDetallePedido from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import { deshacer_eliminado } from "@/services/detallesComprobante/serviceDetallesUpdate";
import PedidoDetalleActualizar from "@/components/pedido/actualizar/pedido.detalle.actualizar.vue";
import AgregarProductoBarcode from "@/components/comprobante/detalle/comprobante.agregar.detalle.barcode.vue";
import VentaMenuButtons from "@/components/venta/venta.menu.buttons.vue";
import ProductosFrecuentes from "@/components/producto/consultar/productos.frecuentes.card.vue";
import PedidoDetalleSubtotal from "@/components/pedido/actualizar/pedido.detalle.subtotal.vue";
import PedidoClienteVistaPrevia from "@/components/venta/cliente/venta.cliente.vista-previa.vue";
import VentaClienteInfoCard from "@/components/venta/cliente/VentaClienteInfoCard";




import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "pedido.venta.actualizar",
  components: {
    PedidoDetalleSubtotal,
    AgregarDetallePedido,
    DialogConfirmationClose,
    PedidoDetalleActualizar,
    LayoutThreeColumn,
    AgregarProductoBarcode,
    VentaMenuButtons,
    ProductosFrecuentes,
    PedidoClienteVistaPrevia,
    VentaClienteInfoCard
  },
  data() {
    return {
      tipoEntrega: 0,
      dialogPedidoCancelar: false,

      menuFechaEntrega: false,
      fechaEntregaEstimada: "",
      get_detalles: [],
      pedido: null,
      detallesPedido: [],
      estado: null,
      estadosSiguientes: [],
      paraEnviar: false,
      

      flagCambioTipoEntrega: false,
      flagCambioFechaEntrega: false,

      dialog: false,
      producto_buscado: null,
      dialogDiscardChanges: false,

      toNextRoute: null,

      initCantidadDetalles: null,
      loadingButtonG: false,
      loadingButtonGA: false,
      loadingButtonGF: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    formCliente(){
      if (this.pedido.nombreEntidad) {
        return this.pedido.nombreEntidad.cliente
      }
      return null
    },
    formEnvio(){
      if (this.pedido.nombreEntidad) {
        return this.pedido.nombreEntidad.envio
      }
      return null
    },
    disabledDetalle() {
      if (this.estado == "ENVIADO") {
        return true;
      }
      return false;
    },
    hay_cambios() {
      if (this.hay_detalles_para_ver) {
        if (
          this.is_update_detalles ||
          this.detallesPedido.length > this.initCantidadDetalles ||
          this.flagCambioTipoEntrega ||
          this.flagCambioFechaEntrega
        ) {
          return true;
        }
      }
      return false;
    },

    fechaCreacion() {
      return this.moment(this.pedido.fechaAlta).format("LL H:mma");
    },
    ultimaActualizacion() {
      return this.moment(this.pedido.fechaUltModificacion).format("LL H:mma");
    },

    navigation() {
      return [
        {
          text: "Ventas",
          disabled: false,
          to: { name: "ventas" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoVenta" },
        },
        {
          text: `Nº ${this.pedido.numeroVentaPorArqueo}`,
          disabled: false,
          to: { name: "ActualizarPedido" },
        },
      ];
    },

    hay_detalles_para_ver() {
      if (this.detallesPedido.length > 0) {
        return true;
      }
      return false;
    },
    ...mapState("pedidos", ["deshacer"]),
    ...mapGetters("pedidos", ["is_update_detalles", "get_detalles_update"]),
  },
  methods: {
    ...mapMutations("pedidos", [
      "deshacer_eliminado",
      "clean_detalles_update",
      "set_pedido_con_detalle",
    ]),
    ...mapActions("pedidos", ["get_pedido_con_detalle"]),
    fetchPedido(id) {
      var url = `/pedidos/venta/${id}`;

      this.axios.get(url).then((response) => {
        this.initCantidadDetalles = response.data.detalles_pedido.length;
        this.pedido = response.data;
        this.detallesPedido = response.data.detalles_pedido;
        this.estado = response.data.getEstadosActual;
        this.paraEnviar = response.data.paraEnviar
        this.tipoEntrega = response.data.tipoEntrega;
        this.fechaEntregaEstimada = response.data.fechaEntregaEstimada;

        this.estadosSiguientes = response.data.getEstadosSiguientesDescripcion;
      });
    },

    setProductoBuscado(data) {
      if (data == null) {
        this.producto_buscado = "";
      } else {
        this.producto_buscado = data;
      }
    },
    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.pedido.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.toNextRoute = true;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });

          var route = "pedidoVenta";
          this.$router.push({ name: route });
        })
        .catch((e) => {});
    },
    cambiarEstado(estado) {
      if (estado == "CANCELADO") {
        this.dialogPedidoCancelar = true;
        return;
      }
      this.loadingButton = true;

      let url = `pedidos/actestado/${this.pedido.id}/${estado}`;
      this.axios
        .get(url)
        .then((response) => {
          this.toNextRoute = true;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido ${estado}`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButton = false;
          var route = "pedidoVenta";

          this.$router.push({ name: route });
        })
        .catch((e) => {
          this.loadingButton = false;
        });
    },
    volver() {
      this.toNextRoute = true;

      this.clean_detalles_update();
      var route = "pedidoVenta";

      this.$router.push({ name: route });
    },
    guardarPedido() {
      this.loadingButtonG = true;
      if (
        !this.flagCambioTipoEntrega &&
        !this.flagCambioFechaEntrega &&
        this.get_detalles_update == false
      ) {
        this.loadingButtonG = false;
        return alert("No encuentra detalles a actualizar");
      }

      let url = `/pedidos/venta/${this.pedido.id}`;
      this.axios({
        method: "PUT",
        url: url,
        data: {
          detalle_pedido: this.get_detalles_update,
          tipoEntrega: this.tipoEntrega,
          fechaEntregaEstimada: this.fechaEntregaEstimada,
        },
      })
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido guardado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButtonG = false;
          this.goToNextRoute();
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "danger",
            text: `No se pudo guardar el pedido`,
            icon: "mdi-close-octagon",
            timeout: 3000,
          });
          this.loadingButtonG = false;
        });
    },
    async guardarEnviarPedido() {
      this.loadingButtonGF = true;

      if (this.get_detalles_update == false)
        return alert("No encuentra detalles a actualizar");

      let urlGuardar = `/pedidos/venta/${this.pedido.id}`;
      const responseGuardar = await this.axios({
        method: "PUT",
        url: urlGuardar,
        data: { detalle_pedido: this.get_detalles_update },
      });

      let urlAprobar = `pedidos/actestado/${responseGuardar.data.id}/enviado`;
      const resonseAprobar = await this.axios.get(urlAprobar);
      this.loadingButtonGF = false;
      this.goToNextRoute();
    },
    // async guardarAprobarPedido() {
    //   this.loadingButtonGA = true;

    //   if (this.get_detalles_update == false)
    //     return alert("No encuentra detalles a actualizar");

    //   let urlGuardar = `/pedidos/${this.pedido.id}`;
    //   const responseGuardar = await this.axios({
    //     method: "PUT",
    //     url: urlGuardar,
    //     data: { detalle_pedido: this.get_detalles_update },
    //   });

    //   let urlAprobar = `pedidos/actestado/${responseGuardar.data.id}/aprobado`;
    //   try {
    //     const resonseAprobar = await this.axios.get(urlAprobar);
    //     this.loadingButtonGA = false;
    //     this.goToNextRoute();
    //   } catch (error) {
    //     this.loadingButtonGA = false;
    //   }

    //   this.goToNextRoute();
    // },
    goToNextRoute() {
      this.toNextRoute = true;
      var route = "pedidoVenta";

      this.$router.push({ name: route });
    },

    method_deshacer() {
      deshacer_eliminado(this.detallesPedido);
    },

    closeDialog() {
      this.dialogDiscardChanges = false;
      this.toNextRoute = null;
    },
    discardChanges() {
      this.dialogDiscardChanges = false;
      this.$router.push(this.toNextRoute);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.is_update_detalles) {
      next();
    } else {
      if (this.toNextRoute) {
        this.clean_detalles_update();

        next();
      } else {
        this.dialogDiscardChanges = true;
        this.toNextRoute = to;
      }
    }
  },

  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: false });

    let id = this.$route.params.id;
    this.fetchPedido(id);
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
