<template>
  <div>
    <v-toolbar :color="withColor ? color: 'grey'">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <ValidationObserver ref="obs">
      <v-card slot-scope="{ invalid, validated }">
        <v-card-text>
          <v-col cols="12">
            <VTextFieldWithValidation
              rules="required|max:50"
              v-model="categoria.name"
              autofocus
              :counter="50"
              label="Nombre"
              name="Nombre"
              @keyup.enter="validateForm('form-categoria')"
            />
          </v-col>
          <v-col v-if="withColor" class="d-flex justify-center">
            <v-color-picker
              type="hex"
              hide-inputs
              v-model="color"
            ></v-color-picker>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="$emit('cerrar-dialog')"
            >Cancelar</v-btn
          >
          <v-btn
            color="green lighten-1"
            @click="validateForm('form-categoria')"
            :disabled="invalid || !validated"
          >
            <span class="black--text">{{ nameButton }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";

export default {
  name: "FormInputCategoria",
  $_veeValidate: {
    validator: "new",
  },
  components: { ValidationObserver, VTextFieldWithValidation },

  props: {
    categoria: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "Nueva categoría",
    },
    nameButton: {
      type: String,
      required: false,
      default: "Registrar",
    },
    withColor: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      color: "#FF00FF",
    };
  },
  methods: {
    getRandomColor() {
      if (this.categoria.color){
        this.color = this.categoria.color
        return
      }
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.color = color
      return 
    },
    async validateForm(scope) {
      const result = await this.$refs.obs.validate();
      if (result) {
        if (this.withColor) {
          this.categoria.color = this.color;
        }
        // eslint-disable-next-line
        this.$emit("processCategoria", this.categoria);
        //     this.$nextTick(() => {
        //   this.$refs.obs.reset();
        // });
        this.$validator.reset();
        // call form submission logic
      } else {
        this.$validator.validate();
      }
    },
  },
  created() {
    this.getRandomColor()
  },
};
</script>

<style lang="scss" scoped></style>
