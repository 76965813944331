<template>
  <div>
    <v-list subheader v-show="!loading">
      <v-subheader>Empleados</v-subheader>

      <v-list-item
        v-show="empleados.length > 0"
        @click="$emit('seleccion-empleado', empleado)"
        v-for="empleado in empleados"
        :key="empleado.id"
      >
        <v-list-item-avatar>
          <v-img v-if="empleado.foto" contain :src="empleado.foto"></v-img>

          <v-img v-else contain src="@/assets/man-woman.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ empleado.persona.nombre }} {{ empleado.persona.apellido }}</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text
            v-text="empleado.is_active ? 'Habilitado' : 'Deshabilitado'"
          ></v-list-item-action-text>
          <v-icon :color="empleado.is_active ? 'green' : 'grey'">
            mdi-radiobox-marked
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-show="empleados.length == 0">
        <v-list-item-content>
          <v-list-item-title>No hay empleados</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        v-show="loading"
        max-height="350"
        type="list-item-avatar"
      ></v-skeleton-loader>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "empleado.list",
  props: {
    empleados: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
</style>