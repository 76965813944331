<template>
  <v-simple-table>
    <thead>
       <tr>
        <th class="text-left" style="width: 20%;">Cuenta</th>
        <th class="text-left" style="width: 40%;">Descripción</th>
        <th class="text-left" style="width: 20%;">Debe</th>
        <th class="text-left" style="width: 10%;">Haber</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="detalle in detalles" :key="detalle.id">
        <td>{{ detalle.cuenta ? detalle.cuenta.nombre : "N/A" }}</td>
        <td>{{ detalle.descripcion }}</td>
        <td>{{ detalle.debe }}</td>
        <td>{{ detalle.haber }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>


<script>
export default {
  name: "AsientoTipoDetalleItem",
  components: {},
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* Puedes usar clases para definir anchos específicos también */
th {
  width: 20%;
}
th:nth-child(2) {
  width: 40%;
}
</style>

