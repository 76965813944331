<template>
   <v-container>
    <v-row class="flex-child">
      <v-col
        class="d-flex"
        cols="12"
        md="4"
      >
        <v-sheet
          class="d-flex"
          color="grey lighten-3"
          height="424"
        >
          <sheet-footer>
            #1: (3r x 2c)
          </sheet-footer>
        </v-sheet>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        md="4"
      >
        <v-row class="ma-n3">
          <v-col cols="6">
            <v-sheet
              class="d-flex"
              color="green lighten-3"
              height="150"
            >
              <sheet-footer>
                #2: (1r x 1c)
              </sheet-footer>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              height="150"
            >
              <sheet-footer>
                #3: (1r x 1c)
              </sheet-footer>
            </v-sheet>
          </v-col>

          <v-col cols="12">
            <v-sheet
              class="d-flex"
              color="red lighten-3"
              height="250"
            >
              <sheet-footer>
                #5: (2r x 2c)
              </sheet-footer>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="6"
        md="2"
      >
        <v-sheet
          class="d-flex"
          color="teal lighten-3"
          height="300"
        >
          <sheet-footer>
              <v-list-item >
                      <v-list-item-content>
                        
                        <v-list-item-title >
                          <div class="title">
                              Ventas
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
              <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Cantidad de ventas</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{ arqueo.ventas.cantidad ? arqueo.ventas.cantidad : 0 }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Ventas anuladas</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{
                            arqueo.ventasAnuladas.length > 0
                              ? arqueo.ventasAnuladas.length
                              : 0
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="arqueo.ventasAnuladas.length > 0"
                          class="text-center"
                        >
                          <span class="text-wrap text-caption">
                            <a @click="verVentasAnuladas"> Ver ventas anuladas</a>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption"
                            >Ventas para facturar</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          {{
                            arqueo.ventasFacturadas.length > 0
                              ? arqueo.ventasFacturadas.length
                              : 0
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="
                            arqueo.ventasFacturadas &&
                            arqueo.ventasFacturadas.length > 0
                          "
                          class="text-center"
                        >
                          <a @click="verventasFacturadas"> Ver ventas</a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                       <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center"
                          ><span class="text-wrap text-caption">
                            Productos vendidos</span
                          ></v-list-item-subtitle
                        >
                        <v-list-item-title class="text-center">
                          <span> {{ arqueo.detallesComprobantes.length }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="arqueo.detallesComprobantes.length > 0"
                          class="text-center"
                        >
                          <a @click="verProductos"> Ver productos</a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
        
          </sheet-footer>
        </v-sheet>
      </v-col>

      <v-col
        class="d-flex"
        cols="6"
        md="2"
      >
        <v-sheet
          class="d-flex mt-auto"
          color="purple lighten-3"
          height="300"
        >
          <sheet-footer>
            #6: (2r x 1c)
          </sheet-footer>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "reporte.resumen.arqueo.2",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },

  data() {
    return {
      arqueo: null,
      dialogVentasAnuladas: false,
      dialogVentasFacturar: false,
      dialogProductosVendidos: false,
      dialogEgresos: false,
      expanded: [],

      headersParaFacturar: [
        {
          text: "Hora",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Nro", value: "numeroVentaPorArqueo" },

        { text: "Tipo comprobante", value: "letra", align: "center" },
        { text: "Forma de pago", value: "formaDePago.nombre" },

        { text: "Total", value: "total" },
      ],
      headers: [
        {
          text: "Hora creación",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Hora anulación", value: "fechaUltModificacion" },
        { text: "Total", value: "total" },
        { text: "Motivo", value: "motivoAnulacion.descripcion" },
        { text: "Comentario", value: "comentarioMotivoAnulacion" },
      ],
    };
  },
  methods: {
    verventasFacturadas() {
      this.dialogVentasFacturar = true;
    },
    verEgresos() {
      this.dialogEgresos = true;
    },
    verVentasAnuladas() {
      this.dialogVentasAnuladas = true;
    },
    verProductos() {
      this.dialogProductosVendidos = true;
    },

    fechaInicioActividad(arqueo) {
      return this.moment(arqueo.fechaInicio).format("LLL");
    },
    fechaCierreActividad(arqueo) {
      return this.moment(arqueo.fechaCierre).format("LLL");
    },
    horaCierreActividad(arqueo) {
      return this.moment(arqueo.fechaCierre).format("HH:mm");
    },

    horaInicioActividad(arqueo) {
      return this.moment(arqueo.fechaInicio).format("HH:mm");
    },
    textDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "Está faltando";
      } else {
        return "Está sobrando";
      }
    },
    iconDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "mdi-cash-minus";
      } else {
        return "mdi-cash-plus";
      }
    },
    diferenciaDeCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return arqueo.montoCierre - arqueo.montoCierreConteo;
      } else {
        return arqueo.montoCierreConteo - arqueo.montoCierre;
      }
    },
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          this.$router.go(-1);
        } else {
          if (params.arqueo) {
            this.arqueo = params.arqueo;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
