<template>
  <div>
    <v-container fluid fill-height class="pa-0">
      <v-row class="fill-height">
        <v-col cols="12">
          <!-- <v-row class="mt-4">
            <v-col cols="12">
              <productos-filtros-tag :removeTagCategoria="false"></productos-filtros-tag>
              

            </v-col>
          </v-row> -->
          <v-row v-if="!loading_productos_admin">
            <v-col>
              <v-scale-transition>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="headline">
                      <v-btn @click="processEliminar(productos_admin_selected)"
                        v-if="productos_admin_selected.length > 0 && productos_admin_selected.length < 11" key="delete"
                        icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Eliminar producto</span>
                </v-tooltip>
              </v-scale-transition>
              <v-scale-transition>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="headline">
                      <v-btn @click="actualizarProductos(productos_admin_selected)"
                        v-if="productos_admin_selected.length == 1" key="export" icon>
                        <v-icon>mdi-pen</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Editar producto</span>
                </v-tooltip>
              </v-scale-transition>
              <!-- <v-scale-transition>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="headline">
                      <v-btn v-if="productos_admin_selected.length" key="cash" icon>
                        <v-icon>mdi-cash</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Actualizar precio de costo</span>
                </v-tooltip>
              </v-scale-transition> -->
              
              <productos-tabla ref="productosTabla" :productos="productos_admin" 
               >
                <template v-slot:search>
                  <div class="title">{{ input_search }}</div>
                </template>
              </productos-tabla>
            </v-col>
          </v-row>
          <v-row justify="center" v-else>
            <v-col cols="2">
              <v-progress-circular class="text-center justifu-center" :size="70" :width="7" color="purple"
                indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <layout-dialog-delete @close-dialog="dialogDelete = false" @processDelete="eliminarProducto"
            v-if="dialogDelete">
            <template v-slot:title>Eliminar producto</template>
            <template v-slot:message>
              <div v-if="productoParaEliminar.length > 1">
                ¿Desea eliminar los productos
                <li v-for="p in productoParaEliminar" :key="p.id">
                  <i>{{ p.descripcion }}</i> ' ?
                </li>
              </div>
              <div v-else>
                ¿Desea eliminar el producto '
                <i>{{ productoParaEliminar[0].descripcion }}</i> ' ?
              </div>
            </template>
          </layout-dialog-delete>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductosTabla from "@/components/producto/listar/productos.list.table.update";
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import ProductoListadoHome from "@/components/producto/listar/productos.list.card";
import ProductosFiltrosTag from "@/components/producto/filtros/productos.filtros.tag.vue";



import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "productos.list.update",
  components: {
    ProductosTabla,
    LayoutDialogDelete,
    ProductoListadoHome,
    ProductosFiltrosTag,
    
  },
  props: {
    params: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      verTabla: false,
      toggle_one: 0,
      input_search: null,
      isLoading: false,
      searchActive: false,

      menu: false,
      selected: [],

      dialogDelete: false,
      productoParaEliminar: [],
      blockForKeyRepeat: false,
      fab: false,
    };
  },
  computed: {
    ...mapGetters("productos", ["filters_admin"]),

    ...mapState("productos", ["paginacion_productos_admin", "productos_admin", "productos_admin_selected", "loading_productos_admin"]),
  },

  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => { });
    },
    setProductosSelected(productos) {
      this.selected = productos;
    },
    ...mapMutations("productos", [
      "quit_producto_admin",
      "SET_PRODUCTOS_ADMIN_SELECTED"
    ]),
    ...mapActions("productos", [
      "eliminar_producto_buscador_admin",
    ]),

    actualizarProductos(selected) {
      if (selected.length > 1) {
        this.$router.push({
          name: "actualizarProductoMultiple",
          params: { productos: selected },
        });
      } else {
        this.$router.push({
          name: "actualizarProducto",
          params: { id: selected[0].id, producto: selected[0] },
        });
      }
      // }
    },
    irActualizarPrecioCosto(selected) {
      if (selected.length > 0) {
        // this.set_producto_selected(selected);

        if (selected.length == 1) {
          this.$router.push({
            name: "ActualizarPrecioCosto",
            params: { id: selected[0].id },
          });
        } else {
          this.$router.push({
            name: "ActualizarPrecioCostoMultiple",
            params: { productos: selected },
          });
        }
      }
    },
    processEliminar(producto) {
      this.dialogDelete = true;
      this.productoParaEliminar = producto;
    },
    eliminarProducto() {
      if (this.productoParaEliminar.length == 1) {
        this.eliminar_producto_buscador_admin(
          this.productoParaEliminar[0].id
        ).then((response) => {
          this.dialogDelete = false;
          this.SET_PRODUCTOS_ADMIN_SELECTED([]);
          this.$refs.productosTabla.cleanSelected();

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Se eliminó el producto '${this.productoParaEliminar[0].descripcion}'`,
            icon: "mdi-information",

            timeout: 2500,
          });
        });
      } else {
        var data = [];
        for (let i = 0; i < this.productoParaEliminar.length; i++) {
          data.push(this.productoParaEliminar[i].id);
        }

        var url = `/productos/delete/multiple`;
        this.axios.post(url, data).then((response) => {
          for (let i = 0; i < this.productoParaEliminar.length; i++) {
            this.quit_producto_admin(this.productoParaEliminar[i].id);
          }
          this.dialogDelete = false;
          this.SET_PRODUCTOS_ADMIN_SELECTED([]);
          this.$refs.productosTabla.cleanSelected();
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Productos eliminados`,
            icon: "mdi-information",
            timeout: 2500,
          });
        });
      }
    },
  
    
   


    
  },
  

  mounted() {
    window.addEventListener("keydown", (event) => {
      /*repeat se setea en true cuando tiene presionada la tecla constantemente,
       levanta la bandera para que no realice peticiones la server */
      var repeat = event.repeat;
      if (repeat) {
        this.blockForKeyRepeat = true;
      } else {
        this.blockForKeyRepeat = false;
      }
    });
    window.addEventListener("keyup", (event) => {
      this.blockForKeyRepeat = false;
    });
  },
};
</script>

<style lang="scss" scoped></style>
