<template>
  <v-container>
    <v-list-item class="pl-1">
      <v-list-item-avatar>
        <v-icon large>mdi-account-plus</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>
        <h1 style="font-size: 30px">Nuevo usuario</h1>
      </v-list-item-title>
    </v-list-item>
    <v-row align="center" justify="center">
      <v-col xs="12" sm="8" md="6">
        <v-card color="transparent">

          <v-card-text>
            <usuario-register-form @process-register="registrarUsuario" :user="user"></usuario-register-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsuarioRegisterForm from "@/components/usuarios/UsuarioRegisterForm";
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;

import { mapActions, mapState } from "vuex";

export default {
  name: "UsuarioRegister",
  components: {
    UsuarioRegisterForm,
    LayoutTwoColumn,
  },
  data() {
    return {
      user: {
        username: null,
        email: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState("empleado", ["empleado"]),
  },
  methods: {
    ...mapActions("auth", ["registrar_usuario"]),
    registrarUsuario(user) {
      user.e = this.empleado.id
      this.registrar_usuario(user)
        .then((response) => {
          try {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: "Usuario registrado",
              icon: "mdi-check",
              timeout: 3000,
            });
            this.$router.push("/empleados");
          } catch (error) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "info",
              text: "Problema inesperado",
              icon: "mdi-information",
              timeout: 3000,
            });
          }
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.username}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
