<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Remitos de Compra Pendientes</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="remito in remitos" :key="remito.id" @click="selectRemito(remito)">
                          <v-list-item-content>
                <v-list-item-title>
                  {{ remito.codigo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ moment(remito.fechaAlta).format("lll") }}
                </v-list-item-subtitle>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text> 
    </v-card>
  </div>
</template>

<script>
import RemitoApi from '@/api/compra/remito';

export default {
  name: 'RemitosCompraPendientes',
  data() {
    return {
      remitos: [],
    };
  },
  created() {
    RemitoApi.getRemitosPendientes()
      .then(response => {
        this.remitos = response.data.results;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    selectRemito(remito) {
      this.$emit('select-remito', remito);
    },
  },
};
</script>
