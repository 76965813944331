<template>
  <div class="mt-1">
    <b>¿Generaste el pedido de compra?</b>

    <div class="mt-1">
      <v-list dense rounded>
        <v-list-item
          style="background-color: grey"
          v-if="!selectPedido"
          @click="menu = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-page-next</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Seleccionar pedido </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-dialog width="500" scrollable v-model="menu">
      <v-card>
        <v-card-title
          >Pedidos aprobados
          <v-spacer></v-spacer>
          <v-btn large icon @click="menu = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-alert v-if="pedidos.length == 0" prominent dense type="warning">
            <v-row align="center">
              <v-col class="grow"> No hay pedidos aprobados </v-col>

              <v-col class="shrink">
                <v-btn @click="goToGenerarPedido">Ir a Aprobar</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-list v-else>
            <v-list-item dense>
              <v-list-item-action>
                <small>Nro</small>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Proveedor</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item-group v-model="select" color="primary">
              <v-list-item
                @click="seleccionarPedido(pedido)"
                v-for="pedido in pedidos"
                :key="pedido.id"
              >
                <v-list-item-action>{{
                  pedido.numeroVentaPorArqueo
                }}</v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    pedido.proveedor.nombre
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    pedido.fechaAlta
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <pedido-seleccionado-consultar
      tipoComprobante="compra"
      @set-menu="menu = !menu"
      v-if="selectPedido"
      @reestablecer-detalles="reestablecerDetalles"
      @reset-pedido="resetPedido"
      :pedido="selectPedido"
    ></pedido-seleccionado-consultar>
  </div>
</template>

<script>
import InformationDialogIcon from "@/components/dialogs/information-dialog.icon.vue";

import pedidoSeleccionadoConsultar from "@/components/pedido/consultar/pedido.seleccionado.consultar.vue";
export default {
  name: "pedido.compra.aprobados.list",
  components: {
    pedidoSeleccionadoConsultar,
    InformationDialogIcon,
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
    },
    selectPedido: {
      required: true,
    },
  },
  data() {
    return {
      select: null,

      menu: false,
    };
  },
  methods: {
    goToGenerarPedido() {
      this.$router.push({ name: "pedidoCompra" });
    },
    ahoraQuieroSeleccionarPedido() {
      this.menu = true;
      this.$emit("reset-pedido");
    },
    seleccionarPedido(pedido) {
      this.menu = false;
      this.$emit("set-pedido", pedido);
      this.select = null;
    },
    resetPedido() {
      this.$emit("reset-pedido");
    },
    setRemitoSinPedido() {
      this.$emit("set-remito-sin-pedido");
    },
    reestablecerDetalles() {
      this.$emit("reestablecer-detalles");
    },
  },
};
</script>

<style lang="scss" scoped></style>
