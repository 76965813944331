<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> Lista de precios guardadas </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action> <v-btn @click="goToRoute('reportesProductosGenerarLista')">Generar nueva lista</v-btn> </v-list-item-action>
      </v-list-item>
      <template v-if="reportes.length > 0">
        
      <v-list-item v-for="(reporte, index) in firstReportes" :key="index">
        <v-list-item-icon>
          <v-icon color="red">mdi-file-pdf-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ reporte.nombre }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ moment(reporte.fechaUltimaConsulta).format("lll") }}
        </v-list-item-action>
        <v-list-item-action>
          <v-btn @click="openReporte(reporte)"
            >Descargar <v-icon>mdi-download</v-icon></v-btn
          >
        </v-list-item-action>
        <v-list-item-action>
          <v-btn icon @click="eliminarReporte(reporte.id)"
            > <v-icon>mdi-delete</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="reportes.length > 4">
        <v-list-item-content>
          <v-list-item-title> </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action> </v-list-item-action>
        <v-list-item-action>
          <v-btn
            color="primary"
            text
            @click="dialogAllReportes = true"
            v-if="reportes.length > 0"
            >Ver todas</v-btn
          >
        </v-list-item-action>
      </v-list-item>
      </template>
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title> <v-alert type="info">No hay listas de precios guardadas</v-alert> </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action> </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialogAllReportes" width="500">
      <v-card class="items-reporte">
        <v-card-title>Reportes guardados</v-card-title>

        <v-list two-line class="detalle">
          <v-list-item
            v-for="(reporte, index) in reportes"
            :key="index"
            @click="openReporte(reporte)"
          >
            <v-list-item-icon>
              <v-icon color="red">mdi-file-pdf-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-spacer></v-spacer>{{ reporte.nombre }}</v-list-item-title
              >
              <v-list-item-subtitle
                >Fecha de generación:
                {{
                  moment(reporte.fecha).format("DD-MM-YYYY, HH:mm")
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <!-- <v-icon color="grey lighten-1">mdi-information</v-icon> -->
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Descargar</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-if="reportaParaDescargar"
      v-model="dialogDescargarReporte"
      width="500"
    >
      <v-card>
        <v-card-title>
          <b>Descargar reporte</b>
          <v-icon color="red">mdi-file-pdf-box</v-icon>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="grey" @click="dialogDescargarReporte = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">
            ¿Seguro que quieres descargar el reporte
            <span class="title">'{{ reportaParaDescargar.nombre }}'?</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="dialogDescargarReporte = false" text>
            <b>Cancelar</b>
          </v-btn>
          <v-btn @click="descargarReporte" color="info">
            <b>Descargar</b>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { descargarReporteProductoListado, eliminarReporteListaPrecio } from "@/services/reportes";
export default {
  name: "ReportesGuardados",
  data() {
    return {
      reporte: null,
      reportaParaDescargar: null,
      dialogDescargarReporte: false,
      dialogAllReportes: false,
      reportes: [],
    };
  },
  computed: {
    firstReportes() {
      if (this.reportes.length > 0) {
        return this.reportes.slice(0, 4);
      } else {
        return this.reportes;
      }
    },
  },
  methods: {
        goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    openReporte(reporte) {
      this.reportaParaDescargar = reporte;
      this.dialogDescargarReporte = true;
    },
    eliminarReporte(id){
      eliminarReporteListaPrecio(id).then(response => {
         this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: response.data.data,
            icon: "mdi-information",
            timeout: 2500,
          });
          this.getReportesGuardados()
      }).catch(e => {
        console.log(e);
      })
    },
    descargarReporte() {
      var reporteId = this.reportaParaDescargar.id;
      var parametros = {
        reporte: reporteId,
        grupo: ["categoriaArbol"],
        filtro: { proveedor: [0] },
        guardar: false,
        nombre: "",
      };

      descargarReporteProductoListado(parametros);

      this.dialogDescargarReporte = false;
    },
  

    gerReporteGuardado(id) {
      this.axios
        .get(`/reportes/reportesgenerado/${id}`)
        .then((response) => {
          this.reportaParaDescargar = response.data;
        })
        .catch((e) => {
          console.log("Error al consultar reporte guardado");
        });
    },
    getReportesGuardados() {
      this.axios
        .get("/reportes/reportesgenerado")
        .then((response) => {
          this.reportes = response.data;
        })
        .catch((e) => {
          console.log("Error al consultar reporte guardado");
        });
    },
  },
  created() {
    this.getReportesGuardados();
  },
};
</script>

<style scoped>
.v-list.detalle {
  height: 400px;
  overflow-y: auto;
}
.items-reporte:hover ::-webkit-scrollbar {
  width: 8px !important;
}
.items-reporte ::-webkit-scrollbar {
  width: 0px;
}
/* width */

/* Track */
.items-reporte ::-webkit-scrollbar-track {
  background: transparent;
}
.items-reporte ::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 38, 38);
}
/* Handle */
.items-reporte ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
