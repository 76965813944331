<template>
  <div>
     <v-date-picker
      v-model="egreso.fechaPeriodo"
      no-title
      type="month"
      class="mb-4"
      locale="es"
      year-icon="mdi-calendar-blank"
      prev-icon="mdi-skip-previous"
      next-icon="mdi-skip-next"
    ></v-date-picker>
    <egreso-form-categoria
      :egreso="egreso"
    ></egreso-form-categoria>
    <v-select
      v-model="egreso.tipoIngresoEgreso"
      :items="tiposIngresoEgreso"
      no-data-text="No hay tipos de egresos registrados"
      value="tipoEgreso"
      outlined
      item-text="descripcion"
      item-value="descripcion"
      label="Tipo de egreso"
      name="Tipo de egreso"
      v-validate="'required'"
      :error-messages="errors.collect('tipoEgreso')"
      data-vv-name="tipoEgreso"
    ></v-select>
    <v-textarea
      outlined
      label="Descripción"
      v-model="egreso.descripcion"
      placeholder="descripcion"
      name="descripción"
      maxlength="100"
      :counter="100"
      v-validate="'max:100'"
      :error-messages="errors.collect('descripcion')"
    ></v-textarea>
    <v-text-field
      outlined
      v-model="egreso.monto"
      v-validate="'required|numeric|max:10'"
      :error-messages="errors.collect('total')"
      data-vv-name="total"
      label="Total"
      prefix="$"
      type="number"
      required
    ></v-text-field>
  </div>
</template>

<script>
import EgresoFormCategoria from "@/components/egresos/EgresoFormCategoria";
export default {
  name: "EgresoForm",
  inject: ["$validator"],
  components: {
    EgresoFormCategoria,
  },
  props: {
    egreso: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tiposIngresoEgreso: [],
    };
  },
  methods: {
    async fetchTipos() {
      const response = await this.axios.get(
        "/egresos/tipoingresoegreso?esIngreso=False"
      );
      this.tiposIngresoEgreso = response.data;
    },
  },
  created() {
    this.fetchTipos();
  },
};
</script>

<style lang="scss" scoped>
</style>