<template>
  <div>
     <v-badge
        color="red"
        :value="selected.length"
        :content="selected.length"
        
      >
        <span  class="text-body-2"
          ><b>Plataforma</b></span
        >
      </v-badge>
    <v-list v-if="type == 'list'" dense class="ml-2">
      <v-list-item @click="setPlataforma">
        <v-list-item-content>
          <v-list-item-title> Pedidos Ya </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="type == 'checkbox'">
      <v-checkbox
        class="pb-0 pt-0"
        dense
        multiple
        v-for="(item, i) in plataformas"
        :key="i"
        :label="`${item.nombre}`"
        v-model="selected"
        :value="item.key"
        @change="setPlataforma(item, $event)"
      >
      </v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "producto.filtro.plataforma",
  props: {
    plataformas: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
  },
  data() {
    return {
      selected: [],
    };
  },

  methods: {
    setPlataforma(item) {
      this.$emit("select-plataforma", item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>