<template>
  <v-card flat>
    <v-container v-if="planDeCuentas">
      <v-card-title> Contabilidad </v-card-title>
      <v-card-text>
        <v-list>
          <v-col cols="12">
            <CondicionIvaVSelect :entity="cliente"></CondicionIvaVSelect>
          </v-col>
          <v-col cols="12">
            <RubroVentaListSelect
              :value="cliente.rubro_ventas"
              @process-select="setRubroVenta"
              title="Rubro venta"
            ></RubroVentaListSelect>
          </v-col>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Cuenta Corriente:
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="cliente.cuenta_corriente"
                  @update:initialCuenta="setCuentaCorriente"
                ></CuentasTreeviewDialog>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Cuenta Contado:
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="cliente.cuenta_contado"
                  @update:initialCuenta="setCuentaContado"
                ></CuentasTreeviewDialog>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Cuenta Otros
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="cliente.cuenta_otros"
                  @update:initialCuenta="setCuentaOtros"
                ></CuentasTreeviewDialog>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <date-picker-date-of-birth
          @process-date-birthday="setDateOfBirth"
        ></date-picker-date-of-birth> -->
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn text color="grey" @click="$emit('close-dialog')"
            >Cancelar</v-btn
          >

          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{ textButton }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import DatePickerDateOfBirth from "@/util/DatePickerDateOfBirth.vue";
import CondicionIvaVSelect from "@/components/util/CondicionIvaVSelect.vue";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import RubroVentaListSelect from "@/components/contabilidad/RubroVentaListSelect.vue";

export default {
  name: "ClienteFormContabilidad",
  components: {
    ValidationObserver,
    DatePickerDateOfBirth,
    CondicionIvaVSelect,
    CuentasTreeviewDialog,
    RubroVentaListSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    cliente: {
      type: Object,
      required: true,
    },

    textButton: {
      type: String,
      required: false,
      default: "Guardar",
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      loading: false,
      searchInput: "",
      searchInputLocalidad: "",

      barrios: [],
      persona: null,
    };
  },

  methods: {
    setCuentaCorriente(value) {
      this.cliente.cuenta_corriente = value[0];
    },
    setCuentaContado(value) {
      this.cliente.cuenta_contado = value[0];
    },
    setCuentaOtros(value) {
      this.cliente.cuenta_otros = value[0];
    },
    setDateOfBirth(date) {
      this.cliente.fechaNacimiento = date;
    },
    setPersona(persona) {
      this.persona = persona;
    },
    setRubroVenta(value) {
      this.cliente.rubro_ventas = value;
    },

    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-cliente", this.cliente);
        } else {
          this.$validator.validate();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
