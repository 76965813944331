<template>
  <categoria-update
    :categoria="categoria"
    @refresh-categorias="$emit('refresh-categorias')"
    @cerrar-dialog="$emit('cerrar-dialog')"
    @update-category="actualizarCategoria"
  ></categoria-update>
</template>
<script>
import CategoriaUpdate from "@/components/categorias/categoria.update";
import ProductosAPI from "@/api/productos";
export default {
  name: "ProductoCategoriaUpdate",
  components: {
    CategoriaUpdate,
  },
  props: {
    categoria: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    actualizarCategoria(categoria) {
      var data = {
        padre: categoria.parent,
        name: categoria.name,
      };
      ProductosAPI.updateCategoria(categoria.id, data)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Categoría actualizada",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("refresh-categorias");
          this.$emit("cerrar-dialog");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Problema al actualizar",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("cerrar-dialog");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>