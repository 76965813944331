<template>
  <v-card height="100%">
    <v-card-title
      ><h2>¡Cobremos!</h2>
      <v-img
        v-if="esPedidoYa"
        class="mx-2"
        src="@/assets/pedidosya/pedidoYa.svg"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn large icon @click="$emit('close-dialog')"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-card-title
    >
    <v-container>
      <v-card v-if="!esPedidoYa" class="d-flex justify-end mb-2" flat tile>
        <v-switch
          v-model="esParaFacturar"
          hide-details
          dense
          :label="esParaFacturar ? 'Factura' : 'Pendiente'"
          class="pt-0 mt-0"
        ></v-switch>
      </v-card>
      <formasdepago-list
        :subtotal="subtotal()"
        :formasDePago="formasDePago"
      ></formasdepago-list>
      <!-- <v-list>
        <v-list-item v-for="fp in formasDePago" :key="fp.id" dense>
          <forma-de-pago
            @seleccionar-cliente="seleccionarCliente"
            :formaDePago="fp"
          >
          </forma-de-pago>
        </v-list-item>
      </v-list> -->
      <v-divider></v-divider>
      <v-list>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Subtotal</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon
            ><h4>{{ subtotalConImpuestos | convertPesoArg }}</h4>
          </v-list-item-icon>
        </v-list-item>
        <!-- <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Impuestos</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon
            ><h4>{{ Number(impuestos()) | convertPesoArg }}</h4>
          </v-list-item-icon>
        </v-list-item> -->

        <venta-cobrar-descuento :subtotal="subtotal"></venta-cobrar-descuento>

        <venta-cobrar-recargo></venta-cobrar-recargo>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h2>Total</h2></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon
            ><h2>{{ Number(total()) | convertPesoArg }}</h2>
          </v-list-item-icon>
        </v-list-item>
        <template v-if="totalPagoCliente">
          <v-list-item dense v-if="totalPagoCliente >= total()">
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  <h2 class="success--text">VUELTO:</h2>
                </div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <h2 class="success--text">
                {{ vuelto() | convertPesoArg }}
              </h2>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item dense v-else>
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  <h2 class="orange--text">Faltan:</h2>
                </div></v-list-item-title
              >
            </v-list-item-content>

            <v-list-item-icon>
              <h2 class="orange--text">{{ faltante() | convertPesoArg }}</h2>
            </v-list-item-icon>
          </v-list-item>
        </template>

        <v-divider></v-divider>
        <!-- <v-list-item v-show="cliente && cliente.paraEnviar">
          <v-list-item-avatar>
            <v-icon color="indigo">mdi-truck-delivery</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Para enviar</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-list-item-subtitle
              >{{ envio.calle }} {{ envio.altura }}</v-list-item-subtitle
            >
          </v-list-item-icon>
        </v-list-item> -->
      </v-list>
    </v-container>
    <v-card-actions>
      <v-btn
        block
        class=""
        x-large
        color="success"
        @click="validateForm()"
        :loading="loadingRegistrar"
        :disabled="errors.any() || !pagoClienteValido"
        ><span class="title">Cobrar </span>
        <template v-if="esParaFacturar" v-slot:loader>
          <span
            >Esperando a AFIP ...
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular
          ></span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import formaDePago from "@/components/comprobante/forma-de-pago.vue";
import VentaCobrarDescuento from "@/components/venta/cobro/venta.cobrar.descuento.vue";
import VentaCobrarRecargo from "@/components/venta/cobro/venta.cobrar.recargo.vue";
import FormasdepagoList from "../../comprobante/formasdepago.list.vue";

export default {
  components: {
    formaDePago,
    VentaCobrarDescuento,
    FormasdepagoList,
    VentaCobrarRecargo,
  },
  name: "venta.cobrar",
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    detallesVenta: {
      type: Array,
      required: true,
    },
    formasDePago: {
      type: Array,
      required: true,
    },
    cliente: {
      type: Object,
    },
    envio: {
      type: Object,
    },
    tipoRedondeo: {
      type: String,
      required: true,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
  data() {
    return {
      esParaFacturar: false,
      pagoCliente: [],
      formaDePago: [],
      loadingRegistrar: false,
    };
  },
  computed: {
    ...mapState("remitos", ["esPedidoYa"]),
    ...mapState("ventas", ["clienteVenta"]),

    ...mapGetters("ventas", [
      "totalPagoCliente",
      "get_total",
      "get_total_descuento",
      "get_total_recargo_credito",
    ]),
    // ...mapGetters("comprobantes", ["formasDePago"]),
    subtotalConImpuestos() {
      // Convertir los valores a números
      const subtotal = Number(this.subtotal());
      const impuestos = Number(this.impuestos());

      // Realizar la suma
      return subtotal + impuestos;
    },
    pagoClienteValido() {
      return this.totalPagoCliente >= this.total();
    },
    descuento() {
      return this.$store.state.ventas.descuento;
    },
    motivoDescuento() {
      return this.$store.state.ventas.motivoDescuento;
    },
    recargo() {
      return this.$store.state.ventas.porcentajeRecargoCredito;
    },
  },
  methods: {
    ...mapMutations("ventas", ["RESET_FORMAS_DE_PAGO_SELECCIONADAS"]),
    ...mapActions("ventas", ["reset_recargo_descuento_credito"]),
    ...mapActions("comprobantes", ["fetch_formas_de_pago"]),

    seleccionarCliente() {
      alert("Seleccionar cliente");
    },

    vuelto() {
      return parseFloat((this.totalPagoCliente - this.total()).toFixed(2));
    },
    faltante() {
      return parseFloat((this.total() - this.totalPagoCliente).toFixed(2));
    },
    subtotal() {
      let subtotal = 0;
      for (let index = 0; index < this.detallesVenta.length; index++) {
        if (this.detallesVenta[index].producto) {
          let precioBase = parseFloat(this.detallesVenta[index].producto.precioBase.toFixed(4)); // Redondear a 4 decimales
          let cantidad = parseFloat(this.detallesVenta[index].cantidad);
          cantidad = parseFloat(cantidad.toFixed(2)); // Redondear a 2 decimales
          let precioCalculado = parseFloat((cantidad * precioBase).toFixed(2)); // Redondear a 4 decimales

          subtotal += precioCalculado;
        }
      }
      
      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        subtotal = Math.round(subtotal); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        subtotal = parseFloat(subtotal.toFixed(2)); // Redondea a dos decimales
      }

      return subtotal;
    },
    impuestos() {
      let totalImpuestos = 0;
      for (let index = 0; index < this.detallesVenta.length; index++) {
        if (this.detallesVenta[index].producto) {

          let precioBase = parseFloat(this.detallesVenta[index].producto.precioBase.toFixed(4)); // Redondear a 4 decimales
          let cantidad = parseFloat(this.detallesVenta[index].cantidad);
          cantidad = parseFloat(cantidad.toFixed(2)); // Redondear a 2 decimales
          let iva_number = parseFloat(this.detallesVenta[index].producto.iva.description);
          let iva_description = this.detallesVenta[index].producto.iva.description
          
          if (iva_description.toLowerCase()  == 'exento' ){
             iva_number = parseFloat(0);
          }else{
             iva_number = parseFloat(iva_description);
          }

          let impuestoUnitario = parseFloat((precioBase * (iva_number / 100)).toFixed(2)); // Redondear a 4 decimales
          totalImpuestos += parseFloat((impuestoUnitario * cantidad).toFixed(2)); // Redondear a 4 decimales
        }
      }

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        totalImpuestos = Math.round(totalImpuestos); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        totalImpuestos = parseFloat(totalImpuestos.toFixed(2)); // Redondea a dos decimales
      }

      return totalImpuestos;
    },
    total() {
      let total = parseFloat(this.subtotal()) + parseFloat(this.impuestos());
  // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.descuento) {
        total = parseFloat((total - this.get_total_descuento).toFixed(2));
      }

      if (this.recargo) {
        total = parseFloat((total + this.get_total_recargo_credito).toFixed(2));
      }

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        total = Math.round(total); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        total = parseFloat(total.toFixed(2)); // Redondea a dos decimales
      }

      return total;
    },

    reset() {
      this.RESET_FORMAS_DE_PAGO_SELECCIONADAS();
      this.reset_recargo_descuento_credito();
    },
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.registrar();
        } else {
          this.$validator.validate();
        }
      });
    },
    setLoading(bool) {
      this.loadingRegistrar = bool;
    },
    registrar() {
      this.loadingRegistrar = true;
      if (
        this.totalPagoCliente == null ||
        this.totalPagoCliente < this.total()
      ) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Controlá que este todo bien.`,
          icon: "mdi-hand-okay",
          timeout: 2500,
        });
        return;
      } else {
        var data = {
          descuento: this.descuento,
          recargo: this.recargo,
          motivoDescuento: this.motivoDescuento,
          formaDePago: this.formaDePago,
          pagoCliente: this.totalPagoCliente,
          esParaFacturar: this.esParaFacturar,
          cliente: this.clienteVenta ? this.clienteVenta.id : 0,
        };
        this.$emit("process-register", data);
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      event.preventDefault();
      // if (this.dialogCobrar) {
      if (event.key === "Enter") {
        if (this.pagoClienteValido) {
          this.validateForm();
        }
      }
      // }
      // if (!this.dialogCobrar && !this.esValidoRegistrar && !this.errors.any()) {
      //   if (event.key === "F4") {
      //     this.dialogCobrar = true;
      //   }
      // }
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
    });
  },
  created() {
    this.fetch_formas_de_pago();
  },
};
</script>

<style scoped>
.subheader {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 60px;
}
</style>
