<template>
  <v-card>
    <v-container>
      <v-card-title>
        <h4 class="text-start">Gastos por categoría</h4>
        <small
          >Analizá tus categorías y gasto mensual para controlar tus
          finanzas.</small
        >
      </v-card-title>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-divider></v-divider>
          <doughnut-chart
            v-if="chartGastos"
            :chartdata="chartGastos"
            :key="keyChartGastos"
            :options="chartOptions"
          />
          <h2 class="text-center">
            {{ totalGastosPorCategoria | convertPesoArg }}
          </h2>

          <v-list v-if="chartGastos && verListadoGastos">
            <v-list-item
              v-for="gasto in chartGastos.data"
              :key="gasto.id"
              @click="
                $router.push({
                  name: 'reporteGastosDetalle',
                  params: { categoria: gasto },
                })
              "
            >
              <v-list-item-avatar>
                <v-chip :color="gasto.color"></v-chip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ gasto.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ gasto.total_categoria | convertPesoArg }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
   
    </v-container>
         <v-list-item @click="$router.push({ name: 'reporteGastos' })">
          <v-list-item-content>

          <v-list-item-title> <span style="color: #009ee3"> Analizar gastos</span> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon color="#009ee3">mdi-chevron-right</v-icon>
          </v-list-item-action>

        </v-list-item>
  </v-card>
</template>

<script>
import DoughnutChart from "@/chartjs/doughnut.chart.vue";

export default {
  name: "ChartGastosPorMes",
  components: { DoughnutChart },
  props: {
    chartGastos: {
      required: true,
    },
    verListadoGastos: {
      required: false,
      default: true,
    },
    verAction: {
      required: false,
      default: false,
    },
  },
  computed: {
    totalGastosPorCategoria() {
      if (this.chartGastos) {
        const sum = this.chartGastos.data.reduce((accumulator, object) => {
          return accumulator + object.total_categoria;
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      keyChartGastos: 1,
      chartOptions: {
        showLines: true,
        legend: {
          display: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>