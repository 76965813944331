<template>
  <v-container>
    <v-row
      justify="center"
      style="height: 100vh; max-height: calc(100vh - 250px)"
    >
      <v-col cols="8">
        <div
          class="d-flex align-center justify-center mb-6"
          style="height: 100%"
        >
          <v-row justify="center">
            <v-col>
              <v-row class="mt-1" justify="space-around">


              <v-col v-for="(item, i) in items" :key="i"  cols="4" >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      @click="goToRoute(item.route)"
                      height="258px"
                      width="240px"
                      class="d-flex align-center"
                      :color="hover ? '#313233' : ''"
                    >
                      <v-container class="text-center">
                        <div>
                     
                          <v-icon color="blue" large v-text="item.icon"></v-icon>
                        </div>
                        <h3>{{item.text}}</h3>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>






                <!-- <v-col cols="4">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      @click="goToRoute('pedidoCompra')"
                      height="258px"
                      width="240px"
                      class="d-flex align-center"
                      :color="hover ? '#313233' : ''"
                    >
                      <v-container class="text-center">
                        <div>
                          <v-icon color="blue" large
                            >mdi-file-document-multiple</v-icon
                          >
                        </div>
                        <h3>Pedidos de compra</h3>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="4">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      @click="goToRoute('facturaCompra')"
                      height="258px"
                      width="240px"
                      class="d-flex align-center"
                      :color="hover ? '#313233' : ''"
                    >
                      <v-container class="text-center">
                        <div>
                          <v-icon color="blue" large
                            >mdi-file-document-multiple-outline</v-icon
                          >
                        </div>
                        <h3>Factura de compra</h3>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
export default {
  name: "Compras",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      items: [
        { text: "Pedidos de compra", icon: "mdi-file-document-multiple", route: "pedidoCompra" },
        { text: "Remitos de compra", icon: "mdi-file-document-multiple-outline", route: "remitoCompra" },

        { text: "Facturas de compra", icon: "mdi-file-document-multiple-outline", route: "facturaCompra" },
      ],
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss" scoped></style>
