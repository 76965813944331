<template>
  <v-container fluid>
    <template v-if="productos.length > 0">
      <v-row>
        <v-col cols="12" md="6" lg="4" v-for="producto in productos" :key="producto.id">
          <v-card
            :disabled="
              producto.controlaStock &&
              producto.cantidadstock <= 0 &&
              !$route.path.includes('compras')
            "
            color="#303030"
            @click="$emit('process-producto', producto)"
            hover
            height="100%"
          >
            <v-card-title v-if="esPedidoYa" class="ma-0 mt-1 pa-0">
              <v-spacer></v-spacer>
              <v-img
                src="@/assets/pedidosya/pedidoya.png"
                max-height="20"
                max-width="20"
                contain
              ></v-img>
            </v-card-title>

            <v-row justify="center" align="center">
              <v-col cols="12" class="text-xs-center" align="center">
                <div class="title">{{ producto.descripcion }}</div>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col cols="12" class="text-xs-center" align="center">
                  <small>Código: {{ producto.codigo }}</small>
                  <div class="title font-weight-light">
                    <b>$ {{ isCompra ? producto.precioCosto :  producto.precioVenta }}</b>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-pagination
            v-if="productos"
            @input="paginationChangeHandler"
            :length="pages"
            :value="paginacion.page"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row justify="center" align="center">
        <v-col cols="10">
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-row justify="center" align="center">
                <v-col cols="8">
                  <div class="headline">
                    <strong>No se han encontrado productos.</strong>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "productos.list.card.select",

  props: {
    productos: {
      type: Array,
      required: true,
      default: () => [],
    },
    paginacion: {
      type: Object,
      required: true,
    },
    isCompra: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
    ...mapState("remitos", ["esPedidoYa"]),
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.$emit("set-page", pageNumber);
      } else {
        this.$emit("set-page", pageNumber);
      }
    },
  },
  mounted() {},
};
</script>
