<template>
  <v-row justify="center">
    <v-col cols="10">
      <v-row>
        <v-col>
          <v-card color="transparent" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large> mdi-pencil </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h4 mb-1">
                    Editor masivo
                  </v-list-item-title>
                
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-toolbar>
        <v-checkbox
          class="mt-4 ml-5"
          dense
          v-model="esSeleccionManual"
          label="Selección manual"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <span class="title">{{ cantidadProductosParaActualizar }}</span>
        <v-menu :close-on-content-click="true" :close-on_click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- Actualizar Precio -->
            <v-list-item>
              <v-list-item-title>
                <CmpEditorMasivoPrecioUpdate
                  @process-update="fetchProductos"
                  :isSeleccionManual="productos_admin_seleccion_manual"
                  :cantidadProductosParaActualizar="
                    cantidadProductosParaActualizar
                  "
                  :productosParaActualizar="productosParaActualizar"
                />
              </v-list-item-title>
            </v-list-item>
            <!-- Ganancia -->
            <v-list-item>
              <v-list-item-title>
                <CmpEditorMasivoGananciaUpdate
                  @process-update="fetchProductos"
                  :isSeleccionManual="productos_admin_seleccion_manual"
                  :cantidadProductosParaActualizar="
                    cantidadProductosParaActualizar
                  "
                  :productosParaActualizar="productosParaActualizar"
                />
              </v-list-item-title>
            </v-list-item>
            <!-- Actualizar Categoria -->
            <v-list-item v-if="seleccionManual">
              <v-list-item-title>
                <CmpEditorMasivoCategoriaUpdate
                  @process-update="fetchProductos"
                  :isSeleccionManual="productos_admin_seleccion_manual"
                  :cantidadProductosParaActualizar="
                    cantidadProductosParaActualizar
                  "
                  :productosParaActualizar="productosParaActualizar"
                ></CmpEditorMasivoCategoriaUpdate>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <productos-list-update></productos-list-update>
    </v-col>
  </v-row>
</template>

<script>
import ProductosListUpdate from "@/components/producto/listar/productos.list.update.vue";
import CmpEditorMasivoCategoriaUpdate from "@/components/producto/actualizar/CmpEditorMasivoCategoriaUpdate.vue";
import CmpEditorMasivoGananciaUpdate from "@/components/producto/actualizar/CmpEditorMasivoGananciaUpdate.vue";
import CmpEditorMasivoPrecioUpdate from "@/components/producto/actualizar/CmpEditorMasivoPrecioUpdate.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "CmpProductoEditorMasivo",
  components: {
    ProductosListUpdate,
    CmpEditorMasivoCategoriaUpdate,
    CmpEditorMasivoGananciaUpdate,
    CmpEditorMasivoPrecioUpdate,
  },

  data() {
    return {
      seleccionManual: false,
    };
  },
  computed: {
    ...mapState("productos", [
      "paginacion_productos_admin",
      "productos_admin",
      "productos_admin_selected",
      "productos_admin_seleccion_manual",
    ]),
    esSeleccionManual: {
      get() {
        return this.seleccionManual;
      },
      set(val) {
        this.seleccionManual = val;
        if (val == false) {
          this.SET_PRODUCTOS_ADMIN_SELECTED([]);
        }
        this.SET_SELECCION_MANUAL_PRODUCTOS_ADMIN(val);
      },
    },
    productosParaActualizar() {
      return this.productos_admin_seleccion_manual
        ? this.productos_admin_selected
        : this.productos_admin;
    },
    cantidadProductosParaActualizar() {
      return this.productos_admin_seleccion_manual
        ? this.productos_admin_selected.length
        : this.paginacion_productos_admin.cantidad;
    },
  },
  methods: {
    ...mapMutations("productos", [
      "set_page_productos_admin",
      "SET_SELECCION_MANUAL_PRODUCTOS_ADMIN",
      "SET_PRODUCTOS_ADMIN_SELECTED",
    ]),

    ...mapActions("productos", [
      "get_productos_for_filters",
      "fetch_productos_mas_vendidos",
    ]),
    fetchProductos() {
      this.set_page_productos_admin(1);
      this.fetch_productos_mas_vendidos();
      this.get_productos_for_filters();
      this.SET_PRODUCTOS_ADMIN_SELECTED([]);
      this.esSeleccionManual = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>