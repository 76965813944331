<template>
  <layout-actualizar-productos>
    <template v-slot:title>
      <v-list class="ma-0 mt-2 pa-0">
        <v-list-item class="pl-1">
          <v-list-item-avatar class="ma-1">
            <v-btn icon @click="$router.push('/productos')">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-title>
            <h1>Actualizar producto multiple</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list-item-group
        v-if="!displayProducts"
        v-model="item"
        mandatory
        color="indigo"
      >
        <v-list>
          <v-list-item link @click="displayProducts = false">
            <v-list-item-avatar>
              <v-icon>mdi-home</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title"> Principal </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="tab = 0">
            <v-list-item-avatar>
              <v-icon>mdi-home</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title">
                Precio de costo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-divider></v-divider>
          <v-list-item link @click="displayProducts = true">
            <v-list-item-avatar>
              <v-icon>mdi-basket</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title">Ver productos</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-list-item-group>

      <v-list v-else>
        <v-list-item link>
          <v-list-item-avatar @click="displayProducts = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="title">Productos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card>
        <v-row>
          <v-col cols>
            <v-card max-width="500" class="overflow-y-auto" max-height="440px">
              <v-card-title>
                <div>
                  Cantidad de productos:
                  <span class="headline">{{ productos_admin.length }} </span>
                </div>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- <v-list-item-group v-model="indexProducto" color="primary">
                <v-list-item v-for="(item, i) in productos" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.id"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.descripcion"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group> -->
              <v-data-table
                hide-default-footer
                hide-default-header
                disable-pagination
                v-model="productSelect"
                :single-select="true"
                :headers="headers"
                :items="productos_admin"
                :search="search"
                show-select
              >
                <template v-slot:item="{ item, isSelected, select }">
                  <tr
                    :class="isSelected ? 'indigo' : ''"
                    @click="toggle(isSelected, select)"
                  >
                    <td class="d-flex align-center">
                      {{ item.descripcion }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </template>
    <template v-slot:vista-previa>
      <div v-if="displayProducts" id="displayProducts">
        <v-card>
          <v-row>
            <v-col cols="12">
              {{ productSelect }}
              <div v-if="productSelect.length > 0">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="displayVistaPrevia = true"
                        ><v-icon>mdi-eye</v-icon></v-btn
                      >
                    </template>
                    <span>Vista previa</span>
                  </v-tooltip>
                </div>
                <v-dialog width="600px" v-model="displayVistaPrevia">
                  <item-producto-vista-previa
                    :producto="productSelect[0]"
                    v-if="productSelect.length > 0"
                  >
                    <template v-slot:buttonClose>
                      <v-icon large @click="displayVistaPrevia = false"
                        >mdi-close</v-icon
                      >
                    </template>
                  </item-producto-vista-previa>
                </v-dialog>
                <item-producto :producto="productSelect[0]"></item-producto>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-card v-else>
        <v-container>
          <v-card-text>
            <div>
              <v-container>
                <v-row>
                  <v-col>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col cols="4">
                                <div class="title">Selecciona la operación</div>
                                <div>
                                  <v-select
                                    color="blue"
                                    v-model="selectOperacion"
                                    :items="operacion"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Operación"
                                  ></v-select>
                                </div>
                              </v-col>
                              <v-col cols="4">
                                <div class="title">Ingrese un porcentaje</div>
                                <div>
                                  <v-text-field
                                    v-model="porcentaje"
                                    type="number"
                                    required
                                    label="Porcentaje"
                                    append-icon="mdi-percent"
                                    min="0"
                                  ></v-text-field>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                Los productos seleccionados van a
                                {{
                                  selectOperacion == 1
                                    ? "INCREMENTAR"
                                    : "DECREMENTAR"
                                }}
                                un %{{ porcentaje }}
                              </v-col>
                            </v-row>
                            <v-row justify="end">
                              <v-col>
                                <v-btn large block @click="aplicarPrecioCompra"
                                  >APLICAR</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col cols="4">
                                <div class="title">Selecciona la marca</div>
                                <div>
                                  <marca-select></marca-select>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row justify="end">
                              <v-col>
                                <v-btn large block @click="aplicarMarca"
                                  >APLICAR</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col cols="4">
                                <div class="title">Selecciona el proveedor</div>
                                <div>
                                  <proveedor-select></proveedor-select>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row justify="end">
                              <v-col>
                                <v-btn large block @click="aplicarProveedor"
                                  >APLICAR</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col cols="4">
                                <div class="title">Selecciona la categoría</div>
                                <div>
                                  <categoria-card-select
                                    @process-categoria="setCategoria"
                                  ></categoria-card-select>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row justify="end">
                              <v-col>
                                <v-btn
                                  :disabled="!categoria"
                                  @click="aplicarCategoria"
                                  large
                                  block
                                  >APLICAR</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </template>
  </layout-actualizar-productos>
</template>

<script>
import CalculadoraPrecioVenta from "@/components/producto/actualizar/producto.calculadora.precioVenta.vue";
import LayoutActualizarProductos from "@/layouts/LayoutBasic.vue";;
import ItemProducto from "@/components/producto/actualizar/producto.item.actualizar";

import ItemProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";
import MarcaSelect from "@/components/marcas/FormSelectMarca.vue";
import ProveedorSelect from "@/components/proveedor/FormSelectProveedor.vue";
import CategoriaCardSelect from "@/components/categorias/consultar/CategoriaCardSelect.vue";
import { mapState } from 'vuex';

export default {
  name: "producto.actualizar.multiple.home",
  components: {
    LayoutActualizarProductos,
    ItemProducto,
    ItemProductoVistaPrevia,
    CalculadoraPrecioVenta,
    MarcaSelect,
    ProveedorSelect,
    CategoriaCardSelect,
  },
  data() {
    return {
      tab: null,
      search: "",
      headers: [{ text: "descripcion", value: "descripcion" }],
      indexProducto: 0,
      displayProducts: false,
      displayVistaPrevia: false,
      items: [
        { tab: "Precio compra", content: "Precio compra" },
        // { tab: "Marca", content: "Marca" },
        // { tab: "Proveedor", content: "Proveedor" },
        // { tab: "Categoría", content: "Categoría" },
      ],
      item: 0,
      productSelect: null,
      operacion: [
        { id: 1, nombre: "Incrementar" },
        { id: 2, nombre: "Decrementar" },
      ],
      porcentaje: null,
      selectOperacion: 1,
      loading: false,
      categoria: null,
    };
  },
  computed: {
    // producto: {
    //   get() {
    //     return this.$route.params.producto;
    //   },
    //   set(val){
    //     return val
    //   }
    // },
     ...mapState("productos", ["paginacion_productos_admin", "productos_admin"]),
    // productos() {
    //   return this.$route.params.productos;
    // },
  },
  methods: {
    setCategoria(categoria) {
      this.categoria = categoria;
    },
    toggle(isSelected, select) {
      this.displayProducts = true;
      if (isSelected) return;
      select(!isSelected);
    },
    aplicarPrecioCompra() {
      let productos_id = [];
      this.productos.forEach((element) => {
        productos_id.push(element.id);
      });
      var url = `/products/multiple/${this.selectOperacion}/${parseFloat(
        this.porcentaje
      )}`;
      this.axios.put(url, productos_id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Productos Actualizados",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.$router.push("/productos");
      });
    },
    aplicarMarca() {
      alert("Sin implementar");
    },
    aplicarProveedor() {
      alert("Sin implementar");
    },
    aplicarCategoria() {
      alert("Sin implementar");
    },

    actualizarProducto(p) {
      this.loading = true;
      var categoria_id = null;
      if (p.categoriaArbol) categoria_id = p.categoriaArbol.id;
      var data = {
        id: p.id,
        descripcion: p.descripcion,
        precioVenta: this.precioVenta,
        precioCosto: p.precioCosto,
        iva: p.iva,
        ganancia: p.ganancia,
        flete: p.flete,
        categoriaArbol_id: categoria_id,
        proveedor_id: p.proveedor.id,
        marca_id: null,
      };

      this.axios
        .put(`/products/${p.id}`, data)
        .then((response) => {
          this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto Actualizado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.loading = false;

          this.$emit("quitarProducto", p);
        })
        .catch((e) => {
          this.loading = false;

          this.$router.push({
            name: "MensajeRegistroError",
            params: { error: e.response.data },
          });
        });
    },

    quitarProducto(p) {
      let index = this.productos.indexOf(p);
      if (index > -1) {
        this.productos.splice(index, 1);
      }
    },
  },

  // created() {
  //   if (this.$route.params.productos === undefined) {
  //     this.$router.push("/productos");
  //   }
  // },
};
</script>

<style lang="scss" scoped></style>
