<template>
  <div>
    <h1>Ventas por mes</h1>
    <v-row>
      <v-col cols="12" md="6">
        <bar-chart v-if="loaded" :chartdata="ventasPorMes" :options="chartOptions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VentasPorRango from "@/views/reportes/ventas/reporte.ventas.por.periodo.vue";
import BarChart from "@/chartjs/bar.chart.vue";
import ReportesAPI from "@/api/reportes";
import DatePickerMultiple from "@/components/reportes/DatePickerMultiple";

import { descargarPedidoPDF } from "@/services/reportes";

export default {
  name: "reporte.ventas",
  components: {
    BarChart,
    VentasPorRango,
    DatePickerMultiple,
  },
  data() {
    return {
      ventasPorMes: null,
      loaded: false,
      menu: false,
       chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1000,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
      },
    };
  },
  computed: {
    myStyles() {
      return {
        width: `500px`,
      };
    },
  },
  methods: {
    fetchVentasMensual() {
      this.loaded = false;
      ReportesAPI.getReporteVentaMensual().then((response) => {
        this.ventasPorMes = response.data;
        this.loaded = true;
      });
    },

    async descargarReporte(reporte) {
      var url = `/reportes/reporte/0`;
      descargarPedidoPDF(10);
    },
  },
  mounted() {
    this.fetchVentasMensual();
  },
};
</script>

<style lang="scss" scoped></style>
