<template>
    <div>
        <slot name="list">
          asfas
        </slot>
        <v-pagination v-if="productos_admin" @input="paginationChangeHandler" :length="pages"
            :value="paginacion_productos_admin.page" circle>
        </v-pagination>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'ProductoLayoutList',
    computed: {
        ...mapState("productos", ["paginacion_productos_admin", "productos_admin"]),
        pages() {
            if (this.paginacion_productos_admin.rowsPerPage == null || this.paginacion_productos_admin.cantidad < 0) {
                return 0;
            }

            return Math.ceil(this.paginacion_productos_admin.cantidad / this.paginacion_productos_admin.rowsPerPage);
        },
    },
    methods: {
        ...mapMutations("productos", [
            "set_page_productos_admin",
        ]),
        ...mapActions("productos", [
            "get_productos_for_params",
        ]),
        get_text_transform(str) {
            if (typeof str === "string") {
                return str.match(/[^ ]+/g).join("-");
            } else {
                return str;
            }
        },
        get_params_query() {
            var array = [];
            var params = this.$route.params;
            if (params && params.categoria) {
                var object = {
                    value: null,
                    key: "",
                };
                object.value = params.categoria;
                object.key = "categoria";
                array.push(object);
            }

            var querys = this.$route.query;
            if (Object.keys(querys).length !== 0) {
                for (const property in querys) {
                    var object = {
                        nombre: null,
                        key: "",
                    };
                    object.key = property;
                    object.value = this.get_text_transform(querys[property]);
                    array.push(object);
                }
            }
            return array;
        },
        setPage_fetchProductos(page) {
            this.set_page_productos_admin(page);
            this.get_productos_for_params(this.get_params_query());
            window.scrollTo(0, 0);
        },
        paginationChangeHandler(pageNumber) {
            if (this.paginacion_productos_admin.page == pageNumber) {
                return false;
            }
            if (this.paginacion_productos_admin.page < pageNumber) {
                this.setPage_fetchProductos(pageNumber);
            } else {
                this.setPage_fetchProductos(pageNumber);
            }
        },
    }
}
</script>

<style lang="scss" scoped></style>