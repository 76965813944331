<template>
  <div>
    <v-list>
      <v-list-item v-for="fp in formasDePago" :key="fp.id" dense>
        <forma-de-pago :formaDePago="fp"> </forma-de-pago>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import formaDePago from "@/components/comprobante/forma-de-pago.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "formasdepago.list",
  components: {
    formaDePago,
  },
  props: {
    formasDePago: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
</style>