<template>
  <v-autocomplete
    :label="title"
    :autofocus="false"
    v-model="data"
    :items="marcas"
    item-text="nombre"
    required
    item-value="id"
    @change="set_select_data"
    @click:clear="clearData()"
    :disabled="disabled"
    :placeholder="placeholder"
    :filled="filled"
    :solo="solo"
    :flat="flat"
    :dense="dense"
    return-object
    :clearable="clearable"
    :outlined="outlined"
  >
  </v-autocomplete>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {},
  name: "FormSelectMarca",
  props: {
    marca: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Marca",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
  },
  methods: {
    clearData() {
      this.$emit("clearable");
    },

    set_select_data(data) {
      this.$emit("set_marca", data);
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
