<template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <cliente-buscador @set-cliente="setCliente"></cliente-buscador>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense v-if="cliente">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>Cliente</v-list-item-subtitle>
              <v-list-item-title
                >{{ cliente.nombre }} {{ cliente.apellido }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ cliente.telefono }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ cliente.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      
      </v-card>
    <!-- </v-menu> -->
  
</template>

<script>
import ClienteBuscador from "@/components/cliente/cliente.buscador.vue";


import { mapMutations } from "vuex";

export default {
  name: "venta.cliente.seleccionar",
  components: {
    ClienteBuscador,
  },
  props: {
      title: {
          type: String,
          default: 'Cliente desconocido'
      }
  },
  data() {
    return {
      cliente: null,

      menu: false,
      isValidCliente: true,
    };
  },
  computed: {
  },
  methods: {
    ...mapMutations("ventas", ["SET_CLIENTE_VENTA"]),
    setCliente(cliente) {
      this.menu = false;
      this.cliente = cliente;
      this.SET_CLIENTE_VENTA(cliente);
    },
    
    setIsValidCliente(val) {
      this.isValidCliente = val;
    },

   
  },
 
};
</script>

<style lang="scss" scoped></style>
