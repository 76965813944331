<template>
  <span>
    <layout-remito-registrar>
      <template v-slot:title><div>Nuevo remito</div> </template>
      <template v-slot:navigation-controller>
        <v-container>
          <pedido-compra-aprobados-list
            :selectPedido="selectPedido"
            :pedidos="pedidos_compra_aprobados"
            @set-remito-sin-pedido="setRemitoSinPedido"
            @reestablecer-detalles="reestablecerDetallesRemito"
            @set-pedido="seleccionarPedido"
            @reset-pedido="resetPedido"
          ></pedido-compra-aprobados-list>

          <div v-show="!selectPedido" class="text-center mt-1 subtitle-2">
            ¿No generaste el pedido?
            <v-hover v-slot:default="{ hover }">
              <v-icon
                v-text="hover ? 'mdi-emoticon-neutral' : 'mdi-emoticon-frown'"
              ></v-icon>
            </v-hover>
          </div>
          <v-divider class="mt-2"></v-divider>
          <div v-show="!selectPedido" class="mt-4">
            <proveedor
              :title="proveedor ? 'Cambiar proveedor' : 'Seleccionar proveedor'"
              @set-proveedor-register="setProveedor"
            ></proveedor>
          </div>

          <pedido-compra-agregar-detalle
            class="mt-4"
            :detalles="detalles_remito"
            :isCompra="true"
          ></pedido-compra-agregar-detalle>


           <v-divider class="mt-4"></v-divider>

          <div class="title mt-4 mb-4" style="line-height: 1.3333">
            Información del remito
          </div>
        
          <v-text-field
            outlined
            label="Nº de remito"
            dense
            type="number"
            v-model="nroRemito"
            v-validate="'numeric|max:13|min:1'"
            :error-messages="errors.collect('nroRemito')"
            data-vv-name="nroRemito"
            :counter="13"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="13"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Sucursal"
            v-validate="'numeric|max:13|min:1'"
            :error-messages="errors.collect('sucursal')"
            data-vv-name="sucursal"
            v-model="sucursal"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="5"
            :counter="5"
          ></v-text-field>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <compra-detalle-subtotal
          :detalles="detalles_remito"
        ></compra-detalle-subtotal>

        <v-row>
          <v-col cols="12">
            <v-btn
              block
              color="green"
              :disabled="esValidoRegistrar || errors.any()"
              @click="validateForm"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
          <v-alert type="info"
            >Podés editar la cantidad y actualizar el precio de costo.</v-alert
          >
          <v-container fill-height fluid>
            <v-row align="center">
              <v-col cols="12">
                <div class="mb-2">Detalle</div>

                <remito-compra-detalles-registrar-list
                  :edit="true"
                  :detalles="detalles_remito"
                ></remito-compra-detalles-registrar-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:cliente>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ documento }} - Nº {{ nroRemito }}
                </v-list-item-title>
                
              </v-list-item-content>
            </v-list-item>
            
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Sucursal </v-list-item-title>
                <v-list-item-subtitle>
                  {{ sucursal ? sucursal : "-" }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <info-proveedor
            v-if="tipoComprobante === 'compras'"
            :proveedor="proveedor"
          ></info-proveedor>
        </v-container>
      </template>
    </layout-remito-registrar>
    <!-- <v-dialog width="600" v-model="dialogPagar">
      <v-card>
        <v-container>
          <v-card-title> ¿Vas a pagar la factura? </v-card-title>
          <v-card-text>
            <v-alert type="success" v-if="montoActual > totalRemito">
              <v-row align="center">
                <v-col class="grow">
                  Saldo suficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="registrarRemito(true)"
                    :loading="loadingButton"
                    color="yellow"
                    class="black--text"
                    ><b>Pagar</b></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
            <v-alert v-else type="error">
              <v-row align="center">
                <v-col class="grow">
                  Saldo insuficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    :loading="loadingButton"
                    @click="registrarRemito(false)"
                    >Pagar después</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <v-card-actions
            v-if="montoActual > totalRemito"
            class="justify-space-around"
          >
            <v-btn
              :loading="loadingButton"
              color="info"
              text
              @click="registrarRemito(false)"
              >Pagar después</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog> -->
    <dialog-confirmation-close
      v-if="dialogCancelarRemito"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar el remito?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el remito? Se perderán todos los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar remito</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
//GLOBAL

import LayoutRemitoRegistrar from "@/layouts/layout.registrar.vue";
import CompraDetalleSubtotal from "@/components/compra/compra.detalle.registrar.subtotal.vue";
import RemitoCompraDetallesRegistrarList from "@/components/compra/remito/registrar/remito.compra.detalles.registrar.list.vue";

import BuscarProducto from "@/components/producto/buscador/producto.buscador.menu.vue";
import PedidoCompraAgregarDetalle from "@/views/compra/pedidos/pedido.compra.agregar.detalle";
import PedidoCompraAprobadosList from "@/components/compra/pedido/pedido.compra.aprobados.list.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import proveedor from "@/components/proveedor/ProveedorCardSelect";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";

import { mapActions } from "vuex";

export default {
  name: "remito.compra.registrar",
  components: {
    RemitoCompraDetallesRegistrarList,
    PedidoCompraAgregarDetalle,
    CompraDetalleSubtotal,
    PedidoCompraAprobadosList,
    proveedor,
    InfoProveedor,
    BuscarProducto,
    LayoutRemitoRegistrar,
    DialogConfirmationClose,
  },
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },

  data() {
    return {
      montoActual: null,
      totalRemito: 0,
      proveedor: null,
      documento: "RE",

      selectPedido: null,
      isSelectPedido: false,

      sucursal: null,
      nroRemito: null,
      detalles_remito: [],
      detalle_remito: {
        producto: {},
        cantidad: null,
      },
      pedidos_compra_aprobados: [],

      finalizar: false,
      to: null,

      //Bandera que se activa cuando hubo un cambio
      isBorrador: false,
      loadingValidBorrador: false,
      dialogCancelarRemito: false,
      loadingButton: false,
    };
  },

  computed: {
    isValidBorrador() {
      var detalles = this.detalles_remito;

      if (detalles.length > 0) return true;

      if (this.proveedor !== null) return true;

      return false;
    },
    esValidoRegistrar() {
      if (this.selectPedido) {
        let detalles = this.detalles_remito.length > 0 ? true : false;
        let proveedor = this.proveedor !== null ? true : false;

        return proveedor && detalles ? false : true;
      } else {
        let detalles = this.detalles_remito.length > 0 ? true : false;
        let proveedor = this.proveedor !== null ? true : false;

        return proveedor && detalles ? false : true;
      }
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Remito",
          disabled: false,
          to: { name: "remitoCompra" },
        },
        {
          text: "Nuevo remito",
          disabled: false,
          to: { name: "nuevoRemitoCompra" },
        },
      ];
    },
  },
  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

  
    ...mapActions("remitos", ["registrar_remito_compra"]),
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.registrarRemito();
        } else {
          this.$validator.validate();
        }
      });
    },

    registrarRemito(estaPago) {
      this.loadingButton = true;
      this.finalizar = true;

      var data = {
        proveedor: this.proveedor,
        detalles: this.detalles_remito,
        pedido: this.selectPedido,
        nroRemito: this.nroRemito,
        sucursal: this.sucursal,
        estaPago: estaPago,
      };
     


      this.registrar_remito_compra(data)
        .then((response) => {
          this.to = true; // Si es verdadero me deja salir de la interfaz

          this.$router.push({ name: "remitoCompra" });
          this.fetch_productos_mas_vendidos();
          this.loadingButton = false;
        })
        .catch((e) => {
          var message = e.response.data;
          if (e.response.data.detail) {
            message = e.response.data.detail;
          }
          this.loadingButton = false;
          this.finalizar = false;

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${message}`,
            icon: "mdi-information",
            timeout: 2000,
          });
          console.log(e);
        });
    },

    fetchPedidosAprobados() {
      var url = `/pedidos/compra?estado=APROBADO`;

      this.axios.get(url).then((response) => {
        this.pedidos_compra_aprobados = response.data.results;
      });
    },
    setTotalRemito(total) {
      this.totalRemito = total;
    },
    reestablecerDetallesRemito(pedido) {
      this.isBorrador = false;
      var url = `/pedidos/${this.selectPedido.id}`;
      this.axios.get(url).then((response) => {
        this.detalles_remito = response.data.detalles_pedido;
      });
    },
    fetchPedido(id_pedido) {
      var url = `/pedidos/compra/${id_pedido}`;
      this.axios.get(url).then((response) => {
        this.selectPedido = response.data;
        this.detalles_remito = response.data.detalles_pedido;
        this.proveedor = response.data.proveedor;
      });
    },
    setRemitoSinPedido() {
      this.detalles_remito = [];
      this.proveedor = null;
      this.selectPedido = null;
      // this.isSelectPedido = false;
    },
    resetPedido() {
      this.selectPedido = null;
      this.detalles_remito = [];
      this.proveedor = null;
      // this.isSelectPedido = true;
    },

    seleccionarPedido(pedido) {
      this.fetchPedido(pedido.id);
    },

    setProveedor(data) {
      this.proveedor = data;
    },
    closeDialog() {
      this.dialogCancelarRemito = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarRemito = false;
      this.$router.push(this.to);
    },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
  },
  created() {
    this.fetchPedidosAprobados();
    this.getMontoActual();
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return;
        } else {
          if (params.pedido) {
            console.log(params);
            // this.fetchPedido(params.pedido);
            this.selectPedido = params.pedido;

            this.detalles_remito = params.pedido.detalles_pedido;
            this.proveedor = params.pedido.proveedor;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta.
    if (this.to) {
      if (this.finalizar) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Remito registrado",
          icon: "mdi-check",
          timeout: 2000,
        });
      }
      next();
    } else {
      // seteo to con la siguiente ruta

      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada

      !this.isValidBorrador ? next() : (this.dialogCancelarRemito = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
