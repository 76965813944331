<template>
  <div>
    <v-list-item @click="dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Agregar producto
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>


    <!-- <v-autocomplete
      outlined
      hide-no-data
      placeholder="Agregar producto"
      color="blue"
      append-icon=""
      hide-details=""
      prepend-inner-icon="mdi-magnify"
      @click="dialog = true"
    >
    </v-autocomplete> -->
    <v-dialog persistent width="1000px" v-model="dialog">
      <v-card>
        <v-container>
          <v-card-title>
            <v-btn @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-row justify="center">
            <!-- <v-col cols="4">
              <pedido-compra-detalles-table
                :detalles="detalles"
              ></pedido-compra-detalles-table>
            </v-col> -->
            <v-col cols="8">
              <producto-buscador :categoriasFiltro="categorias" :isCompra="isCompra" @process-producto="agregarProductoDetalle">
              </producto-buscador>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PedidoCompraDetallesTable from "@/components/compra/pedido/pedido.compra.detalles.table.vue";
import ProductoBuscador from "@/components/producto/buscador/producto.buscador.menu2.vue";
import AgregarDetalle from "@/components/comprobante/detalle/comprobante.agregar.detalle.vue";
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";

export default {
  name: "pedido.compra.agregar.detalle",
  components: {
    ProductoBuscador,
    AgregarDetalle,

    PedidoCompraDetallesTable,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isCompra: {
      type: Boolean,
      default: false,
    },
    detalles: {
      type: Array,
      required: false,
    },
    categorias: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    agregarProductoDetalle(producto) {
      var detalle = {
        cantidad: 1,
        isProducto: true,
        precioUnitario: producto.precioCosto,
        producto: producto,
      };
      var det = { ...detalle };
      this.agregarDetalle(det);
    },
    agregarDetalle(detalle) {
      if (this.isUpdate) {
        add_detalle_update(this.detalles, detalle);
      } else {
        addDetalle(this.detalles, detalle);
      }

      this.$updateBus.$emit("showSnackBar", {
        show: true,
        button: false,
        right: false,
        top: false,
        color: "success",
        text: "Producto agregado",
        icon: "mdi-check",
        timeout: 1500,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>