<template>
  <LayoutBasic :showLinksMenu="false">
    <template v-slot:navigation-title>Ventas </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item
            v-for="(item, i) in links"
            :key="i"
            @click="$router.push({ name: item.routeName })"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list-item @click="sheet = !sheet">
        <v-list-item-icon>
          <v-icon>mdi-info</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><span class="grey--text">Facturado</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <venta-home></venta-home>
        <v-bottom-sheet v-model="sheet">
          <v-sheet class="text-center" height="120px">
            <div class="pt-4">REPORTE EN CAJON</div>
            <div class="py-3">
              {{ total_vendido_blanco.total | convertPesoArg }}
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-container>

      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import VentaHome from "@/components/venta/venta.home";
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ventas",
  components: {
    VentaHome,
    LayoutBasic,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      navigation: [
        {
          text: "Ventas",
          disabled: false,
          to: { name: "ventas" },
        },
      ],

      item: 0,
      links: [],
      sheet: false,
      menuName: "ventas",
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    ...mapState("comprobantes", ["total_vendido_blanco"]),
  },
  methods: {
    ...mapActions("comprobantes", [
      "get_total_ventas_blanco",
      "fetch_formas_de_pago",
    ]),
  },
  created() {
    this.fetch_formas_de_pago();
    this.get_total_ventas_blanco();
    this.links.push({ text: "Inicio", icon: "mdi-clock", routeName: "ventas" });
    this.links.push({
      text: "Nueva venta",
      icon: "mdi-cart-plus",
      routeName: "nuevaVenta",
    });
    if (this.configuracion.ventaConPedido) {
      this.links.push({
        text: "Pedidos",
        icon: "mdi-account",
        routeName: "pedidoVenta",
      });
    }
    // this.$store.commit("SET_LINKS", this.links);
  },
};
</script>

<style lang="scss" scoped></style>
