<template>
   <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="cantidadProductosParaActualizar < 1" dark text v-bind="attrs" v-on="on">
                Actualizar ganancia
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Actualizar ganancia
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 600px">
                <v-row>

                  <v-col cols="12">
                    <div class="title">Ingrese el nuevo porcentaje</div>
                    <div>
                      <v-text-field v-model="porcentajeGanancia" outlined type="number" required label="Porcentaje"
                        append-icon="mdi-percent" min="0"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <div class="title">
                            Se van a actualizar
                            {{ cantidadProductosParaActualizar}} productos
                          </div>
                        </v-list-item-title>
                        <v-list-item-action>
                          <span>
                            <v-dialog persistent v-model="dialogVerProductosSeleccionados" scrollable max-width="500px"
                              v-if="isSeleccionManual">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn dark text v-bind="attrs" v-on="on">


                                  <small> Ver productos</small>
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>Productos seleccionados
                                  <v-spacer></v-spacer>
                                  <v-btn icon @click="dialogVerProductosSeleccionados = false">
                                    <v-icon>mdi-close</v-icon></v-btn>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 600px">
                                  <v-list>
                                    <v-list-item v-for="p in productosParaActualizar" :key="p.id">
                                      <v-list-item-title>
                                        {{ p.descripcion }}
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>





                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="blue darken-1" @click="actualizarGanancia">
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
</template>

<script>
export default {
    name: 'CmpEditorMasivoGananciaUpdate',
   
    props: {
        productosParaActualizar: {
            type: Array,
            required: true
        },
        isSeleccionManual: {
            type: Boolean,
            required: true
        }, 
        cantidadProductosParaActualizar: {
          type: Number,
          required: true
        }
    },
    data(){
        return {
            dialog: false,
            dialogVerProductosSeleccionados: false,
            porcentajeGanancia: null
        }
    },
    methods: {
        removeCategoria() {
      this.categoriaSelected = null
    },
    setCategoria(data) {
      this.categoriaSelected = data

    },
    actualizarGanancia() {
      let productos_id = [];
      this.productosParaActualizar.forEach((element) => {
        productos_id.push(element.id);
      });
      if (
        !this.porcentajeGanancia ||
        this.porcentajeGanancia == "e" ||
        this.porcentajeGanancia <= 0 ||
        Number(this.porcentajeGanancia) > 200
      ) {
        alert("Ingrese un porcentaje válido");
      }
      var data = { 'productos': productos_id, 'field': 'ganancia', 'value': this.porcentajeGanancia }


      var url = `/productos/multiple_products_update`;
      this.axios.put(url, data).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Productos Actualizados",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialog = false;
        this.$emit('process-update')
      });
    },
    }
}
</script>

<style lang="scss" scoped></style>