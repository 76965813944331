var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue"},on:{"click":function($event){_vm.dialogFacturar = true}}},'v-btn',attrs,false),on),[_vm._v(" Facturar "),_c('v-icon',[_vm._v("mdi-cloud-upload-outline")])],1)]}}]),model:{value:(_vm.dialogFacturar),callback:function ($$v) {_vm.dialogFacturar=$$v},expression:"dialogFacturar"}},[(!_vm.preImpreso)?_c('v-card',{attrs:{"color":"#242526"}},[_c('v-card-title',[_c('div',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v("Elige el tipo de facturación")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialogFacturar = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center justify-center mb-6",staticStyle:{"height":"100%"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"loading":_vm.loadingFacturar,"height":"150px","width":"150px","color":hover ? '#313233' : ''},on:{"click":_vm.registrarFacturaElectronica}},[_c('v-container',{staticClass:"text-center"},[_c('div',[_c('v-icon',{attrs:{"color":"blue","large":""}},[_vm._v("mdi-tag")])],1),_c('h3',[_vm._v("Factura electrónica")]),_c('span',{staticStyle:{"color":"#bdbdbd"}})])],1)]}}],null,false,2580917888)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"height":"150px","width":"150px","color":hover ? '#313233' : ''},on:{"click":function($event){_vm.preImpreso = true}}},[_c('v-container',{staticClass:"text-center"},[_c('div',[_c('v-icon',{attrs:{"color":"blue","large":""}},[_vm._v("mdi-tag-multiple")])],1),_c('h3',[_vm._v("Pre-impreso")]),_c('span',{staticStyle:{"color":"#bdbdbd"}},[_vm._v(" Asocia una factura en papel con la venta. ")])])],1)]}}],null,false,1505584853)})],1)],1)],1)],1)],1)])],1):_vm._e(),(_vm.preImpreso)?_c('factura-pre-impresa',{attrs:{"comprobante":_vm.comprobante},on:{"go-back":function($event){_vm.preImpreso = false},"close-dialog":function($event){_vm.dialogFacturar = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogConsultarPreImpreso),callback:function ($$v) {_vm.dialogConsultarPreImpreso=$$v},expression:"dialogConsultarPreImpreso"}},[(_vm.dialogConsultarPreImpreso)?_c('factura-pre-impresa-consultar',{attrs:{"comprobante":_vm.comprobante},on:{"close-dialog":function($event){_vm.dialogConsultarPreImpreso = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }