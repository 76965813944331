<template>
  <span>
    <v-navigation-drawer
      width="360"
      color="#1E1E1E"
      class="items3"
      fixed
      floating
      clipped
      app
      id="nav"
      :right="positionRight"
      :key="reload"
    >
      <template v-slot:prepend>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                style="background-color: #4e4f50"
                class="ml-1 mt-1"
                icon
                large
                @click="$router.go(-1)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <a @click="$router.push('/home')" class="siteLogo mt-3"></a>
            </v-col>
          </v-row>

          <slot name="breadcrumbs"></slot>
          <div class="mt-5 pa-0">
            <div class="estilo-subheader"><slot name="up-title">Compras</slot></div>
          </div>
          <slot name="title">TITLE</slot>
        </v-container>
      </template>

      <slot name="navigation-controller" :reload="reloadNav">
        <div id="aca"></div>
      </slot>
      <template v-slot:append>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <slot name="navigation-stepper"></slot>
                <v-row class="mt-1">
                  <v-col>
                    <slot name="navigation-append" :reload="reloadNav"></slot>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-navigation-drawer>
    <slot name="vista-previa"></slot>
    <v-navigation-drawer
     v-if="navigationSecond"
      width="360"
      color="#1E1E1E"
      class="items3"
      fixed
      floating
      clipped
      app
      id="nav-2"
      :right="!positionRight"
      :key="reloadSecond"
    >
      <template v-slot:prepend>
        <v-container>
         

          <slot name="breadcrumbs"></slot>
          <div class="mt-5 pa-0">
            <div class="estilo-subheader"><slot name="up-title-second">Compras</slot></div>
          </div>
          <slot name="title-second"></slot>
        </v-container>
      </template>

      <slot name="navigation-controller-second" :reloadSecond="reloadNav">
        <div id="aca"></div>
      </slot>
      <template v-slot:append>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <slot name="navigation-stepper-second"></slot>
                <v-row class="mt-1">
                  <v-col>
                    <slot name="navigation-append-second" :reloadSecond="reloadNav"></slot>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-navigation-drawer>
    
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "layout.registrar.producto",
  components: {},
  props: {
    expandOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    positionRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    navigationSecond: {
      type: Boolean,
      required: false,
      default: false,
    },
    
    
  },
  data() {
    return {
      item: 0,
      reload: 0,
      reloadSecond: 1,

      openDrawer: true,
      duration: 300,
      offset: 0,
    };
  },

 
  methods: {
    reloadNav() {
      this.reload = !this.reload;
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },

  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: false });
  },
};
</script>

<style scoped>
.estilo-subheader {
  word-break: break-word;
  color: #b0b3b8;
  font-size: 0.9125rem;
}
.siteLogo {
  position: absolute;
  top: 0;
  left: 40px;
  margin-left: 30px;
  background-image: url("../../assets/bear-face-icon-2.png"), none;
  background-size: 50px;
  /* filter: brightness(1.1);
  mix-blend-mode: multiply; */
  width: 50px;
  height: 50px;
}
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  /* background: #869; */
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
