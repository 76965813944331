<template>
  <div>
    <h1>Compras</h1>
    <v-row>
      <v-col cols="12" md="4">
        <bar-chart v-if="loaded" :chartdata="chartData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineChart from "@/chartjs/line.chart.vue";
import BarChart from "@/chartjs/bar.chart.vue";

import { descargarPedidoPDF } from "@/services/reportes";

export default {
  name: "reporte.compras",
  components: {
    LineChart,
    BarChart,
  },
  data() {
    return {
      chartData: null,
      loaded: false,
    };
  },
  methods: {
    fetchReporte() {
      this.loaded = false;
      var url = "/reportes/reportecompraventa/compra/m";
      this.axios.get(url).then((response) => {
        this.chartData = response.data;
        this.loaded = true;
      });
    },
  },
  mounted() {
    this.fetchReporte();
  },
};
</script>

<style lang="scss" scoped></style>
