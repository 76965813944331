<template>
  <layout-two-column>
    <template v-slot:title>
      <v-list class="ma-0 ml-2 pa-0">
        <v-list-item class="pl-1">
          <v-list-item-avatar>
            <v-icon large>mdi-account-plus</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            <h1 style="font-size: 30px">Nuevo empleado</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <!-- <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group> -->
        <v-list-item @click="$router.push({ name: 'empleados' })">
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Volver</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <v-row align="center" justify="center">
          <v-col xs="12" sm="8" md="6">
            <v-card>
              <v-card-title>
                <div class="headline"><b>Registrar empleado</b></div>
              </v-card-title>
              <v-card-text>
                <empleado-register
                  @process-register="registrarUsuario"
                  :empleado="empleado"
                ></empleado-register>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout-two-column>
</template>

<script>
import EmpleadoRegister from "@/components/empleado/empleado.register.vue";
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
import EmpleadoAPI from "@/api/empleados";

import { mapActions } from "vuex";

export default {
  name: "empleado.registrar",
  components: {
    EmpleadoRegister,
    LayoutTwoColumn,
  },
  data() {
    return {
      empleado: {
        nombre: null,
        apellido: null,
        documento: null,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["registrar_usuario"]),
    registrarUsuario(empleado) {
      EmpleadoAPI.create(empleado)
        .then((response) => {
          try {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: "Empleado registrado",
              icon: "mdi-check",
              timeout: 3000,
            });
            this.$router.push("/empleados");
          } catch (error) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "info",
              text: "Problema inesperado",
              icon: "mdi-information",
              timeout: 3000,
            });
          }
        })
        .catch((e) => {
          const response = e.response ? e.response.data : "Problema inesperado"
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: response,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
