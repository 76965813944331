<template>
  <v-row>
    <v-col cols="3">
      <div class="ml-3">
        <v-navigation-drawer
          width="330"
          color="#1E1E1E"
          class="items2"
          permanent
          absolute
        >
          <v-list class="ma-0 mt-2 pa-0">
            <v-list-item class="pl-1">
              <v-list-item-avatar class="ma-1">
                <v-btn @click="$router.go(-1)" icon>
                  <v-icon large>mdi-close</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-title>
                <h1 class="text-wrap" style="font-size: 2em">
                  {{ categoriaPadre.name }}
                </h1>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-alert class="mt-4" type="info">
            safsaf
            <h4>Editar / eliminar</h4>
            Utilice el click derecho sobre la categoría
          </v-alert>
        </v-navigation-drawer>
      </div>
    </v-col>
    <v-col>
      <v-container>
        <h2>Subcategorías</h2>
        <v-row
          class="scroll-bar align-stretch d-flex flex-row flex-nowrap mt-2"
        >
          <v-col
            cols="3"
            v-for="comp in categoriasSelected"
            :key="comp.categoria.id"
          >
            <component
              @processCategoria="fetchCategoria"
              :parent="comp.categoria"
              :is="comp.type"
            ></component>
          </v-col>
          <span id="scrollFinal"></span>
          <v-col
            v-if="loadingCategoriaSelected"
            class="d-flex align-center justify-center"
            cols="3"
          >
            <v-progress-circular
              width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import EgresoCategoriasTreeBranch from "@/components/egresos/categorias/EgresoCategoriasTreeBranch.vue";
import EgresoAPI from "@/api/egresos/index.js";

export default {
  name: "EgresoCategoriasTree",
  components: {
    EgresoCategoriasTreeBranch,
  },
  data() {
    return {
      categoriaPadre: [],
      categoriasSelected: [],
      loadingCategoriaSelected: false,
      count: 0,
    };
  },

  methods: {
    fetchCategoria(categoria) {
      console.log("categoria: ",categoria);
      this.loadingCategoriaSelected = true;
      var componentes = this.categoriasSelected;

      // Elimina los componentes que estan despues del que se va a crear
      for (let index = 0; index < componentes.length; index++) {
        const element = componentes[index];
        if (categoria.mptt_level < element.categoria.level_padre + 1) {
          this.categoriasSelected.splice(index);
        }
      }
      this.fetchCategoriasEgreso(categoria.name);
    },
    fetchCategoriasEgreso(nombreCategoria) {
      EgresoAPI.index(nombreCategoria).then((response) => {
        this.categoriaPadre = response.data.parent;

        this.categoriasSelected.push({
          type: EgresoCategoriasTreeBranch,
          categoria: {
            level_padre: response.data.parent.mptt_level,
            categorias: response.data.parent.children,
            nombre_padre: response.data.parent.name,
            id: response.data.parent.id,
          },
          id: this.count++,
        });

        this.loadingCategoriaSelected = false;
      });
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
    this.fetchCategoriasEgreso(this.$route.params.padre);
  },
};
</script>
<style scoped>
.scroll-bar {
  /* align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; */
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
