<template>
  <div>
    <v-container fluid fill-height class="pa-0">
      <v-row class="fill-height">
        <v-col cols="12">
          <v-row justify="start">
            <v-col cols="6">
              <div class="headline">Productos</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <reportes-guardados></reportes-guardados>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="6">
             
            <!-- <ReporteProductosGenerarListaPrecios></ReporteProductosGenerarListaPrecios> -->
            </v-col>
          </v-row>
         

         
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>


import ReportesGuardados from "@/views/reportes/ReportesGuardados";
import ReporteProductosGenerarListaPrecios from "@/views/reportes/productos/ReporteProductosGenerarListaPrecios"

export default {
  name: "reporte.productos",
  components: {
    ReportesGuardados,
    ReporteProductosGenerarListaPrecios,
  },
  methods: {
     goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },


};
</script>

<style lang="scss" scoped></style>
