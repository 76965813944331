<template>
  <div>
    <dialog-form-generic
      v-if="dialogFormGenerics"
      @close-dialog="closeDialog"
      @process-save="closeDialog"
    >
      <template v-slot:titulo>Historial - Listas de precios</template>
      <template v-slot:message>
        <v-row>
          <v-col cols="12">
            <data-table-pagination
              :items="listaDePrecioHistorial"
              :headers="headers"
              :pagination="pagination"
              @set-page="setPage"
            >
            </data-table-pagination>
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttonCancel>Cancelar</template>
      <template v-slot:buttonOK @click="closeDialog()">Aceptar</template>
    </dialog-form-generic>
  </div>
</template>

<script>
import DataTablePagination from "@/util/DataTablePagination";
import DialogFormGeneric from "@/components/dialogs/DialogFormGeneric";
import { subirListaDePrecios } from "@/services/proveedor/listaDePrecios.js";
import ListaDePrecioAPI from "@/api/proveedores/listaDePrecio";

export default {
  name: "ProveedorListaDePrecioUpdate",
  $_veeValidate: {
    validator: "new",
  },
  components: { DialogFormGeneric, DataTablePagination },
  data() {
    return {
      listaDePrecioHistorial: [],
      dialogFormGenerics: true,
      loading: false,
      cantidadItems: 0,
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Fecha", value: "fecha" },
        { text: "Usuario", value: "usuario" },
      ],
      pagination: { page: 1, cantidad: 0, rowsPerPage: 10 },
    };
  },

  methods: {
    closeDialog() {
      this.dialogFormGenerics = false;
      this.$emit("close-dialog");
    },
    setPage(pageNumber) {
      this.pagination.page = pageNumber;
      this.getListaDePrecio();
    },
    updateListaPrecio() {},
    getListaDePrecio() {
      var proveedor_id = this.$route.params.id_proveedor;
      ListaDePrecioAPI.fetchListaDePrecioHistorial({
        proveedor_id: proveedor_id,
        pagination: this.pagination,
      }).then((response) => {
        this.listaDePrecioHistorial = response.data.results;
        this.pagination.cantidad = response.data.count;
      });
    },
  },

  created() {
    this.getListaDePrecio();
  },
};
</script>

<style lang="scss" scoped>
</style>