var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBasic',{attrs:{"showLinksMenu":false,"routeBack":'/compras/pedidos'},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('v-breadcrumbs',{staticClass:"pa-3 ml-1",attrs:{"items":_vm.navigation,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('v-breadcrumbs-item',{attrs:{"exact":"","to":item.to}},[_vm._v(_vm._s(item.text))])}}])})]},proxy:true},{key:"navigation-title",fn:function(){return [_vm._v("Consultar pedidos ")]},proxy:true},{key:"navigation-content",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" Ingresa los últimos números sin los ceros de la izquierda ")]),_c('pedido-buscador',{staticClass:"mt-2",attrs:{"tipoComprobante":"compras"},on:{"resultado-buscar-producto":_vm.setPedidosfromBuscador,"clean-buscador":_vm.fetchPedidos}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-select',{attrs:{"items":_vm.estados,"required":"","label":"Estado","clearable":"","outlined":""},on:{"change":_vm.fetchPedidos,"click:clear":_vm.fetchPedidos},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","title":"Fecha","label":"Desde","prepend-icon":"mdi-calendar","readonly":"","color":"green lighten-1"},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"green lighten-1","max":_vm.moment().format('YYYY-MM-DD'),"locale":"es"},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","prepend-icon":"mdi-calendar","title":"Fecha","label":"Hasta","readonly":"","color":"green lighten-1"},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"green lighten-1","max":_vm.moment().format('YYYY-MM-DD'),"min":_vm.fechaInicio,"locale":"es"},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"large":"","color":"info","block":""},on:{"click":_vm.fetchPedidos}},[_vm._v("Consultar")])],1)],1)],1)]},proxy:true},{key:"vista-previa",fn:function(){return [(_vm.loading)?_c('v-container',_vm._l((3),function(i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"max-height":"350","type":"list-item-avatar-three-line"}})],1)],1)}),1):_c('v-container',[_c('pedido-consultar-estado',{ref:"consultarPedidos",attrs:{"paginacion":_vm.paginacion,"estado":_vm.estado,"tipoComprobante":"compras","pedidos":_vm.pedidos},on:{"set-page":_vm.setPage_fetchPedido}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }