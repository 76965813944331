<template>
  <v-navigation-drawer
    width="400px"
    v-if="remito"
    app
    v-model="modelComputed"
    temporary
    right
  >
    <template v-slot:prepend>
   

      <v-list-item class="ma-0">
        <v-list-item-content>
          <v-list-item-title>
            <div class="display-1 text-wrap">{{ remito.codigo ? remito.codigo : remito.id }}    </div>
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
          <v-chip
          v-if="remito.estaPagado"
          label
          class="ma-2 green darken-2 pa-2 text-capitalize"
          
        >
          <v-avatar left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          Pagado
        </v-chip>
        <v-chip v-else label class="ma-2 orange darken-4 pa-2 text-capitalize">
          <v-avatar left>
            <v-icon>mdi-cash</v-icon>
          </v-avatar>
          <b>Pendiente de pago</b>
        </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer></v-spacer>
       <v-list-item dense>
          <v-list-item-avatar class="mb-0 mt-0"
            ><v-icon left>mdi-calendar</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-subtitle class="caption">
              Fecha
            </v-list-item-subtitle>
            <v-list-item-title>      {{moment(remito.fechaUltModificacion).format('lll')}}
 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <v-list class="pa-0" dense>
        <v-list-item dense>
          <v-list-item-avatar class="mb-0 mt-0"
            ><v-icon left>mdi-account</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-subtitle class="caption">
              Proveedor
            </v-list-item-subtitle>
            <v-list-item-title>{{remito.proveedor.nombre}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-avatar class="mb-0 mt-0"
            ><v-icon left>mdi-phone</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-title>{{remito.proveedor.telefono}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- <pedido-detalle-actualizar
          :disabledDetalle="true"
          v-if="detalles_pedido.length > 0"
          :detalles="detalles_pedido"
        ></pedido-detalle-actualizar> -->
    </template>
    <pedido-detalle-list :detalles="remito.detalles_comprobante"></pedido-detalle-list>

    <template v-slot:append>
      <v-divider></v-divider>
      <pedido-detalle-subtotal
        :detalles="remito.detalles_comprobante"
      ></pedido-detalle-subtotal>
    </template>
  </v-navigation-drawer>
</template>

<script>
import PedidoDetalleList from "@/components/pedido/actualizar/pedido.detalle.list.vue";
import PedidoDetalleSubtotal from "@/components/pedido/actualizar/pedido.detalle.subtotal.vue";

export default {
  name: "remito.consultar.item.drawer",
  components: {
    PedidoDetalleList,
    PedidoDetalleSubtotal,
  },
  props: {
    remito: {
      type: Object,
      required: true,
    },
     drawer: {
      required: true,
    },
  },
  data() {
    return {
      loadingButton: false,
    };
  },
  computed: {
    modelComputed: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("set-drawer", val);
      },
    },
  },

  methods: {},
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
