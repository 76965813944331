<template>
    <layout-two-column>
      <template v-slot:breadcrumbs>
      </template>
      <template v-slot:title>
        <v-list class="pt-0 mt-0">
          <v-list-item>
            <v-list-item-title>
              <h1 class="text-wrap" style="font-size: 30px">
                Consultar Precios
              </h1>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <producto-buscador
                @process-buscador-producto="goToBuscador"
                @process-buscador-producto-select="goToBuscador"
              ></producto-buscador>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </template>
  
      <template v-slot:navigation-controller>
      </template>
      <template v-slot:vista-previa>
        <v-container>
          <!-- <producto-listado
            :params="get_params_query()"
            @process-query-params="replaceParams"
          ></producto-listado> -->
        </v-container>
      </template>
    </layout-two-column>
  </template>
  
  <script>
  // import LayoutBasic from "@/layouts/productos/LayoutProductos";
  import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
 import ProductoListado from "@/components/producto/listar/productos.list.card.vue"; 
  
  import ProductoBuscador from "@/components/producto/buscador/producto.buscador.vue";
  
  import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
  import ProductosFiltros from "@/components/producto/filtros/productos.filtros.vue";
  
  export default {
    name: "ProductoConsultarPrecio",
    components: {
      ProductosFiltros,
      ProductoBuscador,
      LayoutTwoColumn,
      ProductoListado,
    },
    data() {
      return {
        itemMenu: 0,
        navigation: [
          {
            text: "Productos",
            disabled: false,
            to: "/productos",
          },
        ],
        links: [
          {
            icon: "mdi-format-list-bulleted-type",
            title: "Lista de productos",
          },
          {
            icon: "mdi-folder-plus",
            title: "Nuevo Producto",
            route: "nuevoProducto",
          },
  
          {
            icon: "mdi-clipboard-list",
            title: "Inventario",
            route: "productoInventarioGestion",
          },
          {
            icon: "mdi-pencil",
            title: "Editor masivo",
            route: "productosEditorMasivo",
          },
        ],
        modelMenu: 0,
        item: 1,
      };
    },
    computed: {
      ...mapGetters("proveedor", ["proveedores"]),
      ...mapGetters("marcas", ["marcas"]),
      queryParams() {
        return this.$route.query || "";
      },
      titulo() {
        return this.$route.query.categoria
          ? this.$route.query.categoria.split(/[-]+/).join(" ")
          : "Productos";
      },
    },
  
    methods: {
      ...mapActions("categorias", ["fetchCategoriasProductosFiltros"]),
      removeNavigation(name) {
        var index = this.navigation.findIndex(
          (e) => e.text.toLowerCase() == name.toLowerCase()
        );
        if (this.navigation[index + 1]) {
          this.navigation.splice(index + 1);
        }
        if (this.navigation.length == 1) {
          this.fetchCategoriasProductosFiltros("CATEGORIA");
        } else {
          this.fetchCategoriasProductosFiltros(name);
        }
      },
      capitalize(str) {
        const lower = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lower.slice(1);
      },
      replaceParams(categoria) {
        var string = categoria.name.split(/[ ]+/).join("-");
        return string;
        // this.$router.push({ path: `/productos/categoria/${string}` });
      },
      goToBuscador(search) {
        if (search == "") {
          this.$router.replace({ path: "productos" }).catch((e) => {});
          return;
        }
        if (search == this.$route.query.q) {
          return;
        }
        this.set_page_productos_admin(1);
        var isBarcode = false
      let isnum = /^\d+$/.test(search);

      if (isnum && search.length > 6){
        isBarcode = true
      }
      if (isBarcode) {
        this.add_query_url({ barcode: search });
        
      }else{
        this.add_query_url({ q: search });
      }
        
      },
  
      ...mapMutations("productos", ["set_page_productos_admin"]),
  
      ...mapActions("productos", ["get_productos_for_params"]),
      //Agrega el filtro, lleva el scroll para arriba, y hace la peticion al server
  
      goToRoute(route) {
        if (!route) return;
        this.$router.push({ name: route }).catch(() => {});
      },
      setCategoria(data) {
        console.log("DATA; ", data);
        let nameCapitalize = this.capitalize(data.name);
  
        let navigations = {
          text: nameCapitalize,
          disabled: false,
          to: this.replaceParams(data),
        };
        this.navigation.push(navigations);
      },
      setProveedor(data) {
        this.add_query_url({ proveedor: data.nombre });
      },
      setPedidosYa() {
        this.add_query_url({ py: true });
      },
      setMarca(data) {
        this.add_query_url({ marca: data.nombre });
      },
      get_text_transform(str) {
        if (typeof str === "string") {
          return str.match(/[^ ]+/g).join("-");
        } else {
          return str;
        }
      },
      add_query_url(value) {
        var querys = this.$route.query;
  
        for (const property in value) {
          var name = value[property];
          value[property] = this.get_text_transform(name);
          querys = Object.assign({}, querys, value);
        }
        this.$router.replace({ query: querys }).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      },
      get_params_query() {
        var array = [];
        var params = this.$route.params;
        if (params && params.categoria) {
          var object = {
            value: null,
            key: "",
          };
          object.value = params.categoria;
          object.key = "categoria";
          array.push(object);
        }
  
        var querys = this.$route.query;
        if (Object.keys(querys).length !== 0) {
          for (const property in querys) {
            var object = {
              nombre: null,
              key: "",
            };
            object.key = property;
            object.value = this.get_text_transform(querys[property]);
            array.push(object);
          }
        }
        return array;
      }
    },
  
    created() {
      this.$store.commit("SET_LINKS", this.links);
    },
  
    watch: {
      $route: {
        handler(value) {
          this.set_page_productos_admin(1);
  
          var params = this.get_params_query();
          this.get_productos_for_params(params);
        },
        deep: true,
        immediate: true,
      },
    },
  };
  </script>
  
  <style>
  .v-text-field--box .v-input__slot,
  .v-text-field--outline .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
  </style>
  