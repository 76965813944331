<template>
  <v-btn @click="imprimir"><v-icon>mdi-printer</v-icon></v-btn>
</template>

<script>
import { imprimirPedido, imprimirComprobante } from "@/services/comprobante.js";

export default {
  name: "buttonImprimir",
  props: {
    id: {
      type: Number,
      required: true,
    },
    tipoComprobante: {
      type: String,
      required: true,
    },
  },
  methods: {
    imprimir() {
      if (this.tipoComprobante == "PE") {

        imprimirPedido(this.id)
      }
      if (this.tipoComprobante == "NV") {
        imprimirComprobante(this.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>