<template>
  <v-card>
    <v-container>
      <v-card-title>
        <div class="display-1">Nuevo retiro</div>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close-dialog')">mdi-close</v-icon>
      </v-card-title>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field
            class="mt-2"
            outlined
            v-model="efectivoRetirar"
            type="number"
            onFocus="this.select()"
            oninput="javascript: if (this.value > this.maxLength) this.value = this.maxLength;"
            :maxlength="egresos.monto_actual"
            label="Dinero a retirar"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-card-text>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon :color="false ? 'green' : 'red'">{{
              false ? "mdi-arrow-up-thick" : "mdi-arrow-down-thick"
            }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >Total a retirar
              <span class="title">
                {{ efectivoRetirar | convertPesoArg }}</span
              ></v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-card-text>
      <v-card-actions class="text-end justify-end">
        <div>
          <v-btn color="success" @click="registrarRetiro">Registrar</v-btn>
        </div>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import EgresosApi from "@/api/egresos/index";
import { mapActions } from "vuex";
export default {
  name: "buzon.registrar",
  data() {
    return {
      model: 0,
      date: this.moment().format("YYYY-MM-DD"),
      tipoEgreso: "EGRESO BUZON",

      menu2: false,
      items: [],
      cantidadSobres: 1,
      efectivoRetirar: 0,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.moment(this.date).format("ll") : "";
    },
    egresos() {
      return this.$store.state.egresos.egresos_buzon;
    },
  },
  methods: {
    ...mapActions("egresos", ["registrar_egreso_buzon"]),
    registrarRetiro() {
      if (this.efectivoRetirar <= 0) {
        return;
      }
      var formData = {
        descripcion: this.tipoEgreso,
        monto: this.efectivoRetirar,
        esIngreso: false,
        tipoIngresoEgreso: this.tipoEgreso,
        detalles: [],
      };

      this.registrar_egreso_buzon(formData);
      this.$emit("close-dialog");
      this.$router.push({ name: "buzonHome" }).catch((e) => {});
      this.efectivoRetirar = 0;
    },
    addCant() {
      this.cantidadSobres++;
    },
    removeCant() {
      if (this.cantidadSobres == 1) {
        return;
      }
      this.cantidadSobres--;
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped></style>
