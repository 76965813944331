<template>
  <v-list>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Cantidad artículos</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
    </v-list-item>
    <v-list-item dense v-if="showSubTotal">
      <v-list-item-content>
        <v-list-item-title>Subtotal</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>
          {{ useBackendData ? backendSubtotal : subtotal() | convertPesoArg }}
        </h3>
      </v-list-item-icon>
    </v-list-item>
    <div>
         <v-list-item dense v-if="backendTotalVat">
      <v-list-item-content>
        <v-list-item-title>Total IVA</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>
          {{
           backendTotalVat | convertPesoArg
          }}
        </h3>
      </v-list-item-icon>
    </v-list-item>
      
    </div>
    <!-- Mostrar los subtotales de IVA por tipo -->
    <span v-for="(item, index) in totalesIva" :key="index">
      <v-list-item dense v-if="item.value > 0">
        <v-list-item-content>
          <v-list-item-title>IVA {{ item.key }}%</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <h3>{{ item.value | convertPesoArg }}</h3>
        </v-list-item-icon>
      </v-list-item>
    </span>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Perc. IVA</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>
          {{
            useBackendData
              ? backendPercepcionIva
              : percepcionIva | convertPesoArg
          }}
        </h3>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Perc. I.B.</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>
          {{
            useBackendData ? backendPercepcionIB : percepcionIB | convertPesoArg
          }}
        </h3>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>No gravado</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>
          {{ useBackendData ? backendNoGravado : noGravado | convertPesoArg }}
        </h3>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item dense v-if="showSubTotal">
      <v-list-item-content>
        <v-list-item-title>TOTAL</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>{{ useBackendData ? backendTotal : total() | convertPesoArg }}</h3>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "compra.detalle.registrar.subtotal",
  props: {
    totalesIva: {
      type: Array,
      required: false,
    },
    detalles: {
      type: Array,
      required: true,
    },
    withIva: {
      type: Boolean,
      default: true,
    },
    percepcionIva: {
      type: Number,
      required: false,
      default: 0,
    },
    percepcionIB: {
      type: Number,
      required: false,
      default: 0,
    },
    noGravado: {
      type: Number,
      required: false,
      default: 0,
    },

    showSubTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
    useBackendData: {
      type: Boolean,
      required: false,
      default: false,
    },
    backendSubtotal: {
      type: Number,
      required: false,
      default: 0,
    },
    backendPercepcionIva: {
      type: Number,
      required: false,
      default: 0,
    },
    backendPercepcionIB: {
      type: Number,
      required: false,
      default: 0,
    },
    backendNoGravado: {
      type: Number,
      required: false,
      default: 0,
    },
    backendTotalVat: {
      type: Number,
      required: false,
      default: 0,
    },
    backendTotal: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      // totalesIva: {
      //   "21": 0,
      //   "10.5": 0,
      //   "27": 0,
      //   "2.5": 0,
      //   "5": 0,
      //   "0": 0,
      //   'Exento': 0,
      // },
    };
  },
  methods: {
    total() {
      if (this.useBackendData) {
        return this.backendTotal;
      }
      // Calculamos la suma de los valores de totalesIva
      const sumaTotalesIva = this.totalesIva.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.value);
      }, 0);

      // Calculamos el total sumando el subtotal, percepciones y los totales de IVA
      return (
        this.subtotal() +
        parseFloat(this.percepcionIB) +
        parseFloat(this.percepcionIva) +
        parseFloat(this.noGravado) +
        sumaTotalesIva
      );
    },
    subtotal() {
      if (this.useBackendData) {
        return this.backendSubtotal;
      }
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          var precioBase =
            this.detalles[index].cantidad * this.detalles[index].precioUnitario;
          var iva = 0;
          if (this.withIva) {
            iva =
              (precioBase *
                parseFloat(this.detalles[index].producto.iva.description)) /
              100;
          }
          var precioConIva = precioBase + iva;
          subtotal += precioConIva;
        } else {
          var precio =
            this.detalles[index].cantidad * this.detalles[index].precioUnitario;
          subtotal += precio;
        }
      }
      return parseFloat(subtotal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
