<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/egresos/gastos'">
    <template v-slot:navigation-title>Nuevo Gasto </template>

    <template v-slot:navigation-content>
      <v-container>
        <egreso-form :egreso="egreso"></egreso-form>
        <v-dialog width="600" v-model="dialogDetalle">
          <ValidationObserver ref="formDetalle">
            <div>
              <v-card>
                <v-container>
                  <v-card-title>
                    Nuevo detalle
                    <v-spacer></v-spacer>
                    <v-icon large @click="dialogDetalle = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>

                  <VTextAreaWithValidation
                    outlined
                    label="Descripción"
                    autofocus
                    v-model="detalle.descripcion"
                    placeholder="descripcion"
                    rules="required|max:100"
                    :counter="30"
                    name="descripción"
                    rows="3"
                    row-height="30"
                  />
                  <VTextFieldWithValidation
                    outlined
                    label="Monto"
                    v-model="detalle.monto"
                    rules="required|decimal|max:30"
                    :counter="30"
                    name="monto"
                  />
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialogDetalle = false">Cancelar</v-btn>

                    <v-btn color="success" @click="añadirDetalle()"
                      >Agregar</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </div>
          </ValidationObserver>
        </v-dialog>
      </v-container>
    </template>

    <template v-slot:navigation-append>
      <v-divider></v-divider>
      <v-btn
        large
        block
        color="green"
        :disabled="!validRegister"
        @click="dialogRegistrarGasto = true"
        >Registrar</v-btn
      >
      <dialog-confirmation-close
        :buttonCancel="false"
        v-if="dialogRegistrarGasto"
        @close-dialog="dialogRegistrarGasto = false"
        @process-button-extra="registrarEgreso(false)"
        @process-accept="registrarEgreso(true)"
      >
        <template v-slot:titulo>¿Vas a pagar con dinero de la caja?</template>
        <template v-slot:message
          ><p>
            Si utilizas dinero de la caja, seleccioná <b>Pagar con caja</b>
          </p>
          <p>En caso contrario, selecciona <b>Pagar de otra manera</b></p>
        </template>
        <template v-slot:buttonCancel v-if="false">>Cancelar</template>
        <template v-slot:buttonExtra>Pagar de otra manera</template>
        <template v-slot:buttonOK>Pagar con caja</template>
      </dialog-confirmation-close>
    </template>

    <template v-slot:vista-previa>
      <v-row justify="center" class="fill-height" align="start">
        <v-col cols="6">
          <v-card>
            <v-container>
              <v-card-title>
                {{ egreso.fechaPeriodo }}
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        moment(egreso.fechaPeriodo)
                          .format("MMMM-YYYY")
                          .toUpperCase()
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-title>
              <v-card-text>
                <div v-if="egreso.categoria">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-chip :color="egreso.categoria.color"></v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ egreso.categoria.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-if="egreso.tipoIngresoEgreso">
                  <v-list-item dense>
                    <v-list-item-avatar>
                      <v-chip></v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ egreso.tipoIngresoEgreso }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-if="egreso.descripcion">
                  <v-list-item dense>
                    <v-list-item-avatar>
                      <v-icon>mdi-comment</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ egreso.descripcion }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-card-action v-if="egreso.monto">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="text-h4">
                          {{ egreso.monto | convertPesoArg }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-action>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="false" justify="center" class="fill-height" align="start">
        <v-col>
          <v-subheader>Detalles del egreso</v-subheader>
          <v-list>
            <v-list-item v-for="detalle in egreso.detalles" :key="detalle.id">
              <v-list-item-content>
                <v-list-item-title>{{ detalle.descripcion }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-center"
                ><div style="font-size: 20px">
                  {{ Number(detalle.monto) | convertPesoArg }}
                </div></v-list-item-icon
              >
              <v-list-item-icon class="align-center">
                <v-btn icon @click="modificarDetalle(detalle)">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
                <v-btn icon @click="modificarDetalle(detalle)">
                  <v-icon>mdi-delete-circle</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <div v-if="egreso && egreso.tipoIngresoEgreso">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  color="#121212"
                  @click="dialogDetalle = true"
                  :elevation="hover ? 12 : 4"
                  :class="{ 'on-hover': hover }"
                >
                  <v-container>
                    <span class="justify-center">
                      <v-icon>mdi-plus</v-icon>
                      <span class="text-center"> Agregar detalle </span>
                    </span>
                  </v-container>
                </v-card>
              </v-hover>
            </div>
          </v-list>
          <v-divider></v-divider>
          <v-list class="align-end">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>Cantidad items</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>{{ egreso.detalles.length }} </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Subtotal</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon
                ><h3>{{ Number(subtotal()) | convertPesoArg }}</h3>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { ValidationObserver } from "vee-validate";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";
import EgresoForm from "@/components/egresos/EgresoForm";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
export default {
  name: "egreso.registrar",
  inject: {
    $validator: "$validator",
  },
  components: {
    LayoutBasic,
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
    VSelectWithValidation,
    EgresoForm,
    DialogConfirmationClose,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      dialogDetalle: false,
      dialogRegistrarGasto: false,
      egreso: {
        tipoIngresoEgreso: null,
        descripcion: "",
        categoria: null,
        monto: 0,
        detalles: [],
        esIngreso: false,
        fechaPeriodo: new Date().toISOString().substr(0, 7),
      },
      tiposIngresoEgreso: [],
      detalle: { descripcion: "", monto: null },

      isLoading: false,
    };
  },
  computed: {
    validRegister() {
      return (
        this.egreso.tipoIngresoEgreso &&
        this.egreso.categoria &&
        this.egreso.monto > 0
      );
    },
    items() {
      return [
        {
          text: "Nuevo egreso",
          icon: "mdi-clock",
          route: "egresoNuevo",
        },
        { text: "Consultar", icon: "mdi-account", route: "#" },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ];
    },
  },
  methods: {
    subtotal() {
      var subtotal = 0;
      if (this.egreso.detalles.length > 0) {
        for (let index = 0; index < this.egreso.detalles.length; index++) {
          var precio = parseFloat(this.egreso.detalles[index].monto);
          subtotal = subtotal + precio;
        }
      }

      return subtotal;
    },
    async añadirDetalle() {
      const result = await this.$refs.formDetalle.validate();
      if (result) {
        this.egreso.detalles.push(this.detalle);
        this.detalle = { descripcion: "", monto: null };
        this.$refs.formDetalle.reset();
        this.dialogDetalle = false;
      } else {
        this.$validator.validate();
      }
    },
    quitarDetalle(detalle) {
      const index = this.egreso.detalles.indexOf(detalle);

      if (index > -1) {
        this.egreso.detalles.splice(index, 1);
      }
    },

    modificarDetalle(detalle) {
      this.dialogDetalle = true;

      this.detalle = detalle;
      this.quitarDetalle(detalle);
    },
    async registrarEgreso(pagarConCaja = false) {
      try {
        if (this.egreso.detalles.length > 0) {
          this.egreso.monto = this.subtotal();
        }
        this.egreso.pagaConCaja = pagarConCaja;
        const response = await this.axios.post(
          "/egresos/ingresoegreso",
          this.egreso
        );
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Egreso registrada",
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.$router.push("/egresos");
      } catch (error) {
        console.log(error.response.data.data);
        this.dialogRegistrarGasto = false;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "primary",
          text: `${error.response.data.data}`,
          icon: "mdi-information",
          timeout: 4000,
          right: false,
          top: false,
        });
      }
    },
    async fetchTipos() {
      const response = await this.axios.get(
        "/egresos/tipoingresoegreso?esIngreso=False"
      );
      this.tiposIngresoEgreso = response.data;
    },

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
    this.fetchTipos();
  },
};
</script>

<style lang="scss" scoped></style>
