<template>
  <div>
    <v-text-field
      filled
      v-model="producto.descripcion"
      autofocus
      label="Nombre del producto"
      id="descripcion"
      v-validate="'required|max:100'"
      :error-messages="errors.collect('descripcion')"
      data-vv-name="descripcion"
      :counter="100"
      maxlength="100"
      required
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.descripcion",
  inject: ["$validator"],
 
  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
