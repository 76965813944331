<template>
  <div>
    <div>
      <h1>Ventas anuladas</h1>
    </div>
    <v-row justify="center" class="mt-4">
      <v-col cols="12" md="6">
        <v-autocomplete
          label="Empleado"
          :autofocus="false"
          v-model="empleado"
          :items="empleados"
          item-text="username"
          required
          item-value="id"
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="2">
        <v-btn large color="info" @click="fetchVentasAnuladas()">Consultar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="ventas"
          :expanded.sync="expanded"
          hide-default-footer
          item-key="id"
          show-expand
          class="elevation-1"
          v-if="!loading"
        >
          <template slot="no-data">
            <v-alert icon="mdi-information"> No se encontraron resultados </v-alert>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="2">
              <v-list>
                <v-list-item
                  v-for="detalle in item.detalles_comprobante"
                  :key="detalle.id"
                >
                  <ul>
                    <li>
                      <v-list-item-icon>
                        <span style="color: grey">{{ detalle.cantidad }}x</span>
                      </v-list-item-icon>
                    </li>
                  </ul>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span style="color: grey">{{ detalle.producto.descripcion }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action> </v-list-item-action>
                </v-list-item>
              </v-list>
            </td>
          </template>
          <template v-slot:[`item.fechaAlta`]="{ item }">
            {{ moment(item.fechaAlta).format("lll") }}
          </template>
          <template v-slot:[`item.fechaUltModificacion`]="{ item }">
            {{ moment(item.fechaAlta).format("lll") }}
          </template>
        </v-data-table>
        <template v-if="loading">
          <v-skeleton-loader max-height="350" type="table-heading"></v-skeleton-loader>

          <v-skeleton-loader max-height="350" type="table-tbody"></v-skeleton-loader>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "reporte.ventas.anuladas",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Fecha creación",
          align: "start",
          sortable: false,
          value: "fechaAlta",
        },
        { text: "Fecha anulaciòn", value: "fechaUltModificacion" },
        { text: "Total", value: "total" },
        { text: "Motivo", value: "motivoAnulacion.descripcion" },
        { text: "Comentario", value: "comentarioMotivoAnulacion" },
      ],

      empleado: null,
      empleados: [],
      ventas: [],
      date: this.moment().format("YYYY-MM-DD"),
      menu2: false,
      loading: false,
    };
  },
  methods: {
    fetchVentasAnuladas() {
      this.loading = true;
      this.axios
        .get(`/reportes/ventasanuladas?empleado=${this.empleado}`)
        .then((response) => {
          this.ventas = response.data.ventas;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    fetchEmpleados() {
      this.axios
        .get("/usuarios/usuarios")
        .then((response) => {
          this.empleados = response.data;
        })
        .catch((e) => {});
    },
  },
  created() {
    this.fetchEmpleados();
  },
};
</script>

<style lang="scss" scoped></style>
