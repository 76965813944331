<template>
 <LayoutBasic :showLinksMenu="false">
    <template v-slot:breadcrumbs>
      <!-- <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
        <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
          item.text
        }}</v-breadcrumbs-item>
      </v-breadcrumbs> -->
    </template>
    <template v-slot:navigation-title>Compras </template>


       <template v-slot:navigation-content>

      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <router-view></router-view>
    </template>
 </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
export default {
  name: "Compras",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      item: 0,
      items: [
        { text: "Inicio", icon: "mdi-clock", route: "compras" },
        { text: "Pedidos", icon: "mdi-file-document-multiple", route: "pedidoCompra" },
        { text: "Remitos", icon: "mdi-file-document-multiple-outline", route: "remitoCompra" },

        { text: "Facturas", icon: "mdi-file-document-multiple-outline", route: "facturaCompra" },
      ],
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss" scoped></style>
