<template>
  <div>
    <!-- Descuento  -->
    <v-list-item v-show="!descuento && totalDebitoEfectivo > 0 && !tarjeta_credito">
      <v-btn @click="dialogDescuento = true" rounded small block dark>
        <div>Agregar <strong class="primary--text">Descuento</strong></div>
      </v-btn>
    </v-list-item>
    <v-dialog persistent width="400" v-if="dialogDescuento" v-model="dialogDescuento">
      <v-card>
        <v-container>
          <v-card-title
            >Descuento <v-spacer></v-spacer>
            <v-btn icon @click="resetDescuento"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-card-title
          >
          <v-card color="#303030" flat class="mx-2 pb-2">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    flat
                    autofocus
                    hide-details
                    prepend-icon="mdi-percent"
                    v-validate="'numeric|max_value:100'"
                    :error-messages="errors.collect('descuento')"
                    data-vv-name="descuento"
                    clearable
                    type="number"
                    max="100"
                    min="0"
                    v-model="descuento"
                    label="Ingresa el descuento"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card flat>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :disabled="descuento <= 0"
                    append-icon="mdi-comment-outline"
                    v-model="motivoDescuento"
                    label="Motivo descuento"
                    color="white"
                    filled
                    counter
                    maxlength="40"
                    rows="2"
                    row-height="50"
                    required
                    @input="$emit('activate-is-borrador-venta')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetDescuento()"> Cancelar </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="!descuento || !motivoDescuento || errors.any()"
            @click="aplicarDescuento"
          >
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list-item v-show="descuento > 0" dense class="justify-end">
      <v-list-item-content>
        <v-list-item-title>Descuento</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon
        ><h4>{{ descuentoDelTotal() | convertPesoArg }}</h4>
        <v-icon color="red" class="ml-2" @click="resetDescuento"
          >mdi-trash-can-outline</v-icon
        >
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "venta.cobrar.descuento",
   $_veeValidate: {
    validator: "new",
  },
   filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
      subtotal:{
          required: true
      }
  },
  data() {
    return {
      dialogDescuento: false,
    };
  },
  computed: {
    descuento: {
      get() {
        return this.$store.state.ventas.descuento;
      },
      set(value) {
        this.SET_DESCUENTO(value);
      },
    },
    motivoDescuento: {
      get() {
        return this.$store.state.ventas.motivoDescuento;
      },
      set(value) {
        this.SET_MOTIVO_DESCUENTO(value);
      },
    },
      ...mapGetters("ventas", [
        "totalPagoCliente",
        "totalCredito",
        "totalDebitoEfectivo",
        "get_total",
        "get_total_recargo_credito",
      ]),
      tarjeta_credito() {
        return this.$store.getters["ventas/get_forma_de_pago_seleccionada"](
          "TARJETA DE CRÉDITO"
        );
      },
  },
  methods: {
    ...mapMutations("ventas", ["SET_DESCUENTO", "SET_MOTIVO_DESCUENTO"]),
    ...mapActions("ventas", [
      "aplicar_descuento",
      "quitar_descuento",
    ]),
    aplicarDescuento() {
      this.dialogDescuento = false;
      this.aplicar_descuento();
    },
    resetDescuento() {
      this.dialogDescuento = false;
        this.SET_DESCUENTO(null);
        this.SET_MOTIVO_DESCUENTO('');


      this.quitar_descuento();
    },
descuentoDelTotal() {
    var descuento = this.descuento ? this.descuento : 0;
    return parseFloat((this.subtotal() * (descuento / 100)).toFixed(2));
},
  },
};
</script>

<style lang="scss" scoped></style>
