<template>
  <div class="items3">
    <v-snackbar
      color="error"
      v-model="error_detalle_repetido.state"
      multi-line
      >{{ error_detalle_repetido.message }}</v-snackbar
    >
    <v-data-table
    style="background-color:transparent"
      :headers="headers"
      :items="detalles"
      sort-by="calories"
      no-data-text="No hay productos agregados"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.producto.cantidadstock="{ item }">
        {{ item.producto.controlaStock ? item.producto.cantidadstock : "N/A" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <comprobante-detalle-buttons
          :detalle="item"
          @process-increment="incremetnCant(item)"
          @process-decrement="decrementCant(item)"
          @process-delete="eliminarDetalle(item)"
        >
        </comprobante-detalle-buttons> -->
        
        <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="openDialogEditDetalle(item)"
               
              >
                <v-list-item-title>Editar cantidad</v-list-item-title>

              </v-list-item>
              <v-list-item
              @click="eliminarDetalle(item)"
               
              >
                <v-list-item-title>Quitar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
    </v-data-table>
  
    <v-dialog persistent v-model="dialogDetalleForm" width="500">
      <comprobante-detalle-form
        v-if="dialogDetalleForm"
        ref="detalleForm"
        @close-dialog="dialogDetalleForm = false"
        @process-detalle="editarCantidad"
        :detalle="detalleEdit"
      ></comprobante-detalle-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  quitDetalle,
  updateDetalle,
  addDetalle,
} from "@/services/detallesComprobante/serviceDetalles";

import { mapMutations, mapState } from "vuex";
import ComprobanteDetalleForm from "@/components/comprobante/detalle/comprobante.detalle.form.vue";
import ComprobanteDetalleButtons from "@/components/comprobante/detalle/comprobante.detalle.buttons.vue";

export default {
  name: "pedido.compra.detalles.table",

  components: {
    ComprobanteDetalleForm,
    ComprobanteDetalleButtons,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      items: [
        { title: 'Editar cantidad', action: 'editar' },
        { title: 'Quitar', action: 'quitar' },
        
      ],
      dialogDetalleForm: false,
      detalleEdit: null,
      dialog_detalle: false,

      detalle: null,
      detalle_update: {},
      height: "100%",
      headers: [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "producto.descripcion",
        },
        { text: "Cant. Ped", value: "cantidad" },
        { text: "Stock actual", value: "producto.cantidadstock" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),
    editarCantidad(detalle){
      var index = this.detalles.find(d => d.producto.id == detalle.producto.id)
      if (index){
        index.cantidad = detalle.cantidad
      }
      this.dialogDetalleForm = false
    },
    openDialogEditDetalle(detalle){
      this.detalleEdit = structuredClone(detalle)
      this.dialogDetalleForm = true
    },
    emitSubTotal() {
      //Lo invoca el padre
      this.$emit("process-sub-total", this.subtotal());
    },
    incremetnCant(detalle) {
      detalle.cantidad = parseFloat(detalle.cantidad)
      console.log(detalle);
      if (detalle.producto) {

          if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
            detalle.cantidad = parseFloat(detalle.cantidad) + 0.5;
          } else {
            detalle.cantidad++;
          }
      } else {
        detalle.cantidad++;
      }
    },
    decrementCant(detalle) {
      var cantidad = parseFloat(detalle.cantidad);
      if (detalle.producto) {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          if (cantidad == 0.5) {
            return;
          }
          detalle.cantidad = detalle.cantidad - 0.5;
          return;
        } else {
          if (cantidad == 1) {
            return;
          }

          detalle.cantidad--;
        }
      } else {
        if (cantidad == 1) {
          return;
        }

        detalle.cantidad--;
      }
    },

    eliminarDetalle(detalle) {
      quitDetalle(this.detalles, detalle);
    },
    modificarDetalle(detalle) {
      this.dialog_detalle = false;
      if (
        this.configuracion.productoInventario &&
        detalle.producto.controlaStock
      ) {
        detalle.producto.cantidadstock -= parseInt(detalle.cantidad);
      }

      updateDetalle(this.detalles, detalle);
    },

    subtotal() {
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          var precio =
            this.detalles[index].cantidad *
            this.detalles[index].producto.precioVenta;
          subtotal += precio;
        } else {
          var precio =
            this.detalles[index].cantidad *
            this.detalles[index].promocion.precioVenta;
          subtotal += precio;
        }
      }
      return subtotal;
    },

    setDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
  },
  watch: {
    dialog_detalle: function (val) {
      if (val == false) (this.detalle = null), (this.detalle_update = {});
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
      event.preventDefault();

      //escape
      if (event.key === "Escape") {
        this.detalle = null;
      }
      //suprimir
      //Elimina el detalle si esta seleccionado y el dialog para agregar detalle esta desactivado
      if (event.keyCode === 46 && this.dialog_detalle == false) {
        if (this.detalle !== null) {
          event.preventDefault();

          this.eliminarDetalle(this.detalles[this.detalle]);
          this.detalle = null;
        }
      }

      // //Flecha hacia abajo
      // if (event.keyCode === 40 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = 0;
      //   } else if (this.detalle == this.detalles.length - 1) {
      //     this.detalle = 0;
      //   } else {
      //     this.detalle = this.detalle + 1;
      //   }
      // }

      // //Flecha hacia arriba
      // if (event.keyCode === 38 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = this.detalles.length - 1;
      //   } else if (this.detalle == 0) {
      //     this.detalle = this.detalles.length - 1;
      //   } else {
      //     this.detalle = this.detalle - 1;
      //   }
      // }

      //ENTER - Abre el modal cuando esta seleccionado algun elemento de la lista (detalle.length > 0)
      if (event.keyCode === 13) {
        if (this.detalles.length > 0) {
          if (this.detalle !== null && this.dialog_detalle == false) {
            this.setDetalle(this.detalles[this.detalle]);
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.v-list.detalle {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
