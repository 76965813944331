<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5">Facturación</div>

        <!-- <div class="subtitle">Elegí que notificación queres recibir</div> -->

         <v-list rounded class="mt-4">
          <configuracion-item
            v-for="parametro in parametros"
            :key="parametro.id"
            :parametro="parametro"
          >
          </configuracion-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfiguracionItem from "@/views/configuracion/configuracion.item";
    import ConfiguracionesAPI from '@/api/configuracion/parametros/index.js'

import { mapActions, mapState } from "vuex";

export default {
  name: "configuracion.facturacion",
  components: {
   ConfiguracionItem,

  },
  data() {
    return {
      codigo: 500,

      dialog: false,
      monto: null,
      montoEdit: null,
      tipoNotificacionesEdit: null,
      loading: false,
      alertas: [],
      switch1: false,
      parametros: []
    };
  },
  computed: {
  
  },
  methods: {
  async fetchParametros(){
       const response = await ConfiguracionesAPI.index(this.codigo)
       this.parametros = response.data
    },
    openDialog(monto) {
      this.montoEdit = monto;

      this.dialog = true;
    },
   
 
  },
  created() {
    this.fetchParametros()
  },
};
</script>

<style lang="scss" scoped></style>
