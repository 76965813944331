<template>
  <LayoutBasic :showLinksMenu="false" >
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
         <template v-slot:navigation-title>Clientes</template>
   
    <template v-slot:navigation-content>
      <v-list>
      
        <v-list-item @click="dialogClienteCreate = true">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Nuevo cliente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="$router.push({name: 'clientesCuentasCorrientes'})" >
          <v-list-item-icon>
            <v-icon>mdi-account-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Cuentas Corrientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
       
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <clientes-list :search="search"></clientes-list>
      </v-container>
      <v-dialog width="800" v-model="dialogClienteCreate">
        <v-card>
          <cliente-create
            @close-dialog="dialogClienteCreate = false"
          ></cliente-create>
        </v-card>
      </v-dialog>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import ClienteCreate from "@/views/cliente/ClienteCreate";
import ClienteUpdate from "@/views/cliente/ClienteUpdate";
import ClientesList from "@/components/cliente/ClientesList";

export default {
  name: "Clientes",
  components: {
    ClienteCreate,
    ClienteUpdate,
    ClientesList,
    LayoutBasic,
  },
  data() {
    return {
      search: "",
      dialogClienteCreate: false,
      activeBtn: 0,
    };
  },
  computed: {
  
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  mounted() {
    //  this.clientees = Cliente.query().with('localidad.provincia').get()
    // this.provincias = Provincia.all()
  },
};
</script>

<style lang="scss" scoped></style>
