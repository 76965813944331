<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <div class="text-h5">Mis consumos</div>
        </v-col>
        {{consumo}}
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EmpleadosAPI from "@/api/empleados"
export default {
  name: "misConsumos",
  data(){
    return {
      consumo: null,
    }
  
  },
  methods: {
    async obtenerConsumo(){
      var consumo = await EmpleadosAPI.getConsumo()
      this.consumo = consumo
    }
  },
  created(){
    this.obtenerConsumo()
  }

};
</script>

<style lang="scss" scoped></style>
