<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ asiento.codigo }} - {{ asiento.descripcion }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-btn icon>
              <v-btn icon @click="goToEditar(asiento)">
                <v-icon color="grey lighten-1">mdi-pencil</v-icon></v-btn
              >
            </v-btn>
            <v-btn icon>
              <v-btn icon @click="dialogEliminar = true">
                <v-icon color="grey lighten-1">mdi-delete</v-icon></v-btn
              >
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
             Info detalles
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      
      </v-list>
    </v-expansion-panel-content>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarCuenta()"
    >
      <template v-slot:titulo>¿Eliminar el asiento?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar el asiento "{{ asiento.descripcion }}"?
        No podrá revertir los cambios.</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
  </v-expansion-panel>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import { mapActions, mapState } from "vuex";

export default {
  name: 'AsientoTipoListItem',
  components: {
    DialogConfirmationClose,
  },
  props: {
    asiento: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  methods: {
    ...mapActions("contabilidad", ["eliminar_asiento_tipo"]),
    goToEditar(asiento) {
      this.$router.push({
        name: "asientoTipoUpdate",
        params: { asientoTipo: asiento },
      });
    },
    eliminarCuenta() {
      this.eliminar_asiento_tipo(this.asiento).then((response) => {
        this.dialogEliminar = false;
      });
    },
  },
};
</script>