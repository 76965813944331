<template>
  <layout-two-column :showLinksMenu="false">
    <template v-slot:navigation-title>Gastos </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item
            v-for="(item, i) in links"
            :key="i"
            @click="goToRoute(item.route)"
          >
            <v-list-item-icon>
              <v-icon
                :color="item.color ? item.color : ''"
                v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-if="user.role.includes('owner') || user.role.includes('admin')">
        <v-list-item class="">
          <v-list-item-content>
            <v-list-item-title
              ><span style="text-subtitle-1"
                ><b>Filtros</b></span
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="">
          <v-list-item-content>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaPeriodo"
                  label="Fecha periodo"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaPeriodo"
                no-title
                type="month"
                class="mb-4"
                locale="es"
                year-icon="mdi-calendar-blank"
                prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next"
              ></v-date-picker>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <h1>{{ moment(fechaPeriodo).format("MMMM YY").toUpperCase() }}</h1>
        <v-row justify="center">
          <v-col cols="12">
            <EgresosList
              @process-egreso="handleClick"
              :egresos="egresos"
            ></EgresosList> </v-col
        ></v-row>
      </v-container>

      <router-view></router-view>
      <v-dialog v-model="dialogVistaPrevia" width="600px">
        <egreso-vista-previa
          v-if="dialogVistaPrevia"
          :egreso="egreso"
          @close-dialog="dialogVistaPrevia = false"
          @process-eliminar-success="fetchEgresos"
        ></egreso-vista-previa>
      </v-dialog>
    </template>
  </layout-two-column>
</template>
<script>
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";
import EgresoVistaPrevia from "@/components/egresos/egreso.preview.vue";
import EgresosList from "@/components/egresos/egresos.list.vue";
import { mapState } from "vuex";
export default {
  name: "EgresosGastos",
  components: {
    LayoutTwoColumn,
    EgresoVistaPrevia,
    EgresosList,
  },
  data() {
    return {
      egresos: [],
      links: [],
      fechaPeriodo: new Date().toISOString().substr(0, 7),

      egreso: null,
      dialogVistaPrevia: false,

      item: null,
      menu: false,
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
    };
  },
  computed: {
    ...mapState("auth", ["group"]),
    ...mapState("auth", ["user", "isLogged"]),

   
  },
  methods: {
    quitarEgreso(egreso) {
      alert(quitarEgreso);
    },
    handleClick(egreso) {
      this.egreso = egreso;
      this.dialogVistaPrevia = true;
    },

    async eliminarPromocion(promo) {
      const response = await this.axios.delete(`egresos/${promo.id}`);
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    editarEgreso(item) {},
    eliminarEgreso(item) {},
    verDetalleEgreso(egreso) {
      var id = egreso.id;
      this.$router
        .push({ name: "detallePromocion", params: { id: id } })
        .catch(() => {});
    },
    async fetchEgresos() {
      var response = await this.axios.get(
        `/egresos/gastos?fechaPeriodo=${this.fechaPeriodo}`
      );

      this.egresos = response.data;
    },
  },
  created() {
    this.links.push({
      id: 1,
      text: "Nuevo gasto",
      icon: "mdi-plus",
      route: "egresoNuevo",
    });
    if (this.user.role.includes('owner') || this.user.role.includes('admin')) {
      this.links.push({
        id: 11,
        text: "Tipos de gasto",
        icon: "mdi-brightness-1",
        route: "tipoIngresoEgreso",
      });
      this.links.push({
        id: 12,

        text: "Categorias",
        icon: "mdi-sitemap",
        route: "egresosCategoria",
      });
    }
    this.fetchEgresos();
  },
  watch: {
    fechaPeriodo(value) {
      this.fetchEgresos(value);
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
