<template>
  <div>
    <v-autocomplete
      class="mr-1"
      :items="pedidos"
      append-icon="mdi-magnify"
      ref="searchCmp"
      :loading="isLoading"
      :search-input.sync="inputSearch"
      hide-no-data
      persistent-hint
      clearable
      @click:clear="clearData()"
      item-text="descripcion"
      item-value="descripcion"
      height="28"
      label="Nº de comprobante"
      outlined
      id="inputSearch"
      v-validate="'required|integer|max:10'"
      :error-messages="errors.collect('inputSearch')"
      data-vv-name="código"
      cache-items
      type="number"
      autocomplete="off"
      autofocus
      @focus.native="$event.target.select()"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  // Puede recibir como prop cleanInputSearch para limpiar lo que este escrito
  // Emito @resultadoBusqueda="setProductoBuscado" cuando se realiza la busqued a la api

  name: "remito.buscador",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },

  data() {
    return {
      cantidad: null,
      isLoading: false,

      pedidos: [],
      inputSearch: null,
      hayPedidos: false,
    };
  },

  methods: {
    focus() {
      this.$refs.searchCmp.focus();
    },
    clearData() {
      this.$emit("clear-search");
    },
    cleanInputSearch() {
      this.inputSearch = null;
      this.$refs.searchCmp.focus();
    },
  
    getFromSearch(val) {
      // Si ingresa cadena vacia, retorna falso
      this.$emit("loading-buscar-pedido", true);
      var esVenta = false;
      if (this.tipoComprobante == "compras" || this.tipoComprobante == "compra") {
        esVenta = false;
      } else {
        esVenta = true;
      }
      if (val) {
        var url = `/comprobantes/comprobantes?esVenta=${esVenta}&doc=RE&numero=${val}`;
      } else {
        var url = `/comprobantes/comprobantes?esVenta=${esVenta}&doc=RE`;
      }

      //El servidor devulve un único producto, o devuelve array vacio
      this.axios
        .get(url)
        .then((request) => {
          request.data.results.length > 0
            ? (this.hayPedidos = true)
            : (this.hayPedidos = false);
          this.$emit("loading-buscar-pedido", false);

          this.$emit("resultado-buscar-producto", request.data);

          this.isLoading = false;
        })
        .catch((e) => {
          this.$emit("loading-buscar-pedido", false);

          this.isLoading = false;
        });
    },
  },
  watch: {
    inputSearch(data) {
      // if (data === null || data === undefined || data === "")
      //   return this.$emit("loading-buscar-pedido", false);
      if (!data) return
      if (data.length > 16) return (this.inputSearch = "");

      if (Number.isInteger) {
        this.isLoading = true;

        this.getFromSearch(data);
      } else {
        return (this.pedidos = []);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
