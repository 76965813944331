<template>
  <v-data-table
    :headers="headers"
    :items="productos_agregados_register"
    sort-by="calories"
    class="transparent"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Productos agregados</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>
    </template>

    <template v-show="!marca" v-slot:item.marcas="{ item }">
      <v-row align="center">
        <v-col>
          <marca
            :clearable="true"
            :placeholder="'Seleccionar'"
            @clearable="quitar_marca(item)"
            @set_marca="setMarcaRegister($event, item)"
          ></marca>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row>
        <v-icon small color="green" class="mr-2" @click="editarProducto(item)"
          >mdi-pencil</v-icon
        >
        <v-icon small color="red" class="mr-2" @click="quitProduct(item)"
          >mdi-delete</v-icon
        >
      </v-row>
    </template>

    <template v-slot:no-data>
      <h4>Ningún producto agregado</h4>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Marca from "@/components/marcas/FormSelectMarca";



export default {
  name: "ProductoListaRegistro",
  components: {
    Marca,
  },
  props: {
    marca: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      dialogProductoCreate: false,
      dialogProductoUpdate: false,

      headers_basic: [
        {
          text: "Barcode",
          align: "start",
          sortable: false,
          value: "codigoDeBarra",
        },
        {
          text: "Nombre",
          value: "descripcion",
        },
        { text: "$ Costo", value: "precioCosto" },
        { text: "IVA", value: "iva" },
        { text: "% Gan", value: "ganancia" },
        { text: "% Flete", value: "flete" },
        { text: "$ Venta", value: "precioVenta" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers_marca: [
        {
          text: "Barcode",
          align: "start",
          sortable: false,
          value: "codigoDeBarra",
        },
        {
          text: "Nombre",
          value: "descripcion",
        },
        { text: "$ Costo", value: "precioCosto" },
        { text: "IVA", value: "iva" },
        { text: "% Gan", value: "ganancia" },
        { text: "% Flete", value: "flete" },
        { text: "$ Venta", value: "precioVenta" },
        { text: "Marca", value: "marcas", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    headers() {
      if (this.marca) {
        return this.headers_basic;
      } else {
        return this.headers_marca;
      }
    },
    ...mapGetters("productos", ["productos_agregados_register"]),
  },
  methods: {
    ...mapMutations("productos", [
      "SET_MARCA_POR_PRODUCTO_EN_REG_PROD_MUL",
      "QUITAR_PRODUCTO_EN_REG_PROD_MUL",
    ]),
    quitProduct(p) {
      this.QUITAR_PRODUCTO_EN_REG_PROD_MUL(p);
    },
    quitar_marca(producto) {
      this.SET_MARCA_POR_PRODUCTO_EN_REG_PROD_MUL({ marca: null, producto: producto });
    },
    setMarcaRegister(marca, producto) {
      this.SET_MARCA_POR_PRODUCTO_EN_REG_PROD_MUL({ marca: marca, producto: producto });
    },
    editarProducto(data) {
      this.QUITAR_PRODUCTO_EN_REG_PROD_MUL(data);
      this.$emit("process-update", data);
      // this.set_selected_product(data);
      // this.dialogProductoUpdate = true;
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 107 && this.$route.fullPath == "/productos") {
        this.dialogProductoCreate = true;
      }
    });
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
