<template>
  <div>
    <v-text-field
      filled
      prepend-inner-icon="mdi-barcode-scan"
      v-model="producto.codigoDeBarra"
      label="Código de barra (opcional)"
      id="codigodebarra"
      v-validate="'numeric|max:14|min:8'"
      :error-messages="errors.collect('codigodebarra')"
      data-vv-name="codigodebarra"
      type="number"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.barcode",
  inject: ["$validator"],
  components: {},
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
