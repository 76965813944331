<template>
  <v-row align="center">
    <template v-if="promociones.length > 0">
      <v-row>
        <v-col cols="4" v-for="promocion in promociones" :key="promocion.id">
          <promocion-item-card
            :isVenta="isVenta"
            @process-promocion="eventprocessPromocion"
            :promocion="promocion"
          ></promocion-item-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-container class="mt-2">
        <v-row>
          <v-col>
            <v-alert text type="info"> <h2>No se han encontrado promociones</h2></v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import PromocionItemCard from "@/views/promocion/promocion.item.card.vue";

export default {
  name: "ListadoProducto",
  components: {
    PromocionItemCard,
  },
  props: {
    promociones: {
      type: Array,
      required: true,
      default: () => [],
    },
    isVenta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      imagen: {
        // noImage: require("@/assets/no-image.png")
      },
      input_search: null,
      borrando: false,
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    eventprocessPromocion(promocion) {
      this.$emit("process-promocion", promocion);
    },
  },
};
</script>
