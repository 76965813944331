<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="start">
          <v-col>
            <div class="headline">Mantenimiento de asientos</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-title>
                <v-text-field
                  rounded
                  outlined
                  style="background-color: #3a3b3c"
                  dense
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar asiento"
                  single-line
                  hide-details
                  autofocus
                  clearable
                  @click:clear="remover_asiento"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-action>
                <v-btn @click="buscarAsiento" text> Buscar </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row v-if="asiento_contable">
          <v-col>
            <v-card>
              <v-container>
                <v-card-title>
                  Resultado de la búsqueda {{ search }}
                </v-card-title>
                <v-card-subtitle>
                  Número del asiento: {{ asiento_contable.numero }}
                </v-card-subtitle>

                <v-list-item>
                  <v-list-item-title>
                    <AsientoContableListItem :asiento="asiento_contable">
                    </AsientoContableListItem>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click="remover_asiento" text>
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-list>
              <v-list-item
                @click="$router.push({ name: 'asientoMantenimientoRegistrar' })"
              >
                <v-list-item-icon>
                  <v-icon>mdi-book-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Nuevo asiento </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12">
            <AsientoContablesList></AsientoContablesList>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AsientoContablesList from "@/components/contabilidad/asiento/mantenimiento/AsientoContablesList.vue";

import AsientoContableListItem from "@/components/contabilidad/asiento/mantenimiento/AsientoContableListItem.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "MantenimientoAsientoView",
  components: {
    AsientoContablesList,
    AsientoContableListItem,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState("comprobantes", ["asiento_contable"]),
  },
  methods: {
    ...mapActions("comprobantes", [
      "buscarAsientoContable",
      "fetch_asientos_contables",
    ]),
    ...mapMutations("comprobantes", ["SET_ASIENTO_CONTABLE"]),

    buscarAsiento() {
      this.buscarAsientoContable(this.search);
    },
    remover_asiento() {
      this.search = "";
      this.SET_ASIENTO_CONTABLE(null);
    },
  },
};
</script>
