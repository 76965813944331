<template>
  <v-card>
    <v-container>
      <v-card-text>
        <producto-form-actualizar :producto="producto"></producto-form-actualizar>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          :loading="loading"
          block
          @click="actualizarProducto(producto)"
        >
          Guardar</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CategoriaCardSelect from "@/components/categorias/consultar/CategoriaCardSelect";

import ProductoFormActualizar from "@/components/producto/form/producto.form";

export default {
  name: "producto.item.actualizar",
  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductoFormActualizar,
  },
  data() {
    return {
      loading: false,
      venta: null,
     
    
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
    ...mapGetters("proveedor", ["proveedores"]),

    precioVenta: {
      get() {
        return this.calcularPrecioVenta();
      },
      set(val) {
        return (this.venta = val);
      },
    },
  },
  methods: {
    ...mapMutations("productos", ["update_product"]),
    calcularPrecioVenta() {
      return Math.round(
        this.producto.precioCosto *
          (1 + parseFloat(this.producto.ganancia) / 100) *
          (1 + parseFloat(this.producto.flete) / 100) *
          (1 + parseFloat(this.producto.iva) / 100)
      );
    },

    calcularGanancia(venta) {
      let ganancia =
        (venta /
          (this.producto.precioCosto *
            (1 + parseFloat(this.producto.flete) / 100) *
            (1 + parseFloat(this.producto.iva) / 100)) -
          1) *
        100;

      return ganancia;
    },

    actualizarProducto(p) {
      this.loading = true;
      var marca_id = p.marca ? p.marca.id : null;
      var data = {
        id: p.id,
        descripcion: p.descripcion,
        precioVenta: this.precioVenta,
        precioCosto: p.precioCosto,
        iva: p.iva,
        ganancia: p.ganancia,
        flete: p.flete,
        marca: marca_id,
        categoriaArbol: p.categoriaArbol.id,
        proveedor: p.proveedor.id,
        stockmin: p.stockmin,
        stockmax: p.stockmax,
        controlaStock: p.controlaStock,
        codigoDeBarra: p.codigoDeBarra,
      };

      this.axios
        .put(`/productos/p/${p.id}`, data)
        .then((response) => {
          // this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
 button:false,
            color: "success",
            text: "Producto Actualizado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.loading = false;

          this.$emit("process-product-register-success");
        })
        .catch((e) => {
          this.loading = false;

          this.$router.push({
            name: "MensajeRegistroError",
            params: { error: e.response.data },
          });
        });
    },
  },
  watch: {
    venta: {
      handler(val) {
        if (val != null) {
          let ganancia = this.calcularGanancia(parseInt(val));
          this.producto.ganancia = Math.round(ganancia * 100) / 100;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
