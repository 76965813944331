<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Editar rubro de venta</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <RubroVentaForm
          :planDeCuentas="planDeCuentas"
          :rubroVenta="rubroVenta"
          @register:rubro="submitForm"
          textButton="Guardar"
        ></RubroVentaForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import RubroVentaList from "@/components/contabilidad/RubroVentaList.vue";
import RubroVentaForm from "@/components/contabilidad/RubroVentaForm.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "empleados",
  components: {
    RubroVentaList,
    LayoutBasic,
    RubroVentaForm,
  },
   props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      search: "",
      rubroVenta: null,
    };
  },

  methods: {
    ...mapActions("contabilidad", [
      "actualizar_rubro_venta",
    ]),
    async submitForm() {
      try {
        const datosParaEnviar = {
          ...this.rubroVenta,
          cuenta_neto_gravado: this.rubroVenta.cuenta_neto_gravado
            ? this.rubroVenta.cuenta_neto_gravado.id
            : null,
          cuenta_iva_no_inscripto: this.rubroVenta.cuenta_iva_no_inscripto
            ? this.rubroVenta.cuenta_iva_no_inscripto.id
            : null,
          cuenta_neto_no_gravado_exento: this.rubroVenta
            .cuenta_neto_no_gravado_exento
            ? this.rubroVenta.cuenta_neto_no_gravado_exento.id
            : null,
          cuenta_iva: this.rubroVenta.cuenta_iva
            ? this.rubroVenta.cuenta_iva.id
            : null,
          cuenta_iva_per_ret: this.rubroVenta.cuenta_iva_per_ret
            ? this.rubroVenta.cuenta_iva_per_ret.id
            : null,
          cuenta_ing_bru: this.rubroVenta.cuenta_ing_bru
            ? this.rubroVenta.cuenta_ing_bru.id
            : null,
          cuenta_ganancia: this.rubroVenta.cuenta_ganancia
            ? this.rubroVenta.cuenta_ganancia.id
            : null,
          cuenta_o_conc_3: this.rubroVenta.cuenta_o_conc_3
            ? this.rubroVenta.cuenta_o_conc_3.id
            : null,
        };
        const response = await this.actualizar_rubro_venta(datosParaEnviar);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Actualizado",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.$router.push("/contabilidad/rubroventa");


        // Redirigir a la lista de rubros de venta o mostrar un mensaje de éxito
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Problema inesperado",
          icon: "mdi-information",
          timeout: 3000,
        });
        console.error("Error al actualizar el rubro de venta:", error);
        // Mostrar un mensaje de error
      }
    },
      // Mostrar un mensaje de error
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          this.$router.go(-1);
        } else {
          if (params.rubroVenta) {
            this.rubroVenta = params.rubroVenta;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
