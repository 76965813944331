<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5">Configuración de la caja</div>

        <!-- <div class="subtitle">Elegí que notificación queres recibir</div> -->

        <v-list rounded class="mt-4">
          <configuracion-item
            v-for="parametro in parametros"
            :key="parametro.id"
            :parametro="parametro"
          >
          </configuracion-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfiguracionItem from "@/views/configuracion/configuracion.item";
import ConfiguracionesAPI from '@/api/configuracion/parametros/index.js'

import { mapActions, mapState } from "vuex";

export default {
  name: "configuracion.caja",
  components: {
    ConfiguracionItem,
  },
  data() {
    return {
      codigo: 400,
      dialog: false,
      monto: null,
      montoEdit: null,
      tipoNotificacionesEdit: null,
      loading: false,
      alertas: [],
      switch1: false,
      parametros: []
    };
  },
  computed: {

    // parametros(){
    //   return [{
    //     id: 1,
    //     nombre: 'Cierre de caja',
    //     descripcion: 'El usuario no podrá cerrar la caja si tiene más dinero que el permitido',
    //     codigo: 401,
    //     subscripto: true,
    //     monto: this.$store.state.sucursales.configuracion.montoFC

    //   }]
    // }
  },
  methods: {
    async fetchParametros(){
       const response = await ConfiguracionesAPI.index(this.codigo)
       this.parametros = response.data
    },
    openDialog(monto) {
      this.montoEdit = monto;

      this.dialog = true;
    },
  

  },
  created() {
    console.log(this.parametro);
    this.fetchParametros()

  },
};
</script>

<style lang="scss" scoped></style>
