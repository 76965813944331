<template>
  <div>
    <v-list-item v-for="(buzon, index) in egresos.buzon" :key="index">
      <v-list-item-avatar>
        <v-icon :color="buzon.ingreso ? 'green' : 'red'">{{
          buzon.ingreso ? "mdi-arrow-up-thick" : "mdi-arrow-down-thick"
        }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          >{{ buzon.ingreso ? "Total ingresado" : "Se retiró" }}
          {{ buzon.ingreso ? buzon.ingreso : buzon.egreso | convertPesoArg }}
        </v-list-item-title>

        <v-list-item-subtitle>
          {{
            moment(buzon.fecha_alta) > moment().subtract(1, "days")
              ? moment(buzon.fecha_alta).fromNow()
              : moment(buzon.fecha_alta).format("lll")
          }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        {{ buzon.usuario }}
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "buzon.list",
  props: {
    egresos: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
