<template>
  <v-row>
    <v-col cols="6">
      <v-list-item class="mt-0 pt-0">
        <v-list-item-content>
          <v-list-item-title>{{ formaDePago.nombre }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="6" style="padding: 0">
      <v-list-item>
        <v-list-item-content>
          <v-text-field
          outlined
            v-model="monto"
            type="number"
            label="Monto"
            dense
            min="0"
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    formaDePago: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      monto: null,
    };
  },
  watch: {
    monto() {
      this.$emit("monto-cambiado", {
        formaDePago: this.formaDePago,
        monto: parseFloat(this.monto),
      });
    },
  },
};
</script>
