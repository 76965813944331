<template>
  <LayoutBasic :routeBack="'/cajas'">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->

    <template v-slot:navigation-subtitle> Cajas </template>
    <template v-slot:navigation-title> Configuración </template>

    <template v-slot:vista-previa-title>
      Configuración de la caja
    </template>

    <template v-slot:vista-previa-list>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-if="!edit"
              ><div class="title">
                {{ caja.nombre }}
              </div></v-list-item-title
            >
            <v-list-item-title v-else
              ><div class="title">
                <v-text-field
                  label="Editar"
                  v-model="caja.nombre"
                  autofocus
                ></v-text-field></div
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!edit">
            <v-btn text @click="edit = true">
              <div>Editar nombre</div>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import CajaRegistrar from "@/components/sucursal/caja/CajaRegistrar.vue";

export default {
  name: "CajaSettings",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    LayoutBasic,
    CajaRegistrar,
  },
  data() {
    return {
      caja: null,
      edit: false,
    };
  },
  computed: {
    sucursal() {
      return this.$store.state.sucursales.sucursal
    },
  },
  methods: {
    fetchConfig(id_caja) {
      var id_caja = this.$route.params.id_caja;
      var url = `/sucursales/cajas/${id_caja}/settings`;
      this.axios.get(url).then((response) => {
        this.caja = response.data;
      });
    },
    saveSettings() {
      var data = this.caja;
      var url = `/sucursales/cajas/${id_caja}/settings`;
      this.axios.post(url, data).then((response) => {
        this.$router.push("/cajas");
      });
    },
  },
  created() {
    this.fetchConfig();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 50px;
}
.two-colors {
  background: #005d5b;
  background: linear-gradient(
    180deg,
    #005d5b 0,
    #005d5b 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );

  height: 150vh;
}
</style>
