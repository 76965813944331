<template>
  <div>
    <producto-por-promocion @process-promocion="agregarDetalle"></producto-por-promocion>
  </div>
</template>

<script>
import ProductoPorPromocion from "@/views/promocion/promocion.consultar.vue";
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";

export default {
  name: "AgregarDetallePorCategoria.vue",
  components: {
    ProductoPorPromocion,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    agregarDetalle(producto) {
      var detalle = { cantidad: 1, isProducto: false, precioUnitario: producto.precioVenta, promocion: producto };

      var det = { ...detalle };

      this.isBorrador = true;
      if (this.isUpdate) {
        add_detalle_update(this.detalles, det);
      } else {
        addDetalle(this.detalles, det);
      }
      this.$updateBus.$emit("showSnackBar", {
        show: true,
        button: false,
        right: false,
        top: false,
        color: "success",
        text: "Promocion agregado",
        icon: "mdi-check",
        timeout: 1500,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
