<template>
  <v-select
    v-model="selectedSucursal"
    :items="sucursalesConTodas"
    label="Sucursales"
    hide-details
    prepend-icon="mdi-home-group"
    item-text="nombre"
    item-value="nombre"
    single-line
    @change="handleClick(selectedSucursal)"
    return-object
  ></v-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SucursalesVSelect",
  data() {
    return {
      selectedSucursal: { id: 0, nombre: "Todas las sucursales" }
    };
  },
  computed: {
    ...mapState("sucursales", ["sucursales"]),
    sucursalesConTodas() {
      // Crea una copia de la lista de sucursales
      const sucursalesConTodas = [...this.sucursales];

      // Agrega una opción al principio para seleccionar todas las sucursales
      sucursalesConTodas.unshift({ id: 0, nombre: "Todas las sucursales" });

      return sucursalesConTodas;
    },
    filteredSucursales() {
      const actual = this.sucursales.filter(
        (sucursal) => sucursal.es_sucursal_actual
      );
      const restoSucursales = this.sucursales.filter(
        (sucursal) => !sucursal.es_sucursal_actual
      );

      return {
        actual,
        restoSucursales,
      };
    },
  },
  methods: {
    ...mapActions("sucursales", ["fetch_sucursales"]),
    handleClick(sucursal) {
      console.log("handleClick", sucursal);
      this.$emit("handle-click", sucursal);
    },
  },
  created() {
    this.fetch_sucursales();

    // this.axios.get("/sucursales/sucursales").then((response) => {
    //   this.sucursales = response.data;
    // });
  },
};
</script>

<style scoped>
</style>