<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="#FDD835" icon @click="$emit('process-increment')">
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </template>
      <span>Agregar</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn color="#40C4FF" v-on="on" icon @click="$emit('process-decrement')">
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
      </template>
      <span>Restar</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn color="red" v-on="on" icon @click="$emit('process-delete')">
          <v-icon>mdi-delete-circle</v-icon>
        </v-btn>
      </template>
      <span>Eliminar</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "pedido.detalle.buttons",
  methods: {
    incremetnCant() {
      this.$emit("process-increment");
    },
    decrementCant() {
      this.$emit("process-increment");
    },

    eliminarDetalle(detalle) {
      this.$emit("process-increment");
    },
  },
};
</script>

<style lang="scss" scoped></style>
