<template>
  <layout-pedidos>
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:title>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <h1 style="font-size: 35px">Listas de precios</h1>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar proveedor"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <!-- <v-list-item @click="dialogProveedorCreate = true">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Nuevo proveedor</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          @click="$router.push({ name: 'proveedoresListaDePrecio' })"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nueva lista de precios</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <!-- <ProveedoresList
          @process-proveedor="selectProveedor"
          :proveedores="proveedores"
          title="Proveedores con lista de precios"
          :search="search"
        ></ProveedoresList> -->
        <v-row justify="center">
          <v-col cols="6">

        <v-list>
          <template v-if="proveedoresComputed.length > 0">
            
          <v-list-item @click="selectProveedor(proveedor)" v-for="proveedor in proveedoresComputed" :key="proveedor.id">
            <v-list-item-content>
              <v-card class="transparent" flat>
                <v-card-title>
                  {{proveedor.nombre}}
                </v-card-title>
                <v-card-subtitle>
                  {{proveedor.last_update}}
                </v-card-subtitle>
              </v-card>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>

          </v-list-item>
          </template>
           <v-list-item v-else>
            <v-list-item-content>
               <v-card-title>
                 No hay proveedores con listas de precios.
                </v-card-title>
            </v-list-item-content>
           </v-list-item>

        </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog width="800" v-model="dialogProveedorCreate">
        <v-card>
          <proveedor-create
            @close-dialog="dialogProveedorCreate = false"
          ></proveedor-create>
        </v-card>
      </v-dialog>
    </template>
  </layout-pedidos>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import ProveedorCreate from "@/components/proveedor/ProveedorCreate";
import ClienteUpdate from "@/views/cliente/ClienteUpdate";
import ProveedoresList from "@/components/proveedor/ProveedoresList.vue";
import ProveedoresAPI from "@/api/proveedores/proveedor";

export default {
  name: "ProveedoresListasDePrecios",
  components: {
    LayoutBasic,
    ProveedorCreate,
    ClienteUpdate,
    ProveedoresList,
  },
  data() {
    return {
      search: "",
      dialogProveedorCreate: false,
      proveedores: [],
    };
  },
  computed: {
    proveedoresComputed() {
      let proveedores = this.proveedores;

      if (this.search) {
        proveedores = this.proveedores.filter(
          (u) => u.nombre.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      }

      return proveedores;
    },
  },
  methods: {
     selectProveedor(proveedor){
      this.$router.push({ path: `/proveedores/${proveedor.id}/lista-de-precio` });

    },
    fetchListasDePrecios() {
      ProveedoresAPI.conListaDePrecios().then((response) => {
        this.proveedores = response.data;
      });
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  created() {
    this.fetchListasDePrecios();
  },
};
</script>

<style lang="scss" scoped></style>
