<template>
  <div>
    <v-card max-width="375" class="mx-auto">
      <v-container>
        <v-card-title>
          <v-btn v-if="!isEditing" dark icon @click="goBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn v-if="isEditing" dark icon @click="closeEditing()">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-show="!isEditing" @click="isEditing = true" dark icon class="mr-4">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-menu v-if="!isEditing" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!isEditing" dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

       
          </v-menu>
          <v-btn text v-if="isEditing" @click="guardarCambios()" color="primary">
            Guardar
          </v-btn>
        </v-card-title>
        <v-img contain :src="user.foto" :aspect-ratio="16 / 9" dark v-if="user.foto">
        </v-img>
       

        <v-img
          v-if="!user.foto"
          height="150px"
          dark
          contain
          src="@/assets/man-woman.jpg"
        ></v-img>
        <v-list-item v-if="isEditing">
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-text-field
              v-if="isEditing"
              autocapitalize="sentences"
              label="Nombre usuario"
              v-model="user.username"
              v-validate="'required|min:1|max:25'"
              :error-messages="errors.collect('nombreUsuario')"
              data-vv-name="nombreUsuario"
              name="nombre"
            />
          </v-list-item-content>
        </v-list-item>
        <v-card-title v-else class="white--text">
          <div class="display-1">{{ user.username }}</div>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-switch
                v-on="on"
                color="success"
                @change="setActiveUser(user)"
                v-model="user.is_active"
                :label="user.is_active ? 'Habilitado' : 'Deshabilitado'"
              >
              </v-switch>
            </template>
            <span>{{ user.is_active ? "Deshabilitar" : "Habilitar" }}</span>
          </v-tooltip>
         
        </v-card-title>

        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-phone </v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="isEditing">
              <v-text-field
                label="Teléfono"
                v-validate="'required|min:7|max:10'"
                :error-messages="errors.collect('contraseña')"
                data-vv-name="contraseña"
                v-model="user.telefono"
              >
              </v-text-field>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{
                user.telefono ? user.telefono : "-"
              }}</v-list-item-title>
              <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-email </v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="isEditing">
              <v-text-field
                label="E-mail"
                v-validate="'email'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                v-model="user.email"
              >
              </v-text-field>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ user.email ? user.email : "-" }}</v-list-item-title>
              <v-list-item-subtitle>E-mail</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-map-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="isEditing">
              <v-text-field label="Dirección" v-model="user.direccion"> </v-text-field>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{
                user.direccion ? user.direccion : "-"
              }}</v-list-item-title>
              <v-list-item-subtitle>Dirección</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          v-show="!isEditing"
          block
          color="red"
          @click="dialogReestablecerContraseña = true"
          >Reestablecer contraseña</v-btn
        >
        <v-dialog persistent v-model="dialogReestablecerContraseña" width="400">
          <reestablecer-contraseña
            :user="user"
            @close-dialog="dialogReestablecerContraseña = false"
          ></reestablecer-contraseña>
        </v-dialog>
        <v-dialog v-model="dialogSubirFoto" width="400">
          <usuario-subir-foto
            :user="user"
            @process-success="dialogSubirFoto = false"
            @process-cancel-foto="dialogSubirFoto = false"
          ></usuario-subir-foto>
        </v-dialog>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ReestablecerContraseña from "@/components/usuarios/usuario.reestablecer.contraseña.vue";
import UsuarioSubirFoto from "@/components/usuarios/usuario.subir.foto.vue";
export default {
  name: "usuario.detalle",
  components: {
    ReestablecerContraseña,
    UsuarioSubirFoto,
  },

  inject: {
    $validator: "$validator",
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      changed: false,
      dialogReestablecerContraseña: false,
      dialogSubirFoto: false,
      loadingActive: false,
    };
  },
  methods: {
    setActiveUser(user) {
      this.loadingActive = true;
      setTimeout(() => {
        console.log(user.is_active);
        var accion = user.is_active ? "activar" : "desactivar";
        var data = {
          accion: accion,
          usuario: user.id,
        };
        this.axios
          .post("/usuarios/setactive", data)
          .then((response) => {
            this.loadingActive = false;
            user.is_active = user.is_active;
          })
          .catch((e) => {
            this.loadingActive = false;

            console.log(e);
          });
      }, 1000);
    },
    goBack() {
      this.$emit("close-dialog");
    },
    closeEditing() {
      if (this.changed) {
        alert("CAMBIASTE ALGO");
      } else {
        this.isEditing = false;
      }
    },
    guardarCambios() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          //   this.$emit("processMarca", this.dataMarca);
          console.log(this.user);
          var data = this.user;
          if (data.foto) {
            delete data.foto;
          }
          this.axios
            .put(`/usuarios/usuarios/${this.user.id}`, data)
            .then((response) => {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
 button:false,
                color: "success",
                text: "Actualizado",
                icon: "mdi-check",
                timeout: 3000,
              });
              this.isEditing = false;
            })
            .catch((e) => {
              console.log(e);
              this.$updateBus.$emit("showSnackBar", {
                show: true,
 button:false,
                color: "info",
                text: "Problema inseperado",
                icon: "mdi-information",
                timeout: 3000,
              });
            });
          this.isEditing = false;
        } else {
          console.log("error");
          console.log(this.$validator);
          this.$validator.validate();
        }
      });
    },
  },
  watch: {
    user: {
      deep: true, // detecting nested changes in objects
      immediate: true, // triggering the handler immediately with the current value
      handler(newValue, oldValue) {
        if (this.isEditing) {
          this.changed = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
