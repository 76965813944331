<template>
  <v-container>
    <v-row>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ formaDePago.nombre }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <a @click="dialogEliminar = true"> Eliminar</a>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-progress-circular
              v-if="loading"
              width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="formaDePago.cuenta"
              @update:initialCuenta="setCuentaContable"
            ></CuentasTreeviewDialog>
            <!-- <v-btn icon>
              <v-btn icon @click="dialogEliminar = true">
                <v-icon color="grey lighten-1">mdi-delete</v-icon></v-btn
              >
            </v-btn> -->
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-row>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarFormaDePago()"
    >
      <template v-slot:titulo>¿Quieres quitar esta forma de pago?</template>
      <template v-slot:message
        >¿Seguro que quieres quitar la forma de pago "{{ formaDePago.nombre }}"
        para esta sucursal?</template
      >
      <template v-slot:buttonOK>Quitar</template>
    </dialog-confirmation-close>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import ContabilidadFormasDePagoListItem from "@/components/contabilidad/formasDePago/ContabilidadFormasDePagoListItem.vue";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import FormaDePagoSucursalAPI from "@/api/contabilidad/formadepago";

export default {
  components: {
    ContabilidadFormasDePagoListItem,
    CuentasTreeviewDialog,
    DialogConfirmationClose,
  },
  props: {
    formaDePago: {
      type: Object,
      required: true,
    },
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogEliminar: false,
      loading: false,
    };
  },

  computed: {
    ...mapState("contabilidad", ["formasDePago"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetch_rubro_ventas", "eliminar_rubro"]),
    async setCuentaContable(cuenta) {
      console.log(this.formaDePago);
      this.loading = true; // Mostrar un indicador de carga mientras se realiza la operación
      const cuentaContableId = cuenta[0].id; // Obtener el ID de la cuenta contable
      const data = { cuenta: cuentaContableId }; // Crear el objeto de datos que se enviará a la API

      try {
        // Realizar la solicitud de actualización a la API
        const response = await FormaDePagoSucursalAPI.update(
          this.formaDePago.id,
          data
        );

        // Si la respuesta es exitosa, actualizar los datos locales
        this.formaDePago.cuenta = cuenta[0];

        // Puedes agregar más lógica aquí si necesitas manejar la respuesta
      } catch (error) {
        // Manejar errores en la solicitud de actualización
        console.error("Error actualizando la cuenta contable:", error);
      } finally {
        // Asegurarse de que el indicador de carga se apague
        this.loading = false;
      }
    },
    async eliminarFormaDePago() {
      this.loading = true; // Iniciar el indicador de carga

      try {
        // Realizar la solicitud DELETE a la API
        await FormaDePagoSucursalAPI.delete(this.formaDePago.id);
        this.$emit("delete:formadepago", this.formaDePago.id)
        
        
     
        this.dialogEliminar = false

        // Puedes agregar más lógica aquí si necesitas manejar la respuesta
      } catch (error) {
        // Manejar errores en la solicitud de eliminación
        console.error("Error eliminando la forma de pago:", error);
      } finally {
        // Asegurarse de que el indicador de carga se apague
        this.loading = false;
      }
    },
  },

};
</script>