<template>
  <div>
    <egreso-form-categoria-select
      @process-categoria="setCategoriaRegister"
      @clearable="removeCategoria"
      :categoria="egreso.categoria"
      :outlined="true"
    ></egreso-form-categoria-select>
  </div>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria.vue";
import CategoriaSelect from "@/components/categorias/consultar/categoria.select.vue"
import EgresoFormCategoriaSelect from '@/components/egresos/EgresoFormCategoriaSelect'

export default {
  name: "EgresoFormCategoria",
  components: {
    TreeCategoria,
    EgresoFormCategoriaSelect,
  },


  props: {
    egreso: {
      type: Object,
      required: true,
    },
  },


  methods: {
    removeCategoria(){
      this.egreso.categoriaArbol = null
      this.$emit("set_categoria", null);

    },
    setCategoriaRegister(cat) {
      console.log("this.egreso.categoriaArbol = ",cat);
      this.egreso.categoria = cat;
      this.$emit("set_categoria", cat);
    },
  },
};
</script>

<style lang="scss" scoped></style>
