<template>
  <v-row>
    <v-col cols="12">
      <v-select
        v-model="tipoEntregaMutable"
        :items="tiposEntrega"
        menu-props="auto"
        label="Tipo de entrega"
        item-text="nombre"
        item-value="id"
        @change="$emit('update-tipo-entrega', tipoEntregaMutable)"
        :disabled="disabledDetalle"
        hide-details
        prepend-icon="mdi-truck-outline"
        single-line
        return-object
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-menu
        v-model="menuFechaEntrega"
        :close-on-content-click="false"
        :nudge-right="80"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fechaEntregaEstimadaMutable"
            label="Fecha estimada"
            :disabled="disabledDetalle"
            prepend-icon="mdi-calendar"
            readonly
            color="green lighten-1"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          @change="$emit('update-fecha-estimada', fechaEntregaEstimadaMutable)"
          @input="menuFechaEntrega = false"
          color="green lighten-1"
          v-model="fechaEntregaEstimadaMutable"
          :min="new Date().toISOString().substr(0, 1)"
          locale="es"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "pedido.compra.tipoentrega.fechaestimada",
  props: {
      tipoEntrega: {
          type: Object,
          required: true,
          default: 0
      },
       fechaEntregaEstimada: {
          required: true,
          default: ""
      },
       disabledDetalle: {
          type: Boolean,
          default: false
      }
  },
  data() {
    return {
    tipoEntregaMutable: this.tipoEntrega,
    fechaEntregaEstimadaMutable: this.fechaEntregaEstimada,
      tiposEntrega: [
        { id: 0, nombre: "ENTREGA EN EL LOCAL" },
        { id: 1, nombre: "PARA RETIRAR" },
      ],
      menuFechaEntrega: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>