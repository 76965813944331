<template>
  <div>
    <v-row>
      <v-col cols="4" md="2">
        <v-card color="grey" class="">
          <v-container>
            <div class="text-tab">En buzón</div>
            <div class="value-tab">{{ 45000 | convertPesoArg }}</div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-list subheader two-line>
      <v-divider inset></v-divider>

      <v-subheader inset>Movimientos</v-subheader>

      <v-list-item v-for="file in files" :key="file.title">
        <v-list-item-avatar>
          <v-icon :class="file.color" dark v-text="file.icon"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="file.title"></v-list-item-title>

          <v-list-item-subtitle v-text="file.fecha"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <v-icon :color="false ? 'green' : 'red'">{{
              false ? "mdi-arrow-up-thick" : "mdi-arrow-down-thick"
            }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  
  </div>
</template>

<script>
export default {
  name: "misRetiros",

  data() {
    return {
      retiros: [
        {
          id: 1,
          fecha: "17/5/21",
          debe: null,
          haber: 5000,
          descripcion: "Retiraste 5000",
        },
      ],
      files: [
        {
          color: "blue",
          icon: "mdi-clipboard-text",
          fecha: "Jan 20, 2014",
          title: "Ingreso de dinero",
        },
        {
          color: "amber",
          icon: "mdi-gesture-tap-button",
          fecha: "Jan 10, 2014",
          title: "Kitchen remodel",
        },
      ],
      folders: [
        {
          fecha: "Jan 9, 2014",
          title: "Photos",
        },
        {
          fecha: "Jan 17, 2014",
          title: "Recipes",
        },
        {
          fecha: "Jan 28, 2014",
          title: "Work",
        },
      ],
    };
  },
  computed: {
    arrowIcon(esIngreso) {
      if (esIngreso) {
        return {
          icon: "mdi-arrow-up-thin",
          color: "green",
        };
      } else {
        return {
          icon: "mdi-arrow-down-thin",
          color: "red",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
