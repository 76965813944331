<template>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><h2>Total vendido</h2></v-list-item-subtitle
                        >
                        <v-list-item-title>
                          <span class="text-h6"
                            >{{ ventas.total ? ventas.total : 0 | convertPesoArg }}
                            </span
                          >
                           <span class="blue--text text-body-2">

                          {{ ventas.facturado.total ? ventas.facturado.total : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>En efectivo</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ ventas.efectivo ? ventas.efectivo : 0 | convertPesoArg }}
                          <span class="blue--text text-body-2">

                          {{ ventas.facturado.efectivo ? ventas.facturado.efectivo : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Con tarjeta de débito</v-list-item-subtitle>
                        <v-list-item-title>
                           {{
                          ventas["tarjeta de débito"]
                            ? ventas["tarjeta de débito"]
                            : 0 | convertPesoArg
                        }}
                         <span class="blue--text text-body-2">

                          {{ ventas.facturado["tarjeta de débito"] ? ventas.facturado["tarjeta de débito"] : 0 | convertPesoArg }}

                          </span>
  
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Con tarjeta de crédito</v-list-item-subtitle
                        >

                        <v-list-item-title>
                         {{
                          ventas["tarjeta de crédito"]
                            ? ventas["tarjeta de crédito"]
                            : 0 | convertPesoArg
                        }}
                         <span class="blue--text text-body-2">

                          {{ ventas.facturado["tarjeta de crédito"] ? ventas.facturado["tarjeta de crédito"] : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                  <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Con mercado pago</v-list-item-subtitle
                        >

                        <v-list-item-title>
                         {{
                          ventas["mercado pago"]
                            ? ventas["mercado pago"]
                            : 0 | convertPesoArg
                        }}
                         <span class="blue--text text-body-2">

                          {{ ventas.facturado["mercado pago"] ? ventas.facturado["mercado pago"] : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                 <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Con transferencia</v-list-item-subtitle
                        >

                        <v-list-item-title>
                         {{
                          ventas["transferencia"]
                            ? ventas["transferencia"]
                            : 0 | convertPesoArg
                        }}
                         <span class="blue--text text-body-2">

                          {{ ventas.facturado["transferencia"] ? ventas.facturado["transferencia"] : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                  <v-col>
                  <v-card flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Con cta cte</v-list-item-subtitle
                        >

                        <v-list-item-title>
                         {{
                          ventas["cta. cte."]
                            ? ventas["cta. cte."]
                            : 0 | convertPesoArg
                        }}
                         <span class="blue--text text-body-2">

                          {{ ventas.facturado["cta. cte."] ? ventas.facturado["cta. cte."] : 0 | convertPesoArg }}

                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
</template>

<script>
    export default {
        name: 'VentasPorFormaDePago',
        props: {
            ventas: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>