<template>
      <LayoutBasic :showLinksMenu="false" :routeBack="'/promociones'" v-if="promocion">
    <template v-slot:navigation-title> Editar promoción </template>
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
   
    <template v-slot:navigation-content>
      <v-container>
        <ValidationObserver ref="formPromo">
          <div slot-scope="{ invalid, validated }">
            <VTextFieldWithValidation
              outlined
              label="Nombre"
              autofocus
              v-model="promocion.nombre"
              rules="required|max:30"
              :counter="30"
              name="nombre"
            />
            <VTextAreaWithValidation
              outlined
              label="Descripción"
              autofocus
              v-model="promocion.descripcion"
              :placeholder="descripcionSugerida"
              rules="required|max:100"
              :counter="100"
              name="descripción"
              rows="3"
              row-height="30"
            />
            <VTextFieldWithValidation
              outlined
              label="Precio"
              autofocus
              v-model="promocion.precioVenta"
              rules="required|decimal|max:30"
              :counter="30"
              name="precio"
            />
          </div>
        </ValidationObserver>

        <v-menu
          v-model="menuFechaInicio"
          :close-on-content-click="false"
          :nudge-right="80"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="promocion.fechaInicioVigencia"
              label="Fecha de inicio"
              prepend-icon="mdi-calendar"
              readonly
              color="green lighten-1"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            @input="menuFechaInicio = false"
            color="green lighten-1"
            v-model="promocion.fechaInicioVigencia"
            :min="new Date().toISOString().substr(0, 10)"
            locale="es"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menuFechaFin"
          :close-on-content-click="false"
          :nudge-right="80"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="promocion.fechaFinVigencia"
              label="Fecha de finalización"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              color="green lighten-1"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="menuFechaFin = false"
            color="green lighten-1"
            v-model="promocion.fechaFinVigencia"
            locale="es"
          ></v-date-picker>
        </v-menu>
        <v-row align="center">
          <v-col cols="7">
            <div>
              <div>
                <v-checkbox
                  dense
                  class="ma-0"
                  v-model="promocion.controlaStock"
                  label="Control de inventario"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-col>
          <v-col cols="1">
            <information-dialog-icon
              title="Inventario"
              body="Se descontarán los productos del inventario cuando realize una venta de la promo."
            >
              <template v-slot:icon-btn>
                <v-icon>mdi-help-circle-outline</v-icon>
              </template>
              <template v-slot:icon-title>
                <v-icon>mdi-help-circle-outline</v-icon>
              </template>
            </information-dialog-icon>
          </v-col>
        </v-row>

        <template v-if="promocion.controlaStock">
          <v-card class="mt-4" color="#424242">
            <v-subheader>Productos</v-subheader>

            <v-list color="#424242">
              <v-list-item v-for="tag in promocion.detalles" :key="tag.id">
                <v-list-item-avatar> {{ tag.cantidad }}x </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <i>{{ tag.producto.descripcion }}</i></v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn icon @click="incremetnCant(tag)">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="decrementCant(tag)">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="#D84315" @click="deleteItem(tag)">
                    <v-icon>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-hover v-slot:default="{ hover }">
                <v-list-item
                  style="background-color: #43a047"
                  :dense="!hover"
                  @click="dialogAddProducto = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>Añadir producto</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn icon>
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-hover>
            </v-list>
          </v-card>

          <v-dialog v-model="dialogAddProducto" width="500">
            <v-toolbar
              >Añadir producto <v-spacer></v-spacer>
              <v-icon large @click="dialogAddProducto = false"
                >mdi-close</v-icon
              ></v-toolbar
            >
            <v-card>
              <v-card-title>
                <v-autocomplete
                  v-model="productosSeleccionados"
                  :items="productos"
                  :loading="isLoading"
                  :search-input.sync="inputSearch"
                  item-text="descripcion"
                  item-value="descripcion"
                  label="Buscar"
                  dense
                  autofocus
                  hide-selected
                  hide-no-data
                  clearable
                  return-object
                ></v-autocomplete>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item v-for="tag in promocion.detalles" :key="tag.id">
                    <v-list-item-avatar> {{ tag.cantidad }}x </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <i>{{ tag.producto.descripcion }}</i></v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-btn icon @click="incremetnCant(tag)">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                      <v-btn icon @click="decrementCant(tag)">
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteItem(tag)">
                        <v-icon>mdi-delete-circle</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-container>
    </template>
   <template v-slot:navigation-append>
      <v-divider></v-divider>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-btn large block color="green" @click="actualizarPromocion()"
            >Actualizar</v-btn
          >
          <v-btn class="mt-2" text large block color="red" @click="dialogEliminar = true"
            >Eliminar</v-btn
          >
        </v-col>
      </v-row>
    </template>

    <template v-slot:vista-previa>
      <v-row justify="center" class="fill-height" align="center">
        <v-col cols="4">
          <promocion-vista-previa :promocion="promocion"></promocion-vista-previa>
        </v-col>
        <v-col cols="4" v-if="promocion.controlaStock">
          <v-card height="100%">
            <v-container>
              <v-card-title> Inventario </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item v-for="tag in promocion.detalles" :key="tag.id">
                    <v-list-item-avatar> {{ tag.cantidad }}x </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <i>{{ tag.producto.descripcion }}</i></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <dialog-confirmation-close
        v-if="dialogEliminar"
        @close-dialog="dialogEliminar = false"
        @process-accept="eliminarPromocion()"
      >
        <template v-slot:titulo>¿Eliminar la promoción?</template>
        <template v-slot:message
          >¿Seguro que quieres eliminar la promoción {{ promocion.nombre }}? No podrá
          revertir los cambios.</template
        >
        <template v-slot:buttonOK>Eliminar promoción</template>
      </dialog-confirmation-close>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;

import { ValidationObserver } from "vee-validate";
import InformationDialogIcon from "@/components/dialogs/information-dialog.icon.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";
import PromocionVistaPrevia from "@/views/promocion/promocion.item.card.vue";

export default {
  name: "promocion.actualizar",
  inject: {
    $validator: "$validator",
  },
  components: {
    LayoutBasic,
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
    PromocionVistaPrevia,
    InformationDialogIcon,
    DialogConfirmationClose,
  },
  data() {
    return {
      dialogEliminar: false,
      menuFechaInicio: false,
      menuFechaFin: false,
      promociones: [],
      promocion: {
        nombre: "",
        descripcion: "",
        detalles: [],
        controlaStock: false,
        precioVenta: null,
        fechaInicioVigencia: null,
        fechaFinVigencia: null,
      },
      detallesServer: [],
      descripcionSugerida: "",
      inputSearch: null,
      eventClick: false,
      productos: [],
      productosSeleccionados: null,

      isLoading: false,
      dialogAddProducto: false,
    };
  },
  computed: {
    items() {
      return [
        {
          text: "Nueva promocion",
          icon: "mdi-clock",
          route: "nuevoPedidoVenta",
        },
        { text: "Consultar", icon: "mdi-account", route: "#" },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ];
    },
  },
  methods: {
    async eliminarPromocion() {
      try {
        let id_promocion = this.promocion.id;
        const response = await this.axios.delete(
          `/promociones/${id_promocion}`,
          this.promocion
        );

        this.$updateBus.$emit("showSnackBar", {
          show: true,
 button:false,
          color: "success",
          text: "Promocion eliminada",
          icon: "mdi-information",
          timeout: 2500,
        });
        this.$router.push("/promociones");
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
 button:false,
            color: "primary",
            text: error.response.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    async actualizarPromocion() {
      try {
        const result = await this.$refs.formPromo.validate();
        var promocion = this.promocion;
        const zone = "America/Argentina/Cordoba";
        var promo = {
          nombre: promocion.nombre,
          descripcion: promocion.descripcion,
          detalles: this.detallesServer,
          controlaStock: promocion.controlaStock,
          precioVenta: promocion.precioVenta,
          fechaInicioVigencia: promocion.fechaInicioVigencia
            ? this.moment(promocion.fechaInicioVigencia).tz(zone).format()
            : null,
          fechaFinVigencia: promocion.fechaFinVigencia
            ? this.moment(promocion.fechaFinVigencia).tz(zone).format()
            : null,
        };

        if (result) {
          if (promocion.controlaStock && promocion.detalles.length == 0) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
 button:false,
              color: "primary",
              text:
                "Debe asignar algún producto al inventario. Caso contrario desactive el control.",
              icon: "mdi-information",
              timeout: 4000,
              right: false,
              top: false,
            });
          } else {
            var id = promocion.id;
            var id_string = id.toString();

            const response = await this.axios.put(`/promociones/${id_string}`, promo);

            this.$updateBus.$emit("showSnackBar", {
              show: true,
 button:false,
              color: "success",
              text: "Promocion actualizada",
              icon: "mdi-bug",
              timeout: 2500,
            });
            this.$router.push("/promociones");
          }
        } else {
          this.$validator.validate();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
 button:false,
            color: "primary",
            text: error.response.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    sugerirDescripcion() {
      this.descripcionSugerida = this.descripcionPromoSugerida();
    },
    descripcionPromoSugerida() {
      if (this.promocion.detalles <= 0) {
        return "";
      }
      var descri = "";
      var detalles = this.promocion.detalles;

      for (let i = 0; i < detalles.length; i++) {
        var producto = detalles[i].producto;
        var cantidad = detalles[i].cantidad;

        if (i == 0) {
          descri += cantidad + " " + producto.descripcion + " ";
        } else {
          descri += "+ " + cantidad + " " + producto.descripcion;
        }
      }

      return descri;
    },
    incremetnCant(detalle) {
      let isDetalle = this.detallesServer.find(
        (d) => d.producto.id == detalle.producto.id
      );
      if (isDetalle) {
        detalle.cantidad++;
      } else {
        detalle.cantidad++;
        detalle.accion = 1;

        this.detallesServer.push(detalle);
      }

      this.sugerirDescripcion();
    },
    decrementCant(detalle) {
      var cantidad = parseInt(detalle.cantidad);
      if (cantidad == 1) {
        return;
      }
      let isDetalle = this.detallesServer.find(
        (d) => d.producto.id == detalle.producto.id
      );
      if (isDetalle) {
        detalle.cantidad--;
      } else {
        detalle.cantidad--;
        detalle.accion = 1;

        this.detallesServer.push(detalle);
      }

      this.sugerirDescripcion();
    },
    deleteItem(detalle) {
      const index = this.promocion.detalles.indexOf(detalle);

      if (index > -1) {
        detalle.accion = 0;
        this.detallesServer.push(detalle);
        this.promocion.detalles.splice(index, 1);
      }
      this.sugerirDescripcion();
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async fetchPromocion(id) {
      const response = await this.axios.get(`/promociones/${id}`);
      this.promocion = response.data;

      this.promocion.fechaInicioVigencia = response.data.fechaInicioVigencia
        ? this.moment(response.data.fechaInicioVigencia).format("YYYY-MM-DD")
        : "";
      this.promocion.fechaFinVigencia = response.data.fechaFinVigencia
        ? this.moment(response.data.fechaFinVigencia).format("YYYY-MM-DD")
        : "";
      //   this.promocion.fechaFinVigencia = response.data.fechaFinVigencia
      //     .toISOString()
      //     .substr(0, 10);
    },

    async getFromSearch(input_search) {
      try {
        this.eventClick = true;
        this.isLoading = true;

        var replaced = input_search.split(" ").join("-");
        var url = `/productos/products_search/search?q=${replaced}&page=1&page_size=10`;
        const request = await this.axios.get(url);

        this.productos = request.data.results;
        this.isLoading = false;
      } catch (error) {
        var e = error.response;
        this.isLoading = false;

        // this.mensajeBuscando = "No se encontró coincidencia";
      }
      setTimeout(() => {
        this.eventClick = false;
      }, 1000);
    },
  },
  watch: {
    productosSeleccionados(select) {
      if (select === undefined) return;

      var copy = { ...select };
      var detalle = { producto: copy, cantidad: 1 };
      this.detallesServer.push(detalle);
      this.promocion.detalles.push(detalle);
      this.sugerirDescripcion();
    },
    inputSearch(val) {
      //val -> lo que escribe en el buscador
      if (val == null || val == "" || val == " " || val == undefined || val.length < 2) {
        return;
      }
      const regex = /\S|$/;

      var cantEspacios = val.search(regex) || 0;
      if (cantEspacios > 0) {
        var cadena2 = val.slice(cantEspacios);
        this.inputSearch = cadena2;
        return;
      }

      // this.noDataSearch = val;
      // this.mensajeBuscando = "Buscando...";

      // Items have already been requested
      if (this.isLoading) return;
      if (this.borrando) return;

      if (this.eventClick) return;

      this.getFromSearch(val);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad

      // +
      if (event.keyCode === 107 && this.promocion.controlaStock) {
        this.dialogAddProducto = true;
      }
    });
  },
  created() {
    let id = this.$route.params.id;
    this.fetchPromocion(id);
  },
};
</script>

<style lang="scss" scoped></style>
