<template>
  <LayoutBasic :showLinksMenu="false">
      <template v-slot:navigation-title>Empleados  </template>
       <template v-slot:navigation-content>
          <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar empleado"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
       </template>
   
    <template v-slot:navigation-after-links>
      <v-list>
        <v-list-item @click="$router.push({ name: 'nuevoEmpleado' })">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Nuevo empleado</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="$router.push({ name: 'empleadoUsuarios' })">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
         <v-list-item @click="$router.push({ name: 'empleadosConsumo' })">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Consumos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push({ name: 'empleadosConsultar' })">
          <v-list-item-icon>
            <v-icon>mdi-account-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Deshabilitados</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-row justify="center">
        <v-col cols="6">
          <empleados-list :loading="loadingEmpleados" :empleados="empleadosFilter" @seleccion-empleado="handleSeleccionarEmpleado"></empleados-list>
        </v-col>
      </v-row>
    </template>
  </LayoutBasic>
</template>

<script>
import EmpleadoDetalle from "@/components/empleado/empleado.detalle";
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import EmpleadosList from "@/components/empleado/empleado.list.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "empleados",
  components: {
    EmpleadoDetalle,
    LayoutBasic,
    EmpleadosList,
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapState("empleado", ["empleados", "loadingEmpleados"]),

    empleadosFilter() {
      let empleados = this.empleados;

      if (this.search) {
        empleados = this.empleados.filter(
          (u) => u.nombre.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      }

      return empleados;
    },
  },
  methods: {
    ...mapActions("empleado", ["fetch_empleados"]),
    handleSeleccionarEmpleado(empleado){
       this.$router.push({
        name: 'empleadoHome',
        params: { id_empleado: empleado.id, empleado: empleado },
      });
    }
  },
  created() {
    this.fetch_empleados(true);
  },
};
</script>

<style lang="scss" scoped></style>
