<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(item, index) in cajas"
        :key="index"
        v-on="isClickable(item)"
      >
        <v-list-item-avatar>
          <v-icon dark> mdi-desktop-classic </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ item.nombre }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.ocupada"
            >Ocupada por ss {{ item.user }}
            <v-alert type="info">
              Dar a entende que esta ocupada porque existe un arqueo vigente
              para esta caja</v-alert
            >
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="menuOptions">
          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon>
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  v-for="(option, optionIndex) in menuOptions"
                  :key="optionIndex"
                  @click="handleOptionSelected(item, option)"
                >
                  <v-list-item-icon v-if="option.icon">
                    <v-icon>{{ option.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ option.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CajaList",
  props: {
    menuOptions: {
      type: Boolean,
      default: false,
      required: false,
    },
    // handleOptionSelected: {
    //   type: Function,
    //   default: () => {},
    //   required: false,
    // },
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  data() {
    return {
      cajas: [],
    };
  },
  methods: {
    isClickable(item) {
      if (
        this.user.role.includes("admin") ||
        this.user.role.includes("owner")
      ) {
        return { click: () => this.handleItemSelected(item) };
      } else {
        return {};
      }
    },
    handleItemSelected(caja) {
      // if (caja.ocupada) {
      //   alert("Esta caja esta ocupada");
      //   return;
      // }
      this.$store.commit("sucursales/SET_CAJA", caja);
      this.$store.commit("sucursales/SET_CAJA_ID_COOKIE", caja.id);

      const url = `/sucursales/arqueos/${caja.id}`;

      this.axios
        .get(url)
        .then((response) => {
          if (response.data.vigente) {
            store.commit("sucursales/SET_ARQUEO_CAJA", response.data.vigente);
            this.$router.push("/home");
          } else {
            console.log("Como no tenes arqueo, te vas a iniciarlo");
            this.$router.push("/iniciarCaja");
          }
          // else {
          //   this.$router.push({
          //     name: "arqueos",
          //     params: { id_caja: caja.id },
          //   });
          // }
        })
        .catch((error) => {
          console.error("Error al obtener arqueos:", error);
          next();
        });
    },
    fetchCajas() {
      var url = `/sucursales/cajas`;
      this.axios.get(url).then((response) => {
        this.cajas = response.data;
      });
    },
  },
  created() {
    this.fetchCajas();
  },
};
</script>

<style lang="scss" scoped>
</style>