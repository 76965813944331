import { render, staticRenderFns } from "./layout.menu.derecha.vue?vue&type=template&id=486a2878&scoped=true&"
import script from "./layout.menu.derecha.vue?vue&type=script&lang=js&"
export * from "./layout.menu.derecha.vue?vue&type=script&lang=js&"
import style0 from "./layout.menu.derecha.vue?vue&type=style&index=0&id=486a2878&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a2878",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VContainer,VFooter,VIcon,VProgressLinear,VSpacer,VToolbarTitle})
