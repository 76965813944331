<template>
  <div>
    <div class="d-flex flex-column">
      <div class="two-colors">
        <v-row justify="center">
          <v-col cols="12" lg="8" md="10">
            <div class="h-100 d-flex flex-column">
              <div class="justify-content-center">
                <v-row>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Sucursal</h4>
                      <h3>
                        <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                        {{ sucursal.direccion }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Caja</h4>
                      <h3>
                        <v-icon>mdi-desktop-classic</v-icon>
                        {{ caja.nombre }}
                      </h3>
                    </div>
                  </v-col>
                </v-row>
                <v-card shaped class="mt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <h2 class="mb-3 mt-4 text-left text-white">
                          <slot name="title"></slot>
                        </h2>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <h4 class="mb-3 mt-4 text-right text-white">
                          {{ moment().format("ll") }} {{ horaActual }}
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-n3">
                      <v-col                         cols="12" md="6" lg="6"
 align="start">
                        <slot name="left"></slot>
                      </v-col>

                      <v-col
                        style="background-color: #181818"
                        cols="12" md="6" lg="6"
                        class="d-flex flex-column"
                      >
                        <slot name="right"></slot>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapState, mapActions } from "vuex";
import { getStorage } from "@/services/serviceLocalStorage";

export default {
  name: "layoutArqueo.vue",

  data() {
    return {
      tiempoTranscurrido: "",
      fechaInicio: "2020-10-14T21:57:59.963Z",
      horaActual: "",
    };
  },

  computed: {
    sucursal() {
      return this.$store.state.sucursales.sucursal;
    },
    caja() {
      return this.$store.state.sucursales.caja;
    },
  },
  methods: {},
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });

    setInterval(() => {
      this.tiempoTranscurrido = this.moment(this.fechaInicio)
        .startOf("seconds")
        .fromNow();
    }, 1000);
    setInterval(() => {
      this.horaActual = this.moment().format("LTS");
    }, 1000);
  },
};
</script>

<style scoped>
.two-colors {
  background: #afcb08;
  background: linear-gradient(
    180deg,
    #afcb08 0,
    #afcb08 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );
}
</style>
