<template>
  <LayoutBasic :showLinksMenu="false">
    <template v-slot:title>
      <!-- <v-list class="ma-0 pa-0">
        <v-list-item class="pl-1">
          <v-list-item-title>
            <h1 style="font-size: 32px">Reportes</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list> -->
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title"> Reportes </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>
      <template v-slot:navigation-title>Reportes </template>
    <template v-slot:navigation-content>
      <v-list>
        <v-list-item
          link
          exact
          color="primary"
          :to="{ name: item.route }"
          v-for="item in items"
          :key="item.text"
        >
          <v-list-item-action>
            <v-icon :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              ><span style="font-size: 0.9375rem">{{
                item.text
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in linksProductos"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
          link
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            dense
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="{ name: subItem.route }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          v-for="item in linksVentas"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
          link
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            dense
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="{ name: subItem.route }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
export default {
  name: "ReportesHome",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      expandOnHover: true,
      model2: 1,
      model: 1,
      linksVentas: [
        {
          action: "mdi-format-list-checks",
          title: "Ventas",
          items: [
            { title: "Métricas", route: "reportesVentasPeriodo" },
            { title: "Por mes", route: "reportesVentasPorMes" },
            { title: "Anuladas", route: "reportesVentasAnuladas" },
          ],
        },
      ],
      linksProductos: [
        {
          action: "mdi-format-list-checks",
          title: "Productos",
          items: [
            { title: "Generar lista de precios", route: "reportesProductos" },
            { title: "Generar planilla de gondola", route: "reportePlanillaGondola" },

          ],
        },
      ],
    };
  },
  computed: {
    items() {
      return [
          {
          text: "Informe mensual",
          icon: "mdi-home-analytics",
          route: "reporteInformeMensual",
        },
        {
          text: "Resumen por día",
          icon: "mdi-home-analytics",
          route: "reporteArqueo",
        },

        {
          text: "Compras",
          icon: "mdi-chart-box-outline",
          route: "reportesCompras",
        },
        { text: "Egresos/Igresos", icon: "mdi-flag", route: "reporteEgresoIngreso" },
       
      ];
    },
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
  },
};
</script>

<style scoped>
.items3 {
  display: block;
  right: 0px;
  height: 100%;
}
.items3.detalle {
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
