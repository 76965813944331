<template>
  <div class="items3">
    <v-snackbar color="error" v-model="error_detalle_repetido.state" multi-line>{{
      error_detalle_repetido.message
    }}</v-snackbar>
    <v-list color="transparent" class="detalle" dense>
      <!-- <v-list-item-group v-model="detalle"> -->
      <v-subheader>Detalles del pedido</v-subheader>
      <v-list-item v-for="(detalle, index) in detalles" :key="index">
        <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="detalle.isProducto || detalle.producto">{{
            detalle.producto.descripcion
          }}</v-list-item-title>
          <v-list-item-title v-else
            >{{ detalle.promocion.nombre }} -{{
              detalle.promocion.descripcion
            }}</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon class="align-center">
          <div style="font-size: 20px" v-if="detalle.producto">
            {{ Number(detalle.producto.precioVenta * detalle.cantidad) | convertPesoArg }}
          </div>
          <div v-else style="font-size: 20px">
            {{
              Number(detalle.promocion.precioVenta * detalle.cantidad) | convertPesoArg
            }}
          </div>
        </v-list-item-icon>
        <v-list-item-icon class="align-center">
          <pedido-detalle-buttons
            :detalle="detalle"
            @process-increment="incremetnCant(detalle)"
            @process-decrement="decrementCant(detalle)"
            @process-delete="eliminarDetalle(detalle)"
          >
          </pedido-detalle-buttons>
        </v-list-item-icon>
      </v-list-item>

      <!-- </v-list-item-group> -->
      <v-dialog v-if="dialog_detalle" v-model="dialog_detalle" width="500">
        <detalle-pedido-form
          @close-dialog="dialog_detalle = false"
          @process-detalle="modificarDetalle"
          :detalle="detalle_update"
        ></detalle-pedido-form>
      </v-dialog>
    </v-list>
    <!-- <v-list class="align-end">
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>Cantidad artículos</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Subtotal</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h3>{{ Number(subtotal()) | convertPesoArg }}</h3>
        </v-list-item-icon>
      </v-list-item>
      
    </v-list> -->
  </div>
</template>

<script>
import {
  quitDetalle,
  updateDetalle,
  addDetalle,
} from "@/services/detallesComprobante/serviceDetalles";

import { mapMutations, mapState } from "vuex";
import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";
import ItemDetallePedido from "@/components/pedido/ItemDetallePedido";
import PedidoDetalleButtons from "../pedido.detalle.buttons.vue";

export default {
  name: "pedido.detalle.registrar",

  components: {
    DetallePedidoForm,
    ItemDetallePedido,
    PedidoDetalleButtons,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      dialog_detalle: false,
      detalle: null,
      detalle_update: {},
      height: "100%",
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales",["configuracion"]),

    
  },
  methods: {
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),
    emitSubTotal() {
      //Lo invoca el padre
      this.$emit("process-sub-total", this.subtotal());
    },
    incremetnCant(detalle) {
      if (detalle.producto){

      if (this.configuracion.productoInventario && detalle.producto.controlaStock) {
        if (detalle.producto.cantidadstock > detalle.cantidad) {
          // detalle.producto.cantidadstock--; //Disminuye el stock al agregar un producto
          if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
            detalle.cantidad = parseFloat(detalle.cantidad) + 0.5;
          } else {
            detalle.cantidad++;
          }
        }
      } else {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          detalle.cantidad = parseFloat(detalle.cantidad) + 0.5;
        } else {
          detalle.cantidad++;
        }
      }
      }
      else{
         detalle.cantidad++;
      }
    },
    decrementCant(detalle) {
      var cantidad = parseFloat(detalle.cantidad);
      if (detalle.producto) {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          if (cantidad == 0.5) {
            return;
          }
          detalle.cantidad = detalle.cantidad - 0.5;
          return;
        } else {
          if (cantidad == 1) {
            return;
          }

          detalle.cantidad--;
        }
      } else {
        if (cantidad == 1) {
          return;
        }

        detalle.cantidad--;
      }
    },

    eliminarDetalle(detalle) {
      quitDetalle(this.detalles, detalle);
    },
    modificarDetalle(detalle) {
      this.dialog_detalle = false;
      if (this.configuracion.productoInventario && detalle.producto.controlaStock) {
        detalle.producto.cantidadstock -= parseInt(detalle.cantidad);
      }

      updateDetalle(this.detalles, detalle);
    },

    subtotal() {
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          var precio =
            this.detalles[index].cantidad * this.detalles[index].producto.precioVenta;
          subtotal += precio;
        } else {
          var precio =
            this.detalles[index].cantidad * this.detalles[index].promocion.precioVenta;
          subtotal += precio;
        }
      }
      return subtotal;
    },

    setDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
  },
  watch: {
    dialog_detalle: function (val) {
      if (val == false) (this.detalle = null), (this.detalle_update = {});
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
      event.preventDefault();

      //escape
      if (event.key === "Escape") {
        this.detalle = null;
      }
      //suprimir
      //Elimina el detalle si esta seleccionado y el dialog para agregar detalle esta desactivado
      if (event.keyCode === 46 && this.dialog_detalle == false) {
        if (this.detalle !== null) {
          event.preventDefault();

          this.eliminarDetalle(this.detalles[this.detalle]);
          this.detalle = null;
        }
      }

      // //Flecha hacia abajo
      // if (event.keyCode === 40 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = 0;
      //   } else if (this.detalle == this.detalles.length - 1) {
      //     this.detalle = 0;
      //   } else {
      //     this.detalle = this.detalle + 1;
      //   }
      // }

      // //Flecha hacia arriba
      // if (event.keyCode === 38 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = this.detalles.length - 1;
      //   } else if (this.detalle == 0) {
      //     this.detalle = this.detalles.length - 1;
      //   } else {
      //     this.detalle = this.detalle - 1;
      //   }
      // }

      //ENTER - Abre el modal cuando esta seleccionado algun elemento de la lista (detalle.length > 0)
      if (event.keyCode === 13) {
        if (this.detalles.length > 0) {
          if (this.detalle !== null && this.dialog_detalle == false) {
            this.setDetalle(this.detalles[this.detalle]);
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.v-list.detalle {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
