var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Control de stock")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',[_c('h1',[_vm._v("Nuevo control")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Último control")])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.moment().format("lll"))+" ")])],1)],1)],1),_c('productos-filtros-tag-card',{attrs:{"removeTagCategoria":false,"closeTag":false}}),_c('v-row',[_c('v-col',[_c('v-slide-group',{attrs:{"mandatory":"","show-arrows":""}},_vm._l((_vm.tags),function(tag,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":""},on:{"change":function($event){return _vm.change(tag)},"click":function($event){toggle(tag), _vm.change(tag)}}},[_vm._v(" "+_vm._s(tag.nombre)+" ")])]}}],null,true)})}),1)],1)],1)],1)],1),(!_vm.loading)?_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.productos,"hide-default-footer":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" "+_vm._s(props.header.text)+" ")]),_c('span',[_vm._v(" "+_vm._s(props.header.text)+" ")])])]}},{key:"item.cantidadstock",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":!item.controlaStock},model:{value:(item.cantidadstock),callback:function ($$v) {_vm.$set(item, "cantidadstock", $$v)},expression:"item.cantidadstock"}})]}},{key:"item.gestion",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{model:{value:(item.controlaStock),callback:function ($$v) {_vm.$set(item, "controlaStock", $$v)},expression:"item.controlaStock"}})]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(" "+_vm._s(item.cantidadstock)+" ")]),_vm._v(" asf ")]}},{key:"item.stockmin",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":!item.controlaStock},model:{value:(item.stockmin),callback:function ($$v) {_vm.$set(item, "stockmin", $$v)},expression:"item.stockmin"}})]}},{key:"item.stockmax",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":!item.controlaStock},model:{value:(item.stockmax),callback:function ($$v) {_vm.$set(item, "stockmax", $$v)},expression:"item.stockmax"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.actualizar_inventario(item)}}},[_vm._v("Guardar")])]}}],null,true)}),(_vm.productos.length)?_c('v-pagination',{attrs:{"length":_vm.pages,"value":_vm.paginacion.page,"circle":""},on:{"input":_vm.paginationChangeHandler}}):_vm._e()],1):[_c('v-skeleton-loader',{attrs:{"max-height":"600","type":"table-heading"}}),_c('v-skeleton-loader',{attrs:{"max-height":"1800px","type":"table-tbody"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }