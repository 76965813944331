<template>
  <v-row justify="space-around">
    <v-col>
      <v-dialog v-model="dialogProductos" width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            block
            color="yellow"
            class="black--text title"
            v-bind="attrs"
            v-on="on"
          >
            Productos
          </v-btn>
        </template>

        <v-card>
          <v-container>
            <agregar-detalle-por-categoria
              :detalles="detalles"
              :isUpdate="isUpdate"
            ></agregar-detalle-por-categoria>
          </v-container>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col>
      <v-dialog v-model="dialogPromocion" width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            block
            color="red"
            class="black--text title"
            v-bind="attrs"
            v-on="on"
          >
            Promociones
          </v-btn>
        </template>

        <v-card>
          <v-container>
            <v-card-text>
              <agregar-detalle-por-promocion
                v-if="dialogPromocion"
                :isUpdate="isUpdate"
                :detalles="detalles"
              ></agregar-detalle-por-promocion>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col>
      <v-dialog v-model="dialogVianda" width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            block
            color="purple"
            class="black--text title"
            v-bind="attrs"
            v-on="on"
          >
            Vianda
          </v-btn>
        </template>

        <v-card>
          <v-container>
            <v-card-title><span class="display-1">Viandas</span></v-card-title>
            <v-card-text>
              <agregar-detalle-por-vianda
                v-if="dialogVianda"
                :isUpdate="isUpdate"
                :detalles="detalles"
              ></agregar-detalle-por-vianda>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import AgregarDetallePorCategoria from "@/components/comprobante/detalle/comprobante.agregar.detalle.por.categoria.vue";
import AgregarDetallePorPromocion from "@/components/comprobante/detalle/comprobante.agregar.detalle.por.promocion.vue";
import AgregarDetallePorVianda from "@/components/comprobante/detalle/comprobante.agregar.detalle.por.vianda.vue";

export default {
  name: "venta.menu.buttons",
  components: {
    AgregarDetallePorVianda,
    AgregarDetallePorCategoria,
    AgregarDetallePorPromocion,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVianda: false,
      dialogPromocion: false,
      dialogProductos: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
