<template>
  <v-autocomplete
    class="mr-1 pa-0"
    :items="promociones"
    append-icon
    filled
    rounded
    ref="searchCmp"
    :loading="isLoading"
    :search-input.sync="inputSearch"
    hide-no-data
    persistent-hint
    clearable
    @click:clear="clearData()"
    item-text="descripcion"
    item-value="descripcion"
    height="28"
    label="Buscar por nombre"
    id="inputSearchNombre"
    v-validate="'required|integer|max:10'"
    :error-messages="errors.collect('inputSearch')"
    data-vv-name="nombre"
    cache-items
    @focus.native="$event.target.select()"
  ></v-autocomplete>
</template>

<script>
export default {
  // Puede recibir como prop cleanInputSearch para limpiar lo que este escrito
  // Emito @resultadoBusqueda="setpromocionBuscado" cuando se realiza la busqued a la api

  name: "BuscarpromocionesNombre",
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      cantidad: null,
      isLoading: false,

      promociones: [],
      inputSearch: null,
      haypromociones: false,
    };
  },

  methods: {
    focus() {
      this.$refs.searchCmp.focus();
    },
    clearData() {
      this.$emit("clean-buscador");
    },
    cleanInputSearch() {
      this.inputSearch = null;
      this.$refs.searchCmp.focus();
    },

    getFromSearch(val) {
      this.$emit("loading-buscar-promocion", true);
      // this.$emit("loading-buscar-promocion", false);

      // Si ingresa cadena vacia, retorna falso
      if (val == "") {
        this.detalle_pedido.promocion = null;
        return false;
      }
      if (val) {
        let url = `/promociones?search=${val}`;

        // El servidor devulve un único promocion, o devuelve array vacio
        this.axios
          .get(url)
          .then((request) => {
            request.data.length > 0
              ? (this.haypromociones = true)
              : (this.haypromociones = false);
            this.$emit("loading-buscar-promocion", false);

            this.$emit("resultado-buscar-promocion", request.data);

            this.isLoading = false;
          })
          .catch((e) => {
            this.$emit("loading-buscar-promocion", false);

            this.isLoading = false;
          });
      } else {
        if (this.haypromociones) {
          this.clearData();
        }
      }
    },
  },
  watch: {
    inputSearch(data) {
      if (data === null || data === undefined)
        return this.$emit("loading-buscar-promocion", false);
      if (data.length > 50) return (this.inputSearch = "");
      if (data == "" || data.length > 8)
        return (this.isLoading = false), this.$emit("loading-buscar-promocion", false);

      if (Number.isInteger) {
        this.isLoading = true;

        this.getFromSearch(data);
      } else {
        return (this.promociones = []);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
