<template>
  <layout-two-column>
    <template v-slot:title>
      <v-list>
        <v-list-item>
          <v-list-item-avatar class="ma-1">
            <v-btn @click="$router.go(-1)" icon>
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content>

            <v-list-item-title>
              <h1 style="font-size: 35px">Empleados </h1>
            </v-list-item-title>
            <v-list-item-subtitle>
              <h1 style="font-size: 20px">Deshabilitados</h1>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar empleado"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
   
    </template>
    <template v-slot:vista-previa>
      <v-row justify="center">
        <v-col cols="6">
          <empleados-list :loading="loadingEmpleados" :empleados="empleadosFilter"></empleados-list>
        </v-col>
      </v-row>
    </template>
  </layout-two-column>
</template>

<script>
import EmpleadoDetalle from "@/components/empleado/empleado.detalle";
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
import EmpleadosList from "@/components/empleado/empleado.list.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "empleados",
  components: {
    EmpleadoDetalle,
    LayoutTwoColumn,
    EmpleadosList,
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapState("empleado", ["empleados", "loadingEmpleados"]),

    empleadosFilter() {
      let empleados = this.empleados;

      if (this.search) {
        empleados = this.empleados.filter(
          (u) => u.nombre.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      }

      return empleados;
    },
  },
  methods: {
    ...mapActions("empleado", ["fetch_empleados"]),
  },
  created() {
    this.fetch_empleados(false);
  },
};
</script>

<style lang="scss" scoped></style>
