<template>
  <v-container fluid>
    <template v-if="productos.length > 0">
      <v-row>
        <v-col cols="12" md="4" v-for="producto in productos" :key="producto.id">
         
            <v-card hover height="100%">
              <v-container>
             

                <!-- <v-card-title v-if="producto.esPedidoYa" class="ma-0 mt-0 pa-0">
              <v-spacer></v-spacer>
              <v-img
                src="@/assets/pedidosya/pedidoya.png"
                max-height="20"
                max-width="20"
                contain
              ></v-img>
            </v-card-title> -->
               
                <v-card-text>
                  <div style="font-size: 25px">
                    {{ producto.precioCosto | convertPesoArg }}
                  </div>
                  
                
                </v-card-text>

                <v-card-text>
                  <v-row justify="center" align="center">
                    <div>
                      <h2>{{ producto.descripcion }}</h2>
                    </div>
                  </v-row>
                </v-card-text>
              </v-container>
            </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-pagination
            v-if="productos"
            @input="paginationChangeHandler"
            :length="pages"
            :value="paginacionProducto.page"
            circle
          ></v-pagination>
        </v-col>
      </v-row> -->
    </template>
    <template v-else>
      <v-img contain height="200" src="@/assets/empty_icons/null_pedidos.svg"></v-img>
      <div class="text-center title" style="line-height: 1.6555">
        No se han encontraron resultados.
      </div>
      <div class="text-center">
        <li>Revisá los filtros aplicados.</li>
      </div>
    </template>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "proveedor-lista-de-precio-producto-list",
  components: {},
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    productos: {
      type: Array,
      required: true,
      default: () => [],
    },
    paginacion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
      paginacionProducto: this.paginacion,
    };
  },
  computed: {
  
    ...mapState("sucursales",["configuracion"]),
  

    // pages() {
    //   if (
    //     this.paginacionProducto.rowsPerPage == null ||
    //     this.paginacionProducto.cantidad < 0
    //   ) {
    //     return 0;
    //   }

    //   return Math.ceil(
    //     this.paginacionProducto.cantidad / this.paginacionProducto.rowsPerPage
    //   );
    // },
  },
  methods: {
    actualizarProductos(selected) {
      this.$emit("actualizar-producto", [selected]);
      // }
    },
    calcularPrecioPY(producto) {
      return Math.round(
        producto.precioVenta + (producto.precioVenta * producto.porcentajePY) / 100
      );
    },
    ...mapMutations("buscador", ["setPage"]),
    paginationChangeHandler(pageNumber) {
      if (this.paginacionProducto.page == pageNumber) {
        return false;
      } else {
        this.$emit("set-page", pageNumber);
      }
    },
  },
};
</script>
