<template>
  <v-list>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Cantidad artículos</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
    </v-list-item>
    <v-list-item dense v-if="showSubTotal">
      <v-list-item-content>
        <v-list-item-title><b>Base imponible</b></v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h4>{{ subtotal() | convertPesoArg }}</h4>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Impuestos</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h4>{{ impuestos() | convertPesoArg }}</h4>
      </v-list-item-icon>
    </v-list-item>

    <v-list-item dense v-if="showSubTotal">
      <v-list-item-content>
        <v-list-item-title><h3>Total</h3></v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <h3>{{ total() | convertPesoArg }}</h3>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "FacturaVentaSubtotal",
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    showSubTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
    tipoRedondeo: {
      type: String,
      required: true,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
  methods: {
    ...mapMutations("ventas", ["SET_SUBTOTAL", "SET_IMPUESTOS"]),
    subtotal() {
      let subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          let precioBase = parseFloat(
            this.detalles[index].producto.precioBase.toFixed(4)
          ); // Redondear a 4 decimales

          // Convertir 'cantidad' a número flotante y redondear a 2 decimales
          let cantidad = parseFloat(this.detalles[index].cantidad);
          cantidad = parseFloat(cantidad.toFixed(2)); // Redondear a 2 decimales
          let precioCalculado = parseFloat((cantidad * precioBase).toFixed(2)); // Redondear a 4 decimales
          subtotal += precioCalculado;
        }
      }

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        subtotal = Math.round(subtotal); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        subtotal = parseFloat(subtotal.toFixed(2)); // Redondea a dos decimales
      }

      this.SET_SUBTOTAL(subtotal);
      return subtotal;
    },
    getIvaValue(vat_type) {
      const iva_description = vat_type.description || ""; // Asegura que sea un string
      let iva = 0; // Inicializa IVA en 0 por defecto

      if (iva_description.toLowerCase() === "exento") {
        iva = 0;
      } else {
        const parsedIva = parseFloat(iva_description);
        iva = isNaN(parsedIva) ? 0 : parsedIva; // Si no es un número válido, asigna 0
      }
      return iva;
    },
    impuestos() {
      let totalImpuestos = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          let precioBase = parseFloat(
            this.detalles[index].producto.precioBase.toFixed(4)
          ); // Redondear a 4 decimales
          let cantidad = parseFloat(this.detalles[index].cantidad);
          cantidad = parseFloat(cantidad.toFixed(2)); // Redondear a 2 decimales
          let iva = this.getIvaValue(this.detalles[index].producto.iva);

          let impuestoUnitario = parseFloat(
            (precioBase * (iva / 100)).toFixed(2)
          ); // Redondear a 4 decimales
          totalImpuestos += parseFloat(
            (impuestoUnitario * cantidad).toFixed(2)
          ); // Redondear a 4 decimales
        }
      }

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        totalImpuestos = Math.round(totalImpuestos); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        totalImpuestos = parseFloat(totalImpuestos.toFixed(2)); // Redondea a dos decimales
      }

      this.SET_IMPUESTOS(totalImpuestos);
      return totalImpuestos;
    },
    total() {
      let total = this.subtotal() + this.impuestos();

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        total = Math.round(total); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        total = parseFloat(total.toFixed(2)); // Redondea a dos decimales
      }

      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
