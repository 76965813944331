<template>
  <span>
    <v-navigation-drawer width="350" color="#1E1E1E" dark app flat permanent>
      <template v-slot:prepend>
        <v-container>
          <div>
            <v-btn style="background-color: #4e4f50" large icon @click="goBack">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
            <a @click="$router.push('/home')" class="siteLogo mt-2"></a>
          </div>
          <div class="mt-6 pa-0">
            <div class="estilo-subheader">
              <slot name="up-title">Compras</slot>
            </div>

            <h1 style="font-size: 35px">
              <slot name="title">Pedidos</slot>
            </h1>

            <slot name="isBorrador"> </slot>

            <slot name="subtitle"></slot>
          </div>
        </v-container>
      </template>
      <slot name="navigation-controller"></slot>
    </v-navigation-drawer>

    <v-navigation-drawer
      right
      color="#1E1E1E"
      width="400"
      dark
      floating
      app
      permanent
    >
      <template v-slot:prepend>
        <slot name="cliente"></slot>
      </template>
      <slot name="detalle-pedido"></slot>
      <template v-slot:append>
        <div class="pa-2">
          <slot name="button-navigation-controller"></slot>
        </div>
      </template>
    </v-navigation-drawer>

    <slot name="vista-previa"></slot>

    <!-- <v-footer app>
      <v-row justify="start" no-gutters>
        <v-col class="text-start white--text" cols="12" md="2" lg="2">
          <span style="font-size: 17px"><strong>Atajos</strong></span>
        </v-col>
        <v-col class="text-start white--text" cols="12" md="2" lg="2">
          <span style="font-size: 17px">(+) <i>Buscar producto</i></span>
        </v-col>
        <v-col class="text-start white--text" cols="12" md="2" lg="2">
          <span style="font-size: 17px">(F4) <i>Cobrar</i></span>
        </v-col>
        <v-col class="text-start white--text" cols="12" md="2" lg="2">
          <span style="font-size: 17px">(F9) <i>Enviar pedido</i></span>
        </v-col>
      </v-row>
    </v-footer> -->
    <!-- <v-row class="d-none d-md-block d-lg-block"> </v-row> -->
  </span>
</template>

<script>
import EmpresaVistaPrevia from "@/views/empresa/empresa.vista-previa.vue";

export default {
  name: "layout.registrar",
  components: {
    EmpresaVistaPrevia,
  },
  props: {
    widthNavigation: {
      type: String,
      default: "350",
    },
  },

  data() {
    return {
      dialog: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Account", icon: "mdi-account-box" },
        { title: "Admin", icon: "mdi-gavel" },
      ],
    };
  },

  methods: {
    goToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).catch((e) => {});
      }
    },
    goBack() {
      var path = "";
      if (
        this.$route.name === "pedidoVenta" ||
        this.$route.name === "pedidoCompra"
      ) {
        path = this.$route.name === "pedidoVenta" ? "ventas" : "compras";
        this.$router.push({ name: path });
      } else if (
        this.$route.name === "nuevoPedidoVenta" ||
        this.$route.name === "nuevoPedidoCompra"
      ) {
        path =
          this.$route.name === "nuevoPedidoVenta"
            ? "pedidoVenta"
            : "pedidoCompra";
        this.$router.push({ name: path });
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {
    if (true) {
      this.$store.commit("SET_LAYOUT", {
        layout: "layout-basic",
        withNav: false,
      });
    }
  },
};
</script>

<style scoped>
.estilo-subheader {
  word-break: break-word;
  color: #b0b3b8;
  font-size: 0.9125rem;
}
.siteLogo {
  position: absolute;
  top: 0;
  left: 30px;
  margin-left: 30px;
  background-image: url("../assets/bear-face-icon-2.png"), none;
  background-size: 60px;
  /* filter: brightness(1.1);
  mix-blend-mode: multiply; */
  width: 113px;
  height: 80px;
}

.items3 {
  right: 0px;
  height: 100vh;
}

.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  background: #869;
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
.items3.detalle {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
