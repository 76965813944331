<template>
  <v-card>
    <v-container>
      <v-card-title
        >Reestablecer contraseña <v-spacer></v-spacer>
        <v-btn @click="$emit('close-dialog')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form @submit.prevent="reestablecerContraseña()">
        <v-text-field
        outlined
          v-validate="'required|min:8|max:25'"
          :error-messages="errors.collect('contraseña')"
          data-vv-name="contraseña"
          label="Nueva contraseña"
          prepend-icon="mdi-lock"
          v-model="password.new_password1"
          name="contraseña"
          type="password"
        />

        <v-text-field
        outlined

          v-validate="'required|min:8|max:25'"
          :error-messages="errors.collect('ConfirmarContraseña')"
          data-vv-name="ConfirmarContraseña"
          :rules="[confirmationPassword]"
          prepend-icon="mdi-lock-check"
          v-model="password.new_password2"
          name="password_confirmation"
          type="password"
          label="Confirmar contraseña"
        />
        <v-btn block color="green" type="submit">Reestablecer</v-btn>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "usuario.reestablecer.contraseña",
  inject: {
    $validator: "$validator",
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      password: {
        new_password1: null,
        new_password2: null,
      },

      disabledPassword: false,
    };
  },
  methods: {
    reestablecerContraseña() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = this.password;
          data.user = this.user.id;
          this.axios
            .put("/usuarios/resetpassword", data)
            .then((response) => {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "success",
                text: "Contraseña reestablecida",
                icon: "mdi-check",
                timeout: 3000,
              });
              this.$emit("close-dialog");
            })
            .catch((e) => {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "info",
                text: e.response.data.non_field_errors[0],
                icon: "mdi-information",
                timeout: 3000,
              });
            });
          return;
        }
      });
    },

    confirmationPassword(value) {
      if (value !== this.password.new_password1) {
        this.disabledPassword = true;
        return "Las contraseñas no coinciden";
      } else {
        this.disabledPassword = false;
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
