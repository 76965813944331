<template>
  <layout-remito-registrar>
    <template v-slot:up-title>Productos > Registrar > Múltiple</template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <h1 style="font-size: 30px">Registrar productos</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>

    <template v-slot:navigation-controller="{ reload }">
      <v-container>
        <template v-if="step == 1" name="step-1">
          <form-producto-es-elaboracion-propia
            :outlined="true"
            :producto="producto"
            @set-elab-prop="setElabProp"
          >
          </form-producto-es-elaboracion-propia>
          <form-producto-proveedor
            :outlined="true"
            :producto="producto"
            @set_proveedor="setProveedor"
          >
          </form-producto-proveedor>

          <keep-alive>
            <form-producto-marca
              :outlined="true"
              :clearable="true"
              @clearable="setMarca(null)"
              @set_marca="setMarca"
              :producto="producto"
            >
            </form-producto-marca>
          </keep-alive>
          <keep-alive>
            <categoria-select
              :outlined="true"
              :categoria="producto.categoriaArbol"
              @process-categoria="setCategoria"
            ></categoria-select>
          </keep-alive>
        </template>
        <template v-if="step == 2">
          <div>
            <div class="mt-2">
              <b>Nombre del producto</b>
            </div>
            <div style="font-size: 14px">Ingrese el nombre del producto.</div>
            <div>
              <form-producto-descripcion :producto="producto">
              </form-producto-descripcion>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Código de barra</b>
            </div>
            <div style="font-size: 14px">
              Escanea el código de barra del producto o escríbelo manualmente.
            </div>
            <div class="mt-1">
              <form-producto-barcode
                :producto="producto"
              ></form-producto-barcode>
            </div>
          </div>
          <div name="Precio de costo">
            <div>
              <b>Precio de costo</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el precio de costo o compra del producto.
            </div>
            <div class="mt-1">
              <form-producto-precio-costo
                :producto="producto"
                :outlined="true"
              ></form-producto-precio-costo>
            </div>
          </div>
          <div name="Ganancia">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Ganancia del producto</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el procentaje de ganancia que tendrá el producto.
            </div>
            <div class="mt-1">
              <form-producto-ganancia :producto="producto" :outlined="true">
              </form-producto-ganancia>
            </div>
          </div>
          <div name="Flete">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Flete</b>
            </div>
            <div style="font-size: 14px">
              Ingresa un porcentaje de costo extra que lleva el producto.
            </div>
            <div class="mt-1">
              <form-producto-flete
                :producto="producto"
                :outlined="true"
              ></form-producto-flete>
            </div>
          </div>

                      <div class="mt-1">
              <b>IVA</b>
            </div>
            <div style="font-size: 14px">
              Impuesto sobre el valor añadido
            </div>
           <div class="mt-1">
         <AlicuotaIvaSelect
            class="mt-1 mb-1"
            :iva="producto.iva"
            @update:iva="setIva(producto, $event)"
          ></AlicuotaIvaSelect>
           </div>
          <form-producto-precio-venta
            :producto="producto"
            :outlined="true"
          ></form-producto-precio-venta>
          <v-btn
            v-show="step == 2"
            large
            class="mb-2"
            color="primary"
            :loading="loadingAgregar"
            @click="
              agregarProducto(producto);
              reload();
            "
            block
            ><b style="font-size: 17px">Añadir</b>
          </v-btn>
        </template>
        <template name="step-3" v-if="step == 3"> </template>
      </v-container>
    </template>
    <template v-slot:navigation-stepper>
      <v-row>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            value="100"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 1 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 2 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </template>
    <template v-slot:navigation-append="{ reload }">
      <v-btn
        v-show="step == 1"
        large
        class="mb-2"
        color="primary"
        :disabled="errors.any()"
        @click="
          validateForm(2);
          reload();
        "
        block
        ><b style="font-size: 17px">Siguiente</b>
      </v-btn>
      <v-row v-show="step == 2">
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            @click="nextStep(1)"
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            color="primary"
            @click="validateForm(3)"
            block
            ><b style="font-size: 17px">Siguiente</b></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="step == 3">
        <v-col>
          <v-btn
            large
            v-show="step == 3"
            class="mb-2"
            @click="nextStep(2)"
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            v-show="step == 3"
            large
            class="mb-2"
            color="success"
            :loading="loadingButtonRegister"
            :disabled="errors.any()"
            @click="validateForm()"
            block
            ><b style="font-size: 17px">Registrar</b>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-slot:vista-previa>
      <v-row>
        <v-col>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-card class="d-flex align-center">
                  <v-container class="text-center">
                    <div>
                      <v-icon color="blue" large
                        >{{
                          producto_registrar_multiple.proveedor
                            ? "mdi-clipboard-account"
                            : "mdi-account-question"
                        }}
                      </v-icon>
                    </div>
                    <div v-if="producto_registrar_multiple.proveedor">
                      <h3>
                        {{ producto_registrar_multiple.proveedor.nombre }}
                      </h3>
                    </div>
                    <div v-else><h3>Selecciona un proveedor</h3></div>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="4" v-if="producto_registrar_multiple.es_marca_unica">
                <v-card class="d-flex align-center">
                  <v-container class="text-center">
                    <div>
                      <v-icon color="blue" large
                        >{{
                          producto_registrar_multiple.marca
                            ? "mdi-rhombus"
                            : "mdi-help-rhombus"
                        }}
                      </v-icon>
                    </div>
                    <div v-if="producto_registrar_multiple.marca">
                      <h3>{{ producto_registrar_multiple.marca.nombre }}</h3>
                    </div>
                    <div v-else><h3>Sin marca</h3></div>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="d-flex align-center">
                  <v-container class="text-center">
                    <div>
                      <v-icon color="blue" large>mdi-sitemap </v-icon>
                    </div>
                    <div v-if="producto_registrar_multiple.categoria.id">
                      <h3>{{ producto_registrar_multiple.categoria.name }}</h3>
                    </div>
                    <div v-else><h3>Sin categoría</h3></div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <producto-lista-registro
                  @process-update="setProducto"
                  :marca="marca"
                ></producto-lista-registro>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </template>
  </layout-remito-registrar>
</template>

<script>
import LayoutRemitoRegistrar from "@/layouts/productos/layout.registrar.producto";

import ProveedorSelect from "@/components/proveedor/FormSelectProveedor.vue";
import MarcaSelect from "@/components/marcas/FormSelectMarca.vue";
import CategoriaSelect from "@/components/categorias/consultar/categoria.select.vue";
import ProductoListaRegistro from "@/components/producto/registrar/ProductoListaRegistro";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";
import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";

import FormProductoProveedor from "@/components/producto/form/form.producto.proveedor.vue";
import FormProductoCategoria from "@/components/producto/form/form.producto.categoria.vue";
import FormProductoMarca from "@/components/producto/form/form.producto.marca.vue";
import FormProductoEsElaboracionPropia from "@/components/producto/form/FormProductoEsElaboracionPropia.vue";

import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "ProductoHomeRegistrarMultiple",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    FormProductoProveedor,
    FormProductoCategoria,
    FormProductoMarca,
    FormProductoDescripcion,
    FormProductoBarcode,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoPrecioVenta,
    FormProductoEsElaboracionPropia,
    ProveedorSelect,
    MarcaSelect,
    CategoriaSelect,
    ProductoListaRegistro,
    LayoutRemitoRegistrar,
  },
  data() {
    return {
      step: 1,
      marca: null,
      categoria: null,
      proveedor: null,
      loadingButtonRegister: false,
      loadingAgregar: false,

      producto: {
        descripcion: "",
        precioCosto: null,
        codigoDeBarra: null,
        ganancia: null,
        marca: { id: null },
        proveedor: null,
        categoriaArbol: { id: null },
        stockmin: 0,
        stockmax: 0,
        flete: 0,
        iva: 0,
        esPedidoYa: false,
        porcentajePY: 0,
        es_elaboracion_propia: false,
      },
    };
  },
  computed: {
    ...mapState("productos", ["producto_registrar_multiple"]),
  },

  methods: {
    ...mapActions("productos", ["registrar_productos_multiple"]),
    ...mapMutations("productos", [
      "SET_MARCA_PRODUCTO_REGISTRA_MULTIPLE",
      "SET_PROV_PRODUCTO_REGISTRAR_MULTIPLE",
      "SET_CAT_PRODUCTO_REGISTRAR_MULTIPLE",
      "SET_PROD_PRODUCTO_REGISTRAR_MULTIPLE",
      "SET_MARCA_UNICA_PROD_REG_MUL",
      "SET_ELAB_PROP_PRODUCTO_REGISTRAR_MULTIPLE",
      "RESET_PRODUCTO_REGISTRAR_MULTIPLE",
    ]),
    registrarProductosMultiple() {
      this.loadingButtonRegister = true;
      this.registrar_productos_multiple()
        .then((response) => {
          this.resetProducto();
          this.step = 1;
          this.loadingButtonRegister = false;
          this.$router.push({ name: "nuevoProductoMultiple" });
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Productos registrados",
            icon: "mdi-check",
            timeout: 3000,
          });
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
          console.log(e);
          this.loadingButtonRegister = false;
        });
    },
    setProducto(producto) {
      this.producto = producto;
    },
      setIva(producto, vatType) {
      producto.iva = vatType;
    },
    agregarProducto(producto) {
      console.log(producto);
      this.loadingAgregar = true;
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          var precioVenta = Math.round(
            this.producto.precioCosto *
              (1 + parseFloat(this.producto.ganancia) / 100) *
              (1 + parseFloat(this.producto.flete) / 100) *
              (1 + parseFloat(this.producto.iva) / 100)
          );
          producto.precioVenta = precioVenta;

          this.SET_PROD_PRODUCTO_REGISTRAR_MULTIPLE(producto);
          this.resetProducto();
          this.$validator.reset();
          this.loadingAgregar = false;
        } else {
          this.loadingAgregar = false;
          this.$validator.validate();
        }
      });
      // this.producto = {
      //   descripcion: "",
      //   precioCosto: null,
      //   precioVenta: null,
      //   ganancia: 0,
      //   marca_id: null,
      //   flete: 0,
      //   iva: 0,
      // };
    },
    resetProducto() {
      this.producto = {
        descripcion: "",
        codigoDeBarra: null,
        precioCosto: null,
        ganancia: null,
        marca: { id: null },
        proveedor: { id: null },
        categoriaArbol: { id: null },
        stockmin: 0,
        stockmax: 0,
        flete: 0,
        iva: 0,
      };
    },
    nextStep(step) {
      this.step = step;
    },
    validateForm(nextStep) {
      if (
        nextStep == 3 &&
        this.producto_registrar_multiple.productos.length > 0
      ) {
        this.nextStep(nextStep);
      } else {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            if (nextStep) {
              this.nextStep(nextStep);
            } else {
              this.registrarProductosMultiple();
            }
          } else {
            this.$validator.validate();
          }
        });
      }
    },
    setElabProp(bool){
      this.SET_ELAB_PROP_PRODUCTO_REGISTRAR_MULTIPLE(bool)
    },
    setCategoria(categoria) {
      this.categoria = categoria;
      this.producto.categoriaArbol = categoria;
      this.SET_CAT_PRODUCTO_REGISTRAR_MULTIPLE(categoria);
    },
    setMarca(marca) {
      if (marca) {
        this.SET_MARCA_PRODUCTO_REGISTRA_MULTIPLE(marca);
        this.SET_MARCA_UNICA_PROD_REG_MUL(true);
      } else {
        this.SET_MARCA_UNICA_PROD_REG_MUL(false);
      }
      this.marca = marca;
    },
    setProveedor(proveedor) {
      this.SET_PROV_PRODUCTO_REGISTRAR_MULTIPLE(proveedor);
      this.proveedor = proveedor;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_PRODUCTO_REGISTRAR_MULTIPLE();
    next();
  },
};
</script>

<style lang="scss" scoped></style>
