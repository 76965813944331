<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
           {{ rubroVenta.codigo }} - {{ rubroVenta.descripcion }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-btn icon>
              <v-btn icon @click="goToEditar(rubroVenta)">
                <v-icon color="grey lighten-1">mdi-pencil</v-icon></v-btn
              >
            </v-btn>
            <v-btn icon>
              <v-btn icon @click="dialogEliminar = true">
                <v-icon color="grey lighten-1">mdi-delete</v-icon></v-btn
              >
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta Neto Gravado:
              {{
                rubroVenta.cuenta_neto_gravado
                  ? rubroVenta.cuenta_neto_gravado.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta Neto No Gravado/Exento:
              {{
                rubroVenta.cuenta_neto_no_gravado_exento
                  ? rubroVenta.cuenta_neto_no_gravado_exento.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta IVA:
              {{ rubroVenta.cuenta_iva ? rubroVenta.cuenta_iva.nombre : "N/A" }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta IVA No Inscripto:
              {{
                rubroVenta.cuenta_iva_no_inscripto
                  ? rubroVenta.cuenta_iva_no_inscripto.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta IVA Percepción Retención:
              {{
                rubroVenta.cuenta_iva_per_ret
                  ? rubroVenta.cuenta_iva_per_ret.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta Ingresos Brutos:
              {{
                rubroVenta.cuenta_ing_bru
                  ? rubroVenta.cuenta_ing_bru.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta Ganancia:
              {{
                rubroVenta.cuenta_ganancia
                  ? rubroVenta.cuenta_ganancia.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Cuenta Otros o Conciliación 3:
              {{
                rubroVenta.cuenta_o_conc_3
                  ? rubroVenta.cuenta_o_conc_3.nombre
                  : "N/A"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarCuenta()"
    >
      <template v-slot:titulo>¿Eliminar el rubro venta?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar el rubro "{{ rubroVenta.descripcion }}"?
        No podrá revertir los cambios.</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
  </v-expansion-panel>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    DialogConfirmationClose,
  },
  props: {
    rubroVenta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  methods: {
    ...mapActions("contabilidad", ["eliminar_rubro"]),
    goToEditar(rubro) {
      this.$router.push({
        name: "rubroVentaUpdate",
        params: { rubroVenta: rubro },
      });
    },
    eliminarCuenta() {
      this.eliminar_rubro(this.rubroVenta).then((response) => {
        this.dialogEliminar = false;
      });
    },
  },
};
</script>