import axios from 'axios'
const url = '/afipfiscal/receipts/pdf'

export default {

    show(id) {

        var url = `/afipfiscal/pdf/${id}`;

        return axios.get(url)
    },
    open(id) {
        var baseURL = axios.defaults.baseURL
        var url = `${baseURL}/afipfiscal/pdf/${id}`;
        window.open(url, "_blank");
    },


}