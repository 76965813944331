<template>
  <v-card>
    <v-container>
      <v-card-title class="text-center justify-center">Cambiar Estado</v-card-title>
      <v-card-text>
        <v-row justify="space-around">
          <v-col cols="3" v-for="(estado,i) in estados" :key="i">
            <v-btn @click="processEstado(estado.nombre)">{{estado}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "estadosSelect",
  props: {
    estados: {
      type: Array,
      required: true,
    },
    methods: {
      processEstado(estado) {
        alert("estado seleccionado", estado);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>