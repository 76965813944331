<template>
  <div>
    <v-autocomplete
      :label="title"
      v-model="producto.proveedor"
      :items="proveedores"
      item-text="nombre"
      required
      item-value="id"
      v-validate.continues="producto.es_elaboracion_propia ? '' : 'required'"
      :error-messages="errors.collect('proveedor')"
      data-vv-name="proveedor"
      @change="set_select_data"
      @click:clear="clearData()"
      :disabled="disabled"
      :placeholder="placeholder"
      :filled="filled"
      :solo="solo"
      :flat="flat"
      :dense="dense"
      return-object
      :clearable="clearable"
      :outlined="outlined"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "producto.form",
  components: {},
  inject: ["$validator"],
  props: {
    producto: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Proveedor",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
    ...mapGetters("proveedor", ["proveedores"]),
  },
  methods: {
     ...mapActions("proveedor",["fetchProveedores"]),
    set_select_data(data) {
      this.$emit("set_proveedor", data);
    },
  },
   created(){
    this.fetchProveedores()
  }
};
</script>

<style lang="scss" scoped></style>
