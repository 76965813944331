<template>
  <LayoutBasic :showLinksMenu="false">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:navigation-title> Proveedores </template>
    <template v-slot:navigation-content>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            rounded
            outlined
            style="background-color: #3a3b3c"
            dense
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar proveedor"
            single-line
            hide-details
            autofocus
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>

    </template>
     <template v-slot:navigation-before-links>
      <v-list-item @click="dialogProveedorCreate = true">
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Nuevo proveedor</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     </template>
      <template v-slot:vista-previa>
        <ProveedoresList
          @process-proveedor="selectProveedor"
          :proveedores="proveedores"
          :search="search"
        ></ProveedoresList>
         <v-dialog width="800" v-model="dialogProveedorCreate">
        <v-card>
          <proveedor-create
            @close-dialog="dialogProveedorCreate = false"
          ></proveedor-create>
        </v-card>
      </v-dialog>
       </template>
   
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ProveedorCreate from "@/components/proveedor/ProveedorCreate";
import ClienteUpdate from "@/views/cliente/ClienteUpdate";
import ProveedoresList from "@/components/proveedor/ProveedoresList.vue";
import { mapGetters } from "vuex";

export default {
  name: "proveedores-home",
  components: {
    LayoutBasic,
    ProveedorCreate,
    ClienteUpdate,
    ProveedoresList,
  },
  data() {
    return {
      search: "",
      dialogProveedorCreate: false,
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
  },
  methods: {
    selectProveedor(proveedor) {
      this.$router.push({ path: `/proveedores/${proveedor.id}` });
    },
  },
};
</script>

<style lang="scss" scoped></style>
