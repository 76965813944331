<template>
  <div id="endPage">
    <!-- Buscador por vista -->
    <v-dialog persistent v-model="dialogDetalleForm" width="500">
      <comprobante-detalle-form
        v-if="dialogDetalleForm"
        ref="detalleForm"
        @close-dialog="dialogDetalleForm = false"
        @process-detalle="enviarDetalle2"
        :detalle="detalle_pedido"
      ></comprobante-detalle-form>
    </v-dialog>
  </div>
</template>

<script>
import ComprobanteDetalleForm from "@/components/comprobante/detalle/comprobante.detalle.form.vue"


import ProductoBuscadorMenu from "@/components/producto/buscador/producto.buscador.menu.vue";
import ProductosPorCategoria from "@/components/producto/consultar/producto.por.categoria.vue";
import PromocionConsultar from "@/views/promocion/promocion.consultar.vue";

import {
  addDetalle,
} from "@/services/detallesComprobante/serviceDetalles";
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";
import { mapMutations, mapState } from "vuex";

export default {
  name: "comprobante.agregar.detalle.menu",
  components: {
    ProductoBuscadorMenu,
    ComprobanteDetalleForm,
    ProductosPorCategoria,
    PromocionConsultar,
  },
  props: {
    // detalles: {
    //   type: Array,
    //   required: true,
    // },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    fab: {
      //Para mostrar button fab
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detalles: [],
      reloadCategoria: 1,
      detalle_pedido: {},
      dialogDetalleForm: false,

      productos: [],
      isProducto: true,
      showProductos: false,
      verProductosFromBuscador: false,
    };
  },
  computed: {
    ...mapState("sucursales",["configuracion"]),
    ...mapState("remitos", ["esPedidoYa"]),

    inventario() {
      return this.configuracion.productoInventario;
    }
  },
  methods: {
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),

    goToEndPage() {
      // Sirve para cuando el dialog no esta en fullPage
      let elmnt = document.getElementById("endPage");
      elmnt.scrollIntoView(false);
    },
    enviarDetalle2() {
      this.enviarDetalle(this.detalle_pedido);
      this.dialogDetalleForm = false;
    },

    agregarProducto(producto) {
      var detalle = {};
      const copyProducto = { ...producto };
      detalle.producto = copyProducto;
      detalle.cantidad = 1;
      detalle.precioUnitario = copyProducto.precioVenta
      detalle.isProducto = true;

      this.detalle_pedido = detalle;
      this.dialogDetalleForm = true;
    },
    agregarPromocion(promocion) {
      var detalle = {};
      detalle.promocion = promocion;
      detalle.cantidad = 1;
      detalle.isProducto = false;
      detalle.precioUnitario = promocion.precioVenta


      this.detalle_pedido = detalle;
      this.dialogDetalleForm = true;
    },
    ...mapMutations("pedidos", ["add_detalle_update"]),
    agregarDetalle(detalle) {
      var det = { ...detalle };
      this.isBorrador = true;
    
      addDetalle(this.detalles, det, true);
      this.$updateBus.$emit("showSnackBar", {
        show: true,
        button: false,
        color: "success",
        text: "Producto agregado",
        icon: "mdi-check",
        timeout: 1500,
      });
    },
    enviarDetalle(detalle) {
      this.reloadCategoria = !this.reloadCategoria;
      if (this.isUpdate) {
        this.agregarDetalleUpdate(detalle);
      } else {
        this.agregarDetalle(detalle);
      }

      this.dialog = false;
    },
    agregarDetalleUpdate(detalle) {
      let clone_detalle = { ...detalle };
      add_detalle_update(this.detalles, clone_detalle);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {

      if (event.key === "Escape") {
        if (this.dialogDetalleForm) {
          this.dialogDetalleForm = false;
         
        } else {
          this.showProductos = false;
        }
      }

      if (event.key === "+") {
        this.showProductos = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
