<template>
  <v-card max-width="350px" class="overflow-y-auto" max-height="500px">
    <v-card-text>
      <!-- REGISTRAR CATEGORIA -->
      <v-row justify="end">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              @click="dialogCreate = true"
              class="mr-1"
              icon
              color="red lighten-2"
              dark
              v-on="{ ...tooltip }"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>

        <egreso-categoria-create
          :dialogCategoria="dialogCreate"
          @close-dialog="dialogCreate = false"
          :parent="parent"
          @refresh-categorias="fetchCategoria()"
        ></egreso-categoria-create>
      </v-row>
      <!-- LISTA CATEGORIA -->
      <div class="mt-2"></div>
      <v-list dense v-if="categorias">
        <v-list-item-group>
          <v-list-item
            v-for="categoria in categorias"
            :key="categoria.id"
            @click="$emit('processCategoria', categoria)"
          >
            <v-list-item-content @click.right="openMenu(categoria, $event)">{{
              categoria.name
            }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-alert class="red lighten-2" v-if="categorias == false"
        >No hay categorias</v-alert
      >
    </v-card-text>
    <!-- MENU DE OPCIONES -->
    <v-menu
      absolute
      fixed
      transition="scale-transition"
      v-model="menu"
      :position-x="xMenu"
      :position-y="yMenu"
    >
      <v-list>
        <v-list-item @click="openDialogEdit">
          <v-list-item-avatar>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dialogEliminar = true">
          <v-list-item-avatar>
            <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>Eliminar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialogEdit" width="500">

      <egreso-categoria-update
        :categoria="selectEditCategoria"
        @refresh-categorias="fetchCategoria"
        @cerrar-dialog="dialogEdit = false"
      ></egreso-categoria-update>
    </v-dialog>
    <v-dialog v-if="selectEditCategoria" v-model="dialogEliminar" width="500">
      <v-toolbar color="red lighten-3">
        <v-toolbar-title>Eliminar</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <div class="text-center">
            ¿Está seguro que dese eliminar
            <b>{{ selectEditCategoria.name }} ?</b>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="dialogEliminar = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="red lighten-1"
            @click="eliminarCategoria(selectEditCategoria)"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import EgresoCategoriaCreate from '@/components/egresos/categorias/EgresoCategoriaCreate'
import EgresoCategoriaUpdate from '@/components/egresos/categorias/EgresoCategoriaUpdate'
import EgresoAPI from "@/api/egresos/index.js";

export default {
  name: "EgresoCategoriasTreeBranch.vue",
  components: {
    EgresoCategoriaCreate,
    EgresoCategoriaUpdate,
  },
  props: {
    parent: {
      type: Object,
    },
  },
  data() {
    return {
      dialogCreate: false,
      dialogEdit: false,
      dialogEliminar: false,
      categorias: null,
      selectEditCategoria: null,
      messageError: null,
      menu: false,
      xMenu: 0,
      yMenu: 0,
    };
  },
  computed: {},
  methods: {
    openDialogEdit() {
      if (this.selectEditCategoria) {
        this.dialogEdit = true;
      }
    },

    openMenu(categoria, e) {
      this.selectEditCategoria = { ...categoria };
      e.preventDefault();
      this.xMenu = e.pageX;
      this.yMenu = e.pageY;
      this.menu = true;
    },
    eliminarCategoria(categoria) {
      this.axios.delete(`/categorias/${categoria.id}`).then((response) => {
        this.dialogEliminar = false;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Categoría eliminada",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.fetchCategoria();
      });
    },

    fetchCategoria() {
      EgresoAPI.index(this.parent.nombre_padre).then((response) => {
        if (response.data.parent.children.length > 0) {
          this.categorias = response.data.parent.children;
        } else {
          this.categorias = false;
        }
      });
    },
  },

  created() {
    this.fetchCategoria();
  },
};
</script>

<style scoped></style>
