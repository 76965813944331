<template>
  <v-dialog v-model="dialogCategoria" width="500">
    <template v-slot:activator="{ on }">
      <v-hover v-slot:default="{ hover }">
        <v-card outlined v-on="on">
          <v-card-text>
            <div :class="hover ? 'headline text-center' : 'text-center'">Categoría</div>
            <div class="text-center title white--text" v-if="categoria">
              {{ categoria.name }}
            </div>
          </v-card-text>
        </v-card>
      </v-hover>
    </template>
    <v-card>
      <v-container>
        <v-card-title>
          Seleccione una categoría
          <v-spacer></v-spacer>
          <v-icon large @click="dialogCategoria = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <keep-alive>
            <tree-categoria @processCategoria="setCategoriaRegister"></tree-categoria>
          </keep-alive>
        </v-card-text>
        <v-card-actions v-if="categoria">
          <v-spacer></v-spacer>
          <v-btn @click="setCategoriaRegister(null)" text>
            <small>Quitar categoria</small>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria";

export default {
  name: "CategoriaCardSelect",
  components: {
    TreeCategoria,
  },
  data() {
    return {
      dialogCategoria: false,
      categoria: null,
    };
  },
  computed: {},
  methods: {
    setCategoriaRegister(val) {
      this.categoria = val;
      this.$emit("process-categoria", val);
      this.dialogCategoria = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
