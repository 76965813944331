<template>
  <div>
    <v-checkbox
    :label="title"
      class="text-center justify-center mt-4"
      v-model="producto.es_elaboracion_propia"
      value
    >
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "FormProductoEsElaboracionPropia",
  props: {
    producto: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Es elaboración propia",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "producto.es_elaboracion_propia": function (bool) {
      this.$emit('set-elab-prop', bool)
    },
  }
};
</script>

<style lang="scss" scoped>
</style>