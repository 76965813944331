<template>
  <div>
    <v-card color="#242526">
      <v-card-title>
        <b> Pagar CTA. CTE. </b>

        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon color="grey" @click="cancelar">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mt-2">Saldar el consumo del empelado.</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="info" @click="cancelar" text>
          <b></b>
          <b> Cancelar</b>
        </v-btn>
        <v-btn
          @click="pagarCuentaCorrienteEmpleado"
          :loading="loading"
          color="info"
        >
          <b> Pagar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EmpleadoApi from "@/api/empleados";

export default {
  name: "empleado.cuentacorriente.pagar",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    pagarCuentaCorrienteEmpleado() {
      this.loading = true;
      EmpleadoApi.pagarCtaCte(this.$route.params.id_empleado)
        .then((response) => {
          this.$emit("refresh-cuenta-corriente");
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pago registrado",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;

          this.cancelar();
        });
    },
    cancelar() {
      this.$emit("close-dialog-cobrar");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>