<template>
  <LayoutBasic>
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:navigation-title>
      Sucursales
      <!-- <v-list>
        <v-list-item>
          <v-list-item-title>
            <h1 style="font-size: 35px">Sucursales</h1>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar sucursales"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-list> -->
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Nueva sucursal</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-row justify="center">
        <v-col cols="10">
          <v-row>
            <v-col v-if="user.role.includes('admin')">
              <v-list style="background-color: transparent" flat two-line>
                <v-list-item @click="setSucursal(sucursal)">
                  <v-list-item-content>
                    <v-list-item-title
                      ><div class="title">
                        Listado de sucursales
                      </div></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <div>
                        En el detalle de cada local, podrás agregar
                        colaboradores, configurar los medios de cobro de tus
                        cajas.
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="user.role.includes('admin')">
                    <v-btn large @click="goToRegisrarSucursal()">
                      <v-icon color="grey lighten-1">mdi-plus</v-icon>
                      <span class="text-subtitle">Agregar sucursal</span>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col v-else>
              <v-list style="background-color: transparent" flat two-line>
                <v-list-item @click="setSucursal(sucursal)">
                  <v-list-item-content>
                    <v-list-item-title
                      ><div class="title">
                        Sucursales disponibles
                      </div></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Selecciona una sucursal.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <v-skeleton-loader
        v-for="i in 4"
        :key="i"
        v-show="loading"
        max-height="350"
        type="list-item-avatar"
      ></v-skeleton-loader>
              <v-list two-line v-if="!loading">
                <!-- Lista de sucursales activas -->
                <v-subheader>Disponibles</v-subheader>
                <v-list-item
                  @click="setSucursal(sucursal)"
                  v-for="sucursal in sucursales"
                  :key="sucursal.id"
                >
                  <v-list-item-avatar>
                    <v-icon color="success">mdi-map-marker</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ sucursal.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sucursal.direccion
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <!-- Lista de sucursales no activas -->
                <v-subheader v-if="false">Otras sucursales</v-subheader>
                <v-list-item
                  @click="setSucursal(sucursal)"
                  v-for="sucursal in []"
                  :key="sucursal.id"
                >
                  <v-list-item-avatar>
                    <v-icon dark>mdi-bank</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ sucursal.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sucursal.direccion
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import { mapActions, mapMutations, mapState } from "vuex";
import LoginVue from "../usuarios/Login.vue";

export default {
  name: "Sucursales",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      tab: null,
      loading: false,

      localidad: null,
      sucursales: [],
      sucursalSeleccionada: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    // filteredSucursales() {
    //   const actual = this.sucursales.filter(
    //     (sucursal) => sucursal.es_sucursal_actual
    //   );
    //   const restoSucursales = this.sucursales.filter(
    //     (sucursal) => !sucursal.es_sucursal_actual
    //   );

    //   return {
    //     actual,
    //     restoSucursales,
    //   };
    // },
  },
  methods: {
    ...mapMutations("auth", ["SET_AUTH_SUCURSAL"]),
    ...mapActions("sucursales", [
      "validate_asignacion_sucursal",
      "validate_caja",
      "fetchArqueoVigente",
    ]),
    goToRegisrarSucursal() {
      this.$router.push({
        name: "registrarSucursal",
      });
    },
    setLocalidad(val) {
      //Llega el nombre de la localidad
      this.localidad = val;
    },

    async setSucursal(sucursal) {
      try {
    this.$store.commit("setLoadingInit", true);

         this.sucursalSeleccionada = sucursal;

      const response = await this.validate_asignacion_sucursal(sucursal.uuid);
      if (response.success) {
        this.$router.push({
          name: "cajas",
        });
      }

      const response_caja = await this.validate_caja();

      if (response_caja.success) {
        const response_arqueo = await this.fetchArqueoVigente();
        if (
          response_arqueo.success &&
          response_arqueo.response.data.arqueo_de_caja
        ) {
          this.$router.push({
            name: "home",
          });
        }
      }
      } catch (error) {
        
      }finally{
    this.$store.commit("setLoadingInit", false);


      }
     
    },
    async fetchSucursales() {
      try {
        this.loading = true;
        const response = await this.axios.get("/sucursales/sucursales");
        this.sucursales = response.data;
      } catch (error) {
        console.log("Error al obtener las sucursales");
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchSucursales()
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 50px;
}
.two-colors {
  background: #005d5b;
  background: linear-gradient(
    180deg,
    #005d5b 0,
    #005d5b 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );

  height: 150vh;
}
</style>
