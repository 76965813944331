<template>
  <v-card  flat max-width="375" class="transparent">

    
 
    <v-list two-line dense>
      <v-list-item dense link >
        <v-list-item-icon>
          <v-icon color="indigo">mdi-account</v-icon>
        </v-list-item-icon>

        <v-list-item-content v-if="cliente">
          <v-list-item-title>{{
            cliente.full_name ? cliente.full_name : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            cliente.telefono ? cliente.telefono : "Tel: N/A"
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="formCliente">
          <v-list-item-title>{{
            formCliente.nombre ? formCliente.nombre : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            formCliente.telefono ? formCliente.telefono : "N/A"
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="!paraEnviar">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-home-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Para retirar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <VentaClienteEnvioInfo
      v-if="paraEnviar"
         :formEnvio="formEnvio"
                  :cliente="cliente"
                  :paraEnviar="paraEnviar"
      ></VentaClienteEnvioInfo>
     
    </v-list>
  </v-card>
</template>

<script>
import VentaClienteEnvioInfo from "@/components/venta/cliente/VentaClienteEnvioInfo";

export default {
  name: "VentaClienteInfoCard",
  components: {
    VentaClienteEnvioInfo
  },
  props: {
    formCliente: {
      type: [Object, null],
    },
    formEnvio: {
      type: [Object, null],
    },
    cliente: {
      type: [Object, null],
    },
    paraEnviar: {
      type: Boolean,
      required: true,
    },

    showImage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
