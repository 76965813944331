<template>
  <layout-two-column>
    <template v-slot:breadcrumbs> </template>
    <template v-slot:title>
      <v-list class="pt-0 mt-0">
        <v-list-item>
          <v-list-item-icon>
           <v-btn class="" style="background-color: #4e4f50" large icon @click="$router.push('/egresos')">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-title>
            <h1 class="text-wrap" style="font-size: 30px">BUZON</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list  >
        <v-list-item
          exact
          
          color="blue lighten-3"
          v-for="(item, i) in items"
          :key="i"
          :to="item.route ? { name: item.route }: ''"
          @click="item.action ? item.action() : ''"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
   
      <router-view></router-view>
      <v-dialog width="400" v-model="dialogRegistrar">
        <buzon-registrar @close-dialog="dialogRegistrar=false"></buzon-registrar>
      </v-dialog>
    </template>
  </layout-two-column>
</template>

<script>
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
import BuzonRegistrar from "@/views/egresos/buzon/buzon.registrar.vue";

import { mapActions, mapState } from 'vuex';

export default {
  name: "buzon",
  components: {
    LayoutTwoColumn,
    BuzonRegistrar
  },

  data() {
    return {
      items: [],
      dialogRegistrar: false
    };
  },
 
  methods: {
    openDialog(){
      this.dialogRegistrar = true
    },

 
  },

  created() {
    this.items.push(
      { text: "Inicio", icon: "mdi-home", route: "buzonHome" },

      { text: "Nuevo retiro", icon: "mdi-shape-rectangle-plus", route: '', action: this.openDialog },
      { text: "Historial", icon: "mdi-history", route: "buzonHistorial" }
    );
    this.$store.commit("SET_LINKS", this.items);
  },
};
</script>

<style lang="scss" scoped></style>
