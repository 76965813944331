<template>
  <!-- <v-autocomplete
      v-model="localidad"
      no-data-text="Sin localidades"
      value="nombre"
      outlined
      solo
      :items="localidades"
      item-text="nombre"
      item-value="nombre"
      label="Elegí localidad"
      name="Localidad"
      @change="setLocalidad"
    ></v-autocomplete> -->
  <v-autocomplete
    :label="title"
    v-model="data"
    :items="isForArchivo ? proveedoresConLista : proveedores"
    item-text="nombre"
    required
    item-value="id"
    @change="set_select_data"
    @click:clear="clearData()"
    :disabled="disabled"
    :placeholder="placeholder"
    :filled="filled"
    :solo="solo"
    :flat="flat"
    :dense="dense"
    return-object
    :clearable="clearable"
    :outlined="outlined"
  >
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  components: {},
  name: "FormSelectProveedor",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Proveedor",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    isForArchivo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reset: false,
      data: {},
      proveedoresConLista: [],
    };
  },
  computed: {
    ...mapState("productos", ["reset_filter"]),
    ...mapGetters("proveedor", ["proveedores"]),
  },

  methods: {
    clearData() {
      this.$emit("clearable");
    },
    async getProveedores(data) {
      let url = "/proveedores/proveedorconlista";
      const proveedores = await this.axios.get(url);

      this.proveedoresConLista = proveedores.data;
    },
    set_select_data(data) {
      this.$emit("set_proveedor", data);
    },
  },
  watch: {
    reset_filter: {
      handler(val) {
        if (val == "proveedor") {
          this.reset = true;
          setTimeout(() => {
            this.reset = false;
          }, 500);
        }
      },
    },
  },

  created() {
    if (this.isForArchivo) {
      this.getProveedores();
    }
  },
};
</script>

<style lang="scss" scoped></style>
