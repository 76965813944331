<template>
  <v-row>
    <v-col cols="3">
      <v-select
        class="mt-1"
        v-model="tipoBusqueda"
        :items="items"
        label="Buscar por"
        dense
        filled
        rounded
        item-text="value"
        item-value="id"
        @change="inputSearch = null"
      ></v-select>
    </v-col>
    <v-col cols="9">
      <v-autocomplete
        class="mr-1"
        :items="productos"
        append-icon
        filled
        rounded
        ref="searchCmp"
        :loading="isLoading"
        :placeholder="tipoBusqueda == 1 ? '10-' : ''"
        :search-input.sync="inputSearch"
        hide-no-data
        autofocus
        @keyup.enter="seleccionarProducto"
        persistent-hint
        clearable
        @click:clear="clearData()"
        item-text="descripcion"
        item-value="descripcion"
        height="28"
        label="Buscar"
        id="inputSearch"
        v-validate="'required|integer|max:10'"
        :error-messages="errors.collect('inputSearch')"
        data-vv-name="código"
        cache-items
        @focus.native="$event.target.select()"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {

  name: "producto.buscador.multiple",
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      tipoBusqueda: 0,
      items: [
        { id: 0, value: "Nombre" },
        { id: 1, value: "Código" },
        { id: 2, value: "Código de barra" },
      ],
      cantidad: null,
      isLoading: false,

      productos: [],
      inputSearch: null,
      hayProductos: false,
    };
  },
  computed: {
    ...mapState("remitos", ["esPedidoYa"]),
  },
  methods: {
    focus() {
      this.$refs.searchCmp.focus();
    },
    clearData() {
      this.$emit("clean-buscador");
    },
    cleanInputSearch() {
      this.inputSearch = null;
      this.$refs.searchCmp.focus();
    },
    seleccionarProducto() {
      if (this.hayProductos) {
        this.$emit("seleccionar-producto-local");
      }
    },
    fetchProductos(key, val) {
      if (this.esPedidoYa) {
        var url = `/productos/p?${key}=${val}&py=true&page=1&page_size=10`;
      } else {
        var url = `/productos/p?${key}=${val}&page=1&page_size=10`;
      }
      //El servidor devulve un único producto, o devuelve array vacio
      this.axios
        .get(url)
        .then((request) => {
          request.data.results.length > 0
            ? (this.hayProductos = true)
            : (this.hayProductos = false);

          this.$emit("loading-buscar-producto", false);

          this.$emit("resultado-buscar-producto", request.data.results);

          this.isLoading = false;
        })
        .catch((e) => {
          this.$emit("loading-buscar-producto", false);

          this.isLoading = false;
        });
    },
    getFromSearch(val) {
      // Si ingresa cadena vacia, retorna falso
      this.$emit("loading-buscar-producto", true);

      if (val == "") {
        this.detalle_pedido.producto = null;
        return false;
      }
      var key;
      switch (this.tipoBusqueda) {
        case 0:
          key = "nombre";
          break;
        case 1:
          key = "codigo";
          break;
        case 2:
          key = "barcode";
          break;

        default:
          key = "nombre";
          break;
      }

      if (key == "codigo") {
        if (val.substring(val.indexOf("-")) && val.includes("-")) {
          this.fetchProductos(key, val);
          return;
        } else {
          if (this.hayProductos) {
            this.clearData();
          }
          return;
        }
      }
      if (key == "barcode") {
        let isnum = /^\d+$/.test(val);
        if (isnum) {
          this.fetchProductos(key, val);
          return;
        }
      }
      this.fetchProductos(key, val);
    },
  },
  watch: {
    inputSearch(data) {
      if (data === null || data === undefined)
        return this.$emit("loading-buscar-producto", false);

      if (data.length > 16) return (this.inputSearch = "");
      if (data == "" || data.length > 8)
        return (this.isLoading = false), this.$emit("loading-buscar-producto", false);

      if (Number.isInteger) {
        this.isLoading = true;

        this.getFromSearch(data);
      } else {
        return (this.productos = []);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
