<template>
  <div>
    <v-select
    
      v-model="producto.iva"
      :items="iva"
      v-validate="'required'"
      :error-messages="errors.collect('iva')"
      data-vv-name="iva"
      id="iva"
      item-text="valor"
      item-value="valor"
      label="IVA"
      type="number"
      :outlined="outlined"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.iva",
  components: {},
  inject: ["$validator"],
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      iva: [
        { id: 88, valor: '0.00' },
        { id: 89, valor: '10.50' },
        { id: 90, valor: '21.00' },
      ],
    };
  },
  mounted(){
    console.log(typeof this.producto.iva);
  }

};
</script>

<style lang="scss" scoped></style>
