<template>
  <div>
    <productos-filtros-tag
      v-if="withTags"
      :removeTagCategoria="false"
      @close-chip="closeChip"
    ></productos-filtros-tag>
    <v-list-item class="">
      <v-list-item-content>
        <v-list-item-title
          ><span class="subtitle-1 font-weight-black"
            >Filtros</span
          ></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <!-- <producto-filtro-sucursal
      @select-sucursal="setSucursal"
    ></producto-filtro-sucursal> -->

    <producto-filtro-categoria
      type="list"
      @select-categoria="setCategoria"
    ></producto-filtro-categoria>
    <v-list-item class="">
      <producto-filtro-plataforma
        type="list"
        @select-plataforma="setPedidosYa"
        :plataformas="plataformas"
      ></producto-filtro-plataforma>
    </v-list-item>

    <v-list-item v-if="false">
      <v-list-item-content>
        <v-list-item-title>
          <span style="font-size: 14px">Stock</span></v-list-item-title
        >
        <v-row justify="center" class="mt-1">
          <v-col cols="5">
            <v-text-field
              background-color="#424242"
              outlined
              dense
              placeholder="Mín."
              >|</v-text-field
            >
          </v-col>
          <div class="mt-5" style="display: block">a</div>
          <v-col cols="5">
            <v-text-field
              background-color="#424242"
              outlined
              dense
              placeholder="Máx."
            ></v-text-field>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <producto-filtro-proveedor
      type="list"
      @select-proveedor="setProveedor"
    ></producto-filtro-proveedor>

    <producto-filtro-marca
      type="list"
      @select-marca="setMarca"
    ></producto-filtro-marca>
    <!-- <v-list-item v-if="proveedores.length > 0">
      <v-list-item-content>
        <proveedor-list-select
          :proveedores="proveedores"
          @set_proveedor="setProveedor"
        ></proveedor-list-select>
      </v-list-item-content>
    </v-list-item> -->
  </div>
</template>

<script>
import ProductosFiltrosTag from "@/components/producto/filtros/productos.filtros.tag.vue";
import ProductoFiltroPlataforma from "@/components/producto/filtros/producto.filtro.plataforma.vue";
import ProductoFiltroProveedor from "@/components/producto/filtros/producto.filtro.proveedor.vue";
import ProductoFiltroCategoria from "@/components/producto/filtros/producto.filtro.categoria.vue";
import ProductoFiltroMarca from "@/components/producto/filtros/producto.filtro.marca.vue";
import { mapActions, mapMutations } from "vuex";
import ProductoFiltroSucursal from "@/components/producto/filtros/producto.filtro.sucursal.vue";

export default {
  name: "productos.filtros",
  components: {
    ProductosFiltrosTag,
    ProductoFiltroPlataforma,
    ProductoFiltroProveedor,
    ProductoFiltroCategoria,
    ProductoFiltroMarca,
    ProductoFiltroSucursal,
  },
  props: {
    withTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      plataformas: [
        { id: 1, nombre: "Pedidos Ya", key: "plataforma-pedidosya" },
        { id: 1, nombre: "Mi sistema", key: "paltaforma-misistema" },
      ],
      filters: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("categorias", ["fetchCategoriasProductosFiltros"]),
    ...mapMutations("categorias", [
      "SET_CATEGORIAS_PRODUCTOS_FILTROS_SELECCIONADO",
    ]),
    loadCategoria(name) {
      this.$refs.categoriaList.load(name);
    },
    closeChip(chip) {
      console.log("CHIP", chip);
      if (chip.key == "categoria") {
        this.$refs.categoriaList.loadRoot();
      }
    },
    addTag(tag) {
      console.log(tag);
      var object = {};
      object[tag.key] = tag.value;
      return object;
    },
    get_text_transform(str) {
      if (typeof str === "string") {
        return str.match(/[^ ]+/g).join("-");
      } else {
        return str;
      }
    },

   // Método para agregar parámetros de consulta a la URL actual y manejar valores duplicados
 add_query_url(value) {
  console.log("add_query_url", value);
    const existingQuery = this.$route.query;
    const querys = { ...existingQuery }; // Copiamos los parámetros actuales a un nuevo objeto

    for (const key in value) {
      if (querys.hasOwnProperty(key)) {
        const existingValues = querys[key].split('|'); // Dividimos los valores existentes
        if (existingValues.includes(value[key])) {
          // Si el valor ya existe, lo eliminamos del array
          existingValues.splice(existingValues.indexOf(value[key]), 1);
          if (existingValues.length === 0) {
            // Si no quedan valores, eliminamos la clave
            delete querys[key];
          } else {
            querys[key] = existingValues.join('|'); // Actualizamos los valores de la clave
          }
        } else {
          console.log("value[key]", value[key]);
          existingValues.push(value[key]); // Agregamos el valor al array
          querys[key] = existingValues.join('|'); // Actualizamos los valores de la clave
        }
      } else {
          console.log("value[key]", value[key]);

        querys[key] = value[key]; // Agregamos el valor directamente si la clave no existe
      }
    }

    // Reemplazamos la URL actual con los nuevos parámetros de consulta actualizados
    this.$router.replace({ query: querys }).catch((error) => {
      // Manejamos el error de navegación duplicada (NavigationDuplicated)
      if (error.name !== "NavigationDuplicated") {
        throw error;
      }
    });
  },

    // add_query_url(value) {
    //   // Obtenemos los parámetros de consulta actuales de la ruta
    //   var querys = this.$route.query;

    //   // Recorremos los valores pasados como argumento
    //   for (const property in value) {
    //     var name = value[property];

    //     // Aplicamos una transformación de texto al valor
    //     value[property] = this.get_text_transform(name);

    //     // Fusionamos los valores pasados con los actuales
    //     querys = Object.assign({}, querys, value);
    //   }

    //   // Actualizamos la URL con los nuevos parámetros de consulta
    //   this.$router.replace({ query: querys }).catch((error) => {
    //     // Manejamos el error de navegación duplicada
    //     if (error.name !== "NavigationDuplicated") {
    //       throw error;
    //     }
    //   });
    // },
    setProveedor(data) {
      const transformedData = {
        proveedor: this.get_text_transform(data.nombre),
      };
      this.add_query_url(transformedData);
    },
    setPedidosYa() {
      const transformedData = {
        py: true,
      };
      this.add_query_url({ py: true });
    },
    setMarca(data) {
      const transformedData = {
        marca: this.get_text_transform(data.nombre),
      };
      this.add_query_url(transformedData);
    },
    setSucursal(data) {
      console.log("setSucursal",data);
      const transformedData = {
        sucursal: data.nombre,
      };
      this.add_query_url(transformedData);
    },
    setCategoria(data) {
      this.SET_CATEGORIAS_PRODUCTOS_FILTROS_SELECCIONADO(data);
      this.fetchCategoriasProductosFiltros(data.name);
      const transformedData = {
        categoria: this.get_text_transform(data.name),
      };
      this.add_query_url(transformedData);
      this.$emit("set-categoria", data);
    },

    replaceParams(categoria) {
      var string = categoria.name.split(/[ ]+/).join("-");
      return string;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>