<template>
  <div>
    <h2>Recientes</h2>

    <v-slide-group v-model="model" v-if="remitos.length > 0" show-arrows>
      <v-slide-item
        v-for="item in remitos"
        :key="item.id"
      >
        <!-- <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-4"
          height="200"
          width="100"
          @click="toggle"
        >-->

        <v-card width="200" max-width="200" @click="abrirRemito(item)" class="ma-2">
          <div class="caption text-end pa-2 ma-2">
            {{ moment(item.fechaAlta).fromNow() }}
          </div>

          <v-card-title class="pa-0 justify-center"
            >{{ item.letra }}{{ item.id }}</v-card-title
          >
          <v-card-title class="pa-1 justify-center">
            <div class="d-inline-block text-truncate">{{ item.proveedor.nombre }}</div>
          </v-card-title>
          <v-card-actions class="pa-0 justify-center">
            <v-chip
              v-if="item.estaPagado"
              label
              class="ma-2 green darken-2 pa-2 text-capitalize"
            >
              <v-avatar left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              Pagado
            </v-chip>
            <v-chip v-else label class="ma-2 orange darken-4 pa-2 text-capitalize">
              <v-avatar left>
                <v-icon>mdi-cash</v-icon>
              </v-avatar>
              <b>Pendiente de pago</b>
            </v-chip>
          </v-card-actions>
          <!-- <v-card-actions class="pa-0 justify-center">
            <v-chip
              class="ma-2"
              label
              :class="estado == 'aprobado'? 'green darken-2 d-inline pa-2 text-capitalize ' : 'orange darken-1 d-inline pa-2 text-capitalize '"
            >
              <v-avatar left>
                <v-icon>{{estado == 'aprobado' ? 'mdi-checkbox-marked-circle':'mdi-clock'}}</v-icon>
              </v-avatar>
              {{estado}}
            </v-chip>
          </v-card-actions> -->
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <v-alert type="info" v-else>Sin remitos recientes</v-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";
import EstadoSelect from "@/views/pedido/estadosSelect";
export default {
  name: "remito.compra.consultar",

  components: {
    DetallePedidoForm,
    EstadoSelect,
  },
  props: {
    remitos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      dialogEstado: false,
      estados: ["Cancelar", "Finalizar"],
      model: null,
      // remitos: [],
      search: "",
      headers: [
        // { text: "Nro", value: "numeroVentaPorArqueo" },
        { text: "Fecha", value: "fechaAlta" },
        { text: "Cliente", value: "cliente.cliente.nombre" },

        { text: "Forma de pago", value: "formaDePago.nombre" },
        { text: "Total", value: "total" },
      ],
    };
  },
  computed: {
    ...mapState("remitos", ["remitos_compra", "paginacion_remito_compra"]),

    estado() {
      return this.remitos[0].estado;
    },
  },

  methods: {
    ...mapActions("remitos", ["fetch_remitos"]),

    handleClick(value) {
      this.$router.push({ name: "detalleVenta", params: { id: value.id } });
    },
    async abrirRemito(remito) {
      console.log(remito);
      this.$router.push({
        name: "remitosConsultarDetalle",
        params: { id: remito.id },
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
