<template>
    <v-select
      dense
      v-model="selectediva"
      :items="alicuotasIva"
      hide-details
      value="nombre"
      label="IVA"
      outlined
      item-text="description"
      item-value="description"
      return-object
      name="Alicuota iva"
      v-validate="'required'"
      :error-messages="errors.collect('alicuotaIVA')"
      data-vv-name="alicuotaIVA"
    ></v-select>
</template>

<script>
export default {
  name: "AlicuotaIvaSelect",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    iva: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectediva: {
      get() {
        return this.iva
      },
      set(value) {
        this.$emit('update:iva', value);
      }
    }
  },
  data() {
    return {
      alicuotasIva: ["21.00", "10.5", "0.00"],
    };
  },
  methods: {
    async fetchAlicuotasIva() {
        try {
            const response = await this.axios.get(`/contabilidad/alicuotaiva`);
            this.alicuotasIva = response.data;
        } catch (error) { }
    },
  },
  created() {
    this.fetchAlicuotasIva();
  },
};
</script>

<style lang="scss" scoped></style>