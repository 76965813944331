<template>
  <v-container>
    <v-list-group :value="false" prepend-icon="mdi-cash-minus">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ parametro.nombre }}</v-list-item-title>
          <v-list-item-subtitle
            ><div class="text-caption text-wrap" v-html="parametro.descripcion"></div>
            <v-chip :color="parametro.habilitado ? 'Blue' : ''" x-small>{{
              parametro.habilitado ? "Activado" : "Desactivado"
            }}</v-chip></v-list-item-subtitle
          >
        </v-list-item-content>
      </template>

      <v-list-item dense>
        <v-list-item-avatar class="ma-0"><v-icon>mdi-bell </v-icon></v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{
            parametro.habilitado ? "Activado" : "Desactivado"
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-switch
            dense
            :loading="loadingStatus"
            @change="updateParametro(['habilitado', parametro])"
            v-model="parametro.habilitado"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense v-if="parametro.codigo != 200">
        <v-list-item-avatar> </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Monto</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title
            ><span class="grey--text"> {{ parametro.monto }}</span></v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-btn text plain @click="openDialog(parametro.monto)">
            <span class="blue--text">Editar</span>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense v-if="parametro.codigo == 100">
        <v-list-item-avatar class="ma-0"><v-icon>mdi-lock </v-icon></v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            >Bloquear el sistema cuando se envíe la notificación.</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-switch
            dense
            :loading="loadingBloqueo"
            @change="updateParametro(['bloqueo', parametro])"
            v-model="parametro.bloqueo"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- <v-list v-if="parametro.tipoEnvio">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              ><div style="font-size: 15px">
                <b>Donde recibes estas notificaciones</b>
              </div></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="envio in parametro.tipoEnvio" :key="envio.id" dense>
          <v-list-item-avatar class="ma-0"
            ><v-icon v-text="envio.icon"> </v-icon
          ></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ envio.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-list-group>
    <v-divider></v-divider>
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-container>
          <v-card-title><span class="text-h5">Modificar monto</span></v-card-title>
          <v-card-text>
            <v-text-field autofocus v-model="montoEdit" label="Monto"></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn large text plain @click="dialog = false">Cancelar</v-btn>
            <v-btn
              large
              color="blue"
              :loading="loading"
              @click="updateParametro(['monto', parametro])"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AlertaAPI from "@/api/configuracion/alerta/retiro";
import NotificacionesAPI from "@/api/configuracion/notificaciones/index";
import ConfiguracionesAPI from "@/api/configuracion/parametros/index.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "configuracion.notificaciones.item",
  data() {
    return {
      dialog: false,
      montoEdit: null,
      loading: false,
      loadingStatus: false,
      loadingBloqueo: false,
    };
  },
  props: {
    parametro: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    openDialog(monto) {
      this.montoEdit = monto;

      this.dialog = true;
    },
    updateParametro(data) {
      if (data[0] == "monto") {
        data[1].monto = this.montoEdit;
      }
      console.log(data);
      this.loadingStatus = true;
      ConfiguracionesAPI.update(data[0], data[1])
        .then((response) => {
          this.loadingStatus = false;
          this.dialog = false;
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "No se pudo actualizar",
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loadingStatus = false;
          this.dialog = false;
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
