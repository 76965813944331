<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <RubroVentaListItem
          v-for="rubroVenta in rubrosVenta"
          :key="rubroVenta.id"
          :rubroVenta="rubroVenta"
        >
        </RubroVentaListItem>
      </v-expansion-panels>
    </v-row>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarCuenta()"
    >
      <template v-slot:titulo>¿Eliminar la cuenta?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar la cuenta "{{ parent.nombre }}"? No podrá
        revertir los cambios.</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import RubroVentaListItem from '@/components/contabilidad/RubroVentaListItem.vue';

export default {
  components: {
    RubroVentaListItem,

  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  computed: {
    ...mapState("contabilidad", ["rubrosVenta"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetch_rubro_ventas", "eliminar_rubro"]),
    goToEditar(rubro) {
      this.$router.push({
        name: "rubroVentaUpdate",
        params: { rubroVenta: rubro },
      });
    },
    eliminarCuenta(rubro) {
      this.eliminar_rubro(rubro).then((response) => {
        this.dialogEliminar = false;
      });
    },
    fetchRubroVentas() {
      this.fetch_rubro_ventas();
    },
  },
  created() {
    this.fetchRubroVentas();
  },
};
</script>