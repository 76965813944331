var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items3"},[_c('v-snackbar',{attrs:{"color":"error","multi-line":""},model:{value:(_vm.error_detalle_repetido.state),callback:function ($$v) {_vm.$set(_vm.error_detalle_repetido, "state", $$v)},expression:"error_detalle_repetido.state"}},[_vm._v(_vm._s(_vm.error_detalle_repetido.message))]),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.computedHeaders,"items":_vm.detalles,"sort-by":"calories","no-data-text":"No hay productos agregados","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.producto.cantidadstock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.producto.controlaStock ? item.producto.cantidadstock : "N/A")+" ")]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [(_vm.edit)?_c('v-edit-dialog',{attrs:{"cancel-text":"Cancelar","save-text":"Guardar","return-value":item.cantidad,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "cantidad", $event)},"update:return-value":function($event){return _vm.$set(item, "cantidad", $event)},"close":function($event){return _vm.closeCantidad(item)},"cancel":_vm.cancel,"save":_vm.save,"open":function($event){return _vm.open('Editar cantidad')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"step":"0.01","type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxLength":"6","min":"0","label":"Edit","single-line":"","counter":""},model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", $$v)},expression:"item.cantidad"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.cantidad)+" "),_c('v-icon',{staticClass:"mb-1 ml-1",attrs:{"small":""}},[_vm._v(" mdi-pen")])],1):_c('span',[_vm._v(_vm._s(item.cantidad))])]}},{key:"item.precioUnitario",fn:function(ref){
var item = ref.item;
return [(_vm.edit)?_c('v-edit-dialog',{attrs:{"cancel-text":"Cancelar","save-text":"Guardar","persistent":"","return-value":item.precioUnitario,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "precioUnitario", $event)},"update:return-value":function($event){return _vm.$set(item, "precioUnitario", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":function($event){return _vm.open('Editar precio')},"close":function($event){return _vm.guardarPrecioCosto(item.precioUnitario)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxLength":"6","label":"Edit","single-line":"","counter":""},model:{value:(item.precioUnitario),callback:function ($$v) {_vm.$set(item, "precioUnitario", $$v)},expression:"item.precioUnitario"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.precioUnitario)+" "),_c('v-icon',{staticClass:"mb-1 ml-1",attrs:{"small":""}},[_vm._v(" mdi-pen")])],1)]):_c('span',[_vm._v(" "+_vm._s(item.precioUnitario))])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.precioUnitario) * parseFloat(item.cantidad))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('comprobante-detalle-buttons',{attrs:{"detalle":item},on:{"process-increment":function($event){return _vm.incremetnCant(item)},"process-decrement":function($event){return _vm.decrementCant(item)},"process-delete":function($event){return _vm.eliminarDetalle(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }