<template>
  <v-card width="600px">
    <v-card-title>
      <span class="text-h5 white--text">No está registrado como cliente.</span>

      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      Para tener una cuenta corriente, debe registrarse como cliente.
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="registrarCLienteEmpleado(empleado)" color="success"
        >Registrar como cliente</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import { mapActions, mapState } from "vuex";
import EmpleadoAPI from "@/api/empleados";

export default {
  name: "ClientesCuentaCorriente",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("empleado", ["empleado", "loadingEmpleado"]),
  },

  methods: {
    ...mapActions("empleado", ["get_empleado"]),

    registrarCLienteEmpleado(empleado) {
      EmpleadoAPI.create(empleado)
        .then((response) => {
          try {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: "Registrado",
              icon: "mdi-check",
              timeout: 3000,
            });
            this.get_empleado(empleado.id);
          } catch (error) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "info",
              text: "Problema inesperado",
              icon: "mdi-information",
              timeout: 3000,
            });
          }
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
  },
  computed: {
    ...mapState("empleado", ["empleado", "loadingEmpleado"]),

    //  this.clientees = Cliente.query().with('localidad.provincia').get()
    // this.provincias = Provincia.all()
  },
  created() {
    if (this.empleado.cliente) {
      this.$router.push({ name: "empleadoCtaCte", params: { id_cliente: this.empleado.cliente} });
    }
  },
};
</script>

<style lang="scss" scoped></style>
