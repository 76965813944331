<template>
  <layout-two-column>
    <template v-slot:title>
      <v-list>
        <v-list-item>
          <v-list-item-avatar class="ma-1">
            <v-btn @click="$router.go(-1)" icon>
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-title>
            <h1 style="font-size: 35px">Consumos</h1>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar empleado"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller> </template>
    <template v-slot:vista-previa>
      <v-row justify="center" class="mt-4">
        <v-col cols="3">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-center"
                  style="font-size: 16px; line-height: 1.25"
                >
                  Total de consumo
                </v-list-item-subtitle>
                <v-list-item-title
                  class="mt-2 text-center"
                  style="font-weight: 380; line-height: 1; font-size: 35px"
                >
                  {{ totalCtaCte | convertPesoArg }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <empleado-consumo-list
            :loading="loadingEmpleados"
            :empleados="empleadosFilter"
            noData="No hay cuentas corrientes activas"
          ></empleado-consumo-list>
        </v-col>
      </v-row>
    </template>
  </layout-two-column>
</template>

<script>
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
import EmpleadoConsumoList from "@/components/empleado/cuentacorriente/empleado.consumo.list.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "empleados.consumos",
  components: {
    LayoutTwoColumn,
    EmpleadoConsumoList,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState("empleado", ["empleados_consumo", "loadingEmpleados", "totalCtaCte"]),

    empleadosFilter() {
      let empleados = this.empleados_consumo;

      if (this.search) {
        empleados = this.empleados.filter(
          (u) => u.nombre.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      }

      return empleados;
    },
  },

  methods: {
    ...mapActions("empleado", ["fetch_empleados_consumo"]),
  },
  created() {
    this.fetch_empleados_consumo();
  },
};
</script>

<style lang="scss" scoped></style>
