<template>
  <div>
    <CuentasTreeviewDialog
      :planDeCuentas="planDeCuentas"
      :initialCuenta="cuenta"
      @update:initialCuenta="setCuenta"
    ></CuentasTreeviewDialog>
  </div>
</template>

<script>
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import { mapState } from 'vuex';
export default {
  name: "PlanDeCuentaSeleccionar",
  components: {
    CuentasTreeviewDialog
  },
  props: {
    cuenta: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },
  methods: {
    setCuenta(cuenta) {
      this.$emit("set:cuenta", cuenta);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
