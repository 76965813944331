<template>
  <div>
    <dialog-form-generic
      v-if="dialogFormGenerics"
      @close-dialog="closeDialog"
      @process-save="updateListaPrecio"
    >
      <template v-slot:titulo>Subir lista de precios</template>
      <template v-slot:message>
        <v-row>
          <v-col cols="12" class="mt-4">
            <v-file-input
              v-model="files"
              :loading="loading"
              :rules="rules"
              accept=".xls, .xlsx, .ods"
              placeholder="Selecciona un archivo"
              label="Archivo"
              clearable
              loader-height="4"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-checkbox
                    dense
                    label="Actualizar productos para la venta"
                    v-model="esParaAactualizarProductosVenta"
                  >
                  </v-checkbox>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-checkbox
                    disabled
                    dense
                    label="Agregar productos nuevos"
                    v-model="esParaAgregarProductosNuevos"
                  >
                  </v-checkbox>
                </v-list-item-title>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-card
                  flat
                  :class="esParaAgregarProductosNuevos ? '' : 'transparent'"
                >
                  <v-card-text>
                    Ganancia
                    <v-text-field
                      dense
                      outlined
                      v-model="ganancia"
                      id="ganancia"
                      v-validate="'required|decimal:2|max:30'"
                      :counter="30"
                      :error-messages="errors.collect('ganancia')"
                      data-vv-name="ganancia"
                      required
                      type="number"
                      prefix="%"
                      min="0"
                    ></v-text-field>
                    Flete
                    <v-text-field
                      dense
                      outlined
                      v-model="flete"
                      id="fletes"
                      v-validate="'required|integer|max:5'"
                      :counter="30"
                      :error-messages="errors.collect('flete')"
                      data-vv-name="flete"
                      required
                      type="number"
                      min="0"
                      prefix="%"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-row>
          <v-progress-linear
            v-model="tiempoProcesamiento"
            :indeterminate="tiempoProcesamiento < 1 || !tiempoProcesamiento"
            :active="loading && !isArchivoSubido"
            :query="true"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-col v-if="isArchivoSubido && !isErrorSubirArchivo" class="mt-6">
            <span class="title green--text">
              <b>¡ {{ messageExito }} !</b>
            </span>
          </v-col>
          <v-col v-if="isErrorSubirArchivo" class="mt-6">
            <span class="title green--text">
              <b>¡ {{ messageError }} !</b>
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttonCancel>Cancelar</template>
      <template v-slot:buttonOK @click="procesarArchivo()">Guardar</template>
    </dialog-form-generic>
  </div>
</template>

<script>
import DialogFormGeneric from "@/components/dialogs/DialogFormGeneric";
import { subirListaDePrecios } from "@/services/proveedor/listaDePrecios.js";

export default {
  name: "ProveedorListaDePrecioUpdate",
  $_veeValidate: {
    validator: "new",
  },
  components: { DialogFormGeneric },
  data() {
    return {
      esParaAgregarProductosNuevos: true,
      esParaAactualizarProductosVenta: true,
      ganancia: 50,
      flete: 0,
      dialogFormGenerics: true,
      loading: false,
      files: null,

      id_hilo: null,
      isArchivoSubido: false,
      isErrorSubirArchivo: false,
      messageError: null,
      messageExito: null,
      estado: 0,
      rules: [
        (value) =>
          !value ||
          value.size > 5000 ||
          "El archivo debe pesar como mínimo 5kB!",
      ],
    };
  },
  computed: {
    tiempoProcesamiento() {
      if (this.estado && this.estado.procesado > 0 && this.estado.total > 0) {
        return parseInt((this.estado.procesado * 100) / this.estado.total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialogFormGenerics = false;
      this.$emit("close-dialog");
    },
    updateListaPrecio() {
      this.loading = true;
      var data = {
        nombre: this.files.name,
        id_proveedor: this.$route.params.id_proveedor,

        ganancia: this.ganancia,
        flete: this.flete,
      };
      subirListaDePrecios(
        this.files,
        this.$route.params.id_proveedor,
        this.ganancia,
        this.flete,
        this.esParaAgregarProductosNuevos,
        this.esParaAactualizarProductosVenta,
        true
      )
        .then((response) => {
          console.log(response);
          console.log(response.data.id);
          this.id_hilo = response.data.id;

          this.verificarEstado();
        })

        .catch((e) => {
          this.isErrorSubirArchivo = true;
          this.messageError = "Error al procesar el archivo";
        });
    },
    verificarEstado() {
      this.axios
        .get(`/productos/estadoprocesamiento/${this.id_hilo}`)
        .then((response) => {
          this.estado = response.data;

          if (response.data.estado == 0) {
            setTimeout(() => {
              this.verificarEstado();
            }, 1325);
          } else {
            this.estado.procesado = this.estado.total - 1;
            this.estado.procesado = this.estado.total;
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              color: "success",
              text: "Lista de precio subida con éxito",
              icon: "mdi-bell-ring",
              timeout: 4000,
            });
            this.messageExito = "Archivo subido con éxito";
            this.isArchivoSubido = true;

            this.loading = false;
            setTimeout(() => {
              this.closeDialog();
              this.$emit('refresh-lista-precio');

            }, 2000);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>