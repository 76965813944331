<template>
  <layout-two-column v-if="listaDePrecio">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="mt-2 pa-0 pl-4" :items="navigation" divider=">">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.to" :disabled="item.disabled">
            <router-link style="text-decoration: none" :to="item.to" replace>
              <span class="caption">{{ item.text }}</span>
            </router-link>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template v-slot:title>
      <v-list class="pt-0 mt-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h1 class="text-wrap" style="font-size: 30px">
                Registrar producto mediante '{{ listaDePrecio.nombre }}'
              </h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-container>
        <div>
          <v-divider></v-divider>
          <div class="mt-2">
            <b>Categoría de los productos</b>
          </div>
          <div style="font-size: 14px">
            Seleccione la categoría a la que pertenecerán los productos
          </div>
          <div class="mt-1">
            {{ categoria }}
            <categoria-select
              :key="reloadCategoria"
              @process-categoria="setCategoria"
              :categoria="categoria"
              :outlined="true"
            ></categoria-select>
          </div>
        </div>

        <div name="Ganancia">
          <v-divider></v-divider>
          <div class="mt-1">
            <b>Ganancia del producto</b>
          </div>
          <div style="font-size: 14px">
            Ingresa el procentaje de ganancia que tendrán los productos.
          </div>
          <div class="mt-1">
            <v-text-field
              :prefix="ganancia ? '%' : ''"
              v-model="ganancia"
              v-validate="'required|decimal:2|max:5'"
              :error-messages="errors.collect('ganancia')"
              data-vv-name="ganancia"
              type="number"
              label="Ganancia"
              required
              outlined
            ></v-text-field>
          </div>
        </div>
      </v-container>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <v-btn
          block
          color="green lighten-2"
          @click="crearProducto"
          :disabled="errors.any() || !esValidoRegistrarProductosDesdeLista"
          >Registrar productos</v-btn
        >
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <ProductoListaDePrecio></ProductoListaDePrecio>
        <router-view></router-view>
      </v-container>
    </template>
  </layout-two-column>
</template>

<script>
import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
import ProductoListaDePrecio from "@/components/producto/registrar/ProductoListaDePrecio";
import CategoriaSeleccionarList from "@/components/categorias/consultar/CategoriaSeleccionarList";
import CategoriaSelect from "@/components/categorias/consultar/categoria.select.vue";

import ListaDePrecioAPI from "@/api/proveedores/listaDePrecio";
import ProductosAPI from "@/api/productos";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "lista-de-precios",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    LayoutTwoColumn,
    ProductoListaDePrecio,
    CategoriaSeleccionarList,
    CategoriaSelect,
  },
  data() {
    return {
      categoria: null,
      reloadCategoria: 0
    };
  },
  computed: {
    ...mapState("categorias", ["categoriaSelected"]),
    ...mapState("proveedor", [
      "productos_de_lista_para_registrar",
      "listaDePrecio",
    ]),
    ...mapGetters("proveedor", [
      "esValidoRegistrarProductosDesdeLista",
      "ganancia",
    ]),
    validarButton() {
      return false;
    },
    navigation() {
      if (this.listaDePrecio) {
        return [
          {
            text: "Proveedores",
            disabled: false,
            to: "/proveedores",
          },
          {
            text: `${this.listaDePrecio.proveedor.nombre}`,
            disabled: false,
            to: `/proveedores/${this.listaDePrecio.proveedor.id}/lista-de-precio`,
          },
          {
            text: `${this.listaDePrecio.nombre}`,
            disabled: true,
            to: `/productos/registrar/lista-de-precios/${this.listaDePrecio.proveedor.id}`,
          },
        ];
      } else {
        [
          {
            text: "Proveedores",
            disabled: false,
            to: "/proveedores",
          },
        ];
      }
    },
    ganancia: {
      get() {
        return this.productos_de_lista_para_registrar.ganancia;
      },
      set(val) {
        this.SET_GANANCIA_LISTA_PRECIO(val);
      },
    },
  },
  methods: {
    ...mapMutations("proveedor", [
      "SET_GANANCIA_LISTA_PRECIO",
      "SET_ID_CATEGORIA_LISTA_PRECIO",
      "SET_PROVEEDOR_ID_LISTA_PRECIO",
      "SET_LISTA_PRECIO",
      "RESET_PRODUCTOS_DE_LISTA_PARA_REGISTRAR",
    ]),
    ...mapActions("proveedor", ["registrarProductosDesdeListaDePrecios"]),
    resetCategoria(){
      this.categoria = null
      this.reloadCategoria = !this.reloadCategoria
    },
    crearProducto() {
      this.registrarProductosDesdeListaDePrecios()
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Productos registrados`,
            icon: "mdi-check",
            timeout: 2500,
          });
          this.RESET_PRODUCTOS_DE_LISTA_PARA_REGISTRAR();
          this.resetCategoria()
          

        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `No se pudieron registrar los productos de la lista`,
            icon: "mdi-information",
            timeout: 2500,
          });

          this.RESET_PRODUCTOS_DE_LISTA_PARA_REGISTRAR();
          this.resetCategoria()

        });
    },
    setCategoria(categoria) {
      this.categoria = categoria;
      var categoria = categoria ? categoria.id : null;
      this.SET_ID_CATEGORIA_LISTA_PRECIO(categoria);
    },

    getListaDePrecio(id_proveedor) {
      if (!this.listaDePrecio) {
        ListaDePrecioAPI.show(id_proveedor).then((response) => {
          this.SET_LISTA_PRECIO(response.data);
          this.SET_PROVEEDOR_ID_LISTA_PRECIO(response.data.proveedor.id);
        });
      }
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (params.id_proveedor) {
          this.getListaDePrecio(params.id_proveedor);
        } else {
          this.$router.push("/productos");
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>