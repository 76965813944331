<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="remitos"
      class="row-pointer transparent"
      no-data-text="No se encontraron resultados"
      @click:row="handleClick"
      hide-default-footer
    >
      <!-- <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
      </template> -->
      <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
      </template>
      <template v-slot:item.estaPagado="{ item }">
        <v-chip
          v-if="item.estaPagado"
          label
          class="ma-2 green darken-2 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          Pagado
        </v-chip>
        <v-chip v-else label class="ma-2 orange darken-4 pa-2 text-capitalize">
          <v-avatar left>
            <v-icon>mdi-cash</v-icon>
          </v-avatar>
          <b>Pendiente de pago</b>
        </v-chip>
        <!-- <v-btn color="green darken-2" >{{
          item.getEstadosActual
        }}</v-btn> -->
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-pagination
          v-if="remitos"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";

export default {
  name: "remito.consultar.lista",
  components: {
    DetallePedidoForm,
  },
  props: {
    remitos: {
      type: Array,
      required: true,
    },
    paginacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Fecha Creación", value: "fechaAlta", sortable: false },
        // { text: "Creacion", value: "fechaAlta", sortable: false },
        { text: "codigo", value: "codigo", sortable: false },


        { text: "Nº", value: "numero", sortable: false },
        { text: "Proveedor", value: "proveedor.nombre", sortable: false },
      ],
    };
  },

  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      this.$emit("set-page", pageNumber);
    },
    handleClick(pedido) {
      this.$emit("abrir-vista-previa", pedido);
    },
    async abrirPedido(pedido) {
      this.$router.push({
        name: "ActualizarPedido",
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
