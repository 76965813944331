<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon class="grey lighten-1" dark> mdi-account </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          >{{ arqueo.usuario.toUpperCase() }} -
          {{ arqueo.ventas.total | convertPesoArg }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                text
                icon
                :color="diferenciaDeCaja(arqueo) == 0 ? 'green' : 'red'"
              >
                <v-icon>{{
                  diferenciaDeCaja(arqueo) == 0 ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon>
              </v-btn>
            </template>

            <span>{{
              diferenciaDeCaja(arqueo) == 0 ? "La caja dió bien" : "La caja no dió bien"
            }}</span>
          </v-tooltip>
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ moment(arqueo.fechaInicio).format("HH:mm") }} -
          {{ arqueo.esVigente ? " - " : moment(arqueo.fechaCierre).format("HH:mm") }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-chip
          class="ma-2"
          :color="arqueo.esVigente ? '#20BB4E' : '#FF6862'"
          text-color="white"
        >
          {{ arqueo.esVigente ? " Activo " : "Cerrada" }}
        </v-chip>
      </v-list-item-action>
    </v-list-item>
    <v-list-item @click="verDetalleArqueo(arqueo)">
      <v-list-item-avatar> </v-list-item-avatar>
      <v-list-item-title
        ><span style="color: #4683f9"
          ><strong>Ver detalles</strong></span
        ></v-list-item-title
      >
      <v-list-item-action>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "CardVistaPreviaArqueo",
  props: {
    arqueo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    verDetalleArqueo(arqueo) {
      this.$emit("ver-detalles", arqueo);
    },
    textDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "Faltante";
      } else {
        return "Sobrante";
      }
    },
    iconDiferenciaCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return "mdi-cash-minus";
      } else {
        return "mdi-cash-plus";
      }
    },
    diferenciaDeCaja(arqueo) {
      if (arqueo.montoCierre >= arqueo.montoCierreConteo) {
        return arqueo.montoCierre - arqueo.montoCierreConteo;
      } else {
        return arqueo.montoCierreConteo - arqueo.montoCierre;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
