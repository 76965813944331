<template>
  <div>
    <v-card flat width="250px">
      <v-list class="pb-0 pt-0 mb-0" dense>
      <span class="text-body-2"><b>Proveedor</b></span>

        <v-list-item
         class="mt-0 mb-0"
          @click="set_select_data(proveedor)"
          v-for="proveedor in proveedoresFilter"
          :key="proveedor.id"
          link
        >
            <v-list-item-title>{{ proveedor.nombre }}</v-list-item-title>
        </v-list-item>
        <v-list-item class="mt-0 mb-0" v-if="proveedores.length > 5" link>
          <v-list-item-content>
            <v-list-item-title @click="dialog = true">
              <div class="blue--text">Ver todos</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog v-model="dialog" width="750">
      <v-card>
        <v-container>
          <v-card-title>
            Elegir Proveedor
            <v-spacer></v-spacer>
            <v-icon color="blue" @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                @click="set_select_data(p)"
                v-for="p in proveedoresOrdenados"
                :key="p.id"
                cols="3"
              >
                <div>
                  <a class="white--text">{{ p.nombre }}</a>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ProveedorListSelect",
  props: {
    proveedores: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    proveedoresFilter() {
      if (this.proveedores.length > 5) {
        return this.proveedores.slice(0, 5);
      } else {
        return this.proveedores;
      }
    },
    proveedoresOrdenados() {
      function compare(a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
        return 0;
      }
      return this.proveedores.sort(compare);
    },
  },

  methods: {
    set_select_data(data) {
      this.dialog = false;
      this.$emit("set_proveedor", data);
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped></style>
