<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Nuevo asiento contable</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-container>
            <AsientoTipoListSelect
              :asientoTipo="nuevoAsiento"
              @select:asiento="setAsiento"
              ref="asientoTipoForm"
            ></AsientoTipoListSelect>
            <AsientoContableForm
              :asiento="nuevoAsiento"
              ref="asientoTipoForm"
            ></AsientoContableForm>
            <AsientoContableDetalleForm
              :detalles="nuevoAsiento.detalles"
              ref="detalleAsientoForm"
            ></AsientoContableDetalleForm>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row justify="end" class="mt-4">
                <v-btn color="success" @click="submitForm">Registrar</v-btn>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AsientoTipoListSelect from "@/components/contabilidad/asiento/asientotipo/AsientoTipoListSelect.vue";
import AsientoContableForm from "@/components/contabilidad/asiento/mantenimiento/AsientoContableForm.vue";
import AsientoContableDetalleForm from "@/components/contabilidad/asiento/mantenimiento/AsientoContableDetalleForm.vue";
import { mapActions } from "vuex";

export default {
  name: "AsientoTipoRegistrarView",
  inject: {
    $validator: "$validator",
  },
  components: {
    AsientoTipoListSelect,
    AsientoContableDetalleForm,
    AsientoContableForm,
  },
  data() {
    return {
      nuevoAsiento: {
        numero: "",
        detalles: [], // Puedes inicializar los detalles según sea necesario
      },
    };
  },
  methods: {
    ...mapActions("comprobantes", ["registrar_asiento_contable"]),
    setAsiento(asiento) {
      this.nuevoAsiento = JSON.parse(JSON.stringify(asiento));
    },
    async submitForm() {
      const isDetalleAvailable =
        await this.$refs.detalleAsientoForm.validateDetalles();
      const isAsientoTipoValid =
        await this.$refs.asientoTipoForm.validateForm();

      if (!isAsientoTipoValid || !isDetalleAvailable) {
        console.log(isAsientoTipoValid);
        console.log(isDetalleAvailable);

        return;
      }
      try {
        const datosParaEnviar = {
          numero: this.nuevoAsiento.numero,
          detalles: this.nuevoAsiento.detalles,
        };
        const response = await this.registrar_asiento_contable(datosParaEnviar);
        this.$router.push("/contabilidad/asiento/mantenimiento");
      } catch (e) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e.response.data.detail}`,
          icon: "mdi-information",
          timeout: 3000,
        });
        console.error("Error al registrar el asiento tipo:", e);
      }
    },
  },
};
</script>
