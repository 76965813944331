<template>
    <layout-two-column>
      <template v-slot:breadcrumbs>
        <v-breadcrumbs class="mt-2 pa-0 pl-4" :items="navigation" divider=">">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.to" :disabled="item.disabled">
              <router-link style="text-decoration: none" :to="item.to" replace>
                <span class="caption">{{ item.text }}</span>
              </router-link>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
      <template v-slot:title>
        <v-list class="pt-0 mt-0">
          <v-list-item>
            <v-list-item-title>
              <div v-if="estaUsandoBuscador">
                <h3 class="text-wrap" style="font-size: 24px">
                  Resultados de la búsqueda:
                </h3>
                <span style="font-size: 24px">
                  {{ $route.query.q }}
                </span>
              </div>
              <h1 class="text-wrap" style="font-size: 30px" v-else>Fraccionados</h1>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <producto-buscador
                @process-buscador-producto="goToBuscador"
                @process-buscador-producto-select="goToBuscador"
              ></producto-buscador>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:navigation-controller>
        <v-list nav dense>
          <v-list-item-group mandatory v-model="itemMenu">
            <v-list-item
              @click="$router.push({ name: 'productosFraccionados' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              @click="$router.push({ name: 'inventarioControl' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-format-list-checks</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Control de stock</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list-item-group>
        </v-list>
        <productos-filtros></productos-filtros>
      </template>
      <template v-slot:vista-previa>
        <v-container>
          <router-view></router-view>
        </v-container>
      </template>
    </layout-two-column>
  </template>
  
  <script>
  import LayoutTwoColumn from "@/layouts/LayoutBasic.vue";;
  import ProductoBuscador from "@/components/producto/buscador/producto.buscador.vue";
  import ProductosFiltros from "@/components/producto/filtros/productos.filtros.vue";
  import ProductosFiltrosTag from "@/components/producto/filtros/productos.filtros.tag.vue";
  
  export default {
    name: "ProductoHomeInventario",
    components: {
      LayoutTwoColumn,
      ProductoBuscador,
      ProductosFiltros,
      ProductosFiltrosTag,
    },
    data() {
      return {
        itemMenu: 0,
        filtroActivo: null,
        estaUsandoBuscador: false,
        tags: [
          { nombre: "Control de stock", key: "inventario", value: true },
          { nombre: "Sin control de stock", key: "inventario", value: false },
          { nombre: "Stock bajo", key: "stockbajo", value: true },
          { nombre: "Sin stock", key: "sinstock", value: true },
        ],
        navigation: [
          {
            text: "Productos",
            disabled: false,
            to: "/productos",
          },
        ],
      };
    },
    methods: {
      get_text_transform(str) {
        if (typeof str === "string") {
          return str.match(/[^ ]+/g).join("-");
        } else {
          return str;
        }
      },
      add_query_url(value) {
        var querys = this.$route.query;
  
        for (const property in value) {
          var name = value[property];
          value[property] = this.get_text_transform(name);
          querys = Object.assign({}, querys, value);
        }
        this.$router.replace({ query: querys }).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      },
      goToBuscador(search) {
        if (search == "") {
          this.$router
            .replace({ path: "/productos/inventario" })
            .catch((e) => {});
          return;
        }
        if (search == this.$route.query.q) {
          return;
        }
        this.add_query_url({ q: search });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>