<template>
  <v-card width="500" flat>
    <!-- <v-img
        height="250"
        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        
      ></v-img> -->
    <v-img contain id="fondo-imagen" src="@/assets/home-empresa.png" height="200px" dark>
      <v-row align="end" class="fill-height">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div
                v-if="empresa.nombreFantasia !== null && empresa.nombreFantasia !== ''"
                class="display-1 text-start"
              >
                {{ empresa.nombreFantasia }}
              </div>
              <div v-else class="title text-center">Aquí va el nombre de fantasía</div>
            </v-list-item-title>
            <v-list-item-subtitle>Nombre fantasía</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-card-title>
        
        </v-card-title> -->
      </v-row>
    </v-img>
    <!-- <v-img
      id="fondo-imagen"
      contain
     
      height="250"
    ></v-img> -->
    <!-- <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> Inicio de actividad </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip small class="ma-2" color="cyan" label text-color="white">
                {{ empresa.fecha_inicio_actividades }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    <v-list dense class="pa-0 ma-0">
      <v-list-item class="text-end">
        <v-list-item-content>
          <v-list-item-title> Inicio de actividad </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip small class="ma-2" color="cyan" label text-color="white">
              {{ empresa.fecha_inicio_actividades }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon v-text="'mdi-cellphone-android'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="empresa.razonSocial"></v-list-item-title>
          <v-list-item-subtitle>Razón social </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-avatar>
          <v-icon v-text="'mdi-email'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ empresa.direccion }} </v-list-item-title>
          <v-list-item-subtitle> Dirección </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon v-text="'mdi-email'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ empresa.cuit }} </v-list-item-title>
          <v-list-item-subtitle> Dirección </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon v-text="'mdi-email'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ empresa.condicionIva }} </v-list-item-title>
          <v-list-item-subtitle> Dirección </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-icon v-text="'mdi-map-marker-radius'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ empresa.direccion }} </v-list-item-title>
          <v-list-item-subtitle
            >{{ empresa.localidad.nombre }} -
            {{ empresa.localidad.provincia.nombre }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "empresa.vista-previa",
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
#fondo-imagen {
  background: rgb(110, 110, 110);
  background: linear-gradient(
    0deg,
    rgba(110, 110, 110, 1) 0%,
    rgba(255, 255, 254, 1) 100%
  );
}
</style>
