<template>
  <div>
    <div>
      <h1>Resumen por día</h1>
    </div>

    <v-row class="mt-4">
      <v-col cols="12" md="3" sm="6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs"
              label="Seleccioná el día"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            locale="es"
            :max="moment().format('YYYY-MM-DD')"
            v-model="date"
            @change="changeDate"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <loading :active.sync="loading"></loading>
    </v-row>
    <v-row v-if="reporte && !loading">
      <v-col cols="12" md="12">
        <template v-if="reporte && reporte.cantidad == 0">
          <div>
            <v-alert> No hay registros de actividad. </v-alert>
          </div>
        </template>
        <template v-if="arqueos.length > 0">
          <ventas-por-forma-de-pago
            :ventas="reporte.ventas"
          ></ventas-por-forma-de-pago>
          <v-row class="mt-2">
            <v-col
              cols="12"
              md="6"
              v-for="(arqueo, index) in arqueos"
              :key="index"
            >
              <h3>Actividades</h3>
              <card-vista-previa-arqueo
                @ver-detalles="verDetalleArqueo"
                :arqueo="arqueo"
              >
              </card-vista-previa-arqueo>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import VentasPorFormaDePago from "@/components/reportes/VentasPorFormaDePago.vue";
import CardVistaPreviaArqueo from "../../../components/reportes/CardVistaPreviaArqueo.vue";
export default {
  name: "reporte.arqueo.caja",
  components: {
    Loading,
    VentasPorFormaDePago,
    CardVistaPreviaArqueo,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      expanded: [],
      detalleHeaders: [
        { text: "Descripción", value: "descripcion" },
        { text: "Debe", value: "debe" },
        { text: "Haber", value: "haber" },
         { text: "Cuenta", value: "cuenta" }
      ],
      date: this.moment().format("YYYY-MM-DD"),
      loading: false,
      menu2: false,
      sinArqueo: false,
      arqueos: [],
      reporte: null,
    };
  },
  computed: {
    queryParams() {
      return this.$route.query || "";
    },
    computedDateFormattedMomentjs() {
      return this.date ? this.moment(this.date).format("ll") : "";
    },
   
  },
  methods: {
    changeDate() {
      this.reporte = null;
      this.$router.replace({
        path: "/reportes/resumen",
        query: { date: this.date },
      });
      this.fetchReporteArqueo();
    },
    verDetalleArqueo(arqueo) {
      this.$router
        .push({
          name: "reportesResumenDetalle",
          params: { arqueo: arqueo },
        })
        .catch(() => {});
    },
    fetchReporteArqueo() {
      console.log("Hola", this.date);
      this.loading = true;
      var url = `/reportes/reporteArqueoCaja?fecha=${this.date}`;
      this.axios
        .get(url)
        .then((response) => {
          this.arqueos = response.data.arqueos;
          this.reporte = response.data;
      

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status && e.response.status === 404) {
            this.loading = false;
            this.arqueo = null;
          }
          this.loading = false;
        });
    },
  },

  created() {
    if (Object.keys(this.queryParams).length !== 0) {
      //Si la fecha que llega como parametro es menor a hoy
      console.log(this.moment(this.queryParams.date).isValid());
      if (this.moment(this.queryParams.date).isValid()) {
        if (
          new Date(this.queryParams.date).toISOString().substr(0, 10) <
          this.date
        ) {
          this.date = this.queryParams.date;
        }
      }
    }

    this.fetchReporteArqueo();
  },
};
</script>

<style lang="scss" scoped></style>
