<template>
  <span v-if="pedido">
    <LayoutRegistrar
      :showLinksMenu="false"
      :routeBack="'/compras/pedidos/consultar'"
    >
      <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5" :items="navigation" divider=">">
          <v-breadcrumbs-item
            slot="item"
            slot-scope="{ item }"
            exact
            :to="item.to"
            >{{ item.text }}</v-breadcrumbs-item
          >
        </v-breadcrumbs>
      </template>
      <template v-slot:title
        >Pedido nº {{ pedido.numeroVentaPorArqueo }}</template
      >
      <template v-slot:subtitle>
        <v-list-item>
          <v-list-item-title>
            <v-alert
              dense
              :color="estado == 'PENDIENTE' ? 'orange' : 'blue'"
              type="info"
            >
              {{ estado }}
            </v-alert>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:navigation-controller>
        <div>
          <!-- Tipo de entrega y fecha estimada -->

           <v-list-item>

                <v-list-item-avatar>
                <v-icon v-text="'mdi-calendar'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="'Fecha entrega estimada'"></v-list-item-title>
                <v-list-item-subtitle>{{ fechaEntregaEstimada ? fechaEntregaEstimada : 'Sin especificar' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>

                <v-list-item-avatar>
                <v-icon v-text="'mdi-truck-outline'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="'Tipo entrega'"></v-list-item-title>
                <v-list-item-subtitle>{{ tipoEntrega.nombre }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-calendar-range'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="'Fecha de creación'"
                ></v-list-item-title>
                <v-list-item-subtitle>{{ fechaCreacion }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-folder'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="'Última modificación'"
                ></v-list-item-title>
                <v-list-item-subtitle>{{
                  ultimaActualizacion
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>

      <template v-slot:vista-previa>
        <pedido-detalle-actualizar
          :disabledDetalle="true"
          v-if="detalles_pedido.length > 0"
          :detalles="detalles_pedido"
        ></pedido-detalle-actualizar>
      </template>
      <template v-slot:detalle-pedido>
        <info-proveedor :proveedor="pedido.proveedor"></info-proveedor>

      
      </template>
    </LayoutRegistrar>

    <dialog-confirmation-close
      v-if="dialogDiscardChanges"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar actualización?</template>
      <template v-slot:message
        >¿Seguro que quieres salir? Se perderán todos los cambios.</template
      >
      <template v-slot:buttonOK>Salir de la página</template>
    </dialog-confirmation-close>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
import AgregarDetallePedido from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";
import LayoutRegistrar from "@/layouts/layout.registrar.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import PedidoDetalleActualizar from "../../../components/pedido/actualizar/pedido.detalle.actualizar.vue";
export default {
  name: "pedido.actualizar",
  components: {
    AgregarDetallePedido,
    InfoProveedor,
    LayoutRegistrar,
    DialogConfirmationClose,
    PedidoDetalleActualizar,
  },
  data() {
    return {
      tipoEntrega: 0,
      dialogPedidoCancelar: false,
      posibilidadesEntrega: [
        { id: 0, nombre: "ENTREGA EN EL LOCAL" },
        { id: 1, nombre: "PARA RETIRAR" },
      ],
      menuFechaEntrega: false,
      fechaEntregaEstimada: "",
      get_detalles: [],
      pedido: null,
      detalles_pedido: [],
      estado: null,
      estadosSiguientes: [],

      flagCambioTipoEntrega: false,
      flagCambioFechaEntrega: false,

      dialog: false,
      producto_buscado: null,
      dialogDiscardChanges: false,

      toNextRoute: null,

      initCantidadDetalles: null,
      loadingButtonG: false,
      loadingButtonGA: false,
      loadingButtonGF: false,
      loadingButton: false,
    };
  },
  computed: {
    fechaCreacion() {
      return this.moment(this.pedido.fechaAlta).format("LL H:mma");
    },
    ultimaActualizacion() {
      return this.moment(this.pedido.fechaUltModificacion).format("LL H:mma");
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoCompra" },
        },
        {
          text: `Nº ${this.pedido.numeroVentaPorArqueo}`,
          disabled: false,
          to: { name: "ActualizarPedido" },
        },
      ];
    },
  },
  methods: {
    fetchPedido(id) {
      var url = `/pedidos/compra/${id}`;

      this.axios.get(url).then((response) => {
        this.initCantidadDetalles = response.data.detalles_pedido.length;
        this.pedido = response.data;
        this.detalles_pedido = response.data.detalles_pedido;
        this.estado = response.data.getEstadosActual;
        this.tipoEntrega = response.data.tipoEntrega;
        this.fechaEntregaEstimada = response.data.fechaEntregaEstimada;

        this.estadosSiguientes = response.data.getEstadosSiguientesDescripcion;
      });
    },

    volver() {
      this.toNextRoute = true;

      this.clean_detalles_update();
      var route = "pedidoCompra";

      this.$router.push({ name: route });
    },

    goToNextRoute() {
      this.toNextRoute = true;
      var route = "pedidoCompra";

      this.$router.push({ name: route });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.is_update_detalles) {
      next();
    } else {
      if (this.toNextRoute) {
        this.clean_detalles_update();

        next();
      } else {
        this.dialogDiscardChanges = true;
        this.toNextRoute = to;
      }
    }
  },
  created() {
    let id = this.$route.params.id;
    this.fetchPedido(id);
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
