import ProveedorListaDePrecioAPI from "@/api/proveedores/listaDePrecio";

async function subirListaDePrecios(file, id_proveedor, ganancia, flete, esParaAgregarProductosNuevos, esParaAactualizarProductosVenta, isUpdate=false) {
    console.log("ENTERE")
    console.log(file, id_proveedor, ganancia, flete, esParaAactualizarProductosVenta, esParaAgregarProductosNuevos);
    if (!file || !id_proveedor){
        return
    }


    var form_data = new FormData();
    form_data.append("file", file);
    form_data.append("proveedor", id_proveedor);
    form_data.append("flete", flete);
    form_data.append("ganancia", ganancia);

    if (isUpdate){
        form_data.append("esParaAgregarProductosNuevos", esParaAgregarProductosNuevos);
    
        form_data.append("actualiza_producto", esParaAactualizarProductosVenta);
    }

    return ProveedorListaDePrecioAPI.subirListaDePrecios(form_data)
    

}

export { subirListaDePrecios }
