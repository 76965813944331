var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items3"},[_c('v-snackbar',{attrs:{"color":"error","multi-line":""},model:{value:(_vm.error_detalle_repetido.state),callback:function ($$v) {_vm.$set(_vm.error_detalle_repetido, "state", $$v)},expression:"error_detalle_repetido.state"}},[_vm._v(_vm._s(_vm.error_detalle_repetido.message))]),_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.detalles,"sort-by":"calories","no-data-text":"No hay productos agregados","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.producto.cantidadstock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.producto.controlaStock ? item.producto.cantidadstock : "N/A")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogEditDetalle(item)}}},[_c('v-list-item-title',[_vm._v("Editar cantidad")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.eliminarDetalle(item)}}},[_c('v-list-item-title',[_vm._v("Quitar")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialogDetalleForm),callback:function ($$v) {_vm.dialogDetalleForm=$$v},expression:"dialogDetalleForm"}},[(_vm.dialogDetalleForm)?_c('comprobante-detalle-form',{ref:"detalleForm",attrs:{"detalle":_vm.detalleEdit},on:{"close-dialog":function($event){_vm.dialogDetalleForm = false},"process-detalle":_vm.editarCantidad}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }