<template>
    <div>
        <v-row>
            <v-col>
                
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="5">
               
                <v-data-table :headers="headers" :items="productos" @click:row="handleClick" class="row-pointer transparent"
                    hide-default-footer :item-class="itemRowSelected">
                    <template v-slot:[`item.fraccion`]="{ item }">
                        <v-chip v-if="item.fraccion" :color="getStatus(item).color" dark>

                            Me puedo fraccionar
                        </v-chip>
                    </template>
                </v-data-table>
                <v-pagination v-if="productos.length" @input="paginationChangeHandler" :length="pages"
                    :value="paginacion.page" circle></v-pagination>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col v-if="producto" cols="7">
                <v-row justify="center" v-if="!loadingProductosFraccionados">
                    <v-col cols="8">
                        
                        <h2 v-if="!producto.fraccion">
                            No tienes productos fraccionados de {{ producto.descripcion }}
                        </h2>
                        <v-btn class="mt-4" color="info" @click="dialogRegistrarProductoFraccionado = true">Nuevo producto fraccionado</v-btn>
                        <template v-if="producto.fraccion">
                            <v-card class="mx-auto" v-for="producto in productosFraccionados" :key="producto.id">
                             <producto-item :producto="producto"></producto-item>
                            </v-card>
                        </template>

                        
                        
                        <v-dialog v-model="dialogRegistrarProductoFraccionado" width="500">

                            <producto-form-fraccionado @close-dialog="dialogRegistrarProductoFraccionado = false"
                                @process-register="registrarProductoFraccionado"
                                :producto="productoRegister"></producto-form-fraccionado>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-progress-linear
                v-else
            :indeterminate="true"
            :active="loadingProductosFraccionados "
          >
          
          </v-progress-linear>
            </v-col>
        </v-row>

        <template v-else>
            <v-skeleton-loader max-height="600" type="table-heading"></v-skeleton-loader>

            <v-skeleton-loader max-height="1800px" type="table-tbody"></v-skeleton-loader>
        </template>
    </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";
import ProductoFormFraccionado from "@/components/producto/ProductoFormFraccionado.vue"
import ProductoItem from "@/components/producto/producto.item.vue";

export default {
    name: "ProductosFraccionados",
    components: { ProductoFormFraccionado, ProductoItem },
    data() {
        return {

            paginacion: { page: 1, cantidad: 8, rowsPerPage: 12 },
            productos: [],
            productosFraccionados: [],
            loading: false,

            filtroActivo: {
                nombre: "Control de stock",
                key: "inventario",
                value: true,
            },
            producto: null,
            productoRegister: {
                descripcion: "",
                precioCosto: 0,
                ganancia: 0,
                codigoDeBarra: null,
                flete: 0,
                iva: 0,
            },

            model: null,
            tags: [
                { nombre: "Control de stock", key: "inventario", value: true },
                { nombre: "Sin control de stock", key: "inventario", value: false },
                { nombre: "Stock bajo", key: "stockbajo", value: true },
                { nombre: "Sin stock", key: "sinstock", value: true },
            ],
            dialogRegistrarProductoFraccionado: false,
            loadingProductosFraccionados: false,
        };
    },
    computed: {
        ...mapState("productos", ["productos_inventario"]),
        pages() {
            if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
                return 0;
            }

            return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
        },
        isFiltroSinControl() {
            return this.filtroActivo.key == "inventario" && !this.filtroActivo.value;
        },
        headers() {
            var array = [
                {
                    text: "Nombre ( descripción )",
                    align: "left",
                    sortable: false,
                    value: "descripcion",
                    width: "30%",
                },
                {
                    align: "left",
                    sortable: false,
                    text: "Categoría",
                    value: "categoriaArbol.name",
                    width: "2%",
                },
                {
                    align: "left",
                    sortable: false,
                    text: "Fraccion",
                    value: "fraccion",
                    width: "1%",
                },

            ];

            if (this.isFiltroSinControl) {
                array.splice(2, 1);
            }
            return array;
        },
    },

    methods: {
        ...mapActions("productos", [
            "getProductosSinInventario",
            "getProductosInventario",
            "actualizar_inventario",
        ]),

        itemRowSelected(item) {
            if (this.producto) {
                if (this.producto.id == item.id) {
                    return "blue lighten-4 black--text";
                }
            }
            return "";
        },
        handleClick(producto) {
            this.loadingProductosFraccionados = true
                // this.$router.push({name: 'añadirProductoFraccionado', params: { id: producto.id, producto: producto }})
                ProductosAPI.showProductosFraccionados(this.producto.id).then((response) => {
                    
                    this.productosFraccionados = response.data
                    this.loadingProductosFraccionados = false
                    
                })
                .catch((e) => {
                    this.$updateBus.$emit("showSnackBar", {
                        show: true,
                        color: "danger",
                        text: "No se pudo registrar el producto",
                        icon: "mdi-bell-ring",
                        timeout: 3000,
                    });
                    this.loadingProductosFraccionados = false
                    
                });
                const copy = { ...producto };
                this.producto = copy;
        },
        getStatus(producto) {
            var status = {
                nombre: "",
                color: "",
            };
            var cantidadstock = parseInt(producto.cantidadstock);
            var stockmin = parseInt(producto.stockmin);
            var stockmax = parseInt(producto.stockmax);

            if (stockmax > 0 && cantidadstock > stockmax) {
                status.color = "purple darken-2";
                status.nombre = "Sobrestock";
            } else if (cantidadstock >= stockmin) {
                status.color = "green";
                status.nombre = "Normal";
            } else if (cantidadstock < stockmin && cantidadstock > 0) {
                status.color = "yellow darken-2";
                status.nombre = "Bajo";
            } else {
                status.color = "red";
                status.nombre = "Sin stock";
            }
            return status;
        },
        getColor(producto) {
            var cantidadstock = parseInt(producto.cantidadstock);
            var stockmin = parseInt(producto.stockmin);
            var stockmax = parseInt(producto.stockmax);

            if (cantidadstock >= stockmin) return "green";
            else if (cantidadstock < stockmin && cantidadstock > 0)
                return "yellow darken-2";
            else return "red";
        },
        registrarProductoFraccionado(productoParaRegistrar) {
            var productoPadre = this.producto
            if (!productoPadre) {
                alert("Debes seleccionar un producto")
            }

            ProductosAPI.registrarProductoFraccionado(productoPadre, productoParaRegistrar)
                .then((response) => {

                    this.$updateBus.$emit("showSnackBar", {
                        show: true,
                        color: "success",
                        text: "Registrado",
                        icon: "mdi-check",
                        timeout: 3000,
                    });
                })
                .catch((e) => {
                    this.$updateBus.$emit("showSnackBar", {
                        show: true,
                        color: "danger",
                        text: "No se pudo registrar el producto",
                        icon: "mdi-bell-ring",
                        timeout: 3000,
                    });
                });
        },
        remove_item_inventario(response) {
            if (this.filtroActivo.key == "inventario") {
                if (this.filtroActivo.value != response.data.controlaStock) {
                    this.productos = this.productos.filter(
                        (p) => p.id != response.data.id
                    );
                    this.producto = this.productos[0];
                    if (this.productos.length == 0) {
                        this.fetchProductosInventario();
                    }
                }
            }
        },

        paginationChangeHandler(pageNumber) {
            if (this.paginacion.page == pageNumber) {
                return false;
            }
            if (this.paginacion.page < pageNumber) {
                this.paginacion.page = pageNumber;
                this.fetchProductosInventario();
            } else {
                this.paginacion.page = pageNumber;
                this.fetchProductosInventario();
            }
        },
        get_params() {
            var array = [this.filtroActivo];
            var querys = this.$route.query;
            if (Object.keys(querys).length !== 0) {
                for (const property in querys) {
                    var object = {
                        nombre: null,
                        key: "",
                    };
                    object.key = property;
                    object.value = querys[property];
                    array.push(object);
                }
            }
            return array;
        },
        change(filtro) {
            this.paginacion.page = 1;
            this.filtroActivo = filtro;
            return this.fetchProductosInventario();
        },
        async fetchProductosInventario() {
            this.loading = true;
            var params = this.get_params();
            try {
                const response = await ProductosAPI.fetchInventario(
                    params,
                    this.paginacion
                );
                this.productos = response.data.results;
                this.paginacion.cantidad = response.data.count;
                if (response.data.count > 0) {
                    this.producto = response.data.results[0];
                } else {
                    this.producto = null;
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
    },
    watch: {
        $route: {
            handler: function (route) {
                this.fetchProductosInventario();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.fetchProductosInventario();
    },
};
</script>
  
<style  scoped>
.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>