<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h1>Control de stock</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn>
              <h1>Nuevo control</h1>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-card>
              <v-card-title>
                <h3>Último control</h3>
              </v-card-title>
              <v-card-text>
                {{ moment().format("lll") }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <productos-filtros-tag-card
          :removeTagCategoria="false"
          :closeTag="false"
        ></productos-filtros-tag-card>
        <v-row>
          <v-col>
            <v-slide-group mandatory show-arrows>
              <v-slide-item
                v-for="(tag, index) in tags"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="purple white--text"
                  depressed
                  rounded
                  @change="change(tag)"
                  @click="toggle(tag), change(tag)"
                >
                  {{ tag.nombre }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-col v-if="!loading">
      <v-data-table
        :headers="headers"
        :items="productos"

        hide-default-footer
        class="elevation-1"
      >
        <template slot="headerCell" slot-scope="props">
          <v-tooltip bottom>
            <span slot="activator">
              {{ props.header.text }}
            </span>
            <span>
              {{ props.header.text }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.cantidadstock="{ item }">
          <v-text-field
            :disabled="!item.controlaStock"
            v-model="item.cantidadstock"
          ></v-text-field>
        </template>

        <template v-slot:[`item.gestion`]="{ item }">
          <v-switch v-model="item.controlaStock"></v-switch>
        </template>
        <template v-slot:item.estado="{ item }">
          <v-chip :color="getColor(item)" dark>
            {{ item.cantidadstock }}
          </v-chip>
          asf
        </template>
        <template v-slot:item.stockmin="{ item }">
          <v-text-field
            :disabled="!item.controlaStock"
            v-model="item.stockmin"
          ></v-text-field>
        </template>
        <template v-slot:item.stockmax="{ item }">
          <v-text-field
            :disabled="!item.controlaStock"
            v-model="item.stockmax"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="actualizar_inventario(item)" rounded color="primary"
            >Guardar</v-btn
          >
        </template>
      </v-data-table>
      <v-pagination
        v-if="productos.length"
        @input="paginationChangeHandler"
        :length="pages"
        :value="paginacion.page"
        circle
      ></v-pagination>
    </v-col>
    <template v-else>
      <v-skeleton-loader
        max-height="600"
        type="table-heading"
      ></v-skeleton-loader>

      <v-skeleton-loader
        max-height="1800px"
        type="table-tbody"
      ></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";
import ProductosFiltrosTagCard from "@/components/producto/filtros/productos.filtros.tag.card.vue";

export default {
  name: "ProductoHomeInventarioControl",
  components: {
    ProductosFiltrosTagCard,
  },
  data() {
    return {
      paginacion: { page: 1, cantidad: 8, rowsPerPage: 12 },
      productos: [],
      loading: false,

      filtroActivo: {
        nombre: "Control de stock",
        key: "inventario",
        value: true,
      },

      model: null,
      tags: [
        { nombre: "Control de stock", key: "inventario", value: true },
        { nombre: "Sin control de stock", key: "inventario", value: false },
        { nombre: "Stock bajo", key: "stockbajo", value: true },
        { nombre: "Sin stock", key: "sinstock", value: true },
      ],
    };
  },
  computed: {
    ...mapState("productos", ["productos_inventario"]),

    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
    isFiltroSinControl() {
      return this.filtroActivo.key == "inventario" && !this.filtroActivo.value;
    },
    headers() {
      var array = [
        {
          text: "Nombre (100g serving)",
          align: "left",
          sortable: false,
          value: "descripcion",
          width: "1%",
        },
        {
          align: "left",
          sortable: false,
          text: "Categoría",
          value: "categoriaArbol.name",
          width: "1%",
        },
        {
          align: "left",
          sortable: false,
          text: "Estado",
          value: "estado",
          width: "1%",
        },
        {
          align: "left",
          sortable: false,
          text: "Cantidad",
          value: "cantidadstock",
          width: "0.2%",
        },
        {
          align: "left",
          sortable: false,
          text: "Stock min",
          value: "stockmin",
          width: "0.2%",
        },
        {
          align: "left",
          sortable: false,
          text: "Stock max",
          value: "stockmax",
          width: "0.2%",
        },
        {
          text: "Gestionar stock",
          value: "gestion",
          sortable: false,
          width: "0.1%",
        },
        { text: "Actions", value: "actions", sortable: false, width: "0.1%" },
      ];

      if (this.isFiltroSinControl) {
        array.splice(2, 1);
      }
      return array;
    },
  },

  methods: {
    ...mapActions("productos", [
      "getProductosSinInventario",
      "getProductosInventario",
      "actualizar_inventario",
    ]),
   

    getColor(producto) {
      var cantidadstock = parseInt(producto.cantidadstock);
      var stockmin = parseInt(producto.stockmin);
      var stockmax = parseInt(producto.stockmax);

      if (cantidadstock >= stockmin) return "green";
      else if (cantidadstock < stockmin && cantidadstock > 0)
        return "yellow darken-2";
      else return "red";
    },
    actualizar_inventario(p) {
      var producto = { ...p };
      var marca_id = producto.marca ? producto.marca.id : null;
      producto.marca = marca_id;
      producto.categoriaArbol = producto.categoriaArbol.id;
      producto.proveedor = producto.proveedor.id;

      ProductosAPI.update(producto).then((response) => {
        this.remove_item_inventario(response);
      });
    },
    remove_item_inventario(response) {
      if (this.filtroActivo.key == "inventario") {
        if (this.filtroActivo.value != response.data.controlaStock) {
          this.productos = this.productos.filter(
            (p) => p.id != response.data.id
          );
          if (this.productos.length == 0) {
            this.fetchProductosInventario();
          }
        }
      }
    },

    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.paginacion.page = pageNumber;
        this.fetchProductosInventario();
      } else {
        this.paginacion.page = pageNumber;
        this.fetchProductosInventario();
      }
    },
    get_params() {
      var array = [this.filtroActivo];
      var querys = this.$route.query;
      if (Object.keys(querys).length !== 0) {
        for (const property in querys) {
          var object = {
            nombre: null,
            key: "",
          };
          object.key = property;
          object.value = querys[property];
          array.push(object);
        }
      }
      return array;
    },
    change(filtro) {
      this.paginacion.page = 1;
      this.filtroActivo = filtro;
      return this.fetchProductosInventario();
    },
    async fetchProductosInventario() {
      this.loading = true;
      var params = this.get_params();
      try {
        const response = await ProductosAPI.fetchInventario(
          params,
          this.paginacion
        );
        this.productos = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.fetchProductosInventario();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchProductosInventario();
  },
};
</script>

<style lang="scss" scoped>
</style>