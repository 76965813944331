<template>
   
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <VentasFacturacionList :sucursal_id="sucursal_id" />

      </v-col>
      <v-col cols="6">
        <VentasPlataformaList :sucursal_id="sucursal_id" :tipoPlataforma="tipoPlataforma" />


      </v-col>
       <v-col cols="12" md="12" lg="12">
        <VentasFacturacionList :sucursal_id="sucursal_id" :pendienteFacturacion="true" />
      </v-col>
     
    </v-row>
     
</template>

<script>
import VentasList from "@/components/venta/listar/VentasVTable.vue";
import VentasPlataformaList from './listar/ventas.plataforma.list.vue';
import VentasFacturacionList from './listar/ventas.facturacion.list.vue';

export default {
  name: "venta.home",
  components: {
    VentasList, VentasPlataformaList, VentasFacturacionList
  },

  data() {
    return {
      sucursal_id: 0,
      tipoPlataforma: { nombre: "Pedidos Ya", key: "py" }
      // chartData: {
      //   labels: ["January", "February", "March", "April", "May", "June", "July"],
      //   datasets: [
      //     {
      //       label: "Data One",
      //       backgroundColor: "#f87979",
      //       data: [40, 39, 10, 40, 39, 80, 40],
      //     },
      //   ],
      // },
      // labels: ["January", "February", "March", "April", "May", "June", "July"],
      // datasets: [
      //   {
      //     label: "Data One",
      //     backgroundColor: "#f87979",
      //     data: [40, 39, 10, 40, 39, 80, 40],
      //   },
      // ],
    };
  },
  methods: {
 handleSelectSucursal(sucursal) {
      this.sucursal_id = sucursal.id
    },
    
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
  },
};
</script>

<style lang="scss" scoped></style>
