<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-row>
          <v-col>
            <v-list style="background-color: transparent" flat two-line>
              <v-list-item style="padding-left: 0px">
                <v-list-item-content>
                  <v-list-item-title
                    ><div class="title">Nueva sucursal</div></v-list-item-title
                  >
                </v-list-item-content> 
                <v-list-item-action>
                  <slot name="vista-previa-action"></slot>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <sucursal-form
              :sucursal="sucursal"
              :provincias="provincias"
              :isUpdate="false"
              @process-sucursal="registrarSucursal"
            ></sucursal-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SucursalForm from "@/components/sucursal/sucursal.form.vue";

export default {
  name: "sucursal.registrar",
  components: {
    SucursalForm,
  },
  data() {
    return {
      provincias: [],
    
      sucursal: {
        nombre: "",
        direccion: "",
        telefono: null,
        encargado: null,
        localidad: { id: null },
        condicionIva: null,
        telegram_chat_id: null,

      },
       empresaUuid: null,
    };
  },
  methods: {
    registrarSucursal(data) {
      var url = "/sucursales/";

      // Verificar si hay empresaUuid y agregarlo a la URL
      if (this.empresaUuid) {
        url += `?uuid_empresa=${this.empresaUuid}`;
      }
      this.axios.post(url, this.sucursal).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Sucursal registrada",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.$router.push("/sucursales");
      });
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
    this.empresaUuid = this.$route.params.uuid_empresa || null;
  },
};
</script>

<style lang="scss" scoped></style>
