<template>
  <span>
    <LayoutBasic>
      <template v-slot:title><div>Nuevo pedido</div> </template>
      <template v-slot:isBorrador>
        <v-subheader>
          <v-btn
            :disabled="!isValidBorrador"
            @click="guardarBorrador"
            :text="isValidBorrador"
            :loading="loadingValidBorrador"
            color="info"
          >
            <b>Guardar Borrador</b>
          </v-btn>
        </v-subheader></template
      >

      <template v-slot:navigation-controller>
        <v-container>
          <proveedor
            :title="proveedor ? 'Cambiar proveedor' : 'Seleccionar proveedor'"
            :key="reloadProveedor"
            @set-proveedor-register="setProveedor"
          ></proveedor>
             <pedido-compra-agregar-detalle
            class="mt-4"
            :categorias="proveedor ? proveedor.categorias : []"

            :detalles="detallesPedido"
            :isCompra="true"
          ></pedido-compra-agregar-detalle>

        </v-container>
        <!-- <agregar-detalle-pedido
          :detalles="detallesPedido"
        ></agregar-detalle-pedido> -->
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
          <v-container fill-height fluid>
            <v-row align="center" class="mt-2 mb-2 ml-2 mr-2">
              <v-col cols="12">
                <div class="mb-2">Detalles del pedido</div>
                <pedido-compra-detalles-table
                  :detalles="detallesPedido"
                ></pedido-compra-detalles-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <v-list-item class="pl-1">
            <v-list-item-title>
              <h1>Proveedor</h1>
            </v-list-item-title>
          </v-list-item>
          <proveedor-info-select :proveedor="proveedor"></proveedor-info-select>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <compra-detalle-subtotal
          :detalles="detallesPedido"
          :showSubTotal="false"
        ></compra-detalle-subtotal>

        <v-row>
          <v-col cols="12">
            <v-btn
              block
              color="green"
              :disabled="esValidoRegistrar || errors.any()"
              @click="registrarPedido()"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </LayoutBasic>

    <dialog-confirmation-close
      v-if="dialogCancelarPedido"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? Se perderán todos los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
// import LayoutBasic from "@/layouts/LayoutBasic.vue";
import LayoutBasic from "@/layouts/layout.registrar.vue";
import ListaDetallePedido from "@/components/pedido/ListaDetallePedido.vue";

import TablaDetallePedido from "@/components/pedido/TablaDetallePedido";
import AgregarDetallePedido from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import Proveedor from "@/components/proveedor/ProveedorCardSelect";
import ProveedorInfoSelect from "@/components/proveedor/proveedor.info.vue";
import PedidoCompraAgregarDetalle from "@/views/compra/pedidos/pedido.compra.agregar.detalle";
import PedidoCompraDetallesTable from "@/components/compra/pedido/pedido.compra.detalles.table.vue";
import CompraDetalleSubtotal from "@/components/compra/compra.detalle.registrar.subtotal.vue";

import { getStorage, saveStorage } from "@/services/serviceLocalStorage";

import { mapMutations, mapActions } from "vuex";
import PedidoDetalleRegistrar from "../../../components/pedido/registrar/pedido.detalle.registrar.vue";

export default {
  name: "pedido.compra.registrar",
  components: {
    LayoutBasic,
    ListaDetallePedido,
    TablaDetallePedido,
    AgregarDetallePedido,
    PedidoCompraAgregarDetalle,
    DialogConfirmationClose,
    PedidoCompraDetallesTable,
    CompraDetalleSubtotal,

    Proveedor,
    ProveedorInfoSelect,
    PedidoDetalleRegistrar,
  },

  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      dialog: false,
      tipoComprobante: "compras",
      reloadProveedor: 0,

      detallesPedido: [],
      detalle_pedido: {
        producto: {},
        cantidad: null,
      },
      proveedor: null,

      dialogCancelarPedido: false,
      finalizar: false,

      loadingValidBorrador: false, //Muestra un loading al presionar guardarBorrador
      to: null,
    };
  },

  computed: {
    isValidBorrador() {
      var detalles = this.detallesPedido;

      if (detalles.length > 0) return true;

      if (this.proveedor !== null) return true;

      return false;
    },
    esValidoRegistrar() {
      let detalles = this.detallesPedido.length > 0 ? true : false;
      let proveedor = this.proveedor !== null ? true : false;
      return proveedor && detalles ? false : true;
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoCompra" },
        },
        {
          text: "Nuevo pedido",
          disabled: false,
          to: { name: "nuevoPedidoCompra" },
        },
      ];
    },
  },
  methods: {
    ...mapActions("pedidos", ["registrar_pedido"]),
    ...mapMutations("pedidos", ["reset_state_pedido_compra"]),

    registrarPedido() {
      this.finalizar = true;
      var tipoComprobante = this.tipoComprobante;
      var detalles = this.detallesPedido;
      var proveedor = this.proveedor;
      this.registrar_pedido({ tipoComprobante, proveedor, undefined, detalles })
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pedido registrado",
            icon: "mdi-information",
            timeout: 2000,
          });
          this.reloadProveedor = !this.reloadProveedor;
          this.to = true; // Si es verdadero me deja salir de la interfaz

          this.$router.push({ name: "pedidoCompra" });
        })
        .catch((e) => {
          console.log(e.response.data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 2000,
          });
        });
    },

    guardarBorrador() {
      var nuevoBorradorPedidoCompra = {
        detalles: this.detallesPedido,
        proveedor: this.proveedor,
        time: new Date(),
      };
      var existenBorradores = getStorage("borradoresPedidosCompra");

      if (existenBorradores == null) existenBorradores = [];

      existenBorradores.push(nuevoBorradorPedidoCompra);
      saveStorage("borradoresPedidosCompra", existenBorradores);

      this.to = true;
      this.loadingValidBorrador = true;
      setTimeout(() => {
        this.$router.push({ name: "pedidoCompra" });
      }, 1000);
    },

    setProveedor(proveedor) {
      this.proveedor = proveedor;
    },
    closeDialog() {
      this.dialogCancelarPedido = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarPedido = false;
      this.$router.push(this.to);
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (Object.keys(params).length == 0) return;

        this.proveedor = params.proveedor;
        this.detallesPedido = params.detalles;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta.
    if (this.to) {
      if (this.finalizar == false) {
        if (this.to !== true) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: "Pedido borrado",
            icon: "mdi-bug",
            timeout: 2000,
          });
        } else {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "El borrador se guardo correctamente",
            timeout: 2000,
          });
        }
      }
      next();
    } else {
      // seteo to con la siguiente ruta

      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada
      !this.isValidBorrador ? next() : (this.dialogCancelarPedido = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
