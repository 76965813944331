<template>
  <div>
    <div>
      <h2>
        {{ estadoPlural | capitalize }}
        <span class="subtitle-1">{{ pedidos.length }}</span>
      </h2>
    </div>
    <lista-pedidos
      v-if="pedidos.length > 0"
      @abrir-vista-previa="abrirVistaPrevia"
      :pedidos="pedidos"
    ></lista-pedidos>
    <div v-else>No hay pedidos</div>
    <v-dialog v-model="dialogVistaPrevia" width="800px">
      <pedido-compra-vista-previa
        @close-dialog="dialogVistaPrevia = false"
        @process-quitar-pedido="quitarPedido"
        @process-ver-pedido="verPedido"
        @process-refresh="fetchPedidos"
        :pedido="pedido"
      ></pedido-compra-vista-previa>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ListaPedidos from "@/views/pedido/lista/ListaPedidos";
import PedidoCompraVistaPrevia from "./pedido.compra.vista-previa.vue";

export default {
  name: "pedido.compra.list",
  components: {
    ListaPedidos,
    PedidoCompraVistaPrevia,
  },
  props: {
    estado: {
      type: String,
      required: true,
      default: "pendiente",
    },
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      model: null,
      // pedidos: [],
      pedido: null,
      dialogVistaPrevia: false,
    };
  },
  computed: {
    estadoPlural() {
      return this.estado + "s";
    },
    fechaAlta() {},
    pedidos() {
      if (this.estado == "pendiente") {
        return this.pedidos_compra_pendientes;
      } else {
        return this.pedidos_compra_aprobados;
      }
    },

    ...mapGetters("pedidos", ["pedidos_compra_aprobados", "pedidos_compra_pendientes"]),
  },
  methods: {
    ...mapMutations("pedidos", ["set_pedidos_compra"]),
    ...mapActions("pedidos", ["fetch_pedidos_compra"]),
    abrirVistaPrevia(pedido) {
      this.pedido = pedido;
      this.dialogVistaPrevia = true;
    },
    verPedido(pedido) {
      console.log(pedido);
      this.$router.push({
        name: "ActualizarPedidoCompra",
        params: {
          id: pedido.id,
          estado: this.estado,
        },
      });
    },
    quitarPedido(pedido) {
      const index = this.pedidos.indexOf(pedido);
      if (index > -1) {
        this.pedidos.splice(index, 1);
        this.dialogVistaPrevia = false;
      }
    },
    fetchPedidos(estado) {
      this.fetch_pedidos_compra(estado);

      // var url = `/pedidos/?esVenta=False&estado=${this.estado.toUpperCase()}`;
      // var estado = this.estado;
      // this.axios.get(url).then((response) => {
      //   this.pedidos = response.data;
      //   // this.set_pedidos_compra({ estado, pedidos });
      // });
    },
  },
  created() {
    this.fetchPedidos(this.estado);
  },
};
</script>

<style scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
