<template>
  <div>
    <v-list v-if="type == 'list'" dense >
      <v-list-item>
        <v-list-item-content>
          <proveedor-list-select
            :proveedores="proveedores"
            @set_proveedor="setProveedor"
          ></proveedor-list-select>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="type == 'checkbox'">
      <v-badge
        color="red"
        :value="selected.length"
        :content="selected.length"
        
      >
        <span  class="text-body-2"
          ><b>Proveedores</b></span
        >
      </v-badge>
      <v-checkbox
        class="pb-0 pt-0"
        dense
        v-for="(item, i) in proveedoresFilter"
        :key="i"
        :label="`${item.nombre}`"
        multiple
        v-model="selected"
        :value="item"
        @change="setProveedor(item, $event)"
      >
      </v-checkbox>
      <v-list-item dense class="mt-0" v-if="proveedores.length > 5" link>
        <v-list-item-content>
          <v-list-item-title @click="dialog = true">
            <div class="blue--text">Ver todos</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-dialog v-model="dialog" width="900">
        <v-card>
          <v-container>
            <v-card-title>
              Elegir Proveedor
              <v-spacer></v-spacer>
              <v-icon color="blue" @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-for="p in proveedoresOrdenados" :key="p.id" cols="3">
                  <v-checkbox
                    class="pb-0 pt-0"
                    dense
                    :label="`${p.nombre}`"
                    multiple
                    v-model="selected"
                    :value="p"
                    @change="setProveedor(p, $event)"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="dialog = false"
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ProveedorListSelect from "@/components/proveedor/ProveedorListSelect";
import { mapGetters } from "vuex";
export default {
  name: "producto.filtro.proveedor",
  components: {
    ProveedorListSelect,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
    proveedoresFilter() {
      if (this.proveedores.length > 5) {
        return this.proveedores.slice(0, 5);
      } else {
        return this.proveedores;
      }
    },
    proveedoresOrdenados() {
      function compare(a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
        return 0;
      }
      return this.proveedores.sort(compare);
    },
  },

  methods: {
    getKeyFilter(object) {
      console.log("getKeyFilter", object);
      return `proveedor-${object.id}`;
    },
    setProveedor(item) {

      this.$emit("select-proveedor", item);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>