import axios from 'axios'
export default {

    getReporteVentaPorHora(filter) {
        var url = `reportes/reporteventaporhora?${filter}`
        return axios.get(url)
    },
    getReporteProductoListado(parametros, headers) {
        var url = `/reportes/reporte/producto/listado`;
        return axios.post(url, parametros, headers)
    },
    getReportePlanillaGondola(parametros, headers) {
        var url = `/reportes/reporte/producto/gondola`;
        return axios.post(url, parametros, headers)
    },
    deleteReporteListaPrecio(id){
        var url = `/reportes/reporte/producto/listado/${id}`;
        return axios.delete(url)
    },
    getReporteVentaMensual() {
        var url = "/reportes/reportecompraventa/venta/m";
        return axios.get(url)
    },
    getReporteProductosTopTen() {
        var url = "/reportes/reporte/producto/topten";
        return axios.get(url)
    }
}