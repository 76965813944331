<template>
  <div>
    <v-row align-center justify="center" row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          v-model="selected"
          :headers="headers"
          :items="productos_reporte"
          :search="search"
          show-select
          item-key="id"
          class="elevation-1"
          no-data-text="No se encontraron productos"
          no-results-text="Sin coincidencia para la búsqueda"
        >
          <template v-slot:top>
            <v-toolbar :color="selected.length ? 'grey darken-4' : 'red accent-4'" dark>
              <v-btn v-if="selected.length" icon @click="selected = []">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-toolbar-title>{{
                selected.length
                  ? `${selected.length} seleccionados`
                  : "Seleccionar productos"
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-scale-transition>
                <v-text-field
                  color="white"
                  class="white--text mt-2"
                  v-model="search"
                  placeholder="Buscar"
                  clearable
                  :append-outer-icon="search ? '' : 'mdi-magnify'"
                ></v-text-field>
              </v-scale-transition>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:headers="props">
            <tr>
              <th>
                <v-checkbox
                  :input-value="props.all"
                  :indeterminate="props.indeterminate"
                  primary
                  hide-details
                  @click.stop="toggleAll"
                ></v-checkbox>
              </th>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                  'column sortable',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : '',
                ]"
                @click="changeSort(header.value)"
              >
                <v-icon small>arrow_upward</v-icon>
                <h2>{{ header.text }}</h2>
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductosReportesTabla",
  data() {
    return {
      headers: [
        {
          text: "Id",
          value: "id",
          align: "center",
        },
        {
          text: "Descripcion",
          value: "descripcion",
          align: "center",
        },
        {
          text: "Marca",
          value: "marca.nombre",
          align: "center",
        },
        {
          text: "Proveedor",
          value: "proveedor.nombre",
          align: "center",
        },
         {
          text: "Categoria",
          value: "categoriaArbol.name",
          align: "center",
        },
        {
          text: "P. Costo",
          value: "precioCosto",
          align: "center",
        },
        {
          text: "P. Venta",
          value: "precioVenta",
          align: "center",
        },
        {
          text: "Ganancia",
          value: "ganancia",
          align: "center",
        },
        {
          text: "Flete",
          value: "flete",
          align: "center",
        },
        {
          text: "IVA",
          value: "iva",
          align: "center",
        },
      ],
      search: "",
      openSearch: false,
      loading: false,
      productos: [],
      selected: [],
    };
  },
  computed: {
    ...mapState("reportes", ["productos_reporte"]),
  },
  mounted() {
    this.selected = this.productos_reporte.slice();
  },
  watch: {
    selected: function (val) {
      this.$emit("process-productos-seleccionados", val);
    },
    productos_reporte: function (val) {
      this.selected = this.productos_reporte.slice();

      this.$emit("process-productos-seleccionados", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
