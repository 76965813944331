<template>
  <LayoutBasic :showLinksMenu="false"   v-if="cliente" :width="'400px'">
            <template v-slot:navigation-subtitle>
         <div>
          Clientes
         </div>
    </template>
    <template v-slot:navigation-title>
         {{ cliente.persona.nombre }} {{ cliente.persona.apellido }}
    </template>
        <template v-slot:navigation-subtitle-after>
         <v-chip v-if="cliente.es_empleado" small color="success">Empleado</v-chip>
    </template>
   
    <template v-slot:navigation-content>
      <v-img
        :src="noImage"
        :lazy-src="`https://picsum.photos/10/6?image=${15}`"
        aspect-ratio="1"
        height="150px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-divider light></v-divider>
      <v-list>
        <v-list-item @click="$router.push({ name: 'clienteHome' })">
          <v-list-item-icon>
            <v-icon>mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'actualizarCliente',
              params: { cliente: cliente },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Editar perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({ name: 'direcciones', params: { cliente: cliente } })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-map-marker-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Direcciones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <v-list-item
          @click="
            $router.push({
              name: 'clienteContabilidad',
              params: { cliente: cliente },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-wallet-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Contabilidad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'clientesCuentasCorrientesDetalle',
              params: { cliente: cliente },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-wallet-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Cuenta corriente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <v-btn color="red" @click="dialogDelete = true" block small
          >Eliminar cliente</v-btn
        >
        <layout-dialog-delete
          @close-dialog="dialogDelete = false"
          @processDelete="eliminarCliente"
          v-if="dialogDelete"
        >
          <template v-slot:title>Eliminar clientppe</template>
          <template v-slot:message>
            ¿Desea eliminar el cliente '
            <i>{{ cliente.persona.nombre }} {{ cliente.persona.apellido }}</i> ' ?
          </template>
        </layout-dialog-delete>
      </v-container>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </LayoutBasic >
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

import ClienteApi from "@/api/clientes/clientes.js";
import { fetchCliente } from "@/mixins/cliente/fetchCliente";
import { mapActions } from "vuex";

export default {
  name: "ClientesCuentaCorriente",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
    LayoutDialogDelete,
  },
  mixins: [fetchCliente],
  data() {
    return {
      dialogDelete: false,
      search: "",

      noImage: require("@/assets/man-woman.jpg"),
      navigation: [
        {
          text: "Clientes",
          disabled: false,
          to: { name: "clientes" },
        },
      ],
    };
  },

  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    ...mapActions("cliente", ["eliminar_cliente", "fetchClientes"]),
    eliminarCliente() {
      this.eliminar_cliente(this.cliente.id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Se eliminó el cliente '${this.cliente.persona.nombre}'`,
          icon: "mdi-check",
          timeout: 2500,
        });
        this.$router.push("/clientes");
        this.dialogDelete = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
