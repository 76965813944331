<template>
  <div>
    <stock-parameters-form :stock_parameters="stock_parameters" :producto="producto" @guardar="actualizarInventario"></stock-parameters-form>
  </div>
</template>

<script>
import StockParametersForm from "@/components/producto/inventario/ProductoStockParametersForm.vue";
import ProductosAPI from "@/api/productos";

export default {
  components: {
    StockParametersForm
  },
  props: {
    stock_parameters: {
        type: Object,
        required: true,
    }, 
    producto: {
        type: Object,
        required: true,
    }, 

  },
  data() {
    return {
    
    };
  },
  methods: {
    actualizarInventario(updatedStockParameters) {
      var producto = { ...this.producto, ...updatedStockParameters };
      ProductosAPI.updateInventario(producto)
        .then((response) => {
          this.$emit("showSnackBar", {
            show: true,
            color: "success",
            text: "Actualizado",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("nuevoMovimientoStock", response.data);
        })
        .catch((e) => {
          this.$emit("showSnackBar", {
            show: true,
            color: "danger",
            text: "No se pudo actualizar el producto",
            icon: "mdi-bell-ring",
            timeout: 3000,
          });
        });
    }
  }
};
</script>