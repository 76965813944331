<template>
  <LayoutBasic :linksMenu="linksMenu">
    <template v-slot:navigation-subtitle>
      <v-breadcrumbs class="mt-2 pa-0" :items="navigation" divider=">">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            @click="removeNavigation(item.text)"
            :href="item.href"
            :disabled="item.disabled"
          >
            <router-link
              style="text-decoration: none"
              :to="
                item.text == 'Productos'
                  ? { path: `${$route.path}` }
                  : { path: `${$route.path}?categoria=${item.to}` }
              "
              replace
            >
              <span class="caption">{{ item.text }}</span>
            </router-link>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div v-if="sucursal">{{ sucursal.nombre }} </div>
      
    </template>
    <template v-slot:navigation-title> {{ titulo }} </template>

    <template v-slot:navigation-content>
     
    
      <v-list class="pt-0 mt-0">
        <v-list-item>
          <v-list-item-title>
            <producto-buscador
              @process-buscador-producto="goToBuscador"
              @process-buscador-producto-select="goToBuscador"
            ></producto-buscador>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="get_params_query().length > 0">
          <v-list-item-title>
            <div class="text-wrap">
              <productos-filtros-tag
                @close-chip="removeTagCategory"
                :removeTagCategoria="false"
              ></productos-filtros-tag>
            </div>
          </v-list-item-title>
        </v-list-item>
        
      </v-list>
    </template>

    <template v-slot:navigation-after-links>
      <v-divider></v-divider>
      <productos-filtros
        ref="filtros"
        @set-categoria="setCategoria"
        :withTags="false"
      ></productos-filtros>
 

      <!-- <v-list nav dense>
        <v-list-item exact-path :to="{ name: link.route }" v-for="(link, index) in links" :key="index">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list> -->
    </template>
    <template v-slot:vista-previa>

   
      <!-- <v-dialog v-model="menuSucursales" width="800px">
        <v-card>
          <v-container>
            <v-card-title>
              Lista de sucursales
              <v-spacer></v-spacer>
              <v-btn icon @click="menuSucursales=false"> <v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
              <SucursalSeleccion @handle-selection="fetchProductos"></SucursalSeleccion>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog> -->
      <v-list-item v-if="$route.query.q">
        <v-list-item-content>
          <v-list-item-subtitle>
            <div>
              <span class="text-wrap" style="font-size: 18px">
                Resultado de la búsqueda: <b>{{ $route.query.q }}</b>
              </span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ProductoBuscador from "@/components/producto/buscador/producto.buscador.vue";
import ProductosFiltros from "@/components/producto/filtros/productos.filtros.vue";
import ProductosFiltrosTag from "@/components/producto/filtros/productos.filtros.tag.vue";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import { menuLinks } from "@/mixins/menu/menuLinksMixin.js";
import { getStorage } from "@/services/serviceLocalStorage";
import SucursalSeleccion from '../../components/sucursal/SucursalSeleccion.vue';

export default {
  name: "ProductosHome",
  components: {
    ProductosFiltros,
    ProductoBuscador,
    LayoutBasic,
    ProductosFiltrosTag,
    SucursalSeleccion
  },
  mixins: [menuLinks],
  data() {
    return {
      menuName: "productos",
      menuSucursales: false,
      navigation: [
        {
          text: "Productos",
          disabled: false,
          to: "/productos",
        },
      ],
      links: [
        {
          icon: "mdi-format-list-bulleted-type",
          title: "Lista de productos",
          route: "productosListado",
        },
        {
          icon: "mdi-folder-plus",
          title: "Nuevo Producto",
          route: "nuevoProducto",
        },

        {
          icon: "mdi-clipboard-list",
          title: "Inventario",
          route: "productoInventarioGestion",
        },
        {
          icon: "mdi-pencil",
          title: "Editor masivo",
          route: "productosEditorMasivo",
        },
      ],
      item: 1,
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
    ...mapGetters("marcas", ["marcas"]),
    ...mapState("categorias", ["categoriasProductosFiltros"]),
    ...mapState("sucursales", ["sucursal"]),

    queryParams() {
      return this.$route.query || "";
    },
    titulo() {
      return this.$route.query.categoria
        ? this.$route.query.categoria.split(/[-]+/).join(" ")
        : "Productos";
    },
  },

  methods: {
    ...mapActions("categorias", ["fetchCategoriasProductosFiltros"]),
    ...mapMutations("productos", ["set_page_productos_admin"]),
    ...mapActions("productos", ["get_productos_for_params"]),
    removeTagCategory(filter) {
      console.log("Filter category", filter);
      var name = this.get_text_transform(filter.nombre);

      //Verifico si existe el nombre de la categoria en la navegacion
      var index = this.navigation.findIndex(
        (e) =>
          this.get_text_transform(e.text).toLowerCase() == name.toLowerCase()
      );
      this.navigation.splice(index);
      this.getCategoriasFromNavigation(this.navigation);
    },
    add_query_category_if_exists() {
      var last_navigation = null;
      if (this.navigation.length > 1) {
        last_navigation = this.navigation[this.navigation.length - 1];
        console.log(
          "[add_query_category_if_exists] - last_navigation",
          last_navigation
        );
        this.add_query_url({ categoria: last_navigation.text });
      }
    },
    add_query_url(value) {
      console.log("{add_query_url} - ", value);
      var querys = this.$route.query;

      for (const property in value) {
        var name = value[property];
        value[property] = this.get_text_transform(name);
        querys = Object.assign({}, querys, value);
      }
      this.$router.replace({ query: querys }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    removeNavigation(name) {
      // latest -> Si es true, elimina toda la navegacion despues del elemento encontrado
      //Si es false, elimina toda la navegacion desde el elemento encontrado incluido
      if (name == "Productos" && this.navigation.length == 1) {
        return;
      }
      if (name == "Productos") {
        this.navigation.splice(1);
        this.$router.replace({ query: null }).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        //Verifico si existe el nombre de la categoria en la navegacion
        var name = this.get_text_transform(name);
        var index = this.navigation.findIndex(
          (e) =>
            this.get_text_transform(e.text).toLowerCase() == name.toLowerCase()
        );
        //Si estoy haciendo click en el ultimo navigation
        if (this.navigation.length > 1 && index == this.navigation.length - 1) {
          return;
        }
        // Si existe la categoria, elimino todas las demas navegaciones despues de esta
        if (index > -1) {
          if (this.navigation[index + 1]) {
            this.navigation.splice(index + 1);
          }
        }
      }
      this.getCategoriasFromNavigation(this.navigation);
    },
    capitalize(str) {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    },
    replaceParams(categoria) {
      var string = categoria.name.split(/[ ]+/).join("-");
      return string;
      // this.$router.push({ path: `/productos/categoria/${string}` });
    },
    goToBuscador(search) {
      var isBarcode = false;
      let isnum = /^\d+$/.test(search);

      if (isnum && search.length > 6) {
        isBarcode = true;
      }
      if (search == "") {
        this.$router.replace({ path: "productos" }).catch((e) => {});
        return;
      }

      if (search == this.$route.query.q) {
        return;
      }
      this.set_page_productos_admin(1);

      if (isBarcode) {
        this.add_query_url({ barcode: search });
      } else {
        this.add_query_url({ q: search });
      }
    },
    get_params_query() {
      var array = [];
      var params = this.$route.params;
      if (params && params.categoria) {
        var object = {
          value: null,
          key: "",
        };
        object.value = params.categoria;
        object.key = "categoria";
        array.push(object);
      }

      var querys = this.$route.query;
      if (Object.keys(querys).length !== 0) {
        for (const property in querys) {
          var object = {
            nombre: null,
            key: "",
          };
          object.key = property;
          object.value = this.get_text_transform(querys[property]);
          array.push(object);
        }
      }
      return array;
    },

    getCategoriasFromNavigation(navigations) {
      var last_navigation = null;
      if (navigations.length > 1) {
        last_navigation = navigations[navigations.length - 1];
      } else {
        last_navigation = navigations[0];
      }
      if (navigations.length == 1) {
        this.fetchCategoriasProductosFiltros("CATEGORIA");
      } else {
        this.fetchCategoriasProductosFiltros(last_navigation.text);
      }
    },

    //Agrega el filtro, lleva el scroll para arriba, y hace la peticion al server

    goToRoute(route) {
      if (!route) return;
      this.$router.push({ name: route }).catch(() => {});
    },
    setCategoria(data) {
      let nameCapitalize = this.capitalize(data.name);
      let navigations = {
        type: "category",
        text: nameCapitalize,
        disabled: false,
        to: this.replaceParams(data),
      };
      this.navigation.push(navigations);
    },

    get_text_transform(str) {
      if (typeof str === "string") {
        return str.match(/[^ ]+/g).join("-");
      } else {
        return str;
      }
    },

    check_if_object_exists_in_array(array, key, value) {
      return array.some((el) => el.type === value);
    },
    fetchProductos(){
       var params = this.get_params_query();
       this.get_productos_for_params(params);
       this.menuSucursales = false
    }
  },
  watch: {
    $route: {
      handler: function (value, oldvalue) {
        this.set_page_productos_admin(1);
        var params = this.get_params_query();
        if (params.length == 0 && this.navigation.length > 1) {
          this.add_query_category_if_exists();
          return;
        } else {
          this.get_productos_for_params(params);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
};
</script>

<style>
.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>
