<template>
  <div>
    <v-container>
      <v-row justify="start">
        <v-col cols="12">
          <div class="headline">
            <h1 class="text-wrap" style="font-size: 30px"></h1>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="2" v-for="item in productosSinCategoria" :key="item.id">
            <v-card class="" height="100%">
                <v-container>
                    <v-card-title>
                        {{item.descripcion}}
                    </v-card-title>
                </v-container>
            </v-card>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="6">
          <div class="title">{{ listaDePrecio.nombre }}</div>
          <v-alert
            class="mt-2"
            shaped
            outlined
            colored-border
            type="info"
            elevation="2"
          >
            Seleccioná los productos que quieras agregar para vender
          </v-alert>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            outlined
            single-line
            hide-details
          ></v-text-field>

          <v-card class="overflow-y-auto mt-2" max-height="700px">
            <v-card>
              <v-card-text>
                <v-data-table
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                  class="row-pointer"
                  item-key="descripcion"
                  v-model="productosSeleccionados"
                  :single-select="false"
                  :headers="headers"
                  :items="productosDeLista"
                  :search="search"
                  show-select
                >
                  <template v-slot:item="{ item, isSelected, select }">
                    <tr
                      :class="isSelected ? colorSelected : ''"
                      @click="toggle(isSelected, select, $event)"
                    >
                      <td class="d-flex align-center">
                        {{ item.descripcion }}
                        <span v-if="item.producto"><b>Existo</b></span>
                        <!-- <v-icon class="px-1" color="black" v-if="isSelected"
                          >mdi-check</v-icon
                        > -->
                      </td>
                      <td>{{ item.iva }}</td>
                      <td>{{ item.precioCosto }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="6">
          <div class="title">
            Nuevos productos para vender.
            <span class="ml-4"> {{ productosSeleccionados.length }}</span>
          </div>
          <v-alert
            class="mt-2"
            shaped
            outlined
            colored-border
            type="info"
            elevation="2"
          >
            Lista de nuevos productos
          </v-alert>
          <v-text-field
            v-model="searchProductosSeleccionados"
            append-icon="mdi-magnify"
            label="Buscar"
            outlined
            single-line
            hide-details
          ></v-text-field>
          <v-card class="overflow-y-auto mt-2" max-height="700px">
            <v-card-text>
              <v-list
                :color="
                  productosSeleccionados.length
                    ? 'grey darken-2'
                    : 'transparent'
                "
                shaped
                dense
              >
                <template
                  v-for="(item, i) in filterProductosSeleccionadosByName"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                  <v-list-item v-else :key="`item-${i}`" :value="item">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.descripcion"
                      ></v-list-item-title>
                      <v-divider
                        v-if="productosSeleccionadosOrdenados.length > 1"
                      ></v-divider>
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-btn icon @click="removeSelected(item)">
                        <v-icon>mdi-delete</v-icon></v-btn
                      ></v-list-item-action
                    >
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <loading :active.sync="loading"></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ListaDePrecioAPI from "@/api/proveedores/listaDePrecio";

import { mapState, mapMutations } from "vuex";
export default {
  name: "Asignar-Categoria",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      productosSinCategoria: [],
      search: "",
      searchProductosSeleccionados: "",
      categoriaSeleccionada: 1,
      headers: [
        { text: "descripcion", value: "descripcion" },
        { text: "IVA", value: "iva" },
        { text: "precio", value: "precioCosto" },
      ],
      proveedor: 3,
    };
  },
  computed: {
    ...mapState("categorias", ["categoriaSelected"]),
    ...mapState("proveedor", [
      "productos_de_lista_para_registrar",
      "listaDePrecio",
    ]),
    colorSelected() {
      return "light-blue darken-1";
    },
    productosDeLista() {
      if (this.listaDePrecio) {
        return this.listaDePrecio.precios.filter((p) => !p.producto);
      } else {
        return [];
      }
    },
    productosSeleccionados: {
      get() {
        return this.productos_de_lista_para_registrar.productos;
      },
      set(val) {
        this.SET_PRODUCTOS_LISTA_PRECIO(val);
      },
    },
    filterProductosSeleccionadosByName() {
      return this.productosSeleccionadosOrdenados.filter((product) =>
        product.descripcion
          .toLowerCase()
          .includes(this.searchProductosSeleccionados.toLowerCase())
      );
    },

    productosSeleccionadosOrdenados() {
      function compare(a, b) {
        if (a.descripcion.toLowerCase() < b.descripcion.toLowerCase())
          return -1;
        if (a.descripcion.toLowerCase() > b.descripcion.toLowerCase()) return 1;
        return 0;
      }
      return this.productosSeleccionados.sort(compare);
    },
  },
  methods: {
    ...mapMutations("proveedor", ["SET_PRODUCTOS_LISTA_PRECIO"]),

    removeSelected(producto) {
      this.productosSeleccionados = this.productosSeleccionados.filter(
        (item) => item !== producto
      );
    },

    toggle(isSelected, select, e) {
      select(!isSelected);
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>