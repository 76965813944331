<template>
  <v-navigation-drawer
    color="greyen"
    class="items2"
    app
    width="300"
    fixed
    style="overscroll-behavior-y: contain; perspective-origin: right top"
    :mini-variant.sync="miniVariant"
  >
    <template v-slot:prepend>
      <v-list>
        <v-list-item @click.stop="miniVariant = !miniVariant" class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Menú</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>

    <v-list nav>
      <!-- <v-list-group
            v-for="item in linksProductos"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              dense
              v-for="subItem in item.items"
              :key="subItem.title"
              :to="subItem.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="subItem.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> -->
      <v-list-item v-for="item in links" :key="item.text" link :to="item.route">
        <v-list-item-action>
          <v-icon :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            ><span style="font-size: 0.9375rem">{{ item.text }}</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list subheader>
      <v-subheader>Administrar</v-subheader>

      <v-list-item v-for="item in navAdministrar" :key="item.text" :to="item.route">
        <v-list-item-icon>
          <v-icon :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><span style="font-size: 0.9375rem">{{ item.text }}</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "administrar.home",
  data() {
    return {
      miniVariant: true,
      linksProductos: [
        {
          action: "mdi-format-list-checks",
          title: "Mov. de caja",
          items: [
            { title: "Ingreso de efectivo", route: "/AsignarCategoria" },
            { title: "Egreso de efectivo", route: "/productos" },
            { title: "Cerrar caja", route: "/nuevoProducto" },
          ],
        },
      ],

      navAdministrar: [
        {
          id: 1,
          icon: "mdi-sitemap",
          text: "Categorias",
          route: "/Categorias",
        },
        {
          id: 2,
          icon: "mdi-account-multiple",
          text: "Proveedores",
          route: "/proveedores",
        },
        { id: 3, icon: "mdi-basket", text: "Marcas", route: "/marcas" },
      ],
      links: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),

    ...mapState("auth", ["group"]),
   
  },
  mounted(){
     if (this.$route.path == '/home' ){
          this.miniVariant = false
        }else{
          this.miniVariant = true
        }
  },
  created() {
    this.links.push({
      id: 1,
      icon: "mdi-home",
      text: "Principal",
      route: "/home",
    });
    if (localStorage.getItem("arqueoCaja")) {
      this.links.push({
        id: 12,
        icon: "mdi-cart-arrow-right",
        text: "Ventas",
        route: "/ventas",
      });
    }
    if (this.configuracion.compra) {
      this.links.push({
        id: 11,
        icon: "mdi-store-outline",
        text: "Compras",
        route: "/compras",
      });
    }
    if (this.configuracion.promociones) {
      this.links.push({
        id: 4,
        icon: "mdi-sale",
        text: "Promociones",
        route: "/promociones",
      });
    }

    this.links.push({
      id: 4,
      icon: "mdi-format-list-checks",
      text: "Productos",
      route: "/productos",
    });
    if (localStorage.getItem("arqueoCaja")) {
      this.links.push(
        {
          id: 5,
          icon: "mdi-book-edit-outline",
          text: "Egresos",
          route: "/egresos",
        },

        {
          id: 15,
          icon: "mdi-bank",
          text: "Movimientos de caja",
          route: "/movimientos",
        }
      );
    } else {
      this.links.push({
        id: 15,
        icon: "mdi-bank",
        text: "Iniciar caja",
        route: "/iniciarCaja",
      });
    }
    if (this.group.includes("esDueño") || this.group.includes("esAdmin")) {
      this.links.push(
        {
          id: 14,
          icon: "mdi-chart-bar",
          text: "Reportesd",
          color: "green",
          route: "/reportes/resumen",
        },
        {
          id: 16,
          icon: "mdi-account",
          text: "Empleados",
          color: "blue",
          route: "/empleados",
        },
        {
          id: 17,
          icon: "mdi-account",
          text: "Configuracion",
          color: "blue",
          route: "/configuracion",
        }
      );
    }
    var array = this.links.concat(this.navAdministrar);
    console.log("ADINISTRAR VENTA");
    this.$store.commit("SET_LINKS", array);
  },
  watch: {
    '$route': {
      handler: function (route) {
        console.log(route);
        if (route.path == '/home' ){
          this.miniVariant = false
        }else{
          this.miniVariant = true
        }
      }
    }
  }
};
</script>

<style scoped>
.items2:hover ::-webkit-scrollbar {
  width: 8px;
}
.items2 ::-webkit-scrollbar {
  width: 0px;
}
/* width */

/* Track */
.items2 ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.items2 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
