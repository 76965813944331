<template>
  <v-list v-if="inventario" subheader dense>
    <v-subheader>Inventario</v-subheader>

    <v-list-item class="mt-0 pt-0">
      <v-list-item-content>
        <v-list-item-title>
          <div style="font-weight: 600">Controlar stock</div>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-checkbox
          dense
          hide-details=""
          class="text-center justify-center"
          v-model="producto.controlaStock"
          value
        >
        </v-checkbox>
      </v-list-item-action>
    </v-list-item>
    <span v-if="producto.controlaStock">
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            label="Stock actual"
            v-model="producto.cantidadstock"
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            label="Stock minimo"
            v-model="producto.stockmin"
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            label="Stock maximo"
            v-model="producto.stockmax"
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
    </span>
  </v-list>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "form.producto.inventario",
  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),

    inventario() {
      return this.configuracion.productoInventario;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>