<template>
  <span>
    <layout-pedido-registrar>
      <template v-slot:title><h2>Nuevo pedido</h2> </template>
      <template v-slot:bar-before-spacer>
        <v-btn
          :disabled="!isValidBorrador"
          @click="guardarBorrador"
          :text="isValidBorrador"
          :loading="loadingValidBorrador"
          color="info"
        >
          <b>Guardar Borrador</b>
        </v-btn>
      </template>

      <template v-slot:bar-extension>
        <template v-if="configuracion.menuVentaCruachan">
          <venta-menu-buttons :detalles="detallesPedido"></venta-menu-buttons>
        </template>
      </template>
      <template v-slot:search-barcode>
        <agregar-producto-barcode
          :detalles="detallesPedido"
        ></agregar-producto-barcode
      ></template>
      <template v-slot:arriba-vista-previa>
        <productos-frecuentes
          :detalles="detallesPedido"
          :fromBuscador="false"
        ></productos-frecuentes>
      </template>

      <template v-slot:detalle-pedido>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="display-1">Nuevo pedido</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <PedidoVentaClienteDireccionEnvio
          v-if="isModuleCliente"
        ></PedidoVentaClienteDireccionEnvio>

        <ClienteManualEnvio
          v-if="!pedidoClienteVenta"
          ref="formClient"
          :isOptional="isOptional"
          @activate-is-borrador-venta="activeBorradorVenta = true"
          @process-cliente="setIsValidCliente"
          @set-paraenviar="setEsParaEnviar"
          :validCliente="isValidCliente"
          :formClient="cliente"
          :formEnvio="envio"
        ></ClienteManualEnvio>
        <v-divider></v-divider>
        <pedido-detalle-registrar
          :detalles="detallesPedido"
        ></pedido-detalle-registrar>
      </template>

      <template v-slot:button-navigation-controller>
        <pedido-detalle-subtotal
          :detalles="detallesPedido"
          :tipoRedondeo="'entero'"
        ></pedido-detalle-subtotal>
        <v-row>
          <v-col cols="12">
            <v-btn
              block
              color="green"
              :disabled="esValidoRegistrar || errors.any()"
              @click="registrarPedidoVenta()"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </layout-pedido-registrar>

    <dialog-confirmation-close
      v-if="dialogCancelarPedido"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? Se perderán todos los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
import AgregarProductoBarcode from "@/components/comprobante/detalle/comprobante.agregar.detalle.barcode.vue";

import LayoutPedidoRegistrar from "@/layouts/layout.three.column";

import TablaDetallePedido from "@/components/pedido/TablaDetallePedido";
import AgregarDetallePedido from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import ClienteManualEnvio from "@/components/cliente/ClienteManualEnvio.vue";

import { getStorage, saveStorage } from "@/services/serviceLocalStorage";

import { mapState, mapActions, mapMutations } from "vuex";
import VentaMenuButtons from "@/components/venta/venta.menu.buttons.vue";
import PedidoDetalleRegistrar from "@/components/pedido/registrar/pedido.detalle.registrar.vue";
import ProductosFrecuentes from "@/components/producto/consultar/productos.frecuentes.card.vue";
import PedidoDetalleSubtotal from "@/components/pedido/actualizar/pedido.detalle.subtotal.vue";
import VentaClienteEnvio from "@/components/venta/cliente/venta.cliente.envio.vue";
import PedidoVentaClienteDireccionEnvio from "@/components/pedido/venta/PedidoVentaClienteDireccionEnvio.vue";

export default {
  name: "pedido.venta.registrar",

  components: {
    LayoutPedidoRegistrar,
    AgregarProductoBarcode,
    VentaMenuButtons,
    ProductosFrecuentes,
    PedidoDetalleSubtotal,

    TablaDetallePedido,
    AgregarDetallePedido,

    ClienteManualEnvio,

    DialogConfirmationClose,
    PedidoDetalleRegistrar,
    VentaClienteEnvio,
    PedidoVentaClienteDireccionEnvio,
  },

  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      tipoComprobante: "ventas",
      cliente: {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      },
      envio: {
        barrio: [],
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      },
      activeBorradorVenta: false, //Se activa cuando se modifico el formulario del cliente
      isValidCliente: true, // Bandera para saltar la validacion, se usa en conjunto con isOptional
      isOptional: true, // Bandera que hace required al formCliente

      detallesPedido: [],

      dialogCancelarPedido: false,
      finalizar: false,

      loadingValidBorrador: false, //Muestra un loading al presionar guardarBorrador
      to: null,
    };
  },

  computed: {
    ...mapState("pedidos", ["pedidoClienteVenta"]),
    ...mapState("sucursales", ["configuracion"]),
    ...mapState("sucursales", ["configuracion"]),
    isModuleCliente() {
      return this.configuracion.clientes;
    },

    isValidBorrador() {
      var detalles = this.detallesPedido;

      if (this.activeBorradorVenta || detalles.length > 0) return true;

      return false;
    },

    esValidoRegistrar() {
      let detalles = this.detallesPedido.length > 0 ? true : false;
      return detalles && this.isValidCliente ? false : true;
    },

    navigation() {
      return [
        {
          text: "Ventas",
          disabled: false,
          to: { name: "ventas" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoVenta" },
        },
        {
          text: "Nuevo pedido",
          disabled: false,
          to: { name: "nuevoPedidoVenta" },
        },
      ];
    },
  },
  methods: {
    ...mapActions("pedidos", ["registrar_pedido_venta"]),
    ...mapMutations("pedidos", ["SET_PEDIDO_ES_PARA_ENVIAR"]),
    goBack(name) {
      this.$router.push({ name: name });
    },
    setEsParaEnviar(bool) {
      this.SET_PEDIDO_ES_PARA_ENVIAR(bool);
    },
    setIsValidCliente(val) {
      this.isValidCliente = val;
    },

    registrarPedidoVenta() {
      this.finalizar = true;
      var data = {
        nombreEntidad: { cliente: this.cliente, envio: this.envio },
        detalles: this.detallesPedido,
      };

      console.log(data);

      this.registrar_pedido_venta(data)
        .then((response) => {
          this.to = true; // Si es verdadero me deja salir de la interfaz

          this.$router.push({ name: "pedidoVenta" });
        })
        .catch((error) => {
          alert("Ha ocurrido un error. Code: PV01: ", error.response.data);
        });
    },

    guardarBorrador() {
      var nuevoBorradorPedidoVenta = {
        detalles: this.detallesPedido,
        cliente: this.cliente,
        envio: this.envio,
        time: new Date(),
      };

      var existenBorradores = getStorage("borradoresPedidosVenta");

      if (existenBorradores == null) existenBorradores = [];

      existenBorradores.push(nuevoBorradorPedidoVenta);
      saveStorage("borradoresPedidosVenta", existenBorradores);
      //Lo seteo en true para que me deje salir de la vista
      this.to = true;
      this.loadingValidBorrador = true;

      setTimeout(() => {
        this.$router.push({ name: "pedidoVenta" });
      }, 1000);
    },

    closeDialog() {
      this.dialogCancelarPedido = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarPedido = false;
      this.$router.push(this.to);
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (Object.keys(params).length == 0) return;
        this.cliente = params.cliente;

        this.envio = params.envio;
        this.detallesPedido = params.detalles;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", {
      layout: "layout-basic",
      withNav: false,
    });
  },

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta.
    if (this.to) {
      if (this.finalizar == false) {
        if (this.to !== true) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pedido borrado",
            icon: "mdi-information",
            timeout: 2000,
          });
        } else {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "El borrador se guardo correctamente",
            timeout: 2000,
          });
        }
      } else {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Pedido registrado",
          icon: "mdi-bug",
          timeout: 2000,
        });
      }
      next();
    } else {
      // seteo to con la siguiente ruta
      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada
      !this.isValidBorrador ? next() : (this.dialogCancelarPedido = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
