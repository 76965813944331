<template>
  <span>
    <layout-remito-registrar>
      <template v-slot:title><div>Nueva venta</div> </template>
      <template v-slot:navigation-controller>
        <v-container>
         

          <v-divider></v-divider>

          <div class="mt-4 mb-2">
            <VentaClienteDireccionEnvio v-if="isModuleCliente"></VentaClienteDireccionEnvio>
          </div>
        <v-divider></v-divider>

                    <agregar-detalle-comprobante
          :fab="true"
          :detalles="detallesFactura"
        ></agregar-detalle-comprobante>
          
        

       
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <FacturaVentaSubtotal
          :detalles="detallesFactura"
          :percepcionIva="percepcionIVANumber"
          :percepcionIB="percepcionIBNumber"
        ></FacturaVentaSubtotal>

        <v-row>
          <v-col cols="12">
            <v-btn
              block
              color="green"
              :disabled="esValidoRegistrar || errors.any()"
              @click="validateForm"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
        
          <v-container fill-height fluid>
            <v-row>
              <v-col>
                  <productos-frecuentes-card
          :detalles="detallesFactura"
          :fromBuscador="false"
        ></productos-frecuentes-card>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <div class="mb-2">Detalle</div>

                <FacturaVentaDetallesList
                  :edit="true"
                  :detalles="detallesFactura"
                ></FacturaVentaDetallesList>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:cliente>
    
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
               <ClienteInfo
                  :cliente="clienteVenta"
                  :direccion="clienteDireccion"

                ></ClienteInfo>
                  <FacturaVentaDetallesList
                  :edit="true"
                  :detalles="detallesFactura"
                ></FacturaVentaDetallesList>
        </v-container>
      </template>
    </layout-remito-registrar>
    <v-dialog width="600" v-model="dialogPagar">
      <v-card>
        <v-container>
          <v-card-title> ¿Vas a pagar la factura? </v-card-title>
          <v-card-text>
            <v-alert type="success" v-if="montoActual > totalRemito">
              <v-row align="center">
                <v-col class="grow">
                  Saldo suficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="registrarFactura(true)"
                    :loading="loadingButton"
                    color="yellow"
                    class="black--text"
                    ><b>Pagar</b></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
            <v-alert v-else type="error">
              <v-row align="center">
                <v-col class="grow">
                  Saldo insuficiente para efectuar el pago
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    :loading="loadingButton"
                    @click="registrarFactura(false)"
                    >Pagar después</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <v-card-actions
            v-if="montoActual > totalRemito"
            class="justify-space-around"
          >
            <v-btn
              :loading="loadingButton"
              color="info"
              text
              @click="registrarFactura(false)"
              >Pagar después</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogCancelarRemito"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar venta?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar la venta? Se perderán todos los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar venta</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
//GLOBAL

import LayoutRemitoRegistrar from "@/layouts/layout.registrar.vue";
import FacturaVentaSubtotal from "@/components/venta/factura/FacturaVentaSubtotal.vue";
import FacturaVentaDetallesList from "@/components/venta/factura/FacturaVentaDetallesList.vue";
import SeleccionarPedidosVentaAprobados from "@/components/venta/pedido/pedidos.venta.seleccion.aprobados.vue";

import ProductosFrecuentes from "@/components/producto/consultar/productos.frecuentes.list.vue";
import ProductosFrecuentesCard from "@/components/producto/consultar/productos.frecuentes.card.vue";


import BuscarProducto from "@/components/producto/buscador/producto.buscador.menu.vue";
import PedidoCompraAgregarDetalle from "@/views/compra/pedidos/pedido.compra.agregar.detalle";
import PedidoCompraAprobadosList from "@/components/compra/pedido/pedido.compra.aprobados.list.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import VentaClienteDireccionEnvio from "@/components/venta/cliente/VentaClienteDireccionEnvio.vue";
import ClienteInfo from "@/components/cliente/ClienteInfo.vue";
import AgregarDetalleComprobante from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";


import InfoProveedor from "@/components/proveedor/proveedor.info.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "VentaContable",
  components: {
    ProductosFrecuentesCard,
    ProductosFrecuentes,
    FacturaVentaDetallesList,
    AgregarDetalleComprobante,
    SeleccionarPedidosVentaAprobados,
    PedidoCompraAgregarDetalle,
    FacturaVentaSubtotal,
    PedidoCompraAprobadosList,
    VentaClienteDireccionEnvio,
    InfoProveedor,
    BuscarProducto,
    LayoutRemitoRegistrar,
    DialogConfirmationClose,
    ClienteInfo
  },
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    ...mapState("ventas", [
      "clienteVenta", "clienteDireccion"
    ]),

    isModuleCliente() {
      return this.configuracion.clientes;
    },
    percepcionIBNumber() {
      return Number(this.percepcionIB) || 0;
    },
    percepcionIVANumber() {
      return Number(this.percepcionIva) || 0;
    },
    isValidBorrador() {
      var detalles = this.detallesFactura;

      if (detalles.length > 0) return true;

      if (this.proveedor !== null) return true;

      return false;
    },
    esValidoRegistrar() {
      let detalles = this.detallesFactura.length > 0 ? true : false;
      return detalles ? false : true;
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Remito",
          disabled: false,
          to: { name: "remitoCompra" },
        },
        {
          text: "Nuevo remito",
          disabled: false,
          to: { name: "nuevoRemitoCompra" },
        },
      ];
    },
  },

  data() {
    return {
      dialogPagar: false,
      montoActual: null,
      totalRemito: 0,
      proveedor: null,
      documento: "FC",
      letra: "A",

      showRemitosDialog: false, // Para mostrar el diálogo de remitos pendientes
      remitoSeleccionado: null, // Remito seleccionado

      sucursal: null,
      nroFactura: null,
      detallesFactura: [],
      percepcionIva: 0,
      percepcionIB: 0,

      pedidos_compra_aprobados: [],

      finalizar: false,
      to: null,

      //Bandera que se activa cuando hubo un cambio
      isBorrador: false,
      loadingValidBorrador: false,
      dialogCancelarRemito: false,
      loadingButton: false,
    };
  },

  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

    activeDialogPagar() {
      this.dialogPagar = true;
    },
    ...mapActions("remitos", ["registrar_factura_compra", "get_remito_compra"]),
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.registrarFactura();
        } else {
          this.$validator.validate();
        }
      });
    },
    setProveedor(data) {
      this.proveedor = data;
    },
    setRemito(remito) {
      this.remitoSeleccionado = remito;
    },
    setRemitoDetalles(detalles) {
      this.detallesFactura = detalles;
    },
    setProveedorRemito(proveedor) {
      this.proveedor = proveedor;
    },
    removeRemito() {
      this.remitoSeleccionado = null;
    },
    async fetchRemito(remito) {
      try {
        console.log("remito", remito);
        const response = await this.get_remito_compra(remito.id);
        console.log(response);
        this.setRemito(response.data);
        this.setRemitoDetalles(response.data.detalles_comprobante);
        this.setProveedorRemito(response.data.proveedor);

        this.showRemitosDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    registrarFactura() {
      this.loadingButton = true;
      this.finalizar = true;

      var data = {
        proveedor: this.proveedor,
        detalles: this.detallesFactura,
        remito: this.remitoSeleccionado,
        numero: this.nroFactura,
        sucursal: this.sucursal,
        estaPago: false,
        percepcionIB: this.percepcionIBNumber,
        percepcionIva: this.percepcionIVANumber,
      };
      if (this.nroFactura !== null && !isNaN(parseInt(this.nroFactura))) {
        data.documento = this.documento;
        data.letra = this.letra;
      }

      this.registrar_factura_compra(data)
        .then((response) => {
          this.to = true; // Si es verdadero me deja salir de la interfaz

          this.$router.push({ name: "facturaCompra" });
          this.fetch_productos_mas_vendidos();
          this.loadingButton = false;
        })
        .catch((e) => {
          var message = e.response.data;
          if (e.response.data.detail) {
            message = e.response.data.detail;
          }
          this.loadingButton = false;
          this.finalizar = false;

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${message}`,
            icon: "mdi-information",
            timeout: 4000,
          });
          console.log(e);
        });
    },

    closeDialog() {
      this.dialogCancelarRemito = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarRemito = false;
      this.$router.push(this.to);
    },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
  },
  created() {
    this.getMontoActual();
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return;
        } else {
          if (params.remito) {
            console.log(params);
            // this.fetchPedido(params.pedido);
            this.remitoSeleccionado = params.remito;

            // this.detallesFactura = params.pedido.detalles_pedido;
            this.proveedor = params.remito.proveedor;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta.
    if (this.to) {
      if (this.finalizar) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Factura registrada",
          icon: "mdi-check",
          timeout: 2000,
        });
      }
      next();
    } else {
      // seteo to con la siguiente ruta

      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada

      !this.isValidBorrador ? next() : (this.dialogCancelarRemito = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
