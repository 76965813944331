import { render, staticRenderFns } from "./promocion.item.card.vue?vue&type=template&id=df9d283c&scoped=true&"
import script from "./promocion.item.card.vue?vue&type=script&lang=js&"
export * from "./promocion.item.card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df9d283c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCard,VCardText,VCardTitle,VChip,VChipGroup,VDivider,VHover,VIcon,VTooltip})
