<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Control de stock</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          small
          class="ma-2"
          :color="controlaStock ? 'green' : 'red'"
          @click="controlaStock = !controlaStock"
        >
          {{ controlaStock ? "Habilitado" : "Deshabilitado" }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-title>Cantidad</v-list-item-title>
      <v-list-item-action>
        <v-text-field
          outlined
          hide-details
          type="number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxLength="6"
          :disabled="!controlaStock"
          v-model="cantidadstock"
        ></v-text-field>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-title>Stock mínimo</v-list-item-title>
      <v-list-item-action>
        <v-text-field
          outlined
          hide-details
          type="number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxLength="6"
          :disabled="!controlaStock"
          v-model="stockmin"
        ></v-text-field>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-title>Stock máximo</v-list-item-title>
      <v-list-item-action>
        <v-text-field
          outlined
          hide-details
          type="number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxLength="6"
          :disabled="!controlaStock"
          v-model="stockmax"
        ></v-text-field>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-spacer></v-spacer>
      <v-list-item-action>
        <v-btn @click="onGuardar" rounded color="primary">Guardar</v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "ProductoStockParametersForm",
  props: {
    stock_parameters: {
      type: Object,
      required: true,
    },
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      controlaStock: this.stock_parameters.control_stock,
      cantidadstock: this.producto.cantidad_actual,
      stockmin: this.stock_parameters.stock_min,
      stockmax: this.stock_parameters.stock_max,
    };
  },
  methods: {
   
    onGuardar() {
      this.$emit("guardar", {
        controlaStock: this.controlaStock,
        cantidadstock: this.cantidadstock,
        stockmin: this.stockmin,
        stockmax: this.stockmax,
      });
    },
  },
};
</script>
