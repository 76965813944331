<template>
  <div>
    <v-text-field
      color="green"
      class="title"
      :prefix="precioVenta ? '$' : ''"
      id="precioVenta"
      v-model="precioVenta"
      :label="precioVentaLabel"
      v-validate="'required|decimal:2|max:30'"
      :error-messages="errors.collect('precioVenta')"
      data-vv-name="precioVenta"
      type="number"
      :outlined="outlined"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.precio.venta",
  inject: ["$validator"],
  components: {},
  props: {
    producto: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tipoRedondeo: {
      type: String,
      required: false,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
  data() {
    return {
      venta: null,
    };
  },
  computed: {
    precioVenta: {
      get() {
        return this.calcularPrecioVenta();
      },
      set(val) {
        return (this.venta = val);
      },
    },
    precioVentaLabel() {
      return this.tipoRedondeo === "entero"
        ? "Precio de Venta Manual"
        : "Precio de Venta Calculado";
    },
  },
  methods: {
    calcularCostoTotal() {
      const iva = this.producto.iva
        ? parseFloat(this.producto.iva.description)
        : 0;
      const costoTotal =
        this.producto.precioCosto *
        (1 + parseFloat(this.producto.flete ? this.producto.flete : 0) / 100) *
        (1 + iva / 100);

      return costoTotal;
    },
    calcularPrecioVenta() {
      if (this.producto.precioCosto && this.producto.ganancia) {
        let precioVenta =
          this.calcularCostoTotal() *
          (1 + parseFloat(this.producto.ganancia) / 100);

        if (this.tipoRedondeo === "decimales") {
          return parseFloat(precioVenta.toFixed(2));
        } else if (this.tipoRedondeo === "entero") {
          return Math.round(precioVenta);
        }
      } else {
        return null;
      }
    },

    calcularGanancia(precioVenta) {
      const costoTotal = this.calcularCostoTotal();

      // Calcular la ganancia basada en el precio de venta proporcionado
      let ganancia = (precioVenta / costoTotal - 1) * 100;

      return parseFloat(ganancia).toFixed(2); // Redondear la ganancia a dos decimales
    },
  },
  watch: {
    venta: {
      handler(val) {
        if (val !== null) {
          this.producto.ganancia = this.calcularGanancia(val); // Actualiza la ganancia al cambiar el precio de venta
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
