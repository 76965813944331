var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"#263238","height":"100%"},on:{"click":function($event){return _vm.$emit('process-promocion', _vm.promocion)}}},[_c('v-card-title',[_c('v-chip',{attrs:{"small":"","color":"success"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-clock")])],1),(true)?_c('span'):_vm._e(),_vm._v("Activa")],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"headline align-end"},on),[(_vm.promocion.fechaFinVigencia)?_c('v-chip',{staticClass:"align-end",attrs:{"x-small":"","color":"#FBC02D"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"light":"","x-small":""}},[_vm._v("mdi-clock")])],1),_c('span',[_vm._v(_vm._s(_vm.moment(_vm.promocion.fechaFinVigencia).format("ll")))])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v(" Vence el "+_vm._s(_vm.moment(_vm.promocion.fechaFinVigencia).format("ll")))])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-xs-center",attrs:{"align":"center","cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"headline"},on),[_c('p',[_c('b',[_vm._v(_vm._s(_vm.promocion.nombre != "" ? _vm.promocion.nombre : "Aquí va el nombre")+" ")])])])]}}])},[_c('span',[_vm._v("Nombre")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"title"},on),[_c('p',[_c('b',[_vm._v(_vm._s(_vm.promocion.descripcion != "" ? _vm.promocion.descripcion : "Aquí va la descripción")+" ")])])])]}}])},[_c('span',[_vm._v("Descripción")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-center display-1",staticStyle:{"color":"grey"}},on),[_vm._v(" $ "+_vm._s(_vm.promocion.precioVenta)+" ")])]}}])},[_c('span',[_vm._v("Precio venta")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }