<template>
  <div>
    <v-text-field
      v-model="producto.precioCosto"
      id="precioCosto"
      v-validate="'required|decimal:2|max:30'"
      :error-messages="errors.collect('precioCosto')"
      data-vv-name="precioCosto"
      label="Precio de costo"
      :prefix="producto.precioCosto ? '$' : ''"
      type="number"
      required
      :outlined="outlined"
      :append-outer-icon="appendOuterIcon ? iconButton : ''"
      @click:append-outer="clickAppendOuter()"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.precio.costo",
  inject: ["$validator"],
  components: {},
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendOuterIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconButton: {
      type: String,
      required: false,
      default: 'mdi-send',
    },
  },
  methods: {
    clickAppendOuter(){

      this.$emit('click-button-append')
    }
  },
};
</script>

<style lang="scss" scoped></style>
