<template>
  <v-card max-height="500" flat>
    <v-img contain src="@/assets/man-woman.jpg" height="125px" dark>
      <v-row align="end" class="fill-height">
        <v-card-title>
          <div v-if="isCliente" class="display-1 text-center">
            {{ cliente.nombre }} {{ cliente.apellido }}
          </div>
          <div v-else class="title text-center">Cliente no disponible</div>
        </v-card-title>
      </v-row>
    </v-img>

    <v-card-text v-if="isCliente">
      <v-list class="pa-0 ma-0" dense two-line>
        <v-list-item v-if="cliente.condicionIva">
          <v-list-item-content>
            <v-list-item-title>Condición IVA</v-list-item-title>
            <v-list-item-subtitle>{{ cliente.condicionIva.nombre }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-text="'mdi-account-card-details'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ cliente.documento || 'Documento no disponible' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-text="'mdi-phone'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ cliente.telefono || 'Teléfono no disponible' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="direccion">
          <v-list-item-avatar>
            <v-icon v-text="'mdi-home'"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ direccion.calle }} {{ direccion.altura }}</v-list-item-title>
            <v-list-item-subtitle>{{ direccion.localidad.nombre }}, {{ direccion.localidad.provincia.nombre }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ direccion.barrio.nombre }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ direccion.nombre }} {{ direccion.depto ? ', Depto: ' + direccion.depto : '' }} {{ direccion.piso ? ', Piso: ' + direccion.piso : '' }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ direccion.observacionEnvio }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "cliente-info",
  props: {
    cliente: {
      required: true,
      type: Object
    },
    direccion: {
      required: true,
      type: Object
    }
  },
  computed: {
    isCliente() {
      return this.cliente !== null;
    }
  }
};
</script>

<style scoped>
</style>
