<template>
  <div>
    <v-card>
      <v-card-title
        >Direcciones
        <v-spacer></v-spacer>
        <v-btn icon @click="openDialogAdministrarDirecciones"
          ><v-icon>mdi-cog</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <cliente-direccion-list
          @process-direccion="setDireccion"
          :direcciones="direcciones"
        ></cliente-direccion-list>
      </v-card-text>
    </v-card>
    <v-dialog width="600px" v-model="dialogDirecciones" v-if="cliente">
      <v-card>
        <v-container>
          <v-card-title class="pa-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialogAdministrarDirecciones">
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <cliente-direcciones
            v-if="cliente"
            :onlySelect="true"
            :cliente="cliente"
            
          ></cliente-direcciones>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ClienteDirecciones from "@/views/cliente/direcciones/cliente.direcciones.vue";
import ClienteDireccionList from "@/components/cliente/direcciones/cliente.direcciones.list.vue";
import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";

export default {
  name: "cliente.direccion.seleccion",
  components: {
    ClienteDireccionList,
    ClienteDirecciones,
  },
  props: {
    cliente: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      dialogDirecciones: false,
      direcciones: [],
    };
  },
  methods: {
    ...mapMutations("ventas", ["SET_CLIENTE_DIRECCION"]),
    openDialogAdministrarDirecciones() {
      this.dialogDirecciones = true;
      this.$emit("close-menu");
    },
    closeDialogAdministrarDirecciones() {
      this.dialogDirecciones = false;
      this.$emit("open-menu");
      this.fetchDirecciones();
    },
    setDireccion(direccion) {
      this.$emit("process-direccion", direccion);
    },
    fetchDirecciones() {
      this.loading = true;
      ClienteDireccionesAPI.index(this.cliente.id).then((response) => {
        this.direcciones = response.data;

        this.loading = false;
      });
    },
  },
  created() {
    this.fetchDirecciones();
  },
};
</script>

<style lang="scss" scoped></style>
