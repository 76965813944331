<template>
  <div>
    <v-row justify="center">
      <v-col cols="10">
        <v-row>
          <v-col>
            <h1>Métricas</h1>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <DatePickerMultiple @process-select="setDateFilter" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-container>
                <v-row v-if="reporte && !loading">
                  <v-col cols="12" md="12">
                    <template v-if="reporte">
                      <v-tabs v-model="tabs" height="100%">
                        <v-tab color="transparent" class="pa-0" href="#ventas">
                          <v-hover v-slot="{ hover }">
                          <v-card flat :color="hover ? '#737373' : '' ">
                            <v-container>
                              <div class="text-tab">Ventas brutas</div>
                              <div class="value-tab">
                                {{ reporte.ventas.total | convertPesoArg }}
                              </div>
                            </v-container>
                          </v-card>
                          </v-hover>
                        </v-tab>
                        <v-tab class="pa-0" href="#unidadesVendidas">
                          <v-hover v-slot="{ hover }">
                          <v-card flat :color="hover ? '#737373' : '' ">
                            <v-container>
                              <div class="text-tab">Productos vendidos</div>
                              <div class="value-tab">
                                {{ reporte.detallesComprobantes.length }}
                              </div>
                            </v-container>
                          </v-card>
                          </v-hover>
                        </v-tab>
                        <v-tab
                          disabled
                          style="opacity: 1 !important"
                          class="white--text pa-0"
                        >
                          <v-card flat>
                            <v-container>
                              <div class="text-tab">Cantidad de ventas</div>
                              <div class="value-tab">
                                {{ reporte.ventas.cantidad }}
                              </div>
                            </v-container>
                          </v-card>
                        </v-tab>
                        <v-tab
                          style="opacity: 1 !important"
                          href="#facturado"
                          class="white--text pa-0"
                        >
                         <v-hover v-slot="{ hover }">
                          <v-card flat :color="hover ? '#737373' : '' ">
                            <v-container>
                              <div class="text-tab">Facturado</div>
                              <div class="value-tab">
                                {{
                                  reporte.ventas.facturado.total ||
                                  0 | convertPesoArg
                                }}
                              </div>
                            </v-container>
                          </v-card>
                         </v-hover>
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tabs">
                        <v-tab-item id="ventas">
                          <v-row class="mt-2">
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>Efectivo</div>
                                  <v-card-title class="subtitle-1">
                                    {{
                                      reporte.ventas.efectivo
                                        ? reporte.ventas.efectivo
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>Tarjeta de débito</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["tarjeta de débito"]
                                        ? reporte.ventas["tarjeta de débito"]
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>Tarjeta de crédito</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["tarjeta de crédito"]
                                        ? reporte.ventas["tarjeta de crédito"]
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>Mercado pago</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["mercado pago"]
                                        ? reporte.ventas["mercado pago"]
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>Transferencia</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["transferencia"]
                                        ? reporte.ventas["transferencia"]
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-card flat>
                                <v-container>
                                  <div>CTA. CTE.</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["cta. cte."]
                                        ? reporte.ventas["cta. cte."]
                                        : 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                        <v-tab-item id="unidadesVendidas">
                          <v-row class="mt-2">
                            <v-col cols="12" md="12">
                              <v-simple-table fixed-header>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Producto</th>
                                      <th class="text-left">Cantidad</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        item, i
                                      ) in reporte.detallesComprobantes"
                                      :key="i"
                                    >
                                      <td>{{ item.nombre }}</td>
                                      <td>{{ item.cantidad }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                        <v-tab-item id="facturado">
                          <v-row class="mt-2">
                            <v-col cols="12" md="3">
                              <v-card flat>
                                <v-container>
                                  <div>Efectivo facturado</div>
                                  <v-card-title class="subtitle-1">
                                    {{
                                      reporte.ventas.facturado.efectivo ||
                                      0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-card flat>
                                <v-container>
                                  <div>Tarjeta de débito</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas.facturado[
                                        "tarjeta de débito"
                                      ] || 0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-card flat>
                                <v-container>
                                  <div>Tarjeta de crédito</div>
                                  <v-card-title>
                                    {{
                                      reporte.ventas["tarjeta de crédito"] ||
                                      0 | convertPesoArg
                                    }}
                                  </v-card-title>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs-items>

                    
                    </template>
                  </v-col>
                  <v-col cols="12">
                      <div class="mt-4 sc-metrics-subtitle__title">
                        Retiros de buzón
                      </div>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-card flat class="">
                            <v-container>
                              <div class="text-tab">Dinero en buzón</div>
                              <div class="value-tab">
                                {{ reporte.buzon | convertPesoArg }}
                              </div>
                            </v-container>
                          </v-card>
                        </v-col>
                      </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="loading">
                  <v-container style="height: 400px">
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                    >
                      <v-col class="text-subtitle-1 text-center" cols="12">
                        Generando el reporte...
                      </v-col>
                      <v-col cols="6">
                        <v-progress-linear
                          color="deep-purple accent-4"
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="dateFilter">
          <v-col cols="6">
            <ReporteVentasPorHora
              :dateFilter="dateFilter"
            ></ReporteVentasPorHora>
          </v-col>
         
        </v-row>
      </v-col>
    </v-row>
 
  </div>
</template>

<script>
import DatePickerMultiple from "@/components/reportes/DatePickerMultiple";
import ReporteVentasPorHora from "./ReporteVentasPorHora.vue";
import ReporteVentasPorMes from "@/views/reportes/ventas/ReporteVentaPorMes.vue";
export default {
  name: "reporte.ventas.por.periodo",

  components: { DatePickerMultiple, ReporteVentasPorHora, ReporteVentasPorMes },
  data() {
    // filters=&from_current=2021-09-08T15:00:00.000Z&start_period=custom&to_current=2021-09-16T15:00:00.000Z
    return {
      // dates: [new Date().toISOString().substr(0, 10)],
      loading: false,
      reporte: null,
      reporteGanancia: null,

      tabs: null,
      tab: null,
      dateFilter: null,
      items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  methods: {
    setDateFilter(filter) {
      this.dateFilter = filter;
    },
    fetchReporteArqueo(filter) {
      console.log("Esta llegando: ", filter);
      this.loading = true;
      var url = `/reportes/reporteventaporrango?${filter}`;
      this.axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.reporte = response.data;
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.loading = false;
          }
          this.loading = false;
        });
    },
  },
  watch: {
    dateFilter(val) {
      this.fetchReporteArqueo(val);
    },
  },
};
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
  text-align: left;
}
.v-tab,
.v-card {
  width: 100%;
}
.text-tab {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
}
.value-tab {
  margin-left: 6px;

  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 8px;
  margin-top: 8px;
}
.sc-metrics-subtitle__title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}

.sc-dropdown-group__label {
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 4px;
}
</style>
