<template>
    <div>
        <reporte-productos-generar-lista-precios></reporte-productos-generar-lista-precios>
    </div>
</template>

<script>
import ReporteProductosGenerarListaPrecios from "@/views/reportes/productos/ReporteProductosGenerarListaPrecios"
    export default {
        name: 'reporte.planilla.gondola',
        components: {
            ReporteProductosGenerarListaPrecios
        }
    }
</script>

<style lang="scss" scoped>

</style>