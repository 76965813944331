<template>
  <div>
    <v-toolbar flat color="#005d5b">
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="two-colors">
      <div class="d-flex flex-column">
        <v-row justify="center">
          <v-col cols="2">
             <v-sheet rounded="lg">
              <v-list color="transparent">
                <v-list-item v-for="item in links" :key="item.text" link :to="item.route">
        <v-list-item-action>
          <v-icon :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            ><span style="font-size: 0.9375rem">{{ item.text }}</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list subheader>
      <v-subheader>Administrar</v-subheader>

      <v-list-item v-for="item in navAdministrar" :key="item.text" :to="item.route">
        <v-list-item-icon>
          <v-icon :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><span style="font-size: 0.9375rem">{{ item.text }}</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
            </v-sheet>
          </v-col>
          <v-col lg="6">
            <router-view />
            <div class="h-100 d-flex flex-column">
              <div class="justify-content-left d-none d-sm-block">
                <v-row>
                  <v-col cols="12" md="5" lg="8" v-if="sucursal">
                    <div class="info-cont flex-grow-1 text-left text-white">
                      <h4 class="">Sucursal</h4>
                      <h3>
                        <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                        {{ sucursal.direccion }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" lg="4">
                    <div class="info-cont flex-grow-1 text-left text-white" v-if="cajaComputed">
                      <h4 class="">Caja</h4>
                        <v-list-item
                        class="ml-0 pl-0"
                        @click="quitarCajaSeleccionada"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <h3>
                              <v-icon>mdi-desktop-classic</v-icon
                              > {{ cajaComputed.nombre }}
                            </h3>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                   
                    </div>
                  </v-col>
                </v-row>
                <!-- <v-list-item class="ma-0 pa-0">
                      <v-list-item-avatar>
                        <v-btn icon @click="eliminarSucursalSeleccioanda">
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h4 class="">Sucursales</h4>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                <!-- <v-list-item @click="eliminarSucursalSeleccioanda" class="ml-0 pl-0">
                      <v-list-item-avatar>
                        <v-btn icon >
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h4 class="">Sucursales</h4>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->

                <v-card class="rounded-card mt-4" v-if="arqueosDeCaja">
                  <v-card-title>Jornada - {{moment(arqueosDeCaja.fecha_jornada).format('lll')}} </v-card-title>
                  <v-card-text>
                    <h2 class="mb-3 text-left text-white">Arqueos de caja</h2>

                    <v-list two-line>
                      <v-list-item
                        v-for="arqueo in arqueosDeCaja.arqueos"
                        :key="arqueo.id"
                      >
                        <v-list-item-avatar>
                          <v-icon dark :color="arqueo.esVigente ? 'success' : ''"> mdi-desktop-classic </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{arqueo.username}}</v-list-item-title>
                          <v-list-item-subtitle>{{moment(arqueo.fechaInicio).format('lll')}}</v-list-item-subtitle>

                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon color="grey lighten-1"
                              >mdi-menu-right</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                   
                  </v-card-text>
                </v-card>
                 <v-card class="rounded-card mt-4" v-else>
                  <v-card-text>
                    <h2 class="mb-3 text-left text-white">Arqueos de caja</h2>

                    <v-list two-line>
                      <v-list-item
                      >
                        <v-list-item-avatar>
                          <v-icon dark > </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>No existen arqueos de caja </v-list-item-title>

                        </v-list-item-content>
                       
                      </v-list-item>
                    </v-list>
                   
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
  
    </div>
  </div>
</template>

<script>
import ContarBilletes from "@/views/actividad/contar.billetes.vue";

import { getStorage } from "@/services/serviceLocalStorage";

export default {
  name: "seleccion.sucursal.caja.arqueo",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    ContarBilletes,
  },
  data() {
    return {
      caja: { descripcion: "" },
      arqueosDeCaja: null,
      loading: false,
      tieneSucursales: true,
      cajas: [],
      cajaSeleccionada: null,
      montoInicial: 0,
      dialog: false,
       navAdministrar: [
        {
          id: 1,
          icon: "mdi-sitemap",
          text: "Categorias",
          route: "/Categorias",
        },
        {
          id: 2,
          icon: "mdi-account-multiple",
          text: "Proveedores",
          route: "/proveedores",
        },
        { id: 3, icon: "mdi-basket", text: "Marcas", route: "/marcas" },
      ],
    };
  },
  computed: {
    sucursal() {
      return this.$store.state.sucursales.sucursal
    },

        cajaComputed() {
      return this.$store.state.sucursales.caja
    },
    // esValidoIniciar(){
    //   if (this.cajaSeleccionada && this.montoInicial > 0) {
    //     return true
    //   }
    //   return false
    // }
  },
  methods: {
       quitarCajaSeleccionada(){
      localStorage.removeItem("caja")
      this.$router.push('/cajas')
    },
    eliminarSucursalSeleccioanda() {
      localStorage.removeItem("sucursal");
      this.$router.push("/sucursales");
    },
   
    fetchArqueos(id_caja) {
      console.log(this.$store);
      this.$store.commit('initialData/SET_LOADING_DATA_INIT',true)
      var id_caja = this.$route.params.id_caja;
      var url = `/sucursales/arqueos/${id_caja}`;
      this.axios.get(url).then((response) => {
        console.log('response.data.arqueos',response.data.arqueos);
        if (response.data.vigente ) {
      this.$store.commit('sucursales/SET_ARQUEO_CAJA', response.data.vigente);

          // localStorage.setItem("arqueoCaja", JSON.stringify(response.data.vigente ));

      this.$store.commit('initialData/SET_LOADING_DATA_INIT',false, { root: false })
           this.$router.push(response.data.next);

        }
        if (!response.data.arqueos){
      this.$store.commit('initialData/SET_LOADING_DATA_INIT',false, { root: false })

            this.$router.push("/iniciarCaja");

        }else{
      this.$store.commit('initialData/SET_LOADING_DATA_INIT', { root: false })


          this.arqueosDeCaja = response.data;
        }
      });
    },
  },



  created() {
    // this.fetchArqueos();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 50px;
}
.two-colors {
  background: #005d5b;
  background: linear-gradient(
    180deg,
    #005d5b 0,
    #005d5b 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );

  height: 150vh;
}
</style>
