<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          v-if="isSelectPedido && !selectPedido && fab"
          @click="menu = true"
          class="mx-2"
          fab
          dark
          color="orange"
        >
          <v-icon dark> mdi-format-list-bulleted-square </v-icon>
        </v-btn>
      </template>
      <span>Seleccionar pedido</span>
    </v-tooltip>

    <v-btn
      class="justify-center"
      v-if="isSelectPedido && !selectPedido && !fab"
      block
      outlined
      small
      color="orange"
      @click="menu = true"
    >
      Seleccionar pedido
      <v-icon small class="justify-end ml-2">mdi-page-next</v-icon>
    </v-btn>

    <v-dialog width="500" scrollable v-model="menu">
      <v-card>
        <v-card-title
          >Pedidos aprobados
          <v-spacer></v-spacer>
          <v-btn large icon @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert v-if="pedidos.length == 0" prominent type="warning">
            <v-row align="center">
              <v-col class="grow"> No hay pedidos aprobados </v-col>

              <v-col class="shrink">
                <v-btn @click="goToGenerarPedido">Aprobar pedidos</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-list v-else-if="tipoComprobante == 'compra'">
            <v-list-item dense>
              <v-list-item-action>
                <small>Nro</small>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Proveedor</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item-group v-model="select" color="primary">
              <v-list-item
                @click="seleccionarPedido(pedido)"
                v-for="pedido in pedidos"
                :key="pedido.id"
              >
                <v-list-item-action>{{ pedido.numeroVentaPorArqueo }}</v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ pedido.proveedor.nombre }}</v-list-item-title>
                  <v-list-item-subtitle>{{ pedido.fechaAlta }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list v-else>
            <v-list-item dense>
              <v-list-item-action>
                <small>Nro</small>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Cliente</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item-group v-model="select" color="primary">
              <v-list-item
                @click="seleccionarPedido(pedido)"
                v-for="pedido in pedidos"
                :key="pedido.id"
              >
                <v-list-item-action>{{ pedido.numeroVentaPorArqueo }}</v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    pedido.cliente.cliente.nombre
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ pedido.fechaAlta }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <pedido-seleccionado-consultar
      tipoComprobante="venta"
      @set-menu="menu = !menu"
      v-if="selectPedido"
      @reestablecer-detalles="reestablecerDetalles"
      @reset-pedido="resetPedido"
      :pedido="selectPedido"
    ></pedido-seleccionado-consultar>
  </div>
</template>

<script>
import InformationDialogIcon from "@/components/dialogs/information-dialog.icon.vue";

import pedidoSeleccionadoConsultar from "@/components/pedido/consultar/pedido.seleccionado.consultar.vue";
export default {
  name: "pedidos.venta.seleccion.aprobados",
  components: {
    pedidoSeleccionadoConsultar,
    InformationDialogIcon,
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
    },
    selectPedido: {
      required: true,
    },
    isSelectPedido: {
      type: Boolean,
      required: true,
    },
    fab: {
      //Para mostrar button fab
      type: Boolean,
      required: false,
      default: false,
    },
    tipoComprobante: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      select: null,

      menu: false,
    };
  },
  methods: {
    goToGenerarPedido() {
      if (this.tipoComprobante === "compra") {
        this.$router.push({ name: "pedidoCompra" });
      } else {
        this.$router.push({ name: "pedidoVenta" });
      }
    },
    ahoraQuieroSeleccionarPedido() {
      this.menu = true;
      this.$emit("reset-pedido");
    },
    seleccionarPedido(pedido) {
      this.menu = false;
      this.$emit("set-pedido", pedido);
    },
    resetPedido() {
      this.$emit("reset-pedido");
    },
    setRemitoSinPedido() {
      this.$emit("set-remito-sin-pedido");
    },
    reestablecerDetalles() {
      this.$emit("reestablecer-detalles");
    },
  },
};
</script>

<style lang="scss" scoped></style>
