<template>
  <div>
    <v-list-item @click="dialogProveedor = !dialogProveedor">
      <v-list-item-icon>
        <v-icon>mdi-account-search</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> {{title}} </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-btn class="justify-center" block large outlined>
      Seleccioná el proveedor
      <v-icon class="ml-2">mdi-account-search</v-icon>
    </v-btn> -->
    <v-dialog v-model="dialogProveedor" width="500">
      <!--  <template v-slot:activator="{ on }">
      <v-hover v-slot:default="{ hover }">
        <v-card v-on="on" outlined @click="dialogProveedor = !dialogProveedor">
          <v-card-text>
            <div :class="hover ? 'headline text-center' : 'text-center'">Proveedor</div>
            <div class="text-center title white--text" v-if="proveedor_select">
              {{ proveedor_select.nombre }}
            </div>
          </v-card-text>
        </v-card>
      </v-hover>
    </template> -->

      <v-card>
        <v-container>
          <v-card-title>
            Elegir Proveedor
            <v-spacer></v-spacer>
            <v-icon color="blue" @click="dialogProveedor = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                @click="sendProveedor(p)"
                v-for="p in proveedoresOrdenados"
                :key="p.id"
                cols="3"
              >
                <div>
                  <a>{{ p.nombre }}</a>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="proveedor_select">
            <v-spacer></v-spacer>
            <v-btn @click="sendProveedor(null)" text>
              <small>Quitar proveedor</small>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProveedorCardSelect",
  props: {
    title: {
      type: String,
      default: "Seleccionar",
    },
  },
  data() {
    return {
      dialogProveedor: false,
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
    ...mapGetters("productos", ["proveedor_select"]),

    proveedoresOrdenados() {
      function compare(a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
        return 0;
      }
      return this.proveedores.sort(compare);
    },
  },
  methods: {
    sendProveedor(val) {
      this.$emit("set-proveedor-register", val);
      this.dialogProveedor = false;
    },
    ...mapActions("proveedor",["fetchProveedores"])
  },
  created(){
    this.fetchProveedores()
  }
};
</script>

<style lang="scss" scoped></style>
