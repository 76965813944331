<template>
  <div class="navigation2">
    <div class="display-1">
      <v-icon large left v-show="categoriasSeleccionadas.length > 1" @click="goBackOne"
        >mdi-arrow-left</v-icon
      >{{ categoriasSeleccionadas.at(-1).name }}
    </div>
    <div id="navigation">
      <ul>
        <li
          @click="quitBreadCrumbCategoria(cat)"
          v-for="cat in categoriasSeleccionadas"
          :key="cat.id"
        >
          <a>
            <span class="ml-1">{{ cat.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="!loading">
      <card-list-categorias
        v-if="verCategorias"
        :key="reloadCategoriaList"
        @process-categoria="selectCategoria"
        :categorias="categorias"
      ></card-list-categorias>
      <productos-list-card-select
        v-if="!verCategorias"
        :paginacion="paginacion"
        :productos="productos"
        @process-producto="agregarProducto"
        @set-page="setPage_fetchProductos"
      ></productos-list-card-select>
    </div>
    <v-container v-if="loading">
      <v-row>
        <v-col v-for="i in 3" :key="i" cols="4">
          <v-skeleton-loader max-height="100" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardListCategorias from "@/views/categorias/CardListCategorias";
import ProductosListCardSelect from "@/components/producto/listar/productos.list.card.select.vue";
import CategoriasAPI from "@/api/categorias";


export default {
  name: "producto.por.categoria",
  components: {
    CardListCategorias,
    ProductosListCardSelect,
  },
  data() {
    return {
      reloadCategoriaList: 0,
      loading: false,
      categoriaPadre: [],
      categorias: [],
      productos: [],
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },

      categoriasSeleccionadas: [
        {
          id: 0,
          name: "Categorías",
        },
      ],
      verCategorias: true,
    };
  },
  computed: {
    dialogCategorias() {
      if (this.categoriasSeleccionadas.length > 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    agregarProducto(producto) {
      this.$emit("process-producto", producto);
      // this.categoriasSeleccionadas = [
      //   {
      //     id: 0,
      //     name: "Categorías",
      //   },
      // ];
    },
    goBackOne() {
      this.paginacion.page = 1

      this.categoriasSeleccionadas.splice(-1);
      var cat = this.categoriasSeleccionadas.at(-1)
      this.verCategorias = true;

       if (cat.name === "Categorías") {
        this.fetchCategoriaPadre(cat);
        // const index = this.categoriasSeleccionadas.indexOf(cat);
        // if (index > -1) {
        //   this.categoriasSeleccionadas.splice(index + 1);
        // }
      } else {
        CategoriasAPI.index(cat.name).then((response) => {
          if (response.data.parent.children.length == 0) {
            alert("buscar los productos");
          } else {
            this.categorias = response.data.parent.children;
          }
        });
       
      }

    },
    quitBreadCrumbCategoria(cat) {
      this.paginacion.page = 1
      var last = this.categoriasSeleccionadas[this.categoriasSeleccionadas.length - 1];

      if (last == cat) return;
      this.verCategorias = true;

      if (cat.name === "Categorías") {
        this.fetchCategoriaPadre(cat);
        const index = this.categoriasSeleccionadas.indexOf(cat);
        if (index > -1) {
          this.categoriasSeleccionadas.splice(index + 1);
        }
      } else {
        CategoriasAPI.index(cat.name).then((response) => {
          if (response.data.parent.children.length == 0) {
            alert("buscar los productos");
          } else {
            this.categorias = response.data.parent.children;
          }
        });
        const index = this.categoriasSeleccionadas.indexOf(cat);
        if (index > -1) {
          this.categoriasSeleccionadas.splice(index + 1);
        }
      }
    },
    addBreadCrumb(cat) {
      this.categoriasSeleccionadas.push(cat);
      this.selectCategoria(cat);
    },
    selectCategoria(cat) {
      this.loading = true;
      this.categoriasSeleccionadas.push(cat);
      CategoriasAPI.index(cat.name).then((response) => {
        if (response.data.parent.children.length == 0) {
          var url = `/productos/p?q=&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}&categoria=${cat.name}`;
          this.axios.get(url).then((productos) => {
            // this.$emit("process-producto", productos.data.results);
            this.loading = false;
            this.productos = productos.data.results;
            this.paginacion.cantidad = productos.data.count;
            this.verCategorias = false;
            setTimeout(() => {
              this.$emit("process-end-page");
            }, 600);
          });
        } else {
          this.verCategorias = true;
          this.loading = false;
          this.categorias = response.data.parent.children;
          setTimeout(() => {
            this.$emit("process-end-page");
          }, 100);
        }
      });
    },
    setPage_fetchProductos(page) {
      this.paginacion.page = page;
      var last = this.categoriasSeleccionadas[this.categoriasSeleccionadas.length - 1];
      var url = `/productos/p?q=&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}&categoria=${last.name}`;
      this.axios.get(url).then((productos) => {
        this.productos = productos.data.results;
        this.paginacion.cantidad = productos.data.count;
      });
    },
    fetchCategoriaPadre() {
      CategoriasAPI.index().then((response) => {
        this.categoriaPadre = response.data.parent;
        this.categorias = response.data.parent.children;
      });
    },
  },
  created() {
    this.fetchCategoriaPadre();
  },
};
</script>

<style scoped>
#navigation ul {
  margin: 1px auto 0 auto;
  height: 50px;
  padding: 0;
  float: relative;
}

#navigation ul li {
  position: relative;
  list-style-type: none;
  display: inline;
}

#navigation li:before {
  content: "  > ";
}

#navigation li:first-child:before {
  content: none;
}
#navigation li:last-child a {
  color: grey;
  pointer-events: none;
}
</style>
