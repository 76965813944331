<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-toolbar color="transparent" flat>
          <v-btn icon @click="$router.go(-1)">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>
            <h2>{{ fechaInicioActividad(arqueo) }}</h2>
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>

    <div class="text-h5">
      Detalle de ventas
    </div>
    <ventas-por-forma-de-pago :ventas="arqueo.ventas"></ventas-por-forma-de-pago>
    <!-- <v-select
      label="Formas de pago"
      :items="formasDePago"
      item-text="descripcion"
      item-value="descripcion"
    >
    </v-select> -->
    <v-tabs v-model="tabs" grow>
      <v-tab color="transparent" class="pa-0" href="#ventas">
        <div class="text-tab">Ventas</div>
      </v-tab>
      <v-tab class="pa-0" href="#unidadesVendidas">
        <div class="text-tab">Anuladas</div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item id="ventas">
        <v-data-table
          :headers="headers"
          :items="ventas"
          :items-per-page="5"
          class="elevation-1"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.estado="{ item }">
            <v-chip small :color="getColor(item.estado)" dark>
              {{ item.estado ? "Pendiente" : "Facturado" }}
            </v-chip>
          </template>
          <template v-slot:item.fecha="{ item }">
            {{ moment(item.fecha).format("lll") }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total | convertPesoArg }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu min-width="50px" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item dense class="pt-0 mt-0" style="background-color: grey">
                  <v-list-item-title>   <VentaAnularComprobante :comprobante="item"></VentaAnularComprobante></v-list-item-title>
                </v-list-item>

                <v-list-item  v-if="!item.estado" @click="abrirFactura(item)">
                  <v-list-item-title>Factura</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item id="unidadesVendidas">
        <v-row class="mt-2">
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers_anuladas"
              :items="anuladas"
              :items-per-page="5"
              class="elevation-1"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.estado="{ item }">
                <v-chip small :color="getColor(item.estado)" dark>
                  {{ item.estado ? "Pendiente" : "Facturado" }}
                </v-chip>
              </template>
              <template v-slot:item.fecha="{ item }">
                {{ moment(item.fecha).format("lll") }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.total | convertPesoArg }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu min-width="50px" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item dense class="pt-0 mt-0" style="background-color: grey">
                      <v-list-item-title>Anular</v-list-item-title>
                    </v-list-item>

                    <v-list-item  v-if="!item.estado" @click="abrirFactura(item)">
                      <v-list-item-title>Factura</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  
                </v-menu>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary"> Reset </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogConsultarPreImpreso" width="500">
      <factura-pre-impresa-consultar
        v-if="dialogConsultarPreImpreso"
        :comprobante="comprobantePreImpreso"
        @close-dialog="
          (dialogConsultarPreImpreso = false), (comprobantePreImpreso = null)
        "
      ></factura-pre-impresa-consultar>
    </v-dialog>
  </div>
</template>

<script>
import VentasPorFormaDePago from "@/components/reportes/VentasPorFormaDePago.vue";
import FacturaPreImpresaConsultar from "@/components/venta/factura/venta.factura.pre-impreso.consultar.vue";
import AfipFiscalAPI from "@/api/afipfiscal"
import VentaAnularComprobante from "@/components/venta/VentaAnularComprobante.vue";
export default {
  components: { VentasPorFormaDePago, FacturaPreImpresaConsultar,VentaAnularComprobante },
  name: "reporte.resumen.arqueo.ventas",
  data() {
    return {
      tabs: null,
      ventas: [],
      anuladas: [],

      arqueo: null,
      dialogConsultarPreImpreso: false,
      comprobantePreImpreso: null,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        { text: "Generada por", value: "generada" },
        { text: "Total", value: "total" },
        { text: "Forma de pago", value: "formas_de_pago" },

        { text: "Estado", value: "estado" },
        { text: "Tipo factura", value: "tipo_factura" },
        { text: "Acciones", value: "actions" },
      ],

      headers_anuladas: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        { text: "Total", value: "total" },
        { text: "Forma de pago", value: "formas_de_pago" },
        { text: "Motivo", value: "motivo_anulacion" },
        { text: "Comentario", value: "motivo_anulacion_comentario" },

        { text: "Estado", value: "estado" },
        { text: "Tipo factura", value: "tipo_factura" },
        { text: "Acciones", value: "actions" },
      ],

      items: [],
    };
  },
  computed: {
    formasDePago() {
      return this.$store.state.comprobantes.formas_de_pago;
    },
  },
  methods: {
    getColor(bool) {
      if (bool) return "orange";
      else return "green";
    },
    fechaInicioActividad(arqueo) {
      return this.moment(arqueo.fechaInicio).format("LLL");
    },
    abrirFactura(comprobante) {
      console.log(comprobante);
      if (comprobante.tipo_factura == "Pre-impreso") {
        this.comprobantePreImpreso = comprobante;
        this.dialogConsultarPreImpreso = true;
      } else if (comprobante.tipo_factura == "Factura electrónica") {
        AfipFiscalAPI.open(comprobante.ce);
      }
      return;
    },
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          this.$router.go(-1);
        } else {
          if (params.arqueo) {
            this.arqueo = params.arqueo;
            this.axios
              .get(`/reportes/arqueo/ventas?id=${params.arqueo.id}`)
              .then((response) => {
                console.log(response.data);
                this.ventas = response.data.ventas;
                this.anuladas = response.data.anuladas;
              });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
