<template>
  <div>
    <v-row>
      <v-col>
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">
                  Listado de dueños
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  Aquí encontrarás la lista de dueños asociados a esta sucursal.
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="$router.push( {name: 'sucursalEmpleadosRegistrar'})">
                Registrar dueño
                <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Dueños de la sucursal</h3>
        <v-list>
          <v-list-item-group v-if="dueños_sucursal.length > 0">
            <v-list-item  v-for="dueño in dueños_sucursal" :key="dueño.id">
              <v-list-item-avatar>
                <v-icon dark>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ dueño.persona.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ dueño.persona.apellido }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="gestionarEmpleado(dueño, 'quitar')">
                  Remover
                  <v-icon small color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-else>
            <v-list-item-content>No se encontraron dueños en esta sucursal</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col>
        <h3>Todos los dueños de la empresa</h3>
        <v-list>
          <v-list-item-group v-if="dueños_empresa.length > 0">
            <v-list-item  v-for="dueño in dueños_empresa" :key="dueño.id">
              <v-list-item-avatar>
                <v-icon dark>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ dueño.persona.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ dueño.persona.apellido }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="gestionarEmpleado(dueño, 'asignar')" >
                  Asignar
                  <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-else>
            <v-list-item-content>No se encontraron dueños en la empresa</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>


  </div>
</template>

<script>

export default {
  name: "SucursalDueñosList",

  props: {
    sucursal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dueños_sucursal: [],
      dueños_empresa: [],
      dialogEmpleados: false
    };
  },
  methods: {
    handleClick(dueño) {
      this.$router.push({
        name: "detallesEmpleado",
        params: { id_empleado: dueño.id },
      });
    },
    async fetchEmpleados(uuid) {
      try {
        const response = await this.axios.get(`/empleados/asignaciones/owner/${uuid}`);
        this.dueños_sucursal = response.data.dueños_sucursal;
        this.dueños_empresa = response.data.dueños_empresa;
      } catch (error) {
        console.error("Error al obtener la lista de dueños", error);
      }
    },
     async gestionarEmpleado(dueño, accion) {
      // Realiza una solicitud POST para asignar o quitar al dueño de la sucursal
      try {
        const response = await this.$axios.post('/empleados/asignaciones/gestion/owner', {
          dueño_id: dueño.id,
          accion: accion,
          sucursal_uuid: this.sucursal.uuid,

        });
         this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `${response.data.detail}`,
            icon: "mdi-check",
            timeout: 3000,
          });
        // Actualiza las listas después de la operación
        this.fetchEmpleados(this.sucursal.uuid);
      } catch (error) {
        console.error('Error al gestionar dueño:', error);
        this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${error.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
      }
    },
   
  },
watch: {
  sucursal: {
    immediate: true,
    handler(newSucursal) {
      if (!newSucursal && this.$route.params.uuid_sucursal) {
        // Si no hay 'sucursal', pero hay un 'uuid' en los parámetros de la ruta
        const uuid = this.$route.params.uuid_sucursal;

        // Realiza la solicitud de empleados según el 'uuid'
        this.fetchEmpleados(uuid);
      } else if (newSucursal && newSucursal.uuid) {
        // Si hay 'sucursal', utiliza su 'uuid'
        this.fetchEmpleados(newSucursal.uuid);
      }
    },
  },
},
};
</script>

<style lang="scss" scoped>
/* Estilos personalizados si es necesario */
</style>
