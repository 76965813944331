<template>
    <v-row>
      <v-col
        class="text-center align-center"
        cols="12"
        md="3"
        v-for="chip in get_querys"
        :key="chip.key"
      >
        <v-card height="100%" class="d-flex align-center text-center">
          <v-container class="text-center">
            <div   v-if="chip.key == 'py'">
              <v-card-title class="ma-0 pa-0">

              <span class="caption">Plataforma</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close(chip)"> <v-icon> mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-img
              class="text-center"
             
              src="@/assets/pedidosya/pedidoYa.svg"
              max-height="58"
              contain
            ></v-img>
            </div>
            

            <div v-else-if="chip.key == 'proveedor'">
              <v-card-title class="ma-0 pa-0">

              <span class="caption">Proveedor</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close(chip)"> <v-icon> mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-icon color="blue" large>mdi-clipboard-account </v-icon>
              <div>
                {{ chip.nombre }}
              </div>
            </div>
            <div v-else-if="chip.key == 'marca'">
               <v-card-title class="ma-0 pa-0">

              <span class="caption">Marca</span>
               <v-spacer></v-spacer>
              <v-btn icon @click="close(chip)"> <v-icon> mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-icon color="blue" large>mdi-tag </v-icon>
              <div>
                {{ chip.nombre }}
              </div>
            </div>
            <div v-else-if="chip.key == 'categoria'">
               <v-card-title class="ma-0 pa-0">

              <span class="caption">Categoría</span>
               <v-spacer></v-spacer>
              <v-btn icon @click="close(chip)"> <v-icon> mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-icon color="blue" large>mdi-sitemap </v-icon>
              <div>
                {{ chip.nombre }}
              </div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: "productos.filtros.tag",
  props: {
    removeTagCategoria: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeTag: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    get_querys() {
      var array = [];
      var querys = this.$route.query;
      if (Object.keys(querys).length !== 0) {
        for (const property in querys) {
          var object = {
            nombre: null,
            key: "",
          };
          object.key = property;
          object.nombre = this.get_text_transform(querys[property]);
          if (property !== "categoria") {
            array.push(object);
          } else {
            if (!this.removeTagCategoria) {
              array.push(object);
            }
          }
        }
      }
      return array;
    },
  },
  methods: {
    get_text_transform(str) {
      if (typeof str === "string") {
        return str.match(/[^ ]+/g).join("-");
      } else {
        return str;
      }
    },
    remove_query_url(key) {
      let queryParams = Object.assign({}, this.$route.query);
      delete queryParams[key];
      this.$router.replace({ query: queryParams }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },

    close(chip) {
      this.$emit("close-chip", chip);
      this.remove_query_url(chip.key);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>