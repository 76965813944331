<template>
  <div>
    <nav-only-logout v-if="userEsEmpleado"></nav-only-logout>
    <!-- <navigation-admin v-if="!userEsEmpleado"></navigation-admin> -->
    <v-toolbar flat color="#afcb08">
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="two-colors">
      <div class="d-flex flex-column">
        <v-row justify="center">
          <v-col lg="6">
            <div class="h-100 d-flex flex-column">
              <div class="justify-content-left">
                <v-row>
                  <v-col cols="12" md="5" lg="8">
                    <div class="info-cont flex-grow-1 text-left text-white">
                      <h4 class="">Sucursal</h4>
                      <h3>
                        <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                        {{ sucursal.direccion }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" lg="4">
                    <div class="info-cont flex-grow-1 text-left text-white">
                      <h4 class="">Caja</h4>
                      <v-list-item
                        @click="quitarCajaSeleccionada"
                        class="ml-0 pl-0"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <h3>
                              <v-icon>mdi-desktop-classic</v-icon>
                              {{ caja.nombre }}
                            </h3>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>

                <v-card shaped class="mt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <h2 class="mb-3 mt-4 text-left text-white">
                          Iniciar la caja
                        </h2>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <h4 class="mb-3 mt-4 text-right text-white">
                          {{ moment().format("ll") }} {{ horaActual }}
                        </h4>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-row>
                      <v-col cols="12" md="6">
                        <v-list>
                          <v-list-item
                            :class="esValidoIniciar ? 'parpadea' : ''"
                            color="grey"
                            @click="agregarBilletes = true"
                          >
                            <v-list-item-avatar>
                              <v-icon>mdi-bank-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div></div>
                                Ingresar efectivo</v-list-item-title
                              >
                            </v-list-item-content>
                            <v-list-item-action> </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-dialog
                        width="600"
                        persistent
                        v-model="agregarBilletes"
                      >
                        <v-card>
                          <v-container>
                            <v-row>
                              <v-col>
                                <v-toolbar color="transparent" flat>
                                  <v-toolbar-title>
                                    <div class="mb-3 mt-2 text-left text-white">
                                      Ingresá la cantidad de billetes
                                    </div>
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <div class="mr-4 title">
                                    {{ montoInicial | convertPesoArg }}
                                  </div>
                                  <v-btn
                                    color="blue"
                                    @click="agregarBilletes = false"
                                    :disabled="montoInicial < 1"
                                    >Aceptar</v-btn
                                  >
                                  <!-- <v-btn
                                    icon
                                    @click="agregarBilletes = false"
                                    ><v-icon>mdi-close</v-icon></v-btn
                                  > -->
                                </v-toolbar>

                                <v-card-text>
                                  <contar-billetes
                                    @close-dialog="agregarBilletes = false"
                                    @process-monto="setMonto"
                                    textButton="Aceptar"
                                  ></contar-billetes>
                                </v-card-text>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-container>
                  <v-card-actions class="align-center justify-end d-flex">
                    <div
                      v-if="
                        cajas &&
                        cajas.cierre_anterior &&
                        configuracion.mostrarCierreCajaAnterior
                      "
                      style="color: #cdcdcd"
                      class="info-cont flex-grow-1 d-none d-lg-block text-center text-white"
                    >
                      <h4>Saldo anterior</h4>
                      <h3>
                        <v-icon large>mdi-currency-usd</v-icon
                        >{{ cajas.cierre_anterior }}
                      </h3>
                    </div>
                    <div
                      class="info-cont flex-grow-1 d-none d-lg-block text-center text-white"
                    >
                      <h3 class="">Saldo inicial</h3>
                      <h2>
                        <v-icon large>mdi-currency-usd</v-icon
                        >{{ montoInicial }}
                      </h2>
                    </div>
                    <v-btn
                      large
                      rounded
                      color="#afcb08"
                      @click="inciarArqueoCaja"
                      :disabled="esValidoIniciar"
                    >
                      Iniciar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <loading
      :active.sync="loading"
      loader="dots"
      :height="128"
      :width="128"
      background-color="#AFB"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import NavigationAdmin from "@/components/administrar.home.vue";
import ContarBilletes from "@/views/actividad/contar.billetes.vue";

import { getStorage } from "@/services/serviceLocalStorage";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "arqueo.caja.iniciar",
  components: {
    ContarBilletes,
    NavigationAdmin,
    Loading
  },
  data() {
    return {
      agregarBilletes: false,
      tieneSucursales: true,
      cajas: null,
      cajaSeleccionada: null,
      montoInicial: 0,
      horaActual: "",
      corteCaja: null,
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", ["isLogged"]),
    ...mapGetters("auth", ["userEsEmpleado"]),
    ...mapState("sucursales", ["configuracion", "caja"]),
    sucursalUuid() {
      return this.$store.getters["sucursales/sucursalUuid"];
    },
    sucursal() {
      return this.$store.state.sucursales.sucursal;
    },

    // caja() {
    //   return this.$store.getters["sucursales/caja_id_cookie"];

    // },
    esValidoIniciar() {
      if (this.montoInicial > 0) {
        return false;
      }
      return true;
    },
  },
  // mounted() {
  //   this.cajas = this.$route.params.cajas;
  // },
  methods: {
    quitarCajaSeleccionada() {
      localStorage.removeItem("caja");
      this.$router.push("/movimientos");
    },
    setMonto(billetes) {
      this.corteCaja = billetes;
      var saldo = null;
      billetes.forEach((element) => {
        saldo += element.cantidad * parseInt(element.valorBillete);
      });

      this.montoInicial = saldo;
    },
    fetchCajas() {
      var url = `/sucursales/cajas/${this.caja_id}`;
      this.axios.get(url).then((response) => {
        console.log(response);
        this.caja = response.data;
      });
    },

    inciarArqueoCaja() {
      this.loading = true;
      var data = {
        detalle: this.corteCaja,
      };
      this.axios
        .post("/sucursales/arqueodecaja/inicio", data)
        .then((response) => {
          this.$store.commit("sucursales/SET_ARQUEO_CAJA", data);

          localStorage.setItem("arqueoCaja", JSON.stringify(data));
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Bienvenido",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loading = false;

          this.$router.push("/home");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Ocurrió un problema al iniciar la caja",
            icon: "mdi-alert-circle-outline",
            timeout: 5000,
          });
          this.loading = false;
        });
    },
  },
  created() {
    if (this.userEsEmpleado) {
      this.$store.commit("SET_LAYOUT", {
        layout: "layout-basic",
        withNav: false,
      });
    } else {
      this.$store.commit("SET_LAYOUT", {
        layout: "layout-basic",
        withNav: true,
      });
    }
    setInterval(() => {
      this.horaActual = this.moment().format("LT");
    }, 1000);
  },
};
</script>

<style scoped>
.two-colors {
  background: #afcb08;
  background: linear-gradient(
    180deg,
    #afcb08 0,
    #afcb08 200px,
    hsla(0, 0%, 100%, 0) 0,
    hsla(0, 0%, 100%, 0) 0
  );
}
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
