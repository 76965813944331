<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-toolbar color="green">
      <v-toolbar-title
        ><v-icon>mdi-calculator</v-icon> Calcular precio de
        costo</v-toolbar-title
      >
    </v-toolbar>

    <v-card>
      <v-container>
        <v-card-text>
          <v-row align="center" align-content="center">
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="6">
                  <!--                
                <v-card outlined flat>
                  <v-container>
                    <v-card-title >
                      Precio costo actual
                    </v-card-title>
                    <v-card-text>
                      <div
                        class="d-flex align-center justify-center"
                        style="height: 100%"
                      >
                        <v-row
                          align="center"
                          justify="center"
                          align-content="center"
                        >
                          <div class="center">
                            <h1>{{ producto.precioCosto | convertPesoArg }}</h1>
                          </div>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-container>
                </v-card> -->
                  <v-card outlined flat>
                    <v-container>
                      <v-card-title class="justify-center">
                        Precio costo actual
                      </v-card-title>
                      <v-card-text class="mb-2">
                        <div
                          class="d-flex align-center justify-center"
                          style="height: 100%"
                        >
                          <v-row
                            align="center"
                            justify="center"
                            align-content="center"
                          >
                            <div class="center">
                              <h1>
                                {{ producto.precioCosto | convertPesoArg }}
                              </h1>
                            </div>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-card>
                  <!-- <p>
                    <b>Precio costo actual</b>
                  </p>
                  <v-text-field
                    color="green"
                    prepend-icon="mdi-cash"
                    disabled
                    v-model="producto.precioCosto"
                    type="number"
                  ></v-text-field> -->
                </v-col>
                 <v-col v-if="porcentaje" cols="6">
                  <v-card height="100" outlined flat>
                    <v-container>
                      <v-card-title class="justify-center">
                        Nuevo precio de costo
                      </v-card-title>
                      <v-card-text class="mb-2">
                        <div
                          class="d-flex align-center justify-center"
                          style="height: 100%"
                        >
                          <v-row
                            align="center"
                            justify="center"
                            align-content="center"
                          >
                            <div class="center">
                              <h1>{{ precioCostoNuevo | convertPesoArg }}</h1>
                            </div>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-card>

                  <!-- <v-text-field
                    color="green"
                    prepend-icon="mdi-cash"
                    v-model="precioCostoNuevo"
                    data-vv-name="precioCostoNuevo"
                    min="0"
                    type="number"
                    required
                  ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row class="mt-4" justify="center">
                <v-col>
                  <v-slider
                    v-model="porcentaje"
                    label="Porcentaje"
                    thumb-color="blue"
                    thumb-label="always"
                    @input="aplicarPorcentaje"
                  ></v-slider>
                  <v-text-field
                    autofocus
                    v-model="porcentaje"
                    outlined
                    @keyup.enter="aplicarPorcentaje"
                    label="Porcentaje"
                    type="number"
                    @input="aplicarPorcentaje"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
               <v-row justify="center">
               
                <v-col cols="6">
                  <v-card  outlined flat>
                    <v-container>
                      <v-card-title class="justify-center">
                        Precio de venta
                      </v-card-title>
                      <v-card-text class="mb-2">
                        <div
                          class="d-flex align-center justify-center"
                          style="height: 100%"
                        >
                          <v-row
                            align="center"
                            justify="center"
                            align-content="center"
                          >
                            <div class="center">
                              <h1>{{ precioVentaNuevo | convertPesoArg }}</h1>
                            </div>
                          </v-row>
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-text>
                        <div
                          class="mt-2 d-flex align-center justify-center"
                          style="height: 100%"
                        >
                          <v-row
                            align="center"
                            justify="center"
                            align-content="center"
                          >
                            <v-col cols="4">
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title> % Gan </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ producto.ganancia }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col cols="4">
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title> IVA </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ producto.iva }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col cols="4">
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title> Flete </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ producto.flete }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="end">
            <v-col>
              <v-btn text color="grey" @click="$emit('close')">Cancelar</v-btn>
              <v-btn
                color="green"
                :loading="loading"
                @click="enviarProducto(producto)"
                :disabled="!validarBotonSubmit"
                >Actualizar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

//Estoy intentando obtener los id_proveedor  y demas para saber y llenar los campos a la hora de modificar los datos
export default {
  name: "producto.calculadora.precioVenta",
  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      operacion: [
        { id: 1, nombre: "Incrementar" },
        { id: 2, nombre: "Decrementar" },
      ],
      loading: false,
      loadingOverlay: false,

      precioCostoNuevo: null,
      porcentaje: null,
      diferenciaPorcentaje: null,
      selectOperacion: 1,
      precioVentaNuevo: null,
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
      dialog: true,
    };
  },
  computed: {
    validarBotonSubmit() {
      return this.precioVentaNuevo && this.precioCostoNuevo;
    },
    ...mapState("productos", ["productos_selected"]),

    //Precio de venta redondeado. Se muestra el producto a actualizar
    precioVenta() {
       var iva = this.producto.iva? this.producto.iva.description : 0
      return Math.round(
        this.producto.precioCosto *
          (1 + parseFloat(this.producto.ganancia) / 100) *
          (1 + parseFloat(iva) / 100) *
          (1 + parseFloat(this.producto.flete) / 100)
      );
    },
    precioCostoComputed() {
      return Math.round(this.producto.precioCosto);
    },
  },
  methods: {
    // Obtener el porcentaje de diferencia que tiene el nuevo precio con el actual
    calcularPorcentaje(val) {
      var porcentaje = (
        (parseFloat(this.precioCostoNuevo) /
          parseFloat(this.precioCostoComputed) -
          1) *
        100
      ).toFixed(2); //toFixed para redondear a dos decimales

      this.diferenciaPorcentaje = porcentaje;
      this.porcentaje = porcentaje;
    },
    calcularPrecioVenta(precioCostoNuevo) {
      this.precioVentaNuevo = Math.round(
        precioCostoNuevo *
          (1 + parseFloat(this.producto.ganancia) / 100) *
          (1 + parseFloat(this.producto.iva) / 100) *
          (1 + parseFloat(this.producto.flete) / 100)
      );
    },

    aplicarPorcentaje() {
      this.precioCostoNuevo = (
        this.precioCostoComputed *
        (1 + parseInt(this.porcentaje) / 100)
      ).toFixed(2);

      this.precioVentaNuevo = Math.round(
        this.precioCostoNuevo *
          (1 + parseFloat(this.producto.ganancia) / 100) *
          (1 + parseFloat(this.producto.iva) / 100) *
          (1 + parseFloat(this.producto.flete) / 100)
      );
    },

    async enviarProducto() {
      this.$emit("change-precio-costo", this.precioCostoNuevo);
      this.$emit("close");


    },
  },
  watch: {
    precioCostoNuevo: function (val) {
      console.log("precioCostoNuevo", val);
      let precio = val;

      if (precio < 0 || precio.length > 7 || precio === "") {
        return (
          (this.diferenciaPorcentaje = null), (this.precioVentaNuevo = null)
        );
      } else {
        // this.calcularPrecioVenta(precio);
        console.log(" this.calcularPorcentaje(precio);");
        this.calcularPorcentaje(precio);
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
