<template>
  <div>
    <h1>Egresos / Ingresos</h1>
    <v-row>
      <v-col cols="12" md="4">
        <bar-chart v-if="chartDataEgreso" :chartdata="chartDataEgreso" />
      </v-col>
      <v-col cols="12" md="4">
        <bar-chart v-if="chartDataIngreso" :chartdata="chartDataIngreso" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineChart from "@/chartjs/line.chart.vue";
import BarChart from "@/chartjs/bar.chart.vue";

export default {
  name: "reporte.compras",
  components: {
    LineChart,
    BarChart,
  },
  data() {
    return {
      chartDataEgreso: null,
      chartDataIngreso: null,

      options: {
        scales: {
          xAxes: [
            {
              barPercentage: 0.4,
            },
          ],
        },
      },
    };
  },
  methods: {
    fetchReporte() {
      var url = "/reportes/reporteEgresoIngreso";
      this.axios.get(url).then((response) => {
        this.chartDataEgreso = response.data.egreso;
        this.chartDataIngreso = response.data.ingreso;
      });
    },
  },
  created() {
    this.fetchReporte();
  },
};
</script>

<style lang="scss" scoped></style>
