<template>
  <v-list-item>
    <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ detalle.producto.descripcion }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon class="align-center">
      <v-btn icon @click="incremetnCant(detalle)">
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn icon @click="decrementCant(detalle)">
        <v-icon>mdi-minus-circle</v-icon>
      </v-btn>
      <v-btn icon @click="deleteItem(detalle)">
        <v-icon>mdi-delete-circle</v-icon>
      </v-btn>
    </v-list-item-icon>
    <v-list-item-icon class="align-center"
      ><div style="font-size: 20px">
        {{ Number(detalle.producto.precioVenta * detalle.cantidad) | convertPesoArg }}
      </div></v-list-item-icon
    >
  </v-list-item>
</template>

<script>
export default {
  name: "ItemDetallePedido",
  props: {
    detalle: {
      type: Object,
      required: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  methods: {
    incremetnCant(detalle) {
      //  this.$emit("incremetn-cant", this.detalle);
      detalle.cantidad++;
      // this.sugerirDescripcion();
    },
    decrementCant(detalle) {
      var cantidad = parseInt(detalle.cantidad);
      if (cantidad == 1) {
        return;
      }
      detalle.cantidad--;
    },
    deleteItem(detalle) {
      this.$emit("delete-detalle", detalle);

      // const index = this.promocion.detalles.indexOf(detalle);
      // if (index > -1) {
      //   this.promocion.detalles.splice(index, 1);
      // }
    },
    selectDetalle() {
      this.$emit("select-detalle", this.detalle);
    },
  },
};
</script>

<style lang="scss" scoped></style>
