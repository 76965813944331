<template>
  <div>

    <div flat v-if="ventas.length > 0 && !loading">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="ventas"
        :search="search"
        class="row-pointer"
        @click:row="handleClick"
      >
        <template v-slot:item.fechaAlta="{ item }">
          {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
        </template>
        <template v-slot:item.total="{ item }"> $ {{ item.total }} </template>

        <template v-slot:item.origen="{ item }">
          <v-icon v-if="!pendienteFacturacion && item.origen"
            >mdi-cloud-question</v-icon
          >

          <v-icon v-if="!pendienteFacturacion && !item.origen" color="green"
            >mdi-cloud-check-outline</v-icon
          >
          <v-icon v-if="pendienteFacturacion && item.origen" color="orange"
            >mdi-cloud-alert</v-icon
          >
        </template>
      </v-data-table>
      
    </div>
    <div class="mt-6 " v-if="ventas.length == 0 && !loading">
      <v-alert>

      No hay ventas
      </v-alert>
    
    </div>
    <v-container v-if="loading">
      <v-skeleton-loader
        max-height="50"
        type="table-heading"
      ></v-skeleton-loader>

      <v-skeleton-loader
        max-height="175"
        type="table-tbody"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import { getStorage } from "@/services/serviceLocalStorage";
import VentasApi from "@/api/venta/venta.js";

export default {
  name: "ventas.list",
  props: {
    ventas: {
      type: Array,
      required: true

    },
    pendienteFacturacion: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      search: "",


    };
  },
  computed: {
    headers() {
      return [
        { text: "Fecha", value: "fechaAlta" },
        { text: "Nro", value: "numeroVentaPorArqueo" },

        { text: "Total", value: "total" },
        { text: "a", value: "origen" },
      ];
      // if (this.esPedidosYa) {
      //   return [
      //     { text: "Fecha", value: "fechaAlta" },
      //     { text: "Nro", value: "numeroVentaPorArqueo" },
      //     { text: "Cliente", value: "cliente.cliente.nombre" },

      //     { text: "Total", value: "total" },
      //   ];
      // } else {
      // }
    },
   
  },

  methods: {
    
   
    handleClick(value) {
      this.$emit('process-open', value)
      // this.$router.push({ name: "detalleVenta", params: { id: value.id } });
    },

   
  },
  
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
