<template>
  <layout-remito-registrar>
    <template v-slot:up-title>Promociones</template>

    <template v-slot:title>
      <v-list class="ma-0 pa-0">
        <v-list-item class="pl-1">
          <v-list-item-title>
            <h1>Nueva promoción</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list></template
    >

    <template v-slot:navigation-controller>
      <v-container>
        <div v-if="step == 1">
          <v-text-field
            outlined
            v-model="promocion.nombre"
            autofocus
            label="Nombre de la promocion"
            id="nombre"
            v-validate="'required|max:30'"
            :error-messages="errors.collect('nombre')"
            data-vv-name="nombre"
            :counter="100"
            maxlength="100"
            required
          ></v-text-field>

          <v-textarea
            outlined
            v-model="promocion.descripcion"
            label="Describe la promoción"
            id="descripcion"
            :error-messages="errors.collect('descripcion')"
            v-validate="'required|max:100'"
            :counter="100"
            name="descripcion"
            rows="3"
            row-height="30"
            maxlength="100"
          ></v-textarea>
          <v-text-field
            color="green"
            class="title"
            :prefix="promocion.precioVenta ? '$' : ''"
            id="precioVenta"
            v-model="promocion.precioVenta"
            label="Precio de venta"
            v-validate="'required|integer|max:30'"
            :error-messages="errors.collect('precioVenta')"
            data-vv-name="precioVenta"
            type="number"
            outlined
          ></v-text-field>

          <div style="line-height: 1.3333" class="title mt-2 mb-2">
            Vigencia
          </div>
          <v-menu
            v-model="menuFechaInicio"
            :close-on-content-click="false"
            :nudge-right="80"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="promocion.fechaInicioVigencia"
                outlined
                label="Fecha de inicio"
                prepend-icon="mdi-calendar"
                readonly
                color="green lighten-1"
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              @input="menuFechaInicio = false"
              color="green lighten-1"
              v-model="promocion.fechaInicioVigencia"
              :min="new Date().toISOString().substr(0, 10)"
              locale="es"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuFechaFin"
            :close-on-content-click="false"
            :nudge-right="80"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="promocion.fechaFinVigencia"
                label="Fecha de finalización"
                prepend-icon="mdi-calendar"
                readonly
                color="green lighten-1"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="menuFechaFin = false"
              color="green lighten-1"
              v-model="promocion.fechaFinVigencia"
              :min="
                promocion.fechaInicioVigencia
                  ? promocion.fechaInicioVigencia
                  : new Date().toISOString().substr(0, 10)
              "
              locale="es"
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-if="step == 2">
          <v-checkbox
            dense
            v-model="promocion.controlaStock"
            label="Control de inventario"
            hide-details
          ></v-checkbox>

          <template v-if="promocion.controlaStock">
            <v-card class="mt-4" color="#424242">
              <v-subheader>Productos</v-subheader>

              <v-list color="#424242">
                <v-list-item v-for="tag in promocion.detalles" :key="tag.id">
                  <v-list-item-avatar> {{ tag.cantidad }} </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <i>{{ tag.producto.descripcion }}</i></v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn icon @click="incremetnCant(tag)">
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn icon @click="decrementCant(tag)">
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                    <v-btn icon color="#D84315" @click="deleteItem(tag)">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
                <v-hover v-slot:default="{ hover }">
                  <v-list-item
                    style="background-color: #2096f4"
                    :dense="!hover"
                    @click="dialogAddProducto = true"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Añadir producto</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-btn icon>
                        <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-hover>
              </v-list>
            </v-card>

            <v-dialog v-model="dialogAddProducto" width="500">
              <v-toolbar
                >Añadir producto <v-spacer></v-spacer>
                <v-icon large @click="dialogAddProducto = false"
                  >mdi-close</v-icon
                ></v-toolbar
              >
              <v-card>
                <v-card-title>
                  <v-autocomplete
                    v-model="productosSeleccionados"
                    :items="productos"
                    :loading="isLoading"
                    :search-input.sync="inputSearch"
                    item-text="descripcion"
                    item-value="descripcion"
                    label="Buscar"
                    dense
                    autofocus
                    hide-selected
                    hide-no-data
                    clearable
                    return-object
                  ></v-autocomplete>
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="tag in promocion.detalles"
                      :key="tag.id"
                    >
                      <v-list-item-avatar>
                        {{ tag.cantidad }}
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <i>{{
                            tag.producto.descripcion
                          }}</i></v-list-item-title
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-btn icon @click="incremetnCant(tag)">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                        <v-btn icon @click="decrementCant(tag)">
                          <v-icon>mdi-minus-circle</v-icon>
                        </v-btn>
                        <v-btn icon @click="deleteItem(tag)">
                          <v-icon>mdi-delete-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </div>
      </v-container>
    </template>

    <template v-slot:vista-previa>
      <v-subheader><b>Vista previa</b></v-subheader>

      <v-row justify="center">
        <v-col cols="4">
          <promocion-vista-previa
            :promocion="promocion"
          ></promocion-vista-previa>
        </v-col>
        <v-col cols="4" v-if="promocion.controlaStock">
          <v-card height="100%">
            <v-toolbar>
              <v-toolbar-title> Inventario </v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="detalle in promocion.detalles"
                    :key="detalle.id"
                  >
                    <v-list-item-avatar>
                      {{ detalle.cantidad }}
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <i>{{
                          detalle.producto.descripcion
                        }}</i></v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-btn icon @click="incremetnCant(detalle)">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                      <v-btn icon @click="decrementCant(detalle)">
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteItem(detalle)">
                        <v-icon>mdi-delete-circle</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-slot:navigation-stepper>
      <v-row>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            value="100"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 1 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </template>
    <template v-slot:navigation-append="{ reload }">
      <v-btn
        v-show="step == 1"
        large
        class="mb-2"
        color="primary"
        @click="
          validateForm(2);
          reload();
        "
        block
        ><b style="font-size: 17px">Siguiente</b>
      </v-btn>
      <v-row>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            @click="
              nextStep(1);
              reload();
            "
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            color="success"
            :loading="loading"
            @click="validateForm()"
            block
            ><b style="font-size: 17px">Registrar</b></v-btn
          >
        </v-col>
      </v-row>
    </template>
  </layout-remito-registrar>
</template>
  <script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;

import LayoutRemitoRegistrar from "@/layouts/productos/layout.registrar.producto";

import { ValidationObserver } from "vee-validate";

import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "@/components/inputs/VTextAreaWithValidation";
import PromocionVistaPrevia from "./promocion.vista-previa.vue";

export default {
  name: "promocion.registrar",
  inject: {
    $validator: "$validator",
  },
  components: {
    LayoutRemitoRegistrar,
    LayoutBasic,
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
    PromocionVistaPrevia,
  },
  data() {
    return {
      menuFechaInicio: false,
      menuFechaFin: false,
      promociones: [],
      promocion: {
        nombre: "",
        descripcion: "",
        detalles: [],
        controlaStock: false,
        precioVenta: null,
        fechaInicioVigencia: null,
        fechaFinVigencia: null,
      },
      nombre: null,
      descripcionSugerida: "",
      inputSearch: null,
      eventClick: false,
      productos: [],
      productosSeleccionados: null,
      loading: false,
      step: 1,
      isLoading: false,
      dialogAddProducto: false,
    };
  },
  computed: {
    items() {
      return [
        {
          text: "Nueva promocion",
          icon: "mdi-clock",
          route: "nuevoPedidoVenta",
        },
        { text: "Consultar", icon: "mdi-account", route: "#" },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ];
    },
  },
  methods: {
    validateForm(nextStep) {
      console.log("validategirna");
      console.log(nextStep);
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          if (nextStep) {
            this.nextStep(nextStep);
          } else {
            console.log("registrarPromocion");
            this.registrarPromocion();
          }
        } else {
          this.$validator.validate();
        }
      });
    },
    nextStep(step) {
      this.step = step;
    },
    async registrarPromocion() {
      try {
        if (
          this.promocion.controlaStock &&
          this.promocion.detalles.length == 0
        ) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: "Debe asignar algún producto al inventario. De lo contrario, desactive el control.",
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        } else {
          const response = await this.axios.post(
            "/promociones/",
            this.promocion
          );

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Promocion registrada",
            icon: "mdi-bug",
            timeout: 2500,
          });
          this.goToRoute("promociones");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: error.response.data,
            icon: "mdi-information",
            timeout: 4000,
            right: false,
            top: false,
          });
        }
      }
    },
    sugerirDescripcion() {
      this.descripcionSugerida = this.descripcionPromoSugerida();
    },
    descripcionPromoSugerida() {
      if (this.promocion.detalles <= 0) {
        return "";
      }
      var descri = "";
      var detalles = this.promocion.detalles;

      for (let i = 0; i < detalles.length; i++) {
        var producto = detalles[i].producto;
        var cantidad = detalles[i].cantidad;

        if (i == 0) {
          descri += cantidad + " " + producto.descripcion + " ";
        } else {
          descri += "+ " + cantidad + " " + producto.descripcion;
        }
      }

      return descri;
    },
    incremetnCant(detalle) {
      detalle.cantidad++;
      this.sugerirDescripcion();
    },
    decrementCant(detalle) {
      var cantidad = parseInt(detalle.cantidad);
      if (cantidad == 1) {
        return;
      }
      detalle.cantidad--;
      this.sugerirDescripcion();
    },
    deleteItem(detalle) {
      const index = this.promocion.detalles.indexOf(detalle);
      if (index > -1) {
        this.promocion.detalles.splice(index, 1);
      }
      this.sugerirDescripcion();
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async getFromSearch(input_search) {
      try {
        this.eventClick = true;
        this.isLoading = true;

        var replaced = input_search.split(" ").join("-");
        var url = `/productos/products_search/search?q=${replaced}&page=1&page_size=10`;
        const request = await this.axios.get(url);

        this.productos = request.data.results;
        this.isLoading = false;
      } catch (error) {
        var e = error.response;
        this.isLoading = false;

        // this.mensajeBuscando = "No se encontró coincidencia";
      }
      setTimeout(() => {
        this.eventClick = false;
      }, 1000);
    },
  },
  watch: {
    productosSeleccionados(select) {
      if (select === undefined) return;

      var copy = { ...select };
      var detalle = { producto: copy, cantidad: 1 };
      this.promocion.detalles.push(detalle);
      this.sugerirDescripcion();
    },
    inputSearch(val) {
      //val -> lo que escribe en el buscador
      if (
        val == null ||
        val == "" ||
        val == " " ||
        val == undefined ||
        val.length < 2
      ) {
        return;
      }
      const regex = /\S|$/;

      var cantEspacios = val.search(regex) || 0;
      if (cantEspacios > 0) {
        var cadena2 = val.slice(cantEspacios);
        this.inputSearch = cadena2;
        return;
      }

      // this.noDataSearch = val;
      // this.mensajeBuscando = "Buscando...";

      // Items have already been requested
      if (this.isLoading) return;
      if (this.borrando) return;

      if (this.eventClick) return;

      this.getFromSearch(val);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad

      // +
      if (event.keyCode === 107 && this.promocion.controlaStock) {
        this.dialogAddProducto = true;
      }
    });
  },
};
</script>

  <style lang="scss" scoped></style>
</template>
