<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>Lista de productos</v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="9">
          <productos-filtros-tag
            :removeTagCategoria="true"
            @close-chip="close"
          ></productos-filtros-tag>
        </v-col>
        <v-col cols="3">
          <div class="text-end">
            <v-btn-toggle class="mr-3" v-model="toggle_one" mandatory>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <v-icon>mdi-view-module</v-icon>
                  </v-btn>
                </template>
                <span>Vista con imágenes </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <v-icon>mdi-format-list-checks</v-icon>
                  </v-btn>
                </template>
                <span>Vista de lista</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>

      <!-- Filtros seleccionados -->

      <div v-if="toggle_one == 0">
        <producto-listado-home
          :products="productos_admin"
          @actualizar-producto="actualizarProductos"
        >
        </producto-listado-home>
      </div>
      <productos-tabla
        ref="productosTabla"
        :productos="productos_admin"
        v-if="toggle_one == 1"
        :verBuscador="false"
      >
        <template v-slot:search>
          <div class="title">{{ input_search }}</div>
        </template>
        <template v-slot="{ selected }">
          <v-scale-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="headline">
                  <v-btn
                    @click="processEliminar(selected)"
                    v-if="selected.length > 0 && selected.length < 11"
                    key="delete"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Eliminar producto</span>
            </v-tooltip>
          </v-scale-transition>
          <v-divider vertical></v-divider>
          <!-- <v-scale-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="headline">
                  <v-btn v-if="selected.length == 1" key="export" icon>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Información del producto</span>
            </v-tooltip>
          </v-scale-transition> -->
          <v-scale-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="headline">
                  <v-btn
                    @click="actualizarProductos(selected)"
                    v-if="selected.length == 1"
                    key="export"
                    icon
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Editar producto</span>
            </v-tooltip>
          </v-scale-transition>
          <!-- <v-scale-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="headline">
                  <v-btn v-if="selected.length" key="cash" icon>
                    <v-icon>mdi-cash</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Actualizar precio de costo</span>
            </v-tooltip>
          </v-scale-transition> -->
        </template>
      </productos-tabla>
      <layout-dialog-delete
        @close-dialog="dialogDelete = false"
        @processDelete="eliminarProducto"
        v-if="dialogDelete"
      >
        <template v-slot:title>Eliminar producto</template>
        <template v-slot:message>
          <div v-if="productoParaEliminar.length > 1">
            ¿Desea eliminar los productos
            <li v-for="p in productoParaEliminar" :key="p.id">
              <i>{{ p.descripcion }}</i> ' ?
            </li>
          </div>
          <div v-else>
            ¿Desea eliminar el producto '
            <i>{{ productoParaEliminar[0].descripcion }}</i> ' ?
          </div>
        </template>
      </layout-dialog-delete>
    </v-col>
  </v-row>
</template>
  
  <script>
import ProductosTabla from "@/components/producto/listar/productos.list.table";
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import ProductoListadoHome from "@/components/producto/listar/productos.list.card";
import ProductosFiltrosTag from "@/components/producto/filtros/productos.filtros.tag.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "producto.listado",
  components: {
    ProductosTabla,
    LayoutDialogDelete,
    ProductoListadoHome,
    ProductosFiltrosTag,
  },
  data() {
    return {
      verTabla: false,
      toggle_one: 0,
      input_search: null,
      isLoading: false,
      searchActive: false,

      menu: false,
      selected: [],

      dialogDelete: false,
      productoParaEliminar: [],
      blockForKeyRepeat: false,
      fab: false,
    };
  },
  computed: {
    ...mapGetters("productos", ["filters_admin"]),

    ...mapState("productos", ["paginacion_productos_admin", "productos_admin"]),
  },

  methods: {
    ...mapActions("productos", ["get_productos_for_params"]),
    ...mapMutations("productos", ["set_page_productos_admin"]),

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },

    ...mapMutations("productos", ["quitFilter", "quit_producto_admin"]),
    ...mapActions("productos", [
      "getProductosFromSearch",
      "eliminar_producto_buscador_admin",
    ]),

    actualizarProductos(selected) {
      if (selected.length > 1) {
        this.$router.push({
          name: "actualizarProductoMultiple",
          params: { productos: selected },
        });
      } else {
        this.$router.push({
          name: "productoHome",
          params: { id: selected[0].id, producto: selected[0] },
        });
      }
      // }
    },
    irActualizarPrecioCosto(selected) {
      if (selected.length > 0) {
        // this.set_producto_selected(selected);

        if (selected.length == 1) {
          this.$router.push({
            name: "ActualizarPrecioCosto",
            params: { id: selected[0].id },
          });
        } else {
          this.$router.push({
            name: "ActualizarPrecioCostoMultiple",
            params: { productos: selected },
          });
        }
      }
    },
    processEliminar(producto) {
      this.dialogDelete = true;
      this.productoParaEliminar = producto;
    },
    eliminarProducto() {
      if (this.productoParaEliminar.length == 1) {
        this.eliminar_producto_buscador_admin(
          this.productoParaEliminar[0].id
        ).then((response) => {
          this.dialogDelete = false;
          this.selected = [];
          this.$refs.productosTabla.cleanSelected();

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Se eliminó el producto '${this.productoParaEliminar[0].descripcion}'`,
            icon: "mdi-information",

            timeout: 2500,
          });
        });
      } else {
        var data = [];
        for (let i = 0; i < this.productoParaEliminar.length; i++) {
          data.push(this.productoParaEliminar[i].id);
        }

        var url = `/productos/delete/multiple`;
        this.axios.post(url, data).then((response) => {
          for (let i = 0; i < this.productoParaEliminar.length; i++) {
            this.quit_producto_admin(this.productoParaEliminar[i].id);
          }
          this.dialogDelete = false;
          this.selected = [];
          this.$refs.productosTabla.cleanSelected();
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Productos eliminados`,
            icon: "mdi-information",
            timeout: 2500,
          });
        });
      }
    },

    async fetchProductos(input_search) {
      try {
        this.isLoading = true;
        this.getProductosFromSearch(input_search);

        this.isLoading = false;
      } catch (error) {
        alert(error);
      }
    },

    close(chip) {
      this.quitFilter(chip);
    },
  },
  watch: {
    input_search(val) {
      //val -> lo que escribe en el buscador
      if (
        val == null ||
        val == " " ||
        val == undefined ||
        this.blockForKeyRepeat
      ) {
        return;
      } else {
        if (this.isLoading) return;
        //Seteo el paginador en 1, porque si la pagina es distinta a 1 al buscar, tira un 500
        this.set_page_productos_admin(1);

        this.fetchProductos(val);
      }
    },
    //Realiza la peticion cuando se dejo de presionar constantemente una tecla
    blockForKeyRepeat(val) {
      if (val == false) {
        this.fetchProductos(this.input_search);
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", (event) => {
      /*repeat se setea en true cuando tiene presionada la tecla constantemente,
         levanta la bandera para que no realice peticiones la server */
      var repeat = event.repeat;
      if (repeat) {
        this.blockForKeyRepeat = true;
      } else {
        this.blockForKeyRepeat = false;
      }
    });
    window.addEventListener("keyup", (event) => {
      this.blockForKeyRepeat = false;
    });
  },
};
</script>
  
  <style lang="scss" scoped></style>
  