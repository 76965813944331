<template>
  <v-card>
    <v-container>
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="data.nombre"
          label="Nombre"
          autofocus
          data-vv-name="nombre"
          v-validate="'required|max:12'"
          :error-messages="errors.collect('nombre')"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-btn text color="grey" @click="$emit('process-cancel')">Cancelar</v-btn>
          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{textButton}}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "CajaForm",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    textButton: {
        type: String,
        required: false, 
        default: 'Guardar'
    },
    title: {
        type: String,
        required: false, 
        default: 'Agregar caja'
    },
    data: {
        type: Object,
        required: true, 
    },
    loading: {
        type: Boolean,
        required: true, 
    }

  },
  methods:{
      validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
         
          this.$emit('process-save');
        } else {
          this.$validator.validate();
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>