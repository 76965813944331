<template>
  <div>
    <v-list-item
      @click="
        $router.push({
          name: 'empleadoCtaCte',
          params: { id_empleado: empleado.id, id_cliente: empleado.cliente },
        })
      "
    >
      <v-list-item-avatar>
        <v-img contain src="@/assets/man-woman.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ empleado.nombre }} {{ empleado.apellido }}</v-list-item-title
        >
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text
          Saldo
        ></v-list-item-action-text>
        {{empleado.cta_cte | convertPesoArg}}
      </v-list-item-action>
    </v-list-item>
   
  </div>
</template>

<script>
export default {
  name: "empleado.consumo.item",
  props: {
    empleado: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>