<template>
  <v-card outlined>
    <div class="d-flex align-center justify-center" style="height: 100%">
      <v-row>
       
        <v-col cols="12">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-title>
                <span class="text-wrap text-left" style="font-size: 30px"
                    ><strong>{{
                      producto.producto.descripcion
                    }}</strong></span
                  >
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="font-size: 40px">{{ producto.precioVenta | convertPesoArg }}</span>
                </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
   
       
          <v-simple-table class="mt-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>$ Costo</th>
                  <th>% Gan</th>
                  <th>IVA</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ producto.precioCosto }}</td>
                  <td>{{ producto.ganancia }}</td>
                  <td>{{ producto.iva }}</td>

                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "producto.vista.previa",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    producto: {
      type: Object,
      required: true,
    },
   
  },
  data() {
    return {};
  },
  computed: {
   
  },
};
</script>

<style lang="scss" scoped></style>
