<template>
  <div>
    <!-- Recargo  -->
    <v-list-item v-show="totalCredito > 0 && !recargo">
      <v-btn @click="dialogRecargo = true" rounded small block dark>
        <div>Agregar <strong class="orange--text">Recargo</strong></div>
      </v-btn>
    </v-list-item>
    <v-dialog persistent width="400" v-model="dialogRecargo">
      <v-card>
        <v-container>
          <v-card-title
            >Recargo <v-spacer></v-spacer>
            <v-btn icon @click="resetRecargo"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-card-title
          >
          <v-card color="#303030" flat class="mx-2 pb-2">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    hide-details=""
                    autofocus
                    flat
                    v-validate="'decimal'"
                    :error-messages="errors.collect('recargo')"
                    data-vv-name="recargo"
                    v-model="recargo"
                    type="number"
                    min="0"
                    max="100"
                    prepend-icon="mdi-percent"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-list-item dense class="justify-end">
            <v-list-item-content>
              <v-list-item-title>Total del recargo</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon
              ><h3>{{ recargoDelTotal() | convertPesoArg }}</h3>
            </v-list-item-icon>
          </v-list-item>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetRecargo()"> Cancelar </v-btn>
          <v-btn color="green darken-1" :disabled="!recargo" @click="agregarRecargo">
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list-item dense v-show="totalCredito > 0 && recargo">
      <v-list-item-content>
        <v-list-item-title>Total del recargo</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon
        ><h3>{{ get_total_recargo_credito | convertPesoArg }}</h3>
        <v-icon color="red" class="ml-2" @click="resetRecargo"
          >mdi-trash-can-outline</v-icon
        >
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "venta.cobrar.descuento",
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },

  data() {
    return {
      dialogRecargo: false,
      motivoDescuento: "",
    };
  },
  computed: {
    ...mapState("remitos", ["esPedidoYa", "porcentajeRecargoCredito"]),
    ...mapGetters("ventas", ["totalCredito", "get_total_recargo_credito"]),
    recargo: {
      get() {
        return this.$store.state.ventas.porcentajeRecargoCredito;
      },
      set(value) {
        this.SET_PORCENTAJE_RECARGO_CREDITO(value);
      },
    },
    tarjeta_credito() {
      return this.$store.getters["ventas/get_forma_de_pago_seleccionada"](
        "TARJETA DE CRÉDITO"
      );
    },
  },
  methods: {
    ...mapMutations("ventas", ["SET_PORCENTAJE_RECARGO_CREDITO"]),
    ...mapActions("ventas", [
      "aplicar_recargo_credito",
      "quitar_recargo_credito",
      "reset_recargo_descuento_credito",
    ]),

    agregarRecargo() {
      this.aplicar_recargo_credito();
      this.dialogRecargo = false;
    },
    resetRecargo() {
      this.dialogRecargo = false;
      this.recargo = null;
      this.quitar_recargo_credito();
    },
    recargoDelTotal() {
      return Math.round(this.totalCredito * (this.recargo / 100));
    },
  },
};
</script>

<style lang="scss" scoped></style>
