<template>
  <v-card>
    <v-container>
      <v-card-title class="justify-center"
        >Pedido {{ pedido.nroPedido }} <v-spacer></v-spacer>
        <v-btn large color="grey" icon @click="$emit('close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-alpha-p-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ pedido.getEstadosActual }}</b> ·
                  {{ pedido.proveedor.nombre }}</v-list-item-title
                >
                <v-list-item-subtitle class="text--primary">{{
                  pedido.tipoEntrega == 0
                    ? "Entrega en el local"
                    : "Para retirar"
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{
                  pedido.fechaAlta
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn block @click="verPedido" color="indigo accent-4"
            >Ver pedido
            <v-icon class="ml-3">mdi-book-open-outline</v-icon></v-btn
          >
          <v-row justify="center" align="center">
            <v-col cols="4" v-if="pedido.getEstadosActual == 'APROBADO'">
              <v-btn
                @click="
                  $router.push({
                    name: 'nuevoRemitoCompra',
                    params: { pedido: pedido },
                  })
                "
                class="ml-7"
                :loading="loadingButton"
                x-large
                icon
              >
                <v-icon> mdi-send-outline </v-icon>
              </v-btn>
              <div class="text-center" id="buttonAccion">
                <span> Generar remito </span>
              </div>
            </v-col>
            <v-col
              cols="4"
              v-for="e in pedido.getEstadosSiguientesDescripcion"
              :key="e.nombre"
               v-show="e.nombre !== 'FINALIZADO'"
            >
              <v-btn
               
                @click="cambiarEstado(e.nombre)"
                :loading="loadingButton"
                class="ml-7"
                x-large
                icon
              >
                <v-icon v-if="e.nombre == 'APROBADO'" dark>
                  mdi-check-bold
                </v-icon>
                <v-icon v-if="e.nombre == 'PENDIENTE'" dark>
                  mdi-keyboard-return
                </v-icon>

                <v-icon v-if="e.nombre == 'FINALIZADO'" dark>
                  mdi-send-outline
                </v-icon>
                <v-icon v-if="e.nombre == 'CANCELADO'" dark>
                  mdi-cancel
                </v-icon>
              </v-btn>

              <div class="text-center" id="buttonAccion">
                <span v-if="e.nombre == 'PENDIENTE'">
                  Volver a {{ e.nombre }}</span
                >
                <span v-else>
                  {{ e.accion }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-row class="mt-2">
            <v-col>
 <div v-if="pedido.getEstadosActual == 'APROBADO'">

          <v-btn text @click="descargarPedido">  <v-icon color="red">mdi-file-pdf-box</v-icon> Descargar pedido</v-btn>
          </div>
          <div v-else>
            <v-alert type="info" dense>Para descargar el pedido, debe estar APROBADO</v-alert>
          </div>
            </v-col>
          </v-row>
         
        </v-col>
      </v-row>
    </v-container>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </v-card>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import { descargarPedidoPDF } from "@/services/reportes";

import { mapActions } from "vuex";

export default {
  name: "pedido.compra.vista-previa",
  components: {
    DialogConfirmationClose,
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogPedidoCancelar: false,
      loadingButton: false,
    };
  },
  computed: {
    pedidosSiguientes() {
      var pedidos = [];
      this.pedido.getEstadosSiguientesDescripcion.forEach((element) => {
        if (element.accion) {
          pedidos.push(element);
        }
      });
      return pedidos;
    },
  },
  methods: {
    ...mapActions("pedidos", ["actualizar_estado_pedido"]),
    descargarPedido(){
      descargarPedidoPDF(this.pedido)
    },
    verPedido() {
      this.$emit("process-ver-pedido", this.pedido);
    },

    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.pedido.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("process-quitar-pedido", this.pedido);
        })
        .catch((e) => {});
    },

    cambiarEstado(estado) {
      if (estado == "CANCELADO") {
        this.dialogPedidoCancelar = true;
        return;
      }
      if (estado == "FINALIZADO") {
        var pedido = this.pedido;
        this.$router
          .push({
            name: "nuevoRemitoCompra",
            params: { pedido: pedido.id },
          })
          .catch(() => {});

        return;
      }
      this.loadingButton = true;
      let id_pedido = this.pedido.id;
      this.actualizar_estado_pedido({ id_pedido, estado })

        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido ${estado}`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButton = false;

          this.$emit("close-dialog");
          this.$emit("process-refresh", estado);
          this.$emit("process-refresh", this.pedido.getEstadosActual);
        })
        .catch((e) => {
          this.loadingButton = false;
        });
    },

    fetchPedido(id) {
      var url = `/pedidos/${id}`;

      this.axios.get(url).then((response) => {
        this.initCantidadDetalles = response.data.detalles_pedido.length;
        this.pedido = response.data;
        this.detalles_pedido = response.data.detalles_pedido;
        this.estado = response.data.getEstadosActual;
        this.tipoEntrega = response.data.tipoEntrega;
        this.fechaEntregaEstimada = response.data.fechaEntregaEstimada;

        this.estadosSiguientes = response.data.getEstadosSiguientesDescripcion;
      });
    },
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
