<template>
  <div>
    <!-- <SucursalesList @handle-click="handleSelectSucursal" /> -->
    <SucursalesVSelect @handle-click="handleSelectSucursal" />

  </div>
</template>

<script>
import SucursalesList from "./SucursalesList.vue";
import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import { mapMutations } from 'vuex';
import SucursalesVSelect from './SucursalesVSelect.vue';
export default {
  name: "SucursalSeleccion",
  components: { SucursalesList, SucursalesVSelect },
  methods: {
    ...mapMutations("sucursales", ['SET_SUCURSAL']),
    handleSelectSucursal(sucursal) {
        this.SET_SUCURSAL(sucursal)
      // saveStorage("sucursal", sucursal);
      this.$emit('handle-selection')

    },
  },
};
</script>

<style scoped>
</style>