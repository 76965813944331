<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h4">General</div>
        <div class="text-h6 mt-4">Datos de la sucursal</div>
        <v-list rounded class="mt-2" v-if="sucursal">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Nombre</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="sucursal.nombre"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Dirección</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="sucursal.direccion"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Teléfono</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="sucursal.telefono"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Condición IVA</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                v-text="sucursal.condicionIva"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import { mapActions, mapState } from "vuex";

export default {
  name: "Ventas",
  components: {
    LayoutBasic,
  },
  data() {
    return {
      navigation: [
        {
          text: "Alertas",
          disabled: false,
          to: { name: "ventas" },
        },
      ],

      item: 0,
      items: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["sucursal", "configuracion"]),
  },
  methods: {
    ...mapActions("sucursales", ["fetch_sucursal_info"]),

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  created() {
    this.fetch_sucursal_info();
    this.items.push(
      { text: "General", icon: "mdi-clock", route: "ventas" },
      {
        text: "Alertas",
        icon: "mdi-cart-plus",
        route: "configuracionNotificacion",
      }
    );
    if (this.configuracion.ventaConPedido) {
      this.items.push({ text: "Pedidos", icon: "mdi-account", route: "pedidoVenta" });
    }
    this.$store.commit("SET_LINKS", this.items);
  },
};
</script>

<style lang="scss" scoped></style>
