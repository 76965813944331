<template>
  <v-card color="transparent" flat class="items3">
    <v-list class="detalle" dense>
      <v-subheader>Detalles del pedido</v-subheader>
      <v-list-item v-for="(detalle, index) in detalles" :key="index">
        <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="detalle.isProducto || detalle.producto">{{
            detalle.producto.descripcion
          }}</v-list-item-title>
          <v-list-item-title v-else
            >{{ detalle.promocion.nombre }} 
            </v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon class="align-center">
          <div style="font-size: 20px" v-if="detalle.producto">
            {{ Number(detalle.producto.precioVenta ? detalle.producto.precioVenta : detalle.precioUnitario  * detalle.cantidad) | convertPesoArg }}
          </div>
          <div v-else style="font-size: 20px">
            {{
              Number(detalle.promocion.precioVenta * detalle.cantidad) | convertPesoArg
            }}
          </div>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  
  
  </v-card>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "pedido.detalle.list",

  props: {
    detalles: {
      type: Array,
      required: true,
    },
  
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },




};
</script>

<style scoped>
.v-list.detalle {
  overflow-y: auto;
}
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
}
.items3 ::-webkit-scrollbar {
  width: 8px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 6, 6);
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
