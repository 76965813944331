var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('loading',{attrs:{"active":_vm.loadingProductosFrecuentes,"is-full-page":false,"background-color":"transparent","color":"#FA0050"},on:{"update:active":function($event){_vm.loadingProductosFrecuentes=$event}}}),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingProductosFrecuentes),expression:"!loadingProductosFrecuentes"}]},_vm._l((_vm.productos_mas_vendidos),function(producto){return _c('v-list-item-group',{key:producto.id},[_c('v-list-item',{class:['mx-auto', { 'grey darken-1': _vm.esPedidoYa }],attrs:{"disabled":_vm.inventario && producto.controlaStock && producto.cantidadstock <= 0},on:{"click":function($event){return _vm.validate(producto)}}},[(_vm.esPedidoYa)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-img',{attrs:{"src":require("@/assets/pedidosya/pedidoYa.svg"),"max-height":"20","max-width":"20","contain":""}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 text-truncate"},[_vm._v(" "+_vm._s(producto.descripcion)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-h6"},[(
                _vm.inventario &&
                producto.controlaStock &&
                producto.cantidadstock <= 0
              )?_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v("Sin stock")]):_vm._e(),(
                _vm.inventario &&
                producto.controlaStock &&
                producto.cantidadstock > 0
              )?_c('v-chip',{attrs:{"small":"","color":"info"}},[_vm._v(_vm._s(producto.cantidadstock))]):_vm._e()],1)],1),_c('v-list-item-action',[_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(producto.precioVenta))+" ")])],1),_c('v-divider')],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }