<template>
  <div>
    <categoria-select
      @process-categoria="setCategoriaRegister"
      @clearable="removeCategoria"
      :categoria="producto.categoriaArbol"
      :outlined="true"
    ></categoria-select>
  </div>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria.vue";
import CategoriaSelect from "@/components/categorias/consultar/categoria.select.vue";

import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.categoria",
  components: {
    TreeCategoria,
    CategoriaSelect,
  },
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoria: null
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
    ...mapGetters("proveedor", ["proveedores"]),
  },
  methods: {
    removeCategoria(){
      this.producto.categoriaArbol = null
      this.$emit("set_categoria", null);

    },
    setCategoriaRegister(cat) {
      this.producto.categoriaArbol = cat;
      this.$emit("set_categoria", cat);
    },
  },
};
</script>

<style lang="scss" scoped></style>
