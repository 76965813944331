<template>
  <v-card color="#263238" height="100%" @click="$emit('process-promocion', promocion)">
    <v-card-title>
      <v-chip small color="success">
        <v-avatar left>
          <v-icon>mdi-clock</v-icon>
        </v-avatar>

        <span v-if="true"></span>Activa</v-chip
      >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on" class="headline align-end">
            <v-chip
              class="align-end"
              v-if="promocion.fechaFinVigencia"
              x-small
              color="#FBC02D"
            >
              <v-avatar left>
                <v-icon light x-small>mdi-clock</v-icon>
              </v-avatar>
              <span>{{ moment(promocion.fechaFinVigencia).format("ll") }}</span></v-chip
            >
          </span>
        </template>
        <span> Vence el {{ moment(promocion.fechaFinVigencia).format("ll") }}</span>
      </v-tooltip>
    </v-card-title>

    <v-row justify="center">
      <!-- <v-col cols="8">
        <v-img
          class="white--text"
          max-height="125"
          contain
          v-bind:src="imagen.noImage"
        ></v-img>
      </v-col> -->

      <v-col class="text-xs-center" align="center" cols="12">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="headline">
              <p>
                <b
                  >{{ promocion.nombre != "" ? promocion.nombre : "Aquí va el nombre" }}
                </b>
              </p>
            </span>
          </template>
          <span>Nombre</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="title">
              <p>
                <b
                  >{{
                    promocion.descripcion != ""
                      ? promocion.descripcion
                      : "Aquí va la descripción"
                  }}
                </b>
              </p>
            </span>
          </template>
          <span>Descripción</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="text-center display-1" style="color: grey">
              $ {{ promocion.precioVenta }}
            </div>
          </template>

          <span>Precio venta</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
  <!-- <v-card color="red"> 
    <v-container>


      <v-row justify="center">
        <v-col cols="8">
          <v-img
            class="white--text"
            max-height="125"
            contain
            v-bind:src="imagen.noImage"
          ></v-img>
        </v-col>
        <v-col class="text-xs-center" align="center" cols="12">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="headline">
                <p>
                  <b>{{ promocion.nombre }} </b>
                </p>
              </span>
            </template>
            <span>Nombre del producto</span>
          </v-tooltip>

          <span class="d-inline-block text-truncate">{{ promocion.descripcion }}</span>
          <div class="row">
            <div class="col-2 text-truncate">
              Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
              tellus.
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="text-center display-1" style="color: grey">
                $ {{ promocion.precioVenta }}
              </div>
            </template>

            <span>Precio venta</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card> -->
</template>

<script>
export default {
  name: "promocion.vista-previa",
  props: {
    promocion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
    };
  },
  computed: {
    activefechaInicioVigencia() {
      this.moment(this.promocion.fechaInicioVigencia).format("YYYY-MM-dd");
    },
    productos() {
      if (this.promocion.detalles <= 0) {
        return "";
      }
      var descri = "";
      var detalles = this.promocion.detalles;

      for (let i = 0; i < detalles.length; i++) {
        var producto = detalles[i].producto;
        var cantidad = detalles[i].cantidad;

        if (i == 0) {
          descri += cantidad + " " + producto.descripcion + " ";
        } else {
          descri += "+ " + cantidad + " " + producto.descripcion;
        }
      }
      return descri;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
