<template>
  <v-autocomplete
    :items="clientes"
    :search-input.sync="inputSearch"
    :disabled="false"
    outlined
    label="Buscar cliente"
    append-icon="mdi-magnify"
    :item-text="getItemText"
    v-model="clienteLocal"
    :loading="isLoading"
    return-object
    clearable
    autofocus
    @click:clear="removeCliente"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="">
            <v-icon> mdi-account-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Agregar cliente</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-list-item dense>
        <v-list-item-title> No se encontraron resultados. </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="blue-grey"
        class="white--text"
        v-on="on"
      >
        <v-icon left> mdi-account </v-icon>
        <span v-text="item.nombre"></span>
        <span v-text="item.apellido"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title
          >{{ item.nombre }} {{ item.apellido }}</v-list-item-title
        >
        <v-list-item-subtitle v-text="item.documento"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-account</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "cliente.buscador",
  props: {
    cliente: {
      type: [Object, null],
    },
  },
  data() {
    return {
      inputSearch: null,
      isLoading: false,
      clienteLocal: this.cliente,

      clientes: [],
    };
  },
  methods: {
    getItemText(item) {
      return `${item.nombre} ${item.apellido} ${item.documento}`;
    },
    removeCliente() {
      this.$emit("remove-cliente");
    },
    async buscarCliente(input_search) {
      try {
        this.isLoading = true;

        var replaced = input_search.split(" ").join("-");
        var url = `/clientes/search?q=${replaced}&page=1&page_size=10`;
        var request = await this.axios.get(url);
        this.clientes = request.data;
      } catch (error) {
        var e = error.response;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    clienteLocal(cliente) {
      if (cliente) {
        this.$emit("set-cliente", cliente);
      }
      this.clienteLocal = cliente;
    },
    inputSearch(value) {
      if (!value || value.value < 2) {
        this.clientes = [];
        return;
      }

      this.buscarCliente(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
