<template>
  <div>
    <v-row>
      <v-col v-for="producto in vianda" :key="producto.id">
    <v-card  @click="agregarDetalle(producto)"  
          
            color="#303030"
            height="100%"
          >
           
          
          

            <v-row justify="center" align="center">
              <v-col cols="12" class="text-xs-center" align="center">
                <div class="title">{{ producto.descripcion }}</div>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col cols="12" class="text-xs-center" align="center">
                  <div class="title font-weight-light">
                    <b>$ {{ producto.precioVenta }}</b>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
import ProductoPorPromocion from "@//views/promocion/promocion.consultar.vue";
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";
import { mapActions, mapState } from 'vuex';
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";


export default {
  name: "AgregarDetallePorCategoria.vue",
  components: {
    ProductoPorPromocion,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
       ...mapState("productos", ["vianda"])
  },
  methods: {
    ...mapActions("productos", ["get_vianda"]),
    agregarDetalle(producto) {
     
       console.log("VIANDA:",producto);
      var detalle = { cantidad: 1, isProducto: true, precioUnitario: producto.precioVenta, producto: producto };

      var det = { ...detalle };

      this.isBorrador = true;
   if (this.isUpdate) {
        add_detalle_update(this.detalles, det);
      } else {
        addDetalle(this.detalles, det);
      }
      this.$updateBus.$emit("showSnackBar", {
        show: true,
        button: false,
        right: false,
        top: false,
        color: "success",
        text: "Vianda agregada",
        icon: "mdi-check",
        timeout: 1500,
      });
    },
  },
  created() {
    this.get_vianda()
  }
};
</script>

<style lang="scss" scoped></style>
