<template>
  <div>
    <div class="title">
      <b>Ventas {{ pendienteFacturacion ? "pendiente de facturación" : "" }}</b>
    </div>
    <v-row>
      <v-col>
        <VentasVTable
          :ventas="ventas"
          :pendienteFacturacion="pendienteFacturacion"
          @process-open="abrirVistaPrevia"
        ></VentasVTable>
        <VentaVistaPreviaDrawer
          v-if="dialogVistaPrevia"
          :model="dialogVistaPrevia"
          @close-dialog="drawerVistaPrevia = false"
          @set-drawer="setDrawer"
          @refresh:venta="setVentaUpdated"
          :venta="ventaSelected"
        ></VentaVistaPreviaDrawer>
      </v-col>
    </v-row>
    <!-- <v-row >
      <v-col
        cols="12"
        md="4"
        v-for="sucursal in ventas_por_sucursales"
        :key="sucursal.sucursal_id"
      >
        <v-card>
          <v-card-title>
            {{ sucursal.sucursal_nombre }}
          </v-card-title>
          <v-card-text>
            {{ sucursal.comprobantes.length }}
            {{ obtenerTextoComprobantes(sucursal.comprobantes.length) }}

            <v-expand-transition>
              <VentasVTable :ventas="sucursal.comprobantes"></VentasVTable>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-pagination
      v-if="ventas"
      @input="paginationChangeHandler"
      :length="pages"
      :value="paginacion.page"
      circle
    ></v-pagination>
  </div>
</template>

<script>
import VentasApi from "@/api/venta/venta.js";
import VentasVTable from "@/components/venta/listar/VentasVTable.vue";
import VentaVistaPreviaDrawer from "@/components/venta/listar/VentaVistaPreviaDrawer.vue";

import { mapState } from "vuex";

export default {
  name: "ventas.facturacion.list",
  components: { VentasVTable, VentaVistaPreviaDrawer },
  props: {
    sucursal_id: {
      type: Number,
      required: false,
      default: null,
    },
    pendienteFacturacion: {
      required: false,
      default: false,
    },
  },
  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
    ...mapState("auth", ["empresa"]),
  },
  data() {
    return {
      loading: false,
      ventas: [],
      ventaSelected: null,
      dialogVistaPrevia: false,

      paginacion: { page: 1, cantidad: 0, rowsPerPage: 4 },
      ventas_por_sucursales: [],
    };
  },
  methods: {
    setVentaUpdated(ventaActualizada) {
      const index = this.ventas.findIndex(
        (venta) => venta.id === ventaActualizada.id
      );
      if (index !== -1) {
        // Actualizar la venta en el array
        this.$set(this.ventas, index, ventaActualizada);
        // // Actualizar la venta seleccionada, si es la misma que la actualizada
        // if (this.ventaSelected && this.ventaSelected.id === ventaActualizada.id) {
        //   this.ventaSelected = ventaActualizada;
        // }
      }
    },
    setDrawer(value) {
      this.dialogVistaPrevia = value;
    },

    abrirVistaPrevia(venta) {
      this.dialogVistaPrevia = true;
      this.ventaSelected = venta;
    },
    closeVistaPrevia() {
      (this.dialogVistaPrevia = false), (this.ventaSelected = null);
    },

    obtenerTextoComprobantes(cantidad) {
      return cantidad === 1 ? "Comprobante" : "Comprobantes";
    },
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.setPage(pageNumber);
      } else {
        this.setPage(pageNumber);
      }
    },
    setPage(pageNumber) {
      this.paginacion.page = pageNumber;

      this.fetchVentas();
    },
    async fetchVentas() {
      try {
        this.loading = true;

        if (this.pendienteFacturacion) {
          var response = await VentasApi.indexPendienteFacturacion(
            this.paginacion
          );
        } else {
          var response = await VentasApi.index(this.paginacion);
        }

        this.ventas = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  watch: {
    sucursal_id(value) {
      this.fetchVentas();
    },
  },
  created() {
    this.fetchVentas();
  },
};
</script>

<style lang="scss" scoped>
</style>