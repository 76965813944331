<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/compras/pedidos'">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>

    <template v-slot:navigation-title>Consultar pedidos </template>

    <template v-slot:navigation-content>
      <v-container>
        <v-row>
          <v-col>
            <div style="font-size: 14px">
              Ingresa los últimos números sin los ceros de la izquierda
            </div>
            <pedido-buscador
              class="mt-2"
              @resultado-buscar-producto="setPedidosfromBuscador"
              @clean-buscador="fetchPedidos"
              tipoComprobante="compras"
            ></pedido-buscador>
          </v-col>
          <v-col cols="12" lg="12">
            <v-select
              v-model="estado"
              :items="estados"
              required
              label="Estado"
              @change="fetchPedidos"
              @click:clear="fetchPedidos"
              clearable
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu2"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaInicio"
                  title="Fecha"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaInicio"
                :max="moment().format('YYYY-MM-DD')"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaFin"
                  prepend-icon="mdi-calendar"
                  title="Fecha"
                  label="Hasta"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaFin"
                :max="moment().format('YYYY-MM-DD')"
                :min="fechaInicio"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="12">
            <v-btn large color="info" block @click="fetchPedidos"
              >Consultar</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-container v-if="loading">
        <v-row v-for="i in 3" :key="i">
          <v-col cols="12">
            <v-skeleton-loader
              max-height="350"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <pedido-consultar-estado
          :paginacion="paginacion"
          @set-page="setPage_fetchPedido"
          ref="consultarPedidos"
          :estado="estado"
          tipoComprobante="compras"
          :pedidos="pedidos"
        ></pedido-consultar-estado>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import PedidoBuscador from "@/views/pedido/consultar/pedido.buscador.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import PedidoConsultarEstado from "@/views/pedido/consultar/pedido.consultar.estado.vue";
export default {
  name: "pedido.consultar",
  components: {
    PedidoConsultarEstado,
    LayoutBasic,
    PedidoBuscador,
  },

  data() {
    return {
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 8 },
      pedidos: [],
      loading: false,
      estados: ["FINALIZADO", "CANCELADO", "APROBADO", "PENDIENTE", "ENVIADO"],
      estado: null,
      dates: [new Date().toISOString().substr(0, 10)],
      menu2: false,
      menu: false,
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      item: null,
      items: [
        {
          text: "Nuevo pedido",
          icon: "mdi-clock",
          route: "nuevoPedidoCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "pedidosCompraConsultar",
        },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoCompra" },
        },
        {
          text: "Consultar",
          disabled: false,
          to: { name: "pedidosCompraConsultar" },
        },
      ];
    },
  },
  methods: {
    setPedidosfromBuscador(pedidos) {
      console.log("setPedidosfromBuscador", pedidos);
      this.pedidos = pedidos.results;
      this.paginacion.cantidad = 1;
    },
    fetchPedidos() {
      this.loading = true;

      var param_url = "";
      if (this.estado) {
        param_url = param_url.concat("&", "estado", "=", this.estado);
      }
      if (this.fechaInicio && this.fechaFin) {
        param_url = param_url.concat(
          "&",
          "fechaAlta_after",
          "=",
          this.fechaInicio
        );
        param_url = param_url.concat(
          "&",
          "fechaAlta_before",
          "=",
          this.fechaFin
        );
      }
      var url = `/pedidos/compra?page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}${param_url}`;

      this.axios.get(url).then((response) => {
        this.pedidos = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      });
    },

    setPage_fetchPedido(page) {
      this.loading = true;
      this.paginacion.page = page;
      this.fetchPedidos();
    },
  },
  created() {
    this.fetchPedidos();
  },
};
</script>

<style lang="scss" scoped></style>
