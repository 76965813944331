<template>
  <v-dialog persistent v-model="dialogEliminar" width="900">
    <v-card>
      <v-toolbar flat color="" dark>
        <v-toolbar-title>CONFLICTO</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon large color="blue" @click="$emit('close-dialog')">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-card-text>
          <v-alert type="info">
            <span>La categoría {{ categoriaPadre.nombre_padre }} </span> contiene
            productos asignados.</v-alert
          >
          <v-card>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  ><h3>{{ categoriaPadre.nombre_padre }}</h3></v-list-item-title
                >
                <v-list-item-subtitle>Productos asignados</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text>
              <v-row>
                <v-col cols="3" v-for="p in productosFilter" :key="p.id">
                  <v-hover v-slot:default="{ hover }">
                    <v-card hover color="#003049">
                      <v-container>
                        <div
                          v-show="!hover"
                          class="text-truncate"
                          style="max-width: 150px"
                        >
                          {{ p.descripcion }}
                        </div>
                        <div v-show="hover">{{ p.descripcion }}</div>
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="text-center">
            <v-divider></v-divider>
            <v-row justify="center" class="mt-2">
              <div class="headline mt-1">¿Que desea hacer?</div>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="3" lg="3">
                <v-card
                  color="#373737"
                  @click="asignarProductosNuevaCategoria"
                  hover
                  height="100%"
                >
                  <v-container>
                    <div>
                      <v-icon color="blue" large>mdi-atom-variant</v-icon>
                    </div>
                    <h3>Recategorizar productos</h3>
                    <span style="color: #bdbdbd">Los productos pertenecerán a </span>
                    <h3>'{{ categoria.name }}'</h3>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" lg="3">
                <v-card
                  @click="quitarCategoriasCrearNueva"
                  dark
                  color="#373737"
                  hover
                  height="100%"
                >
                  <v-container>
                    <div>
                      <v-icon color="amber lighten-2" large>mdi-basket-unfill</v-icon>
                    </div>
                    <h3>Productos sin categoría</h3>
                    <span style="color: #bdbdbd"
                      >Dejar los productos sin categoría y luego registrar
                    </span>
                    <h3>'{{ categoria.name }}'</h3>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" lg="3">
                <v-card
                  class="d-flex align-content-center justify-center flex-wrap"
                  @click="$emit('close-dialog')"
                  color="#373737"
                  hover
                  height="100%"
                >
                  <v-container>
                    <div>
                      <v-icon color="pink lighten-3" large>mdi-hand-right</v-icon>
                    </div>
                    <h3>No hacer nada</h3>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "About",
  props: {
    categoria: {
      type: Object,
    },
    categoriaPadre: {
      type: Object,
    },
    productos: {
      type: Array,
    },
  },
  data() {
    return {
      dialogEliminar: true,
    };
  },
  computed: {
    productosFilter() {
      if (this.productos.length > 5) {
        return this.productos.slice(0, 5);
      } else {
        return this.productos;
      }
    },
  },
  methods: {
    asignarProductosNuevaCategoria() {
      this.axios
        .post("/categorias/categoriaarbolsegundo", {
          padre: this.categoriaPadre.id,
          accion: 0,
          nombre: [this.categoria.name],
        })
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Productos asignados correctamente",
            icon: "mdi-bell-ring",
            timeout: 3000,
          });
          this.$emit("close-dialog");
          this.$emit("refresh-categorias");
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "error",
              text: "Ya existe la categoría",
              icon: "mdi-bell-ring",
              timeout: 3000,
            });
            this.$emit("close-dialog");
          }
        });
    },
    quitarCategoriasCrearNueva() {
      this.axios
        .post("/categorias/categoriaarbolsegundo", {
          padre: this.categoriaPadre.id,
          accion: 1,
          nombre: [this.categoria.name],
        })
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Se quitó la categoría de los productos, y se creó la nueva cat",
            icon: "mdi-bell-ring",
            timeout: 3000,
          });
          this.$emit("close-dialog");
          this.$emit("refresh-categorias");
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "error",
              text: "Ya existe la categoría",
              icon: "mdi-bell-ring",
              timeout: 3000,
            });
            this.$emit("close-dialog");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.asf {
  color: #373737;
}
</style>
