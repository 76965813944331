<template>
  <div class="tree">
    <ul class="tree-list">
      <tree-item-categoria
        @make-folder="makeFolder"
        v-model="selectedItems"
        :item="categorias"
      ></tree-item-categoria>
    </ul>
  </div>
</template>

<script>
import TreeItemCategoria from "@/views/categorias/consultar/tree-item-categoria";
import { mapMutations } from "vuex";
import CategoriasAPI from "@/api/categorias";
 import EgresoAPI from "@/api/egresos/index.js";


export default {
  name: "TreeCategoria.vue",
  components: {
    TreeItemCategoria,
  },
  props: {
    categorias: {
      required: true 
    }
  },
  data() {
    return {
      selectedItems: null,
    };
  },

  methods: {

    async makeFolder(item) {
      try {
        const response = await EgresoAPI.index(item.name);

        if (response.data.parent.children.length == 0) {
          this.$emit("process-categoria", item);
        } else {
          var children = response.data.parent.children;

          this.$set(item, "children", children);
        }
      } catch (error) {}
    },
  },
 
};
</script>

<style lang="scss" scoped></style>
