<template>
  <v-container>
    <div class="display-1">Historial</div>
    <v-row justify="space-between">
      <v-col cols="12" md="3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-4"
              v-model="computedDateFormattedMomentjs"
              label="Seleccioná el día"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            @change="fetchEgresosBuzon"
            locale="es"
            :max="moment().format('YYYY-MM-DD')"
            v-model="date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" md="2">
        <v-sheet outlined :color="model == 0 ? 'green' : 'grey'" rounded>
          <v-card style="border-radius: 10px" color="#121212" flat outlined elevation="0">
            <v-container>
              <div class="text-tab">En buzón</div>
              <div class="value-tab title">
                {{
                  egresos.buzon.length > 0
                    ? seleccionado.monto_actual
                    : egresos.monto_actual | convertPesoArg
                }}
              </div>
            </v-container>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <template v-if="egresos.buzon.length > 0">
      <v-row justify="center">
        <v-col cols="10">
          <v-list subheader two-line>
            <v-list-item-group mandatory v-model="model">
              <v-subheader inset> Últimos Movimientos</v-subheader>

              <buzon-list :egresos="egresos"></buzon-list>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div>
        <v-alert>No hay registros para el {{ computedDateFormattedMomentjs }}</v-alert>
      </div>
    </template>
  </v-container>
</template>

<script>
import EgresosApi from "@/api/egresos/index";
import BuzonList from "@/components/egresos/buzon/buzon.list.vue";

import { mapActions } from "vuex";
export default {
  name: "buzon.historial",
  components: {
    BuzonList,
  },

  data() {
    return {
      model: 0,
      date: this.moment().format("YYYY-MM-DD"),
      menu2: false,
      items: [],
      retiros: [
        {
          id: 1,
          fecha: "17/5/21",
          debe: null,
          haber: 5000,
          descripcion: "Retiraste 5000",
        },
      ],
      egresos: [],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.moment(this.date).format("ll") : "";
    },

    seleccionado() {
      return this.egresos.buzon[this.model];
    },
  },
  methods: {
    ...mapActions("egresos", ["fetch_egresos_buzon"]),

    async fetchEgresosBuzon() {
      console.log("AGASG");
      const egresos = await EgresosApi.fetchEgresoBuzon(this.date, true);
      this.egresos = egresos.data;
    },
  },

  created() {
    this.fetchEgresosBuzon();
  },
};
</script>

<style lang="scss" scoped></style>
