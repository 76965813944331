import ProveedorAPI from "@/api/proveedores/proveedor.js";


export var fetchProveedor = {
  data() {
    return {
      proveedor: null
    }
  },
  methods: {
    fetchProveedor(id) {
      ProveedorAPI.show(id).then((response) => {
        this.proveedor = response.data;
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (params.proveedor) {
          this.proveedor = {...params.proveedor};
        } else {
          this.fetchProveedor(params.id_proveedor);
        }
      },
      deep: true,
      immediate: true,
    },
  },

}