<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/empleados'" v-if="empleado">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>

    <template v-slot:navigation-title>
      {{ empleado.persona.nombre }} {{ empleado.persona.apellido }}
    </template>

    <template v-slot:navigation-content>
      <!-- <v-img
        :src="empleado.foto || noImage"
        :lazy-src="`https://picsum.photos/10/6?image=${15}`"
        aspect-ratio="1"
        height="150px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img> -->

      <v-divider light></v-divider>
      <v-list>
        <v-list-item @click="$router.push({ name: 'empleadoHome' })">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Datos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'editarEmpleado',
              params: { empleado: empleado },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Editar perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="irCuentaCorrienteEmpleado()">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Cuenta corriente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <router-view></router-view>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import EmpleadoAPI from "@/api/empleados";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "empleado",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
  },
  data() {
    return {
      search: "",

      noImage: require("@/assets/man-woman.jpg"),
      navigation: [
        {
          text: "Empleados",
          disabled: false,
          to: { name: "empleados" },
        },
      ],
    };
  },

  computed: {
    ...mapState("empleado", ["empleado", "loadingEmpleado"]),
  },
  methods: {
    ...mapActions("empleado", ["get_empleado"]),
    ...mapMutations("empleado", ["SET_EMPLEADO"]),
    irCuentaCorrienteEmpleado() {
      if (this.empleado.cliente) {
        this.$router.push({
          name: "empleadoCtaCte",
          params: { id_cliente: this.empleado.cliente },
        });
      } else {
        this.$router.push({
          name: "empleadoSinCliente",
          params: { id_cliente: this.empleado.cliente },
        });
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (params.empleado) {
          this.SET_EMPLEADO(params.empleado);
        } else {
          this.get_empleado(params.id_empleado);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
