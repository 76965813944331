<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/compras'">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>
    <template v-slot:navigation-title>Factura de compras </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="goToRoute(item.route)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <FacturaCompraList
          :facturas="facturas"
          @set-page="setPage"
          :paginacion="paginacion"
          @abrir-vista-previa="abrirVistaPrevia"
        ></FacturaCompraList>
        <v-dialog v-model="dialogVistaPrevia" width="800px">
          <FacturaCompraVistaPrevia
            v-if="factura"
            :factura="factura"
            @refresh="fetchFacturasCompra"
            @close-dialog="dialogVistaPrevia = false"
          ></FacturaCompraVistaPrevia>
        </v-dialog>
      </v-container>

      <router-view></router-view>
    </template>
  </LayoutBasic>
</template>

<script>
import FacturaCompraList from "@/components/compra/factura/FacturaCompraList.vue";
import FacturaCompraVistaPrevia from "@/components/compra/factura/FacturaCompraVistaPrevia.vue";

import LayoutBasic from "@/layouts/LayoutBasic.vue";


import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import { get_remitos_compra } from "@/services/comprobante.js";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "FacturaCompraView",
  components: {
    FacturaCompraList,
    FacturaCompraVistaPrevia,
    LayoutBasic,
  },
  data() {
    return {
      item: null,
      loading: false,
      facturas: [],
      factura: null,
      dialogVistaPrevia: false,
      refresh_remitos: 0,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 },
    };
  },
  computed: {
 
    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Factura",
          disabled: false,
          to: { name: "facturaCompra" },
        },
      ];
    },
    items() {
      return [
        {
          text: "Nueva factura",
          icon: "mdi-clock",
          route: "nuevaFacturaCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "remitosConsultar",
        },
      ];
    },
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    ...mapActions("facturaCompra", ["fetch_factura_compra"]),

    abrirVistaPrevia(factura) {
      this.factura = factura;
      this.dialogVistaPrevia = true;
    },

    async setPage(page) {
      try {
        this.loading = true;
        this.paginacion.page = page;
        var response = await this.fetch_factura_compra(this.paginacion);
        this.facturas = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async fetchFacturasCompra() {
      try {
        this.loading = true;
        var response = await this.fetch_factura_compra(this.paginacion);
        console.log(response);
        this.facturas = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  created() {
    console.log("Created");
    this.fetchFacturasCompra();
  },
};
</script>

<style lang="scss" scoped></style>
