<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card>
        <!-- Información básica de la sucursal -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-h5 white--text">{{ sucursal.nombre }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{
              sucursal.direccion
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!-- Detalles de la sucursal -->
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                ><strong>UUID:</strong> {{ sucursal.uuid }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Teléfono:</strong> {{ sucursal.telefono }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Agrega más detalles según sea necesario -->
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

export default {
  name: "SucursalDetailDatos",
  components: {
    LayoutBasic,
  },
  props: {
    sucursal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
