<template>
  <custom-right-drawer
    @set-draweron="setDrawer"
    @process-open="openVenta"
    :model="model"
    v-if="comprobante"
  >
    <!-- Slot de prepend (parte superior) -->
    <template v-slot:prepend-title>
      <!-- Contenido personalizado para el slot -->
      Venta {{ comprobante.numeroVentaPorArqueo }}
    
      <button-imprimir
        :id="comprobante.id"
        tipoComprobante="NV"
      ></button-imprimir>
    </template>
    <!-- <template v-slot:prepend-button>
        <VentaAnularComprobante></VentaAnularComprobante>
      </template> -->
    <template v-slot:prepend-header>
      <div class="text-center" v-if="isPlataforma">
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/pedidosya/pedidoYa.svg"
            max-height="200"
            max-width="200"
            contain
          ></v-img>
        </div>
      </div>
    </template>

    <template v-slot:prepend-header-alert-text>
      <!-- Contenido personalizado para el slot -->
    </template>

    <!-- Slot predeterminado para el cuerpo del drawer -->
    <template v-slot:content>
      <v-alert
        v-if="estaFacturado"
        dense
        icon="mdi-cloud-check-outline"
        color="success"
        type="success"
        text
      >
        <v-row align="center">
          <v-col class="grow"> Facturado </v-col>
          <v-col class="shrink">
            <v-btn text outlined @click="verFactura">
              Ver factura <v-icon color="">mdi-text-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-else
        type="info"
        text
        color="info"
        dense
        icon="mdi-cloud-question"
      >
        <v-row align="center">
          <v-col class="grow"> Pendiente de Facturación </v-col>
          <v-col class="shrink">
            <VentaFacturarComprobante
              :comprobante="comprobante"
              @success:factura="refreshComprobante"
            ></VentaFacturarComprobante>
          </v-col>
        </v-row>
      </v-alert>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>  {{comprobante.nroComprobante}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="comprobante.formasDePago && comprobante.formasDePago.length > 0"
      >
        <v-list-item-avatar>
          <v-icon v-text="'mdi-cash'"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Forma de pago</v-list-item-title>
          <v-list-item-subtitle
            v-for="fp in comprobante.formasDePago"
            :key="fp.id"
            >{{ fp.nombre }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list dense>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ nombreCliente }}</v-list-item-title>
            <v-list-item-subtitle>Nombre</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="telefonoCliente">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ telefonoCliente }}</v-list-item-title>
            <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div>
        <lista-detalle-comprobante
          :total="comprobante.total"
          :descuento="comprobante.descuento"
          :recargo="comprobante.recargo"
          :motivoDescuento="comprobante.motivoDescuento"
          v-if="comprobante.detalles_comprobante.length > 0"
          :detalles="comprobante.detalles_comprobante"
        ></lista-detalle-comprobante>
        <!-- Contenido personalizado para el cuerpo del drawer -->
      </div>
    </template>

    <!-- Slot de append (parte inferior) -->
    <template v-slot:append-content>
      <ComprobanteDetalleTotal
        :comprobante="comprobante"
        :total="comprobante.total"
        :descuento="comprobante.descuento"
        :recargo="comprobante.recargo"
        :motivoDescuento="comprobante.motivoDescuento"
        v-if="comprobante.detalles_comprobante.length > 0"
        :detalles="comprobante.detalles_comprobante"
        :tipoRedondeo="'decimales'"
      ></ComprobanteDetalleTotal>
      <!-- Contenido personalizado para el slot de append -->
    </template>
  </custom-right-drawer>
</template>

<script>
import CustomRightDrawer from "@/components/util/CustomRightDrawer.vue"; // Ajusta l
import ListaDetalleComprobante from "@/components/comprobante/detalle/comprobante.detalles.info.vue";
import ComprobanteDetalleTotal from "@/components/comprobante/detalle/comprobante.detalles.total.vue";
import ButtonImprimir from "@/components/tareas/buttonImprimir.vue";
import VentaAnularComprobante from "@/components/venta/VentaAnularComprobante.vue";
import VentaFacturarComprobante from "@/components/venta/VentaFacturarComprobante.vue";
import AfipFiscalAPI from "@/api/afipfiscal";
import VentasApi from "@/api/venta/venta";


export default {
  components: {
    CustomRightDrawer,
    ListaDetalleComprobante,
    ComprobanteDetalleTotal,
    ButtonImprimir,
    VentaAnularComprobante,
    VentaFacturarComprobante,
  },
  props: {
    venta: {
      type: Object,
      required: true,
    },
    model: {
      type: Boolean,
      required: true,
    },
    isPlataforma: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pedido: true, // Ajusta esto según tu lógic
      comprobante: null,
    };
  },
  computed: {
    estaFacturado() {
      // Si origen es False, esta facturado
      return !this.comprobante.origen;
    },
    nombreCliente() {
      if (this.comprobante.cliente && this.comprobante.cliente.full_name) {
        return this.comprobante.cliente.full_name;
      } else if (
        this.comprobante.nombreEntidad &&
        this.comprobante.nombreEntidad.nombre
      ) {
        return this.comprobante.nombreEntidad.nombre;
      } else {
        return "CONSUMIDOR FINAL";
      }
    },
    telefonoCliente() {
      if (this.comprobante.cliente && this.comprobante.cliente.telefono) {
        return this.comprobante.cliente.telefono;
      } else {
        return null;
      }
    },
  },
  methods: {
    setDrawer(value) {
      this.$emit("set-drawer", value);
    },
    openVenta() {
      // Acción personalizada cuando se hace clic en el botón
      this.$router.push({
        name: "detalleVenta",
        params: { id: this.venta.id },
      });
    },
     verFactura() {
      if (this.comprobante.es_preimpreso) {
        this.dialogConsultarPreImpreso = true;
      } else {
        AfipFiscalAPI.show(this.comprobante.id)
          .then((response) => {
            window.open(response.data.pdf_url, "_blank");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    async refreshComprobante(id){
      const data = await this.fetchComprobante(id)
      this.$emit('refresh:venta', data)
    },

    async fetchComprobante(id) {
      const response = await VentasApi.show(id)
      this.comprobante = response.data;
      return response.data
    },
  },
  created() {
    console.log(this.venta);
    this.comprobante = this.venta
    this.fetchComprobante(this.venta.id);
  },
};
</script>

<style scoped>
</style>
