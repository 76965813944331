import { render, staticRenderFns } from "./ClientesList.vue?vue&type=template&id=b51b0db6&scoped=true&"
import script from "./ClientesList.vue?vue&type=script&lang=js&"
export * from "./ClientesList.vue?vue&type=script&lang=js&"
import style0 from "./ClientesList.vue?vue&type=style&index=0&id=b51b0db6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b51b0db6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VCol,VDataTable,VRow,VToolbar,VToolbarTitle})
