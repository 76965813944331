<template>
  <layout-pedidos :width="'450px'">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:title>
      <v-list>
        <v-list-item>
             <v-list-item-avatar class="ma-1">
            <v-btn @click="$router.go(-1)" icon>

                  <v-icon large>mdi-arrow-left</v-icon>
                </v-btn>
              </v-list-item-avatar>
          <v-list-item-title>
            <h1 style="font-size: 35px">Cuentas corrientes</h1>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar cliente"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <!-- <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group> -->
        <v-list-item>
        
        </v-list-item>
  
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Cuentas con deudas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <clientes-list-cta-cte :search="search"></clientes-list-cta-cte>
      </v-container>
    </template>
  </layout-pedidos>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import ClientesListCtaCte from "@/components/cliente/ClienteListCtaCte";

export default {
  name: "ClientesCuentaCorriente",
  components: {
    ClientesListCtaCte,
    LayoutBasic,
  },
  data() {
    return {
      search: "",
     
      dialogClienteCreate: false,
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  mounted() {
    //  this.clientees = Cliente.query().with('localidad.provincia').get()
    // this.provincias = Provincia.all()
  },
};
</script>

<style lang="scss" scoped></style>
