<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Actualizar asiento tipo</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-container>


              <AsientoTipoForm :asientoTipo="asientoTipo" ref="asientoTipoForm"></AsientoTipoForm>
            <AsientoTipoDetalleForm
            :detalles="asientoTipo.detalles"
              ref="detalleAsientoForm"
            ></AsientoTipoDetalleForm>
            <v-card-actions>
              <v-row justify="end" class="mt-4">
                <v-btn color="success" @click="updateAsientoTipo">Guardar</v-btn>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AsientoTipoForm from "@/components/contabilidad/asiento/asientotipo/AsientoTipoForm.vue";
import AsientoTipoDetalleForm from "@/components/contabilidad/asiento/asientotipo/AsientoTipoDetalleForm.vue";
import { mapActions } from "vuex";

export default {
  name: "AsientoTipoUpdateView",
  components: {
    AsientoTipoForm,
    AsientoTipoDetalleForm,
  },
  data() {
    return {
      asientoTipo: {}, // Objeto asiento tipo a actualizar
    };
  },

  methods: {
    ...mapActions("contabilidad", ["actualizar_asiento_tipo"]),

    async updateAsientoTipo() {
      const isDetalleAvailable =
        await this.$refs.detalleAsientoForm.validateDetalles();
      const isAsientoTipoValid =
        await this.$refs.asientoTipoForm.validateForm();

      if (!isAsientoTipoValid || !isDetalleAvailable) {
        console.log(isAsientoTipoValid);
        console.log(isDetalleAvailable);

        return;
      }
      try {
        const datosParaEnviar = {
          id: this.asientoTipo.id,
          codigo: this.asientoTipo.codigo,
          descripcion: this.asientoTipo.descripcion,
          comentario: this.asientoTipo.comentario,
          detalles: this.asientoTipo.detalles,
        };
        const response = await this.actualizar_asiento_tipo(datosParaEnviar);
        this.$router.push("/contabilidad/asientotipo");
      } catch (e) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e.response.data.detail}`,
          icon: "mdi-information",
          timeout: 3000,
        });
        console.error("Error al registrar el asiento tipo:", e);
      }
    },
   
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          this.$router.go(-1);
        } else {
          if (params.asientoTipo) {
            this.asientoTipo = params.asientoTipo;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
