<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <RubroCompraListItem
          v-for="rubroCompra in rubrosCompra"
          :key="rubroCompra.id"
          :rubroCompra="rubroCompra"
        >
        </RubroCompraListItem>
      </v-expansion-panels>
    </v-row>
  
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import RubroCompraListItem from '@/components/contabilidad/RubroCompraListItem.vue';

export default {
  components: {
    RubroCompraListItem,

  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  computed: {
    ...mapState("contabilidad", ["rubrosCompra"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetch_rubro_compras"]),
    goToEditar(rubro) {
      this.$router.push({
        name: "rubroCompraUpdate",
        params: { rubroCompra: rubro },
      });
    },
    
    fetchRubroCompras() {
      this.fetch_rubro_compras();
    },
  },
  created() {
    this.fetchRubroCompras();
  },
};
</script>