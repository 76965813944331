<template>
  <div>
   
    <ValidationObserver ref="obs">
      <v-card  slot-scope="{ invalid, validated }">
        <v-container>

        <v-card-text>
          <v-col cols="4">
            <VTextFieldWithValidation
              rules="required|numeric|max:30"
              v-model="cuenta.codigo"
              autofocus
              :counter="50"
              label="Codigo"
              name="Codigo"
              @keyup.enter="validateForm()"
            />
          </v-col>
          <v-col cols="8">
            <VTextFieldWithValidation
              rules="required|max:50"
              v-model="cuenta.nombre"
              :counter="50"
              label="Nombre"
              name="Nombre"
              @keyup.enter="validateForm()"
            />
          </v-col>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="$emit('cerrar-dialog')"
            >Cancelar</v-btn
          >
          <v-btn
            color="green lighten-1"
            @click="validateForm()"
            :disabled="invalid || !validated"
          >
            <span class="black--text">{{ nameButton }}</span>
          </v-btn>
        </v-card-actions>
        </v-container>

      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";

export default {
  name: "ContabilidadForm",
  $_veeValidate: {
    validator: "new",
  },
  components: { ValidationObserver, VTextFieldWithValidation },

  props: {
    cuenta: {
      type: Object,
      required: false,
    },
    nameButton: {
      type: String,
      required: false,
      default: "Registrar",
    },
  },
  data() {
    return {
      color: "#FF00FF",
    };
  },
  methods: {
    async validateForm() {
      const result = await this.$refs.obs.validate();
      if (result) {
        this.$emit("process-data", this.cuenta);
        this.$validator.reset();
      } else {
        this.$validator.validate();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
