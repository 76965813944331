<template>
  <v-container fluid class="pl-0">
    <v-row style="heigth: 100%">
      <v-col cols="4" class="pl-0 pt-0">
        <v-card width="420" height="100%">
          <v-container fluid class="pr-0 ml-1">
            <slot name="breadcrumbs"></slot>

            <div>
              <v-btn
                style="background-color: #4e4f50"
                large
                class="ml-2"
                icon
                @click="goBack"
              >
                <v-icon large>mdi-close</v-icon>
              </v-btn>
              <a @click="$router.push('/home')" class="siteLogo mt-2"></a>
            </div>

            <div class="mt-8 pa-0 ml-2">
              <div class="estilo-subheader"><slot name="up-title">Compras</slot></div>

              <h1>
                <slot name="title">Pedidos</slot>
              </h1>

              <slot name="isBorrador"> </slot>

              <slot name="subtitle"></slot>
            </div>
            <v-divider></v-divider>

            <v-container class="items3" fluid>
              <div class="items3 detalle">
                <div>
                  <slot name="navigation-controller"></slot>
                </div>
              </div>
              <v-divider></v-divider>
              <div>
                <slot name="button-navigation-controller"></slot>
              </div>
            </v-container>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="8">
        <v-row justify="end" class="text-end mr-2">
          <v-col cols="12">
            <slot name="arriba-vista-previa">
              <span v-for="button in navBar" :key="button.id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-1" v-on="on" @click="goToRoute(button.route)" fab
                      ><v-icon>{{ button.icon }}</v-icon></v-btn
                    >
                  </template>

                  <span>{{ button.title }}</span>
                </v-tooltip>
              </span>
            </slot>
          </v-col>
        </v-row>
        <v-row style="height: 90%">
          <v-col cols="12">
            <v-card height="100%">
              <slot name="vista-previa"></slot>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "layoutRegistrarProducto",
  props: {
    widthNavigation: {
      type: String,
      default: "350",
    },
  },

  data() {
    return {
      navBar: [
        {
          id: 1,
          title: "PRODUCTOS",
          icon: "mdi-format-list-checks",
          route: "/productos",
        },
        {
          id: 2,
          title: "VENTAS",
          icon: "mdi-cart-arrow-right",
          route: "/ventas",
        },
        {
          id: 3,
          title: "COMPRAS",
          icon: "mdi-store-outline",
          route: "/compras",
        },
      ],
    };
  },

  methods: {
    goToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).catch((e) => {});
      }
    },
    goBack() {
      var path = "";
      if (this.$route.name === "pedidoVenta" || this.$route.name === "pedidoCompra") {
        path = this.$route.name === "pedidoVenta" ? "ventas" : "compras";
        this.$router.push({ name: path });
      } else if (
        this.$route.name === "nuevoPedidoVenta" ||
        this.$route.name === "nuevoPedidoCompra"
      ) {
        path = this.$route.name === "nuevoPedidoVenta" ? "pedidoVenta" : "pedidoCompra";
        this.$router.push({ name: path });
      } else {
        this.$router.go(-1);
      }
    },
    // goToRoute(route) {
    //   this.$router.push({ name: route }).catch(() => {});
    // },
  },
};
</script>

<style scoped>
.estilo-subheader {
  word-break: break-word;
  color: #b0b3b8;
  font-size: 0.9125rem;
}
.siteLogo {
  position: absolute;
  top: 0;
  left: 50px;
  margin-left: 30px;
  background-image: url("../../assets/bear-face-icon-2.png"), none;
  background-size: 60px;
  /* filter: brightness(1.1);
  mix-blend-mode: multiply; */
  width: 113px;
  height: 80px;
}

.items3 {
  display: block;
  right: 0px;
  height: 100%;
}

.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  background: #869;
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
.items3.detalle {
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
