<template>
  <div>
    <ClienteBuscadorSeleccionar
      :cliente="clienteVenta"
      @set-cliente="setCliente"
      @remove-cliente="removeCliente"
    ></ClienteBuscadorSeleccionar>
    <ClienteDireccionEnvioSelect
      v-if="clienteVenta"
      :direccion="clienteDireccion"
      :esParaEnviar="esParaEnviar"
      :cliente="clienteVenta"
      @set-direccion="setDireccion"
      @set-envio="setEnvio"
    ></ClienteDireccionEnvioSelect>
  </div>
</template>

<script>
import ClienteBuscadorSeleccionar from "@/components/cliente/ClienteBuscadorSeleccionar.vue";
import ClienteDireccionEnvioSelect from "@/components/cliente/ClienteDireccionEnvioSelect.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "VentaClienteDireccionEnvio",
  components: {
    ClienteBuscadorSeleccionar,
    ClienteDireccionEnvioSelect,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("ventas", [
      "clienteVenta",
      "clienteDireccion",
      "esParaEnviar",
    ]),
  },

  methods: {
    ...mapMutations("ventas", [
      "SET_CLIENTE_VENTA",
      "SET_CLIENTE_DIRECCION_VENTA",
      "SET_ES_PARA_ENVIAR",
    ]),
    removeCliente() {
      this.SET_CLIENTE_VENTA(null);
      this.SET_CLIENTE_DIRECCION_VENTA(null);
      this.SET_ES_PARA_ENVIAR(false);
    },
    setCliente(cliente) {
      this.SET_CLIENTE_VENTA(cliente);
    },
    setEnvio(bool) {
      this.SET_ES_PARA_ENVIAR(bool);
    },

    setDireccion(direccion) {
      this.SET_CLIENTE_DIRECCION_VENTA(direccion);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>