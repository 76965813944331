<template>
  <categoria-form
    @cerrar-dialog="$emit('cerrar-dialog')"
    @processCategoria="actualizarCategoria"
    :categoria="categoria"
    :title="'Editar categoría'"
    :nameButton="'Guardar'"
    :withColor="withColor"

  ></categoria-form>
</template>

<script>
import CategoriaForm from "@/components/categorias/categoria.form";

export default {
  name: "categoria.update",
  components: {
    CategoriaForm,
  },
  props: {
    categoria: {
      type: Object,
      required: false,
    },
     withColor: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    actualizarCategoria(categoria) {
      this.$emit("update-category", categoria);
    },
  },
};
</script>

<style lang="scss" scoped></style>
