<template>
  <v-container v-if="proveedor">
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Lista de precio</div>
      </v-col>
    </v-row>
    <template v-if="proveedor.tieneLista">
      <v-row v-if="listaDePrecio">
        <v-col cols="4">
          <v-card>
            <v-card-text>
              <v-list-item @click="dialogHistorial = true">
                <v-list-item-avatar>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Última lista subida </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ moment(listaDePrecio.fecha).format("lll") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <proveedor-lista-de-precio-historial v-if="dialogHistorial"
                @close-dialog="dialogHistorial = false"></proveedor-lista-de-precio-historial>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Nombre del archivo</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ listaDePrecio.nombre }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Subida por </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ listaDePrecio.usuario }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar> </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Productos en la lista </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ listaDePrecio.precios.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar> </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Productos a la venta </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ cantidadProductosDeListaParaVender }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="
                $router.push({
                  name: 'productosListasDePreciosRegistrar',
                  params: { id_proveedor: proveedor.id },
                })
              ">
                <v-list-item-content>
                  <v-list-item-title>
                    Añadir productos para la venta
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-open-in-new</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="dialogUpdate = true">
                <v-list-item-content>
                  <v-list-item-title class="justify-center">
                    <v-btn class="primary">Actualizar lista</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <!-- <ProveedorListaDePrecioProductoList :productos="productosDeLista"></ProveedorListaDePrecioProductoList> -->
          <v-card class="overflow-y-auto" max-height="700px">
            <v-card>
              <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table hide-default-footer disable-pagination v-model="productosSeleccionados" :headers="headers"
                :items="listaDePrecio.precios" :search="search">
                <template v-slot:item="{ item }">
                  <tr :class="item.producto ? 'blue' : ''">
                    <td >
                      {{ item.codigo }}
                      <!-- <v-icon class="px-1" color="black" v-if="isSelected"
                          >mdi-check</v-icon
                        > -->
                    </td>
                    <td class="d-flex align-center">
                      {{ item.descripcion }}
                      <!-- <v-icon class="px-1" color="black" v-if="isSelected"
                          >mdi-check</v-icon
                        > -->
                    </td>
                    <td>{{ item.precioCosto | convertPesoArg }}</td>
                    <td>{{ moment(item.fecha).format("ll") }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!listaDePrecio && !isLoading">
        <v-col>
          <v-list-item @click="dialogNuevaLista = true">
            <v-list-item-content>
              <v-list-item-title class="justify-center">
                <v-btn class="primary">Subir nueva lista</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <proveedor-lista-de-precio-update v-if="dialogUpdate" @refresh-lista-precio="getListaDePrecio"
        @close-dialog="dialogUpdate = false"></proveedor-lista-de-precio-update>
      <proveedor-lista-de-precio-nueva v-if="dialogNuevaLista" @refresh-lista-precio="getListaDePrecio"
        @close-dialog="dialogNuevaLista = false"></proveedor-lista-de-precio-nueva>

      <v-progress-linear v-if="isLoading && !listaDePrecio" color="deep-purple accent-4" indeterminate rounded
        height="6"></v-progress-linear>
    </template>
    <template v-else>
      <v-alert type="info">
        No tiene permitido registrar listas de precios. Consulte con el
        administrador para más información.
      </v-alert>
    </template>

  </v-container>
</template>

<script>
import ListaDePrecioAPI from "@/api/proveedores/listaDePrecio";
import ProductoListaDePrecio from "@/components/producto/registrar/ProductoListaDePrecio";
import ProveedorListaDePrecioProductoList from "@/components/proveedor/ProveedorListaDePrecioProductoList.vue";
import { mapMutations, mapState } from "vuex";
import ProveedorListaDePrecioUpdate from "./listasDePrecios/ProveedorListaDePrecioUpdate.vue";
import ProveedorListaDePrecioNueva from "./listasDePrecios/ProveedorListaDePrecioNueva.vue";

import ProveedorListaDePrecioHistorial from "@/components/proveedor/ProveedorListaDePrecioHistorial";

export default {
  name: "proveedor-lista-de-precio",
  components: {
    ProductoListaDePrecio,
    ProveedorListaDePrecioProductoList,
    ProveedorListaDePrecioUpdate,
    ProveedorListaDePrecioNueva,
    ProveedorListaDePrecioHistorial,
  },
  props: {
    proveedor: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      productosDeLista: [],
      // listaDePrecio: null,
      productosSeleccionados: [],
      search: "",
      headers: [
        { text: "Codigo", value: "codigo" },

        { text: "Descripción", value: "descripcion" },
        { text: "Precio", value: "precioCosto" },
        { text: "Última actualización", value: "fecha" },
      ],
      dialogUpdate: false,
      dialogNuevaLista: false,
      dialogHistorial: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("proveedor", ["listaDePrecio"]),
    cantidadProductosDeListaParaVender() {
      if (this.listaDePrecio) {
        return this.listaDePrecio.precios.filter((p) => p.producto).length;
      }
      return "NOT FOUND";
    },
  },
  methods: {
    ...mapMutations("proveedor", [
      "SET_LISTA_PRECIO",
      "SET_PROVEEDOR_ID_LISTA_PRECIO",
    ]),

    getListaDePrecio() {
      this.isLoading = true;

      ListaDePrecioAPI.show(this.proveedor.id)
        .then((response) => {
          this.SET_LISTA_PRECIO(response.data);
          this.SET_PROVEEDOR_ID_LISTA_PRECIO(response.data.proveedor.id);
          this.isLoading = false;
        })
        .catch((e) => {
          this.SET_LISTA_PRECIO(null);
          this.SET_PROVEEDOR_ID_LISTA_PRECIO(null);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getListaDePrecio();
  },
};
</script>

<style lang="scss" scoped></style>