<template>
  <v-card flat class="info-total transparent">
    <v-list class="align-end" dense>
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>Cantidad artículos</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Base imponible</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h3>{{ Number(comprobante.total_vat) | convertPesoArg }}</h3>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Subtotal</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h3>{{ Number(comprobante.subtotalsiniva) | convertPesoArg }}</h3>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item color="red" v-if="recargoAplicado > 0">
        <v-list-item-content>
          <v-list-item-title>Recargo</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h4>+ {{ recargoAplicado | convertPesoArg }}</h4>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item color="red" v-if="descuentoAplicado > 0">
        <v-list-item-content>
          <v-list-item-title>Descuento</v-list-item-title>
          <v-list-item-subtitle>{{ motivoDescuento }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon
          ><h4>- {{ descuentoAplicado | convertPesoArg }}</h4>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><h2>Total</h2></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h2>{{ Number(total) | convertPesoArg }}</h2>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "InfoTotal",
  props: {
    comprobante: {
      required: true,
      type: Object,
    },
    descuento: {
      required: false,
    },
    recargo: {
      required: false,
    },
    total: {
      required: true,
    },
    motivoDescuento: {
      type: String,
      required: false,
    },
    detalles: {
      required: false,
    },
    tipoRedondeo: {
      type: String,
      required: true,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  computed: {
    descuentoAplicado() {
      return Math.round(this.subtotal() * (parseFloat(this.descuento) / 100));
    },
    recargoAplicado() {
      return Math.round(this.subtotal() * (parseFloat(this.recargo) / 100));
    },
  },
  methods: {
    subtotal() {
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        var precio =
          this.detalles[index].cantidad * this.detalles[index].precioUnitario;
        subtotal += precio;
      }
      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        subtotal = Math.round(subtotal); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        subtotal = parseFloat(subtotal.toFixed(2)); // Redondea a dos decimales
      }
      return subtotal;
    },
  },
};
</script>

<style lang="scss" scoped></style>
