var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"display-1",attrs:{"tag":"h3"}},[_vm._v(" Movimientos de cuenta ")]),_c('v-list-item-subtitle',{staticStyle:{"color":"rgba(255, 255, 255, 0.45)","font-size":"18px"}},[_vm._v(" Conoce el detalle de las operaciones que impactan en tu cuenta corriente. ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.movimientos,"search":_vm.search,"loading":_vm.loading,"dark":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title')],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format("ll"))+" ")]}},{key:"item.verDetalle",fn:function(ref){
var item = ref.item;
return [(!item.recibo)?_c('div',{staticStyle:{"color":"#009ee3"},on:{"click":function($event){return _vm.$router.push({
                name: 'ClienteCuentaCorrienteMovimientosDetail',
                params: { id_comprobante: item.comprobante },
              })}}},[_vm._v(" Ver detalle ")]):_vm._e()]}},{key:"item.monto",fn:function(ref){
              var item = ref.item;
return [(item.debe > 0)?_c('span',[_vm._v("- "+_vm._s(_vm._f("convertPesoArg")(item.debe)))]):_vm._e(),(item.haber > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(item.haber)))]):_vm._e()]}},{key:"item.saldo",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm._f("convertPesoArg")(item.saldo)))])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"info","icon":"mdi-information"}},[_vm._v("No hay movimientos.")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[(_vm.movimientos)?_c('v-pagination',{attrs:{"length":_vm.pages,"value":_vm.paginacion.page,"circle":""},on:{"input":_vm.paginationChangeHandler}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }