<template>
  <v-dialog v-model="dialogCancelarPedido" persistent width="500">
    <v-card color="#242526">
      <v-card-title>
        <b>
          <slot name="titulo"> Name slot 'titulo' </slot>
        </b>

        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" icon>
          <v-icon color="grey" >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mt-2">
          <slot name="message"> Name slot 'message' </slot>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="actionButtonExtra" text>
          <b></b>
          <b> <slot name="buttonExtra"></slot></b>
        </v-btn>
        <v-btn v-if="buttonCancel" color="info" @click="closeDialog" text>
          <b></b>
          <b> <slot name="buttonCancel"> Permanecer en la página </slot></b>
        </v-btn>
        <v-btn @click="actionButtonOk" :loading="loading" color="info">
          <b> <slot name="buttonOK"> Name buttonOK </slot></b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirmation.close",
  props: {
    buttonCancel: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      dialogCancelarPedido: true,
      loading: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    actionButtonOk() {
      this.loading = true;
      this.$emit("process-accept");
    },
    actionButtonExtra() {
      this.$emit("process-button-extra");
    },
  },
};
</script>

<style scoped></style>
