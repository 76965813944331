<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="pedidos"
      class="row-pointer transparent"
      no-data-text="No hay pedidos"
      @click:row="handleClick"
      hide-default-footer
      disable-pagination
    >
      <!-- <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
      </template> -->
      <template v-slot:item.fechaAlta="{ item }">
        <span v-if="esVenta">{{
          moment(item.fechaAlta).startOf("minutes").fromNow()
        }}</span>
        <span v-else>{{ moment(item.fechaAlta).format("lll") }}</span>
      </template>

      <template v-slot:item.estaPagado="{ item }">
        <v-chip small :color="item.estaPagado ? 'green' : 'red'">
          {{ item.estaPagado ? "Pagado" : "No pagado" }}
        </v-chip>
        <!-- <v-btn color="green darken-2" >{{
          item.getEstadosActual
        }}</v-btn> -->
      </template>
      <template v-slot:item.cliente="{ item }">
        <v-chip small v-if="item.cliente">
          <v-icon left>mdi-account</v-icon>
          <span>{{ item.cliente.full_name }}</span>
        </v-chip>
        <span v-if="item.nombreEntidad">{{ item.nombreEntidad.cliente.nombre }}</span>
      </template>
      <template v-slot:item.paraEnviar="{ item }">
        <!-- <v-chip small :color="statusColor(item)" >
          {{ item.getEstadosActual }}
        </v-chip> -->
        <v-icon :small="!item.paraEnviar">
          {{ item.paraEnviar ? "mdi-truck-delivery" : "mdi-home" }}
        </v-icon>
      </template>
    </v-data-table>
    <!-- <v-simple-table @click:row="handleClick" height="300">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Fecha</th>

            <th class="text-center">Nº</th>

            <th class="text-center">Proveedor</th>
            <th class="text-center">Estado</th>

            <th class="text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pedidos" :key="item.id">
            <td>{{ moment(item.fechaUltModificacion).fromNow() }}</td>

            <td>{{ item.id }}</td>

            <td>{{ item.proveedor.nombre }}</td>
            <td>
              <v-btn color="green darken-2" @click="dialogEstado = true">{{
                item.getEstadosActual
              }}</v-btn>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="abrirPedido(item)">mdi-arrow-expand</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->

    <v-dialog v-model="dialogEstado" max-width="500px">
      <estado-select :estados="estados"></estado-select>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EstadoSelect from "@/views/pedido/estadosSelect";

import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";

export default {
  name: "ListaPedidos",
  components: {
    DetallePedidoForm,
    EstadoSelect,
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
    },
    esVenta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogEstado: false,
      estados: ["Cancelar", "Finalizar"],
    };
  },

  computed: {
    estado() {
      return this.pedidos[0].estado;
    },
    headers() {
      if (this.esVenta) {
        return [
          {
            text: "Tiempo",
            value: "fechaAlta",
            sortable: false,
          },
          // { text: "Creacion", value: "fechaAlta", sortable: false },

          { text: "Nº", value: "numero", sortable: false },
          { text: "Cobro", value: "estaPagado", sortable: false },

          { text: "Cliente", value: "cliente", sortable: false },
          {
            text: "Envio",
            value: "paraEnviar",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "Fecha registro",
            value: "fechaAlta",
            sortable: false,
          },
          // { text: "Creacion", value: "fechaAlta", sortable: false },

          { text: "Nº", value: "numero", sortable: false },
          { text: "Proveedor", value: "proveedor.nombre", sortable: false },
          { text: "Estado", value: "getEstadosActual", sortable: false },
        ];
      }
    },
  },
  methods: {
    statusColor(pedido) {
      switch (pedido.getEstadosActual) {
        case "PENDIENTE":
          return "warning";
          break;
        case "APROBADO":
          return "primary";
          break;
        default:
          "grey";
      }
    },
    handleClick(pedido) {
      this.$emit("abrir-vista-previa", pedido);
    },
    async abrirPedido(pedido) {
      this.$router.push({
        name: "ActualizarPedido",
        params: { id: pedido.id, estado: this.estado.nombre },
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
