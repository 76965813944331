<template>
  <v-card flat>
    <v-container>
      <v-card-title>
        Agregar dirección
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close-dialog')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          outlined
          v-model="direccion.calle"
          label="Calle"
          v-validate="'required|max:40|min:2'"
          :error-messages="errors.collect('calle')"
          data-vv-name="calle"
          required
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="direccion.altura"
          label="Altura"
          v-validate="'required|max:4|min:1'"
          :error-messages="errors.collect('altura')"
          data-vv-name="altura"
          required
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="direccion.depto"
              label="Depto"
              v-validate="'max:4|min:1'"
              :error-messages="errors.collect('depto')"
              data-vv-name="depto"
              required
            ></v-text-field
          ></v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="direccion.piso"
              label="Piso"
              v-validate="'max:4|min:1'"
              :error-messages="errors.collect('piso')"
              data-vv-name="piso"
              required
            ></v-text-field
          ></v-col>
        </v-row>
        <v-textarea
          v-model="direccion.observacionEnvio"
          dense
          label="¿Alguna instrucción para la entraga?"
          color="white"
          filled
          counter
          maxlength="150"
          hint="Ej: No funciona el timbre"
          persistent-hint
          rows="2"
          row-height="20"
          required
        ></v-textarea>
        <v-select
        class="mt-1"
          dense
          :items="localidadesConBarrios"
          label="Localidad"
          outlined
          v-model="direccion.localidad"
          item-text="nombre"
          item-value="id"
        >
        </v-select>
        <v-select
          dense
          outlined
          label="Barrio"
          v-model="direccion.barrio"
          :items="barrios"
          item-text="nombre"
          item-value="id"
          @input="$emit('activate-is-borrador-venta')"
        ></v-select>

        <v-text-field
          outlined
          v-model="direccion.nombre"
          label="¿Qué nombre le ponemos?"
          hint="Ej: Casa de Franco"
          persistent-hint
          v-validate="'max:20|min:1'"
          :error-messages="errors.collect('nombre')"
          data-vv-name="nombre"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn text color="grey" @click="$emit('close-dialog')">Cancelar</v-btn>

          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >Guardar</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";

export default {
  name: "FormCliente",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    direccion: {
      type: Object,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,

      barrios: [],

      localidadesConBarrios: [],
      localidadConBarrio: 1,
    };
  },

  methods: {
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-save", this.direccion);
        } else {
          this.$validator.validate();
        }
      });
    },
    fetchBarrios(localidad) {
      this.axios.get(`/soporte/barrios/${localidad}`).then((data) => {
        this.barrios = data.data;
      });
    },
    fetchLocalidades(id_loc) {
      this.axios.get("/soporte/localidadesconbarrios").then((data) => {
        this.localidadesConBarrios = data.data;
      });
    },
  },
  watch: {
    "direccion.localidad"(val) {
      this.fetchBarrios(val);
    },
  },

  created() {
    if (this.isUpdate && this.direccion.localidad) {
      //Si solo tiene la localidad y va a actualizar, obtengo los barrios
      console.log(this.direccion.localidad);
      var direccion = this.direccion.localidad.id ? this.direccion.localidad.id : this.direccion.localidad

     
      this.fetchBarrios(direccion);
    }
    this.fetchLocalidades();
  },
};
</script>

<style lang="scss" scoped></style>
