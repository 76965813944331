<template>
  <div>
    <v-row justify="center">
      <v-col cols="10">
        <v-row>
          <v-col>
            <v-card color="transparent" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-list-item three-line>
                  <v-list-item-icon>
                    <v-icon x-large> mdi-clipboard-list </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-h4 mb-1">
                      Inventario
                    </v-list-item-title>
                    <div class="text-overline mb-4">
                      Valor total del inventario:
                      {{ valor_total_inventario | convertPesoArg }}
                    </div>
                    <div class="text-overline mb-4">
                      Total de existencias: {{ total_existencias }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>
  
        <v-row>
          <v-col>
            <v-slide-group mandatory show-arrows>
              <v-slide-item
                v-for="(tag, index) in tags"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="purple white--text"
                  depressed
                  rounded
                  @change="change(tag)"
                  @click="toggle(tag), change(tag)"
                >
                  {{ tag.nombre }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!loading">
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="productos"
          @click:row="handleClick"
          class="row-pointer transparent"
          hide-default-footer
          :item-class="itemRowSelected"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="getStatus(item).color" dark>
              {{ getStatus(item).nombre }}
            </v-chip>
          </template>
        </v-data-table>
        <v-pagination
          v-if="productos.length"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
      <v-navigation-drawer
        temporary
        app
        v-model="producto"
        v-if="producto"
        right
      >
        <template v-if="producto" v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="https://randomuser.me/api/portraits/women/81.jpg" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                ><div class="text-wrap">
                  {{ producto.descripcion }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle v-if="producto.controlaStock">
                <v-chip
                  :color="getStatus(producto).color"
                  v-text="getStatus(producto).nombre"
                >
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>
        <template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title> Control de stock </v-list-item-title>
              <v-list-item-action>
                <v-switch v-model="producto.controlaStock"></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Cantidad </v-list-item-title>
              <v-list-item-action>
                <v-text-field
                  outlined
                  hide-details
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxLength="6"
                  :disabled="!producto.controlaStock"
                  v-model="producto.cantidadstock"
                ></v-text-field>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Stock mínimo </v-list-item-title>
              <v-list-item-action>
                <v-text-field
                  outlined
                  hide-details
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxLength="6"
                  :disabled="!producto.controlaStock"
                  v-model="producto.stockmin"
                ></v-text-field>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Stock máximo </v-list-item-title>
              <v-list-item-action>
                <v-text-field
                  outlined
                  hide-details
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxLength="6"
                  :disabled="!producto.controlaStock"
                  v-model="producto.stockmax"
                ></v-text-field>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-list-item-action>
                <v-btn
                  @click="actualizar_inventario(producto)"
                  rounded
                  color="primary"
                  >Guardar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-row>

    <template v-else>
      <v-skeleton-loader
        max-height="600"
        type="table-heading"
      ></v-skeleton-loader>

      <v-skeleton-loader
        max-height="1800px"
        type="table-tbody"
      ></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";

export default {
  name: "producto.sin.inventario",
  data() {
    return {
      paginacion: { page: 1, cantidad: 8, rowsPerPage: 2 },
      productos: [],
      loading: false,

      filtroActivo: {
        nombre: "Control de stock",
        key: "inventario",
        value: true,
      },
      producto: null,
      total_existencias: null,
      valor_total_inventario: null,

      model: null,
      tags: [
        { nombre: "Control de stock", key: "inventario", value: true },
        { nombre: "Sin control de stock", key: "inventario", value: false },
        { nombre: "Stock bajo", key: "stockbajo", value: true },
        { nombre: "Sin stock", key: "sinstock", value: true },
      ],
    };
  },
  computed: {
    ...mapState("productos", ["productos_inventario"]),
    ...mapState("auth", ["user"]),

    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
    isFiltroSinControl() {
      return this.filtroActivo.key == "inventario" && !this.filtroActivo.value;
    },
    headers() {
      var array = [
        {
          text: "Nombre ( descripción )",
          align: "left",
          sortable: false,
          value: "descripcion",
          width: "1%",
        },
               {
          align: "left",
          sortable: false,
          text: "Precio costo",
          value: "precioCosto",
          width: "1%",
        },
        {
          align: "left",
          sortable: false,
          text: "Cantidad",
          value: "cantidadstock",
          width: "1%",
        },
        {
          align: "left",
          sortable: false,
          text: "Estado",
          value: "estado",
          width: "1%",
        },
      ];

      if (this.isFiltroSinControl) {
        array.splice(2, 2);
      }
      return array;
    },
  },

  methods: {
    ...mapActions("productos", [
      "getProductosSinInventario",
      "getProductosInventario",
      "actualizar_inventario",
    ]),
    itemRowSelected(item) {
      if (this.producto) {
        if (this.producto.id == item.id) {
          return "blue lighten-4 black--text";
        }
      }
      return "";
    },
    handleClick(producto) {
       this.$router.push({
              name: 'productoInventarioDetail',
              params: { id_producto: producto.id, producto: producto },
            })
      if (this.user.role.includes("empleado-basico")) {
        // El usuario tiene el rol 'empleado-basico'
        return;
      }
      const copy = { ...producto };
      this.producto = copy;
    },
    getStatus(producto) {
      var status = {
        nombre: "",
        color: "",
      };
      var cantidadstock = parseInt(producto.cantidadstock);
      var stockmin = parseInt(producto.stockmin);
      var stockmax = parseInt(producto.stockmax);

      if (stockmax > 0 && cantidadstock > stockmax) {
        status.color = "purple darken-2";
        status.nombre = "Sobrestock";
      } else if (cantidadstock >= stockmin) {
        status.color = "green";
        status.nombre = "Normal";
      } else if (cantidadstock < stockmin && cantidadstock > 0) {
        status.color = "yellow darken-2";
        status.nombre = "Bajo";
      } else {
        status.color = "red";
        status.nombre = "Sin stock";
      }
      return status;
    },
    getColor(producto) {
      var cantidadstock = parseInt(producto.cantidadstock);
      var stockmin = parseInt(producto.stockmin);
      var stockmax = parseInt(producto.stockmax);

      if (cantidadstock >= stockmin) return "green";
      else if (cantidadstock < stockmin && cantidadstock > 0)
        return "yellow darken-2";
      else return "red";
    },
    actualizar_inventario(p) {
      var producto = { ...p };
      console.log(p);

      ProductosAPI.updateInventario(producto)
        .then((response) => {
          this.remove_item_inventario(response);

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            color: "success",
            text: "Actualizado",
            icon: "mdi-check",
            timeout: 3000,
          });
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            color: "danger",
            text: "No se pudo actualizar el producto",
            icon: "mdi-bell-ring",
            timeout: 3000,
          });
        });
    },
    remove_item_inventario(response) {
      if (this.filtroActivo.key == "inventario") {
        if (this.filtroActivo.value != response.data.controlaStock) {
          this.productos = this.productos.filter(
            (p) => p.id != response.data.id
          );
          this.producto = this.productos[0];
          if (this.productos.length == 0) {
            this.fetchProductosInventario();
          }
        }
      }
    },

    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.paginacion.page = pageNumber;
        this.fetchProductosInventario();
      } else {
        this.paginacion.page = pageNumber;
        this.fetchProductosInventario();
      }
    },
    get_params() {
      var array = [this.filtroActivo];
      var querys = this.$route.query;
      if (Object.keys(querys).length !== 0) {
        for (const property in querys) {
          var object = {
            nombre: null,
            key: "",
          };
          object.key = property;
          object.value = querys[property];
          array.push(object);
        }
      }
      return array;
    },
    change(filtro) {
      this.paginacion.page = 1;
      this.filtroActivo = filtro;
      return this.fetchProductosInventario();
    },
    async fetchProductosInventario() {
      this.loading = true;
      var params = this.get_params();
      try {
        const response = await ProductosAPI.fetchInventario(
          params,
          this.paginacion
        );
        this.productos = response.data.results;
        this.total_existencias = response.data.total_existencias;
        this.valor_total_inventario = response.data.valor_total_inventario;

        this.paginacion.cantidad = response.data.count;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.fetchProductosInventario();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchProductosInventario();
  },
};
</script>

<style  scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>