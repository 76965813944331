<template>
  <div>
    <v-row>
      <v-col>
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Nuevo empleado</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <PersonaRegister @process-persona="registrarEmpleado"></PersonaRegister>
      </v-col>
    </v-row>

    <v-dialog width="800px" v-model="dialogEmpleados">
      <v-card>
        <v-container> </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmpleadoRegister from "@/components/empleado/empleado.register.vue";
import EmpleadoAPI from "@/api/empleados";
import PersonaRegister from "@/views/usuarios/PersonaRegister.vue";

export default {
  name: "SucursalEmpleadosRegistrar",
  components: {
    EmpleadoRegister,
    PersonaRegister,
  },
  props: {
    sucursal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      empleados_sucursal: [],
      empleados_empresa: [],
      dialogEmpleados: false,
    };
  },
  methods: {
    handleClick(empleado) {
      this.$router.push({
        name: "detallesEmpleado",
        params: { id_empleado: empleado.id },
      });
    },
    registrarEmpleado(empleado) {
      empleado.sucursal_uuid = this.sucursal.uuid;
      EmpleadoAPI.create(empleado)
        .then((response) => {
          try {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: "Empleado registrado",
              icon: "mdi-check",
              timeout: 3000,
            });
            this.$router.push("/empleados");
          } catch (error) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "info",
              text: "Problema inesperado",
              icon: "mdi-information",
              timeout: 3000,
            });
          }
        })
        .catch((e) => {
          const response = e.response ? e.response.data : "Problema inesperado";
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: response,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilos personalizados si es necesario */
</style>
