<template>
  <div>
     <v-toolbar flat>
      <v-toolbar-title>Editar cuenta</v-toolbar-title>
    </v-toolbar>


    <v-divider></v-divider>
    <ContabilidadForm
      nameButton="Guardar"
      :cuenta="cuenta"
      @cerrar-dialog="closeDialog"
      @process-data="actualizarCuenta"
      textButton="Guardar"
      title="Editar cuenta"
    ></ContabilidadForm>
  </div>
</template>

<script>
import ContabilidadForm from "@/components/contabilidad/ContabilidadForm.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ContabilidadUpdate",
  components: {
    ContabilidadForm,
  },
  props: {
    cuenta: {
      required: true,
      type: Object,
    },
  },
  methods: {
    ...mapActions("contabilidad", ["actualizar_cuenta"]),
    ...mapMutations("contabilidad", ["UPDATE_CUENTA"]),
    actualizarCuenta(cuenta) {
      this.actualizar_cuenta(cuenta)
        .then((response) => {
          this.UPDATE_CUENTA(response.data);
          this.$emit("close-dialog");
        })
        .catch((e) => {
           this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>