<template>
  <LayoutBasic :showLinksMenu="false">
    <template v-slot:navigation-title>Contabilidad </template>
    <template v-slot:navigation-content> </template>

    <template v-slot:navigation-after-links>
      <v-list nav>
        <v-list-item-group mandatory color="blue lighten-3">
          <v-list-item
            @click="$router.push({ name: 'contabilidadFormasDePago' })"
          >
            <v-list-item-icon>
              <v-icon>mdi-book</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Formas de pago</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group mandatory color="blue lighten-3">
            <v-list-item
              @click="$router.push({ name: 'contabilidadCuentasDefecto' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Cuentas contables por defecto</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              mandatory
              @click="$router.push({ name: 'plancuentas' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Plan de cuentas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'rubroVenta' })">
              <v-list-item-icon>
                <v-icon>mdi-bookmark-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Rubro de venta</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'rubroCompra' })">
              <v-list-item-icon>
                <v-icon>mdi-bookmark-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Rubro de compra</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-bookmark-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Asientos</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item @click="$router.push({ name: 'asientoTipo' })">
                <v-list-item-content>
                  <v-list-item-title>Asientos tipo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="$router.push({ name: 'asientoMantenimiento' })"
              >
                <v-list-item-content>
                  <v-list-item-title
                    >Mantenimiento de asientos</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-group>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-row justify="center">
        <v-col cols="12">
          <router-view
            v-if="planDeCuentas"
            :planDeCuentas="planDeCuentas"
          ></router-view>
        </v-col>
      </v-row>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import RubroVentaList from "@/components/contabilidad/RubroVentaList.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ContabilidadHome",
  components: {
    RubroVentaList,
    LayoutBasic,
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),
    async fetchCuentas() {
      this.fetchPlanDeCuentas();
      // Mostrar un mensaje de error
    },
  },
  created() {
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped></style>
