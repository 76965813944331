<template>
  <div class="items3">
    <v-snackbar
      color="error"
      v-model="error_detalle_repetido.state"
      multi-line
      >{{ error_detalle_repetido.message }}</v-snackbar
    >
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-data-table
      style="background-color: transparent"
      :headers="computedHeaders"
      :items="detalles"
      sort-by="calories"
      no-data-text="No hay productos agregados"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.producto.cantidadstock="{ item }">
        {{ item.producto.controlaStock ? item.producto.cantidadstock : "N/A" }}
      </template>

      <template v-slot:item.cantidad="{ item }">
        <v-edit-dialog
          v-if="edit"
          cancel-text="Cancelar"
          save-text="Guardar"
          :return-value.sync="item.cantidad"
          @close="closeCantidad(item)"
          @cancel="cancel"
          @save="save"
          large
          @open="open('Editar cantidad')"
          persistent
        >
          {{ item.cantidad }} <v-icon class="mb-1 ml-1" small> mdi-pen</v-icon>
          <template v-slot:input>
            <v-text-field
              v-model="item.cantidad"
              step="0.01"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxLength="6"
              min="0"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.cantidad }}</span>
      </template>

      <template v-slot:item.precioUnitario="{ item }">
        <v-edit-dialog
          cancel-text="Cancelar"
          save-text="Guardar"
          v-if="edit"
          persistent
          :return-value.sync="item.precioUnitario"
          large
          @save="save"
          @cancel="cancel"
          @open="open('Editar precio')"
          @close="guardarPrecioCosto(item.precioUnitario)"
        >
          <div>
            {{ item.precioUnitario }}
            <v-icon class="mb-1 ml-1" small> mdi-pen</v-icon>
          </div>
          <template v-slot:input>
            <v-text-field
              v-model="item.precioUnitario"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxLength="6"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <span v-else> {{ item.precioUnitario }}</span>
      </template>
      <template v-slot:item.producto.iva_compra="{ item }">
        <AlicuotaIvaSelect
          :iva="item.producto.iva_compra"
          @update:iva="setIva(item, $event)"
        ></AlicuotaIvaSelect>
      </template>

      <template v-slot:item.subtotal="{ item }">
        {{ calcularSubtotal(item) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <comprobante-detalle-buttons
          :detalle="item"
          @process-increment="incremetnCant(item)"
          @process-decrement="decrementCant(item)"
          @process-delete="eliminarDetalle(item)"
        >
        </comprobante-detalle-buttons>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  quitDetalle,
  updateDetalle,
  addDetalle,
} from "@/services/detallesComprobante/serviceDetalles";

import { mapMutations, mapState } from "vuex";
import ComprobanteDetalleForm from "@/components/comprobante/detalle/comprobante.detalle.form.vue";
import ComprobanteDetalleButtons from "@/components/comprobante/detalle/comprobante.detalle.buttons.vue";
import AlicuotaIvaSelect from "@/components/util/AlicuotaIvaSelect.vue";

export default {
  name: "remito.compra.detalles.registrar.list",

  components: {
    ComprobanteDetalleForm,
    ComprobanteDetalleButtons,
    AlicuotaIvaSelect,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    withIva: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",

      /////////////////////
      dialog_detalle: false,
      detalle: null,
      detalle_update: {},
      height: "100%",
      headers: [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "producto.descripcion",
        },
        { text: "Cantidad", value: "cantidad" },

        { text: "Precio Costo", value: "precioUnitario" },
        {
          text: "IVA",
          value: "producto.iva_compra",
          align: "center",
          width: "15%",
        },

        { text: "Subtotal", value: "subtotal" },

        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales", ["configuracion"]),
    computedHeaders() {
      if (!this.edit) {
        return this.headers.filter((header) => header.text !== "Acciones");
      }
      if (!this.withIva) {
        return this.headers.filter((header) => header.text !== "IVA");
      }

      return this.headers;
    },
  },
  methods: {
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Guardado";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open(text) {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = text;
    },
    calcularSubtotal(item) {
      const precio =
        parseFloat(item.precioUnitario) * parseFloat(item.cantidad);
      const iva = parseFloat(item.producto.iva_compra.description || 0);
      const subtotal = iva > 0 ? precio * (1 + iva / 100) : precio;
      return parseFloat(subtotal.toFixed(2)); // Redondea a 2 decimales
    },
    setIva(detalle, vatType) {
      detalle.producto.iva_compra = vatType;
      // this.$emit('update:iva')
    },

    /////////////////////////
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),
    guardarPrecioCosto(precioUnitario) {
      var precio = parseFloat(precioUnitario);
      console.log("guardarPrecioCosto", precio);
      if (!precio || precio < 0) {
        console.log("!precio || precio < 0");
        item.precioCosto = 1;
      }
    },
    closeCantidad(item) {
      if (!item.cantidad) {
        item.cantidad = 1;
      }

      if (item.cantidad == 0) {
        item.cantidad = 1;
      }
      if (item.cantidad < 0) {
        item.cantidad = item.cantidad * -1;
      }
    },

    incremetnCant(detalle) {
      detalle.cantidad++;
    },
    decrementCant(detalle) {
      var cantidad = parseFloat(detalle.cantidad);
      if (detalle.producto) {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          if (cantidad == 0.5) {
            return;
          }
          detalle.cantidad = detalle.cantidad - 0.5;
          return;
        } else {
          if (cantidad == 1) {
            return;
          }

          detalle.cantidad--;
        }
      } else {
        if (cantidad == 1) {
          return;
        }

        detalle.cantidad--;
      }
    },

    eliminarDetalle(detalle) {
      quitDetalle(this.detalles, detalle);
    },
    modificarDetalle(detalle) {
      this.dialog_detalle = false;
      if (
        this.configuracion.productoInventario &&
        detalle.producto.controlaStock
      ) {
        detalle.producto.cantidadstock -= parseInt(detalle.cantidad);
      }

      updateDetalle(this.detalles, detalle);
    },

    setDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
  },
  watch: {
    dialog_detalle: function (val) {
      if (val == false) (this.detalle = null), (this.detalle_update = {});
    },
  },

  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
      event.preventDefault();

      //escape
      if (event.key === "Escape") {
        this.detalle = null;
      }
      //suprimir
      //Elimina el detalle si esta seleccionado y el dialog para agregar detalle esta desactivado
      if (event.keyCode === 46 && this.dialog_detalle == false) {
        if (this.detalle !== null) {
          event.preventDefault();

          this.eliminarDetalle(this.detalles[this.detalle]);
          this.detalle = null;
        }
      }

      // //Flecha hacia abajo
      // if (event.keyCode === 40 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = 0;
      //   } else if (this.detalle == this.detalles.length - 1) {
      //     this.detalle = 0;
      //   } else {
      //     this.detalle = this.detalle + 1;
      //   }
      // }

      // //Flecha hacia arriba
      // if (event.keyCode === 38 && this.dialog_detalle == false) {
      //   if (this.detalles.length == 0) return;

      //   if (this.detalle == null) {
      //     this.detalle = this.detalles.length - 1;
      //   } else if (this.detalle == 0) {
      //     this.detalle = this.detalles.length - 1;
      //   } else {
      //     this.detalle = this.detalle - 1;
      //   }
      // }

      //ENTER - Abre el modal cuando esta seleccionado algun elemento de la lista (detalle.length > 0)
      if (event.keyCode === 13) {
        if (this.detalles.length > 0) {
          if (this.detalle !== null && this.dialog_detalle == false) {
            this.setDetalle(this.detalles[this.detalle]);
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.v-list.detalle {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
