<template>
  <span>
    <ValidationObserver ref="formCliente">
      <v-card color="transparent" flat slot-scope="{ invalid, validated }">
        <v-container>
          <v-form ref="form" lazy-validation>
            <VTextFieldWithValidation
              dense
              :rules="
                formClient.paraEnviar
                  ? 'required|alpha_spaces|max:40|min:2'
                  : isOptional
                  ? 'alpha_spaces|max:40|min:2'
                  : 'required|alpha_spaces|max:40|min:2'
              "
              min="0"
              autocomplete="off"
              color=""
              name="Nombre del cliente"
              :label="
                formClient.paraEnviar
                  ? 'Nombre del cliente *'
                  : isOptional
                  ? 'Nombre del cliente (opcional)'
                  : 'Nombre del cliente *'
              "
              v-model="formClient.nombre"
              @input="verificarErrores()"
              prepend-icon="mdi-account"
              class="pa-0"
            />
            <!-- <span class="mt-0" style="font-size: 0.75rem">Opcional</span> -->
            <VTextFieldWithValidation
              dense
              autocomplete="off"
              :rules="
                formClient.paraEnviar
                  ? 'required|numeric|max:14|min:7'
                  : isOptional
                  ? 'numeric|max:14|min:7'
                  : 'required|numeric|max:14|min:7'
              "
              min="0"
              name="Teléfono"
              :label="
                formClient.paraEnviar
                  ? 'Teléfono *'
                  : isOptional
                  ? 'Teléfono (opcional)'
                  : 'Teléfono *'
              "
              type="number"
              prepend-icon="mdi-phone"
              v-model="formClient.telefono"
              @input="verificarErrores()"
            />
            <v-checkbox
              hide-details
              dense
              class="ma-0"
              @change="esParaEnviar"
              v-model="formClient.paraEnviar"
              label="Enviar pedido"
            ></v-checkbox>
          </v-form>
        </v-container>
      </v-card>
    </ValidationObserver>
    <v-dialog width="600" persistent v-model="dialogParaEnviar">
      <ValidationObserver ref="formEnvio">
        <v-card slot-scope="{ valid }">
          <v-toolbar color="#3282b8">
            <v-icon large class="mr-2">mdi-truck-delivery</v-icon>
            <v-toolbar-title>Datos de envío</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelarParaEnviar">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <VTextFieldWithValidation
                  autofocus
                  dense
                  rules="required|max:40|min:2"
                  name="Calle"
                  label="* Calle"
                  outlined
                  v-model="formEnvio.calle"
                  @input="$emit('activate-is-borrador-venta')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <VTextFieldWithValidation
                  rules="required|max:4|min:1"
                  min="0"
                  label="* Altura"
                  outlined
                  name="Altura"
                  v-model="formEnvio.altura"
                  @input="$emit('activate-is-borrador-venta')"
                  dense
                />
              </v-col>

              <v-col cols="12" md="3">
                <span class="mt-0" style="font-size: 0.75rem">Opcional</span>
                <VTextFieldWithValidation
                  rules="max:4|min:1"
                  label="Depto"
                  name="Depto"
                  outlined
                  v-model="formEnvio.depto"
                  @input="$emit('activate-is-borrador-venta')"
                  dense
                />
              </v-col>

              <v-col cols="12" md="3">
                <span class="mt-0" style="font-size: 0.75rem">Opcional</span>
                <VTextFieldWithValidation
                  rules="max:2|min:1"
                  label="Piso"
                  outlined
                  name="Piso"
                  v-model="formEnvio.piso"
                  type="number"
                  @input="$emit('activate-is-borrador-venta')"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <span class="mt-0" style="font-size: 0.75rem">Opcional</span>
                <v-select
                  :items="localidadesConBarrios"
                  label="Localidad"
                  outlined
                  v-model="localidadConBarrio"
                  @input="$emit('activate-is-borrador-venta')"
                  dense
                  item-text="nombre"
                  item-value="id"
                />
              </v-col>

              <v-col cols="12" md="6">
                <span class="mt-0" style="font-size: 0.75rem">Opcional</span>
                <v-autocomplete
                  label="Barrio"
                  v-model="formEnvio.barrio"
                  :items="barrios"
                  item-text="nombre"
                  outlined
                  clearable
                  item-value="id"
                  return-object
                  @input="$emit('activate-is-borrador-venta')"
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="12">
                <span class="mt-0" style="font-size: 0.75rem">Opcional</span>
                <v-textarea
                  v-model="formEnvio.observacionEnvio"
                  dense
                  label="Observación"
                  color="white"
                  filled
                  counter
                  maxlength="150"
                  rows="2"
                  row-height="20"
                  required
                  @input="$emit('activate-is-borrador-venta')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn text @click="cancelarParaEnviar">Cancelar</v-btn>
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="validarNombreTelefono()"
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </span>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import { mapState } from "vuex";

export default {
  name: "ClienteManualEnvio",
  components: { ValidationObserver, VTextFieldWithValidation },
  inject: {
    $validator: "$validator",
  },
  props: {
    formClient: {
      type: Object,
      required: false,
    },
    formEnvio: {
      type: Object,
      required: false,
    },
    validCliente: {
      type: Boolean,
      required: false,
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      localidadConBarrio: 1,
      hayErroresCliente: false,
      dialogParaEnviar: false,
      localidadesConBarrios: [],
      barrios: [],
    };
  },

  computed: {
    validar: {
      // getter
      get: function () {
        return this.validCliente;
      },
      // setter
      set: function (newValue) {
        //Le avisa al padre la validacion del nombre del cliente

        this.$emit("process-cliente", newValue);
      },
    },
  },
  methods: {
    validarNombreTelefono() {
      this.dialogParaEnviar = false;
      this.verificarErrores();
    },
    esParaEnviar() {
      this.dialogParaEnviar = this.formClient.paraEnviar;
      if (!this.formClient.paraEnviar) {
        this.validar = true;
      }
      console.log(this.formClient.paraEnviar);
    },
    async verificarErrores() {
      this.$emit("activate-is-borrador-venta");
      const result = await this.$refs.formCliente.validate();
      this.validar = result;
      // call form submission logic
    },
    cancelarParaEnviar() {
      this.dialogParaEnviar = false;
      this.formClient.paraEnviar = false;
    },

    registrarCliente() {
      this.$emit("registrarCliente", {
        formClient: this.formClient,
        formEnvio: this.formEnvio,
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    cargarBarrios() {
      this.barrios = [];
    },

    fetchBarrios(id_loc) {
      this.axios.get(`/soporte/barrios/${id_loc}`).then((data) => {
        this.barrios = data.data;
      });
    },
    fetchLocalidades(id_loc) {
      this.axios.get("/soporte/localidadesconbarrios").then((data) => {
        this.localidadesConBarrios = data.data;
      });
    },
  },
  watch: {
    localidadConBarrio(val) {
      this.fetchBarrios(val);
    },

    "formClient.paraEnviar": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (bool) {
        this.$emit("set-paraenviar", bool);
      },
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.key === "F9") {
        this.formClient.paraEnviar = !this.formClient.paraEnviar;
        this.esParaEnviar();
      }
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
    });
  },

  created() {
    this.fetchLocalidades();
  },
};
</script>

<style scoped>
.col-12 {
  padding-bottom: 0%;
}
</style>
