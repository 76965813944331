<template>
    <div>
 <v-list>
      <v-list-item @click="sendDireccion(direccion)" v-for="direccion in direcciones" :key="direccion.id">
        <template v-slot:default="{ active }">
          

          <v-list-item-content>
            <v-list-item-title>{{ direccion.nombre }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ direccion.calle }} {{ direccion.altura }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
                <v-icon :color="direccion.is_favorita ? 'yellow darken-3' : 'grey darken-3'"> mdi-star </v-icon>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
    </div>
</template>

<script>


    export default {
        name: 'ClienteDireccionesList',
        props: {
            direcciones: {
            type: Array,
            required: true
            }
        },
        methods: {
            sendDireccion(direccion){
                this.$emit('process-direccion', direccion)
            }
        },
       
    }
</script>

<style lang="scss" scoped>

</style>