<template>
  <div>
    <layout-arqueo>
      <template v-slot:title>Cerrar la caja <v-btn v-if="esAdmin" @click="removeItemBloqueoCerrarCaja">Permitir salir del cierre</v-btn></template>
      <template v-slot:left>
        <template v-if="!isHabilitadoCierre && !isHabilitadoFacturacion">
          <div class="mx-2 mb-2 subtutle">Contá los billetes</div>
          <contar-billetes
            class="justify-center"
            text-button="Continuar"
            @process-monto="setDinero"
          ></contar-billetes>
        </template>
        <v-alert type="info" v-if="isHabilitadoCierre">
          No debes tener más de {{ parametroCaja.monto }} al cerrar la caja.
        </v-alert>
        <v-alert
          icon="mdi-file-document-multiple"
          type="info"
          v-if="isHabilitadoFacturacion"
        >
          Debes facturar {{ parametroFacturacion.monto }} como mínimo.
        </v-alert>
      </template>
      <template v-slot:right>
        <v-row id="saldo">
          <v-col cols="12">
            <v-banner single-line>
              Saldo
              <template v-slot:actions>
                <span class="text-h5">{{ arqueoCaja.montoActual | convertPesoArg }}</span>
              </template>
            </v-banner>
          </v-col>
        </v-row>
        <v-row id="saldocaja">
          <v-col cols="12">
            <v-banner single-line color="teal darken-3">
              Usted tiene
              <template v-slot:actions>
                <span class="display-1">{{ totalEfectivoCaja | convertPesoArg }}</span>
              </template>
            </v-banner>
          </v-col>
        </v-row>
        <v-row id="continuar" class="mt-0" align="end">
          <v-col cols="12">
            <v-btn
              v-show="!verResultado"
              block
              color="success"
              @click="verResultadoCaja"
              :disabled="totalEfectivoCaja < 5"
              >Continuar</v-btn
            >
          </v-col>
        </v-row>
        <template v-if="verResultado">
          <v-row class="mt-0">
            <v-col cols="12" class="mt-0 pt-0">
              <v-alert dense type="error" v-if="diferenciaDeCaja > 0">
                Esta faltando:
                <span class="headline">$ {{ diferenciaDeCaja }}</span>
              </v-alert>
              <v-alert dense type="info" v-else-if="diferenciaDeCaja < 0">
                Esta sobrando:
                <span class="headline">$ {{ Math.abs(diferenciaDeCaja) }}</span>
              </v-alert>
              <v-banner v-else single-line color="success">
                <v-icon slot="icon" size="36"> mdi-check </v-icon>
                ¡Muy bien!
              </v-banner>
            </v-col>
            <v-col cols="12" v-if="diferenciaDeCaja !== 0">
              <v-textarea
                label="Comentarios"
                outlined
                data-vv-name="comentario"
                v-validate="'max:100'"
                :error-messages="errors.collect('comentario')"
                rows="2"
                counter="100"
                style="font-size: 20px"
                row-height="5"
                v-model="comentarios"
              ></v-textarea>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-btn
                large
                @click="cerrarCaja"
                :disabled="errors.any() || (totalEfectivoCaja == 0 && arqueoCaja.montoActual > 0)"
                block
                color="orange"
                ><div class="title">CERRAR CAJA</div>
                <v-icon right dark> mdi-lock </v-icon></v-btn
              >
            </v-col>
          </v-row>
        </template>
        <loading
          :active.sync="loading"
          loader="dots"
          :height="128"
          color="#afcb08"
          :is-full-page="false"
          :width="128"
          background-color="#AFB"
        ></loading>
        <cierre-caja-confirmation
          v-if="dialogCierre"
          @close-dialog="dialogCierre = false"
          @process-accept="cerrarArqueoCaja"
        >
          <template v-slot:titulo>¿Quiere cerrar la caja?</template>
          <template v-slot:message>
            <v-row justify="center">
              <v-col cols="4">
                <v-card
                  @click="cerrarArqueoCaja"
                  height="178px"
                  width="160px"
                  class="d-flex align-center"
                  :loading="loadingCierre"
                >
                  <v-container class="text-center">
                    <div>
                      <v-icon color="blue" large>mdi-update</v-icon>
                    </div>
                    <h3>CAMBIO DE TURNO</h3>
                    <span style="color: #bdbdbd">Se va a iniciar otra caja </span>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  @click="dialogConfirmarCierre = true"
                  height="178px"
                  width="160px"
                  class="d-flex align-center"
                  
                >
                  <v-container class="text-center">
                    <div>
                      <v-icon color="blue" large>mdi-calendar-lock</v-icon>
                    </div>
                    <h3>FINALIZAR JORNADA</h3>
                    <span style="color: #bdbdbd">
                      No se abren más cajas hasta mañana
                    </span>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:buttonOK>Finalizar</template>
        </cierre-caja-confirmation>
        <dialog-confirmation-close
          v-if="dialogConfirmarCierre"
          @close-dialog="dialogConfirmarCierre = false"
          @process-accept="finalizarJornada"
        >
          <template v-slot:titulo>¿Desea finalizar la JORNADA?</template>
          <template v-slot:message>
            <v-alert>

              ¿Está seguro que quieres finalizarla? Se notificará el cierre.
            </v-alert>

            <v-card>
              <v-card-title>
                Cierre Z
              </v-card-title>
              <v-card-text>
                Imprime un <b>INFORME DE CIERRE Z</b> 
                <v-alert v-if="errorImpresionZ" type="warning"> No se pudo imprimir el informe.</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="imprimirz">Imprimir</v-btn>
              </v-card-actions>
            </v-card>
            
            </template
          >
          <template v-slot:buttonOK>Finalizar</template>
        </dialog-confirmation-close>
      </template>
    </layout-arqueo>
  </div>
</template>

<script>

import Loading from "vue-loading-overlay";
import CierreCajaConfirmation from "@/components/dialogs/cierre.caja.confirmation";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import ContarBilletes from "@/views/actividad/contar.billetes.vue";
import LayoutArqueo from "@/layouts/layoutArqueo.vue";


import ConfiguracionesAPI from "@/api/configuracion/parametros/index.js";
import { getStorage } from "@/services/serviceLocalStorage";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "arqueo.caja.finalizar.vue",
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  components: {
    CierreCajaConfirmation,
    DialogConfirmationClose,
    ContarBilletes,
    Loading,
    LayoutArqueo,
  },

  data() {
    return {
      dialogConfirmarCierre: false,
      dialogCierre: false,
      fechaInicio: "2020-10-14T21:57:59.963Z",
      notificar: false,
      verResultado: false,
      saldoInicial: 0,
      ingresosCaja: 0,
      egresosCaja: 0,
      comentarios: "",
      totalEfectivoCaja: 0,
      billetes: [],
      loading: false,
      parametroCaja: null,
      parametroFacturacion: null,
      loadingCierre: false,
      errorImpresionZ: false
    };
  },

  computed: {
    ...mapState("comprobantes", ["total_vendido_blanco"]),
    ...mapState("auth", ["group"]),
    ...mapState("sucursales", ["configuracion", "caja", "arqueoCaja"]),


    esAdmin(){
      return this.group.includes("esAdmin")
    },

    isHabilitadoCierre() {
      if (this.parametroCaja && this.parametroCaja.habilitado) {
        return this.arqueoCaja.montoActual > this.parametroCaja.monto;
      }
      return false;
    },

    isHabilitadoFacturacion() {
      if (this.parametroFacturacion && this.parametroFacturacion.habilitado) {
        return this.parametroFacturacion.monto > this.total_vendido_blanco.total;
      }
    },

    diferenciaDeCaja() {
      return Math.round(this.arqueoCaja.montoActual - this.totalEfectivoCaja);
    },

    totalIngresos() {
      return Math.round(this.arqueoCaja.ingresos + this.arqueoCaja.ingresos);
    },
    totalEgresos() {
      return Math.round(this.arqueoCaja.egresos);
    },
    saldo() {
      return Math.round(this.arqueoCaja.montoInicial + this.totalIngresos - this.totalEgresos);
    },
   
  },
  methods: {
    ...mapMutations("auth", ["REMOVE_USER"]),
    ...mapMutations("sucursal",["SET_ARQUEO_CAJA"]),
    ...mapActions("comprobantes", ["get_total_ventas_blanco"]),
    ...mapActions("sucursales", ["fetchArqueoVigente"]),

    removeItemBloqueoCerrarCaja(){
      localStorage.removeItem('cerrarCaja')
    },
    cerrarCaja() {
      this.dialogCierre = true;
    },
    imprimirz(){
       
       var url = "/reportes/reportezeta";
       this.axios.get(url).then((response) => {
         console.log(response.data);
         this.chartGastos = response.data;
       }).catch(e => {
        this.errorImpresionZ = true
       })
     },
    verResultadoCaja() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.verResultado = true;
      }, 2000);
    },
    setDinero(billetes) {
      this.billetes = billetes;
      var saldo = 0;
      billetes.forEach((element) => {
        if (element.cantidad !== null) {
          saldo += element.cantidad * parseInt(element.valorBillete);
        }
      });
      this.totalEfectivoCaja = saldo;
    },
   
    finalizarJornada() {
      this.notificar = true;
      this.cerrarArqueoCaja();
    },
    cerrarArqueoCaja() {
      this.loadingCierre = true
      var data = {
        caja: this.caja.id,
        detalle: this.billetes,
        observaciones: this.comentarios,
        notificar: this.notificar,
      };
      this.axios
        .post("/sucursales/arqueodecaja/cierre", data)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Caja cerrada",
            icon: "mdi-check",
            timeout: 2000,
          });
          localStorage.removeItem('cerrarCaja')

          this.REMOVE_USER();
          this.SET_ARQUEO_CAJA(null)
          this.$router.push('Login')
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            text: e.response.data.detail,
            icon: "mdi-information",
            timeout: 2000,
          });
          this.notificar = false;

          this.dialogConfirmarCierre = false;
          this.dialogCierre = false;
      this.loadingCierre = false

        });
    },
    async fetchParametroCaja() {
      const parametroCaja = await ConfiguracionesAPI.index(400);
      this.parametroCaja = parametroCaja.data.find((a) => a.nombre == "Cierre de caja");
    },
    async fetchParametroFacturacion() {
      const parametroFacturacion = await ConfiguracionesAPI.index(500);
      this.parametroFacturacion = parametroFacturacion.data.find(
        (a) => a.nombre == "Facturación mínima"
      );
    },
  
  },
  
  created() {
    this.get_total_ventas_blanco();
    this.fetchParametroFacturacion();
    this.fetchParametroCaja();
    this.fetchArqueoVigente()

  },
};
</script>

<style scoped>
.two-colors {
  background: #afcb08;
  background: linear-gradient(
    180deg,
    #afcb08 0,
    #afcb08 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );
}
</style>
