<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="proveedores"
          class="row-pointer transparent"
          disable-pagination
          @click:row="handleClick"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
          </template>

          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay proveedores registrados.</v-alert
            >
          </template>
          <template v-slot:item.tieneLista="{item}">
          
                <v-icon v-if="item.tieneLista">mdi-checkbox-marked-circle</v-icon>
            
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "proveedores-list",
  props: {
    search: {
      type: String,
    },
    title: {
      type: String,
      default: "Lista de proveedores",
    },
    proveedores: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Dirección", value: "direccion", sortable: false },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "E-mail", value: "email", sortable: false },
        { text: "Localidad", value: "localidad.nombre", sortable: false },
        {
          text: "Provincia",
          value: "localidad.provincia.nombre",
          sortable: false,
        },
        { text: "Lista de precio", value: "tieneLista", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions("proveedor", ["eliminar_proveedor", "fetchProveedores"]),
    ...mapMutations("proveedor", ["setProveedorSelectedUpdate"]),

    handleClick(item) {
      this.$emit("process-proveedor", item);
    },
  },
  created() {
    if (this.proveedores.length == 0) {
      this.fetchProveedores();
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
