<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red darken-1" dark v-bind="attrs" v-on="on">
        Agregar dirección
      </v-btn>
    </template>

    <v-card>
      <cliente-direcciones-form
        :direccion="direccion"
        @close-dialog="dialog = false"
        @process-save="registrarDireccion"
      ></cliente-direcciones-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ClienteDireccionesForm from "@/components/cliente/direcciones/cliente.direccion.form.vue";
import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";
import { mapActions } from 'vuex';


export default {
  name: "DireccionCreate",
  components: {
    ClienteDireccionesForm,
  },
  props: {
    cliente_id: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      direccion: {
        barrio: null,
        localidad: null,
        nombre: "",
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
        cliente: null
      },
    };
  },
  methods: {
    ...mapActions("clienteDirecciones", ["fetch_direcciones"]),

    registrarDireccion(direccion) {
      direccion.cliente = this.cliente_id
      console.log(direccion);
      ClienteDireccionesAPI.create(direccion).then((response) => {
        
        this.$emit('refresh-direcciones')

           this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Dirección registrada`,
            icon: "mdi-check",
            timeout: 2500,
          });
          this.dialog = false
      });

    },
  },
};
</script>

<style lang="scss" scoped></style>
