var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProductoLayoutList',{scopedSlots:_vm._u([{key:"list",fn:function(){return [_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.productos,"search":_vm.search,"item-key":"id","show-select":_vm.productos_admin_seleccion_manual,"hide-default-footer":true,"disable-pagination":"","no-data-text":"No se encontraron productos","no-results-text":"Sin coincidencia para la búsqueda","calculate-widths":true},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th',[_c('v-checkbox',{attrs:{"input-value":props.all,"indeterminate":props.indeterminate,"primary":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll.apply(null, arguments)}}})],1),_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
           'column sortable',
           _vm.pagination.descending ? 'desc' : 'asc',
           header.value === _vm.pagination.sortBy ? 'active' : '' ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_c('h2',[_vm._v(_vm._s(header.text))])],1)})],2)]}},{key:"item.cantidadstock",fn:function(ref){
         var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(" "+_vm._s(item.cantidadstock)+" ")])]}},{key:"item.fechaActualizacion",fn:function(ref){
         var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fechaActualizacion).format("lll"))+" ")]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }