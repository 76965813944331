<template>
  <div>
    <h1>Gastos</h1>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col>

         
        <v-card>
          <v-container>
        <v-menu v-model="menu1" :close-on-content-click="true" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="computedDateFormattedMomentjs" label="Mes del periodo" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="fechaPeriodo"  no-title type="month" class="mb-4" locale="es"
            year-icon="mdi-calendar-blank" prev-icon="mdi-skip-previous" next-icon="mdi-skip-next" @change="fetchGastos"
            :max="moment().format('YYYY-MM-DD')"></v-date-picker>
        </v-menu>
          </v-container>
        </v-card>
         </v-col>
        </v-row>
      
        <v-row justify="center">
          <v-col >
            <v-card>
              <v-container>
                <v-row justify="start">
                  <v-col cols="12" md="6">


                    <h1>{{moment(fechaPeriodo).format('MMMM').toUpperCase()}} - {{ totalGastos | convertPesoArg }}</h1>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
      
          <v-col cols="6">
            <ChartGastosPorMes :chartGastos="chartGastos"></ChartGastosPorMes>

          </v-col>
            <v-col cols="6">
        <ChartComprasPorMes :compras="chartGastosCompras" ></ChartComprasPorMes>
        
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoughnutChart from "@/chartjs/doughnut.chart.vue";
import ChartComprasPorMes from '@/components/reportes/ChartComprasPorMes.vue'
import ChartGastosPorMes from '@/components/reportes/ChartGastosPorMes.vue'


export default {
  name: "reporte.gastos",
  components: {
    DoughnutChart,
    ChartComprasPorMes,
    ChartGastosPorMes
  },
  data() {
    return {
      chartGastos: null,
      chartGastosCompras: null,
      keyChartCompras: 0,
      chartDataIngreso: null,
      options: { responsive: true, maintainAspectRatio: false },
      fechaPeriodo: new Date().toISOString().substr(0, 7),
      menu1: false,
    };
  },
  computed: {
    totalGastosPorCategoria() {
      if (this.chartGastos) {
        const sum = this.chartGastos.data.reduce((accumulator, object) => {
          return accumulator + object.total_categoria;
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
     totalGastosCompra() {
      if (this.compras) {
        const sum = this.compras.data.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
 
    totalGastos() {
      this.keyChartCompras = !this.keyChartCompras
      this.keyChartGastos = !this.keyChartGastos


      return this.totalGastosCompra + this.totalGastosPorCategoria
    },
    computedDateFormattedMomentjs() {
      return this.fechaPeriodo
        ? this.moment(this.fechaPeriodo).format("MMMM YYYY").toUpperCase()
        : "";
    },
  },
  methods: {
    fetchGastos(){
      this.fetchReporte()
      this.fetchGastosCompras()
    },
    fetchReporte() {
      var url = `/reportes/reportegastoscat?current=${this.fechaPeriodo}`;
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.chartGastos = response.data;
      });
    },
    fetchGastosCompras() {
      var url = `/reportes/reportecompracategoria?current=${this.fechaPeriodo}`;
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.chartGastosCompras = response.data;
      });
    },
  },
  created() {
    this.fetchReporte();
    this.fetchGastosCompras()

  },
};
</script>

<style lang="scss" scoped></style>
