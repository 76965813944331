<template>
  <v-navigation-drawer
    width="400px"
    app
    v-model="modelComputed"
    temporary
    right
  >
    <!-- Contenido del drawer -->

    <!-- Slot de prepend (parte superior) -->
    <template v-slot:prepend>
      <!-- v-alert para mostrar un mensaje de estado -->
      <slot name="prepend-header"> </slot>

      <!-- v-list-item para mostrar detalles del pedido -->
      <v-list-item class="ma-0">
        <!-- Contenido de encabezado -->
        <v-list-item-content>
          <v-list-item-title>
            <div class="display-1">
              <slot name="prepend-title"></slot>
            </div>
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            <slot name="prepend-subtitle"></slot>
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- Botón para editar el pedido -->
        <v-list-item-icon>
          <slot name="prepend-button">
            <v-btn outlined @click="editarPedido">
              <slot name="prepend-button-text">Editar</slot>

              <v-icon class="mr-2"
                ><slot name="prepend-button-text">mdi-pencil</slot></v-icon
              >
            </v-btn>
          </slot>
        </v-list-item-icon>
      </v-list-item>

      <!-- Detalles del cliente -->
    </template>

    <!-- Slot predeterminado para el cuerpo del drawer -->
    <slot name="content"></slot>

    <!-- Slot de append (parte inferior) -->
    <template v-slot:append>
      <!-- v-divider para separar elementos -->
      <v-divider></v-divider>
      <slot name="append-content"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "CustomRightDrawer",
  props: {
    model: {
      type: Boolean,
      required: true,
    },
    colorAlerta: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    modelComputed: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("set-draweron", val);
      },
    },
  },
  methods: {
    editarPedido() {
      this.$emit("process-open");
    },
  },
};
</script>

<style scoped>
</style>