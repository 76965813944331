import { mapActions, mapGetters } from "vuex";


export var menuLinks = {
    data() {
        return {
            linksMenu: []
        }
    },
    computed: {
        ...mapGetters(["getLinksDelMenu"]),
    },
    methods: {
        ...mapActions(["fetchLinksPorMenu"]),
        async obtenerLinksDelMenu(menuName) {
            if (menuName) {
                await this.fetchLinksPorMenu(menuName); // Espera a que la acción termine antes de continuar
                this.linksMenu = this.getLinksDelMenu(menuName);
            }
        },
    },
    created() {
        this.obtenerLinksDelMenu(this.menuName)
    }



}