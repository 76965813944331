<template>
  <v-card flat id="endPage">
    <v-toolbar flat>
      <v-toolbar-title>Buscar producto</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close-dialog')" text>
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
          <div class="display-1">Productos</div>
          <producto-buscador-multiple
            @loading-buscar-producto="setLoadingBuscador"
            ref="search"
            @focus.native="$event.target.select()"
            @seleccionar-producto-local="getProductoConCodigoLocal"
            @resultado-buscar-producto="setBuscarProducto"
            @clean-buscador="cleanBuscador"
          ></producto-buscador-multiple>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <productos-list-card-select
              :paginacion="paginacion"
              v-if="productos.length > 0"
              :productos="productos"
              @process-producto="agregarProducto"
              @set-page="setPage_fetchProductos"
            ></productos-list-card-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ProductosListCardSelect from "@/components/producto/listar/productos.list.card.select.vue";

import ProductoBuscadorMultiple from "@/components/producto/buscador/producto.buscador.multiple";
import { mapState } from "vuex";

export default {
  name: "producto.buscador.menu",
  components: {
    ProductoBuscadorMultiple,
    ProductosListCardSelect,
  },

  data() {
    return {
      reloadCategoria: 0,
      productos: [],
      loadingBusqueda: false,

      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },

      tab: null,
      items: [
        { tab: "Categorias", content: "Marca" },
        { tab: "Búsqueda avanzada", content: "Precio compra" },
        { tab: "Promociones", content: "Precio compra" },
      ],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    setProductosFromCategoria(data) {
      this.productos = data;
    },
    setLoadingBuscador(bool) {
      this.loadingBusqueda = bool;
    },
    getProductoConCodigoLocal() {
      if (this.productos.length == 1) {
        this.agregarProducto(this.productos[0]);
      }
    },
    cleanBuscador() {
      this.productos = [];
    },
    setBuscarProducto(data) {
      if (data === null) return;
      this.reloadCategoria = !this.reloadCategoria;

      this.productos = data;
    },
    agregarProducto(producto) {
      this.$emit("process-add-producto", producto);
      this.$refs.search.focus();
    },

    enviarDetalle() {
      this.$emit("process-add-detalle-pedido", this.detalle);
      this.$emit("close-dialog");

      this.reloadCategoria = !this.reloadCategoria;

      this.$refs.detalleForm.reset();
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.key === "Insert") {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    });
  },
};
</script>

<style scoped></style>
