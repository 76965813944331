<template>
  <v-list two-line v-if="filteredSucursales">
    <!-- Lista de sucursales activas -->
    <v-subheader>Ubicación actual</v-subheader>
    <v-list-item
      @click="handleClick(sucursal)"
      v-for="sucursal in filteredSucursales.actual"
      :key="sucursal.id"
    >
      <v-list-item-avatar>
        <v-icon color="success">mdi-map-marker</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ sucursal.nombre }}</v-list-item-title>
        <v-list-item-subtitle>{{ sucursal.direccion }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon>
          <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <!-- Lista de sucursales no activas -->
    <v-subheader>Otras sucursales</v-subheader>
    <v-list-item
      @click="handleClick(sucursal)"
      v-for="sucursal in filteredSucursales.restoSucursales"
      :key="sucursal.id"
    >
      <v-list-item-avatar>
        <v-icon dark>mdi-bank</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ sucursal.nombre }}</v-list-item-title>
        <v-list-item-subtitle>{{ sucursal.direccion }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon>
          <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: "SucursalesList",
  data() {
    return {
    //   sucursales: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["sucursales"]),
    filteredSucursales() {
      const actual = this.sucursales.filter(
        (sucursal) => sucursal.es_sucursal_actual
      );
      const restoSucursales = this.sucursales.filter(
        (sucursal) => !sucursal.es_sucursal_actual
      );

      return {
        actual,
        restoSucursales,
      };
    },
  },
  methods: {
    ...mapActions("sucursales", ['fetch_sucursales']),
    handleClick(sucursal) {
        console.log("handleClick");
      this.$emit('handle-click', sucursal);
    },

  },

  created() {
        this.fetch_sucursales()
    
    // this.axios.get("/sucursales/sucursales").then((response) => {
    //   this.sucursales = response.data;
    // });
  },
};
</script>

<style lang="scss" scoped>
</style>