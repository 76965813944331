<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      max-width="200"
      height="100%"
      :color="hover ? '#3A3B3C' : '#242526'"
      @click="$emit('process-promocion', promocion)"
      v-if="isVenta ? (isVigente ? true : false) : true"
    >
      <v-card-title>
        {{
          promocion.nombre != "" ? promocion.nombre : "Aquí va el nombre"
        }}</v-card-title
      >

      <v-card-text>
        <div class="subtitle-1">
          •
          {{
            promocion.descripcion != "" ? promocion.descripcion : "Aquí va la descripcion"
          }}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title class="justify-center">$ {{ promocion.precioVenta }}</v-card-title>

      <v-card-text>
        <v-chip-group column>
          <!-- <v-chip v-if="isVigente" small color="success">
            <v-avatar left>
              <v-icon>mdi-clock</v-icon>
            </v-avatar>

            Vigente</v-chip
          > -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="headline align-end">
                <v-chip class="align-end" small v-if="!isFechaInicio" color="orange">
                  <v-avatar left>
                    <v-icon light>mdi-clock</v-icon>
                  </v-avatar>
                  <span>Por iniciar</span></v-chip
                >
              </span>
            </template>
            <span>
              Inicia el {{ moment(promocion.fechaInicioVigencia).format("ll") }}</span
            >
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="headline align-end">
                <v-chip
                  class="align-end"
                  small
                  v-if="promocion.fechaFinVigencia"
                  color="#FBC02D"
                >
                  <v-avatar left>
                    <v-icon light x-small>mdi-clock</v-icon>
                  </v-avatar>
                  <span>{{
                    moment(promocion.fechaFinVigencia).format("ll")
                  }}</span></v-chip
                >
              </span>
            </template>

            <span v-if="isVigente">
              Vence el {{ moment(promocion.fechaFinVigencia).format("ll") }}</span
            >
            <span v-else>
              Venció el {{ moment(promocion.fechaFinVigencia).format("ll") }}</span
            >
          </v-tooltip>
          <!-- <v-chip
            class="align-end"
            small
            v-if="!promocion.fechaFinVigencia && isVigente"
            color="#FBC02D"
          >
            <v-avatar left>
              <v-icon light x-small>mdi-clock</v-icon>
            </v-avatar>
            <span>Sin vencimiento</span></v-chip
          > -->
          <v-chip
            class="align-end"
            small
            v-if="promocion.fechaFinVigencia && !isVigente"
            color="red"
          >
            <v-avatar left>
              <v-icon light x-small>mdi-clock</v-icon>
            </v-avatar>
            <span>Vencida</span></v-chip
          >
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-hover>
  <!-- <v-card color="#263238" height="100%" @click="$emit('process-promocion', promocion)">
    <v-container>
      <v-card-title>
        <slot name="chip-estado"></slot>
        <v-chip v-if="isFechaInicio" small color="success">
          <v-avatar left>
            <v-icon>mdi-clock</v-icon>
          </v-avatar>

          Activa</v-chip
        >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="headline align-end">
              <v-chip class="align-end" v-if="!isFechaInicio" color="orange">
                <v-avatar left>
                  <v-icon light x-small>mdi-clock</v-icon>
                </v-avatar>
                <span>Por iniciar</span></v-chip
              >
            </span>
          </template>
          <span> Inicia el {{ moment(promocion.fechaInicioVigencia).format("ll") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="headline align-end">
              <v-chip
                class="align-end"
                v-if="promocion.fechaFinVigencia && isFechaInicio"
              
                color="#FBC02D"
              >
                <v-avatar left>
                  <v-icon light x-small>mdi-clock</v-icon>
                </v-avatar>
                <span>{{ moment(promocion.fechaFinVigencia).format("ll") }}</span></v-chip
              >
            </span>
          </template>
          <span> Vence el {{ moment(promocion.fechaFinVigencia).format("ll") }}</span>
        </v-tooltip>
        <v-chip
          class="align-end"
          v-if="!promocion.fechaFinVigencia && isFechaInicio"
          x-small
          color="#FBC02D"
        >
          <v-avatar left>
            <v-icon light x-small>mdi-clock</v-icon>
          </v-avatar>
          <span>Sin vencimiento</span></v-chip
        >
      </v-card-title>

      <v-row justify="center">
       
        <v-col class="text-xs-center" align="center" cols="12">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="headline">
                <b
                  >{{ promocion.nombre != "" ? promocion.nombre : "Aquí va el nombre" }}
                </b>
              </span>
            </template>
            <span>Nombre</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="title">
                <span class="col-12 text-truncate"
                  >{{
                    promocion.descripcion != ""
                      ? promocion.descripcion
                      : "Aquí va la descripción"
                  }}
                </span>
              </span>
            </template>
            <span>Descripción: {{ promocion.descripcion }}</span>
          </v-tooltip>
        </v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="text-center display-1" style="color: grey">
              $ {{ promocion.precioVenta }}
            </div>
          </template>

          <span>Precio venta</span>
        </v-tooltip>
      </v-row>
    </v-container>
  </v-card> -->
  <!-- <v-card color="red"> 
    <v-container>


      <v-row justify="center">
        <v-col cols="8">
          <v-img
            class="white--text"
            max-height="125"
            contain
            v-bind:src="imagen.noImage"
          ></v-img>
        </v-col>
        <v-col class="text-xs-center" align="center" cols="12">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="headline">
                <p>
                  <b>{{ promocion.nombre }} </b>
                </p>
              </span>
            </template>
            <span>Nombre del producto</span>
          </v-tooltip>

          <span class="d-inline-block text-truncate">{{ promocion.descripcion }}</span>
          <div class="row">
            <div class="col-2 text-truncate">
              Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
              tellus.
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="text-center display-1" style="color: grey">
                $ {{ promocion.precioVenta }}
              </div>
            </template>

            <span>Precio venta</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card> -->
</template>

<script>
export default {
  name: "promocion.vista-previa",
  props: {
    promocion: {
      type: Object,
      required: true,
    },
    isVenta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
      cuentaRegresiva: null,
    };
  },
  computed: {
    isFechaInicio() {
      if (this.promocion.fechaInicioVigencia) {
        return (
          this.moment().format("YYYY-MM-DD") >=
          this.moment(this.promocion.fechaInicioVigencia).format("YYYY-MM-DD")
        );
      } else {
        return true;
      }
    },
    isFechaFin() {
      if (this.promocion.fechaFinVigencia) {
        return (
          this.moment().format("YYYY-MM-DD") <=
          this.moment(this.promocion.fechaFinVigencia).format("YYYY-MM-DD")
        );
      } else {
        return true;
      }
    },
    isVigente() {
      if (this.isFechaInicio && this.isFechaFin) {
        return true;
      }
      return false;
    },
    productos() {
      if (this.promocion.detalles <= 0) {
        return "";
      }
      var descri = "";
      var detalles = this.promocion.detalles;

      for (let i = 0; i < detalles.length; i++) {
        var producto = detalles[i].producto;
        var cantidad = detalles[i].cantidad;

        if (i == 0) {
          descri += cantidad + " " + producto.descripcion + " ";
        } else {
          descri += "+ " + cantidad + " " + producto.descripcion;
        }
      }
      return descri;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
