<template>
  <v-card flat  class="items3 transparent">
    <v-list  class="detalle "  dense>
      <v-subheader>Detalles del pedido</v-subheader>
       <v-list-item  v-for="(detalle, index) in detalles" :key="index">
        <template v-if="detalle.producto">
          <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ detalle.producto.descripcion }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>{{
            Number(detalle.precioUnitario * detalle.cantidad) | convertPesoArg
          }}</v-list-item-icon>
        </template>
        <template v-else>
          <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ detalle.promocion.nombre }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>{{
            Number(detalle.precioUnitario * detalle.cantidad) | convertPesoArg
          }}</v-list-item-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "DetallesPedido",
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
