<template>
  <v-container>
    <v-row v-if="contabilidadSucursal" justify="center">
      <v-col cols="12">
        <div class="headline">Sucursal Contable</div>
      </v-col>

      <!-- Rubro Venta -->
      <v-col cols="12" md="6" v-if="contabilidadSucursal">
        <v-card :loading="loadingRubroVenta" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-bookmark-box-outline</v-icon>
            Rubro Venta
          </v-card-title>
          <v-card-text>
            <RubroVentaListSelect
              :value="contabilidadSucursal.rubro_venta"
              @process-select="setRubroVenta"
              title="Seleccionar Rubro Venta"
            ></RubroVentaListSelect>
            <v-alert v-if="errorRubroVenta" type="error" class="mt-2">
              {{ errorRubroVenta }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Rubro Compra -->
      <v-col cols="12" md="6" v-if="contabilidadSucursal">
        <v-card :loading="loadingRubroCompra" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-bookmark-box</v-icon>
            Rubro Compra
          </v-card-title>
          <v-card-text>
            <RubroCompraListSelect
              :value="contabilidadSucursal.rubro_compra"
              @process-select="setRubroCompra"
              title="Seleccionar Rubro Compra"
            ></RubroCompraListSelect>
            <v-alert v-if="errorRubroCompra" type="error" class="mt-2">
              {{ errorRubroCompra }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Retenciones Ganancias -->
      <v-col cols="12" md="6">
        <v-card
          :loading="loadingRetencionesGanancias"
          class="pa-3"
          elevation="2"
        >
          <v-card-title>
            <v-icon class="mr-2">mdi-cash-multiple</v-icon>
            Retenciones Ganancias
          </v-card-title>
          <v-card-text>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="contabilidadSucursal.retenciones_ganancia"
              @update:initialCuenta="setRetencionesGanancias"
            ></CuentasTreeviewDialog>
            <v-alert v-if="errorRetencionesGanancias" type="error" class="mt-2">
              {{ errorRetencionesGanancias }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Retenciones IB -->
      <v-col cols="12" md="6">
        <v-card :loading="loadingRetencionesIb" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-cash-multiple</v-icon>
            Retenciones IB
          </v-card-title>
          <v-card-text>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="contabilidadSucursal.retenciones_ib"
              @update:initialCuenta="setRetencionesIb"
            ></CuentasTreeviewDialog>
            <v-alert v-if="errorRetencionesIb" type="error" class="mt-2">
              {{ errorRetencionesIb }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Cuenta Contado -->
      <v-col cols="12" md="6">
        <v-card :loading="loadingCuentaContado" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-account-cash</v-icon>
            Cuenta Contado
          </v-card-title>
          <v-card-text>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="contabilidadSucursal.cuenta_contado"
              @update:initialCuenta="setCuentaContado"
            ></CuentasTreeviewDialog>
            <v-alert v-if="errorCuentaContado" type="error" class="mt-2">
              {{ errorCuentaContado }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Cuenta Corriente -->
      <v-col cols="12" md="6">
        <v-card :loading="loadingCuentaCorriente" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-account-cash</v-icon>
            Cuenta Corriente
          </v-card-title>
          <v-card-text>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="contabilidadSucursal.cuenta_corriente"
              @update:initialCuenta="setCuentaCorriente"
            ></CuentasTreeviewDialog>
            <v-alert v-if="errorCuentaCorriente" type="error" class="mt-2">
              {{ errorCuentaCorriente }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Cuenta Otros -->
      <v-col cols="12" md="6">
        <v-card :loading="loadingCuentaOtros" class="pa-3" elevation="2">
          <v-card-title>
            <v-icon class="mr-2">mdi-account-cash</v-icon>
            Cuenta Otros
          </v-card-title>
          <v-card-text>
            <CuentasTreeviewDialog
              :planDeCuentas="planDeCuentas"
              :initialCuenta="contabilidadSucursal.cuenta_otros"
              @update:initialCuenta="setCuentaOtros"
            ></CuentasTreeviewDialog>
            <v-alert v-if="errorCuentaOtros" type="error" class="mt-2">
              {{ errorCuentaOtros }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import RubroCompraListSelect from "@/components/contabilidad/RubroCompraListSelect.vue";
import RubroVentaListSelect from "@/components/contabilidad/RubroVentaListSelect.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ContabilidadSucursal",
  components: {
    LayoutBasic,
    CuentasTreeviewDialog,
    RubroCompraListSelect,
    RubroVentaListSelect,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      loadingRubroVenta: false,
      loadingRubroCompra: false,
      loadingRetencionesGanancias: false,
      loadingRetencionesIb: false,
      loadingCuentaContado: false,
      loadingCuentaCorriente: false,
      loadingCuentaOtros: false,
      errorRubroVenta: null, // Para mensajes de error
      errorRubroCompra: null,
      errorRetencionesGanancias: null,
      errorRetencionesIb: null,
      errorCuentaContado: null,
      errorCuentaCorriente: null,
      errorCuentaOtros: null,
    };
  },

  computed: {
    ...mapState("contabilidad", ["contabilidadSucursal"]),
  },
  methods: {
    ...mapActions("contabilidad", [
      "fetch_contabilidad_sucursal",
      "update_contabilidad_sucursal",
    ]),

    async setRubroCompra(value) {
      this.loadingRubroCompra = true;
      this.errorRubroCompra = null; // Resetea el mensaje de error

      var originalRubro = { ...this.contabilidadSucursal.rubro_compra };

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          rubro_compra: value.id,
        });
        this.contabilidadSucursal.rubro_compra = value;
      } catch (error) {
        console.log(error);
        this.errorRubroCompra = "Error al actualizar Rubro Compra."; // Establece el mensaje de error
        this.contabilidadSucursal.rubro_compra = originalRubro;
      } finally {
        this.loadingRubroCompra = false;
      }
    },

    async setRubroVenta(value) {
      this.loadingRubroVenta = true;
      this.errorRubroVenta = null; // Resetea el mensaje de error

      var originalRubro = { ...this.contabilidadSucursal.rubro_venta };

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          rubro_venta: value.id,
        });
        this.contabilidadSucursal.rubro_venta = value;
      } catch (error) {
        console.log(error);
        this.errorRubroVenta = "Error al actualizar Rubro Venta."; // Establece el mensaje de error
        this.contabilidadSucursal.rubro_venta = originalRubro;
      } finally {
        this.loadingRubroVenta = false;
      }
    },

    async setRetencionesIb(data) {
      this.loadingRetencionesIb = true;
      this.errorRetencionesIb = null; // Resetea el mensaje de error

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          retenciones_ib: data[0].id,
        });
        this.contabilidadSucursal.retenciones_ib = data[0];
      } catch (error) {
        console.log(error);
        this.errorRetencionesIb = "Error al actualizar Retenciones IB."; // Establece el mensaje de error
      } finally {
        this.loadingRetencionesIb = false;
      }
    },

    async setRetencionesGanancias(data) {
      this.loadingRetencionesGanancias = true;
      this.errorRetencionesGanancias = null; // Resetea el mensaje de error

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          retenciones_ganancia: data[0].id,
        });
        this.contabilidadSucursal.retenciones_ganancia = data[0];
      } catch (error) {
        console.log(error);
        this.errorRetencionesGanancias =
          "Error al actualizar Retenciones Ganancias."; // Establece el mensaje de error
      } finally {
        this.loadingRetencionesGanancias = false;
      }
    },

    async setCuentaContado(data) {
      this.loadingCuentaContado = true;
      this.errorCuentaContado = null; // Resetea el mensaje de error

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          cuenta_contado: data[0].id,
        });
        this.contabilidadSucursal.cuenta_contado = data[0];
      } catch (error) {
        console.log(error);
        this.errorCuentaContado = "Error al actualizar Cuenta Contado."; // Establece el mensaje de error
      } finally {
        this.loadingCuentaContado = false;
      }
    },

    async setCuentaCorriente(data) {
      this.loadingCuentaCorriente = true;
      this.errorCuentaCorriente = null; // Resetea el mensaje de error

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          cuenta_corriente: data[0].id,
        });
        this.contabilidadSucursal.cuenta_corriente = data[0];
      } catch (error) {
        console.log(error);
        this.errorCuentaCorriente = "Error al actualizar Cuenta Corriente."; // Establece el mensaje de error
      } finally {
        this.loadingCuentaCorriente = false;
      }
    },

    async setCuentaOtros(data) {
      this.loadingCuentaOtros = true;
      this.errorCuentaOtros = null; // Resetea el mensaje de error

      try {
        await this.update_contabilidad_sucursal({
          id: this.contabilidadSucursal.id, // Incluye el ID de la instancia
          cuenta_otros: data[0].id,
        });
        this.contabilidadSucursal.cuenta_otros = data[0];
      } catch (error) {
        console.log(error);
        this.errorCuentaOtros = "Error al actualizar Cuenta Otros."; // Establece el mensaje de error
      } finally {
        this.loadingCuentaOtros = false;
      }
    },

    fecthContabilidadSucursal() {
      this.fetch_contabilidad_sucursal();
    },
  },
  created() {
    this.fecthContabilidadSucursal();
  },
};
</script>
