<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Rubros de venta</div>
      </v-col>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar rubro"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-col>
      <v-col cols="12">
        <v-list>
          <v-list-item @click="$router.push({ name: 'rubroVentaRegistrar' })">
            <v-list-item-icon>
              <v-icon>mdi-book-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Nuevo rubro</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <RubroVentaList></RubroVentaList>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import RubroVentaList from "@/components/contabilidad/RubroVentaList.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ContabilidadRubroVenta",
  components: {
    RubroVentaList,
    LayoutBasic,
  },
   props: {
    planDeCuentas: {
      type: Array, 
      required: true
    }
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
  },
  methods: {
  
  },
  
};
</script>

<style lang="scss" scoped></style>
