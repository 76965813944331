<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          <v-icon class="ma-1">mdi-plus</v-icon>
          Agregar caja
        </v-btn>
      </template>
      <CajaForm
        v-if="dialog"
        :data="caja"
        :loading="loading"
        @process-cancel="cancelar"
        @process-save="guardar"
      />
    </v-dialog>
  </div>
</template>

<script>
import CajaForm from "./CajaForm.vue";
export default {
  name: "CajaRegistrar",
  components: {
    CajaForm,
  },
  data() {
    return {
      dialog: false,
      caja: { nombre: "", descripcion: "" },
      loading: false,
    };
  },

  methods: {
    cancelar() {
      this.dialog = false;
    },
    guardar() {
      this.loading = true;
      this.axios
        .post("/sucursales/cajas", this.caja)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Caja registrada",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.dialog = false;
          this.loading = false;
          this.$emit("process-fetch");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: e.data,
            icon: "mdi-bug",
            timeout: 3000,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>