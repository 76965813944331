import axios from 'axios'
import ReportesAPI from '@/api/reportes'
function descargarReporteProductoListado(parametros, productos) {
  if (productos && productos.length > 0) {
    var productos_format = []
    productos.forEach(element => {
      productos_format.push(element.id)
    });

    parametros.filtro = { producto: productos_format }
  }
  const headers = { responseType: "blob", observe: "response" };
  ReportesAPI.getReporteProductoListado(parametros, headers).then((response) => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(response.data);
    a.download = response.headers["content-disposition"].replace(
      "inline;filename=",
      ""
    );
    a.click();
  })
    .catch((e) => {
      console.log("Error al cargar el archivo");
    });
}

function descargarReportePlanillaGondola(parametros, productos) {
  if (productos && productos.length > 0) {
    var productos_format = []
    productos.forEach(element => {
      productos_format.push(element.id)
    });

    parametros.filtro = { producto: productos_format }
  }
  const headers = { responseType: "blob", observe: "response" };
  ReportesAPI.getReportePlanillaGondola(parametros, headers).then((response) => {
    const a = document.createElement("a");
          a.href = URL.createObjectURL(response.data);
          a.download = 'asfasf'
          // response.headers["content-disposition"].replace(
          //   "inline;filename=",
          //   ""
          // );
          a.click();
  })
    .catch((e) => {
      console.log("Error al cargar el archivo");
    });
}

function eliminarReporteListaPrecio(id) {
  return new Promise((resolve, reject) => {
    ReportesAPI.deleteReporteListaPrecio(id).then(response => {
      resolve(response);


    }, error => {

      reject(error);
    })

  })
}

function descargarPedidoPDF(pedido) {
      const headers = { responseType: "blob", observe: "response" };

      axios
        .get(`/pedidos/generate/pdf?q=${pedido.id}`, headers)
        .then((response) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(response.data);
          a.download = pedido.nroPedido
          // response.headers["content-disposition"].replace(
          //   "inline;filename=",
          //   ""
          // );
          a.click();
        })
        .catch((e) => {
          console.log("Error al cargar el archivo");
        });

    }

function descargarComprobantePDF(id) {
      const headers = { responseType: "blob", observe: "response" };

      axios
        .get(`/comprobantes/generate/pdf?q=${id}`, headers)
        .then((response) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(response.data);
          a.download = id
          // response.headers["content-disposition"].replace(
          //   "inline;filename=",
          //   ""
          // );
          a.click();
        })
        .catch((e) => {
          console.log("Error al cargar el archivo");
        });

    }



export { descargarReporteProductoListado, descargarReportePlanillaGondola, descargarPedidoPDF, descargarComprobantePDF, eliminarReporteListaPrecio }