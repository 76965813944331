<template>
  <v-row justify="center" align="center">
    <v-col
      v-if="pedido.getEstadosActual == 'PENDIENTE' && !pedido.estaPagado"
      cols="4"
    >
      <v-btn
        @click="$emit('process-cobrar')"
        :loading="loadingButton"
        class="ml-7"
        x-large
        icon
      >
        <v-icon dark> mdi-cash-register </v-icon>
      </v-btn>

      <div class="text-center">Cobrar</div>
    </v-col>
    <v-col
      cols="4"
      v-for="e in pedido.getEstadosSiguientesDescripcion"
      :key="e.nombre"
    >
      <v-btn
        @click="cambiarEstado(e.nombre)"
        :loading="loadingButton"
        class="ml-7"
        x-large
        icon
      >
        <v-icon v-if="e.nombre == 'FINALIZADO'" dark>
          {{ pedido.estaPagado ? "mdi-check-bold" : "mdi-cash-register" }}
        </v-icon>
        <v-icon v-if="e.nombre == 'PENDIENTE'" dark>
          mdi-keyboard-return
        </v-icon>
        <v-icon v-if="e.nombre == 'APROBADO'" dark> mdi-check-bold </v-icon>

        <v-icon v-if="e.nombre == 'CANCELADO'" color="red" dark>
          mdi-cancel
        </v-icon>
      </v-btn>

      <div class="text-center" id="buttonAccion">
        <span v-if="e.accion">
          <span v-if="e.nombre == 'FINALIZADO'">
            {{ pedido.estaPagado ? "Finalizar" : "Cobrar" }}
          </span>
          <span v-else-if="e.nombre == 'APROBADO'"> Listo </span>
          <span v-else>
            {{ e.accion }}
          </span>
        </span>
        <span v-else>
          Volver a {{ e.nombre == "PENDIENTE" ? "Producción" : e.nombre }}
        </span>
      </div>
    </v-col>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

export default {
  name: "pedido.estados.siguientes",
  components: {
    DialogConfirmationClose,
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingButton: false,
      dialogPedidoCancelar: false,
    };
  },
  methods: {
    ...mapActions("pedidos", ["actualizar_estado_pedido"]),
    setLoading(bool){
      this.loadingButton = bool
    },
    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.pedido.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.dialogPedidoCancelar = false;
          this.$emit("process-quitar-pedido", this.pedido);
        })
        .catch((e) => {});
    },
    cambiarEstado(estado) {
      if (estado == "CANCELADO") {
        this.dialogPedidoCancelar = true;
        return;
      }

      if (estado == "FINALIZADO") {
        if (this.pedido.estaPagado) {
          this.$emit("process-registrar");
          this.loadingButton = true;
        } else {
          this.$emit("process-cobrar");
        }
        return;
      }

      let id_pedido = this.pedido.id;

      this.actualizar_estado_pedido({ id_pedido, estado })

        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Pedido ${estado}`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loadingButton = false;
              this.$emit("close-dialog");
        })
        .catch((e) => {
          this.loadingButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
