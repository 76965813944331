<template>
  <div>
    <v-list subheader v-if="!loading">
      <v-subheader>Empleados</v-subheader>
      
      <empleado-consumo-item
        v-show="empleados.length > 0"
        v-for="empleado in empleados"
        :key="empleado.id"
        :empleado="empleado"
      >
      </empleado-consumo-item>
      <v-list-item v-show="empleados.length == 0">
        <v-list-item-content>
          <v-list-item-title>{{noData}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="loading">
      <v-skeleton-loader
        v-for="i in 6"
        :key="i"
        max-height="350"
        type="list-item-avatar"
      ></v-skeleton-loader>
    </v-list>
  </div>
</template>

<script>
import EmpleadoConsumoItem from "@/components/empleado/cuentacorriente/empleado.consumo.item.vue";
export default {
  name: "empleado.consumo.list",
  components: {
    EmpleadoConsumoItem,
  },
  props: {
    empleados: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
    noData: {
      type: String,
      required: false,
      default: "No hay empleados"
    },
  },
};
</script>

<style lang="scss" scoped>
</style>