<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <ContabilidadFormasDePagoListItem
          v-for="formaDePago in formasDePago"
          :key="formaDePago.id"
          :formaDePago="formaDePago"
          :planDeCuentas="planDeCuentas"
          @delete:formadepago="quitarFormaDePago"
        >
        </ContabilidadFormasDePagoListItem>
      </v-expansion-panels>
    </v-row>
   
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ContabilidadFormasDePagoListItem from "@/components/contabilidad/formasDePago/ContabilidadFormasDePagoListItem.vue";

export default {
  components: {
    ContabilidadFormasDePagoListItem,
  },
  props: {
    formasDePago: {
      type: Array,
      required: true,
      default: () => [],
    },
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  methods: {
    ...mapActions("contabilidad", ["fetch_rubro_ventas", "eliminar_rubro"]),
    goToEditar(rubro) {
      this.$router.push({
        name: "rubroVentaUpdate",
        params: { rubroVenta: rubro },
      });
    },
    quitarFormaDePago(id) {
      // Eliminar la forma de pago de la lista local (opcional, si manejas una lista local)
      this.formasDePago = this.formasDePago.filter(
        (fp) => fp.id !== id
      );
    },
    fetchRubroVentas() {
      this.fetch_rubro_ventas();
    },
  },
  created() {
    this.fetchRubroVentas();
  },
};
</script>