<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Asientos tipo</div>
      </v-col>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-title>
            <v-text-field
              rounded
              outlined
              style="background-color: #3a3b3c"
              dense
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar asiento"
              single-line
              hide-details
              autofocus
            ></v-text-field>
          </v-list-item-title>
        </v-list-item>
      </v-col>
      <v-col cols="12">
        <v-list>
          <v-list-item @click="$router.push({ name: 'asientoTipoRegistrar' })">
            <v-list-item-icon>
              <v-icon>mdi-book-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Nuevo asiento tipo </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <AsientoTipoList></AsientoTipoList>
  </v-container>
</template>

<script>
import AsientoTipoList from "@/components/contabilidad/asiento/asientotipo/AsientoTipoList.vue";

export default {
  name: "AsientoTipoView",
  components: {
    AsientoTipoList,
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>
