var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_c('h2',[_vm._v(_vm._s(_vm.fechaInicioActividad(_vm.arqueo)))])])],1)],1)],1),_c('div',{staticClass:"text-h5"},[_vm._v(" Detalle de ventas ")]),_c('ventas-por-forma-de-pago',{attrs:{"ventas":_vm.arqueo.ventas}}),_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{staticClass:"pa-0",attrs:{"color":"transparent","href":"#ventas"}},[_c('div',{staticClass:"text-tab"},[_vm._v("Ventas")])]),_c('v-tab',{staticClass:"pa-0",attrs:{"href":"#unidadesVendidas"}},[_c('div',{staticClass:"text-tab"},[_vm._v("Anuladas")])])],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"id":"ventas"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ventas,"items-per-page":5,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.estado),"dark":""}},[_vm._v(" "+_vm._s(item.estado ? "Pendiente" : "Facturado")+" ")])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format("lll"))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"min-width":"50px","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"pt-0 mt-0",staticStyle:{"background-color":"grey"},attrs:{"dense":""}},[_c('v-list-item-title',[_c('VentaAnularComprobante',{attrs:{"comprobante":item}})],1)],1),(!item.estado)?_c('v-list-item',{on:{"click":function($event){return _vm.abrirFactura(item)}}},[_c('v-list-item-title',[_vm._v("Factura")])],1):_vm._e()],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])})],1),_c('v-tab-item',{attrs:{"id":"unidadesVendidas"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_anuladas,"items":_vm.anuladas,"items-per-page":5,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.estado),"dark":""}},[_vm._v(" "+_vm._s(item.estado ? "Pendiente" : "Facturado")+" ")])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format("lll"))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"min-width":"50px","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"pt-0 mt-0",staticStyle:{"background-color":"grey"},attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v("Anular")])],1),(!item.estado)?_c('v-list-item',{on:{"click":function($event){return _vm.abrirFactura(item)}}},[_c('v-list-item-title',[_vm._v("Factura")])],1):_vm._e()],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogConsultarPreImpreso),callback:function ($$v) {_vm.dialogConsultarPreImpreso=$$v},expression:"dialogConsultarPreImpreso"}},[(_vm.dialogConsultarPreImpreso)?_c('factura-pre-impresa-consultar',{attrs:{"comprobante":_vm.comprobantePreImpreso},on:{"close-dialog":function($event){(_vm.dialogConsultarPreImpreso = false), (_vm.comprobantePreImpreso = null)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }