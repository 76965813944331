var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.proveedor)?_c('LayoutBasic',{attrs:{"showLinksMenu":false,"routeBack":"/proveedores","width":'400px'},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('v-breadcrumbs',{attrs:{"items":_vm.navigation,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('v-breadcrumbs-item',{attrs:{"exact":"","to":item.to}},[_vm._v(_vm._s(item.text))])}}],null,false,3095512748)})]},proxy:true},{key:"navigation-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.proveedor.nombre)+" ")]},proxy:true},{key:"navigation-subtitle-after",fn:function(){return [(_vm.proveedor.es_empleado)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Empleado")]):_vm._e()]},proxy:true},{key:"navigation-content",fn:function(){return [_c('v-img',{attrs:{"src":_vm.noImage,"lazy-src":("https://picsum.photos/10/6?image=" + (15)),"aspect-ratio":"1","height":"150px","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}),_c('v-divider',{attrs:{"light":""}}),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'proveedorHome' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Perfil")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'editarPerfilProveedor',
            params: { proveedor: _vm.proveedor },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Editar perfil")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'listaDePrecioProveedor',
            params: { proveedor: _vm.proveedor },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Lista de precio")])],1)],1)],1)]},proxy:true},{key:"navigation-append",fn:function(){return [_c('v-container',[_c('v-btn',{attrs:{"color":"red","block":"","small":""},on:{"click":function($event){_vm.dialogDelete = true}}},[_vm._v("Eliminar proveedor")]),(_vm.dialogDelete)?_c('layout-dialog-delete',{on:{"close-dialog":function($event){_vm.dialogDelete = false},"processDelete":_vm.eliminarProveedor},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Eliminar proveedor")]},proxy:true},{key:"message",fn:function(){return [_vm._v(" ¿Desea eliminar el proveedor ' "),_c('i',[_vm._v(_vm._s(_vm.proveedor.nombre))]),_vm._v(" ' ? ")]},proxy:true}],null,false,1233454843)}):_vm._e()],1)]},proxy:true},{key:"vista-previa",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('router-view',{attrs:{"proveedor":_vm.proveedor}})],1)],1)],1)]},proxy:true}],null,false,3777342900)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }