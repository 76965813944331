<template>
  <div>
    <v-row justify="center">
      <v-col cols="6">
        <!-- Campo de entrada para el buscador -->
        <v-text-field
          v-model="search"
          label="Buscar empleado"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6">
        <!-- Lista de empleados -->
        <empleados-list
          :loading="loadingEmpleados"
          :empleados="empleadosFilter"
          @seleccion-empleado="handleSeleccionarEmpleado"
        ></empleados-list>
      </v-col>
       <v-col cols="6">
        <!-- Lista de empleados -->
        <empleados-list
          :loading="loadingEmpleados"
          :empleados="empleadosFilter"
          @seleccion-empleado="handleSeleccionarEmpleado"
        ></empleados-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmpleadosList from "@/components/empleado/empleado.list.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "EmpleadosSeleccionar",
  components: {
    EmpleadosList,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState("empleado", ["empleados", "loadingEmpleados"]),
    empleadosFilter() {
      let empleados = this.empleados;
      if (this.search) {
        empleados = this.empleados.filter(
          (u) => u.nombre.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      }
      return empleados;
    },
  },
  methods: {
    ...mapActions("empleado", ["fetch_empleados"]),
    handleSeleccionarEmpleado(empleado) {
        alert("Hola empleado:",empleado.nombre)
      // Emitir el evento para que el componente padre maneje la navegación
    },
  },
  created() {
    this.fetch_empleados(true);
  },
};
</script>

<style lang="scss" scoped>
/* Estilos personalizados si es necesario */
</style>
