<template>
  <v-container fluid fill-height class="pa-0">
    <v-row class="fill-height ma-0">
      <v-col cols="3" class="pa-0">
        <v-card width="380" height="100%">
          <v-container fluid class="pr-0">
            <slot name="breadcrumbs"></slot>

            <v-list class="ma-0 pa-0">
              <v-list-item class="pl-1">
                <v-list-item-avatar class="ma-1">
                  <v-btn icon @click="goBack">
                    <v-icon large>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-title>
                  <h1>
                    <slot name="title">Pedidos</slot>
                  </h1>
                </v-list-item-title>
              </v-list-item>
              <slot name="isBorrador"> </slot>

              <slot name="subtitle"></slot>
            </v-list>
            <v-divider></v-divider>

            <v-container class="items3" fluid>
              <div class="items3 detalle">
                <div>
                  <slot name="navigation-controller"></slot>
                </div>
              </div>
              <v-divider></v-divider>
              <div>
                <slot name="button-navigation-controller"></slot>
              </div>
            </v-container>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="9">
        <v-row class="fill-height">
          <v-col cols="12">
            <v-card height="100%">
              <slot name="vista-previa"></slot>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "layout.comprobante.consultar",

  props: {
    widthNavigation: {
      type: String,
      default: "350",
    },
  },

  data() {
    return {};
  },

  methods: {
    goBack() {
      var path = "";
      if (this.$route.name === "pedidoVenta" || this.$route.name === "pedidoCompra") {
        path = this.$route.name === "pedidoVenta" ? "ventas" : "compras";
        this.$router.push({ name: path });
      } else if (
        this.$route.name === "nuevoPedidoVenta" ||
        this.$route.name === "nuevoPedidoCompra"
      ) {
        path = this.$route.name === "nuevoPedidoVenta" ? "pedidoVenta" : "pedidoCompra";
        this.$router.push({ name: path });
      } else if (this.$route.name === "detalleVenta") {
        this.$router.push({ name: "ventas" });
      } else {
        this.$router.push("/home");
      }
    },
    // goToRoute(route) {
    //   this.$router.push({ name: route }).catch(() => {});
    // },
  },
};
</script>

<style scoped>
.element {
  height: 100%;
  top: 0px;
  transform: translateX(0%);
  width: 350px;
}
.items3 {
  display: block;
  right: 0px;
  height: 100%;
}

.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  background: #869;
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
.items3.detalle {
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
