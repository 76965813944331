<template>
  <v-card>
    <cliente-direcciones-form
      :direccion="direccion"
      :isUpdate="true"
      @close-dialog="$emit('close-dialog')"
      @process-save="actualizarDireccion"

    ></cliente-direcciones-form>
  </v-card>
</template>

<script>
import ClienteDireccionesForm from "@/components/cliente/direcciones/cliente.direccion.form.vue";

import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";


export default {
  name: "DireccionUpdate",
  components: {
    ClienteDireccionesForm,
  },
  props: {
    direccion: {
      type: Object,
      required: true,
    },
     cliente_id: {
      required: true
    }
   
  },
  
  methods: {
    actualizarDireccion(direccion) {
      direccion.cliente = this.cliente_id
      console.log(direccion.localidad);
      console.log(direccion.barrio);

      if (direccion.localidad && direccion.localidad.id){
        direccion.localidad =  direccion.localidad.id
      }
      if (direccion.barrio && direccion.barrio.id){
        direccion.barrio = direccion.barrio.id 
      }
    


      ClienteDireccionesAPI.update(direccion).then((response) => {
            this.$emit("updated-direccion", response.data)
            this.$emit('refresh-direcciones')
            this.$emit('close-dialog')
           this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Dirección actualizada`,
            icon: "mdi-check",
            timeout: 2500,
          });
      });
    },
  },

 
};
</script>

<style lang="scss" scoped></style>
