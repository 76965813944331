<template>
<v-card flat color="transparent">
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            append-icon="mdi-home-variant"
            outlined
            label="Nombre sucursal"
            autofocus
            v-model="sucursal.nombre"
            v-validate="'required|max:30'"
            :error-messages="errors.collect('nombre sucursal')"
            :counter="30"
            name="nombre sucursal"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            append-icon="mdi-phone"
            v-model="sucursal.telefono"
            label="Teléfono"
            name="telefono"
            v-validate="'required|numeric|min:7|max:30'"
            :error-messages="errors.collect('telefono')"
            :counter="30"
          />
        </v-col>
        <v-col cols="12">
          <CondicionIvaVSelect :entity="sucursal"></CondicionIvaVSelect>
        </v-col>

        <!-- <v-col cols="12">
          <v-text-field
            outlined
            label="Encargado"
            append-icon="mdi-account-star"
            v-model="sucursal.encargado"
            v-validate="'required|max:30'"
            :error-messages="errors.collect('encargado')"
            :counter="30"
            name="encargado"
          />
        </v-col> -->

        <v-col cols="12">
          <v-text-field
            outlined
            append-icon="mdi-map-marker"
            v-model="sucursal.direccion"
            label="Dirección"
            name="Dirección"
            v-validate="'required|max:25'"
            :error-messages="errors.collect('Direccion')"
            :counter="25"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            append-icon="mdi-chat"
            v-model="sucursal.telegram_chat_id"
            label="Telegram ID"
            name="Telegram ID"
            v-validate="'numeric|min:7|max:17'"
            :error-messages="errors.collect('Telegram ID')"
            :counter="30"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            outlined
            v-model="id_provincia"
            :items="provincias"
            value="nombre"
            item-text="nombre"
            item-value="id"
            v-validate="'required'"
            label="Selecciona una Provincia"
            name="Provincia"
            @change="fetchLocalidades"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="sucursal.localidad.id"
            no-data-text="Sin localidades"
            value="nombre"
            outlined
            :items="localidades"
            item-text="nombre"
            item-value="id"
            v-validate="'required'"
            label="Selecciona una Localidad"
            name="Localidad"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row justify="end" class="mr-2">
        <v-btn
          color="green"
          :loading="loading"
          @click="validateForm"
          :disabled="errors.any()"
        >{{ textButton }}</v-btn>
      </v-row>
    </v-card-actions>
  </v-container>
</v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";
import CondicionIvaVSelect from "@/components/util/CondicionIvaVSelect.vue";

export default {
  name: "sucursal.form",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
    CondicionIvaVSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      localidades: [],
      id_provincia: null,
      loading: false,
    };
  },

  props: {
    sucursal: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },
        isUpdate: {
      type: Boolean,
      default: false,
    },

    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },
  },

  methods: {
    async fetchLocalidades(id_prov) {
      try {
        const localidades = await this.axios.get(
          `/soporte/provincias/${id_prov}/localidades`
        );

        this.localidades = localidades.data;
      } catch (error) {}
    },

    validateForm() {
     this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-sucursal", this.sucursal);
        } else {
          this.$validator.validate();
        }
      });
    },
  },
 created() {
    if (this.isUpdate) {
      console.log(this.isUpdate);
      if (this.sucursal.localidad) {
        this.id_provincia = this.sucursal.localidad.provincia.id;
        this.fetchLocalidades(this.id_provincia);
      } else {
        this.sucursal.localidad = { id: null };
      }
    }
},
};
</script>

<style lang="scss" scoped></style>
