import axios from 'axios'
const url = '/proveedores/listasdeprecios'

export default {
    index() {
        return axios.get(url)
    },


    show(id) {
        return axios.get(`/proveedores/${id}/listadeprecio`)
    },

    create(data) {

        return axios.post(`${url}`, data)
    },
    subirListaDePrecios(data){
        console.log("subirListaDePrecios", data);
        return axios.post('/proveedores/listasdeprecios/upload', data)

    },
    fetchListaDePrecioHistorial(dataObject){
        console.log(dataObject);
        console.log(dataObject.pagination);
        var url = `/proveedores/${dataObject.proveedor_id}/listadeprecio/historial?page=${dataObject.pagination.page}&page_size=${dataObject.pagination.rowsPerPage}`;
        return axios.get(url)
    },

    subirLista(form_data) {
        return axios.post("/productos/importararchivo", form_data)
    },

    get_direccion_favorita(cliente_id) {
        return axios.get(`/clientes/${cliente_id}/direcciones/favorita`)

    },
    set_favorita(cliente_id, direccion_id) {
        var data = {
            d: direccion_id,
        };
        return axios.post(`/clientes/${cliente_id}/direcciones/favorita`, data)

    },


    delete(data) {
        console.log(data);
        return axios.delete(`${url}/${data[0].id}`)


    },
    deleteMultiple(id, data) {
        return axios.post(`${url}/delete`, data)


    },

}