<template>
  <div>
    <v-text-field
      :prefix="producto.ganancia ? '%' : ''"
      v-model="producto.ganancia"
      v-validate="'required|decimal:2|max:30'"
      :error-messages="errors.collect('ganancia')"
      data-vv-name="ganancia"
      type="number"
      label="Ganancia"
      required
      :outlined="outlined"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.ganancia",
  inject: ["$validator"],
  components: {},
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
