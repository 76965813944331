<template>
  <ProductoLayoutList>

    <template v-slot:list>
      <v-container fluid>
      <v-data-table
      :loading="loading"
      :headers="headers"
      :items="productos"
      :search="search"
      item-key="id"
      show-select
      v-model="selected"
      :hide-default-footer="true"
      disable-pagination
      no-data-text="No se encontraron productos"
      no-results-text="Sin coincidencia para la búsqueda"
      :calculate-widths="true"
    >
      <template v-slot:top>
        <v-toolbar :color="selected.length ? 'grey darken-4' : '#8A9597'" dark>
          <v-btn v-if="selected.length" icon @click="selected = []">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>{{
            selected.length ? `${selected.length} seleccionados` : "Listado de productos"
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <slot name="search"></slot>
        

          <v-spacer></v-spacer>

          

          <slot :selected="selected"></slot>
        </v-toolbar>
      </template>

      <template v-slot:headers="props">
        <tr>
          <th>
            <v-checkbox
              :input-value="props.all"
              :indeterminate="props.indeterminate"
              primary
              hide-details
              @click.stop="toggleAll"
            ></v-checkbox>
          </th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
            ]"
            @click="changeSort(header.value)"
          >
            <v-icon small>arrow_upward</v-icon>
            <h2>{{ header.text }}</h2>
          </th>
        </tr>
      </template>
      <template v-slot:item.cantidadstock="{ item }">
        <v-chip :color="getColor(item)" dark>
          {{ item.cantidadstock }}
        </v-chip>
      </template>
      </v-data-table>
      </v-container>
    </template>
  </ProductoLayoutList>
  
   
    
</template>

<script>
import { mapState } from "vuex";
import ProductoLayoutList from "./ProductoLayoutList.vue";
export default {
  name: "produtos.list.table",
  components: {ProductoLayoutList},
  props: {
    productos: {
      type: Array,
      required: true,
      default: () => [],
    },
  
    verBuscador: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selected: [],

    search: "",
    openSearch: false,
    loading: false,

    filtros: null,
  }),
  computed: {
    ...mapState("sucursales", ["configuracion"]),
      selectedProduct: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('process-productos-selected', val)
        return 
      },
    },
   
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
    headers() {
      if (this.configuracion.productoInventario) {
        return [
          {
            text: "Código",
            value: "codigo",
            align: "center",
          },
          {
            text: "Descripcion",
            value: "descripcion",
            align: "center",
            width: '300px'
          },
          {
            text: "Stock",
            value: "cantidadstock",
            align: "center",
          },
          {
            text: "Marca",
            value: "marca.nombre",
            align: "center",
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            align: "center",
          },
          {
            text: "P. Costo",
            value: "precioCosto",
            align: "center",
          },
          {
            text: "P. Venta",
            value: "precioVenta",
            align: "center",
          },
          {
            text: "Ganancia",
            value: "ganancia",
            align: "center",
          },
          {
            text: "Flete",
            value: "flete",
            align: "center",
          },
          {
            text: "IVA",
            value: "iva",
            align: "center",
          },
         
        ];
      } else {
        return [
          {
            text: "Código",
            value: "codigo",
            align: "center",
          },
          {
            text: "Descripcion",
            value: "descripcion",
            align: "center",
          },
          {
            text: "Marca",
            value: "marca.nombre",
            align: "center",
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            align: "center",
          },
          {
            text: "P. Costo",
            value: "precioCosto",
            align: "center",
          },
          {
            text: "P. Venta",
            value: "precioVenta",
            align: "center",
          },
          {
            text: "Ganancia",
            value: "ganancia",
            align: "center",
          },
          {
            text: "Flete",
            value: "flete",
            align: "center",
          },
          {
            text: "IVA",
            value: "iva",
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    cleanSelected() {
      this.selected = [];
    },
    getColor(producto) {
      var cantidadstock = parseInt(producto.cantidadstock);

      var stockmin = parseInt(producto.stockmin);

      var stockmax = parseInt(producto.stockmax);

      var stockPromedio = (stockmin + stockmax) / 2;

      var stockLimiteAprobado = stockmin + stockmin * 0.2;
      console.log("cantidadstock", cantidadstock);
      console.log("stockLimiteAprobado", stockLimiteAprobado);

      if (cantidadstock > stockLimiteAprobado) return "green";
      else if (stockLimiteAprobado < cantidadstock && cantidadstock > stockmin) return "orange";
      else return "red";
    },
   
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.productos.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    verificarCantidadSeleccionada() {
      let selected = this.selected;
      if (this.selected.length == 0) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "warning",
          text: "Debe seleccionar un producto",
          icon: "new_releases",
          timeout: 3000,
        });
      } else {
        return true;
      }
    },

    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style >
.v-datatable thead {
  background-color: rgb(190, 65, 65);
}


.table-scroll-wrapper {
    overflow: auto;
    border: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll {
    margin: 0px;
    border: none;
}

.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-scroll tr td:first-child,
.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  width:120px;
  left: 0;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:first-child {
    z-index: 3;
}

.table-scroll tr td:nth-child(2),
.table-scroll th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 64px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
    background: #1E1E1E;

}

.table-scroll th:nth-child(2) {
    z-index: 4;
}


.table-scroll tr td:nth-child(3),
.table-scroll th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  width: 200px;

  left: 134px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
    background: #1E1E1E;

}

.table-scroll th:nth-child(3) {
    z-index: 4;
}



</style>
