<template>
<v-card>

<v-container>

    <div>Ventas por hora</div>
    <div class="title font-weight-black">
        {{chartData.total}}

    </div>
    <v-row class="mt-4 ">
      <v-col cols="12" md="8">
        <!-- <DatePickerMultiple @process-select="fetchVentasPorHora" /> -->
        <bar-chart
          v-if="!loading"
          :chartdata="chartData"
          :options="chartOptions"
        />
         <v-progress-circular
         v-else
              width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
      </v-col>
    </v-row>
</v-container>
</v-card>


</template>

<script>
import BarChart from "@/chartjs/bar.chart.vue";
import ReportesAPI from "@/api/reportes";
import DatePickerMultiple from "@/components/reportes/DatePickerMultiple";

export default {
  name: "reporte.ventas",
  components: {
    BarChart,
    DatePickerMultiple,
  },
  props: {
    dateFilter: {
      type: String,
      required: true,

    }
  },
  data() {
    return {
      ventasPorHora: null,
      loading: false,
      chartData: {
        labels: [],
        datasets: [],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
      },
    };
  },
  methods: {
    fetchVentasPorHora(filter) {
      this.loading = true;
      ReportesAPI.getReporteVentaPorHora(filter).then((response) => {
        this.chartData = response.data
        this.loading = false;
      }).catch(e => {
        console.log(e);
      })
    },
  },
  watch: {
    dateFilter(val){
      this.fetchVentasPorHora(val)
    }
  },
  created(){
    this.fetchVentasPorHora(this.dateFilter)
  }
};
</script>

<style lang="scss" scoped></style>
