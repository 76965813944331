<template>
  <v-container v-if="proveedor">
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Perfil</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-img contain :src="noImage" height="200px"></v-img>
          <v-card-title class="cyan darken-1">
            <span class="text-h5 white--text"
              >{{ proveedor.nombre }}</span
            >

            <v-spacer></v-spacer>
          </v-card-title>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ proveedor.telefono }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-email</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{
                  proveedor.email || "-"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title v-if="proveedor.localidad"
                  >{{ proveedor.localidad.nombre }},
                  {{ proveedor.localidad.provincia.nombre }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <v-card-actions>
            <v-btn
              @click="$router.push({ name: 'editarPerfilProveedor' })"
              large
              rounded
              outlined
              >Editar perfil</v-btn
            >
          </v-card-actions>
          <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-btn rounded outlined>Editar perfil</v-btn></v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import { fetchProveedor } from "@/mixins/proveedor/fetchProveedor";

export default {
  name: "proveedor-home",
  components: {
    LayoutBasic,
  },
  props: {
    proveedor:{
      required: true,
      type: Object
    }
  },

  data() {
    return {
      noImage: require("@/assets/man-woman.jpg"),
    };
  },

  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
