<template>
  <v-expansion-panels hover flat>
    <v-expansion-panel class="transparent">
      <v-expansion-panel-header class="pa-0">
        <v-list-item two-line dense>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-map-marker</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ direccion }} </v-list-item-title>
            <v-list-item-subtitle>Dirección </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <template v-slot:actions>
            <v-icon color="primary">
              mdi-information
            </v-icon>
          
          </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Depto y piso -->
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Depto: {{ depto }} Piso: {{ piso }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Barrio  -->
        <v-list-item two-line class="pt-0" dense v-if="barrio">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-home-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ barrio }}</v-list-item-title>
            <v-list-item-subtitle>Barrio</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- Observacion envio  -->
        <v-list-item class="pt-0" dense v-if="observacionEnvio">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-message-bulleted</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-title v-on="on"
                  >{{ observacionEnvio }}
                </v-list-item-title>
              </template>
              <span>{{ observacionEnvio }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "VentaClienteEnvioInfo",
  props: {
    formEnvio: {
      type: [Object, null],
    },
    cliente: {
      type: [Object, null],
    },
    paraEnviar: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    direccion() {
      if (this.paraEnviar) {
        if (this.formEnvio) {
          return `${this.formEnvio.calle || ""} ${
            this.formEnvio.altura || ""
          }`.trim();
        }
        if (this.cliente && this.cliente.direccion) {
          return `${this.cliente.direccion.calle || ""} ${
            this.cliente.direccion.altura || ""
          }`.trim();
        }
      }
      return "";
    },
    barrio() {
      if (this.paraEnviar) {
        if (this.formEnvio && this.formEnvio.barrio) {
          return this.formEnvio.barrio.nombre;
        }
        if (
          this.cliente &&
          this.cliente.direccion &&
          this.cliente.direccion.barrio
        ) {
          return this.cliente.direccion.barrio.nombre;
        }
      }
      return "";
    },
    depto() {
      if (this.paraEnviar) {
        if (this.formEnvio) {
          return this.formEnvio.depto || "";
        }
        if (this.cliente && this.cliente.direccion) {
          return this.cliente.direccion.depto || "";
        }
      }
      return "";
    },
    piso() {
      if (this.paraEnviar) {
        if (this.formEnvio) {
          return this.formEnvio.piso || "";
        }
        if (this.cliente && this.cliente.direccion) {
          return this.cliente.direccion.piso || "";
        }
      }
      return "";
    },
    observacionEnvio() {
      if (this.paraEnviar) {
        if (this.formEnvio && this.formEnvio.observacionEnvio) {
          return this.formEnvio.observacionEnvio;
        }
        if (
          this.cliente &&
          this.cliente.direccion &&
          this.cliente.direccion.observacionEnvio
        ) {
          return this.cliente.direccion.observacionEnvio;
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
