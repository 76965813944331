<template>
  <div>
    <div class="d-flex flex-column">
      <div class="two-colors">
        <v-row justify="center">
          <v-col cols="12" lg="8" md="10">
            <div class="h-100 d-flex flex-column">
              <div class="justify-content-center" v-if="!arqueoCaja">
                <v-row>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Sucursal</h4>
                      <h3>
                        <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                        {{ sucursal.direccion }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Caja</h4>
                    </div>
                  </v-col>
                </v-row>
                <v-card shaped class="mt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-alert>Selecciona la caja</v-alert>

                        <CajaList></CajaList>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
              <div class="justify-content-center" v-if="arqueoCaja">
                <v-row>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Sucursal</h4>
                      <h3>
                        <v-icon>mdi-map-marker</v-icon>{{ sucursal.nombre }} -
                        {{ sucursal.direccion }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" lg="6">
                    <div class="info-cont flex-grow-1 text-center text-white">
                      <h4 class="">Caja</h4>
                      <h3>
                        <v-icon>mdi-desktop-classic</v-icon>
                        {{ caja.nombre }}
                      </h3>
                    </div>
                  </v-col>
                </v-row>
                <v-card shaped class="mt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" lg="6">
                        <h2 class="mb-3 mt-4 text-left text-white">
                          Información de la caja
                        </h2>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">

                        <h4 class="mb-3 mt-4 text-right text-white">
                          {{ moment().format("ll") }} {{ horaActual }}
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-n3">
                      <v-col cols="12" md="6" lg="6">
                        <v-list subheader two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon dark> mdi-calendar-check </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >Inicio de caja</v-list-item-title
                              >

                              <v-list-item-subtitle>
                                {{ fechaInicioActividad }}</v-list-item-subtitle
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon>
                                <v-icon color="grey lighten-1"
                                  >mdi-information</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon dark> mdi-cash </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >Saldo inicial</v-list-item-title
                              >

                              <v-list-item-subtitle>
                                {{
                                  arqueoCaja.montoInicial
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon>
                                <v-icon color="grey lighten-1"
                                  >mdi-information</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon dark> mdi-calendar-check </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >Tiempo transcurrido</v-list-item-title
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              {{ tiempoTranscurrido }}
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-row justify="end" class="ma-n3 fill-height">
                          <v-col cols="6">
                            <v-card
                              color="teal darken-3"
                              @click="dialogIngresarDinero = true"
                              class="d-flex flex-column align-center justify-center"
                            >
                              <v-icon x-large>mdi-cash-plus</v-icon>
                              <h3 class="white-text">Ingreso de dinero</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="6">
                            <v-card
                              color="red darken-3"
                              @click="$router.push('/movimientos/retiro')"
                              class="d-flex flex-column align-center justify-center"
                            >
                              <v-icon x-large>mdi-cash-minus</v-icon>
                              <h3 class="white-text">Retiro de dinero</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="6" v-show="isPermisoDueño">
                            <v-card class="">
                              <v-card-title> Ingresos </v-card-title>
                              <v-card-text>
                                <div class="title text-center">
                                  ${{ totalIngresos }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="6" v-show="isPermisoDueño">
                            <v-card class="">
                              <v-card-title> Egresos </v-card-title>
                              <v-card-text>
                                <div class="title text-center">
                                  ${{ totalEgresos }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>

                          <v-col cols="12" v-show="isPermisoDueño">
                            <v-card color="teal darken-3">
                              <v-card-title
                                >Saldo en caja <v-spacer></v-spacer>
                                <span class="display-1"
                                  >${{ arqueoCaja.saldoServer }}</span
                                >
                              </v-card-title>
                            </v-card>
                          </v-col>
                          
                          <v-col>
                            <div class="align-end">
                              <v-btn
                                color="success"
                                @click="dialogCerrarCaja = true"
                                large
                                block
                                ><v-icon>mdi-desktop-classic</v-icon>
                                <div class="title">Cerrar la caja</div>
                              </v-btn>
                              <dialog-confirmation-close
                                v-if="dialogCerrarCaja"
                                @close-dialog="dialogCerrarCaja = false"
                                @process-accept="openCorteCaja"
                              >
                                <template v-slot:titulo
                                  >¿Deseas cerrar la caja?</template
                                >
                                <template v-slot:message
                                  >¿Seguro que quieres cerrar la caja? No podrás
                                  volver atrás.</template
                                >
                                <template v-slot:buttonOK
                                  >Cerrar la caja</template
                                >
                              </dialog-confirmation-close>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogExtraerDinero" width="400">
          <ingreso-egreso-dinero
            v-if="dialogExtraerDinero"
            :esIngreso="false"
            @close-dialog="dialogExtraerDinero = false"
            @process-dinero="extraerDinero"
          >
          </ingreso-egreso-dinero>
        </v-dialog>
        <v-dialog v-model="dialogIngresarDinero" width="600">
          <ingreso-egreso-dinero
            v-if="dialogIngresarDinero"
            :esIngreso="true"
            @close-dialog="dialogIngresarDinero = false"
            @process-dinero="ingresarDinero"
          >
          </ingreso-egreso-dinero>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import IngresoEgresoDinero from "@/views/actividad/ingreso.egreso.dinero.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import ContarBilletes from "@/views/actividad/contar.billetes.vue";
import { mapState, mapActions } from "vuex";
import { getStorage } from "@/services/serviceLocalStorage";
import CajaList from "@/components/sucursal/caja/CajaList.vue";

export default {
  name: "arqueo.movimientos.vue",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    IngresoEgresoDinero,
    ContarBilletes,
    Loading,
    DialogConfirmationClose,
    CajaList,
  },

  data() {
    return {
      dialogCerrarCaja: false,
      tiempoTranscurrido: "",
      horaActual: "",
      dialogIngresarDinero: false,
      dialogExtraerDinero: false,
      verResultado: false,
      comentarios: "",
      totalEfectivoCaja: 0,
      billetes: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["group"]),
    ...mapState("sucursales", ["sucursal", "caja", "arqueoCaja"]),

    isPermisoDueño() {
      if (this.group.includes("esDueño") || this.group.includes("esAdmin")) {
        return true;
      }
      return false;
    },
    diferenciaDeCaja() {
      return Math.round(this.arqueoCaja.saldoServer - this.totalEfectivoCaja);
    },
    fechaInicioActividad() {
      return this.moment(this.arqueoCaja.fechaInicio).format("LLL");
    },
    totalIngresos() {
      return Math.round(this.arqueoCaja.totalVentas + this.arqueoCaja.ingresos);
    },
    totalEgresos() {
      return Math.round(this.arqueoCaja.egresos);
    },
    saldo() {
      return Math.round(
        this.arqueoCaja.montoInicial + this.totalIngresos - this.totalEgresos
      );
    },

    caja_id() {
      return this.$store.getters["sucursales/caja_id_cookie"];
    },
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    ...mapActions("sucursales", ["fetchArqueoVigente"]),
    verResultadoCaja() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.verResultado = true;
      }, 3000);
    },
    setDinero(billetes) {
      this.billetes = billetes;
      var saldo = 0;
      billetes.forEach((element) => {
        if (element.cantidad !== null) {
          saldo += element.cantidad * parseInt(element.valorBillete);
        }
      });
      this.totalEfectivoCaja = saldo;
    },
    extraerDinero(dinero) {
      var extraccion = parseFloat(dinero);
      this.arqueoCaja.egresos += extraccion;
      this.dialogExtraerDinero = false;
      this.getArqueoCaja();
    },
    ingresarDinero(dinero) {
      var ingreso = parseFloat(dinero);

      this.arqueoCaja.ingresos += ingreso;
      this.dialogIngresarDinero = false;
      this.getArqueoCaja();
    },
    openCorteCaja() {
      localStorage.setItem("cerrarCaja", true);

      this.$router.push("/cerrarCaja");
    },

  
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });
    this.fetchArqueoVigente();

    setInterval(() => {
      this.horaActual = this.moment().format("LTS");
    }, 1000);
  },
};
</script>

<style scoped>
.two-colors {
  background: #afcb08;
  background: linear-gradient(
    180deg,
    #afcb08 0,
    #afcb08 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );
}
</style>
