var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',[_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text title",attrs:{"large":"","block":"","color":"yellow"}},'v-btn',attrs,false),on),[_vm._v(" Productos ")])]}}]),model:{value:(_vm.dialogProductos),callback:function ($$v) {_vm.dialogProductos=$$v},expression:"dialogProductos"}},[_c('v-card',[_c('v-container',[_c('agregar-detalle-por-categoria',{attrs:{"detalles":_vm.detalles,"isUpdate":_vm.isUpdate}})],1)],1)],1)],1),_c('v-col',[_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text title",attrs:{"large":"","block":"","color":"red"}},'v-btn',attrs,false),on),[_vm._v(" Promociones ")])]}}]),model:{value:(_vm.dialogPromocion),callback:function ($$v) {_vm.dialogPromocion=$$v},expression:"dialogPromocion"}},[_c('v-card',[_c('v-container',[_c('v-card-text',[(_vm.dialogPromocion)?_c('agregar-detalle-por-promocion',{attrs:{"isUpdate":_vm.isUpdate,"detalles":_vm.detalles}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',[_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text title",attrs:{"large":"","block":"","color":"purple"}},'v-btn',attrs,false),on),[_vm._v(" Vianda ")])]}}]),model:{value:(_vm.dialogVianda),callback:function ($$v) {_vm.dialogVianda=$$v},expression:"dialogVianda"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"display-1"},[_vm._v("Viandas")])]),_c('v-card-text',[(_vm.dialogVianda)?_c('agregar-detalle-por-vianda',{attrs:{"isUpdate":_vm.isUpdate,"detalles":_vm.detalles}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }