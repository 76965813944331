<template>
  <div>
    <v-row>
      <v-col>
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">
                  Listado de empleados
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  Aquí encontrarás la lista de empleados asociados a esta sucursal.
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="$router.push( {name: 'sucursalEmpleadosRegistrar'})">
                Registrar empleado
                <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Empleados de mi sucursal</h3>
        <v-list>
          <v-list-item-group v-if="empleados_sucursal.length > 0">
            <v-list-item  v-for="empleado in empleados_sucursal" :key="empleado.id">
              <v-list-item-avatar>
                <v-icon dark>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ empleado.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ empleado.apellido }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="gestionarEmpleado(empleado, 'quitar')">
                  Remover
                  <v-icon small color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-else>
            <v-list-item-content>No se encontraron empleados en esta sucursal</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col>
        <h3>Todos los empleados de la empresa</h3>
        <v-list>
          <v-list-item-group v-if="empleados_empresa.length > 0">
            <v-list-item  v-for="empleado in empleados_empresa" :key="empleado.id">
              <v-list-item-avatar>
                <v-icon dark>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ empleado.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ empleado.apellido }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="gestionarEmpleado(empleado, 'asignar')" >
                  Asignar
                  <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-else>
            <v-list-item-content>No se encontraron empleados en la empresa</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-dialog width="800px" v-model="dialogEmpleados">
      <v-card>
        <v-container>
          <EmpleadosSeleccionar></EmpleadosSeleccionar>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmpleadosSeleccionar from '@/components/empleado/EmpleadosSeleccionar.vue'

export default {
  name: "SucursalEmpleadosList",
  components: {
    EmpleadosSeleccionar
  },
  props: {
    sucursal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      empleados_sucursal: [],
      empleados_empresa: [],
      dialogEmpleados: false
    };
  },
  methods: {
    handleClick(empleado) {
      this.$router.push({
        name: "detallesEmpleado",
        params: { id_empleado: empleado.id },
      });
    },
    async fetchEmpleados(uuid) {
      try {
        const response = await this.axios.get(`/empleados/asignaciones/${uuid}`);
        this.empleados_sucursal = response.data.empleados_sucursal;
        this.empleados_empresa = response.data.empleados_empresa;
      } catch (error) {
        console.error("Error al obtener la lista de empleados", error);
      }
    },
     async gestionarEmpleado(empleado, accion) {
      // Realiza una solicitud POST para asignar o quitar al empleado de la sucursal
      try {
        const response = await this.$axios.post('/empleados/gestionasignaciones', {
          empleado_id: empleado.id,
          accion: accion,
          sucursal_uuid: this.sucursal.uuid,

        });
         this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `${response.data.detail}`,
            icon: "mdi-check",
            timeout: 3000,
          });
        // Actualiza las listas después de la operación
        this.fetchEmpleados(this.sucursal.uuid);
      } catch (error) {
        console.error('Error al gestionar empleado:', error);
        this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${error.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
      }
    },
   
  },
watch: {
  sucursal: {
    immediate: true,
    handler(newSucursal) {
      if (!newSucursal && this.$route.params.uuid_sucursal) {
        // Si no hay 'sucursal', pero hay un 'uuid' en los parámetros de la ruta
        const uuid = this.$route.params.uuid_sucursal;

        // Realiza la solicitud de empleados según el 'uuid'
        this.fetchEmpleados(uuid);
      } else if (newSucursal && newSucursal.uuid) {
        // Si hay 'sucursal', utiliza su 'uuid'
        this.fetchEmpleados(newSucursal.uuid);
      }
    },
  },
},
};
</script>

<style lang="scss" scoped>
/* Estilos personalizados si es necesario */
</style>
