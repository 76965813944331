<template>
  <div>
    <v-container v-if="pedidos.length > 0">
      <lista-pedidos
        v-if="pedidos.length > 0"
        @abrir-vista-previa="abrirPedido"
        :pedidos="pedidos"
      ></lista-pedidos>
      <!-- <v-list class="mt-2 pa-0" v-for="(pedido, i) in pedidos" :key="i">
        <v-list-item @click="abrirPedido(pedido)">
          <v-avatar class="ma-6" size="125" tile>
            <v-icon x-large>mdi-alpha-p-circle</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-subtitle
              class="text--second"
              v-text="pedido.fechaAlta"
            ></v-list-item-subtitle>

            <v-list-item-title
              class="title"
              v-text="pedido.nroPedido"
            ></v-list-item-title>
            <v-list-item-subtitle
              class="title"
              v-text="pedido.getEstadosActual"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              class="subtitle"
              v-text="pedido.proveedor.nombre"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <v-row>
        <v-col>
          <v-pagination
            v-if="pedidos"
            @input="paginationChangeHandler"
            :length="pages"
            :value="paginacion.page"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-img contain height="200" src="@/assets/empty_icons/null_pedidos.svg"></v-img>
      <div class="text-center title" style="line-height: 1.6555">
        No se han encontraron resultados.
      </div>
      <div class="text-center">
        <li>Revisá los filtros aplicados.</li>
      </div>
    </v-container>
  </div>
</template>

<script>
import ListaPedidos from "@/views/pedido/lista/ListaPedidos";
export default {
  name: "pedido.consultar.estado",
  components: {
    ListaPedidos,
  },
  props: {
    estado: {
      type: String,
      default: "finalizado",
    },
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
    pedidos: {
      type: Array,
      required: true,
    },
    paginacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Fecha Alta", value: "fechaAlta" },
        { text: "Ultima mod.", value: "fechaUltModificacion" },
        { text: "Nro pedido", value: "nroPedido" },
        { text: "Estado", value: "getEstadosActual" },
      ],
    };
  },

  computed: {
    estadoPlural() {
      return this.estado + "S";
    },
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      if (this.paginacion.page < pageNumber) {
        this.$emit("set-page", pageNumber);
      } else {
        this.$emit("set-page", pageNumber);
      }
    },
    abrirPedido(pedido) {
      this.$router.push({
        name: "pedidoCompraDetalle",
        params: {
          id: pedido.id,
          estado: this.estado,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
