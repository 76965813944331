<template>
  <div>
    <v-autocomplete
      :label="title"
      :autofocus="false"
      v-model="producto.marca"
      :items="marcas"
      item-text="nombre"
      required
      item-value="id"
      @change="set_select_data"
      @click:clear="clearData()"
      :disabled="disabled"
      :placeholder="placeholder"
      :filled="filled"
      :solo="solo"
      :flat="flat"
      :dense="dense"
      return-object
      :clearable="clearable"
      :outlined="outlined"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "form.producto.marca",
  components: {},
  inject: ["$validator"],
  // $_veeValidate: {
  //   validator: "new",
  // },

  props: {
    producto: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Marca (opcional)",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
  },
  methods: {
    set_select_data(data) {
      this.$emit("set_marca", data);
    },
    clearData() {
      console.log("clear data");
    },
  },
};
</script>

<style lang="scss" scoped></style>
