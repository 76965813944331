import { render, staticRenderFns } from "./compra.detalle.subtotal.vue?vue&type=template&id=639007e7&scoped=true&"
import script from "./compra.detalle.subtotal.vue?vue&type=script&lang=js&"
export * from "./compra.detalle.subtotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639007e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle})
