<template>

    <v-list subheader three-line>
        <v-list-item  v-if="egresos.length === 0">
            <v-list-item-title>
                No hay registros de gastos.
            </v-list-item-title>
        </v-list-item>
        <v-list-item v-else v-for="egreso in egresos" :key="egreso.categoria">

            <v-list-item-avatar>
                <v-icon :color="egreso.color" dark> mdi-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title  v-text="egreso.categoria"></v-list-item-title>
                <v-list-item @click="handleClick(e)" v-for="e in egreso.gastos" :key="e.id">

                    <v-list-item-content>
                        <v-list-item-title v-text="e.tipoIngresoEgreso.descripcion"></v-list-item-title>
                        <v-list-item-subtitle>
                            {{ e.descripcion }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            {{ moment(e.fechaAlta).format("lll") }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-row align="center" justify="end">
                        <span class="subheading mr-2">{{ e.monto | convertPesoArg }}</span>
                        <v-icon class="mr-1" :color="e.tipoIngresoEgreso.descripcion == 'BUZON' ? 'green' : 'red'">{{
                            e.tipoIngresoEgreso.descripcion == "BUZON"
                            ? "mdi-arrow-up-thick"
                            : "mdi-arrow-down-thick"
                        }}</v-icon>
                      
                    </v-row>

                </v-list-item>
            </v-list-item-content>
        </v-list-item>

    </v-list>
</template>

<script>
export default {
    name: 'egresos.list',
    props: {
        egresos: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        handleClick(egreso) {
            this.$emit('process-egreso', egreso)
        }
    }
}
</script>

<style lang="scss" scoped></style>