<template>
  <LayoutBasic v-if="proveedor" :showLinksMenu="false" routeBack="/proveedores" :width="'400px'">

    <template v-slot:breadcrumbs>   <v-breadcrumbs  :items="navigation" divider=">">
                <v-breadcrumbs-item
                  slot="item"
                  slot-scope="{ item }"
                  exact
                  :to="item.to"
                  >{{ item.text }}</v-breadcrumbs-item
                >
              </v-breadcrumbs></template>
    <template v-slot:navigation-title>  {{ proveedor.nombre }}  </template>
    <template v-slot:navigation-subtitle-after>    <v-chip v-if="proveedor.es_empleado" small color="success">Empleado</v-chip>  </template>


    

    
    <template v-slot:navigation-content>
      <v-img
        :src="noImage"
        :lazy-src="`https://picsum.photos/10/6?image=${15}`"
        aspect-ratio="1"
        height="150px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-divider light></v-divider>
      <v-list>
        <v-list-item @click="$router.push({ name: 'proveedorHome' })">
          <v-list-item-icon>
            <v-icon>mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'editarPerfilProveedor',
              params: { proveedor: proveedor },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Editar perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'listaDePrecioProveedor',
              params: { proveedor: proveedor },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Lista de precio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <v-btn color="red" @click="dialogDelete = true" block small
          >Eliminar proveedor</v-btn
        >
        <layout-dialog-delete
          @close-dialog="dialogDelete = false"
          @processDelete="eliminarProveedor"
          v-if="dialogDelete"
        >
          <template v-slot:title>Eliminar proveedor</template>
          <template v-slot:message>
            ¿Desea eliminar el proveedor '
            <i>{{ proveedor.nombre }}</i> ' ?
          </template>
        </layout-dialog-delete>
      </v-container>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <router-view :proveedor="proveedor"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

import { fetchProveedor } from "@/mixins/proveedor/fetchProveedor";

import { mapActions } from "vuex";

export default {
  name: "proveedor-home",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
    LayoutDialogDelete,
  },
  mixins: [fetchProveedor],
  data() {
    return {
      dialogDelete: false,

      noImage: require("@/assets/man-woman.jpg"),
      navigation: [
        {
          text: "Proveedores",
          disabled: false,
          to: { name: "proveedorHome" },
        },
      ],
    };
  },

  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    ...mapActions("proveedor", ["eliminar_proveedor"]),

    eliminarProveedor() {
      this.eliminar_proveedor(this.proveedor.id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Se eliminó el proveedor '${this.proveedor.nombre}'`,
          icon: "mdi-check",
          timeout: 2500,
        });
        this.$router.push("/proveedor");
        this.dialogDelete = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
