<template>
  <v-container>
    <v-row>
      <v-select
        :items="asientosTipo"
        item-text="descripcion"
        item-value="id"
        return-object
        outlined
        label="Seleccionar asiento tipo"
        v-model="selectedAsientoTipo"
        @change="emitSelectedAsientoTipo"
        :error-messages="errorMessages"
      ></v-select>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AsientoTipoListSelect",

  props: {
    asientoTipo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialogEliminar: false,
       selectedAsientoTipo: this.asientoTipo ? this.asientoTipo.id : null,
       errorMessages : []

    };
  },



  computed: {
    ...mapState("contabilidad", ["asientosTipo"]),

  },
  methods: {
    ...mapActions("contabilidad", ["fetch_asientos_tipo"]),


    fetchAsientosTipo() {
      this.fetch_asientos_tipo();
    },
     emitSelectedAsientoTipo(value) {
      this.$emit("select:asiento", value);
    }
  },
  created() {
    this.fetchAsientosTipo();
  },
};
</script>