<template>
      <LayoutBasic :showLinksMenu="false" :routeBack="'/ventas'">

    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{
          item.text
        }}</v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>

    <template v-slot:navigation-title>Pedidos </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goToRoute(item.route)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="borradoresVenta && borradoresVenta.length > 0">
            <v-subheader>Borradores</v-subheader>
            <v-list-item
              v-for="(item, i) in borradoresVenta"
              :key="i"
              @click="setBorradorPedidoVenta(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-delete'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="item.cliente.nombre">{{
                  item.cliente.nombre
                }}</v-list-item-title>
                <v-list-item-title v-else>Borrador sin nombre cliente</v-list-item-title>

                <v-list-item-subtitle>{{
                  moment(item.time).fromNow()
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <v-row>
          <v-col cols="6">
            <pedido-venta-list estado="PENDIENTE"></pedido-venta-list>
          </v-col>
          <v-col cols="6">
            <pedido-venta-list estado="APROBADO"></pedido-venta-list>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <pedido-venta-consultar></pedido-venta-consultar>
          </v-col>
        </v-row> -->
      </v-container>

      <router-view></router-view>
    </template>
      </LayoutBasic>
</template>

<script>
import pedidoVentaList from "@/views/venta/pedidos/pedido.venta.list.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";


import { getStorage } from "@/services/serviceLocalStorage";

export default {
  name: "pedidos.venta",
  components: {
    pedidoVentaList,
    LayoutBasic,
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "ventas",
    },
  },

  data() {
    return {
      item: null,
      pedidos: [],
    };
  },
  computed: {
    borradoresVenta() {
      return getStorage("borradoresPedidosVenta");
    },
    navigation() {
      return [
        {
          text: "Ventas",
          disabled: false,
          to: { name: "ventas" },
        },
        {
          text: "Pedido",
          disabled: false,
          to: { name: "pedidoVenta" },
        },
      ];
    },
    items() {
      return [
        {
          text: "Nuevo pedido",
          icon: "mdi-receipt",
          route: "nuevoPedidoVenta",
        },
        // { text: "Consultar", icon: "mdi-account", route: "#" },
      ];
    },
  },
  methods: {
    setBorradorPedidoVenta(item) {
      var cliente = item.cliente;
      var envio = item.envio;
      var detalles = item.detalles;
      var i = this.borradoresVenta.indexOf(item);

      if (i !== -1) {
        this.borradoresVenta.splice(i, 1);
      }

      localStorage.setItem(
        "borradoresPedidosVenta",
        JSON.stringify(this.borradoresVenta)
      );
      this.$router
        .push({
          name: "nuevoPedidoVenta",
          params: { cliente: cliente, envio: envio, detalles: detalles },
        })
        .catch(() => {});
    },

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    fetchPedidosVenta() {
      this.axios.get(`/pedidos/?esVenta=False`).then((response) => {
        this.pedidos = response.data;
      });
    },
  },
  created() {
    this.$store.commit("SET_LINKS", this.links);
  },
};
</script>

<style lang="scss" scoped></style>
