<template>
  <div>
    <v-dialog v-model="dialogCategoria" width="500">
      <categoria-create
        :categoriaPadre="parent"
        @save-category="registrarCategoria"
        @refresh-categorias="$emit('refresh-categorias')"
        @cerrar-dialog="$emit('close-dialog')"
      ></categoria-create>
    </v-dialog>
  </div>
</template>
<script>
import CategoriaCreate from "@/components/categorias/categoria.create";
import ProductosAPI from "@/api/productos";
export default {
  name: "ProductoCategoriaCreate",
  components: {
    CategoriaCreate,
  },
  props: {
    parent: {
      type: Object,
    },
    dialogCategoria: {
      type: Boolean,
    },
  },
  methods: {
    registrarCategoria(categoria) {
      var data = {
        padre: this.parent.id,
        nombre: [categoria.name],
      };
      ProductosAPI.createCategoria(data)
        .then((response) => {
          this.$emit("refresh-categorias");
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `${categoria.name.toUpperCase()} se ha registrado con éxito`,
            icon: "mdi-hand-okay",
            timeout: 3000,
          });
          this.$emit("close-dialog");
        })
        .catch((e) => {
          console.log(e);
          console.log(e.response);

          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.$emit("close-dialog");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>