<template>
  <v-container>
    <v-menu
      v-model="menu"
      :disabled="false"
      :close-on-content-click="false"
      :nudge-width="150"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-on="on">
          <v-list-item-avatar>
            <v-img avatar src="@/assets/man-woman.jpg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content v-if="cliente">
            <v-list-item-title>
              {{ cliente.nombre }}
              {{ cliente.apellido }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ cliente.telefono }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title
              ><div>Buscar cliente</div></v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>

     <ClienteBuscadorSeleccionar @set-cliente="setCliente"></ClienteBuscadorSeleccionar>
     asfas
    </v-menu>
   
  </v-container>
</template>

<script>
import ClienteDireccionSeleccion from "@/components/cliente/direcciones/cliente.direccion.seleccion.vue";
import ClienteBuscador from "@/components/cliente/cliente.buscador.vue";
import ClienteDireccionCreate from "@/views/cliente/direcciones/cliente.direccion.create.vue";
import ClienteBuscadorSeleccionar from "@/components/cliente/ClienteBuscadorSeleccionar.vue"

import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";

import { mapMutations, mapState } from "vuex";

export default {
  name: "venta.cliente.envio",
  components: {
    ClienteBuscador,
    ClienteDireccionSeleccion,
    ClienteDireccionCreate,
    ClienteBuscadorSeleccionar
  },
  data() {
    return {
      isParaEnviar: false,
      isDialogDirecciones: false,
      loading: false,
      dialogDireccion: false,
      direcciones: [],

      menu: false,
      isValidCliente: true,
    };
  },
  computed: {
    ...mapState("ventas", ["formasDePagoSeleccionadas", "cliente"]),
   
  },
  methods: {
    ...mapMutations("ventas", ["SET_CLIENTE", "SET_CLIENTE_DIRECCION"]),
    clearCliente(){
      this.SET_CLIENTE(null)
      this.isParaEnviar = false


    },
    setCliente(cliente) {
      console.log(cliente);
      this.menu = false;
      this.SET_CLIENTE(cliente);
      this.isParaEnviar = false


    },
    removeDireccion(){
      this.isParaEnviar = false
      this.SET_CLIENTE_DIRECCION(null);
      this.isDialogDirecciones = false;
    },
    setDireccion(direccion) {
      this.SET_CLIENTE_DIRECCION(direccion);
      this.isDialogDirecciones = false;
    },
    setIsValidCliente(val) {
      this.isValidCliente = val;
    },

    getDireccionFavorita() {
      ClienteDireccionesAPI.get_direccion_favorita(this.cliente.id).then(
        (response) => {
          this.setDireccion(response.data);
        }
      );
    },
  },
  watch: {
    isParaEnviar(val) {
      if (val) {
        this.$emit("set-envio", val);
        this.getDireccionFavorita();
      }
      //   this.isDialogDirecciones = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
