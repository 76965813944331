<template>
  <div>
    <v-row v-if="cuentaCorriente.is_habilitado">
      <v-col cols="12" md="4">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle
                class="text-center"
                style="font-size: 16px; line-height: 1.25"
              >
                Total a pagar
              </v-list-item-subtitle>
              <v-list-item-title
                class="mt-2 text-center"
                style="font-weight: 380; line-height: 1; font-size: 35px"
              >
                {{ cuentaCorriente.saldo | convertPesoArg }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn color="#3483fa" :disabled="cuentaCorriente.saldo <= 0" @click="pagarCtaCte" large block
                  ><span style="font-weight: 420; font-size: 18px"
                    >Pagar todo</span
                  ></v-btn
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
     
      <v-col cols="12" md="8">
        <cliente-cuenta-corriente-actividad
        v-if="cuentaCorriente.movimientos.length > 0"
          :movimientos="cuentaCorriente.movimientos"
        ></cliente-cuenta-corriente-actividad>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert>La cuenta corriente está DESHABILITADA</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClienteCuentaCorrienteActividad from "@/components/cliente/cuentaCorriente/ClienteCuentaCorrienteActividad.vue";

export default {
  name: "cliente.cuentacorriente.saldo",
  components: {
    ClienteCuentaCorrienteActividad,
  },
  props: {
    cuentaCorriente: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogCobrar: false,
    };
  },
  methods: {
    pagarCtaCte() {
      this.$emit('process-pagar')
    },
  },
};
</script>

<style lang="scss" scoped>
</style>