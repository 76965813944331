<template>
  <v-autocomplete
    :items="productos"
    color="white"
    append-icon
    filled
    rounded
    ref="searchCmp"
    :loading="isLoading"
    :search-input.sync="inputSearch"
    hide-no-data
    autofocus
    @keyup.enter="seleccionarProducto(inputSearch)"
    persistent-hint
    clearable
    @click:clear="clearData()"
    item-text="descripcion"
    item-value="descripcion"
    height="28"
    prepend-inner-icon="mdi-barcode-scan"
    label="Código de barra"
    id="inputSearch"
    v-validate="'required|integer|max:10'"
    :error-messages="errors.collect('inputSearch')"
    data-vv-name="código"
    cache-items
    @focus.native="$event.target.select()"
  ></v-autocomplete>
</template>

<script>
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";
export default {
  name: "venta.nuevo.detalle.barcode",
  $_veeValidate: {
    validator: "new",
  },

  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,

      productos: [],
      inputSearch: null,
    };
  },
  methods: {
    seleccionarProducto(data) {
      this.fetchProductos("barcode", data);
    },
    getFromSearch(val) {
      var key = "barcode";

      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.fetchProductos(key, val);
        return;
      }
    },

    fetchProductos(key, val) {
      var url = `/productos/p?${key}=${val}&page=1&page_size=10`;
      //El servidor devulve un único producto, o devuelve array vacio
      this.axios
        .get(url)
        .then((request) => {
          if (request.data.results.length > 0) {
            this.agregarDetalle(request.data.results[0]);
          } else {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "info",
              text: "Producto desconocido.",
              icon: "mdi-information",
              timeout: 2000,
              top: false,
              right: false,
            });
            this.inputSearch = null;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    agregarDetalle(producto) {
      var detalle = { cantidad: 1, isProducto: true, precioUnitario: producto.precioVenta, producto: producto };
      var det = { ...detalle };

      var response = addDetalle(this.detalles, det);
      if (response) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "info",
          text: `${response}`,
          icon: "mdi-information",
          timeout: 1500,
        });
      } else {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "success",
          text: "Producto agregado",
          icon: "mdi-check",
          timeout: 1500,
        });
      }

      this.inputSearch = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
