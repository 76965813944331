<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" icon>
          <slot name="icon-btn"> <v-icon>mdi-information-outline</v-icon></slot>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">
          <slot name="icon-title"> <v-icon>mdi-information-outline</v-icon></slot>
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ body }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "information-dialog",
  props: {
    title: {
      type: String,
      required: true,
      default: "Título",
    },
    body: {
      type: String,
      required: true,
      default: "body",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
