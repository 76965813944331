<template>
  <LayoutBasic :showLinksMenu="false">
    <template v-slot:navigation-title>Egresos </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item
            v-for="(item, i) in links"
            :key="i"
            @click="goToRoute(item.route)"
          >
            <v-list-item-icon>
              <v-icon
                :color="item.color ? item.color : ''"
                v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <EgresosList
          @process-egreso="handleClick"
          :egresos="egresos"
        ></EgresosList>
      </v-container>

      <router-view></router-view>
      <v-dialog v-model="dialogVistaPrevia" width="600px">
        <egreso-vista-previa
          v-if="dialogVistaPrevia"
          :egreso="egreso"
          @close-dialog="dialogVistaPrevia = false"
          @process-eliminar-success="fetchEgresos"
        ></egreso-vista-previa>
      </v-dialog>
    </template>
  </LayoutBasic>
</template>
<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import EgresoVistaPrevia from "@/components/egresos/egreso.preview.vue";
import EgresosList from "@/components/egresos/egresos.list.vue";

import { mapState } from "vuex";
export default {
  name: "egreso.home",
  components: {
    LayoutBasic,
    EgresoVistaPrevia,
    EgresosList,
  },
  data() {
    return {
      egresos: [],
      egreso: null,
      dialogVistaPrevia: false,
      fechaPeriodo: new Date().toISOString().substr(0, 7),

      item: null,

      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
    };
  },
  computed: {
    ...mapState("auth", ["group"]),
    links() {
      return [
        {
          text: "Gastos",
          icon: "mdi-plus",
          route: "egresosGastos",
        },
      ];
    },
  },
  methods: {
    quitarEgreso(egreso) {
      alert(quitarEgreso);
    },
    handleClick(egreso) {
      this.egreso = egreso;
      this.dialogVistaPrevia = true;
    },

    async eliminarPromocion(promo) {
      const response = await this.axios.delete(`egresos/${promo.id}`);
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    editarEgreso(item) {},
    eliminarEgreso(item) {},
    verDetalleEgreso(egreso) {
      var id = egreso.id;
      this.$router
        .push({ name: "detallePromocion", params: { id: id } })
        .catch(() => {});
    },
    async fetchEgresos() {
      var response = await this.axios.get(
        `/egresos/gastos?fechaPeriodo=${this.fechaPeriodo}`
      );
      this.egresos = response.data;
    },
  },
  created() {
    this.fetchEgresos()
    if (this.group.includes("esDueño") || this.group.includes("esAdmin")) {
      this.links.push({
        text: "Buzón",
        icon: "mdi-archive",
        route: "buzon",
        color: "green",
      });
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
