<template>
  <div v-if="sucursales.length">
    <v-list v-if="type == 'list'" dense class="ml-2">
      <v-list-item>
        <v-list-item-content>
          <!-- <marca-list-select
            v-show="sucursales.length > 0"
            :sucursales="sucursales"
            @set_marca="setSucursal"
          ></marca-list-select> -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
     <v-list v-if="type == 'list'" dense class="ml-2">
      <v-list-item>
        <v-list-item-content>
          <!-- <marca-list-select
            v-show="sucursales.length > 0"
            :sucursales="sucursales"
            @set_marca="setSucursal"
          ></marca-list-select> -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="type == 'checkbox'" class="ml-4">
      <span class="text-body-2"><b>Sucursales</b></span>
      <v-checkbox
        class="pb-0 pt-0"
        dense
        v-for="(item, i) in sucursales"
        :key="i"
        :label="`${item.nombre}`"
        multiple
        v-model="selected"
        :value="item.nombre"
        @change="setSucursal(item, $event)"
      >
      </v-checkbox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "producto.filtro.sucursal",
  components: {
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["sucursales"]),
  },
  methods: {
        ...mapActions("sucursales", ["fetch_sucursales"]),

   
    setSucursal(sucursal) {
      this.$emit("select-sucursal", sucursal);
    },
     updateUrl() {
      const selectedQueryParam = this.selected.join('|');
      this.$router.replace({ query: { sucursal: selectedQueryParam } }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
  },
   created() {

    this.fetch_sucursales();
  },
};
</script>

<style lang="scss" scoped>
</style>