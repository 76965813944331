<template>
  <v-data-table
    :headers="headers"
    :items="detalles"
    sort-by="cuenta"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Detalles del Asiento Tipo</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo Detalle
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                editedIndex === -1 ? "Nuevo Detalle" : "Editar Detalle"
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <PlanDeCuentaSeleccionar
                      :cuenta="editedItem.cuenta"
                      @set:cuenta="setCuenta"
                    ></PlanDeCuentaSeleccionar>
                    <!-- <v-text-field
                      v-if="editedItem.cuenta"
                      v-model="editedItem.cuenta.nombre"
                      label="Cuenta Contable"
                      :error-messages="cuentaError"
                      disabled
                    ></v-text-field> -->
                    <v-alert v-if="cuentaError" type="error" outlined>
                      {{ cuentaError }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      type="number"
                      v-model="editedItem.debe"
                      label="Debe"
                      :error-messages="debeError"
                      @input="validateDebeHaber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      v-model="editedItem.haber"
                      label="Haber"
                      :error-messages="haberError"
                      @input="validateDebeHaber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      v-model="editedItem.comentario"
                      label="Comentario"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-alert v-if="detallesError" type="error" outlined>
        {{ detallesError }}
      </v-alert>
      No hay detalles registrados.
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <v-row v-if="detalles.length > 0" justify="end" class="mt-4">
        <v-col cols="3">
          <v-list-item class="text-center">
            <v-list-item-content>
              <v-list-item-title>
                {{ totalDebe }}
              </v-list-item-title>
              <v-list-item-subtitle> Debe </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item class="text-center">
            <v-list-item-content>
              <v-list-item-title>
                {{ totalHaber }}
              </v-list-item-title>
              <v-list-item-subtitle> Haber </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item color="" class="text-center">
            <v-list-item-content>
              <v-list-item-title
                :class="diferencia !== 0 ? 'red--text' : 'green--text'"
              >
                {{ diferencia }}
              </v-list-item-title>
              <v-list-item-subtitle> Diferencia </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import PlanDeCuentaSeleccionar from "@/components/contabilidad/PlanDeCuentaSeleccionar.vue";

export default {
  name: "AsientoTipoDetalleForm",
  components: {
    PlanDeCuentaSeleccionar,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        cuenta: null,
        debe: "",
        haber: "",
        comentario: "",
      },
      headers: [
        { text: "Cuenta Contable", align: "start", value: "cuenta.codigo" },
        { text: "Descripción", align: "start", value: "cuenta.nombre" },
        { text: "Debe", value: "debe" },
        { text: "Haber", value: "haber" },
        { text: "Comentario", value: "comentario" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      debeError: "",
      haberError: "",
      cuentaError: "",
      detallesError: "",
    };
  },
  computed: {
    totalDebe() {
      return this.detalles.reduce(
        (acc, detalle) => acc + parseFloat(detalle.debe || 0),
        0
      );
    },
    totalHaber() {
      return this.detalles.reduce(
        (acc, detalle) => acc + parseFloat(detalle.haber || 0),
        0
      );
    },
    diferencia() {
      const diff = this.totalDebe - this.totalHaber;
      return parseFloat(diff.toFixed(2));
    },
  },
  methods: {
    setCuenta(cuenta) {
      if (cuenta) {
        this.editedItem.cuenta = cuenta[0];
        this.cuentaError = "";
        return;
      }
      this.editedItem.cuenta = null;
    },
    editItem(item) {
      this.editedIndex = this.detalles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.detalles.indexOf(item);
      confirm("¿Estás seguro de que quieres eliminar este detalle?") &&
        this.detalles.splice(index, 1);
    },
    close() {
      this.dialog = false;
      this.reset();
    },
    save() {
      if (this.validateForm()) {
        if (this.editedIndex > -1) {
          Object.assign(this.detalles[this.editedIndex], this.editedItem);
        } else {
          this.detalles.push(this.editedItem);
        }
        this.close();
      }
    },
    reset() {
      this.editedItem = {
        cuenta: null,
        debe: "",
        haber: "",
        comentario: "",
      };
      this.editedIndex = -1;
      this.debeError = "";
      this.haberError = "";
      this.cuentaError = "";
      this.detallesError = "";
    },
    validateDebeHaber() {
      console.log("chau 1");
      this.debeError = "";
      this.haberError = "";

      if (this.editedItem.debe && this.editedItem.haber) {
        this.debeError =
          "No puede tener valores en 'Debe' y 'Haber' simultáneamente.";
        this.haberError =
          "No puede tener valores en 'Debe' y 'Haber' simultáneamente.";
        return false;
      }

      if (!this.editedItem.debe && !this.editedItem.haber) {
        this.debeError = "Debe tener un valor en 'Debe' o 'Haber'.";
        this.haberError = "Debe tener un valor en 'Debe' o 'Haber'.";
        return false;
      }

      return true;
    },
    validateCuenta() {
      this.cuentaError = "";
      if (!this.editedItem.cuenta) {
        this.cuentaError = "Debe seleccionar una cuenta contable.";
        return false;
      }
      return true;
    },
    validateDetalles() {
      this.detallesError = "";
      const detalles = this.detalles;
      if (detalles.length === 0) {
        this.detallesError = "Debe agregar al menos un detalle.";
        return false;
      }
      return true;
    },
    validateForm() {
      return this.validateCuenta() && this.validateDebeHaber();
    },
  },
};
</script>
