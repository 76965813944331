<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/egresos/gastos'">
    <template v-slot:navigation-subtitle>Gastos </template>

    <template v-slot:navigation-title>Categorías </template>

  
    <template v-slot:navigation-content>
      <v-list>
        <v-list-item @click="dialogCategoria = true">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Nueva categoría</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-alert class="mt-4" type="info">
        <h4>Editar / eliminar</h4>
        Utilice el click derecho sobre la categoría
      </v-alert>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <v-row v-if="categoriaPadre">
          <v-col cols="12">
            <egreso-categoria-create
              :dialogCategoria="dialogCategoria"
              @close-dialog="dialogCategoria = false"
              :parent="categoriaPadre"
              @refresh-categorias="fetchCategoriaPadre()"
              :withColor="true"
            ></egreso-categoria-create>
            <v-row
              no-gutters
              v-if="categorias && categorias.length > 0"
              align="center"
              justify="start"
            >
              <v-col cols="4" v-for="child in categorias" :key="child.id">
                <v-card
                  :color="child.color ? child.color : ''"
                  outlined
                  class="ma-4 pa-6 d-flex align-center"
                  height="100"
                  tile
                  @click.right="openMenu(child, $event)"
                >
                  <div>
                    {{ child.name }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else align="center" justify="center" style="height: 250px">
              <v-col cols>
                <h1>Bueno, empecemos registrando la primer categoría</h1>
              </v-col>
              <v-col cols="4">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    @click="dialogCategoria = !dialogCategoria"
                    :elevation="hover ? 12 : 2"
                    class="mx-auto"
                  >
                    <v-container>
                      <v-card-text>
                        <h1 :class="hover ? 'amber--text text--accent-3' : ''">
                          <v-icon large>mdi-plus</v-icon>Nueva Categoría
                        </h1>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-menu
        absolute
        fixed
        transition="scale-transition"
        v-model="menu"
        :position-x="xMenu"
        :position-y="yMenu"
      >
        <v-list>
          <v-list-item @click="openDialogCategoriaEdit">
            <v-list-item-avatar>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogEliminar = true">
            <v-list-item-avatar>
              <v-icon color="red">mdi-delete</v-icon>
            </v-list-item-avatar>

            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="dialogCategoriaEdit" width="500">
        <egreso-categoria-update
          :categoria="selectEditCategoria"
          @refresh-categorias="fetchCategoriaPadre"
          @cerrar-dialog="dialogCategoriaEdit = false"
          :withColor="true"
        ></egreso-categoria-update>
      </v-dialog>
      <v-dialog v-if="selectEditCategoria" v-model="dialogEliminar" width="500">
        <v-toolbar color="red lighten-3">
          <v-toolbar-title>Eliminar</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <div class="text-center">
              ¿Está seguro que dese eliminar
              <b>{{ selectEditCategoria.name }} ?</b>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="grey" @click="dialogEliminar = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="red lighten-1"
              @click="eliminarCategoria(selectEditCategoria)"
              >Eliminar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- MENU DE OPCIONES -->
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import EgresoAPI from "@/api/egresos/index.js";
import EgresoCategoriaCreate from "@/components/egresos/categorias/EgresoCategoriaCreate";
import EgresoCategoriaUpdate from "@/components/egresos/categorias/EgresoCategoriaUpdate";

import { mapActions } from "vuex";

export default {
  name: "AdminCategoria",
  components: {
    LayoutBasic,
    EgresoCategoriaCreate,
    EgresoCategoriaUpdate,
  },
  data() {
    return {
      categoriaPadre: null,
      categorias: [],
      dialogCategoria: false,
      dialogCategoriaEdit: false,
      dialogEliminar: false,
      selectEditCategoria: null,
      menu: false,
      xMenu: 0,
      yMenu: 0,
    };
  },
  computed: {
    items() {
      return [
        {
          text: "Nueva categoría",
          icon: "mdi-clock",
        },
        // { text: "Consultar", icon: "mdi-account", route: "#" },
      ];
    },
  },
  methods: {
    fetchCategoriaPadre() {
      EgresoAPI.index().then((response) => {
        this.categoriaPadre = response.data.parent;
        this.categorias = response.data.parent.children;
      });
    },

    openDialogCategoriaEdit() {
      if (this.selectEditCategoria) {
        this.dialogCategoriaEdit = true;
      }
    },
    openMenu(categoria, e) {
      this.selectEditCategoria = { ...categoria };
      e.preventDefault();
      this.xMenu = e.pageX;
      this.yMenu = e.pageY;
      this.menu = true;
    },
    eliminarCategoria(categoria) {
      this.axios
        .delete(`/egresos/categorias/${categoria.id}`)
        .then((response) => {
          if (!response) return;

          this.dialogEliminar = false;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Categoría eliminada",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.fetchCategoriaPadre();
        })
        .catch((e) => {
          if (e.response.status === 409) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "primary",
              icon: "mdi-information",

              text: `${e.response.data}`,
              timeout: 5000,
              right: false,
              top: false,
            });
            this.dialogEliminar = false;
          }
        });
    },
  },
  created() {
    this.fetchCategoriaPadre();
  },
};
</script>

<style lang="scss" scoped></style>
