<template>
  <v-container>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      item-key="id"
      :hide-default-footer="true"
      disable-pagination
      no-data-text="Sin resultados"
      no-results-text="Sin coincidencia para la búsqueda"
      :calculate-widths="true"
    >
      <slot name="custom-row"></slot>
    </v-data-table>
    <v-pagination
      v-if="items"
      @input="paginationChangeHandler"
      :length="pages"
      :value="pagination.page"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
export default {
  name: "DataTablePagination",
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: "",
    loading: false,
  }),
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.cantidad < 0) {
        return 0;
      }
      return Math.ceil(this.pagination.cantidad / this.pagination.rowsPerPage);
    },
  },

  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page == pageNumber) {
        return false;
      }
      this.$emit("set-page", pageNumber);
    },

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },
};
</script>

<style >
.v-datatable thead {
  background-color: rgb(190, 65, 65);
}

.table-scroll-wrapper {
  overflow: auto;
  border: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll {
  margin: 0px;
  border: none;
}

.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-scroll tr td:first-child,
.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 0;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:first-child {
  z-index: 3;
}

.table-scroll tr td:nth-child(2),
.table-scroll th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 64px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
  background: #1e1e1e;
}

.table-scroll th:nth-child(2) {
  z-index: 4;
}

.table-scroll tr td:nth-child(3),
.table-scroll th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  width: 200px;

  left: 134px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
  background: #1e1e1e;
}

.table-scroll th:nth-child(3) {
  z-index: 4;
}
</style>
