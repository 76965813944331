<template>
  <v-container>
    <v-list-group
      :value="false"
      :disabled="!tipo_notificacion.habilitado_sucursal"
      prepend-icon="mdi-cash-minus"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ tipo_notificacion.nombre }}</v-list-item-title>
          <v-list-item-subtitle
            ><div class="text-caption" v-html="tipo_notificacion.descripcion"></div>
            <v-chip :color="tipo_notificacion.subscripto ? 'Blue' : ''" x-small>{{
              tipo_notificacion.subscripto ? "Activado" : "Desactivado"
            }}</v-chip></v-list-item-subtitle
          >
        </v-list-item-content>
      </template>

      <v-list-item dense>
        <v-list-item-avatar class="ma-0"><v-icon>mdi-bell </v-icon></v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Quiero recibir esta notificación</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-switch
            dense
            :loading="loadingStatus"
            @change="updateSubscripcion(tipo_notificacion)"
            v-model="tipo_notificacion.subscripto"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense v-if="tipo_notificacion.codigo != 200">
        <v-list-item-avatar> </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Monto de alerta</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title
            ><span class="grey--text">
              {{ tipo_notificacion.monto }}</span
            ></v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-btn text plain @click="openDialog(tipo_notificacion.monto)">
            <span class="blue--text">Editar</span>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense v-if="tipo_notificacion.codigo == 100">
        <v-list-item-avatar class="ma-0"><v-icon>mdi-lock </v-icon></v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            >Bloquear el sistema cuando se envíe la notificación.</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-switch
            dense
            :loading="loadingBloqueo"
            @change="updateBloqueo(tipo_notificacion)"
            v-model="tipo_notificacion.bloqueo"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- <v-list v-if="tipo_notificacion.tipoEnvio">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              ><div style="font-size: 15px">
                <b>Donde recibes estas notificaciones</b>
              </div></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="envio in tipo_notificacion.tipoEnvio" :key="envio.id" dense>
          <v-list-item-avatar class="ma-0"
            ><v-icon v-text="envio.icon"> </v-icon
          ></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ envio.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-list-group>
    <v-divider></v-divider>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-container>
          <v-card-title><span class="text-h5">Modificar monto retiro</span></v-card-title>
          <v-card-text>
            <v-text-field autofocus v-model="montoEdit" label="Monto"></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn large text plain @click="dialog = false">Cancelar</v-btn>
            <v-btn
              large
              color="blue"
              :loading="loading"
              @click="updateMonto(tipo_notificacion)"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AlertaAPI from "@/api/configuracion/alerta/retiro";
import NotificacionesAPI from "@/api/configuracion/notificaciones/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "configuracion.notificaciones.item",
  data() {
    return {
      dialog: false,
      montoEdit: null,
      loading: false,
      loadingStatus: false,
      loadingBloqueo: false,
    };
  },
  props: {
    tipo_notificacion: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    ...mapActions("notificaciones", ["fetch_tipo_notificaciones"]),
    openDialog(monto) {
      this.montoEdit = monto;

      this.dialog = true;
    },
    updateSubscripcion(data) {
      this.loadingStatus = true;
      NotificacionesAPI.update("subscripto", data)
        .then((response) => {
          this.fetchTipoNotificaciones();
          this.loadingStatus = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateBloqueo(data) {
      this.loadingBloqueo = true;
      NotificacionesAPI.update("bloqueo", data)
        .then((response) => {
          this.fetchTipoNotificaciones();
          this.loadingBloqueo = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateMonto(data) {
      data.monto = this.montoEdit;
      this.loading = true;
      NotificacionesAPI.update("monto", data)
        .then((response) => {
          console.log(response);
          this.fetchTipoNotificaciones();
          this.loading = false;

          this.dialog = false;
        })
        .catch((e) => {
          this.dialog = false;

          console.log(e);
        });
    },

    fetchTipoNotificaciones() {
      this.fetch_tipo_notificaciones();
    },
  },
};
</script>

<style lang="scss" scoped></style>
