<template>
  <div>
    <v-btn @click="$router.push({name: 'reporteGastos'})" text>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
    <v-row justify="center">
      <v-col cols="6">
        <v-card>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <!-- <doughnut-chart v-if="chartGastos" :chartdata="chartGastos" /> -->
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="12">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-chip :color="categoria.color"></v-chip>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h2>
                        {{ categoria.name }}
                      </h2>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <h2>{{ totalGastosPorCategoria | convertPesoArg }}</h2>
                  </v-list-item-action>
                </v-list-item>
                <h2 class="text-center"></h2>
                <v-list v-if="categoria">
                  <v-list-item
                    v-for="tp in categoria.tipos_egresos"
                    :key="tp.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ tp.descripcion }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      {{ tp.total_tipo | convertPesoArg }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoughnutChart from "@/chartjs/doughnut.chart.vue";

export default {
  name: "ReporteGastosDetalle",
  components: {
    DoughnutChart,
  },
  data() {
    return {
      categoria: null,
      chartDataIngreso: null,
      options: { responsive: true, maintainAspectRatio: false },
    };
  },
  computed: {
    totalGastosPorCategoria() {
      if (this.categoria) {
        console.log(this.categoria);
        const sum = this.categoria.tipos_egresos.reduce(
          (accumulator, object) => {
            return accumulator + object.total_tipo;
          },
          0
        );
        return sum;
      } else {
        return 0;
      }
    },
  },
  methods: {
    fetchReporte() {
      var url = "/reportes/reportegastoscat";
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.chartGastos = response.data;
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        if (params.categoria) {
          this.categoria = { ...params.categoria };
        } else {
          this.$router.go(-1);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
