<template>
  <v-menu
    v-model="menu"
    :disabled="false"
    :close-on-content-click="false"
    :nudge-width="150"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on">
   
        <template v-if="cliente">
               <v-list-item-avatar>
          <v-img avatar src="@/assets/man-woman.jpg"></v-img>
        </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ cliente.nombre }}
              {{ cliente.apellido }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ cliente.telefono }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title><div>Buscar cliente</div></v-list-item-title>
          </v-list-item-content>
          
        </template>
      </v-list-item>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <cliente-buscador
              :cliente="cliente"
              @set-cliente="setCliente"
              @remove-cliente="removeCliente"
            ></cliente-buscador>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense v-if="cliente">
        <v-list-item dense>
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-subtitle>Cliente</v-list-item-subtitle>
            <v-list-item-title
              >{{ cliente.nombre }} {{ cliente.apellido }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-action>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ cliente.telefono }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-action>
            <v-icon>mdi-email</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ cliente.email }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import ClienteBuscador from "@/components/cliente/cliente.buscador.vue";
export default {
  name: "ClienteBuscadorSeleccionar",
  components: {
    ClienteBuscador,
  },
  props: {
    title: {
      type: String,
      default: "Cliente desconocido",
    },
    cliente: {
      type: [Object, null],
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    removeCliente() {
      this.$emit("remove-cliente");
    },
    setCliente(cliente) {
      this.menu = false;
      this.$emit("set-cliente", cliente);
    },
  },
};
</script>

<style lang="scss" scoped></style>
