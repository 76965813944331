<template>
  <div>
    <v-container fluid fill-height class="pa-0">
      <div class="headline">
        <v-icon large left @click="$router.go(-1)">mdi-arrow-left</v-icon
        >Generar lista de precios
      </div>
      <v-row class="fill-height mt-4">
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-alert icon="mdi-shield-lock-outline" type="info"
                >Seleccioná los filtros para generar un nueva lista.</v-alert
              >
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="8">
              <div><v-subheader>Filtros</v-subheader></div>

              <v-row>
                <v-col cols="3">
                  <v-card height="100%">
                    <v-card-text>
                      <proveedor
                        :clearable="true"
                        :isForArchivo="true"
                        @clearable="close('proveedor')"
                        @set_proveedor="setProveedor"
                      ></proveedor>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="3" v-if="verFiltros.marca">
                  <v-card height="100%">
                    <v-card-text>
                      <marca
                        :clearable="true"
                        @clearable="close('marca')"
                        :solo="false"
                        @set_marca="setMarca"
                      ></marca>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-if="verFiltros.categoria" cols="3">
                  <v-card height="100%">
                    <v-card-text>
                      <producto-filtro-categoria
      type="list"
      @select-categoria="seleccionarCategoria"
    ></producto-filtro-categoria>
                      <!-- <categoria-select
                        @clearable="close('categoria')"
                        :categoria="{ id: null }"
                        @process-categoria="seleccionarCategoria"
                      ></categoria-select> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="center" v-if="filters_reporte.length > 0">
                <v-col cols="12">
                  <v-card dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="headline"
                          >Generar nueva lista de precio</v-card-title
                        >

                        <v-card-subtitle>
                          <span v-for="chip in filters_reporte" :key="chip.key">
                            <v-chip class="ma-2">{{ chip.nombre }}</v-chip>
                          </span>
                        </v-card-subtitle>
                      </div>

                      <v-avatar class="ma-3" size="125" tile>
                        <v-icon large color="red">mdi-file-pdf-box</v-icon>
                      </v-avatar>
                    </div>
                    <v-card-actions>
                      <v-col align="start">
                        <v-btn
                          @click="verProductos = !verProductos"
                          v-if="productos_reporte.length > 0"
                          >{{
                            verProductos ? "Ocultar productos" : "Ver productos"
                          }}</v-btn
                        >
                        <div v-else>No hay productos</div>
                      </v-col>
                      <v-col align="end">
                        <v-btn
                          :disabled="
                            productos_reporte.length == 0 ||
                            productos_seleccionados.length == 0
                          "
                          @click="dialogConfirmarReporte = true"
                          >Descargar</v-btn
                        >
                      </v-col>
                    </v-card-actions>
                    <small
                      v-if="
                        productos_seleccionados.length > 0 &&
                        productos_reporte.length > 0
                      "
                      >{{ productos_seleccionados.length }} de
                      {{ productos_reporte.length }} seleccionados</small
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  productos_reporte.length > 0 &&
                  productos_seleccionados.length == 0
                "
              >
                <v-col>
                  <v-alert
                    icon="mdi-shield-lock-outline"
                    prominent
                    text
                    type="info"
                    >Debe seleccionar al menos un producto</v-alert
                  >
                </v-col>
              </v-row>
              <v-row align-center justify="center" row>
                <v-col cols="12">
                  <productos-reporte-tabla
                    @process-productos-seleccionados="productosSeleccionados"
                    v-if="
                      filters_reporte.length > 0 && productos_reporte.length > 0
                    "
                    v-show="verProductos"
                  ></productos-reporte-tabla>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <dialog-confirmation-close
      v-if="dialogConfirmarReporte"
      @close-dialog="dialogConfirmarReporte = false"
      @process-accept="openDialogGuardarReporte"
      @process-button-extra="descargarReporte(reporte)"
    >
      <template v-slot:titulo>¿Quiere guardar el reporte?</template>
      <template v-slot:message
        >¿Quiere guardar la configuración del reporte para usarlo en el
        futuro?</template
      >
      <template v-slot:buttonExtra>No guardar</template>
      <template v-slot:buttonCancel>Cancelar</template>
      <template v-slot:buttonOK>Guardar</template>
    </dialog-confirmation-close>
    <dialog-confirmation-close
      v-if="dialogGuardarReporte"
      @close-dialog="dialogConfirmarReporte = false"
      @process-accept="guardarDescargarReporte"
    >
      <template v-slot:titulo>Guardar</template>

      <template v-slot:message>
        Nombre:
        <v-text-field
          v-model="reporte.nombre"
          autofocus
          placeholder="Sin nombre"
        >
        </v-text-field>
      </template>
      <template v-slot:buttonCancel>Cancelar</template>
      <template v-slot:buttonOK>Guardar</template>
    </dialog-confirmation-close>
  </div>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";

import ProductosReporteTabla from "@/views/reportes/ProductosReportesTabla";
import marca from "@/components/marcas/FormSelectMarca";
import CategoriaSelect from "@/components/categorias/consultar/categoria.select";

import { descargarReporteProductoListado, descargarReportePlanillaGondola } from "@/services/reportes";

import proveedor from "@/components/proveedor/FormSelectProveedor";
import ProductoFiltroCategoria from "@/components/producto/filtros/producto.filtro.categoria.vue";

import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "reporte-productos-generar-lista-precios",

  components: {
    ProductosReporteTabla,
    CategoriaSelect,
    proveedor,
    marca,
    DialogConfirmationClose,
    ProductoFiltroCategoria
  },
  data() {
    return {
      dialogConfirmarReporte: false,
      dialogGuardarReporte: false,
      openDialogCategoria: false,
      categoriaSelected: null,
      productos_seleccionados: [],
      verProductos: false,
      verFiltros: { proveedor: true, marca: true, categoria: true },

      reporte: {
        reporte: 0,
        grupo: ["categoriaArbol", "marca"],
        filtro: {},
        nombre: "",
      },
    };
  },
  computed: {
    ...mapState("reportes", ["filters_reporte", "productos_reporte"]),
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    ...mapActions("reportes", ["get_productos_filters"]),
    ...mapMutations("reportes", [
      "addFilter",
      "quitFilter",
      "set_productos_reporte",
    ]),
    openDialogGuardarReporte() {
      this.dialogConfirmarReporte = false;
      setTimeout(() => {
        this.dialogGuardarReporte = true;
      }, 1000);
    },
    guardarDescargarReporte() {
      var parametros = this.reporte;
      parametros.guardar = true;

      if (
        this.productos_seleccionados.length == this.productos_reporte.length
      ) {
        descargarReporteProductoListado(parametros);
      } else {
        descargarReporteProductoListado(
          parametros,
          this.productos_seleccionados
        );
      }

      this.dialogGuardarReporte = false;
    },
    async descargarReporte(reporte) {
      var parametros = reporte;
      if (
        this.productos_seleccionados.length == this.productos_reporte.length
      ) {
        descargarReporteProductoListado(parametros);
      } else {
        descargarReporteProductoListado(
          parametros,
          this.productos_seleccionados
        );
      }
      this.dialogConfirmarReporte = false;
    },
    productosSeleccionados(val) {
      this.productos_seleccionados = val;
    },
    irProductosGondola(productos) {
      this.$router.push({ name: "ReporteParaGondola", params: { productos } });
    },

    close(descripcion) {
      this.quitFilter(descripcion);
      if (descripcion == "categoria")
        (this.categoriaSelected = null),
          delete this.reporte.filtro["categoriaArbol"];
      if (descripcion == "proveedor") delete this.reporte.filtro["proveedor"];
      if (descripcion == "marca") delete this.reporte.filtro["marca"];

      if (this.filters_reporte.length > 0) {
        this.get_productos_filters();
      } else {
        this.set_productos_reporte([]);
      }
    },
    seleccionarCategoria(data) {
      this.categoriaSelected = data;
      this.openDialogCategoria = false;
      if (data == undefined || data.id == null) return false;
      let chip = {
        nombre: data.name,
        key: "categoria",
        value: data.id,
      };

      this.addFilter(chip);
      this.get_productos_filters();
      this.reporte.filtro.categoriaArbol = [data.id];
      // this.setCategoriaSelected(data)
    },
    setMarca(data) {
      if (data == undefined) return false;
      let chip = {
        nombre: data.nombre,
        key: "marca",
        value: data.id,
      };

      this.addFilter(chip);
      this.get_productos_filters();
      this.reporte.filtro.marca = [data.id];
    },
    setProveedor(data) {
      if (data == undefined) return false;

      let chip = {
        nombre: data.nombre,
        key: "proveedor",
        value: data.id,
      };
      this.addFilter(chip);
      this.get_productos_filters();
      this.reporte.filtro.proveedor = [data.id];
    },
  },
};
</script>

<style lang="scss" scoped></style>

</script>

<style lang="scss" scoped>
</style>