<template>
  <div>
    <v-card class="transparent" flat v-if="cliente">
      <v-card-title class="pl-0 pr-0">
        <div class="headline">Direcciones</div>
        <v-spacer> </v-spacer>
        <cliente-direccion-create
          :cliente_id="onlySelect ? cliente.id : $route.params.id_cliente"
          @refresh-direcciones="fetchDirecciones"
        ></cliente-direccion-create>

        <v-dialog v-model="dialogUpdate" v-if="direccionesSelected.length > 0" width="600px">
          <cliente-direccion-update
            @refresh-direcciones="fetchDirecciones"
            @updated-direccion="setDireccion"
            :direccion="direccionesSelected[0]"
            @close-dialog="dialogUpdate = false"
            :cliente_id="onlySelect ? cliente.id : $route.params.id_cliente"
          ></cliente-direccion-update>
        </v-dialog>
      </v-card-title>
    </v-card>
    <v-list v-if="direcciones.length">
      <v-list-item v-for="direccion in direcciones" :key="direccion.id">
        <template v-slot:default="{ active }">
          <v-list-item-action>
            <v-checkbox
              v-model="direccion.selected"
              :input-value="active"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ direccion.nombre }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ direccion.calle }}
              {{ direccion.altura }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-icon
            @click="setFavorita(direccion)"
            v-if="direccion.is_favorita"
            color="yellow darken-3"
          >
            mdi-star
          </v-icon>

          <v-icon @click="setFavorita(direccion)" v-else color="grey lighten-1">
            mdi-star
          </v-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Sin direcciones</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-footer padless v-if="direccionesSelected.length > 0">
      <v-card flat tile width="100%" class="grey darken-3 text-center">
        <v-card-text class="white--text">
          <v-btn icon large class="mx-4" @click="dialogDelete = true">
            <v-icon>mdi-delete</v-icon></v-btn
          >
          <v-btn
            icon
            large
            class="mx-4"
            v-if="direccionesSelected.length == 1"
            @click="dialogUpdate = true"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </v-card-text>
      </v-card>
    </v-footer>
    <layout-dialog-delete
      @close-dialog="dialogDelete = false"
      @processDelete="eliminarDirecciones(direccionesSelected)"
      v-if="dialogDelete"
    >
      <template v-slot:title> Eliminar dirección </template>
      <template v-slot:message
        >¿Estás eguro que deseas eliminar
        {{
          direccionesSelected > 1
            ? "las direcciones seleccionadas"
            : "la dirección seleccionada"
        }}?
      </template>
    </layout-dialog-delete>
  </div>
</template>

<script>
import ClienteDireccionCreate from "@/views/cliente/direcciones/cliente.direccion.create.vue";
import ClienteDireccionUpdate from "@/views/cliente/direcciones/cliente.direccion.update.vue";
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";

export default {
  name: "cliente.direcciones",
  components: {
    ClienteDireccionCreate,
    ClienteDireccionUpdate,
    LayoutDialogDelete,
  },
  props: {
    onlySelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    cliente: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialogDelete: false,
      dialogUpdate: false,
      selected: [],
      direcciones: [],

      noImage: require("@/assets/man-woman.jpg"),
    };
  },

  methods: {
    setFavorita(direccion) {
      if (direccion.is_habilitado) return;

      var cliente_id = this.cliente? this.cliente.id : this.$route.params.id;
      ClienteDireccionesAPI.set_favorita(cliente_id, direccion.id)
        .then((response) => {
          this.fetchDirecciones();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },

    eliminarDirecciones(direcciones) {
      if (direcciones.length > 1) {
        ClienteDireccionesAPI.deleteMultiple(direcciones)
          .then((response) => {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: `Direcciones eliminadas`,
              icon: "mdi-check",
              timeout: 2500,
            });
          })
          .catch((e) => {
            if (e.response.status === 409) {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "primary",
                text: e.response.data.data,
                icon: "mdi-information",
                timeout: 4500,
              });
            } else {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "primary",
                text: "Problema inseperado",
                icon: "mdi-information",
                timeout: 4000,
              });
            }
          });
      } else {
        ClienteDireccionesAPI.delete(direcciones)
          .then((response) => {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: `Dirección eliminada`,
              icon: "mdi-check",
              timeout: 2500,
            });
          })
          .catch((e) => {
            if (e.response.status === 409) {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "primary",
                text: e.response.data.data,
                icon: "mdi-information",
                timeout: 4500,
              });
            } else {
              this.$updateBus.$emit("showSnackBar", {
                show: true,
                button: false,
                color: "primary",
                text: "Problema inseperado",
                icon: "mdi-information",
                timeout: 4000,
              });
            }
          });
      }
      this.dialogDelete = false;
      this.fetchDirecciones();
    },
    setDireccion(nueva_direccion){
      // Actualizo el array de direcciones con al direccion nueva
      this.direcciones = this.direcciones.map(d => d.id == nueva_direccion.id ? nueva_direccion : d)
    },
    fetchDirecciones() {
      if (this.onlySelect) {
        var cliente_id = this.cliente.id;
      } else {
        var cliente_id = this.$route.params.id_cliente;
      }
      ClienteDireccionesAPI.index(cliente_id).then((response) => {
        this.direcciones = response.data;
      });
    },
  },
  computed: {
    direccionesSelected() {
      return this.direcciones.filter((d) => d.selected == true);
    },
  },

  watch: {
    "$route.params": {
      handler: function (params) {
        console.log("Route params:", params);
        
        if (params.cliente) {
          this.cliente = { ...params.cliente };
        } else if (this.$route.params.id_cliente) {
          this.cliente = this.$route.params.id_cliente;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    console.log(this.cliente);
    this.fetchDirecciones();
  },
};
</script>

<style lang="scss" scoped></style>
